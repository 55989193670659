import { Autocomplete, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, Modal, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import ReceiptIcon from '@mui/icons-material/Receipt';
import api from "../util/api";
import globalContext from "./globalContext";
import dateFormat, { masks } from "dateformat";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import AddIcon from '@mui/icons-material/Add';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import FakturiranjeForm from "./fakturiranjeForm";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    minHeight: '80%',
    maxHeight: '99%',
    minWidth: '80%',
    maxWidth: '95%'
};

function OdobrenjeZaduzenjeForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;
    const formOdobrenjeZaduzenje = props.formOdobrenjeZaduzenje;
    const klijenti = props.klijenti || [];
    const filterRacuni = props.filter;

    const [vrstaRacunaID, setVrstaRacunaID] = useState(0);
    const [loading, setLoading] = useState(true);
    const [loadingRacuni, setLoadingRacuni] = useState(false);
    const [valid, setValid] = useState(false);
    const [naslov, setNaslov] = useState("");
    const [klijent, setKlijent] = useState(null);
    const [racuni, setRacuni] = useState([]);
    const [racun, setRacun] = useState(null);
    const [zaposlenici, setZaposlenici] = useState([]);
    const [potpisnaOsoba, setPotpisnaOsoba] = useState(null);
    const [kontrolorNaplate, setKontrolorNaplate] = useState(null);
    const [firmaValute, setFirmaValute] = useState([]);
    const [firmaValuta, setFirmaValuta] = useState(null);
    const [tipoviOporezivosti, setTipoviOporezivosti] = useState([]);
    const [tipOporezivosti, setTipOporezivosti] = useState(null);
    const [broj, setBroj] = useState(null);
    const [pnb, setPnb] = useState(null);
    const [domaci, setDomaci] = useState(true);
    const [napomena, setNapomena] = useState("");
    const [datumObracuna, setDatumObracuna] = useState(new Date());
    const [datumPrikaza, setDatumPrikaza] = useState(new Date());
    const [iznos, setIznos] = useState("0.00");
    const [ukupno, setUkupno] = useState("0.00");
    const [racunskaStavka, setRacunskaStavka] = useState(null);
    const [opis, setOpis] = useState("");
    const [fakturiranjeFormOpened, setFakturiranjeFormOpened] = useState(false);
    const [blagajna, setBlagajna] = useState(null);
    const [poslovniProstor, setPoslovniProstor] = useState(null);
    const [konta, setKonta] = useState([]);
    const [konto, setKonto] = useState(null);
    const [iznosValid, setIznosValid] = useState(true);
    const [firmaJezici, setFirmaJezici] = useState([]);
    const [porezneNapomene, setPorezneNapomene] = useState([]);
    const [poreznaNapomena, setPoreznaNapomena] = useState({ PoreznaNapomenaID: 0, Naziv: null, TipPorezneNapomeneNaziv: null, JezikID: 0, Opis: null, FirmaID: null, TipPorezneNapomeneID: null});
    const [poreznaNapomenaAlert, setPoreznaNapomenaAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        api.get("racun/getodobrenjezaduzenjeformdetalji/" + global.user.firmaID, loadFormDetaljiSuccess, loadFormDetaljiFail);
    });

    function loadFormDetaljiSuccess(data) {
        //setRacuni(data.Racuni);
        setZaposlenici(data.Zaposlenici);
        setFirmaValute(data.FirmaValute);
        setFirmaJezici(data.FirmaJezici);
        setTipoviOporezivosti(data.TipoviOporezivosti);
        setKonta(data.Konta);
        setPorezneNapomene([{ PoreznaNapomenaID: 0, Naziv: null, TipPorezneNapomeneNaziv: null, JezikID: 0, Opis: null, FirmaID: null, TipPorezneNapomeneID: null}, ...data.PorezneNapomene]);
        let tipRacunaID = 0;
        let varVrstaRacunaID = 0;
        if (formOdobrenjeZaduzenje) {
            setBroj(formOdobrenjeZaduzenje.Broj);
            setPnb(formOdobrenjeZaduzenje.PozivNaBroj);
            klijenti.forEach(k => {
                if (k.KlijentID == formOdobrenjeZaduzenje.KlijentID) {
                    setKlijent(k);
                }
            });
            setPotpisnaOsoba(formOdobrenjeZaduzenje.Korisnik);
            data.Zaposlenici.forEach(z => {
                if (z.KorisnikID == formOdobrenjeZaduzenje.KorisnikID) {
                    setPotpisnaOsoba(z);
                }
            });
            data.Zaposlenici.forEach(z => {
                if (z.KorisnikID == formOdobrenjeZaduzenje.KontrolorNaplateKorisnikID) {
                    setKontrolorNaplate(z);
                }
            });
            data.FirmaValute.forEach(fv => {
                if (fv.FirmaValutaID == formOdobrenjeZaduzenje.FirmaValutaID) {
                    setFirmaValuta(fv);
                }
            });
            data.TipoviOporezivosti.forEach(to => {
                if (to.TipOporezivostiID == formOdobrenjeZaduzenje.TipOporezivostiID) {
                    setFirmaValuta(to);
                }
            });
            setDatumPrikaza(formOdobrenjeZaduzenje.Datum);
            //setRacun(data.Racuni.find(r => r.RacunID == formOdobrenjeZaduzenje.VezaRacunID));
            setNapomena(formOdobrenjeZaduzenje.Napomena);
            tipRacunaID = formOdobrenjeZaduzenje.TipRacunaID;
            varVrstaRacunaID = formOdobrenjeZaduzenje.VrstaRacunaID;
            setKonto(formOdobrenjeZaduzenje.Konto);
            let formRacunskaStavka = formOdobrenjeZaduzenje.RacunskeStavke.at(0);    // odobrenje/zaduzenje ima samo jednu racunsku stavku
            setRacunskaStavka(formRacunskaStavka);
            setIznos(Util.toStringDecimal(formRacunskaStavka.Cijena * (formOdobrenjeZaduzenje.VrstaRacunaID == 4 ? -1 : 1)));
            setTipOporezivosti(formRacunskaStavka.TipOporezivosti);
            setDatumObracuna(formRacunskaStavka.DatumObracuna);
            setOpis(formRacunskaStavka.Opis);
            setPoreznaNapomena(formOdobrenjeZaduzenje.PoreznaNapomena ? formOdobrenjeZaduzenje.PoreznaNapomena : { PoreznaNapomenaID: 0, Naziv: null, TipPorezneNapomeneNaziv: null, JezikID: 0, Opis: null, FirmaID: null, TipPorezneNapomeneID: null});
        } else {
            setFirmaValuta(data.DefaultFirmaValuta);
            setTipOporezivosti(data.DefaultTipOporezivosti);
            setKonto(data.DefaultKonto);
            tipRacunaID = props.tipRacunaID;
            varVrstaRacunaID = props.vrstaRacuna.VrstaRacunaID;
        }
        setVrstaRacunaID(varVrstaRacunaID);
        if (tipRacunaID == 1) {
            if (varVrstaRacunaID == 4) {
                setNaslov("Izlazno odobrenje");
            } else if (varVrstaRacunaID == 5) {
                setNaslov("Izlazno zaduženje");
            }
        } else {
            if (varVrstaRacunaID == 4) {
                setNaslov("Ulazno odobrenje");
            } else if (varVrstaRacunaID == 5) {
                setNaslov("Ulazno zaduženje");
            }
        }
        setLoading(false);
    }

    function loadFormDetaljiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save() {
        let racunskeStavkeResource = [{
            RacunskaStavkaID : racunskaStavka ? racunskaStavka.RacunskaStavkaID : -1,
            RacunID : formOdobrenjeZaduzenje ? formOdobrenjeZaduzenje.RacunID : -1,
            TipRacunskeStavkeID : 2,
            Kolicina : 1,
            Cijena : vrstaRacunaID == 4 ? -1 * parseFloat(iznos) : parseFloat(iznos),
            DatumObracuna : datumObracuna,
            TipNaplatnostiID : 1,
            TipOporezivostiID : tipOporezivosti.TipOporezivostiID,
            TipOporezivosti : tipOporezivosti,
            TipVrsteStavkeID : 0,
            RedniBroj : racunskaStavka ? racunskaStavka.RedniBroj : -1,
            Opis : opis
        }]
        let racunResource = {
            RacunID : formOdobrenjeZaduzenje ? formOdobrenjeZaduzenje.RacunID : -1,
            KlijentID : klijent.KlijentID,
            Datum : datumPrikaza,
            DatumPlacanja : datumPrikaza,
            Broj : broj,
            TipRacunaID : formOdobrenjeZaduzenje ? formOdobrenjeZaduzenje.TipRacunaID : props.tipRacunaID,
            FirmaValutaID : firmaValuta.FirmaValutaID,
            FirmaValutaNaziv : firmaValuta.FirmaValutaNaziv,
            KorisnikID : potpisnaOsoba.KorisnikID,
            FirmaID : global.user.firmaID,
            Napomena : napomena,
            TipDrzavnostiID : domaci ? 1 : 2,
            KontrolorNaplateID : kontrolorNaplate ? kontrolorNaplate.KorisnikID : null,
            VrstaRacunaID : formOdobrenjeZaduzenje ? formOdobrenjeZaduzenje.VrstaRacunaID : props.vrstaRacuna.VrstaRacunaID,
            PozivNaBroj : pnb,
            RacunskeStavke : racunskeStavkeResource,
            //BlagajnaID : blagajna.BlagajnaID,
            //OznakaBlagajne : blagajna.FiskalnaOznaka,
            //OznakaPoslovnice : poslovniProstor.FiskalnaOznaka,
            Konto : konto,
            KontoID : konto.KontoID,
            VezaRacunID : racun ? racun.RacunID : null,
            PoreznaNapomenaID : poreznaNapomena.PoreznaNapomenaID > 0 ? poreznaNapomena.PoreznaNapomenaID : null
        }
        setLoading(true);
        if (formOdobrenjeZaduzenje && !broj) {
            api.post("racun/checkporeznanapomena", racunResource, checkPoreznaNapomenaSuccess, checkPoreznaNapomenaFail);
        } else {
            saveConfirmed();
        }
    }

    function checkPoreznaNapomenaSuccess(data) {
        if (!(data == null && poreznaNapomena.PoreznaNapomenaID == 0 || data == poreznaNapomena.TipPorezneNapomeneID)) {
            setPoreznaNapomenaAlert(true);
        } else {
            saveConfirmed();
        }
    }

    function checkPoreznaNapomenaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function saveConfirmed() {
        let racunskeStavkeResource = [{
            RacunskaStavkaID : racunskaStavka ? racunskaStavka.RacunskaStavkaID : -1,
            RacunID : formOdobrenjeZaduzenje ? formOdobrenjeZaduzenje.RacunID : -1,
            TipRacunskeStavkeID : 2,
            Kolicina : 1,
            Cijena : vrstaRacunaID == 4 ? -1 * parseFloat(iznos) : parseFloat(iznos),
            DatumObracuna : datumObracuna,
            TipNaplatnostiID : 1,
            TipOporezivostiID : tipOporezivosti.TipOporezivostiID,
            TipOporezivosti : tipOporezivosti,
            TipVrsteStavkeID : 0,
            RedniBroj : racunskaStavka ? racunskaStavka.RedniBroj : -1,
            Opis : opis
        }]
        let racunResource = {
            RacunID : formOdobrenjeZaduzenje ? formOdobrenjeZaduzenje.RacunID : -1,
            KlijentID : klijent.KlijentID,
            Datum : datumPrikaza,
            DatumPlacanja : datumPrikaza,
            Broj : broj,
            TipRacunaID : formOdobrenjeZaduzenje ? formOdobrenjeZaduzenje.TipRacunaID : props.tipRacunaID,
            FirmaValutaID : firmaValuta.FirmaValutaID,
            FirmaValutaNaziv : firmaValuta.FirmaValutaNaziv,
            KorisnikID : potpisnaOsoba.KorisnikID,
            FirmaID : global.user.firmaID,
            Napomena : napomena,
            TipDrzavnostiID : domaci ? 1 : 2,
            KontrolorNaplateID : kontrolorNaplate ? kontrolorNaplate.KorisnikID : null,
            VrstaRacunaID : formOdobrenjeZaduzenje ? formOdobrenjeZaduzenje.VrstaRacunaID : props.vrstaRacuna.VrstaRacunaID,
            PozivNaBroj : pnb,
            RacunskeStavke : racunskeStavkeResource,
            //BlagajnaID : blagajna.BlagajnaID,
            //OznakaBlagajne : blagajna.FiskalnaOznaka,
            //OznakaPoslovnice : poslovniProstor.FiskalnaOznaka,
            Konto : konto,
            KontoID : konto.KontoID,
            VezaRacunID : racun ? racun.RacunID : null,
            PoreznaNapomenaID : poreznaNapomena.PoreznaNapomenaID > 0 ? poreznaNapomena.PoreznaNapomenaID : null
        }
        api.post("racun/saveodobrenjezaduzenje", racunResource, saveSuccess, saveFail);
    }

    function saveSuccess() {
        setLoading(false);
        filterRacuni();
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    // function toStringDecimal(value) {
    //     if (value != null) {
    //         let res = value.toString();
    //         var decimals = countDecimals(res);
    //         if (decimals == 0) {
    //             res = value + ".00";
    //         } else if (decimals == 1) {
    //             res = value + "0";
    //         }
    //         return res;
    //     } else {
    //         return null;
    //     }
    // }

    // function countDecimals(value) {
    //     if (value.indexOf(".") == -1) {
    //         return 0;
    //     } else if (Math.floor(parseFloat(value)) !== parseFloat(value)) {
    //         return value.toString().split(".")[1].length;
    //     }
    // }

    function handleIznosFocusOut() {
        if (iznos == "" || iznos.charAt(0) == '-') {
            setIznos("0.00");
        } else if (!isNaN(parseFloat(iznos))) {
            let value = iznos;
            let i = 0;
            while (iznos.length > i + 1 && iznos.charAt(i) == '0' && iznos.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            var decimals = Util.countDecimals(value);
            if (decimals == 0) {
                value = value + ".00";
            } else if (decimals == 1) {
                value = value + "0";
            }
            setIznos(value.toString());
        }
    }

    useEffect(() => handleUkupnoChange(), [iznos, tipOporezivosti]);

    function handleUkupnoChange() {
        if (iznos && tipOporezivosti) {
            let value = (parseFloat(iznos) * (1 + tipOporezivosti.Stopa)).toString();
            var decimals = Util.countDecimals(value);
            if (decimals == 0) {
                value = value + ".00";
            } else if (decimals == 1) {
                value = value + "0";
            }
            setUkupno(Util.toStringDecimal(value));
        }
    }

    useEffect(() => {
        if (racun) {
            setFirmaValuta(racun.FirmaValuta);
        }
    }, [racun]);

    useEffect(() => {
        let checkValid = klijent && firmaValuta && potpisnaOsoba && datumObracuna && !isNaN(new Date(datumObracuna)) && datumPrikaza && !isNaN(new Date(datumPrikaza)) && tipOporezivosti;
        setValid(checkValid);
    }, [klijent, firmaValuta, potpisnaOsoba, datumObracuna, datumPrikaza, tipOporezivosti]);

    function handleVezniRacunChange(e, v) {
        setRacun(v);
        if (v) {
            setKonto(v.Konto);
            setFirmaValuta(v.FirmaValuta);
            setDomaci(v.TipDrzavnostiID == 1 ? true : false);
        }
    }

    useEffect(() => {
        if  (vrstaRacunaID == 4) {
            if(!tipOporezivosti){
                setIznosValid(false);
            }
            else{
                if(racun){
                    if (isNaN(parseFloat(iznos)) || !parseFloat(iznos) || parseFloat(iznos) <= 0 || (parseFloat(iznos) + tipOporezivosti.Stopa * parseFloat(iznos) > racun.Potrazuje + racun.Duguje)) {
                        setIznosValid(false);
                    } else {
                        setIznosValid(true);
                    }        
                }
                else{
                    if (isNaN(parseFloat(iznos)) || !parseFloat(iznos) || parseFloat(iznos) <= 0) {
                        setIznosValid(false);
                    } else {
                        setIznosValid(true);
                    }        
                }
            }
        } else {
            if(iznos && parseFloat(iznos) && parseFloat(iznos) > 0){
                setIznosValid(true);
            }
            else{
                setIznosValid(false);
            }
        }
    }, [iznos, tipOporezivosti, racun]);

    useEffect(() => {
        if (klijent) {
            setLoadingRacuni(true);
            api.get("racun/getfakturirani/" + klijent.KlijentID, data => {
                setRacuni(data);
                if (formOdobrenjeZaduzenje) {
                    setRacun(data.find(r => r.RacunID == formOdobrenjeZaduzenje.VezaRacunID));
                }
                setLoadingRacuni(false);
            }, (data) => {
                setRacuni([]);
                setErrorMessage(data ? (data.Message || data.toString()) : "");
                setLoadingRacuni(false);
            })
        } else {
            setRacuni([])
        }
    }, [klijent]);

    return (
        <ComponentContainer loading={loading || loadingRacuni}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <ReceiptIcon fontSize="medium" style={{marginRight:"15px"}}></ReceiptIcon>
                        {naslov}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth >
                        <TextField label="Broj" inputProps={{ readOnly: true }} value={broj || ""} onChange={e => setBroj(e.target.value)} size="small" style={{width:"99%"}}></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            disabled={broj}
                            options={konta}
                            autoHighlight
                            value={konto}
                            getOptionLabel={(option) => option.Naziv + " (" + option.ForeignKontoID + ") - " + option.MjestoTroskaNaziv + " (" + option.MjestoTroskaForeignID + ")"}
                            onChange={(e,v) => setKonto(v)}
                            renderInput={(params) => <TextField {...params} label="Konto" size="small" style={{width:"99%"}} error={!konto} required />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            disabled={klijenti.length == 0 || broj}
                            options={klijenti}
                            autoHighlight
                            value={klijent}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {if (v) setKlijent(v)}}
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} key={"klijent" + option.KlijentID}>
                                    {option.Broj + " - " + option.Naziv}
                                  </li>
                                );
                            }}
                            filterOptions={(options, state) => options.filter(option => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            renderInput={(params) => <TextField {...params} label="Klijent" size="small" style={{width:"99%"}} error={!klijent} required />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Tooltip title={klijent ? "" : "Za prikaz računa odaberite klijenta."}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                noOptionsText="Za odabranog klijenta ne postoji niti jedan fakturirani račun."
                                disabled={!klijent || broj}
                                autoHighlight
                                options={klijent ? racuni.filter(r => r.KlijentID == klijent.KlijentID && r.VrstaRacunaID == 1 && r.Broj != null && !r.Storniran).sort((prvi, drugi) => (new Date(prvi.DatumFakturiranja) - new Date(drugi.DatumFakturiranja))) : []}
                                value={racun || null}
                                getOptionLabel={(option) => dateFormat(option.Datum, "dd.mm.yyyy") + " - " + option.Broj + " - " + Util.toStringDecimal(Util.izracunajIznosRacuna(option)) + " " + option.FirmaValutaNaziv + " (ID: " + option.RacunID + ")"}
                                onChange={handleVezniRacunChange}
                                renderInput={(params) => <TextField {...params} label="Vezni račun" size="small" style={{width:"99%"}} />}
                            />
                        </FormControl>
                    </Tooltip>
                </Grid><Grid item xs={6} sm={6} md={3} >
                    <FormControlLabel 
                        labelPlacement="end"
                        disabled={racun || broj} 
                        label="Domaći"
                        style={{marginLeft:"0px"}}
                        control={<Checkbox checked={domaci} onChange={e => setDomaci(e.target.checked)}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={6} sm={6} md={3} >
                    <FormControlLabel
                        labelPlacement="end" 
                        disabled={racun || broj} 
                        label="Strani"
                        control={<Checkbox checked={!domaci} onChange={e => setDomaci(!e.target.checked)}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            id="valuta-select"
                            autoHighlight
                            disabled={firmaValute.length == 0 || racun || broj}
                            options={firmaValute}
                            value={firmaValuta}
                            getOptionLabel={(option) => option.FirmaValutaNaziv}
                            onChange={(e,v) => {if (v) setFirmaValuta(v)}}
                            renderInput={(params) => <TextField {...params} label="Valuta" size="small" style={{width:"99%"}} error={!firmaValuta} required />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            disabled={zaposlenici.length == 0 || broj}
                            options={zaposlenici.filter(z => z.PotpisnaOsoba && z.TipStatusaKorisnikaID == 1)}
                            value={potpisnaOsoba}
                            autoHighlight
                            getOptionLabel={(option) => option.Ime + " " + option.Prezime}
                            onChange={(e,v) => setPotpisnaOsoba(v)}
                            renderInput={(params) => <TextField {...params} label="Potpisna osoba" size="small" style={{width:"99%"}} error={!potpisnaOsoba} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            disabled={zaposlenici.length == 0}
                            options={zaposlenici.filter(z => z.TipStatusaKorisnikaID == 1)}
                            value={kontrolorNaplate}
                            autoHighlight
                            getOptionLabel={(option) => option.Ime + " " + option.Prezime}
                            onChange={(e,v) => setKontrolorNaplate(v)}
                            renderInput={(params) => <TextField {...params} label="Kontrolor naplate" size="small" style={{width:"99%"}} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Napomena" disabled={broj} inputProps={{ maxLength: 1024 }} value={napomena || ""} onChange={e => setNapomena(e.target.value)} size="small"></TextField>
                    </FormControl>
                </Grid>
                {formOdobrenjeZaduzenje ? (
                    <Grid item xs={12} sm={12} md={12} >
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                options={porezneNapomene.filter(pn => pn.PoreznaNapomenaID == 0 || pn.JezikID == 1)}
                                value={poreznaNapomena}
                                autoHighlight
                                disabled={broj}
                                getOptionLabel={(option) => option.PoreznaNapomenaID == 0 ? "Bez napomene" : option.TipPorezneNapomeneNaziv}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.PoreznaNapomenaID}>
                                            {option.PoreznaNapomenaID == 0 ? "Bez napomene" : null}
                                            {option.PoreznaNapomenaID != 0 ? (
                                                <Tooltip enterDelay={200} enterNextDelay={200} title={
                                                    <Box>
                                                        <Grid container>
                                                            <Grid item xs={12}>
                                                                <Divider color="white"></Divider>
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                Tip:
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {option.TipPorezneNapomeneNaziv}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider color="white"></Divider>
                                                            </Grid>
                                                        </Grid>
                                                        {porezneNapomene.filter(pn => firmaJezici.map(fj => fj.JezikID).includes(pn.JezikID) && pn.TipPorezneNapomeneID == option.TipPorezneNapomeneID).map((n, i) => {
                                                            return (
                                                                <Grid container>
                                                                    <Grid item xs={4}>
                                                                        Jezik:
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        {firmaJezici.find(fj => fj.JezikID == n.JezikID).FirmaJezikNaziv}
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        Naslov:
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        {n.Naziv}
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        Napomena:
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        {n.Opis}
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Divider color="white"></Divider>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Box>
                                                }>
                                                    <div style={{width:"100%"}}>{option.TipPorezneNapomeneNaziv}</div>
                                                </Tooltip>
                                            ) : null}                                        
                                        </li>
                                    );
                                }}
                                onChange={(e,v) => {if (v) setPoreznaNapomena(v)}}
                                renderInput={(params) => 
                                    <Tooltip enterDelay={200} enterNextDelay={200} title={
                                        poreznaNapomena.PoreznaNapomenaID != 0 ? (
                                            <Box>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Divider color="white"></Divider>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        Tip:
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {poreznaNapomena.TipPorezneNapomeneNaziv}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider color="white"></Divider>
                                                    </Grid>
                                                </Grid>
                                                {porezneNapomene.filter(pn => firmaJezici.map(fj => fj.JezikID).includes(pn.JezikID) && pn.TipPorezneNapomeneID == poreznaNapomena.TipPorezneNapomeneID).map((n, i) => {
                                                    return (
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                Jezik:
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {firmaJezici.find(fj => fj.JezikID == n.JezikID).FirmaJezikNaziv}
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                Naslov:
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {n.Naziv}
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                Napomena:
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {n.Opis}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider color="white"></Divider>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Box>
                                        ) : ""
                                    }>
                                        <TextField {...params} label="Porezna napomena" size="small" style={{width:"99%"}} error={!poreznaNapomena} required />
                                    </Tooltip>
                                }
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Opis" value={opis || ""} inputProps={{ maxLength: 255 }} disabled={broj} multiline rows={2} onChange={e => setOpis(e.target.value)} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={6} marginTop="2px" marginBottom="2px">
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker
                            label="Datum obračuna"
                            value={datumObracuna}
                            inputFormat="dd.MM.yyyy"
                            disabled={broj}
                            onChange={(v) => {if (v) setDatumObracuna(v)}}
                            maxDate={new Date()}
                            renderInput={(params) => <TextField style={{width:"99%"}} {...params} size="small" error={!datumObracuna || isNaN(new Date(datumObracuna))} required />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6} marginTop="2px" marginBottom="2px">
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker
                            label="Datum prikaza"
                            value={datumPrikaza}
                            inputFormat="dd.MM.yyyy"
                            disabled={broj}
                            onChange={(v) => {if (v) setDatumPrikaza(v)}}
                            renderInput={(params) => <TextField style={{width:"99%"}} {...params} size="small" error={!datumPrikaza || isNaN(new Date(datumPrikaza))} required />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3} >
                    <FormControl fullWidth>
                        <TextField value={iznos} disabled={broj} type="number" error={iznos.charAt(0) == "-" || !iznosValid} onChange={e => setIznos(e.target.value)} onBlur={handleIznosFocusOut} label="Iznos" size="small" required></TextField>    
                    </FormControl>
                </Grid>
                <Grid item xs={1} >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        height: '100%'
                    }}>
                        <AddIcon></AddIcon>
                    </div>
                </Grid>
                <Grid item xs={4} >
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            options={tipoviOporezivosti}
                            value={tipOporezivosti}
                            autoHighlight
                            disabled={broj}
                            getOptionLabel={(option) => option.TipPorezaNaziv + " (" + option.Stopa * 100 + " %)"}
                            onChange={(e,v) => {if (v) setTipOporezivosti(v)}}
                            renderInput={(params) => <TextField {...params} label="Tip oporezivosti" size="small" error={!tipOporezivosti} required/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={1} >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        height: '100%'
                    }}>
                        <DragHandleIcon></DragHandleIcon>
                    </div>
                </Grid>
                <Grid item xs={3} >
                    <FormControl fullWidth>
                        <TextField value={ukupno} inputProps={{ readOnly: true }} label="Ukupno" size="small"></TextField>    
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Tooltip title={!valid ? "Nisu dobro uneseni svi podaci." : ""}>
                            <span><Button onClick={save} variant="contained" style={{marginRight:"10px"}} color="success" disabled={loading || !valid || !iznosValid}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <SaveIcon style={{marginRight:"10px"}} />} Spremi</Button></span>
                        </Tooltip>
                        <Button variant="outlined" color="error" onClick={() => {setCanceled(true); setFormOpened(false);}} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>

            {/* <Modal open={fakturiranjeFormOpened}>
                <Box sx={style} overflow={"auto"} >
                    <FakturiranjeForm vrstaRacunaID={formOdobrenjeZaduzenje ? formOdobrenjeZaduzenje.VrstaRacunaID : props.vrstaRacuna.VrstaRacunaID} vezniRacunID={racun ? racun.RacunID : 0} odobrenjeZaduzenje={true} setParentBroj={setBroj} setParentBlagajna={setBlagajna} setParentPoslovniProstor={setPoslovniProstor} odobrenjeZaduzenjeStornoDatumRacuna={racun ? racun.Datum : null} setFormOpened={setFakturiranjeFormOpened} save={save} filterRacuni={props.filter}></FakturiranjeForm>
                </Box>
            </Modal> */}

            {poreznaNapomenaAlert ? <AlertDialog message={"Odabrana porezna napomena ne odgovara očekivanoj. Jeste li sigurni da želite spremiti dokument?"} title={"Upozorenje o poreznoj napomeni"} cancelLabel={"Povratak na uređivanje dokumenta"} confirmLabel={"Spremi"} cancelAction={() => {setPoreznaNapomenaAlert(false); setLoading(false);}} confirmAction={saveConfirmed}></AlertDialog> : null}
        
            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default OdobrenjeZaduzenjeForm;