import { useState, useContext, useEffect } from "react";
import { Line } from "@ant-design/charts";
import { FieldTimeOutlined } from "@ant-design/icons";
import api from "../util/api";
import { Button, Row, Col } from "antd";
import Util from "../util/util";
import GlobalContext from "../components/globalContext";
import TextField from "@mui/material/TextField";
import Spinner from "../components/spinner";

function ComponentContainer(props) {
    const useConstructor = Util.useConstructor();
    const isMobileDevice = Util.isMobileDevice();
    const global = useContext(GlobalContext);

    useConstructor(() => {});

    useEffect(() => {});

    return (
        <Row  type="flex" justify="center" align="flex-start" style={{  background:"", minHeight:"80%", alignContent:"flex-start", maxWidth:(isMobileDevice ? "100vw" : "100vw") }}>
            {props.loading && <Spinner></Spinner>}
            {props.children}
        </Row>
    );
}
export default ComponentContainer;
