import { Autocomplete, Box, Button, FormControl, Grid, Paper, Popper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState, useCallback, useRef, useContext } from "react";
import ComponentContainer from "./componentContainer";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Util from "../util/util";
import api from "../util/api";
import LineIcon from "react-lineicons";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import AlertDialog from "./alertdialog";
import globalContext from "./globalContext";
import ErrorIcon from '@mui/icons-material/Error';

function CjenikForm(props) {

    const useConstructor = Util.useConstructor();
    const isMobileDevice = Util.isMobileDevice();

    const global = useContext(globalContext);

    const predmet = props.predmet;
    const firmaID = props.firmaID;
    const setFormOpened = props.setFormOpened;
    const formCjenik = props.formCjenik;
    const postojeciTipoviKorisnika = props.postojeciTipoviKorisnika;
    const setCjenikChanged = props.setCjenikChanged;

    const [loading, setLoading] = useState(true);
    const [tipoviKorisnika, setTipoviKorisnika] = useState([]);
    const [valute, setValute] = useState(null);
    const [firmaValuta, setFirmaValuta] = useState(null);
    const [firmaValute, setFirmaValute] = useState([]);
    const [tipKorisnikaId, setTipKorisnikaId] = useState(null);
    const [dopusteniTipoviKorisnika, setDopusteniTipoviKorisnika] = useState([]);
    const [tipKorisnika, setTipKorisnika] = useState(null);
    const [iznos, setIznos] = useState("0.00");
    const [firmaValutaId, setFirmaValutaId] = useState(null);
    const [edited, setEdited] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [nepreneseneEvidencije, setNepreneseneEvidencije] = useState([]);
    const [postojeNepreneseneEvidencije, setPostojeNepreneseneEvidencije] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        if (formCjenik) {
            setTipKorisnikaId(formCjenik.TipKorisnikaID);
            setIznos(Util.toStringDecimal(formCjenik.Iznos));
            setFirmaValutaId(formCjenik.FirmaValutaID);
        }
        loadInfo();
    });

    function loadInfo() {
        api.get("cjenik/getinfo?firmaID=" + firmaID + "&predmetID=" + predmet.PredmetID, loadInfoSuccess, loadInfoFail);
    }

    function loadInfoSuccess(data) {
        let tipovi = [];
        data.TipoviKorisnika.forEach(d => {
            if (!postojeciTipoviKorisnika.includes(d.TipKorisnikaID)) {
                tipovi.push(d);
            }
        });
        setTipoviKorisnika(data.TipoviKorisnika);
        setDopusteniTipoviKorisnika(tipovi);
        setNepreneseneEvidencije(data.BrojNeprenesenihEvidencija);
        loadValute();
    }

    function loadInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        loadValute();
    }

    function loadValute() {
        api.get("cjenik/getfirmavalute/" + firmaID, loadValuteSuccess, loadValuteFail);
    }

    function loadValuteSuccess(data) {
        setFirmaValute(data.FirmaValute);
        if(!formCjenik){
            if(predmet && predmet.Cap){
                setFirmaValutaId(predmet.FirmaValuta.FirmaValutaID);
            }
            else{
                setFirmaValutaId(data.DefaultFirmaValuta.FirmaValutaID);
            }
        }
        setLoading(false);
    }

    function loadValuteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleTipKorisnikaChange(e,v) {
        if (v) {
            setEdited(true);
            setTipKorisnikaId(v.TipKorisnikaID);
        }
    }

    useEffect(() => loadTipKorisnika(), [tipKorisnikaId, tipoviKorisnika]);

    function loadTipKorisnika() {
        if (tipoviKorisnika) {
            tipoviKorisnika.forEach(tip => {
                if (tip.TipKorisnikaID == tipKorisnikaId) {
                    setTipKorisnika(tip);
                }
            });
        }
    }

    function handleIznosChange(e) {
        setEdited(true);
        setIznos(e.target.value);
    }

    function handleValutaChange(e,v) {
        if (v) {
            setEdited(true);
            setFirmaValutaId(v.FirmaValutaID);
        }
    }

    function findFirmaValuta() {
        if (firmaValute && firmaValutaId) {
            firmaValute.forEach(v => {
                if (v.FirmaValutaID == firmaValutaId) {
                    setFirmaValuta(v);
                }
            })
        }
    }

    useEffect(() => findFirmaValuta(), [firmaValutaId, firmaValute]);

    function save() {

        setLoading(true);
        let newCjenik = {
            TipKorisnikaID: tipKorisnikaId,
            Iznos: iznos,
            FirmaValutaID: firmaValutaId,
            PredmetID: predmet.PredmetID
        }
        if (formCjenik) {
            newCjenik = {...newCjenik, CjenikID: formCjenik.CjenikID};
        } else {
            newCjenik = {...newCjenik, CjenikID: -1};
        }
        api.post("cjenik/add", newCjenik, saveSuccess, saveFail);
    }

    function saveSuccess() {
        setLoading(false);
        setFormOpened(false);
        setCjenikChanged(true);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => checkIfFormValid(), [tipKorisnika, iznos, firmaValuta]);

    function checkIfFormValid() {
        //ovo sam eksplicitno morao dovesti do booleana, jer u protivnom varijabla valid nije nužno boolena s vrijednošću 'true', što se provjerava na setu state-a setFormValid
        let valid = firmaValuta != null && tipKorisnika != null && iznos != null && !isNaN(iznos) && iznos > 0;
        setFormValid(valid == true);
    }

    useEffect(() => {
        if (tipKorisnika) {
            setPostojeNepreneseneEvidencije(nepreneseneEvidencije.find(ne => ne.TipKorisnikaID == tipKorisnika.TipKorisnikaID && ne.BrojNeprenesenihEvidencija > 0));
        } else {
            setPostojeNepreneseneEvidencije(false);
        }
    }, [tipKorisnika, nepreneseneEvidencije]);

    function handleIznosFocusOut() {
        // if (!isNaN(parseFloat(iznos)) && parseFloat(iznos) > 0) {
        //     setIznos(parseFloat(iznos));
        // } else {
        //     setIznos(0);
        // }

        if (iznos == "" || iznos.charAt(0) == '-') {
            setIznos("0.00");
        } else if (!isNaN(parseFloat(iznos))) {
            let value = iznos;
            let i = 0;
            while (iznos.length > i + 1 && iznos.charAt(i) == '0' && iznos.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            ;
            value = Util.toStringDecimal(value);
            setIznos(value);
        }

    }

    const handleKeyPressShorcuts = useCallback((event) => {
        if (event.altKey) {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if (charCode == 'p' && global.roles.includes("19")) {
                event.preventDefault();
                if (!loading && formValid) {
                    save();
                }
            }
            if (charCode == 'o') {
                event.preventDefault();
                if (!loading) {
                    setFormOpened(false);
                }
            }              
        }
    }, [loading, formValid, iznos, tipKorisnikaId, firmaValutaId, predmet]);

    const handleEsc = useCallback((event) => {
        if(event.keyCode == 27){
            event.preventDefault();
            if (!loading) {
                setFormOpened(false);
            }            
        }
    }, [loading]);
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressShorcuts);
        document.addEventListener('keyup', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleKeyPressShorcuts);
            document.removeEventListener('keyup', handleEsc);
        };
    }, [handleKeyPressShorcuts]);

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{width:"100%", textAlign:"center"}}>
                        <MenuBookIcon fontSize="small" style={{marginRight:"15px"}}></MenuBookIcon>
                        Cjenik {predmet ? "- " + predmet.Naziv : ""}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            options={dopusteniTipoviKorisnika}
                            getOptionLabel={(option) => option.Naziv}
                            disabled={formCjenik != null}
                            value={tipKorisnika}
                            autoHighlight
                            onChange={handleTipKorisnikaChange}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            renderInput={(params) => <TextField {...params} label="Tip korisnika" error={!tipKorisnikaId} required size="small"/>}>
                        </Autocomplete>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField type="number" inputProps={{ min: 0.00 }} label="Iznos (cijena po satu)" value={iznos} onChange={handleIznosChange} error={iznos == null || iznos <= 0} required onBlur={handleIznosFocusOut} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            options={firmaValute}
                            getOptionLabel={(option) => option.FirmaValutaNaziv}
                            value={firmaValuta}
                            onChange={handleValutaChange}
                            disabled={predmet && predmet.Cap != null && predmet.Cap != 0}
                            autoHighlight
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            renderInput={(params) => <TextField {...params} label="Valuta" error={!firmaValutaId} required size="small"/>}>
                        </Autocomplete>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" style={{marginRight:"10px"}} color="success" disabled={!edited || loading || !formValid || !global.roles.includes("19")}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <SaveIcon style={{marginRight:"10px"}} />} Sp&#818;remi</Button>
                        <Button variant="outlined" color="error" onClick={() => setFormOpened(false)} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>O&#818;dustani</Button>
                    </Box>
                </Grid>
            </Grid>

            {postojeNepreneseneEvidencije ? <AlertDialog title="Upozorenje o neprenesenim aktivnostima" message={"Za predmet: '" + predmet.Naziv + "' i tip korisnika '" + tipKorisnika.Naziv + "' postoje neprenesene aktivnosti. Ukoliko nastavite, aktivnosti će poprimiti novi cjenik. Želite li nastaviti?"} cancelLabel={"Odustani"} confirmLabel={"Nastavi"} cancelAction={() => {setFormOpened(false);}} confirmAction={() => setPostojeNepreneseneEvidencije(false)}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default CjenikForm;