import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import ComponentContainer from "./componentContainer";
import ErrorIcon from '@mui/icons-material/Error';
import { Box } from "@mui/system";

function ErrorAlert(props) {

    const message = props.message || "Dogodila se greška!";
    const close = props.close;

    return (
        <ComponentContainer>
            <Grid container>
                <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                    <ErrorIcon fontSize="large" color="error"></ErrorIcon>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="h6" gutterBottom component="div">
                        {message}
                    </Typography>
                </Grid>
                <Grid item xs={12} marginTop="15px">
                    <Box style={{display:"flex", justifyContent:"flex-end"}}>
                        <Button variant="contained" onClick={close}>U redu</Button>
                    </Box>
                </Grid>
            </Grid>
        </ComponentContainer>
    );
}

export default ErrorAlert;