import React, { useContext, useEffect, useState } from "react";
import ComponentContainer from "./componentContainer";
import Util from "../util/util";
import globalContext from "./globalContext";
import { Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, List, ListItem, ListItemText, Menu, MenuItem, Modal, Paper, Radio, TextField, Tooltip, Typography } from "@mui/material";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { Box } from "@mui/system";
import api from "../util/api";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
};

function RegistracijaInterno(props) {
    
    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [zahtjevi, setZahtjevi] = useState([]);
    const [currentZahtjev, setCurrentZahtjev] = useState(null);
    const [odobriAlert, setOdobriAlert] = useState(false);
    const [pregled, setPregled] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    
    useConstructor(() => {
        loadZahtjevi();
    });

    function loadZahtjevi() {
        setLoading(true);
        api.get("registracija/getzahtjevi", (data) => {
            setZahtjevi(data);
            if (data && data.length > 0) {
                setCurrentZahtjev(data[0]);
            }
            setLoading(false);
        }, (data) => {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        });
    }


    function odobriZahtjev() {
        setLoading(true);
        api.get("registracija/odobrizahtjev/" + currentZahtjev.RegistracijaFirmeID, () => {
            setOdobriAlert(false);
            loadZahtjevi();
        }, (data) => {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        });
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{marginTop:"20px", marginBottom:"10px", display:"flex", alignItems:"center"}}>
                        <AddBusinessIcon fontSize="medium" style={{marginRight:"15px"}}></AddBusinessIcon>
                        Zahtjevi za registracijom
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="zahtjevi-title" >
                            <Grid container>
                                <Grid item xs={1}>
                                    <ListItemText primary="ZahtjevID" style={{paddingLeft:"15px"}}></ListItemText>
                                </Grid>
                                <Grid item xs={3}>
                                    <ListItemText primary="Naziv" style={{paddingLeft:"15px"}}></ListItemText>
                                </Grid>
                                <Grid item xs={2}>
                                    <ListItemText primary="OIB" style={{paddingLeft:"15px"}}></ListItemText>
                                </Grid>
                                <Grid item xs={2}>
                                    <ListItemText primary="Mjesto" style={{paddingLeft:"15px"}}></ListItemText>
                                </Grid>
                                <Grid item xs={2}>
                                    <ListItemText primary="Ime" style={{paddingLeft:"15px"}}></ListItemText>
                                </Grid>
                                <Grid item xs={2}>
                                    <ListItemText primary="Email" style={{paddingLeft:"15px"}}></ListItemText>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {zahtjevi.map((zahtjev, index) => {
                                    return <ListItem className="list-item" key={"zahtjev" + index} style={{backgroundColor: zahtjev == currentZahtjev ? "rgba(25, 118, 210, 0.08)" : "white", paddingTop: 0, paddingBottom: 0}} onClick={e => setCurrentZahtjev(zahtjev)} onDoubleClick={() => setPregled(true)}>
                                            <Grid container>
                                                <Grid item xs={1}>
                                                    <ListItemText primary={zahtjev.RegistracijaFirmeID} style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zahtjev == currentZahtjev ? 700 : 400 }}></ListItemText>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <ListItemText primary={zahtjev.Naziv} style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zahtjev == currentZahtjev ? 700 : 400 }}></ListItemText>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ListItemText primary={zahtjev.OIB} style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zahtjev == currentZahtjev ? 700 : 400 }}></ListItemText>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ListItemText primary={zahtjev.Ime + " " + zahtjev.Prezime} style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zahtjev == currentZahtjev ? 700 : 400 }}></ListItemText>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ListItemText primary={zahtjev.Username} style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zahtjev == currentZahtjev ? 700 : 400 }}></ListItemText>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ListItemText primary={zahtjev.Email} style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zahtjev == currentZahtjev ? 700 : 400 }}></ListItemText>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12} style={{textAlign:"right"}}>
                    <Button variant="contained" style={{marginRight:"5px"}} startIcon={<SearchIcon></SearchIcon>} onClick={() => setPregled(true)}>Pregled</Button>
                    <Button variant="contained" color="success" startIcon={<TaskAltIcon></TaskAltIcon>} style={{marginRight:"5px"}} onClick={() => setOdobriAlert(true)} disabled={!currentZahtjev}>Odobri zahtjev</Button>
                </Grid>
            </Grid>
            
            <Modal className="modal" open={pregled && currentZahtjev}>
                <Box sx={{...style, minWidth:"80vw"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}  style={{ textAlign: "center", marginBottom: "10px" }}>
                            <Typography variant="h5" gutterBottom component="div">
                                <SearchIcon fontSize="medium"></SearchIcon>
                                &nbsp;&nbsp;Pregled zahtjeva
                            </Typography>   
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth >
                                <TextField label="RegistracijaFirmeID" value={currentZahtjev && currentZahtjev.RegistracijaFirmeID} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth >
                                <TextField label="Vrsta firme" value={currentZahtjev && currentZahtjev.TipTvrtke} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Naziv" value={currentZahtjev && currentZahtjev.Naziv} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="OIB" value={currentZahtjev && currentZahtjev.OIB} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Adresa" value={currentZahtjev && currentZahtjev.Adresa} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Mjesto" value={currentZahtjev && (currentZahtjev.Mjesto.PostanskiBroj + " " + currentZahtjev.Mjesto.Naziv)} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Država" value={currentZahtjev && currentZahtjev.Mjesto.DrzavaNaziv} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Email" value={currentZahtjev && currentZahtjev.Email} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Telefon" value={currentZahtjev && currentZahtjev.Telefon} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Kanal prodaje" value={currentZahtjev && currentZahtjev.KanalProdaje} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Korisničko ime" value={currentZahtjev && currentZahtjev.Username} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Ime" value={currentZahtjev && currentZahtjev.Ime} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Prezime" value={currentZahtjev && currentZahtjev.Prezime} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "right", marginTop: "10px" }}>
                            <Button variant="outlined" color="error" style={{marginLeft:"5px"}} onClick={() => setPregled(false)} startIcon={<CloseIcon></CloseIcon>}>Zatvori</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            {odobriAlert ? <AlertDialog title="Odobrenje zahtjeva" message={"Jeste li sigurni da želite odobriti odabrani zahtjev?"} cancelLabel={"Odustani"} confirmLabel={"Odobri"} cancelAction={() => setOdobriAlert(false)} confirmAction={odobriZahtjev}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default RegistracijaInterno;