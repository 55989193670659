import React, { useContext, useEffect, useState } from "react";
import ComponentContainer from "./componentContainer";
import Util from "../util/util";
import globalContext from "./globalContext";
import { Alert, Autocomplete, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, List, ListItem, ListItemText, Menu, MenuItem, Modal, Paper, Radio, TextField, Tooltip, Typography } from "@mui/material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Box } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import api from "../util/api";
import dateFormat, { masks } from "dateformat";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import EditIcon from '@mui/icons-material/Edit';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SaveIcon from '@mui/icons-material/Save';
import AlertDialog from "./alertdialog";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
};

const tipoviAktivacijskeRadnje = [
    { TipAktivacijskeRadnjeID : 0, Naziv : "Svi" },
    { TipAktivacijskeRadnjeID : 1, Naziv : "Aktivacija" },
    { TipAktivacijskeRadnjeID : 2, Naziv : "Deaktivacija" }
];

const periodOptions = [
    { Naziv: "Unazad 2 mjeseca", Vrijednost: 6},
    { Naziv: "Svi", Vrijednost: 1},
    { Naziv: "Za godinu", Vrijednost: 2},
    { Naziv: "Za mjesec", Vrijednost: 3},
    { Naziv: "Za datum", Vrijednost: 4},
    { Naziv: "Za razdoblje", Vrijednost: 5}
];

function Zahtjevi(props) {
    
    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const setZahtjevOdobrenTrigger = props.setZahtjevOdobrenTrigger;
    let filterRef = props.filterRef;

    const [loading, setLoading] = useState(true);
    const [zahtjevi, setZahtjevi] = useState([]);
    const [tipAktivacijskeRadnje, setTipAktivacijskeRadnje] = useState(tipoviAktivacijskeRadnje[0]);
    const [firme, setFirme] = useState([]);
    const [firma, setFirma] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const vrijemeOpened = Boolean(anchorEl);
    const [datumOd, setDatumOd] = useState(new Date());
    const [datumDo, setDatumDo] = useState(new Date());
    const [mjesec, setMjesec] = useState(new Date());
    const [datum, setDatum] = useState(new Date());
    const [godina, setGodina] = useState(new Date());
    const [periodOption, setPeriodOption] = useState(periodOptions[0]);
    const [periodLabel, setPeriodLabel] = useState("");
    const [samoNeobradeni, setSamoNeobradeni] = useState(false);
    const [currentZahtjev, setCurrentZahtjev] = useState(null);
    const [formOpened, setFormOpened] = useState(false);
    const [datumPrimjene, setDatumPrimjene] = useState(new Date());
    const [odobriAlert, setOdobriAlert] = useState(false);
    const [odbijAlert, setOdbijAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    useConstructor(() => {
        api.get("firma/getall", loadFirmeSuccess, loadFirmeFail);
    });

    useEffect(() => {
        if (currentZahtjev) {
            setDatumPrimjene(currentZahtjev.DatumPrimjene);
        }
    }, [currentZahtjev]);

    function loadFirmeSuccess(data) {
        let sveFirme = {
            FirmaID: 0,
            Naziv: "Sve"
        };
        data.unshift(sveFirme);
        setFirme(data);
        setFirma(sveFirme);

        if (filterRef.current != null) {
            let filterCopy = JSON.parse(JSON.stringify(filterRef.current));
            filterRef.current = null;
            loadZahtjevi(filterCopy);
        } else {
            loadZahtjevi(null);
        }

    }

    function loadFirmeFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function loadZahtjevi(parameters) {
        let resource;
        setLoading(true);
        if (parameters == null) {
            resource = {
                PeriodType : periodOptions[0].Vrijednost,
                FirmaID : 0,
                SamoNeobradeni : false,
                DatumOd : datumOd,
                DatumDo : datumDo,
                TipAktivacijskeRadnjeID : 0
            };
        } else {
            resource = parameters;
        }
        switch (resource.PeriodType) {
            case 1:
                setPeriodLabel("(Svi)");
                break;
            case 2:
                setPeriodLabel("(Za godinu " + dateFormat(resource.DatumOd, "yyyy.") + ")")
                break;
            case 3:
                setPeriodLabel("(Za mjesec " + dateFormat(resource.DatumOd, "mm.yyyy.") + ")");
                break;
            case 4:
                setPeriodLabel("(Za datum " + dateFormat(resource.DatumOd, "dd.mm.yyyy.") + ")");
                break;
            case 5:
                setPeriodLabel("(Za razdoblje " + dateFormat(resource.DatumOd, "dd.mm.yyyy.") + " - " + dateFormat(resource.DatumDo, "dd.mm.yyyy.") + ")");
                break;
            case 6:
                setPeriodLabel("(Unazad 2 mjeseca)");
                break;
        }
        api.post("korisnici/getpostojecizahtjevi", resource, data => {
            setZahtjevi(data);
            if (data.length > 0) {
                setCurrentZahtjev(data[0]);
            }
            setLoading(false);
        }, data => {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        });
    }

    function handleVrijemeClick(event) {
        setAnchorEl(event.currentTarget);
    };

    function handleVrijemeClose() {
        setAnchorEl(null);
    };

    function handleDatumOdChange(v) {
        if (!isNaN(new Date(v))) {
            setDatumOd(v);
            loadZahtjevi({
                PeriodType : periodOption.Vrijednost,
                FirmaID : firma.FirmaID,
                SamoNeobradeni : samoNeobradeni,
                DatumOd : v,
                DatumDo : datumDo,
                TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
            });
        }
    }

    function handleDatumDoChange(v) {
        if (!isNaN(new Date(v))) {
            setDatumDo(v);
            loadZahtjevi({
                PeriodType : periodOption.Vrijednost,
                FirmaID : firma.FirmaID,
                SamoNeobradeni : samoNeobradeni,
                DatumOd : datumOd,
                DatumDo : v,
                TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
            });
        }
    }

    function handleDatumPrimjeneChange(v) {
        if (!isNaN(new Date(v))) {
            setDatumPrimjene(v);
        }
    }

    function odobrenje() {
        setLoading(true);
        let resource = {
            KorisnikAktivacijaID : currentZahtjev.KorisnikAktivacijaID,
            Datum : datumPrimjene
        };
        api.post("korisnici/odobrenje", resource, () => {
            setLoading(false);
            setOdobriAlert(false);
            // loadZahtjevi({
            //     PeriodType : periodOption.Vrijednost,
            //     FirmaID : firma.FirmaID,
            //     SamoNeobradeni : samoNeobradeni,
            //     DatumOd : datumOd,
            //     DatumDo : datumDo,
            //     TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
            // });
            
            // loadat ce se pri rerenderu koji se dogada zbog promjene statea roditelja

            filterRef.current = {
                PeriodType : periodOption.Vrijednost,
                FirmaID : firma.FirmaID,
                SamoNeobradeni : samoNeobradeni,
                DatumOd : datumOd,
                DatumDo : datumDo,
                TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
            }
            setZahtjevOdobrenTrigger(new Date());
        }, (data) => {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        })
    }

    function odobriOdmah() {
        setLoading(true);
        let resource = {
            KorisnikAktivacijaID : currentZahtjev.KorisnikAktivacijaID,
            Datum : new Date()
        };
        api.post("korisnici/odobrenje", resource, () => {
            setLoading(false);
            setOdobriAlert(false);
            // loadZahtjevi({
            //     PeriodType : periodOption.Vrijednost,
            //     FirmaID : firma.FirmaID,
            //     SamoNeobradeni : samoNeobradeni,
            //     DatumOd : datumOd,
            //     DatumDo : datumDo,
            //     TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
            // });
            
            // loadat ce se pri rerenderu koji se dogada zbog promjene statea roditelja

            filterRef.current = {
                PeriodType : periodOption.Vrijednost,
                FirmaID : firma.FirmaID,
                SamoNeobradeni : samoNeobradeni,
                DatumOd : datumOd,
                DatumDo : datumDo,
                TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
            }
            setZahtjevOdobrenTrigger(new Date());
        }, (data) => {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        })
    }

    function odbijZahtjev() {
        setLoading(true);
        api.get("korisnici/odbijzahtjev/" + currentZahtjev.KorisnikAktivacijaID, () => {
            setLoading(false);
            setOdbijAlert(false);
            // loadZahtjevi({
            //     PeriodType : periodOption.Vrijednost,
            //     FirmaID : firma.FirmaID,
            //     SamoNeobradeni : samoNeobradeni,
            //     DatumOd : datumOd,
            //     DatumDo : datumDo,
            //     TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
            // });

            filterRef.current = {
                PeriodType : periodOption.Vrijednost,
                FirmaID : firma.FirmaID,
                SamoNeobradeni : samoNeobradeni,
                DatumOd : datumOd,
                DatumDo : datumDo,
                TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
            }
            setZahtjevOdobrenTrigger(new Date());
        }, (data) => {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        })
    }

    function save() {
        setLoading(true);
        api.post("korisnik/updatedatumprimjene", {
            KorisnikAktivacijaID : currentZahtjev.KorisnikAktivacijaID,
            Datum : datumPrimjene
        }, () => {
            loadZahtjevi({
                PeriodType : periodOption.Vrijednost,
                FirmaID : firma.FirmaID,
                SamoNeobradeni : samoNeobradeni,
                DatumOd : datumOd,
                DatumDo : datumDo,
                TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
            });
            setFormOpened(false);
        }, (data) => {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        });
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{marginTop:"20px", marginBottom:"10px", display:"flex", alignItems:"center"}}>
                        <ManageAccountsIcon fontSize="medium" style={{marginRight:"15px"}}></ManageAccountsIcon>
                        Zahtjevi za aktivacijom i deaktivacijom korisnika
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth >
                        <Autocomplete
                            disablePortal
                            disableClearable
                            options={tipoviAktivacijskeRadnje}
                            getOptionLabel={(option) => option.Naziv}
                            value={tipAktivacijskeRadnje}
                            onChange={(e,v) => {
                                setTipAktivacijskeRadnje(v);
                                loadZahtjevi({
                                    PeriodType : periodOption.Vrijednost,
                                    FirmaID : v.FirmaID,
                                    SamoNeobradeni : samoNeobradeni,
                                    DatumOd : datumOd,
                                    DatumDo : datumDo,
                                    TipAktivacijskeRadnjeID : v.TipAktivacijskeRadnjeID
                                });
                            }}                           
                            renderInput={(params) => <TextField {...params} label="Tip aktivacijske radnje"  size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth >
                        <Autocomplete
                            disablePortal
                            disableClearable
                            options={firme}
                            getOptionLabel={(option) => option.Naziv}
                            value={firma}
                            onChange={(e,v) => {
                                setFirma(v);
                                loadZahtjevi({
                                    PeriodType : periodOption.Vrijednost,
                                    FirmaID : v.FirmaID,
                                    SamoNeobradeni : samoNeobradeni,
                                    DatumOd : datumOd,
                                    DatumDo : datumDo,
                                    TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
                                });
                            }}                           
                            renderInput={(params) => <TextField {...params} label="Firma"  size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <Button
                        id="basic-button"
                        aria-controls={vrijemeOpened ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={vrijemeOpened ? 'true' : undefined}
                        onClick={handleVrijemeClick}
                        size="medium"
                        style={{marginBottom:"5px", width:"100%", color:"darkgrey", borderColor:"#B0B0B0", height:"2.9em"}}
                        variant="outlined"
                        endIcon={vrijemeOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    >
                        Vremensko razdoblje&nbsp;{periodLabel}
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={vrijemeOpened}
                        onClose={handleVrijemeClose}
                        PaperProps={{
                            style: {
                                 width: "30vw",
                                 maxWidth: "30vw"
                               }
                             }}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriodOption(periodOptions.find(po => po.Vrijednost == 6));
                                    loadZahtjevi({
                                        PeriodType : 6,
                                        FirmaID : firma.FirmaID,
                                        SamoNeobradeni : samoNeobradeni,
                                        DatumOd : datumOd,
                                        DatumDo : datumDo,
                                        TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
                                    });
                                }}
                            >
                                <Radio checked={periodOption.Vrijednost == 6}></Radio>
                                <ListItemText>{periodOptions.find(po => po.Vrijednost == 6).Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriodOption(periodOptions.find(po => po.Vrijednost == 1));
                                    loadZahtjevi({
                                        PeriodType : 1,
                                        FirmaID : firma.FirmaID,
                                        SamoNeobradeni : samoNeobradeni,
                                        DatumOd : datumOd,
                                        DatumDo : datumDo,
                                        TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
                                    });
                                }}
                            >
                                <Radio checked={periodOption.Vrijednost == 1}></Radio>
                                <ListItemText>{periodOptions.find(po => po.Vrijednost == 1).Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriodOption(periodOptions.find(po => po.Vrijednost == 2));
                                    loadZahtjevi({
                                        PeriodType : 2,
                                        FirmaID : firma.FirmaID,
                                        SamoNeobradeni : samoNeobradeni,
                                        DatumOd : datumOd,
                                        DatumDo : datumDo,
                                        TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
                                    });
                                }}
                            >
                                <Radio checked={periodOption.Vrijednost == 2}></Radio>
                                <ListItemText>{periodOptions.find(po => po.Vrijednost == 2).Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriodOption(periodOptions.find(po => po.Vrijednost == 3));
                                    loadZahtjevi({
                                        PeriodType : 3,
                                        FirmaID : firma.FirmaID,
                                        SamoNeobradeni : samoNeobradeni,
                                        DatumOd : datumOd,
                                        DatumDo : datumDo,
                                        TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
                                    });
                                }}
                            >
                                <Radio checked={periodOption.Vrijednost == 3}></Radio>
                                <ListItemText>{periodOptions.find(po => po.Vrijednost == 3).Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriodOption(periodOptions.find(po => po.Vrijednost == 4));
                                    loadZahtjevi({
                                        PeriodType : 4,
                                        FirmaID : firma.FirmaID,
                                        SamoNeobradeni : samoNeobradeni,
                                        DatumOd : datumOd,
                                        DatumDo : datumDo,
                                        TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
                                    });
                                }}
                            >
                                <Radio checked={periodOption.Vrijednost == 4}></Radio>
                                <ListItemText>{periodOptions.find(po => po.Vrijednost == 4).Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriodOption(periodOptions.find(po => po.Vrijednost == 5));
                                    loadZahtjevi({
                                        PeriodType : 5,
                                        FirmaID : firma.FirmaID,
                                        SamoNeobradeni : samoNeobradeni,
                                        DatumOd : datumOd,
                                        DatumDo : datumDo,
                                        TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
                                    });
                                }}
                            >
                                <Radio checked={periodOption.Vrijednost == 5}></Radio>
                                <ListItemText>{periodOptions.find(po => po.Vrijednost == 5).Naziv}</ListItemText>
                            </MenuItem>
                            <Divider></Divider>
                        {periodOption.Vrijednost == 2 ? (
                            <MenuItem>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                    <DatePicker
                                        views={['year']}
                                        label="Godina"
                                        value={godina}
                                        inputFormat="yyyy"
                                        onChange={(v) => {
                                            if (!isNaN(new Date(v))) {
                                                setMjesec(v);
                                                loadZahtjevi({
                                                    PeriodType : periodOption.Vrijednost,
                                                    FirmaID : firma.FirmaID,
                                                    SamoNeobradeni : samoNeobradeni,
                                                    DatumOd : v,
                                                    DatumDo : datumDo,
                                                    TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
                                                });
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{width:"100%"}} size="small" />}
                                    />
                                </LocalizationProvider>
                            </MenuItem>
                        ) : null}
                        {periodOption.Vrijednost == 3 ? (
                            <MenuItem>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                    <DatePicker
                                        views={['year', 'month']}
                                        label="Mjesec"
                                        value={mjesec}
                                        inputFormat="MM.yyyy"
                                        onChange={(v) => {
                                            if (!isNaN(new Date(v))) {
                                                setMjesec(v);
                                                loadZahtjevi({
                                                    PeriodType : periodOption.Vrijednost,
                                                    FirmaID : firma.FirmaID,
                                                    SamoNeobradeni : samoNeobradeni,
                                                    DatumOd : v,
                                                    DatumDo : datumDo,
                                                    TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
                                                });
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{width:"100%"}} size="small" />}
                                    />
                                </LocalizationProvider>
                            </MenuItem>
                        ) : null}
                        {periodOption.Vrijednost == 4 ? (
                            <MenuItem>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                    <DatePicker
                                        label="Datum"
                                        value={datum}
                                        inputFormat="dd.MM.yyyy"
                                        onChange={(v) => {
                                            if (!isNaN(new Date(v))) {
                                                setMjesec(v);
                                                loadZahtjevi({
                                                    PeriodType : periodOption.Vrijednost,
                                                    FirmaID : firma.FirmaID,
                                                    SamoNeobradeni : samoNeobradeni,
                                                    DatumOd : v,
                                                    DatumDo : datumDo,
                                                    TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
                                                });
                                            }
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{width:"100%"}} size="small" />}
                                    />
                                </LocalizationProvider>
                            </MenuItem>
                        ) : null}
                        {periodOption.Vrijednost == 5 ? (
                            <MenuItem>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                    <DatePicker
                                        label="Od"
                                        value={datumOd}
                                        inputFormat="dd.MM.yyyy"
                                        onChange={handleDatumOdChange}
                                        renderInput={(params) => <TextField {...params} sx={{width:"100%"}} size="small" />}
                                    />
                                </LocalizationProvider>
                            </MenuItem>
                        ) : null}
                        {periodOption.Vrijednost == 5 ? (
                            <MenuItem>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                    <DatePicker
                                        label="Do"
                                        value={datumDo}
                                        inputFormat="dd.MM.yyyy"
                                        onChange={handleDatumDoChange}
                                        renderInput={(params) => <TextField {...params} sx={{width:"100%"}} size="small" />}
                                    />
                                </LocalizationProvider>
                            </MenuItem>
                        ) : null}
                        <Divider />
                    </Menu>
                </Grid>
                <Grid item xs={8}>
                </Grid>
                <Grid item xs={4} style={{display:"flex", justifyContent:"flex-end"}}>
                    <FormControlLabel 
                        labelPlacement="start" 
                        label="Samo neobrađeni zahtjevi"
                        control={<Checkbox checked={samoNeobradeni} onChange={e => {
                                setSamoNeobradeni(e.target.checked);
                                loadZahtjevi({
                                    PeriodType : periodOption.Vrijednost,
                                    FirmaID : firma.FirmaID,
                                    SamoNeobradeni : e.target.checked,
                                    DatumOd : datumOd,
                                    DatumDo : datumDo,
                                    TipAktivacijskeRadnjeID : tipAktivacijskeRadnje.TipAktivacijskeRadnjeID
                                });
                            }}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="zahtjevi-title" >
                            <Grid container>
                                <Grid item xs={2}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Ime"} />
                                </Grid>
                                <Grid item xs={2}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Korisničko ime"} />
                                </Grid>
                                <Grid item xs={2}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"E-mail"} />
                                </Grid>
                                <Grid item xs={2}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Tip zahtjeva"} />
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title="Datum zahtjeva">
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Datum zahtjeva"} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={1}>
                                    <Tooltip title="Datum primjene">
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Datum primjene"} />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={2}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Status"} />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {zahtjevi.map((zahtjev, index) => {
                                    return <ListItem className="list-item" style={{paddingTop:0, paddingBottom:0, backgroundColor : zahtjev.TipStatusaAktivacijeID == 1 ? (zahtjev == currentZahtjev ? "rgb(255,223,0)" : "yellow") : (zahtjev == currentZahtjev ? "rgba(25, 118, 210, 0.08)" : "white")}} key={"zahtjev" + index} onClick={e => setCurrentZahtjev(zahtjev)} onDoubleClick={e => setFormOpened(true)}>
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zahtjev == currentZahtjev ? 700 : 400 }} primary={zahtjev.Ime} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zahtjev == currentZahtjev ? 700 : 400 }} primary={zahtjev.Username} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zahtjev == currentZahtjev ? 700 : 400 }} primary={zahtjev.Email} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, color: zahtjev.TipAktivacijskeRadnjeID == 1 ? "green" : "red", fontWeight: zahtjev == currentZahtjev ? 700 : 400 }} primary={zahtjev.TipAktivacijskeRadnje} />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zahtjev == currentZahtjev ? 700 : 400 }} primary={dateFormat(zahtjev.Datum, "dd.mm.yyyy")} />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zahtjev == currentZahtjev ? 700 : 400 }} primary={dateFormat(zahtjev.DatumPrimjene, "dd.mm.yyyy")} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zahtjev == currentZahtjev ? 700 : 400, color: zahtjev.TipStatusaAktivacijeID == 1 ? "black" : (zahtjev.TipStatusaAktivacijeID == 2 ? "blue" : (zahtjev.TipStatusaAktivacijeID == 3 ? "green" : "red")) }} primary={zahtjev.TipStatusaAktivacije} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12} style={{textAlign:"right"}}>
                    <Button variant="contained" startIcon={<EditIcon></EditIcon>} onClick={() => setFormOpened(true)} style={{marginRight:"5px"}} disabled={!currentZahtjev}>Uredi</Button>
                    <Button variant="contained" color="success" startIcon={<TaskAltIcon></TaskAltIcon>} style={{marginRight:"5px"}} onClick={() => setOdobriAlert(true)} disabled={!currentZahtjev || currentZahtjev.TipStatusaAktivacijeID != 1}>Odobri zahtjev</Button>
                    <Button variant="contained" color="error" startIcon={<DoDisturbIcon></DoDisturbIcon>} style={{marginRight:"5px"}} onClick={() => setOdbijAlert(true)} disabled={!currentZahtjev || currentZahtjev.TipStatusaAktivacijeID != 1}>Odbij zahtjev</Button>
                </Grid>
            </Grid>
            
            <Modal className="modal" open={formOpened && currentZahtjev}>
                <Box sx={{...style, minWidth:"80vw"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}  style={{ textAlign: "center", marginBottom: "10px" }}>
                            <Typography variant="h5" gutterBottom component="div">
                                <EditIcon fontSize="medium"></EditIcon>
                                &nbsp;&nbsp;Uređivanje zahtjeva
                            </Typography>   
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="KorisnikAktivacijaID" value={currentZahtjev && currentZahtjev.KorisnikAktivacijaID} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Korisničko ime" value={currentZahtjev && currentZahtjev.Username} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Ime" value={currentZahtjev && currentZahtjev.Ime} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Email" value={currentZahtjev && currentZahtjev.Email} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Firma" value={currentZahtjev && currentZahtjev.FirmaNaziv} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Tip zahtjeva" value={currentZahtjev && currentZahtjev.TipAktivacijskeRadnje} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Status" value={currentZahtjev && currentZahtjev.TipStatusaAktivacije} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Tip korisnika" value={currentZahtjev && currentZahtjev.TipKorisnika} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Telefon" value={currentZahtjev && currentZahtjev.Telefon || "/"} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth >
                                <TextField label="Zatražio korisnik" value={currentZahtjev && currentZahtjev.ZatrazioKorisnik} disabled size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Datum zahtjeva"
                                    value={currentZahtjev ? currentZahtjev.Datum : new Date()}
                                    inputFormat="dd.MM.yyyy"
                                    disabled
                                    renderInput={(params) => <TextField size="small" {...params} sx={{width: '100%'}} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Datum primjene"
                                    value={datumPrimjene}
                                    inputFormat="dd.MM.yyyy"
                                    onChange={handleDatumPrimjeneChange}
                                    renderInput={(params) => <TextField size="small" {...params} sx={{width: '100%'}} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: "right", marginTop: "10px" }}>
                            <Button variant="contained" color="success" onClick={save} startIcon={<SaveIcon></SaveIcon>}>Spremi</Button>
                            <Button variant="outlined" color="error" style={{marginLeft:"5px"}} onClick={() => {
                                setDatumPrimjene(currentZahtjev.DatumPrimjene);
                                setFormOpened(false);
                            }} startIcon={<CloseIcon></CloseIcon>}>Zatvori</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            {odobriAlert ? <AlertDialog title="Odobrenje zahtjeva" message={
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <div>
                            Odaberi datum primjene zahtjeva:
                        </div>
                    </Grid>
                    <Grid item xs={12} marginTop="10px">
                        <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Datum primjene"
                                value={datumPrimjene}
                                minDate={new Date()}
                                inputFormat="dd.MM.yyyy"
                                onChange={handleDatumPrimjeneChange}
                                renderInput={(params) => <TextField size="small" {...params} sx={{width: '100%'}} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} marginTop="10px">
                        <Alert severity="info">Funkcija "Odobri" realizirat će zahtjev na odabrani datum, a funkcija "Odobri odmah" na današnji datum (najranije moguće).</Alert>
                    </Grid>
                </Grid>
            
            } cancelLabel={"Odustani"} confirmLabel={"Odobri"} okHidden={false} okLabel={"Odobri odmah"} okAction={odobriOdmah} cancelAction={() => setOdobriAlert(false)} confirmAction={odobrenje}></AlertDialog> : null}

            {odbijAlert ? <AlertDialog title="Odbijanje zahtjeva" message={"Jeste li sigurni da želite odbiti odabrani zahtjev?"} cancelLabel={"Odustani"} confirmLabel={"Odbij"} cancelAction={() => setOdbijAlert(false)} confirmAction={odbijZahtjev}></AlertDialog> : null}
        
            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default Zahtjevi;