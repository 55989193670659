import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { SketchPicker } from "react-color";
import { Button, Grid } from "@mui/material";

export default function ColorPicker(props) {
    const [color, setColor] = useState("red");
    const [open, setOpen] = useState(false);

    const handleClose = (e) => {
        e.stopPropagation();
        setOpen(false);
    };

    useEffect(() => {
        if (color != props.color) {
            setColor(props.color);
        }
    });

    const onChange = (e) => {
        setColor(e.hex);
        console.log(e);
        if (props.onChange) {
            props.onChange(e.rgb.r + "," + e.rgb.g + "," + e.rgb.b, props.id);
        }
    };

    return (
        <>
            <table style={{cursor:"pointer"}}  onClick={() => setOpen(true)}>
                <tbody>
                    <tr>
                        <td>
                            <div style={{marginTop: "0px", minWidth: "20px", minHeight: "20px", backgroundColor: "rgb(" + props.color + ")", borderRadius: "18px", border: "0px solid #9e9e9e", cursor: "pointer" }} onClick={() => setOpen(true)}>
                                <Dialog onClose={handleClose} open={open}>
                                    <SketchPicker color={"rgb(" + color + ")"} onChangeComplete={onChange} />
                                    <Grid container style={{ textAlign: "center" }}>
                                        <Grid item xs={12}>
                                            <Button
                                                style={{ width: "100%" }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setOpen(false);
                                                }}
                                            >
                                                OK
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Dialog>
                            </div>
                        </td>
                        <td>
                            <label style={{cursor:"pointer"}}><Typography style={{ fontSize: 12 }}>&nbsp; {props.label}</Typography></label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
