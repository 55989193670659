import { Autocomplete, Box, Button, Divider, FormControl, Grid, Modal, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import api from "../util/api";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutocompleteAsync from "../controls/autocompleteasync";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorAlert from "./errorAlert";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import GlobalContext from "../components/globalContext";
import ErrorIcon from '@mui/icons-material/Error';

//const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9.]+$/;
const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9_.]+$/;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

function Registracija(props) {

    const useConstructor = Util.useConstructor();

    const form = props.form;
    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;

    const [loading, setLoading] = useState(true);
    const [naziv, setNaziv] = useState("");
    const [oib, setOib] = useState("");
    const [mjesto, setMjesto] = useState(null);
    const [adresa, setAdresa] = useState("");
    const [mjestoApi, setMjestoApi] = useState();
    const [ime, setIme] = useState("");
    const [prezime, setPrezime] = useState("");
    const [email, setEmail] = useState("");
    const [telefon, setTelefon] = useState("");
    const [formValid, setFormValid] = useState(false);
    const [greska, setGreska] = useState("");
    const [uspjeh, setUspjeh] = useState("");
    const [existingUsernames, setExistingUsernames] = useState([]);
    const [username, setUsername] = useState("");
    const global = useContext(GlobalContext);
    const [kanaliProdaje, setKanaliProdaje] = useState([]);
    const [kanalProdaje, setKanalProdaje] = useState({ KanalProdajeID: 0, Naziv: "Bez prodajnog posrednika" });
    const [message, setMessage] = useState("<div></div>");
    const [errorMessage, setErrorMessage] = useState(null);
    const [tipTvrtke, setTipTvrtke] = useState(null);
    const [tipoviTvrtki, setTipoviTvrtki] = useState([]);

    useConstructor(() => {
        api.get("registracija/getinfo", loadInfoSuccess, loadInfoFail);
    });

    function loadInfoSuccess(data) {
        setExistingUsernames(data.ExistingUsernames);
        data.KanaliProdaje.unshift({ KanalProdajeID: 0, Naziv: "Bez prodajnog posrednika" });
        setKanaliProdaje(data.KanaliProdaje);
        setMessage(data.RegistracijaFormMessage);
        setTipoviTvrtki(data.TipoviTvrtki);
        setLoading(false);
    }

    function loadInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function onMjestoInputChange(e, value) {
        setMjestoApi("/mjesta/search/" + value);
    }

    useEffect(() => {
        let valid = naziv && naziv.length >= 6 && naziv.length <= 70 && tipTvrtke && mjesto && adresa && adresa.length >= 6 && adresa.length <= 70 && ime && ime.length >= 2 && ime.length <= 40 && prezime && prezime.length >=3 && prezime.length <= 40 && email && Util.isValidEmail(email) && telefon && Util.isValidPhoneNumber(telefon) && username && Util.isValidNewUsername(username) && !existingUsernames.includes(username.trim().toLowerCase());
        setFormValid(valid);
    }, [naziv, mjesto, adresa, ime, prezime, email, telefon, username, tipTvrtke]);

    function submit() {
        let resource = {
            Naziv : naziv,
            OIB : oib,
            Adresa : adresa,
            Mjesto : mjesto,
            Ime : ime,
            Prezime : prezime,
            Email : email,
            Telefon : telefon,
            Username: username,
            KanalProdajeID : kanalProdaje.KanalProdajeID > 0 ? kanalProdaje.KanalProdajeID : null,
            TipTvrtkeID : tipTvrtke.TipTvrtkeID
        };
        api.post("registracija", resource, submitSuccess, submitFail);
    }

    function submitSuccess(data) {
        setUspjeh(data);
        setLoading(false);
    }

    function submitFail(data) {
        setGreska(data.Message);
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1} style={{maxWidth:"60vw"}}>
                <Grid item xs={12} style={{textAlign:"left", marginBottom:"15px"}}>
                    <Button variant="outlined" onClick={() => {window.location = "#/login"; global.setLoggedOutComponent("login");}} startIcon={<ArrowBackIcon></ArrowBackIcon>}>Prijava</Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            <AddBusinessIcon fontSize="medium" style={{marginRight:"15px"}}></AddBusinessIcon>
                            Registracija nove firme
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom component="div" style={{margin:0}}>
                        Podaci o firmi
                    </Typography>
                </Grid>
                <Grid item xs={12} marginBottom="10px">
                    <Divider></Divider>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField label={"Naziv"} inputProps={{ minLength: 6, maxLength: 70 }}  value={naziv} onChange={e => setNaziv(e.target.value)} error={!naziv || naziv.length < 6 || naziv > 70} size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField label={"OIB"}  value={oib} onChange={e => setOib(e.target.value)} error={!oib || !Util.OIBCheck(oib)} helperText={oib && !Util.OIBCheck(oib) ? "Neispravan OIB!" : ""} size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField label={"Adresa"} inputProps={{ minLength: 6, maxLength: 70 }} value={adresa} onChange={e => setAdresa(e.target.value)} error={!adresa || adresa.length < 6 || adresa > 70} size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <AutocompleteAsync
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={"klijent" + option.KlijentID}>
                                        {option.PostanskiBroj + " " + option.Naziv + ", " + option.DrzavaNaziv}
                                    </li>
                                );
                            }}
                            api={mjestoApi}
                            onChange={(e,v) => {if (v) setMjesto(v)}}
                            onInputChange={onMjestoInputChange}
                            size="small"
                            value={mjesto}
                            getOptionLabel={(option) => option.PostanskiBroj + " " + option.Naziv + ", " + option.DrzavaNaziv}
                            name="Mjesto"
                            error={!mjesto}
                            renderInput={(params) => <TextField {...params} label="Mjesto" error={!mjesto} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <Autocomplete
                            disablePortal
                            disableClearable
                            options={tipoviTvrtki}
                            getOptionLabel={(option) => option.Naziv}
                            value={tipTvrtke}
                            onChange={(e,v) => {
                                setTipTvrtke(v);
                            }}                           
                            renderInput={(params) => <TextField {...params} label="Vrsta firme" error={!tipTvrtke} size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} marginTop="15px">
                    <Typography variant="h6" gutterBottom component="div" style={{margin:0}}>
                        Podaci o korisniku
                    </Typography>
                </Grid>
                <Grid item xs={12} marginBottom="10px">
                    <Divider></Divider>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField label={"Ime"} inputProps={{ minLength: 2, maxLength: 40 }} value={ime} onChange={e => setIme(e.target.value)} error={!ime || ime.length < 2 || ime > 40} size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField label={"Prezime"} inputProps={{ minLength: 3, maxLength: 40 }} value={prezime} onChange={e => setPrezime(e.target.value)} error={!prezime || prezime.length < 3 || prezime > 40} size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Tooltip title="Započinje sa slovom, barem jedno slovo i broj, minimalno 8 znakova" arrow>
                            <TextField label={"Korisničko ime"} value={username} onChange={e => setUsername(e.target.value)} error={!username || existingUsernames.includes(username.trim().toLowerCase()) || !Util.isValidNewUsername(username)} helperText={existingUsernames.includes(username.trim().toLowerCase()) ? "Korisničko ime je zauzeto." : ""} size="small" onKeyDown={(event) => {if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {event.preventDefault();}}}/>
                        </Tooltip>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField label={"Telefon"} placeholder={"Telefon (3859xyyyyyy)"} value={telefon} onChange={e => setTelefon(e.target.value)} error={!telefon || !Util.isValidPhoneNumber(telefon)} size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField label={"Email"} value={email} onChange={e => setEmail(e.target.value)} error={!email || !Util.isValidEmail(email)} size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={12} marginTop="15px">
                    <Typography variant="h6" gutterBottom component="div" style={{margin:0}}>
                        Kako ste saznali za Chato?
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider></Divider>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <Autocomplete
                            disablePortal
                            disableClearable
                            hiddenLabel={true}
                            options={kanaliProdaje}
                            getOptionLabel={(option) => option.Naziv}
                            value={kanalProdaje}
                            onChange={(e,v) => {
                                setKanalProdaje(v);
                            }}                           
                            renderInput={(params) => <TextField {...params} label=""  size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Divider></Divider>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="p" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div dangerouslySetInnerHTML={{__html: message}}></div>
                    </Typography>                    
                </Grid>
                <Grid item xs={12}>
                    <Box style={{display:"flex", justifyContent:"center", marginTop:"15px"}}>
                        <Button variant="contained" onClick={submit} color="success" disabled={loading || !formValid} startIcon={<CheckCircleOutlineIcon></CheckCircleOutlineIcon>}>Prihvati</Button>
                    </Box>
                </Grid>
            </Grid>

            <Modal open={uspjeh != ""}>
                <Box sx={style} overflow={"auto"} >
                    <Grid container spacing={1}>
                        <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                            <CheckBoxIcon fontSize="large" color="success"></CheckBoxIcon>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography variant="h6" gutterBottom component="div">
                                {uspjeh}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} marginTop="15px">
                            <Box style={{display:"flex", justifyContent:"flex-end"}}>
                                <Button variant="contained" onClick={() => setUspjeh("")}>U redu</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <Modal open={greska != ""}>
                <Box sx={style} overflow={"auto"} >
                    <ErrorAlert message={greska} close={() => setGreska("")}></ErrorAlert>
                </Box>
            </Modal>
        </ComponentContainer>
    );
}

export default Registracija;