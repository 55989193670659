import { Box, Button, FormControl, FormLabel, Grid, InputAdornment, List, ListItem, ListItemText, Paper, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback } from "react";
import ComponentContainer from "./componentContainer";
import ExitToApp from "@mui/icons-material/ExitToApp";
import dateFormat, { masks } from "dateformat";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import Util from "../util/util";
import api from "../util/api";
import globalContext from "./globalContext";
import AlertDialog from "./alertdialog";
import { ResetTvRounded } from "@mui/icons-material";
import { textAlign } from "@mui/system";
import ErrorIcon from '@mui/icons-material/Error';

function RasporedPlacanjaForm(props) {
    
    const formatter = Util.formatter();
    const global = useContext(globalContext);

    const racuni = props.racuni;
    const formPlacanje = props.formPlacanje;
    const close = props.close;
    const refresh = props.refresh;
    const loading = props.loading;
    const setLoading = props.setLoading;


    const [currentRacun, setCurrentRacun] = useState(null);
    const [currentRaspored, setCurrentRaspored] = useState(null);
    const [rasporedeno, setRasporedeno] = useState(0);
    const [iznos, setIznos] = useState(0);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const useConstructor = Util.useConstructor();
    const [errorMessage, setErrorMessage] = useState(null);


    useConstructor(() => {
        if(racuni && racuni.length > 0){
            setCurrentRacun(racuni[0]);
        }
    });

    useEffect(() => {
        if (formPlacanje) {
            let newRasporedeno = 0;
            formPlacanje.RasporediPlacanja.forEach(rp => {
                newRasporedeno += rp.Iznos;
            });
            setRasporedeno(newRasporedeno);
            let nerasporedeno = formPlacanje.Ulaz + formPlacanje.Izlaz - newRasporedeno;
            let racunOtvoreno = 0;
            if(currentRacun) racunOtvoreno = Util.getRacunDetalji(currentRacun).Ostalo; //    currentRacun.Otvoreno;
            if(racunOtvoreno < nerasporedeno){
                setIznos(Util.toStringDecimal(racunOtvoreno));
            }
            else{
                setIznos(Util.toStringDecimal(nerasporedeno));
            }
        }
    }, [formPlacanje, currentRacun]);

    function checkIfIznosInRange(value) {
        let valueAsNumber = parseFloat(value);

        let racunOtvoreno = 0;
        if(currentRacun) racunOtvoreno = Util.getRacunDetalji(currentRacun).Ostalo; //currentRacun.Otvoreno;        
        let nerasporedeno = formPlacanje.Ulaz + formPlacanje.Izlaz - rasporedeno;

        if (isNaN(valueAsNumber) || valueAsNumber < 0) {
            setIznos(Util.toStringDecimal(0));
            return;
        }

        if(valueAsNumber <= nerasporedeno && valueAsNumber <= racunOtvoreno){
            //manji je iznos i od nerspoređenog dijela plaćanja i od otvorenog dijela računa, pa se to može još rasporediti
            setIznos(Util.toStringDecimal(valueAsNumber));
            return;
        }

        //iznos ne valja
        if(racunOtvoreno < nerasporedeno){
            setIznos(Util.toStringDecimal(racunOtvoreno));
        }
        else{
            setIznos(Util.toStringDecimal(nerasporedeno));
        }
        return;

        if (valueAsNumber > nerasporedeno) {
            setIznos(Util.toStringDecimal(nerasporedeno));
        }

    }

    function noviRaspored() {
        setLoading(true);
        let resource = {
            PlacanjeID : formPlacanje.PlacanjeID,
            RacunID : currentRacun.RacunID,
            Iznos : iznos,
            Datum : formPlacanje.Datum,
            KorisnikID : global.drugiZaposlenik.KorisnikID
        }
        api.post("placanje/noviraspored", resource, noviRasporedSuccess, noviRasporedFail);
    }

    function noviRasporedSuccess() {
        setCurrentRacun(null);
        setCurrentRaspored(null);
        refresh();
    }

    function noviRasporedFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function deleteConfirmed() {
        setLoading(true);
        api.get("placanje/deleteraspored/" + currentRaspored.RasporedPlacanjaID, deleteSuccess, deleteFail);
    }

    function deleteSuccess() {
        setDeleteAlert(false);
        refresh();
    }

    function deleteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function racunSelected(racun){
        setCurrentRacun(racun);
    }

    const handleKeyPressShorcuts = useCallback((event) => {
        if (event.altKey) {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if (charCode == 'z') {
                event.preventDefault();
                if (!loading) {
                    close();
                }
            }
            if (charCode == 'r') {
                event.preventDefault();
                if (!loading && currentRacun && iznos != 0 && iznos <= Math.abs(Util.getRacunDetalji(currentRacun).Ostalo)) {
                    noviRaspored();
                }
            }
        }     
    }, [
        loading, 
        currentRacun, 
        iznos,
        formPlacanje
    ]);
   
    const handleEsc = useCallback((event) => {
        if(event.keyCode == 27){
            event.preventDefault();
            if (!loading) {
                close();
            }            
        }
    }, [loading]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressShorcuts);
        document.addEventListener('keyup', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleKeyPressShorcuts);
            document.removeEventListener('keyup', handleEsc);
        };
    }, [handleKeyPressShorcuts]);



    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12} sx={{textAlign:"center"}}>
                    <Typography variant="h6" gutterBottom component="div" >
                        {"Raspoređivanje plaćanja: '" + formPlacanje.Opis + "' u iznosu od: " + (formPlacanje.Ulaz > 0 ? Util.toStringDecimal(formPlacanje.Ulaz) : Util.toStringDecimal(formPlacanje.Izlaz)) + " " + formPlacanje.FirmaValuta.FirmaValutaNaziv}
                    </Typography>    
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h7" gutterBottom component="div" sx={{textAlign:"center"}}>
                        Otvoreni {formPlacanje.Ulaz > 0 ? "izlazni" : "ulazni"} računi u valuti {formPlacanje.FirmaValuta.FirmaValutaNaziv}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={4} sx={{textAlign:"center"}}>
                    <Typography variant="h7" gutterBottom component="div" >
                        Prethodno raspoređeni iznosi ovog plaćanja
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <ListItem className="list-title" key="racuni-title" style={{backgroundColor:"lightblue"}}>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Tooltip title="ID" enterDelay={200} enterNextDelay={200}>
                                    <ListItemText style={{paddingLeft:"5px"}} primaryTypographyProps={{ noWrap:true}} primary="ID" />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4}>
                                <Tooltip title="Datum" enterDelay={200} enterNextDelay={200}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true}} primary="Datum" />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title="Opis" enterDelay={200} enterNextDelay={200}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true}} primary="Opis" />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title="Otvoreno" enterDelay={200} enterNextDelay={200}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ textAlign:"right", noWrap:true }} primary="Otvoreno" />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={2}>
                                <Tooltip title="Napomena" enterDelay={200} enterNextDelay={200}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true}} primary="Napomena" />
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                        <List dense>
                            {racuni.map((racun, index) => {
                                let racunDetalji = Util.getRacunDetalji(racun);
                                return <ListItem className="list-item" key={"racun" + index} style={{paddingTop:0, paddingBottom:0}}  onClick={() => racunSelected(racun)} >
                                        <Grid container spacing={1}>
                                            <Grid item xs={2}>
                                                <Tooltip title={racun.RacunID} enterDelay={200} enterNextDelay={200}>
                                                    <ListItemText style={{paddingLeft:"5px"}} primaryTypographyProps={{ noWrap:true, fontWeight: racun == currentRacun ? 700 : 400 }} primary={racun.RacunID} />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Tooltip title={dateFormat(racun.Datum, "dd.mm.yyyy hh:MM")} enterDelay={200} enterNextDelay={200}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: racun == currentRacun ? 700 : 400 }} primary={dateFormat(racun.Datum, "dd.mm.yyyy hh:MM")} />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Tooltip title={racun.Broj} enterDelay={200} enterNextDelay={200}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: racun == currentRacun ? 700 : 400 }} primary={racun.Broj} />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Tooltip title={formatter.format(racunDetalji.Ostalo)} enterDelay={200} enterNextDelay={200}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: racun == currentRacun ? 700 : 400 }} primary={formatter.format(racunDetalji.Ostalo)} />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Tooltip title={racun.Napomena} enterDelay={200} enterNextDelay={200}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: racun == currentRacun ? 700 : 400 }} primary={racun.Napomena} />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                            })}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={2} paddingLeft="15px" paddingRight="15px">
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField 
                                    size="small" 
                                    value={formatter.format(formPlacanje.Ulaz + formPlacanje.Izlaz)} 
                                    label="Ukupni iznos plaćanja"
                                    InputProps={{ 
                                        readOnly: true,
                                        endAdornment: 
                                            <InputAdornment position="end">
                                                {formPlacanje.FirmaValuta.FirmaValutaNaziv}
                                            </InputAdornment>}}
                                    inputProps={{style:{textAlign:"right"}}}
                                ></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} marginTop="5px">
                            <FormControl fullWidth>
                                <TextField 
                                    size="small" 
                                    label="Raspoređeno" 
                                    InputProps={{ 
                                        readOnly: true,
                                        endAdornment: 
                                            <InputAdornment position="end">
                                                {formPlacanje.FirmaValuta.FirmaValutaNaziv}
                                            </InputAdornment>}}
                                    inputProps={{style:{textAlign:"right"}}}
                                    value={formatter.format(rasporedeno)}
                                ></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} marginTop="30px">
                            <FormControl fullWidth>
                                <TextField 
                                    size="small" 
                                    label="Iznos" 
                                    type="number"
                                    value={iznos}
                                    InputProps={{ 
                                        endAdornment: 
                                            <InputAdornment position="end">
                                                {formPlacanje.FirmaValuta.FirmaValutaNaziv}
                                            </InputAdornment>}}
                                    onChange={e => {
                                        if (!isNaN(parseFloat(e.target.value))) {
                                            setIznos(parseFloat(e.target.value));
                                        } else if (e.target.value == "") {
                                            setIznos(0);
                                        }}}
                                    inputProps={{
                                        style: {textAlign:"right"},
                                        min: 0, 
                                        max: formPlacanje.Ulaz + formPlacanje.Izlaz - rasporedeno}}
                                    onBlur={e => checkIfIznosInRange(e.target.value)}
                                ></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} marginTop="5px">
                            <Button 
                                style={{width:"100%"}} 
                                onClick={noviRaspored} 
                                variant="contained" 
                                color="success" 
                                startIcon={<EastIcon></EastIcon>}
                                disabled={!currentRacun || formPlacanje.PrisilnoZatvoreno || iznos == 0 || iznos > Math.abs(Util.getRacunDetalji(currentRacun).Ostalo)}
                            >R&#818;asporedi</Button>
                        </Grid>
                        <Grid item xs={12} marginTop="5px">
                            <Button style={{width:"100%"}} disabled={!currentRaspored || formPlacanje.PrisilnoZatvoreno || currentRaspored.RacunPrisilnoZatvoren} onClick={() => setDeleteAlert(true)} variant="outlined" color="error" startIcon={<WestIcon></WestIcon>}>Obriši</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="rasporedi-title" style={{backgroundColor:"lightblue"}}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Tooltip title="Račun" enterDelay={200} enterNextDelay={200}>
                                        <ListItemText primaryTypographyProps={{ noWrap:true}} primary="Račun" />
                                    </Tooltip>
                                </Grid>
                                <Grid item xs={6}>
                                    <Tooltip title="Dokument" enterDelay={200} enterNextDelay={200}>
                                        <ListItemText style={{paddingLeft:"10px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right" }} primary="Iznos" />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {formPlacanje.RasporediPlacanja.map((raspored, index) => {
                                    return <ListItem className="list-item" key={"raspored" + index} style={{paddingTop:0, paddingBottom:0}}  onClick={() => setCurrentRaspored(raspored)} >
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <Tooltip title={raspored.RacunBroj} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText primaryTypographyProps={{ noWrap:true, fontWeight: raspored == currentRaspored ? 700 : 400 }} primary={raspored.RacunBroj} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Tooltip title={formatter.format(raspored.Iznos)} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText style={{paddingLeft:"10px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: raspored == currentRaspored ? 700 : 400 }} primary={formatter.format(raspored.Iznos)} />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                </Grid>
                <Grid xs={12}>
                    <Box style={{display:"flex", justifyContent:"flex-end"}}>
                        <Button variant="outlined" color="error" onClick={close} ><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Z&#818;atvori</Button>
                    </Box>
                </Grid>
            </Grid>

            {deleteAlert ? <AlertDialog 
                                title="Brisanje rasporeda plaćanja"
                                message={"Jeste li sigurni da želite obrisati odabrani raspored plaćanja?"} 
                                cancelLabel={"Odustani"} 
                                confirmLabel={"Obriši"} 
                                cancelAction={() => setDeleteAlert(false)} 
                                confirmAction={deleteConfirmed}>
                            </AlertDialog> : null}
               
            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default RasporedPlacanjaForm;