import { Button, FormControlLabel, Grid, Switch } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import ArhivaFileForm from "./arhivaFileForm";
import ComponentContainer from "./componentContainer";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Util from "../util/util";
import globalContext from "./globalContext";
import api from "../util/api";
import ArhivaNeelektronickaForm from "./arhivaNeelektronickaForm";

function ArhivaForm(props) {

    ;
    const setFormOpened = props.setFormOpened;
    const useConstructor = Util.useConstructor();
    const formArhiva = props.formArhivaMaster;
    const global = useContext(globalContext);
    const [tipArhiveOptions, setTipArhiveOptions] = useState([]);
    const [elektronicka, setElektronicka] = useState(false);
    const tipArhive = props.tipArhive;
    const predmetID = props.predmetID;
    const predmetNaziv = props.predmetNaziv;
    const klijentID = props.klijentID;
    const genericUsage = props.genericUsage;
    const predmetTipStanjaPredmetaID = props.predmetTipStanjaPredmetaID;

    useConstructor(() => {
        setElektronicka(formArhiva ? formArhiva.Elektronicka : tipArhive.Elektronicka);
    });

    function save() {

    }

    return (
        <ComponentContainer>

            <Grid container spacing={1}>
                {/* <Grid item xs={12}>
                    
                </Grid> */}
                <Grid item xs={12}>
                    { elektronicka ? (
                        <ArhivaFileForm newMaster={true} setCanceled={props.setCanceled} setFormOpened={setFormOpened} formArhiva={formArhiva} predmetID={predmetID} klijentID={klijentID} tipArhiveID={tipArhive ? tipArhive.TipArhiveID : null} tipArhiveNaziv={tipArhive ? tipArhive.Naziv : null} predmetNaziv={predmetNaziv}></ArhivaFileForm>
                    ) : (
                        <ArhivaNeelektronickaForm predmetTipStanjaPredmetaID={predmetTipStanjaPredmetaID} brojRegistratora={props.brojRegistratora} setCanceled={props.setCanceled} setFormOpened={setFormOpened} formArhiva={formArhiva} predmetID={predmetID} klijentID={klijentID} tipArhiveID={tipArhive ? tipArhive.TipArhiveID : null} tipArhiveOznaka={tipArhive ? tipArhive.Oznaka : null} tipArhiveNaziv={tipArhive ? tipArhive.Naziv : null} predmetNaziv={predmetNaziv}></ArhivaNeelektronickaForm>
                    )}
                </Grid>
            </Grid>           

        </ComponentContainer>

    );

}

export default ArhivaForm;