import { useState, useRef, useContext, useEffect, React } from "react";
import { Line } from "@ant-design/charts";
import { AccessAlarm, SettingsRemoteRounded, ThreeDRotation } from "@mui/icons-material";
import api from "../util/api";
import Button from "@mui/material/Button";
import Util from "../util/util";
import GlobalContext from "../components/globalContext";
import ComponentContainer from "../components/componentContainer";
import TextField from "@mui/material/TextField";
import { FormControl, FormGroup } from "@mui/material";
import Box from "@mui/material/Box";
import database from "../util/database";
import Grid from '@mui/material/Grid';

function SampleForm(props) {
    const useConstructor = Util.useConstructor();
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [someList, setSomeList] = useState([1, 2, 3]);    
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState();


    useConstructor(() => {
        let data = {};
        setLoading(false);
        api.post("", data, success, fail);
    });

    function success(data) {
        setLoading(false);
    }

    function fail(data) {
        setLoading(false);
    }

    function onPasswordChange(e){
        setPassword(e.target.value);
        if(e.target.value.length < 6){
            setPasswordError("Password must be at least 6 characters");
        }else{
            setPasswordError("");
        } 
    }

    return (
        <ComponentContainer loading={loading}>
            <Box sx={{ width: "60%"}}>
                {someList.map((item, index) => {
                    return (
                        <FormControl key={index}>
                            <TextField label={"text-box" + index} placeholder={"text-box" + index} multiline />
                        </FormControl>
                    );
                })}
                <FormGroup>
                    <FormControl>
                        <TextField label={"Username *"} placeholder={"Username"} />
                    </FormControl>
                    <FormControl>
                        <TextField onChange={onPasswordChange} label={"Password *"} placeholder={"Password"} error={passwordError} helperText={passwordError}/>
                    </FormControl>
                </FormGroup>
                <FormControl>
                    <Button
                        variant="contained"
                        onClick={() => {
                            alert("click");
                        }}
                    >
                        Click me! <AccessAlarm />
                    </Button>
                </FormControl>
                <FormControl>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setLoading(true);
                            setTimeout(()=>{setLoading(false)}, 2000);
                        }}
                    >
                        LOAD 2 SEC <ThreeDRotation />
                    </Button>
                </FormControl>
            </Box>
        </ComponentContainer>
    );
}
export default SampleForm;
