import { Box, ButtonBase, ClickAwayListener, Container, Divider, Grid, Hidden, Icon, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, TextField, Tooltip, Typography, InputAdornment, FormControlLabel, Checkbox } from "@mui/material";
import { Button } from "@mui/material";
import { List } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import InfoIcon from '@mui/icons-material/Info';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import dateFormat, { masks } from "dateformat";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import RacunListItem from "./racunListItem";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';

function AddEditDelForm(props) {

    const useConstructor = Util.useConstructor();

    const add = props.add;
    const edit = props.edit;
    const del = props.del;
    const setCurrent = props.setCurrent;
    const handleSearchTextChange = props.handleSearchTextChange;
    const deleteSearchText = props.deleteSearchText;
    const dataType = props.dataType;
    const current = props.current;
    const loading = props.loading;
    const disableButtons = props.disableButtons;
    const disableDeleteButton = props.disableDeleteButton;
    const disableAddButton = props.disableAddButton;
    const fiksnaNaplata = props.fiksnaNaplata;
    const disableEdit = props.disableEdit;
    const data = props.data || [];
    const setData = props.setData;
    const disableTakeALook = props.disableTakeALook;
    const global = useContext(globalContext);
    let isMobileDevice = Util.isMobileDevice();
    const formatter = Util.formatter();
    const bottomRef = useRef(null);
    const searchText = props.searchTextValue;
    const filterModelRef = props.filterModelRef;
    const filterModel = props.filterModel;
    const setFilterModel = props.setFilterModel;
    const sortModelRef = props.sortModelRef;
    const sortModel = props.sortModel;
    const setSortModel = props.setSortModel;
    const page = props.page;
    const setPage = props.setPage;
    const pageSize = props.pageSize;
    const setPageSize = props.setPageSize;
    const limitPageSize = props.limitPageSize;
    const noRowsMessage = props.noRowsMessage;
    const deleteText = props.deleteText;

    let canModify;
    
    switch (dataType) {
        case "klijenti":
        case "kontakti":
            canModify = global.roles.includes("18");
            break;
        case "predmeti":
        case "cjenik":
            canModify = global.roles.includes("19");
            break;
        case "grupa":
        case "konto":
        case "mjesto":
        case "naciniplacanja":
        case "nepredmetneKategorije":
        case "odjel":
        case "poslovniProstori":
        case "blagajne":
        case "satniceRada":
        case "satnicaRadaDetalji":
        case "tipkorisnika":
            canModify = global.roles.includes("20");
            break;
        default:
            canModify = true;
            break;
    }

    const setRowStyle = (record, index) => ({
        backgroundColor: index == 2 ? 'blue' : 'red'
    })

    useConstructor(() => {

    });

    function handleClick(item) {
        setCurrent(item);
    };

    function izracunajTrajanje(start, end) {
        let datumDo = new Date(end);
        let datumOd = new Date(start);
        let diff = datumDo.getTime() - datumOd.getTime();
        return diff / 60000;
    }

    function izracunajIznosRacunskeStavke(stavka) {
        var iznos = 0;
        if (stavka.TipRacunskeStavkeID == 1) {
            stavka.StavkeRacunskeStavke.forEach(stavkaRacunskeStavke => {
                if (stavkaRacunskeStavke.TipNaplatnostiID == 1) {
                    iznos += stavkaRacunskeStavke.Vrijednost * stavkaRacunskeStavke.Kolicina;
                }
            })
        } else {
            if (stavka.TipNaplatnostiID == 1) {
                iznos = stavka.Vrijednost * stavka.Kolicina;
            }
        }
        return Math.round((iznos + Number.EPSILON) * 100) / 100;
        //return iznos;
    }

    function izracunajIznosRacuna(racun) {
        var iznos = 0;
        racun.RacunskeStavke.forEach(stavka => {
            iznos += izracunajIznosRacunskeStavke(stavka);
        });
        return Math.round((iznos + Number.EPSILON) * 100) / 100;
    }

    function izracunajPorezRacunskeStavke(stavka) {
        var porez = 0;
        if (stavka.TipRacunskeStavkeID == 1) {
            stavka.StavkeRacunskeStavke.forEach(stavkaRacunskeStavke => {
                if (stavkaRacunskeStavke.TipNaplatnostiID == 1) {
                    porez += stavkaRacunskeStavke.Vrijednost * stavkaRacunskeStavke.TipOporezivosti.Stopa;
                }
            })
        } else {
            if (stavka.TipNaplatnostiID == 1) {
                porez = stavka.Vrijednost * stavka.TipOporezivosti.Stopa;
            }
        }
        return porez;
    }

    function izracunajPorezRacuna(racun) {
        var porez = 0;
        racun.RacunskeStavke.forEach(stavka => {
            porez += izracunajPorezRacunskeStavke(stavka);
        });
        return Math.round((porez + Number.EPSILON) * 100) / 100;
    }

    function getRacunDetalji(racun) {
        let iznos = izracunajIznosRacuna(racun);
        let porez = izracunajPorezRacuna(racun);
        return {
            Iznos : iznos,
            Porez : porez,
            Ukupno : Math.round((iznos + porez + Number.EPSILON) * 100) / 100
        };
    }

    function getRacunskaStavkaDetalji(stavka) {
        return {
            Kolicina : stavka.TipRacunskeStavkeID == 1 ? 1 : (stavka.Kolicina ? Math.round((stavka.Kolicina + Number.EPSILON) * 100) / 100 : "0.00"),
            Cijena : stavka.TipRacunskeStavkeID == 1 ? "" : (stavka.Cijena ? Math.round((stavka.Cijena + Number.EPSILON) * 100) / 100 : "0.00"),
            Ukupno : stavka.TipRacunskeStavkeID == 1 ? "" : (stavka.Kolicina && stavka.Cijena ? Math.round((stavka.Kolicina * stavka.Cijena + Number.EPSILON) * 100) / 100 : "0.00"),
            Rabat : stavka.TipRacunskeStavkeID == 1 ? "" : (stavka.Rabat ? Math.round((stavka.Rabat + Number.EPSILON) * 100) / 100 : "0.00"),
            Marza : stavka.TipRacunskeStavkeID == 1 ? "" : (stavka.Marza ? Math.round((stavka.Marza + Number.EPSILON) * 100) / 100 : "0.00"),
            Iznos : stavka.TipRacunskeStavkeID == 1 ? "" : stavka.Kolicina && stavka.Cijena ? ((stavka.Kolicina * stavka.Cijena + ((stavka.Marza || 0) / 100) * stavka.Kolicina * stavka.Cijena) * (1 - ((stavka.Rabat || 0) / 100))).toFixed(2) : "0,00"
        };
    }

    function sortedRacunskeStavke() {
        let sortedRacunskeStavke = [...data];
        sortedRacunskeStavke.sort((s1, s2) => s1.RedniBroj - s2.RedniBroj);
        return sortedRacunskeStavke;
    }

    function sortedStavkeSpecifikacije() {
        let sortedStavkeSpecifikacije = [...data];
        sortedStavkeSpecifikacije.sort((s1,s2) => new Date(s1.Datum) - new Date(s2.Datum));
        return sortedStavkeSpecifikacije;
    }
    
    function pomakniRacunskuStavkuGore(redniBroj) {
        ;
        let newData = [...data];
        let racunskaStavkaPrije = newData.find(stavka => stavka.RedniBroj == redniBroj - 1);
        let racunskaStavka = newData.find(stavka => stavka.RedniBroj == redniBroj);
        if (racunskaStavkaPrije) {
            racunskaStavkaPrije["RedniBroj"] = redniBroj;
            racunskaStavka["RedniBroj"] = redniBroj - 1;
            setData(newData);
        }
    }

    function pomakniRacunskuStavkuDolje(redniBroj) {
        let newData = [...data];
        let racunskaStavkaPoslije = newData.find(stavka => stavka.RedniBroj == redniBroj + 1);
        let racunskaStavka = newData.find(stavka => stavka.RedniBroj == redniBroj);
        if (racunskaStavkaPoslije) {
            racunskaStavkaPoslije["RedniBroj"] = redniBroj;
            racunskaStavka["RedniBroj"] = redniBroj + 1;
            setData(newData);
        }
    }

    const localizedTextsMap = {
        columnMenuFilter: "filtriraj",
        filterOperatorEquals: 'jednako',
      };

    // function compareStrings(a, b) {
    //     ;
    //     if ((a || "") > (b || "")) {
    //         return 1;
    //     } else if ((a || "") < (b || "")) {
    //         return -1;
    //     } else {
    //         return 0;
    //     }
    // }

    // function compareNumbers(a, b) {
    //     ;
    //     if ((a || 0) > (b || 0)) {
    //         return 1;
    //     } else if ((a || 0) < (b || 0)) {
    //         return -1;
    //     } else {
    //         return 0;
    //     }
    // }

    // function sortRacuni(field) {
    //     if (field == "ukupno") {
    //         let newData = [...data];
    //         newData.sort((prvi, drugi) => compareNumbers(getRacunDetalji(prvi).Ukupno, getRacunDetalji(drugi).Ukupno));
    //         setData(newData);
    //     } else if (field=="broj") {
    //         let newData = [...data];
    //         newData.sort((prvi, drugi) => compareStrings(prvi.Broj, drugi.Broj));
    //         setData(newData);
    //     }
    // }

    useEffect(() => {
        // if(props.setLoading && props.loading){
        //     props.setLoading(false);
        // }
        bottomRef.current?.scrollIntoView({behavior: 'instant', block: 'nearest', inline: 'start' });

      }, [data]);
    
    // useEffect(() => {
    //     alert(current);
    // }, [current])

    useEffect(() => {
        if (dataType=="evidencije" && current) {
            const element = document.getElementById(current.EvidencijaID);
            if (element) {
                element.scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' });
            }
        }
    }, [data, current]);

    // useEffect(() => {
    //     alert(page)
    // }, [page])

    return (
        
        <ComponentContainer loading={loading}>
            {dataType === "klijenti" ? (
                <Box display="flex" style={{width:"100%"}} justifyContent="space-around">
                    <IconButton aria-label="search" style={{width: "5%"}} onClick={deleteSearchText}>
                        <CloseIcon style={{ fill: "#1976d2" }} />
                    </IconButton>
                    <TextField 
                        
                        inputRef={props.searchRef}
                        onFocus={(e) => e.target.select()}
                        className="search-bar" 
                        label="Pretraži" 
                        variant="standard" 
                        style={{width: "95%"}}
                        onChange={handleSearchTextChange} 
                        value={searchText}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                            ),
                        }}>                     
                    </TextField>
                    {/* <SearchIcon style={{ fill: "#1976d2", width: "5%" }} /> */}
                </Box>
            ) : null}

            
            {dataType === "klijenti" && data && data.length != 0 ? (
                <Box style={{width:"100%"}}>
                    <div style={{height:"calc(100vh - 270px)", width: '100%' }}>
                        <DataGrid
                            onPageSizeChange= {(pgsz, details) => {
                                setPageSize(pgsz)
                            }}
                            onPageChange={(p, details) => {
                                setPage(p)
                            }}
                            {...(limitPageSize ? {
                                pageSize: pageSize || 100,
                                page: page,
                            } : {})}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            headerHeight={40}
                            footerHeight={40}
                            rowHeight={30}
                            rows={data}
                            getRowId={row => row.KlijentID}
                            sx={{
                                ".closed": {
                                    color: "red",
                                },
                                ".normal": {
                                    color: "black",
                                },
                                cursor:'pointer'                                
                            }}                                
                            getRowClassName={(params) => {
                                return params.row.TipStatusaKlijentaID !== 1 ? "closed" : "normal";
                            }}  
                            columns={[
                                {field: 'Broj',
                                headerName: 'Broj',
                                width: 150},
                                {field: 'Naziv',
                                headerName: 'Naziv',
                                width: 450},
                                {field: 'MjestoNaziv',
                                headerName: 'Mjesto',
                                width: 200},
                                {field: 'Napomena',
                                headerName: 'Napomena',
                                renderCell: params => (
                                    <Tooltip title={params.value} >
                                        <span>{params.value}</span>
                                    </Tooltip>
                                ), 
                                width: 400}
                            ]}
                            localeText={Util.dataGridHrvatski}
                            // onRowClick={r => setCurrent(r.row)}
                            onRowDoubleClick={edit}
                            {...(current ? {
                                selectionModel: current.KlijentID
                            } : {
                                onRowClick: r => setCurrent(r.row)
                            })}
                            onSelectionModelChange={kID => setCurrent(data.find(k => k.KlijentID == kID))}
                            filterModel={filterModel}
                            onFilterModelChange={newFilterModel => {
                                setFilterModel(newFilterModel);
                            }}
                            sortModel={sortModel}
                            onSortModelChange={(newSortModel) => {
                                setSortModel(newSortModel);
                            }}
                        />
                    </div>
                </Box>
            ) : null}
                
            {dataType === "predmeti" && data && data.length != 0 && !isMobileDevice ? (
                <Box style={{width:"100%", height:"100%"}}>
                    <div style={{ height: 'calc(100vh - 270px)', width: '100%' }}>
                        <DataGrid
                            onPageSizeChange= {(pgsz, details) => {
                                setPageSize(pgsz)
                            }}
                            onPageChange={(p, details) => {
                                setPage(p)
                            }}
                            {...(limitPageSize ? {
                                pageSize: pageSize || 100,
                                page: page,
                                rowsPerPageOptions: [5, 10, 100]
                            } : {
                                rowsPerPageOptions: [5, 10, 100, 200, 1000]
                            })}
                            headerHeight={40}
                            footerHeight={40}
                            rowHeight={30}
                            rows={data}
                            // rowsPerPageOptions={[5, 10, 100, 200, {label:'Svi', value: -1}]} //ne radi dobro "Svi"
                            getRowId={row => row.PredmetID}
                            // rowStyle={setRowStyle}
                            sx={{
                                ".closed": {
                                    color: "red",
                                },
                                ".normal": {
                                    color: "black",
                                },                                
                                ".fixed": {
                                    color: "green",
                                },                                  
                                ".tariff": {
                                    color: "magenta",
                                },
                                cursor:'pointer' 
                            }}                                
                            getRowClassName={(params) => {
                                return params.row.TipStanjaPredmetaID !== 1 ? "closed" : (params.row.TipNaplateID === 3 ? "fixed" : (params.row.TipNaplateID === 2 ? "tariff" : "normal"));
                            }}                                
                            columns={[
                                {field: 'Broj',
                                headerName: 'Broj',
                                width: 75},
                                {field: 'Naziv',
                                headerName: 'Naziv',
                                renderCell: params => (
                                    <Tooltip title={params.row.TipNaplateID == 1 ? params.row.Naziv : (params.row.TipNaplateID == 2 ? params.row.Naziv : params.row.Naziv + (params.row.Iznos ? (' - ' + Util.toStringDecimal(params.row.Iznos) + ' ' + params.row.FirmaValuta.FirmaValutaNaziv + ' (' + params.row.TipPeriodiciteta.Naziv + ')') : ' - nije definiran iznos'))} >
                                        <span>{params.row.TipNaplateID == 1 ? (params.row.Cap ? params.row.Naziv + ' - Cap/Max: ' + Util.toStringDecimal(params.row.Iznos) + ' ' + params.row.FirmaValuta.FirmaValutaNaziv : params.row.Naziv) : (params.row.TipNaplateID == 2 ? params.row.Naziv : (params.row.Iznos ? (params.row.Naziv + ' - ' + Util.toStringDecimal(params.row.Iznos) + ' ' + params.row.FirmaValuta.FirmaValutaNaziv + ' (' + params.row.TipPeriodiciteta.Naziv + ')') : <span style={{backgroundColor:'yellow'}}><b>{params.row.Naziv + ' - nije definiran iznos'}</b></span>))}</span>
                                    </Tooltip>
                                ),                                    
                                width: 650},
                                {field: 'KlijentNaziv',
                                headerName: 'Klijent',
                                renderCell: params => (
                                    <Tooltip title={params.value} >
                                        <span>{params.value}</span>
                                    </Tooltip>
                                ),                                     
                                width: 250},
                                {field: 'DatumOtvaranja',
                                headerName: 'Datum otvaranja',
                                renderCell: params => (
                                    <span>
                                        {dateFormat(params.value, "dd.mm.yyyy")}
                                    </span>
                                ),
                                width: 125},
                                {field: 'BrojRegistratora',
                                headerName: 'Količina',
                                width: 65,
                                align: 'right'},                                    
                                {field: 'Opaske',
                                headerName: 'Opaske',
                                renderCell: params => (
                                    <Tooltip title={params.value} >
                                        <span>{params.value}</span>
                                    </Tooltip>
                                ), 
                                width: 300},
                                {field: 'SudskiBroj',
                                headerName: 'Sudski broj',
                                width: 175,
                                hide: global.user.TipTvrtkeID == "1"}
                            ]}
                            localeText={Util.dataGridHrvatski}
                            // onRowClick={r => setCurrent(r.row)}
                            onRowDoubleClick={edit}
                            {...(current ? {
                                selectionModel: current.PredmetID
                            } : {
                                onRowClick: r => setCurrent(r.row)
                            })}
                            onSelectionModelChange={pID => setCurrent(data.find(p => p.PredmetID == pID))}
                            filterModel={filterModel}
                            onFilterModelChange={newFilterModel => {
                                filterModelRef.current = newFilterModel;
                                setFilterModel(newFilterModel);
                            }}
                            sortModel={sortModel}
                            onSortModelChange={(newSortModel) => {
                                sortModelRef.current = newSortModel;
                                setSortModel(newSortModel);
                            }}
                        />
                    </div>
                </Box>
            ) : null}

            {dataType === "predmeti" && data && data.length != 0 && isMobileDevice ? (
                <Box style={{width:"100%"}}>
                    {/* <ListItem className="list-title" key={"listHeader"} >
                        <ListItemText style={{width:"10%"}} primaryTypographyProps={{ noWrap:true }} primary={"Broj"} />
                        <ListItemText style={{width:"90%"}} primaryTypographyProps={{ noWrap:true }} primary={"Naziv"} />
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                        <List dense>
                            {data.map((item, index) => {
                                return (
                                    <ListItem className="list-item" key={"listItem" + index} style={{paddingTop:0, paddingBottom:0}} onClick={() => handleClick(item)} onDoubleClick={edit} >
                                            <ListItemText style={{width:"10%"}} primary={(current == item) ? <b> {item.Broj} </b> : item.Broj} />
                                            <Divider orientation="vertical" flexItem />
                                            <ListItemText style={{width:"90%", paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? <b> {item.Naziv} </b> : item.Naziv} />      
                                    </ListItem>
                            );})}
                        </List>
                    </Paper> */}
                    <div>
                        <DataGrid
                            rows={data}
                            getRowId={row => row.PredmetID}
                            columns={[{field: 'PredmetID',
                            headerName: 'PredmetID',
                            width: 150}]}
                            localeText={localizedTextsMap}
                            onRowClick={r => setCurrent(r.row)}
                        />
                    </div>

                </Box>
            ) : null}
                            
            {dataType === "cjenik" && data && data.length != 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key={"listHeader"} >
                        <ListItemText style={{width:"50%"}} primaryTypographyProps={{ noWrap:true }} primary={"Tip korisnika"} />
                        <ListItemText style={{width:"25%", textAlign:"right", paddingRight:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Iznos"} />
                        <ListItemText style={{width:"25%", paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Valuta"} />
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 400, overflow: 'auto'}}>
                        <List dense>
                            {data.map((item, index) => {
                                return <ListItem className="list-item" key={"listItem" + index} style={{paddingTop:0, paddingBottom:0,  backgroundColor: item == current ? 'rgba(25, 118, 210, 0.08)' : 'white'}} onClick={() => handleClick(item)} onDoubleClick={edit}>
                                            <ListItemText style={{width:"50%"}} primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? <b> {item.TipKorisnika} </b> : item.TipKorisnika} />
                                            <Divider orientation="vertical" flexItem />
                                            <ListItemText style={{width:"25%", textAlign:"right", paddingRight:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={current == item ? <b>{item.Iznos ? Util.toStringDecimal(item.Iznos) : ""}</b> : item.Iznos ? Util.toStringDecimal(item.Iznos) : ""} />
                                            <Divider orientation="vertical" flexItem />
                                            <ListItemText style={{width:"25%", paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={current == item ? <b>{item.ValutaKratica ? item.ValutaKratica : ""}</b> : item.ValutaKratica ? item.ValutaKratica : ""} />
                                    </ListItem>;
                            })}
                        </List>
                    </Paper>
                </Box>
            ) : null}

            {dataType === "evidencije" && data && data.length != 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key={"listHeader"} >
                        <Grid container>
                            <Grid item xs={6} sm={6} md={4} lg={4} sx={{display: { xs: "none", sm: "none", md:"none", lg: "block" }}}>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Grid container>
                                            <Grid item sm={4} sx={{display: { xs: "none", sm: "block"}}}>
                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Tip"} />
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={8} lg={8}>
                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Datum"} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {global.user.tipPrikazaSatnice == 2 ? (
                                        <Grid item xs={4} sx={{display: { xs: "none", sm: "none", md:"none", lg: "block" }}}>
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Trajanje"} />
                                        </Grid>
                                    ) : null}
                                    {global.user.tipPrikazaSatnice == 1 ? (
                                        <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"none", lg: "block" }}}>
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Početak"} />
                                        </Grid>
                                    ) : null}
                                    {global.user.tipPrikazaSatnice == 1 ? (
                                        <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"none", lg: "block" }}}>
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Kraj"} />
                                        </Grid>
                                    ) : null}
                                    <Grid item xs={4} sx={{display: { xs: "none", sm: "none", md:"none", lg: "block" }}}>
                                        <ListItemText style={{paddingLeft:"15px", textAlign:"right"}} primaryTypographyProps={{ noWrap:true }} primary={"Iznos"} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={5} sx={{display: { xs: "block", sm: "block", md:"block", lg: "none" }}}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Datum"} />
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={7}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Opis"} /> 
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1} sx={{display: { xs: "none", sm: "none", md:"none", lg: "block" }}}>
                                <ListItemText style={{textAlign:"center"}} primaryTypographyProps={{ noWrap:true }} primary={"Preneseno"} />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Paper variant="outlined" sx={{maxHeight: {xs:150, sm:150, md:300}}} style={{width:"100%", overflow: 'auto'}}>
                        <List dense>
                            {data.map((item, index) => {
                                return (<ListItem className="list-item" id={item.EvidencijaID} key={"listItem" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: current == item ? 'rgba(25, 118, 210, 0.08)' : 'white'}} onClick={() => handleClick(item)} onDoubleClick={() => {if (!disableEdit) edit()}}>
                                            <Grid container>
                                                <Grid item xs={6} sm={6} md={4} lg={4} sx={{display: { xs: "none", sm: "none", md:"none", lg: "block" }}}>
                                                    <Grid container>
                                                        <Grid item xs={4}>
                                                            <Grid container>
                                                                <Grid item sm={4} sx={{display: { xs: "none", sm: "block"}}} display="flex" alignItems="center">
                                                                    <Tooltip title={item.TipEvidencijeID == 1 ? (item.TipPredmetnostiID == 1 ? "Aktivnost" : item.NepredmetnaKategorijaNaziv) : "Trošak"} enterDelay={300} enterNextDelay={300}>
                                                                        <ListItemIcon style={{paddingLeft:"15px", height:"100%", display:"flex", alignItems:"center"}}>
                                                                            <Brightness1Icon sx={{ color: item.TipEvidencijeID == 1 ? ("rgb(" + (item.TipPredmetnostiID == 1 ? "255,0,255" : item.NepredmetnaKategorijaBoja) + ")") : "darkblue" }}></Brightness1Icon>
                                                                        </ListItemIcon>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={12} sm={8} md={8} lg={8}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? <b> {dateFormat(item.PocetakRada, "dd.mm.yyyy")} </b> : dateFormat(item.PocetakRada, "dd.mm.yyyy")} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {global.user.tipPrikazaSatnice == 2 ? (
                                                            <Grid item xs={4} sx={{display: { xs: "none", sm: "none", md:"none", lg: "block" }}}>
                                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400 }} primary={item.TipEvidencijeID == 1 ? ((Math.floor(parseInt(izracunajTrajanje(item.PocetakRada, item.KrajRada) + " min") / 60) > 0 ? (Math.floor(parseInt(izracunajTrajanje(item.PocetakRada, item.KrajRada) + " min") / 60) + " h ") : "") + parseInt(izracunajTrajanje(item.PocetakRada, item.KrajRada) + " min") % 60 + " min" + " (" + izracunajTrajanje(item.PocetakRada, item.KrajRada) + " min" + ")") : ""} />
                                                            </Grid>
                                                        ) : null}
                                                        {global.user.tipPrikazaSatnice == 1 ? (
                                                            <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"none", lg: "block" }}}>
                                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? <b> {item.TipEvidencijeID == 1 && item.PocetakRada.substring(11,16)} </b> : item.TipEvidencijeID == 1 && item.PocetakRada.substring(11,16)} />
                                                            </Grid>
                                                        ) : null}
                                                        {global.user.tipPrikazaSatnice == 1 ? (
                                                            <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"none", lg: "block" }}}>
                                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? <b> {item.TipEvidencijeID == 1 && item.KrajRada.substring(11,16)} </b> : item.TipEvidencijeID == 1 && item.KrajRada.substring(11,16)} />  
                                                            </Grid>
                                                        ) : null}
                                                        <Tooltip title={item.Iznos} enterDelay={200} enterNextDelay={200}>
                                                            <Grid item xs={4} sx={{display: { xs: "none", sm: "none", md:"none", lg: "block" }}}>
                                                                <ListItemText style={{textAlign:"right", paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400 }} primary={item.Iznos} />
                                                            </Grid>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={5} sx={{display: { xs: "block", sm: "block", md:"block", lg: "none" }}}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? <b> {dateFormat(item.PocetakRada, "dd.mm.yyyy")} </b> : dateFormat(item.PocetakRada, "dd.mm.yyyy")} />
                                                </Grid>
                                                <Grid item xs={7} sm={7} md={7} lg={7}>
                                                    <Tooltip title={item.Opis} enterDelay={300} enterNextDelay={300}>
                                                        <ListItemText style={{ paddingLeft:"15px", width:"90%"}} primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? <b> {item.Opis} </b> : item.Opis} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={1} sm={1} md={1} lg={1} sx={{display: { xs: "none", sm: "none", md:"none", lg: "block" }}}>
                                                    <ListItemIcon style={{display:"flex", justifyContent:"center"}}>
                                                        { item.EvidencijaDetalj.filter(ed => ed.BrojPrijenosa != null).length > 0 ? <CheckBoxIcon style={{marginTop:"4px"}} /> : <CheckBoxOutlineBlankIcon style={{marginTop:"4px"}} /> }
                                                    </ListItemIcon>
                                                </Grid>
                                                {index != data.length - 1 ? (
                                                    <Grid item xs={12}>
                                                        <Divider></Divider>
                                                    </Grid>
                                                ) : null}
                                            </Grid>
                                    </ListItem>);
                            })}
                        </List>
                    </Paper>
                </Box>
            ) : null}

            {dataType === "kontakti" && data && data.length != 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key={"listHeader"} >
                        <ListItemText style={{width:"50%", paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Naziv"} />
                        <ListItemText style={{width:"25%", paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Telefon"} />
                        <ListItemText style={{width:"25%", paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Email"} />
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 400, overflow: 'auto'}}>
                        <List dense>
                            {data.map((item, index) => {
                                return <ListItem className="list-item" style={{paddingTop:0, paddingBottom:0}} key={"listItem" + index} onClick={() => handleClick(item)} onDoubleClick={edit}>
                                            <ListItemText style={{width:"50%"}} primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? <b> {item.Naziv} </b> : item.Naziv} />
                                            <Divider orientation="vertical" flexItem />
                                            <ListItemText style={{width:"25%", paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? <b> {item.Tel} </b> : item.Tel} />
                                            <Divider orientation="vertical" flexItem />
                                            <ListItemText style={{width:"25%", paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? <b> {item.Email} </b> : item.Email} />
                                    </ListItem>;
                            })}
                        </List>
                    </Paper>
                </Box>
            ) : null}  

            {dataType=="arhiva" && data.length > 0 ? (
                <Box style={{width:"100%"}}>
                    <Grid item xs={12}>
                        <Box>
                            <ListItem className="list-title" key="stavka-title" >
                                <Grid container>
                                    <Grid item xs={1}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Broj" />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Predmet" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Klijent" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Datum arhiviranja" />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Posuđeno" />                                                
                                    </Grid>
                                    <Grid item xs={1}>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                                <List dense>
                                    {data.map((stavka, stavkaIndex) => {
                                        return <ListItem className="list-item" style={{paddingTop:0, paddingBottom:0}} key={"stavka" + stavkaIndex}  onClick={() => setCurrent(stavka)} onDoubleClick={() => {if (stavka.PosudioKorisnikName == null) edit()}}>
                                                <Grid container>
                                                    <Grid item xs={1}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == stavka) ? (<b>{stavka.Broj}</b>) : (stavka.Broj)} />
                                                    </Grid>
                                                    <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
                                                    <Grid item xs={4}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == stavka) ? (<b>{stavka.PredmetNaziv}</b>) : (stavka.PredmetNaziv)} />
                                                    </Grid>
                                                    <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
                                                    <Grid item xs={3}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == stavka) ? (<b>{stavka.KlijentNaziv}</b>) : (stavka.KlijentNaziv)} />
                                                    </Grid>
                                                    <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == stavka) ? (<b>{dateFormat(stavka.DatumArhiviranja, "dd.mm.yyyy")}</b>) : (dateFormat(stavka.DatumArhiviranja, "dd.mm.yyyy"))} />
                                                    </Grid>
                                                    <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
                                                    { stavka.ArhivaFileID == null ? (
                                                        <Tooltip title={(stavka.PosudioKorisnikName != null || stavka.PosudioKorisnikName == "") ? ("Korisnik: " + stavka.PosudioKorisnikName + ", Datum posudbe: " + dateFormat(stavka.DatumPosudbe, "dd.mm.yyyy")) : ""}>
                                                            <Grid item xs={1}>
                                                                <ListItemIcon style={{textAlign:"center", display:"flex", justifyContent:"center"}}>
                                                                    { stavka.DatumPosudbe != null && stavka.DatumVracanja == null ? <CheckBoxIcon style={{marginTop:"4px"}} /> : <CheckBoxOutlineBlankIcon style={{marginTop:"4px"}} /> }
                                                                </ListItemIcon>
                                                            </Grid>
                                                        </Tooltip>
                                                    ) : (<Grid item xs={1}>
                                                        </Grid>
                                                    )}
                                                    <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
                                                    <Tooltip title={stavka.ArhivaStavkaID ? stavka.Opis : stavka.Naziv}>
                                                        <Grid item xs={1}>
                                                            <ListItemIcon style={{display:"flex", justifyContent:"center"}}>
                                                                <InfoIcon style={{marginTop:"3px"}} />
                                                            </ListItemIcon>
                                                        </Grid>
                                                    </Tooltip>
                                                </Grid>
                                            </ListItem>
                                    })}
                                </List>
                            </Paper>
                        </Box>
                    </Grid>
                </Box>
            ) : null}

            {dataType=="konto" && data.length > 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key="konto-title" >
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Naziv" ></ListItemText>
                            </Grid>
                            <Grid item md={3} sx={{display: { xs: "none", md: "block" }}}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Računovodstvena referenca" />
                            </Grid>
                            <Grid item md={3} sx={{display: { xs: "none", md: "block" }}}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Mjesto troška" />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                        <List dense>
                            {data.map((konto, index) => {
                                return <ListItem className="list-item" style={{paddingTop:0, paddingBottom:0, backgroundColor: current == konto ? 'rgba(25, 118, 210, 0.08)' : 'white'}} key={"konto" + index}  onClick={() => setCurrent(konto)} onDoubleClick={edit}>
                                        <Grid container>
                                            <Grid item xs={12} md={6}>
                                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary={(current == konto) ? (<b>{konto.Naziv}</b>) : (konto.Naziv)} />
                                            </Grid>
                                            <Grid item md={3} sx={{display: { xs: "none", md: "block" }}}>
                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == konto) ? (<b>{konto.ForeignKontoID}</b>) : (konto.ForeignKontoID)} />
                                            </Grid>
                                            <Grid item md={3} sx={{display: { xs: "none", md: "block" }}}>
                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == konto) ? (<b>{konto.MjestoTroskaNaziv}</b>) : (konto.MjestoTroskaNaziv)} />
                                            </Grid>
                                            
                                        </Grid>
                                    </ListItem>
                            })}
                        </List>
                    </Paper>
                </Box>
            ) : null}

            {dataType=="mjesto" && data.length > 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key="konto-title" >
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Naziv" ></ListItemText>
                            </Grid>
                            <Grid item md={3} sx={{display: { xs: "none", md: "block" }}}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Poštanski broj" />
                            </Grid>
                            <Grid item md={3} sx={{display: { xs: "none", md: "block" }}}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Država" />
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                        <List dense>
                            {data.map((item, index) => {
                                return <ListItem className="list-item" style={{paddingTop:0, paddingBottom:0, backgroundColor: current == item ? 'rgba(25, 118, 210, 0.08)' : 'white'}} key={"mjestas" + index}  onClick={() => setCurrent(item)} onDoubleClick={edit}>
                                        <Grid container>
                                            <Grid item xs={12} md={6}>
                                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? (<b>{item.Naziv}</b>) : (item.Naziv)} />
                                            </Grid>
                                            <Grid item md={3} sx={{display: { xs: "none", md: "block" }}}>
                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? (<b>{item.PostanskiBroj}</b>) : (item.PostanskiBroj)} />
                                            </Grid>
                                            <Grid item md={3} sx={{display: { xs: "none", md: "block" }}}>
                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? (<b>{item.DrzavaNaziv}</b>) : (item.DrzavaNaziv)} />
                                            </Grid>
                                            
                                        </Grid>
                                    </ListItem>
                            })}
                        </List>
                    </Paper>
                </Box>
            ) : null}

            {(dataType=="odjel" || dataType=="tipkorisnika") && data.length > 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key="konto-title" >
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Naziv" ></ListItemText>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                        <List dense>
                            {data.map((item, index) => {
                                return <ListItem className="list-item" style={{paddingTop:0, paddingBottom:0, backgroundColor: current == item ? 'rgba(25, 118, 210, 0.08)' : 'white'}} key={"konto" + index}  onClick={() => setCurrent(item)} onDoubleClick={edit}>
                                        <Grid container>
                                            <Grid item xs={12} md={6}>
                                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? (<b>{item.Naziv}</b>) : (item.Naziv)} />
                                            </Grid>
                                            <Grid item md={3} sx={{display: { xs: "none", md: "block" }}}>
                                                {/* <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == konto) ? (<b>{konto.ForeignKontoID}</b>) : (konto.ForeignKontoID)} /> */}
                                            </Grid>
                                            <Grid item md={3} sx={{display: { xs: "none", md: "block" }}}>
                                                {/* <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == konto) ? (<b>{konto.MjestoTroskaNaziv}</b>) : (konto.MjestoTroskaNaziv)} /> */}
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                            })}
                        </List>
                    </Paper>
                </Box>
            ) : null}

            {dataType=="sudovi" && data.length > 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key="konto-title" >
                        <Grid container>
                            <Grid item xs={8}>
                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Naziv" ></ListItemText>
                            </Grid>
                            <Grid item xs={4}>
                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Vrsta" ></ListItemText>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                        <List dense>
                            {data.map((item, index) => {
                                return <ListItem className="list-item" style={{paddingTop:0, paddingBottom:0, backgroundColor: current == item ? 'rgba(25, 118, 210, 0.08)' : 'white'}} key={"konto" + index}  onClick={() => setCurrent(item)} onDoubleClick={() => {if (!disableEdit) {edit()}}}>
                                        <Grid container>
                                            <Grid item xs={8}>
                                                <ListItemText primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400 }} primary={item.Naziv} />
                                            </Grid>
                                            <Grid item md={4} sx={{display: { xs: "none", md: "block" }}}>
                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400 }} primary={item.TipVrsteSuda.Naziv} />
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                            })}
                        </List>
                    </Paper>
                </Box>
            ) : null}

            {dataType=="grupa" && data.length > 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key="konto-title" >
                        <Grid container>
                            <Grid item xs={3}>
                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Naziv" ></ListItemText>
                            </Grid>
                            <Grid item xs={9}>
                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Klijenti" ></ListItemText>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                        <List dense>
                            {data.map((item, index) => {
                                return <ListItem className="list-item" style={{paddingTop:0, paddingBottom:0, backgroundColor: current == item ? 'rgba(25, 118, 210, 0.08)' : 'white'}} key={"konto" + index}  onClick={() => setCurrent(item)} onDoubleClick={edit}>
                                        <Grid container>
                                            <Tooltip title={item.Naziv} enterDelay={200} enterNextDelay={200}>
                                                <Grid item xs={3}>
                                                    <ListItemText primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? (<b>{item.Naziv}</b>) : (item.Naziv)} />
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title={item.Klijenti} enterDelay={200} enterNextDelay={200}>
                                                <Grid item xs={9}>
                                                    <ListItemText primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? (<b>{item.Klijenti}</b>) : (item.Klijenti)} />
                                                </Grid>
                                            </Tooltip>
                                        </Grid>
                                    </ListItem>
                            })}
                        </List>
                    </Paper>
                </Box>
            ) : null}

            {(dataType=="korisnik") && data.length > 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key="konto-title" >
                        <Grid container>
                            <Grid item xs={12} md={3}>
                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Korisničko ime" ></ListItemText>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Naziv" ></ListItemText>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Tip korisnika" ></ListItemText>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Odjel" ></ListItemText>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                        <List dense>
                            {data.map((item, index) => {
                                return <ListItem className="list-item" style={{paddingTop:0, paddingBottom:0, backgroundColor: current == item ? 'rgba(25, 118, 210, 0.08)' : 'white', color: item.TipStatusaKorisnikaID == 1 ? "black" : "red"}} key={"konto" + index}  onClick={() => setCurrent(item)} onDoubleClick={edit}>
                                        <Grid container>
                                            <Grid item xs={12} md={3}>
                                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? (<b>{item.Username}</b>) : (item.Username)} />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? (<b>{item.Naziv}</b>) : (item.Naziv)} />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? (<b>{item.TipKorisnikaNaziv}</b>) : (item.TipKorisnikaNaziv)} />
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <ListItemText primaryTypographyProps={{ noWrap:true }} primary={(current == item) ? (<b>{item.OdjelNaziv}</b>) : (item.OdjelNaziv)} />
                                            </Grid>
                                            <Grid item md={3} sx={{display: { xs: "none", md: "block" }}}>
                                                {/* <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == konto) ? (<b>{konto.ForeignKontoID}</b>) : (konto.ForeignKontoID)} /> */}
                                            </Grid>
                                            <Grid item md={3} sx={{display: { xs: "none", md: "block" }}}>
                                                {/* <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={(current == konto) ? (<b>{konto.MjestoTroskaNaziv}</b>) : (konto.MjestoTroskaNaziv)} /> */}
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                            })}
                        </List>
                    </Paper>
                </Box>
            ) : null}

            {dataType=="racuni" && data.length > 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key="racuni-title" >
                        <Grid container>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                <Grid container>
                                    <Tooltip title="ID">
                                        <Grid item xs={2} >
                                            <ListItemText primaryTypographyProps={{ noWrap:true }} primary="ID" ></ListItemText>
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip title="Vrsta">
                                        <Grid item xs={4} >
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Vrsta" ></ListItemText>
                                        </Grid>
                                    </Tooltip>
                                    <Tooltip title="Broj">
                                        <Grid item xs={6} >
                                            <ListItemText style={{paddingLeft:"15px", cursor:"pointer"}} primaryTypographyProps={{ noWrap:true }} primary="Broj" ></ListItemText>
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Tooltip title="Klijent">
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Klijent" />
                                </Grid>
                            </Tooltip>
                            <Tooltip title="Datum">
                                <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Datum" />
                                </Grid>
                            </Tooltip>
                            <Grid item xs={7} sm={7} md={7} lg={7} xl={7} >
                                <Grid container>
                                    <Tooltip title="Valuta">
                                        <Grid item xs={1}>
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Valuta" />
                                        </Grid>
                                    </Tooltip>
                                    <Grid item xs={10}>
                                        <Grid container>
                                            <Tooltip title="Iznos">
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right" }} primary="Iznos" ></ListItemText>
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title="Porez">
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right" }} primary="Porez" ></ListItemText>
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title="Ukupno">
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px", cursor:"pointer"}} primaryTypographyProps={{ noWrap:true, textAlign:"right" }} primary="Ukupno" ></ListItemText>
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title="Placeno">
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right" }} primary="Plaćeno" ></ListItemText>
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title="Ostalo">
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right" }} primary="Ostalo" ></ListItemText>
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title="Napomena">
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Napomena" ></ListItemText>
                                                </Grid>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <Tooltip title="Izvezeno" sx={{ display:{xs:"none", sm:"none", md:"none" , lg:"block", xl:"block"} }}>
                                        <Grid item xs={1}>
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Izvezeno" ></ListItemText>
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                        <List dense>
                            {data.map((racun, index) => {
                                return <RacunListItem key={index} referenca={props.referenca} racun={racun} racuni={props.data} index={index} lastIndex={props.lastIndex} handleRacunClick={props.handleRacunClick} oznaceni={props.oznaceni} oznaceniLength={props.oznaceni.length} setOznaceni={props.setOznaceni} isLast={index == data.length - 1} forceListItemToReRender={props.forceListItemToReRender} edit={edit} backColor={current && current.RacunID == racun.RacunID || props.oznaceni.includes(racun.RacunID) ? 'rgba(25, 118, 210, 0.08)' : 'white'} fontWeight={(current && current.RacunID == racun.RacunID || props.oznaceni.includes(racun.RacunID)) ? 700 : 400}></RacunListItem> })}
                        </List>
                    </Paper>
                </Box>
            ) : null}
            {/* {dataType=="racuni" && data.length > 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key="racuni-title" >
                        <Grid container>
                            <Tooltip title="Vrsta">
                                <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                                    <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Vrsta" ></ListItemText>
                                </Grid>
                            </Tooltip>
                            <Tooltip title="Broj">
                                <Grid item xs={3} sm={3} md={3} lg={3} xl={1}>
                                    <ListItemText style={{paddingLeft:"15px", cursor:"pointer"}} primaryTypographyProps={{ noWrap:true }} primary="Broj" ></ListItemText>
                                </Grid>
                            </Tooltip>
                            <Tooltip title="Klijent">
                                <Grid item xs={4} sm={4} md={4} lg={4} xl={2}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Klijent" />
                                </Grid>
                            </Tooltip>
                            <Tooltip title="Datum">
                                <Grid item xs={3} sm={3} md={3} lg={3} xl={1}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Datum" />
                                </Grid>
                            </Tooltip>
                            <Grid item xs={0} sm={0} md={0} lg={0} xl={7} sx={{ display:{xs:"none", sm:"none", md:"none" , lg:"none", xl:"block"} }}>
                                <Grid container>
                                    <Tooltip title="Valuta">
                                        <Grid item xs={1}>
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Valuta" />
                                        </Grid>
                                    </Tooltip>
                                    <Grid item xs={10}>
                                        <Grid container>
                                            <Tooltip title="Iznos">
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Iznos" ></ListItemText>
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title="Porez">
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Porez" ></ListItemText>
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title="Ukupno">
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px", cursor:"pointer"}} primaryTypographyProps={{ noWrap:true }} primary="Ukupno" ></ListItemText>
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title="Placeno">
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Plaćeno" ></ListItemText>
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title="Ostalo">
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Ostalo" ></ListItemText>
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title="Napomena">
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Napomena" ></ListItemText>
                                                </Grid>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                    <Tooltip title="Izvezeno" sx={{ display:{xs:"none", sm:"none", md:"none" , lg:"none", xl:"block"} }}>
                                        <Grid item xs={1}>
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Izvezeno" ></ListItemText>
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                        <List>
                            {data.map((racun, index) => {
                                let racunDetalji = getRacunDetalji(racun);
                                return <ListItem className="list-item" key={"konto" + index} style={{  }}  onClick={() => setCurrent(racun)} onDoubleClick={edit}>
                                        <Grid container>
                                            <Tooltip title={racun.Naziv} enterDelay={300} enterNextDelay={300}>
                                                <Grid item xs={2} sm={2} md={2} lg={2} xl={1}>
                                                    <ListItemText primaryTypographyProps={{ noWrap:true }} primary={ (racun.VrstaRacunaNaziv)} />
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title={racun.Broj || ""} enterDelay={300} enterNextDelay={300}>
                                                <Grid item xs={3} sm={3} md={3} lg={3} xl={1}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={ (racun.Broj || "")} />
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title={racun.KlijentNaziv} enterDelay={300} enterNextDelay={300}>
                                                <Grid item xs={4} sm={4} md={4} lg={4} xl={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={ (racun.KlijentNaziv)} />
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title={dateFormat(racun.Datum, "dd.mm.yyyy")} enterDelay={300} enterNextDelay={300}>
                                                <Grid item xs={3} sm={3} md={3} lg={3} xl={1}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={ (dateFormat(racun.Datum, "dd.mm.yyyy"))} />
                                                </Grid>
                                            </Tooltip>
                                            <Grid item xs={0} sm={0} md={0} lg={7} sx={{ display:{xs:"none", sm:"none", md:"none" , lg:"none", xl:"block"} }}>
                                                <Grid container>
                                                    <Tooltip title={racun.FirmaValutaNaziv} enterDelay={300} enterNextDelay={300}>
                                                        <Grid item xs={1}>
                                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={ (racun.FirmaValutaNaziv)} />
                                                        </Grid>
                                                    </Tooltip>
                                                    <Grid item xs={10}>
                                                        <Grid container>
                                                            <Tooltip title={racunDetalji.Iznos} enterDelay={300} enterNextDelay={300}>
                                                                <Grid item xs={2}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={ racunDetalji.Iznos} ></ListItemText>
                                                                </Grid>
                                                            </Tooltip>
                                                            <Tooltip title={racunDetalji.Porez} enterDelay={300} enterNextDelay={300}>
                                                                <Grid item xs={2}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={ racunDetalji.Porez} ></ListItemText>
                                                                </Grid>
                                                            </Tooltip>
                                                            <Tooltip title={racunDetalji.Ukupno} enterDelay={300} enterNextDelay={300}>
                                                                <Grid item xs={2}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={ racunDetalji.Ukupno} ></ListItemText>
                                                                </Grid>
                                                            </Tooltip>
                                                            <Tooltip title="" enterDelay={300} enterNextDelay={300}>
                                                                <Grid item xs={2}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={""} ></ListItemText>
                                                                </Grid>
                                                            </Tooltip>
                                                            <Tooltip title="" enterDelay={300} enterNextDelay={300}>
                                                                <Grid item xs={2}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={ ""} ></ListItemText>
                                                                </Grid>
                                                            </Tooltip>
                                                            <Tooltip title={racun.Napomena} enterDelay={300} enterNextDelay={300}>
                                                                <Grid item xs={2}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={ racun.Napomena} ></ListItemText>
                                                                </Grid>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Tooltip title={racun.IzvezenaDatotekaPutanja} enterDelay={300} enterNextDelay={300} sx={{ display:{xs:"none", sm:"none", md:"none" , lg:"none", xl:"block"} }}>
                                                        <Grid item xs={1}>
                                                            <ListItemIcon style={{display:"flex", justifyContent:"center"}}>
                                                                {racun.IzvezenaDatotekaID ? (
                                                                    <CheckBoxIcon style={{marginTop:"3px"}} />
                                                                ) : (
                                                                    <CheckBoxOutlineBlankIcon style={{marginTop:"3px"}} />
                                                                )}
                                                            </ListItemIcon>
                                                        </Grid>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            {index != data.length - 1 ? (
                                                <Grid item xs={12}>
                                                    <Divider></Divider>
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    </ListItem>
                            })}
                        </List>
                    </Paper>
                </Box>
            ) : null} */}


            {dataType=="racunskeStavke" && data.length > 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key="stavka-title" >
                        <Grid item xs={3}>
                            <Grid container>
                                <Tooltip title="Redni broj">
                                    <Grid item xs={2}>
                                        <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Rb." />
                                    </Grid>
                                </Tooltip>
                                <Grid item xs={4}>
                                </Grid>
                                <Tooltip title="ID stavke"> 
                                    <Grid item xs={3}>
                                        <ListItemText style={{paddingLeft:"10px"}} primaryTypographyProps={{ noWrap:true }} primary="ID stavke" />
                                    </Grid>
                                </Tooltip>
                                <Tooltip title="Vrsta stavke"> 
                                    <Grid item xs={3}>
                                        <ListItemText style={{paddingLeft:"10px"}} primaryTypographyProps={{ noWrap:true }} primary="Vrsta" />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        <Tooltip title="Opis"> 
                            <Grid item xs={3}>
                                <ListItemText style={{paddingLeft:"10px"}} primaryTypographyProps={{ noWrap:true }} primary="Opis" />
                            </Grid>
                        </Tooltip>
                        <Grid item xs={6}>
                            <Grid container>
                                <Tooltip title="Jedinica mjere"> 
                                    <Grid item xs={1}>
                                        <ListItemText style={{paddingLeft:"10px"}} primaryTypographyProps={{ noWrap:true }} primary="JM" />
                                    </Grid>
                                </Tooltip>
                                <Tooltip title="Količina"> 
                                    <Grid item xs={1}>
                                        <ListItemText style={{paddingLeft:"10px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right" }} primary="Količina" />
                                    </Grid>
                                </Tooltip>
                                <Tooltip title="Cijena"> 
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"10px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right" }} primary="Cijena" />
                                    </Grid>
                                </Tooltip>
                                <Tooltip title="Ukupno"> 
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"10px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right" }} primary="Ukupno" />
                                    </Grid>
                                </Tooltip>
                                <Tooltip title="Rabat"> 
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"10px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right" }} primary="Rabat %" />
                                    </Grid>
                                </Tooltip>
                                <Tooltip title="Marža"> 
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"10px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right" }} primary="Marža %" />
                                    </Grid>
                                </Tooltip>
                                <Tooltip title="Iznos"> 
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"10px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right" }} primary="Iznos" />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </ListItem>
                <Paper variant="outlined" style={{width:"100%", maxHeight: 150, overflow: 'auto'}}>
                    <List dense>
                        {sortedRacunskeStavke().map((item, index) => {
                            let detaljiStavka = getRacunskaStavkaDetalji(item);
                            return <ListItem className="list-item" key={"listItem" + index} style={{paddingTop:0, paddingBottom:0, color: (item.TipRacunskeStavkeID == 1 && item.StavkaSeNaplacuje == false) ? "red" : "black", backgroundColor: current == item ? 'rgba(25, 118, 210, 0.08)' : 'white'}} onClick={() => setCurrent(item)} onDoubleClick={(!disableButtons && !disableEdit) ? edit : null}>
                                    <Grid container>
                                        <Grid item xs={3}>
                                            <Grid container>
                                                <Tooltip title={item.RedniBroj}>
                                                <Grid item xs={2}>
                                                    <ListItemText primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400 }} primary={item.RedniBroj} />
                                                </Grid>
                                                </Tooltip>
                                                <Grid item xs={4}>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            <ListItemButton style={{paddingLeft:0}} onClick={() => pomakniRacunskuStavkuGore(item.RedniBroj)}>
                                                                <ArrowUpwardIcon></ArrowUpwardIcon>
                                                            </ListItemButton>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <ListItemButton style={{paddingLeft:0}} onClick={() => pomakniRacunskuStavkuDolje(item.RedniBroj)}>
                                                                <ArrowDownwardIcon></ArrowDownwardIcon>
                                                            </ListItemButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Tooltip title={item.RacunskaStavkaID > 0 ? item.RacunskaStavkaID : ""} enterDelay={300} enterNextDelay={300}>
                                                    <Grid item xs={3}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={item == current ? <b>{item.RacunskaStavkaID > 0 ? item.RacunskaStavkaID : ""}</b> : (item.RacunskaStavkaID > 0 ? item.RacunskaStavkaID : "")} />
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip title={item.TipRacunskeStavkeNaziv} enterDelay={300} enterNextDelay={300}>
                                                    <Grid item xs={3}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={item == current ? <b>{item.TipRacunskeStavkeNaziv}</b> : item.TipRacunskeStavkeNaziv} />
                                                    </Grid>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Tooltip title={item.Opis} enterDelay={300} enterNextDelay={300}>
                                            <Grid item xs={3}>
                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={item == current ? <b>{item.Opis}</b> : item.Opis} />
                                            </Grid>
                                        </Tooltip>
                                        <Grid item xs={6}>
                                            <Grid container>
                                                <Tooltip title={item.TipJediniceMjere.Kratica} enterDelay={300} enterNextDelay={300}>
                                                    <Grid item xs={1}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400  }} primary={item.TipJediniceMjere.Kratica} />
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip title={formatter.format(detaljiStavka.Kolicina)} enterDelay={300} enterNextDelay={300}>
                                                    <Grid item xs={1}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: item == current ? 700 : 400 }} primary={formatter.format(detaljiStavka.Kolicina)} />
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip title={item.TipRacunskeStavkeID != 1 ? formatter.format(detaljiStavka.Cijena) : ""} enterDelay={300} enterNextDelay={300}>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: item == current ? 700 : 400 }} primary={item.TipRacunskeStavkeID != 1 ? formatter.format(detaljiStavka.Cijena) : ""} />
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip title={item.TipRacunskeStavkeID != 1 ? formatter.format(detaljiStavka.Ukupno) : ""} enterDelay={300} enterNextDelay={300}>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: item == current ? 700 : 400 }} primary={item.TipRacunskeStavkeID != 1 ? formatter.format(detaljiStavka.Ukupno) : ""} />
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip title={detaljiStavka.Rabat != null ? formatter.format(detaljiStavka.Rabat) : formatter.format(0)} enterDelay={300} enterNextDelay={300}>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: item == current ? 700 : 400 }} primary={item.TipRacunskeStavkeID != 1 ? (detaljiStavka.Rabat != null ? formatter.format(detaljiStavka.Rabat) : formatter.format(0)) : ""} />
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip title={detaljiStavka.Marza != null ? formatter.format(detaljiStavka.Marza) : formatter.format(0)} enterDelay={300} enterNextDelay={300}>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: item == current ? 700 : 400 }} primary={item.TipRacunskeStavkeID != 1 ? (detaljiStavka.Marza != null ? formatter.format(detaljiStavka.Marza) : formatter.format(0)) : ""} />
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip title={item.TipRacunskeStavkeID != 1 ? formatter.format(detaljiStavka.Iznos) : formatter.format(Util.izracunajIznosRacunskeStavke(item))} enterDelay={300} enterNextDelay={300}>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: item == current ? 700 : 400 }} primary={item.TipRacunskeStavkeID != 1 ? formatter.format(detaljiStavka.Iznos) : formatter.format(Util.izracunajIznosRacunskeStavke(item))} />
                                                    </Grid>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        {index != data.length - 1 ? (
                                            <Grid item xs={12}>
                                                <Divider></Divider>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                </ListItem>;
                            })}
                        </List>
                    </Paper>
                </Box>
            ) : null}

            {dataType=="stavkeSpecifikacije" && data.length > 0 ? (
                <Box style={{width:"100%"}}>
                    <ListItem className="list-title" key="stavka-title" >

                        <Tooltip title="Tip"> 
                            <Grid item xs={1}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Tip" />
                            </Grid>
                        </Tooltip>

                        <Tooltip title="Datum"> 
                            <Grid item xs={1}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Datum" />
                            </Grid>
                        </Tooltip>
                        <Tooltip title="Osoba"> 
                            <Grid item xs={2}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Osoba" />
                            </Grid>
                        </Tooltip>
                        <Tooltip title="Predmet"> 
                            <Grid item xs={2}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Predmet" />
                            </Grid>
                        </Tooltip>
                        <Tooltip title="Opis"> 
                            <Grid item xs={2}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Opis" />
                            </Grid>
                        </Tooltip>
                        <Tooltip title="Trajanje"> 
                            <Grid item xs={1}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ textAlign:"right", noWrap:true }} primary="Količina" />
                            </Grid>
                        </Tooltip>
                        <Tooltip title="Iznos"> 
                            <Grid item xs={2}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ textAlign:"right", noWrap:true }} primary="Iznos" />
                            </Grid>
                        </Tooltip>
                        <Tooltip title="Naplata"> 
                            <Grid item xs={1}>
                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Naplata" />
                            </Grid>
                        </Tooltip>
                    </ListItem>
                    <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                        <List dense>
                            {sortedStavkeSpecifikacije().map((item, index) => {
                                return <ListItem className="list-item" key={"listItem" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: current == item ? 'rgba(25, 118, 210, 0.08)' : 'white'}} onClick={() => setCurrent(item)} onDoubleClick={edit}>
                                        <Grid container>

                                            <Tooltip title={item.TipEvidencijeID == 1 ? 'Aktivnost' : 'Trošak'} enterDelay={300} enterNextDelay={300}>
                                                <Grid item xs={1}>
                                                    <ListItemIcon style={{paddingLeft:"15px", height:"100%", display:"flex", alignItems:"center"}}>
                                                        <Brightness1Icon sx={{ color: item.TipEvidencijeID == 1 ? "rgb(255,0,255)" : "darkblue" }}></Brightness1Icon>
                                                    </ListItemIcon>
                                                </Grid>
                                            </Tooltip>



                                            <Tooltip title={dateFormat(item.Datum, "dd.mm.yyyy")} enterDelay={300} enterNextDelay={300}> 
                                                <Grid item xs={1}>
                                                    <ListItemText style={{paddingLeft:"15px", color: (item.Predmet.TipStanjaPredmetaID !== 1 ? "red" : (item.Predmet.TipNaplateID == 1 ? "black" : (item.Predmet.TipNaplateID == 2 ? "magenta" : "green")))}} primaryTypographyProps={{ noWrap:true }} primary={item == current ? <b>{dateFormat(item.Datum, "dd.mm.yyyy")}</b> : dateFormat(item.Datum, "dd.mm.yyyy")} />
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title={item.Korisnik.Ime + " " + item.Korisnik.Prezime} enterDelay={300} enterNextDelay={300}> 
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px", color: (item.Predmet.TipStanjaPredmetaID !== 1 ? "red" : (item.Predmet.TipNaplateID == 1 ? "black" : (item.Predmet.TipNaplateID == 2 ? "magenta" : "green")))}} primaryTypographyProps={{ noWrap:true }} primary={item == current ? <b>{item.Korisnik.Ime + " " + item.Korisnik.Prezime}</b> : item.Korisnik.Ime + " " + item.Korisnik.Prezime} />
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title={item.Predmet ? item.Predmet.Broj + " - " + item.Predmet.Naziv : ""} enterDelay={300} enterNextDelay={300}> 
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px", color: (item.Predmet.TipStanjaPredmetaID !== 1 ? "red" : (item.Predmet.TipNaplateID == 1 ? "black" : (item.Predmet.TipNaplateID == 2 ? "magenta" : "green")))}} primaryTypographyProps={{ noWrap:true }} primary={item == current ? <b>{item.Predmet ? item.Predmet.Broj + " - " + item.Predmet.Naziv : ""}</b> : (item.Predmet ? item.Predmet.Broj + " - " + item.Predmet.Naziv : "")} />
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title={item.Opis} enterDelay={300} enterNextDelay={300}> 
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px", color: (item.Predmet.TipStanjaPredmetaID !== 1 ? "red" : (item.Predmet.TipNaplateID == 1 ? "black" : (item.Predmet.TipNaplateID == 2 ? "magenta" : "green")))}} primaryTypographyProps={{ noWrap:true }} primary={item == current ? <b>{item.Opis}</b> : item.Opis} />
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title={formatter.format(item.Kolicina)} enterDelay={300} enterNextDelay={300}> 
                                                <Grid item xs={1}>
                                                    <ListItemText style={{paddingLeft:"15px", color: (item.Predmet.TipStanjaPredmetaID !== 1 ? "red" : (item.Predmet.TipNaplateID == 1 ? "black" : (item.Predmet.TipNaplateID == 2 ? "magenta" : "green")))}} primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: item == current ? 700 : 400  }} primary={formatter.format(item.Kolicina)} />
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title={"cijena: " + formatter.format(item.Iznos) + " " + item.FirmaValuta.FirmaValutaNaziv} enterDelay={300} enterNextDelay={300}> 
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px", color: (item.Predmet.TipStanjaPredmetaID !== 1 ? "red" : (item.Predmet.TipNaplateID == 1 ? "black" : (item.Predmet.TipNaplateID == 2 ? "magenta" : "green")))}} primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: item == current ? 700 : 400  }} primary={formatter.format(item.Kolicina * item.Iznos) + " " + item.FirmaValuta.FirmaValutaNaziv} />
                                                </Grid>
                                            </Tooltip>
                                            
                                            
                                            {/* <Tooltip title={item.TipNaplatnosti.Naziv} enterDelay={300} enterNextDelay={300}> 
                                                <Grid item xs={1}>
                                                    <ListItemText style={{paddingLeft:"15px", color: (item.Predmet.TipStanjaPredmetaID !== 1 ? "red" : (item.Predmet.TipNaplateID == 1 ? "black" : (item.Predmet.TipNaplateID == 2 ? "magenta" : "green")))}} primaryTypographyProps={{ noWrap:true }} primary={item == current ? <b>{item.TipNaplatnosti.Naziv}</b> : item.TipNaplatnosti.Naziv} />
                                                </Grid>
                                            </Tooltip> */}

                                            <Tooltip title={item.TipNaplatnosti.Naziv} enterDelay={300} enterNextDelay={300}>
                                                <Grid item xs={1}>
                                                    <ListItemIcon style={{paddingLeft:"30px", height:"100%", display:"flex", alignItems:"center"}}>
                                                        <Brightness1Icon sx={{ color: "rgb(" + (item.TipNaplatnostiID == 1 ? props.tipNaplatnostiNaplatnoBoja : props.tipNaplatnostiNenaplatnoBoja) + ")"}}></Brightness1Icon>
                                                    </ListItemIcon>
                                                </Grid>
                                            </Tooltip>

                                            {index != data.length - 1 ? (
                                                <Grid item xs={12}>
                                                    <Divider></Divider>
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    </ListItem>;
                                })}
                        </List>
                    </Paper>
                </Box>
                ) : null}
                
                {dataType=="naciniplacanja" && data.length > 0 ? (
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="nacinplacanja-title" >
                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Naziv" />
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {data.map((nacinplacanja, index) => {
                                    return <ListItem className="list-item" key={"nacinplacanja" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: nacinplacanja == current ? 'rgba(25, 118, 210, 0.08)' : 'white'}}  onClick={() => setCurrent(nacinplacanja)} onDoubleClick={edit}>
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={nacinplacanja == current ? <b>{nacinplacanja.Naziv}</b> : (nacinplacanja.Naziv)} />
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                ) : null}

                {dataType=="nepredmetneKategorije" && data.length > 0 ? (
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="nepredmetnekategorije-title" >
                            <Grid container>
                                <Grid item xs={2} sm={1}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Boja" />
                                </Grid>
                                <Grid item xs={10} sm={11}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Naziv" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {data.map((kategorija, index) => {
                                    return <ListItem className="list-item" key={"kategorija" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: kategorija == current ? 'rgba(25, 118, 210, 0.08)' : 'white'}}  onClick={() => setCurrent(kategorija)} onDoubleClick={edit}>
                                            <Grid container>
                                                <Grid item xs={2} sm={1} style={{display:"flex", alignItems:"center"}}>
                                                    <ListItemIcon style={{paddingLeft:"15px"}}><Brightness1Icon sx={{ color : "rgb(" + kategorija.Boja + ")" }}></Brightness1Icon></ListItemIcon>
                                                </Grid>
                                                <Grid item xs={10} sm={11}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={kategorija == current ? <b>{kategorija.Naziv}</b> : (kategorija.Naziv)} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                ) : null}

                
                {dataType=="poslovniProstori" && data.length > 0 ? (
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="poslovniprostori-title" >
                            <Grid container>
                                <Grid item xs={2}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Fiskalna oznaka" />
                                </Grid>
                                <Grid item xs={5}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Naziv" />
                                </Grid>
                                <Grid item xs={5}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Adresa" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {data.map((prostor, index) => {
                                    return <ListItem className="list-item" key={"kategorija" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: prostor == current ? 'rgba(25, 118, 210, 0.08)' : 'white'}}  onClick={() => setCurrent(prostor)} onDoubleClick={edit}>
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <Tooltip title={prostor.FiskalnaOznaka} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: prostor == current ? 700 : 400 }} primary={prostor.FiskalnaOznaka} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Tooltip title={prostor.Naziv} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: prostor == current ? 700 : 400 }} primary={prostor.Naziv} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Tooltip title={prostor.Adresa.AdresaNaziv + ", " + prostor.Adresa.MjestoResource.PostanskiBroj + " " + prostor.Adresa.MjestoResource.Naziv + ", " + prostor.Adresa.MjestoResource.DrzavaNaziv} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: prostor == current ? 700 : 400 }} primary={prostor.Adresa.AdresaNaziv + ", " + prostor.Adresa.MjestoResource.PostanskiBroj + " " + prostor.Adresa.MjestoResource.Naziv + ", " + prostor.Adresa.MjestoResource.DrzavaNaziv} />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                ) : null}

                {dataType=="blagajne" && data.length > 0 ? (
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="blagajne-title" >
                            <Grid container>
                                <Grid item xs={2}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Fiskalna oznaka" />
                                </Grid>
                                <Grid item xs={6}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Naziv" />
                                </Grid>
                                <Grid item xs={1}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ textAlign:"center", noWrap:true }} primary="Aktivna" />
                                </Grid>
                                <Grid item xs={1}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ textAlign:"center", noWrap:true }} primary="Fiskalna" />
                                </Grid>
                                <Grid item xs={2}>
                                    <Tooltip title="Sljedeći broj računa na ovoj blagajni u trenutnoj godini" enterDelay={200} enterNextDelay={200}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ textAlign:"center", noWrap:true }} primary="Broj računa" />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {data.map((blagajna, index) => {
                                    return <ListItem className="list-item" key={"blagajna" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: blagajna == current ? 'rgba(25, 118, 210, 0.08)' : 'white'}}  onClick={() => setCurrent(blagajna)} onDoubleClick={edit}>
                                            <Grid container>
                                                <Grid item xs={2}>
                                                    <Tooltip title={blagajna.FiskalnaOznaka} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: blagajna == current ? 700 : 400 }} primary={blagajna.FiskalnaOznaka} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Tooltip title={blagajna.Naziv} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: blagajna == current ? 700 : 400 }} primary={blagajna.Naziv} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <ListItemIcon style={{paddingLeft:"15px", display:"flex", justifyContent:"center"}}>
                                                        { blagajna.Aktivna ? <CheckBoxIcon style={{marginTop:"4px", color:"blue"}} /> : <CheckBoxOutlineBlankIcon style={{marginTop:"4px"}} /> }
                                                    </ListItemIcon>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <ListItemIcon style={{paddingLeft:"15px", display:"flex", justifyContent:"center"}}>
                                                        { blagajna.Fiskalna ? <CheckBoxIcon style={{marginTop:"4px", color:"#FF7F50"}} /> : <CheckBoxOutlineBlankIcon style={{marginTop:"4px"}} /> }
                                                    </ListItemIcon>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Tooltip title="Sljedeći broj računa na ovoj blagajni u trenutnoj godini" enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ textAlign:"center", noWrap:true }} primary={blagajna.SljedeciBrojRacuna} />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                ) : null}

                {dataType=="satniceRada" && data.length > 0 ? (
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="satnicerada-title" >
                            <Grid container>
                                <Grid item xs={8}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Naziv" />
                                </Grid>
                                <Grid item xs={4}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Osnovna" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {data.map((satnica, index) => {
                                    return <ListItem className="list-item" key={"kategorija" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: satnica == current ? 'rgba(25, 118, 210, 0.08)' : 'white'}}  onClick={() => setCurrent(satnica)} onDoubleClick={edit}>
                                            <Grid container>
                                                <Grid item xs={8}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: satnica == current ? 700 : 400 }} primary={satnica.Naziv} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: satnica == current ? 700 : 400 }} primary={satnica.OsnovnaSatnica ? 'Da' : 'Ne'} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                ) : null}

                {dataType=="satnicaRadaDetalji" && data.length > 0 ? (
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="satnicaradadetalji-title" >
                            <Grid container>
                                <Grid item xs={4}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Dan" />
                                </Grid>
                                <Grid item xs={4}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Očekivani početak" />
                                </Grid>
                                <Grid item xs={4}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Trajanje (sati)" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {data.map((detalj, index) => {
                                    return <ListItem className="list-item" key={"detalj" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: detalj == current ? 'rgba(25, 118, 210, 0.08)' : 'white'}}  onClick={() => setCurrent(detalj)} onDoubleClick={edit}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: detalj == current ? 700 : 400 }} primary={detalj.DanTjedna} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: detalj == current ? 700 : 400 }} primary={dateFormat(detalj.Pocetak, "HH:MM")} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: detalj == current ? 700 : 400 }} primary={detalj.Duzina} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                ) : null}

                {dataType=="blagdani" && data.length > 0 ? (
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="blagdani-title" >
                            <Grid container>
                                <Grid item xs={4}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Datum" />
                                </Grid>
                                <Grid item xs={8}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Naziv" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {data.map((item, index) => {
                                    return <ListItem className="list-item" key={"blagdan" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: item == current ? 'rgba(25, 118, 210, 0.08)' : 'white'}}  onClick={() => setCurrent(item)} onDoubleClick={edit}>
                                            <Grid container>
                                                <Grid item xs={4}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400 }} primary={dateFormat(item.Datum, "dd.mm.yyyy")} />
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400 }} primary={item.Naziv} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                ) : null}

                {dataType=="zadaci" && data.length > 0 ? (
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="zadaci-title" >
                            <Grid container>
                                <Grid item xs={1} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Zadavatelj" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Opis" />
                                </Grid>
                                <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Klijent" />
                                </Grid>
                                <Grid item xs={3} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Početak" />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Kraj" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={1} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Status" />
                                </Grid>
                                <Grid item xs={1} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Prioritet" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {data.map((zadatak, index) => {
                                    return <ListItem className="list-item" key={"zad" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: zadatak == current ? 'rgba(25, 118, 210, 0.08)' : 'white'}}  onClick={() => setCurrent(zadatak)} onDoubleClick={() => {if (!disableEdit) {edit();}}}>
                                            <Grid container>
                                                <Tooltip title={zadatak.ZadavateljIme} enterDelay={400} enterNextDelay={400}>
                                                    <Grid item xs={1} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == current ? 700 : 400 }} primary={zadatak.ZadavateljIme} />
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip title={zadatak.Opis} enterDelay={400} enterNextDelay={400}>
                                                    <Grid item xs={12} sm={12} md={4}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == current ? 700 : 400 }} primary={zadatak.Opis} />
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip title={zadatak.KlijentNaziv} enterDelay={400} enterNextDelay={400}>
                                                    <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == current ? 700 : 400 }} primary={zadatak.KlijentNaziv} />
                                                    </Grid>
                                                </Tooltip>
                                                <Grid item xs={3} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                                    <Grid container>
                                                        <Tooltip title={zadatak.Pocetak ? dateFormat(zadatak.Pocetak, "dd.mm.yyyy HH:MM") : ""} enterDelay={400} enterNextDelay={400}>
                                                            <Grid item xs={6} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == current ? 700 : 400 }} primary={zadatak.Pocetak ? dateFormat(zadatak.Pocetak, "dd.mm.yyyy HH:MM") : ""} />
                                                            </Grid>
                                                        </Tooltip>
                                                        <Tooltip title={zadatak.Kraj ? dateFormat(zadatak.Kraj, "dd.mm.yyyy HH:MM") : ""} enterDelay={400} enterNextDelay={400}>
                                                            <Grid item xs={6} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == current ? 700 : 400 }} primary={zadatak.Kraj ? dateFormat(zadatak.Kraj, "dd.mm.yyyy HH:MM") : ""} />
                                                            </Grid>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                                <Tooltip title={zadatak.TipZadatakStatusNaziv + (zadatak.PostotakZavrsenosti != null ? (" (" + zadatak.PostotakZavrsenosti + "%)") : "")} enterDelay={400} enterNextDelay={400}>
                                                    <Grid item xs={1} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == current ? 700 : 400 }} primary={zadatak.TipZadatakStatusNaziv + (zadatak.PostotakZavrsenosti != null ? (" (" + zadatak.PostotakZavrsenosti + "%)") : "")} />
                                                    </Grid>
                                                </Tooltip>
                                                <Tooltip title={zadatak.PrioritetNaziv} enterDelay={400} enterNextDelay={400}>
                                                    <Grid item xs={1} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                                        <ListItemText style={{paddingLeft:"15px", color: zadatak.TipZadatakPrioritetID == 1 ? "green" : (zadatak.TipZadatakPrioritetID == 2 ? "black" : "red")}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == current ? 700 : 400 }} primary={zadatak.PrioritetNaziv} />
                                                    </Grid>
                                                </Tooltip>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                ) : null}

                {dataType=="placanja" && data.length > 0 ? (
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="placanja-title" >
                            <Grid container>
                                <Grid item xs={1}>
                                    <ListItemText  primaryTypographyProps={{ noWrap:true }} primary="ID" />
                                </Grid>
                                <Grid item xs={2}>
                                    <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Datum" />
                                </Grid>
                                <Grid item xs={1}>
                                    <ListItemText  primaryTypographyProps={{ noWrap:true }} primary="Dokument" />
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container>
                                        <Grid item xs={9} >
                                            <ListItemText  primaryTypographyProps={{ noWrap:true }} primary="Opis" />
                                        </Grid>
                                        <Grid item xs={3} >
                                            <ListItemText  primaryTypographyProps={{ noWrap:true }} primary="Valuta" />
                                        </Grid>
                                    </Grid>                            
                                </Grid>
                                <Grid item xs={1}>
                                    <ListItemText  primaryTypographyProps={{ textAlign:"right", noWrap:true }} primary="Duguje" />
                                </Grid>
                                <Grid item xs={1}>
                                    <ListItemText primaryTypographyProps={{ textAlign:"right", noWrap:true }} primary="Potražuje" />
                                </Grid>
                                <Grid item xs={1}>
                                    <ListItemText  primaryTypographyProps={{ textAlign:"right", noWrap:true }} primary="Otvoreno" />
                                </Grid>
                                <Grid item xs={1}>
                                    <ListItemText primaryTypographyProps={{ textAlign:"right", noWrap:true }} primary="Zatvoreno" />
                                </Grid>
                                <Grid item xs={2}>
                                    <ListItemText s primaryTypographyProps={{ noWrap:true, paddingLeft:"15px" }} primary="Napomena" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {data.map((item, index) => {
                                    let racunDetalji = item.RacunID ? Util.getRacunDetalji(item) : null;
                                    return <ListItem className="list-item" key={"placanje" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: item == current ? 'rgba(25, 118, 210, 0.08)' : 'white', color: item.RacunID && (item.Storniran || item.VrstaRacunaID == 7) ? "red" : ((item.RacunID != null && racunDetalji.Ostalo == 0) || (item.RacunID == null && item.Otvoreno == 0) ? "magenta" : "black") }}  onClick={() => setCurrent(item)} onDoubleClick={() => {if (!disableEdit) edit()}}>
                                            <Grid container>
                                                <Grid item xs={1}>
                                                    <Tooltip title={item.RacunID ? item.RacunID : item.PlacanjeID} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText  primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400 }} primary={item.RacunID ? item.RacunID : item.PlacanjeID} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Tooltip title={dateFormat(item.Datum, "dd.mm.yyyy")} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText  primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400 }} primary={dateFormat(item.Datum, "dd.mm.yyyy")} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Tooltip title={item.RacunID ? item.VrstaRacuna.Naziv : "Izvod"} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText  primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400 }} primary={item.RacunID ? item.VrstaRacuna.Naziv : "Izvod"} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Grid container>                                  
                                                        <Grid item xs={9}>
                                                            <Tooltip title={item.RacunID ? item.Broj : item.Opis} enterDelay={200} enterNextDelay={200}>
                                                                <ListItemText  primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400 }} primary={item.RacunID ? item.Broj : item.Opis} />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <Tooltip title={item.FirmaValuta.FirmaValutaNaziv} enterDelay={200} enterNextDelay={200}>
                                                                <ListItemText  primaryTypographyProps={{ noWrap:true, fontWeight: item == current ? 700 : 400 }} primary={item.FirmaValuta.FirmaValutaNaziv} />
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Tooltip title={formatter.format(item.RacunID ? (item.TipRacunaID == 1 ? racunDetalji.Ukupno : 0) : item.Duguje)} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: item == current ? 700 : 400 }} primary={formatter.format(item.RacunID ? (item.TipRacunaID == 1 ? racunDetalji.Ukupno : 0) : item.Duguje)} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Tooltip title={formatter.format(item.RacunID ? (item.TipRacunaID == 2 ? racunDetalji.Ukupno : 0) : item.Potrazuje)} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText  primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: item == current ? 700 : 400 }} primary={formatter.format(item.RacunID ? (item.TipRacunaID == 2 ? racunDetalji.Ukupno : 0) : item.Potrazuje)} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Tooltip title={formatter.format(item.RacunID ? racunDetalji.Ostalo : item.Otvoreno) + (item.PrisilnoZatvoreno ? "*" : "")} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText  primaryTypographyProps={{ noWrap:true, textAlign:"right", textAlign:"right", fontWeight: item == current ? 700 : 400 }} primary={formatter.format(item.RacunID ? racunDetalji.Ostalo : item.Otvoreno) + (item.PrisilnoZatvoreno ? "*" : "")} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Tooltip title={formatter.format(item.RacunID ? racunDetalji.Placeno : item.Zatvoreno) + (item.PrisilnoZatvoreno ? "*" : "")} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText  primaryTypographyProps={{ noWrap:true, textAlign:"right", fontWeight: item == current ? 700 : 400 }} primary={formatter.format(item.RacunID ? racunDetalji.Placeno : item.Zatvoreno) + (item.PrisilnoZatvoreno ? "*" : "")} />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Tooltip title={item.Napomena} enterDelay={200} enterNextDelay={200}>
                                                        <ListItemText  primaryTypographyProps={{ noWrap:true, textAlign:"left", paddingLeft:"15px", fontWeight: item == current ? 700 : 400 }} primary={item.Napomena} />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                        {data.filter(d => d.PrisilnoZatvoreno).length > 0 ? (
                            <div>&nbsp;*&nbsp;Prisilno zatvoreni dokumenti</div>
                        ) : null}
                    </Box>
                ) : null}

            {!data || data.length == 0 ? <div style={{textAlign:"center"}}>{noRowsMessage || "Ne postoji niti jedna stavka."}</div> : null}
            
            {dataType=="evidencije" && !Util.isMobileDevice() && data.filter(e => e.TipEvidencijeID == 1).length > 0 ? (
                <Typography variant="button" gutterBottom component="div" style={{textAlign:"left", width:"100%", marginTop:"3px"}}>
                    Ukupno evidentirano vrijeme: <b>{props.evidentiranoVrijeme}</b>
                </Typography>
            ) : null}

            {dataType=="evidencije" && !Util.isMobileDevice() && data.filter(e => e.TipEvidencijeID == 2).length > 0 ? (
                <Typography variant="button" gutterBottom component="div" style={{textAlign:"left", width:"100%"}}>
                    Ukupni iznos troškova: <b>{props.ukupnoTroskova}</b>
                </Typography>
            ) : null}

            {props.ukupnoTarifa ? (
                <Typography variant="button" gutterBottom component="div" style={{textAlign:"left", width:"100%"}}>
                    Ukupni iznos tarifa: <b>{props.ukupnoTarifa}</b>
                </Typography>
            ) : null}

            {dataType=="racunskeStavke" ? (
                <Box style={{width:"100%", display:"flex", justifyContent:"flex-end"}}>
                    <Typography variant="button" gutterBottom component="span" style={{marginRight:"15px"}}>
                        Iznos: <b>{props.ukupniIznos}</b>
                    </Typography>
                    <Typography variant="button" gutterBottom component="span" style={{marginRight:"15px"}}>
                        Porez: <b>{props.ukupniPorez}</b>
                    </Typography>
                    <Typography variant="button" gutterBottom component="span" style={{marginRight:"15px"}}>
                        Ukupno: <b>{props.sveukupno}</b>
                    </Typography>
                </Box>
            ) : null}
            
            <Box style={{width:"100%"}} display="flex" sx={{display:{ xs:"none", sm:"none", md:"flex" }, justifyContent:"flex-end"}}  marginTop="10px">
                {dataType=="racunskeStavke" ? (
                    <Button variant="contained" startIcon={<MoveUpIcon></MoveUpIcon>} style={{marginRight:"5px"}}  onClick={props.prebaciNaDrugiRacun} disabled={!current || !current.RacunskaStavkaID || disableButtons}>Prebaci na drugi račun</Button>
                ) : null}

                {dataType=="racunskeStavke" || dataType=="stavkeSpecifikacije" ? (
                    <Button variant="contained" startIcon={<VisibilityIcon></VisibilityIcon>} style={{marginRight:"5px"}}  onClick={props.edit} disabled={disableTakeALook || !current}>Pogledaj</Button>
                ) : null}

                {dataType=="korisnik" ? (
                    <FormControlLabel 
                        labelPlacement="end" 
                        label={<Typography style={{fontSize:'unset', marginRight:"15px"}}>Sakrij deaktivirane</Typography>}
                        control={<Checkbox checked={props.sakrijDeaktivirane} onChange={e => props.setSakrijDeaktivirane(e.target.checked)}></Checkbox>}>
                    </FormControlLabel>
                ) : null}
                {dataType == "zadaci" && props.omoguciZadatakEvidencija ? (
                    <Button onClick={props.zadatakEvidencija} startIcon={props.zadatakEvidencijaIcon} disabled={current == null || props.disableZadatakEvidencija || current.TipZadatakStatusID == 3 || current.TipZadatakStatusID == 4} style={{marginRight:"5px"}} variant="outlined">
                        Evidencija
                    </Button>
                ) : null}
                {dataType == "blagdani" ? (
                    <Button onClick={props.automatskoPopunjavanje} style={{marginRight:"5px"}} startIcon={props.automatskoPopunjavanjeIcon} variant="outlined">Automatski popuni</Button>
                ) : null}
                {dataType != "rezultatPretrage" ?
                <Button color="success" variant="contained" onClick={add} disabled={disableButtons || !canModify || disableAddButton}>
                    <Grid container>
                        <Grid item >
                            <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap'}}>
                                <AddIcon  />
                            </div>
                        </Grid>
                        <Grid item sx={{display: { xs: "none", md: "block" }}}>
                            <div>{dataType=="evidencije" ? <div>&nbsp;D&#818;odaj aktivnost</div> : dataType=="klijenti" || dataType=="predmeti" || dataType=="placanja" || dataType=="cjenik" ? <div>&nbsp;D&#818;odaj</div> : <div>&nbsp;Dodaj</div>}</div>
                        </Grid>
                    </Grid>
                </Button> : null}
                {dataType=="evidencije" ? (
                    <Button color="success" variant="contained" onClick={props.addTrosak} disabled={disableAddButton || !canModify} style={{marginLeft:"5px"}}>
                        <Grid container>
                            <Grid item >
                                <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap'}}>
                                    <AddIcon  />
                                </div>
                            </Grid>
                            <Grid item>
                                <div>&nbsp;Dodaj t&#818;rošak</div>
                            </Grid>
                        </Grid>
                    </Button>
                ) : null}
                {dataType != "rezultatPretrage" ? (
                    <Button variant="contained" onClick={edit} style={{marginLeft:"5px"}} disabled={current == null || disableEdit || disableButtons}>
                        <Grid container>
                            <Grid item >
                                <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap'}}>
                                    {canModify ? <EditIcon /> : <SearchIcon />}
                                </div>
                            </Grid>
                            <Grid item sx={{display: { xs: "none", md: "block" }}}>
                                <div>&nbsp;{canModify ? "Uredi" : "Pogledaj"}</div>
                            </Grid>
                        </Grid>
                    </Button>
                ) : null}
                {dataType == "zadaci" ? (
                    <Button style={{marginLeft:"5px"}} color="error" onClick={props.ponisti} startIcon={props.ponistiIcon} disabled={current == null || current.TipZadatakStatusID == 3 || current.TipZadatakStatusID == 4 || disableDeleteButton} variant="outlined">
                        Poništi
                    </Button>
                ) : null}
                {dataType != "rezultatPretrage" ?
                <Button color="error" variant="contained" onClick={del} style={{marginLeft:"5px" }} disabled={current == null || !canModify || disableButtons || disableDeleteButton}>
                    <Grid container>
                        <Grid item >
                            <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap'}}>
                                <DeleteIcon />
                            </div>
                        </Grid>
                        <Grid item sx={{display: { xs: "none", md: "block" }}}>
                            <div>&nbsp;{deleteText || "Briši"}</div>
                        </Grid>
                    </Grid>
                </Button> : null}
            </Box>
            
            {dataType=="evidencije" || dataType=="zadaci" ? (
                <Grid container sx={{display:{ xs:"block", sm:"block", md:"none", lg:"none", xl:"none" }}} marginTop="10px">
                    {dataType == "zadaci" && props.omoguciZadatakEvidencija ? (
                        <Grid item xs={12} marginBottom="2px">
                            <Button style={{width:"100%"}} onClick={props.zadatakEvidencija} startIcon={props.zadatakEvidencijaIcon} disabled={current == null || props.disableZadatakEvidencija || current.TipZadatakStatusID == 3 || current.TipZadatakStatusID == 4} variant="outlined">
                                Evidencija
                            </Button>
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <Button color="success" disabled={disableButtons} style={{width:"100%"}} variant="contained" onClick={add} >
                            <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap'}}>
                                <AddIcon  />
                                {dataType=="evidencije" ? " Dodaj aktivnost" : " Dodaj"}
                            </div>
                        </Button>
                    </Grid>
                    {dataType=="evidencije" ? (
                        <Grid item xs={12} marginTop="2px">
                            <Button color="success" variant="contained" onClick={props.addTrosak} style={{width:"100%"}}>
                                <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap'}}>
                                    <AddIcon  />
                                    &nbsp;Dodaj trošak
                                </div>
                            </Button>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} marginTop="2px">
                        <Button variant="contained" onClick={edit} style={{width:"100%"}} disabled={current == null || disableEdit || disableButtons}>
                            <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap'}}>
                                <EditIcon />
                                &nbsp;Uredi
                            </div>                     
                        </Button>
                    </Grid>
                    {dataType == "zadaci" ? (
                        <Grid item xs={12} marginTop="2px">
                            <Button style={{width:"100%"}} color="error" onClick={props.ponisti} startIcon={props.ponistiIcon} disabled={current == null || current.TipZadatakStatusID == 3 || current.TipZadatakStatusID == 4 || disableDeleteButton} variant="outlined">
                                Poništi
                            </Button>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} marginTop="2px">
                        <Button color="error" variant="contained" onClick={del} style={{width:"100%"}} disabled={current == null || !canModify || disableButtons || disableDeleteButton}>
                            <div style={{display: 'flex', alignItems: 'center',flexWrap: 'wrap'}}>
                                <DeleteIcon />
                                &nbsp;Briši
                            </div>
                        </Button>
                    </Grid>
                </Grid>
            ) : null}
        </ComponentContainer>

    );

}

export default AddEditDelForm;