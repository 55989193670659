import { Box, Button, Checkbox, Radio, ClickAwayListener, Container, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemText, Menu, MenuItem, Modal, Paper, Popper, Select, TextField, Tooltip, Typography, Stack, Divider, CircularProgress, circularProgressClasses } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import api from "../util/api";
import database from "../util/database";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import Util from "../util/util";
import KlijentForm from "./klijentForm";
import SearchIcon from "@mui/icons-material/Search";
import AddEditDelForm from "./addEditDelForm";
import AlertDialog from "./alertdialog";
import { CheckBox, Label } from "@mui/icons-material";
import InventoryIcon from "@mui/icons-material/Inventory";
import Predmeti from "./predmeti";
import CloseIcon from "@mui/icons-material/Close";
import GroupIcon from "@mui/icons-material/Group";
import ExitToApp from "@mui/icons-material/ExitToApp";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Kontakti from "./kontakti";
import PaymentsIcon from "@mui/icons-material/Payments";
import ErrorAlert from "./errorAlert";
import FirmaForm from "../components/firmaForm";
import FinancijskaKartica from "./financijskaKartica";
import GlobalContext from "../components/globalContext";
import BarChartIcon from '@mui/icons-material/BarChart';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ErrorIcon from '@mui/icons-material/Error';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    border: "2px solid #000",
    p: 6,
    padding: "15px",
    maxHeight: "95%",
    maxWidth: "95%",
};

function Postavke(props) {
    const useConstructor = Util.useConstructor();
    const [loading, setLoading] = useState(true);
    const global = useContext(globalContext);
    const [firma, setFirma] = useState();
    const [kapacitetInfo, setKapacitetInfo] = useState({
        Iskoristeno : 0,
        Ukupno : 0,
        IskoristenoPostotak : 0,
        Cijena : 0,
        Opcije: [],
        Message: "<div></div>"
    });
    const [barkodNaplata, setBarkodNaplata] = useState({
        BrojRacuna : 0,
        Omogucen : false,
        Cijena : 0,
        Valuta : "",
        Message : ""
    });
    const [fiskalneBlagajne, setFiskalneBlagajne] = useState({
        Ukupno : 0,
        Aktivno : 0,
        Message : "",
        Cijena: ""
    });
    const [kapacitetOpcije, setKapacitetOpcije] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        api.get("firma/get?id=" + database.get("user").firmaID, (data) => {
            setFirma(data);
            api.get("postavke/pregledinfo/" + global.user.firmaID, (data) => {
                setKapacitetInfo(data.KapacitetInfo);
                setBarkodNaplata(data.BarkodNaplata);
                setFiskalneBlagajne(data.FiskalneBlagajne);
                setLoading(false);
            }, (data) => {
                setErrorMessage(data ? (data.Message || data.toString()) : "");
                setLoading(false);
            })
        }, (data) => {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        });
    });

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container spacing={1}>
                <Grid item xs={9} style={{padding:"10px"}}>
                    <FormControl fullWidth style={{ marginTop: "5px !important" }}>
                        {/* <FinancijskaKartica klijenti={[]}></FinancijskaKartica> */}
                        <FirmaForm data={firma}></FirmaForm>
                    </FormControl>
                </Grid>
                <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
                <Grid item xs={3} style={{padding:"10px"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h4" gutterBottom component="div" style={{ marginBottom: "20px" }}>
                                <BarChartIcon fontSize="medium" style={{ marginRight: "15px" }}></BarChartIcon>
                                Pregled
                            </Typography>
                        </Grid> 
                        <Grid item xs={11}>
                            <Typography variant="h6" gutterBottom component="div" style={{ marginBottom: "15px" }}>
                                Kapacitet elektroničke arhive
                            </Typography>
                        </Grid> 
                        <Grid item xs={1} style={{marginBottom: "15px", display:"flex", alignItems:"flex-end"}}>
                            <Tooltip title={
                                <div style={{fontSize:"10pt"}}>
                                    <div dangerouslySetInnerHTML={{__html: kapacitetInfo.Message}}></div>
                                    <br></br>
                                    <div>Dostupne opcije možete pogledati <a onClick={() => setKapacitetOpcije(true)} style={{color:"white"}}><b><u>ovdje</u></b></a>.</div> 
                                </div>
                            }>
                                <span>
                                    <HelpOutlineIcon></HelpOutlineIcon>
                                </span>
                            </Tooltip>
                        </Grid> 
                        <Grid item xs={12} style={{textAlign:"center"}}>
                            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                                <CircularProgress 
                                    variant="determinate" 
                                    size={150} 
                                    sx={{
                                        color: kapacitetInfo.IskoristenoPostotak < 90 ? "blue" : "red",
                                        borderRadius: '50%',
                                        boxShadow: `inset 0 0 0 ${3.7/44*150}px lightgrey`
                                    }} 
                                    value={kapacitetInfo.IskoristenoPostotak} />
                                <Box
                                    sx={{
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        position: 'absolute',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography variant="caption" component="div" color="text.secondary">
                                        {kapacitetInfo.IskoristenoPostotak + "%"}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={8} marginTop="15px">
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                Opcija:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} marginTop="15px" style={{textAlign:"right"}}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                {kapacitetInfo.OpcijaNaziv}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                Iskorišteno:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{textAlign:"right"}}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                {kapacitetInfo.Iskoristeno} MB
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                Ukupan kapacitet:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{textAlign:"right"}}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                {kapacitetInfo.Ukupno} MB
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                Maksimalna veličina datoteke:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{textAlign:"right"}}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                {kapacitetInfo.MaxVelicinaDatoteke} MB
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                Cijena:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{textAlign:"right"}}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                {kapacitetInfo.Cijena}
                            </Typography>
                        </Grid>
                        <Grid item xs={11}>
                            <Typography variant="h6" gutterBottom component="div" style={{ marginTop: "15px" }}>
                                Ispis barkoda na računima
                            </Typography>
                        </Grid> 
                        <Grid item xs={1} style={{marginTop: "15px", display:"flex", alignItems:"flex-end"}}>
                            <Tooltip title={
                                <div style={{fontSize:"10pt"}}>
                                    <div dangerouslySetInnerHTML={{__html: barkodNaplata.Message}}></div>
                                </div>
                            }>
                                <span>
                                    <HelpOutlineIcon></HelpOutlineIcon>
                                </span>
                            </Tooltip>
                        </Grid> 
                        <Grid item xs={8}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                Omogućeno:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{textAlign:"right"}}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                {barkodNaplata.Omogucen ? <CheckBoxIcon style={{marginRight:"-3px"}}></CheckBoxIcon> : <CheckBoxOutlineBlankIcon style={{marginRight:"-3px"}}></CheckBoxOutlineBlankIcon>}
                            </Typography>
                        </Grid>
                        {barkodNaplata.Omogucen ? (
                            <>
                                <Grid item xs={4}>
                                    <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                        Cijena:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} style={{textAlign:"right"}}>
                                    <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                        {barkodNaplata.Cijena + " " + barkodNaplata.Valuta + " / " + barkodNaplata.BrojRacuna + " računa"}
                                    </Typography>
                                </Grid>
                            </>
                        ) : null}
                        <Grid item xs={11}>
                            <Typography variant="h6" gutterBottom component="div" style={{ marginTop: "15px" }}>
                                Fiskalne blagajne
                            </Typography>
                        </Grid> 
                        <Grid item xs={1} style={{marginTop: "15px", display:"flex", alignItems:"flex-end"}}>
                            <Tooltip title={
                                <div style={{fontSize:"10pt"}}>
                                    <div dangerouslySetInnerHTML={{__html: fiskalneBlagajne.Message}}></div>
                                    <br></br>
                                    <div>Jedinična cijena fiskalne blagajne: <b>{fiskalneBlagajne.Cijena}</b></div>
                                </div>
                            }>
                                <span>
                                    <HelpOutlineIcon></HelpOutlineIcon>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                Broj blagajni:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{textAlign:"right"}}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                {fiskalneBlagajne.Ukupno}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                Aktivnih:
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{textAlign:"right"}}>
                            <Typography variant="caption" gutterBottom component="div" color="text.secondary">
                                {fiskalneBlagajne.Aktivno}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {kapacitetOpcije ? (
                <AlertDialog
                    title={"Opcije kapaciteta elektroničke arhive"}
                    message={
                        <div>
                            <table>
                                <tr>
                                    <th style={{border: "1px solid #dddddd", padding:"10px", textAlign:"right", width:"42rem"}}><b>Opcija</b></th>
                                    <th style={{border: "1px solid #dddddd", padding:"10px", textAlign:"right", width:"42rem"}}><b>Maksimalna veličina datoteke (MB)</b></th>
                                    <th style={{border: "1px solid #dddddd", padding:"10px", textAlign:"right", width:"42rem"}}><b>Ukupni kapacitet arhive (MB)</b></th>
                                    <th style={{border: "1px solid #dddddd", padding:"10px", textAlign:"right", width:"12rem"}}><b>Cijena</b></th>
                                </tr>
                                {kapacitetInfo.Opcije.map((o) => {
                                    return (
                                        <tr>
                                            <td style={{border: "1px solid #dddddd", padding:"10px", textAlign:"right", width:"42rem"}}>{o.Naziv}</td>
                                            <td style={{border: "1px solid #dddddd", padding:"10px", textAlign:"right", width:"42rem"}}>{o.MiBPerFile}</td>
                                            <td style={{border: "1px solid #dddddd", padding:"10px", textAlign:"right", width:"42rem"}}>{o.MiBTotal}</td>
                                            <td style={{border: "1px solid #dddddd", padding:"10px", textAlign:"right", width:"16rem"}}>{o.Cijena + " " + o.Valuta}</td>
                                        </tr>
                                    );
                                })}
                            </table>
                        </div>
                    }
                    cancelHidden={true}
                    confirmHidden={true}
                    okHidden={false}
                    okLabel="U redu"
                    okAction={() => setKapacitetOpcije(false)}
                ></AlertDialog>
            ) : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default Postavke;
