import React from "react";
import api from "../util/api";
import { message, Row, Form, Input } from "antd";
import Spinner from "../components/spinner";
import database from "../util/database";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { Button, Box, ClickAwayListener, Grid, Modal, Typography } from "@mui/material";
import globalContext from "./globalContext";
import AddEditDelForm from "./addEditDelForm";
import OdjelForm from "./odjelForm";
import AlertDialog from "./alertdialog";
import Snack from "../controls/snack";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

class PasswordChange extends React.Component {
    constructor(props) {
        super(props);
        let params = Util.getParametersFromUrl(window.location);
        this.state = { snackOpen: false, snackType: "success", snackMessage: "Uspješno ste promijenili lozinku.", loading: false, setNewPasswordMode: params.uid ? true : false };

        //setNewPasswordMode - zaboravljena lozinka
    }

    success = (data) => {
        this.setState({ loading: false, snackMessage: "Uspješno ste promijenili lozinku.", snackOpen: true });
    };

    fail = () => {
        this.setState({ loading: false });
    };

    update = () => {
        this.setState({ loading: true });
        this.formRef.current
            .validateFields()
            .then((values) => {
                if (this.state.setNewPasswordMode) {
                    let params = Util.getParametersFromUrl(window.location);
                    values.Username = params.user;
                    values.NewPasswordConfirm = values.ConfirmPassword;
                    values.UID = params.uid;
                    api.post("password/setnew", values, this.updateSuccess, this.updateFail);
                } else {
                    values.KorisnikID = database.get("user").korisnikID;
                    values.NewPasswordConfirm = values.ConfirmPassword;
                    this.setState({ loading: true });
                    api.post("password/update", values, this.updateSuccess, this.updateFail);
                }
            })
            .catch((err) => {
                this.setState({ loading: false });
            });
    };

    updateSuccess = (data) => {
        if (data.ErrorMessage) {
            this.setState({ loading: false, snackMessage: data.ErrorMessage, snackType: "error", snackOpen: true });
        } else {
            this.setState({ loading: false, snackMessage: "Uspješno ste promijenili lozinku!", snackType: "success", snackOpen: true });
            // window.location = "#/home";
        }
    };

    updateFail = () => {
        this.setState({ loading: false });
    };

    formRef = React.createRef();

    passwordPattern = {
        pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
        message: `Lozinka mora sadržavati minimalno 7 znakova, i barem 1 broj.`,
    };

    render() {
        const layout = {
            labelCol: { span: 10 },
            wrapperCol: { span: 15 },
        };

        return (
            <ComponentContainer loading={this.state.loading}>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ textAlign: "left", marginBottom: "15px" }}>
                        {this.state.setNewPasswordMode ? (
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    window.location = "#/login";
                                }}
                                startIcon={<ArrowBackIcon></ArrowBackIcon>}
                            >
                                Idi na prijavu
                            </Button>
                        ) : null}
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        {this.state.setNewPasswordMode ? (
                            <Typography gutterBottom component="div" style={{ marginBottom: "15px" }}>
                                {/* Molimo upišite novu lozinku */}
                            </Typography>
                        ) : null}
                    </Grid>
                    <Grid item xs={12}>
                        <Row type="flex" justify="center" align="middle" style={{}}>
                            <Form {...layout} red style={{ width: "400px", textAlign: "center" }} ref={this.formRef} className="login-form">
                                {!this.state.setNewPasswordMode ? (
                                    <Form.Item label="Stara lozinka" name="OldPassword" rules={[{ required: true, message: "Molimo unesite staru lozinku" }]}>
                                        <Input.Password />
                                    </Form.Item>
                                ) : null}
                                <Form.Item label="Nova lozinka" name="NewPassword" rules={[{ required: true, message: "Molimo unesite novu lozinku" }, this.passwordPattern]}>
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item label="Potvrdite novu lozinku" name="ConfirmPassword" rules={[{ required: true, message: "Molimo potvrdite novu lozinku" }, this.passwordPattern]}>
                                    <Input.Password />
                                </Form.Item>
                                <Button style={{ float: "right" }} onClick={() => this.update()} variant="contained">
                                    Spremi
                                </Button>
                            </Form>
                        </Row>
                    </Grid>
                </Grid>
                <Snack open={this.state.snackOpen} type={this.state.snackType} onClose={() => this.setState({ snackOpen: false })} message={this.state.snackMessage} />
            </ComponentContainer>
        );
    }
}
export default PasswordChange;
