import { Autocomplete, Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useState, useCallback, useEffect } from "react";
import ComponentContainer from "./componentContainer";
import AddCardIcon from '@mui/icons-material/AddCard';
import EditIcon from '@mui/icons-material/Edit';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import Util from "../util/util";
import globalContext from "./globalContext";
import api from "../util/api";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

const tipoviPlacanja = [
    { Naziv: "Plaćanje izlaznog računa", Ulaz: true},
    { Naziv: "Plaćanje ulaznog računa", Ulaz: false}
];

function PlacanjeForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formPlacanje = props.formPlacanje;
    const close = props.close;
    const loadPlacanja = props.loadPlacanja;
    const klijentID = props.klijentID;
    const loading = props.loading;
    const setLoading = props.setLoading;
    
    const [datum, setDatum] = useState(new Date());
    const [iznos, setIznos] = useState(Util.toStringDecimal(0));
    const [firmaValute, setFirmaValute] = useState([]);
    const [firmaValuta, setFirmaValuta] = useState(null);
    const [opis, setOpis] = useState("");
    const [napomena, setNapomena] = useState("");
    const [tipPlacanja, setTipPlacanja] = useState(tipoviPlacanja.at(0));
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        api.get("placanje/getplacanjeforminfo/" + global.user.firmaID, loadPlacanjeFormInfoSuccess, loadPlacanjeFormInfoFail);
    });

    function loadPlacanjeFormInfoSuccess(data) {
        setFirmaValute(data.FirmaValute);
        if (formPlacanje) {
            setDatum(formPlacanje.Datum);
            setIznos(Util.toStringDecimal(formPlacanje.Ulaz + formPlacanje.Izlaz));
            setFirmaValuta(formPlacanje.FirmaValuta);
            setOpis(formPlacanje.Opis);
            setNapomena(formPlacanje.Napomena);
            setTipPlacanja(formPlacanje.Ulaz > 0 ? tipoviPlacanja.at(0) : tipoviPlacanja.at(1));
        } else {
            setFirmaValuta(data.DefaultFirmaValuta);
        }
        setLoading(false);
    }   

    function loadPlacanjeFormInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save() {
        let resource = {
            KlijentID : klijentID,
            PlacanjeID : formPlacanje ? formPlacanje.PlacanjeID : -1,
            Datum : datum,
            Opis : opis,
            Napomena: napomena,
            Ulaz : tipPlacanja.Ulaz ? iznos : 0,
            Izlaz : tipPlacanja.Ulaz ? 0 : iznos,
            FirmaValutaID : firmaValuta.FirmaValutaID,
            KorisnikID : global.drugiZaposlenik.KorisnikID
        };
        setLoading(true);
        api.post("placanje/save", resource, saveSuccess, saveFail);
    }

    function saveSuccess() {
        //setLoading(false);
        loadPlacanja(klijentID, null);
        close();
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleIznosFocusOut() {
        if (iznos == "" || iznos.charAt(0) == '-') {
            setIznos("0.00");
        } else if (!isNaN(parseFloat(iznos))) {
            let value = iznos;
            let i = 0;
            while (iznos.length > i + 1 && iznos.charAt(i) == '0' && iznos.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            ;
            value = Util.toStringDecimal(value);
            setIznos(value);
        }

    }

    const handleKeyPressShorcuts = useCallback((event) => {
        if (event.altKey) {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if (charCode == 'p') {
                event.preventDefault();
                if(!(loading || isNaN(new Date(datum)) || iznos <= 0 || !firmaValuta || !tipPlacanja)){
                    save();
                }
            }
            if (charCode == 'o') {
                event.preventDefault();
                if (!loading) {
                    close();
                }
            }
        }
    }, [
        loading, 
        datum, 
        iznos, 
        firmaValuta, 
        tipPlacanja, 
        opis, 
        napomena, 
        tipPlacanja,
        klijentID,
        formPlacanje
    ]);

    const handleEsc = useCallback((event) => {
        if(event.keyCode == 27){
            event.preventDefault();
            if (!loading) {
                close();
            }            
        }
    }, [loading]);


    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressShorcuts);
        document.addEventListener('keyup', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleKeyPressShorcuts);
            document.removeEventListener('keyup', handleEsc);
        };
    }, [handleKeyPressShorcuts]);


    return (
        <ComponentContainer loading={loading}>
            <Grid container className="padding-grid">
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formPlacanje ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <AddCardIcon fontSize="medium" style={{marginRight:"15px"}}></AddCardIcon>
                            )}
                            {formPlacanje ? "Uređivanje plaćanja" : "Novo plaćanje"}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={5} marginTop="2px">
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker
                            label="Datum"
                            value={datum}
                            inputFormat="dd.MM.yyyy"
                            maxDate={new Date()}
                            onChange={v => setDatum(v)}
                            renderInput={(params) => <TextField style={{width:"99%"}} {...params} size="small" error={isNaN(new Date(datum))} helperText={isNaN(new Date(datum)) ? "Pogrešan format datuma!" : ""}/>}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField value={iznos} type="number" InputProps={{ inputProps: { min: 0.00 } }} size="small" error={iznos <= 0} label="Iznos"  onBlur={handleIznosFocusOut} onChange={e => {setIznos(e.target.value)}} required></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            options={firmaValute}
                            autoHighlight
                            value={firmaValuta}
                            getOptionLabel={(option) => option.FirmaValutaNaziv}
                            onChange={(e,v) => {if (v) setFirmaValuta(v)}}
                            disableClearable
                            renderInput={(params) => <TextField {...params} label="Valuta" size="small" style={{width:"99%"}} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField value={opis || ""}  size="small" multiline rows={3} label="Opis" inputProps={{ maxLength: 250 }} onChange={e => setOpis(e.target.value)}></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField value={napomena || ""} size="small" multiline rows={3} label="Napomena" inputProps={{ maxLength: 1024 }} onChange={e => setNapomena(e.target.value)}></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            options={tipoviPlacanja}
                            value={tipPlacanja}
                            autoHighlight
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {if (v) setTipPlacanja(v)}}
                            disableClearable
                            renderInput={(params) => <TextField {...params} label="Tip plaćanja" size="small" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" color="success" style={{marginRight:"10px"}} disabled={loading || isNaN(new Date(datum)) || iznos <= 0 || !firmaValuta || !tipPlacanja}><SaveIcon style={{marginRight:"10px"}} /> Sp&#818;remi</Button>
                        <Button variant="outlined" color="error" onClick={close} ><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>O&#818;dustani</Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default PlacanjeForm;