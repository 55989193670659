import { Autocomplete, Button, FormControl, Grid, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import ComponentContainer from "./componentContainer"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box } from "@mui/system";


export default function PredlosciPoruka(props) {

    const emailSubject = props.emailSubject;
    const emailBody = props.emailBody;
    const emailParametri = props.emailParametri;
    const handleEmailSubjectChange = props.handleEmailSubjectChange;
    const handleEmailBodyChange = props.handleEmailBodyChange;
    const vrstaEmailPoruke = props.vrstaEmailPoruke;
    const setVrstaEmailPoruke = props.setVrstaEmailPoruke;
    const vrsteEmailPoruka = props.vrsteEmailPoruka;
    const close = props.close;
    const vrstePorukaSustava = props.vrstePorukaSustava;
    const vrstaPorukeSustava = props.vrstaPorukeSustava;
    const setVrstaPorukeSustava = props.setVrstaPorukeSustava;
    const handlePorukaSustavaChange = props.handlePorukaSustavaChange;
    const porukaSustava = props.porukaSustava
    const porukeSustavaParametri = props.porukeSustavaParametri;

    const [vrstaPoruka, setVrstaPoruka] = useState("email");

    return (
        <ComponentContainer>
            <Tabs value={vrstaPoruka} style={{width:"100%", marginBottom:"15px"}} centered onChange={(e,v) => setVrstaPoruka(v)}>
                <Tab value="email" style={{width:"100%"}} label="Email poruke"></Tab>
                <Tab value="porukeSustava" style={{width:"100%"}} label="Poruke sustava"></Tab>
            </Tabs>
            {vrstaPoruka == "email" ? (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>  
                            <Autocomplete style={{width:"100%"}}
                                disablePortal
                                disableClearable
                                options={vrsteEmailPoruka}
                                getOptionLabel={(option) => option.Naziv}
                                value={vrstaEmailPoruke}
                                autoHighlight
                                onChange={(e,v) => setVrstaEmailPoruke(v)}
                                renderInput={(params) => <TextField {...params} label="Vrsta poruke" size="small"/>}>
                            </Autocomplete>
                        </FormControl>
                    </Grid>
                    <Grid item xs={11}>
                        <FormControl fullWidth>
                            <TextField value={emailSubject} onChange={e => handleEmailSubjectChange(e.target.value)} label="Predmet" error={!emailSubject} size="small"></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title={vrstaEmailPoruke && emailParametri && emailParametri.length >= vrstaEmailPoruke.Vrijednost ? <pre>{emailParametri[vrstaEmailPoruke.Vrijednost - 1]}</pre> : ""}>
                            <span style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <HelpOutlineIcon></HelpOutlineIcon>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField value={emailBody} multiline rows={12} onChange={e => handleEmailBodyChange(e.target.value)} label="Sadržaj" error={!emailBody} size="small"></TextField>
                        </FormControl>
                    </Grid>
                </Grid>
            ) : null}
            {vrstaPoruka == "porukeSustava" ? (
                <Grid container spacing={1}>
                    <Grid item xs={11}>
                        <FormControl fullWidth>  
                            <Autocomplete style={{width:"100%"}}
                                disablePortal
                                disableClearable
                                options={vrstePorukaSustava}
                                getOptionLabel={(option) => option.Naziv}
                                value={vrstaPorukeSustava}
                                autoHighlight
                                onChange={(e,v) => setVrstaPorukeSustava(v)}
                                renderInput={(params) => <TextField {...params} label="Vrsta poruke" size="small"/>}>
                            </Autocomplete>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title={vrstaPorukeSustava && porukeSustavaParametri && porukeSustavaParametri.length >= vrstaPorukeSustava.Vrijednost ? <pre>{porukeSustavaParametri[vrstaPorukeSustava.Vrijednost - 1]}</pre> : ""}>
                            <span style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <HelpOutlineIcon></HelpOutlineIcon>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField value={porukaSustava} multiline rows={12} onChange={e => handlePorukaSustavaChange(e.target.value)} label="Poruka" error={!porukaSustava} size="small"></TextField>
                        </FormControl>
                    </Grid>
                </Grid>    
            ) : null}
            <Box style={{width:"100%", textAlign:"right", marginTop:"15px"}}>
                <Button color="error" variant="outlined" onClick={close}>Zatvori</Button>
            </Box>
        </ComponentContainer>
    );
}