import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import ArhivaFileForm from "./arhivaFileForm";
import ArhivaStavkaForm from "./arhivaStavkaForm";
import ComponentContainer from "./componentContainer";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Util from "../util/util";

function ArhivaChildForm(props) {

    const useConstructor = Util.useConstructor();

    const formArhivaChild = props.formArhivaChild;
    const setFormOpened = props.setFormOpened;
    const tipArhive = props.tipArhive;
    const elektronicka = props.elektronicka;
    const arhivaID = props.arhivaID;
    const arhivaBroj = props.arhivaBroj;
    const setCanceled = props.setCanceled;

    useConstructor(() => {
    });

    function save() {

    }

    return (
        <ComponentContainer>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    { elektronicka ? (
                        <ArhivaFileForm newMaster={false} formArhivaFile={formArhivaChild} arhivaID={arhivaID} setFormOpened={setFormOpened} setCanceled={setCanceled}></ArhivaFileForm>
                    ) : (
                       <ArhivaStavkaForm oznaka={tipArhive ? tipArhive.Oznaka : ""} arhivaBroj={arhivaBroj} formStavka={formArhivaChild} arhivaID={arhivaID} setFormOpened={setFormOpened} setCanceled={setCanceled}></ArhivaStavkaForm>
                    )}
                </Grid>
            </Grid>
        </ComponentContainer>
    );
}

export default ArhivaChildForm;