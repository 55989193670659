import { Autocomplete, Box, Button, FormControl, Grid, InputAdornment, Menu, MenuItem, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ComponentContainer from "./componentContainer";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Util from "../util/util";
import globalContext from "./globalContext";
import api from "../util/api";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import auth_Header from "../util/authHeader";
import excel from "../images/excel.png";
import word from "../images/word.png";
import pdf from "../images/pdf.png";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';


const aktivnostiPredmeta = [
    { Naziv: "Svi", Vrijednost: 0 },
    { Naziv: "Samo aktivni predmeti", Vrijednost: 1 },
];

const naplatnostiEvidencija = [
    { Naziv: "Sve", Vrijednost: 0 },
    { Naziv: "Samo naplatne evidencije", Vrijednost: 1 },
    { Naziv: "Samo nenaplatne evidencije", Vrijednost: 2 },
];

const fakturiranostiEvidencija = [
    { Naziv: "Sve", Vrijednost: 0 },
    { Naziv: "Samo fakturirane evidencije", Vrijednost: 1 },
    { Naziv: "Samo nefakturirane evidencije", Vrijednost: 2 },
];

const tipoviEvidencija = [
    { Naziv: "Sve", Vrijednost: 0 },
    { Naziv: "Samo aktivnosti", Vrijednost: 1 },
    { Naziv: "Samo troškovi", Vrijednost: 2 },
];

function Izvjestaji(props) {
    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const klijentiPredmeti = props.klijentiPredmeti || {
        Klijenti: [],
        Predmeti: [],
        KlijentiLastUpdate: null,
        PredmetiLastUpdate: null,
    };
    const loadKlijentiAndPredmeti = props.loadKlijentiAndPredmeti;

    const [loading, setLoading] = useState(true);
    const [reporti, setReporti] = useState([]);
    const [report, setReport] = useState(null);
    const [naplatnostEvidencija, setNaplatnostEvidencija] = useState(null);
    const [fakturiranostEvidencija, setFakturiranostEvidencija] = useState(null);
    const [aktivnostPredmeta, setAktivnostPredmeta] = useState(aktivnostiPredmeta[0]);
    const [datumOd, setDatumOd] = useState(new Date());
    const [datumDo, setDatumDo] = useState(new Date());
    const [tipEvidencija, setTipEvidencija] = useState(null);
    const [zaposlenici, setZaposlenici] = useState([]);
    const [klijenti, setKlijenti] = useState([]);
    const [predmeti, setPredmeti] = useState([]);
    const [zaposlenik, setZaposlenik] = useState(null);
    const [klijent, setKlijent] = useState(null);
    const [predmet, setPredmet] = useState(null);
    const [odjeli, setOdjeli] = useState([]);
    const [odjel, setOdjel] = useState(null);
    const [nacinOtvaranjaReporta, setNacinOtvaranjaReporta] = useState("_blank");
    const [anchorEl, setAnchorEl] = useState(null);
    const formatsOpened = Boolean(anchorEl);
    const [logoutAlert, setLogoutAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        // if (klijentiPredmeti.KlijentiLastUpdate != "" && klijentiPredmeti.KlijentiLastUpdate != null && klijentiPredmeti.PredmetiLastUpdate != "" && klijentiPredmeti.PredmetiLastUpdate != null) {
        //     let resource = {
        //         FirmaID: global.user.firmaID,
        //         KlijentiLastUpdate: klijentiPredmeti.KlijentiLastUpdate,
        //         PredmetiLastUpdate: klijentiPredmeti.PredmetiLastUpdate,
        //     };
        //     api.post("firma/checkifklijentiorpredmetiunchanged", resource, checkIfKlijentiOrPredmetiUnchangedSuccess, checkIfKlijentiOrPredmetiUnchangedFail);
        // }
        if (klijentiPredmeti.KlijentiLastUpdate != "" && klijentiPredmeti.KlijentiLastUpdate != null && klijentiPredmeti.PredmetiLastUpdate != "" && klijentiPredmeti.PredmetiLastUpdate != null) {
            let resource = {
                FirmaID : global.user.firmaID,
                KlijentiLastUpdate : klijentiPredmeti.KlijentiLastUpdate,
                PredmetiLastUpdate : klijentiPredmeti.PredmetiLastUpdate,
                KorisnikID : global.user.korisnikID
            }
            api.post("firma/checkeverything", resource, checkEverythingSuccess, checkEverythingFail);
        }

    });

    function checkIfKlijentiOrPredmetiUnchangedSuccess(data) {
        if (data.KlijentiChanged || data.PredmetiChanged) {
            loadKlijentiAndPredmeti(global.user.firmaID, getInfo, data.KlijentiChanged, data.PredmetiChanged);
        } else {
            getInfo();
        }
    }

    function checkIfKlijentiOrPredmetiUnchangedFail() {}

    function startLogout(){
        setLogoutAlert(false);
        Util.logout();
        window.location = "/#/login";
    }

    function checkEverythingSuccess(data) {
        if(data && (data.UserAccountValid == false || data.LogoutRequired)){
            setLoading(false);
            setLogoutAlert(true);
            return;          
        }

        if (data.CheckIfKlijentiOrPredmetiUnchangedResource == false) {
            loadKlijentiAndPredmeti(global.user.firmaID, getInfo, data.KlijentiChanged, data.PredmetiChanged);
        } else {
            getInfo();
        }
    }

    function checkEverythingFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }


    function getInfo() {
        api.get("reportsapi/getinfo/" + global.drugiZaposlenik.KorisnikID, loadInfoSuccess, loadInfoFail);
    }

    function loadInfoSuccess(data) {
        setReporti(data.Reporti);
        if (data.DefaultReport) {
            setReport(data.DefaultReport);
        } else {
            setReport(data.Reporti.length > 0 ? data.Reporti.at(0) : null);
        }
        setNaplatnostEvidencija(naplatnostiEvidencija.find((n) => n.Vrijednost == data.DefaultTipNaplatnostiID));
        setFakturiranostEvidencija(fakturiranostiEvidencija.find((f) => f.Vrijednost == data.DefaultTipFakturiranostiID));
        setTipEvidencija(tipoviEvidencija.find((t) => t.Vrijednost == data.DefaultTipEvidencijaID));
        let opcijeZaposlenika = [{ KorisnikID: 0, Ime: "Svi", Prezime: "" }, ...data.Zaposlenici];
        setZaposlenici(opcijeZaposlenika);
        setZaposlenik(opcijeZaposlenika.find((z) => z.KorisnikID == data.DefaultKorisnikID));
        let opcijeKlijenata = [{ KlijentID: 0, Naziv: "Svi" }, ...klijentiPredmeti.Klijenti];
        setKlijenti(opcijeKlijenata);
        setKlijent(opcijeKlijenata.find((k) => k.KlijentID == data.DefaultKlijentID));
        let opcijePredmeta = [{ PredmetID: 0, Naziv: "Svi" }, ...klijentiPredmeti.Predmeti];
        setPredmeti(opcijePredmeta);
        setPredmet(opcijePredmeta.find((p) => p.PredmetID == data.DefaultPredmetID));
        let opcijeOdjela = [{ OdjelID: 0, Naziv: "Svi" }, ...data.Odjeli];
        setOdjeli(opcijeOdjela);
        setOdjel(opcijeOdjela.find((o) => o.OdjelID == data.DefaultOdjelID));
        setDatumOd(data.DefaultDatumOd);
        setDatumDo(data.DefaultDatumDo);
        if (data.NacinOtvaranja) {
            setNacinOtvaranjaReporta(data.NacinOtvaranja);
        }
        setLoading(false);
    }

    function loadInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function izvjestaj(format) {
        //;
        setLoading(true);
        let printResource = {
            ReportPutanjaNaziv: report,
            DatumOd: datumOd,
            DatumDo: datumDo,
            TipEvidencijaID: tipEvidencija.Vrijednost,
            TipNaplatnostiID: naplatnostEvidencija.Vrijednost,
            TipFakturiranostiID: fakturiranostEvidencija.Vrijednost,
            ZaposlenikKorisnikID: zaposlenik.KorisnikID,
            TipAktivnostiPredmetaID: aktivnostPredmeta.Vrijednost,
            KlijentID: klijent.KlijentID,
            PredmetID: predmet.PredmetID,
            OdjelID: odjel.OdjelID,
            IzradioReportKorisnikID: global.drugiZaposlenik.KorisnikID,
            Format: format
        };
        let extension = ".pdf";
        if (format == "Word") {
            extension = ".docx";
        } else if (format == "Excel") {
            extension = "xlsx";
        }
        api.getReport(
            "reportsapi/ispisi",
            printResource,
            extension,
            nacinOtvaranjaReporta,
            () => setLoading(false),
            (data) => {
                setErrorMessage(data ? (data.Message || data.toString()) : "");
                setLoading(false);
            }
        );
    }

    useEffect(() => {
        if (zaposlenik && zaposlenik.KorisnikID > 0 && odjeli) {
            setOdjel(odjeli.find((o) => o.OdjelID == 0));
        }
    }, [zaposlenik, odjeli]);

    useEffect(() => {
        if (odjel && odjel.OdjelID > 0 && zaposlenici) {
            setZaposlenik(zaposlenici.find((z) => z.KorisnikID == 0));
        }
    }, [odjel, zaposlenici]);

    useEffect(() => {
        if (((klijent && klijent.KlijentID == 0) || (klijent && predmet && klijent.KlijentID != predmet.KlijentID)) && predmeti) {
            setPredmet(predmeti.find((p) => p.PredmetID == 0));
        }
    }, [klijent, predmeti]);

    useEffect(() => {
        if (report) {
            if(report.TipReportaID == 3){
                setAktivnostPredmeta(aktivnostiPredmeta[0]);
            }
            if(report.TipReportaID == 5){
                setAktivnostPredmeta(aktivnostiPredmeta[1]);
            }
        }
    }, [report]);

    function handleIzvjestajClick(event) {
        setAnchorEl(event.currentTarget);
    };

    function handleIzvjestajClose(event) {
        setAnchorEl(null);
    };

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container spacing={1} >
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom component="div">
                        <AssessmentIcon fontSize="medium" style={{ marginRight: "15px" }}></AssessmentIcon>
                        Pregled izvještaja
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            options={reporti}
                            value={report}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e, v) => {
                                if (v) setReport(v);
                            }}
                            disableClearable
                            renderInput={(params) => <TextField {...params} label="Vrsta izvještaja" size="small" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} marginTop="5px !important">
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils}>
                            <DatePicker style={{ width: "100%" }} label="Od datuma" value={datumOd} inputFormat="dd.MM.yyyy" maxDate={new Date()} onChange={(v) => setDatumOd(v)} renderInput={(params) => <TextField {...params} size="small" error={isNaN(new Date(datumOd))} helperText={isNaN(new Date(datumOd)) ? "Pogrešan format datuma!" : ""} />} />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6} marginTop="5px !important">
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                            <DatePicker style={{ width: "100%" }} label="Do datuma" value={datumDo} inputFormat="dd.MM.yyyy" maxDate={new Date()} onChange={(v) => setDatumDo(v)} renderInput={(params) => <TextField {...params} size="small" error={isNaN(new Date(datumDo))} helperText={isNaN(new Date(datumDo)) ? "Pogrešan format datuma!" : ""} />} />
                        </LocalizationProvider>
                    </FormControl>
                </Grid>
                {!report || report.TipReportaID != 4 ? (
                    <Grid item xs={12} sm={12} md={4} marginTop="3px">
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={tipoviEvidencija}
                                value={tipEvidencija}
                                getOptionLabel={(option) => option.Naziv}
                                onChange={(e, v) => {
                                    if (v) setTipEvidencija(v);
                                }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="Tip evidencija" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {!report || report.TipReportaID != 4 ? (
                    <Grid item xs={12} sm={12} md={4} marginTop="3px">
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={naplatnostiEvidencija}
                                value={naplatnostEvidencija}
                                getOptionLabel={(option) => option.Naziv}
                                onChange={(e, v) => {
                                    if (v) setNaplatnostEvidencija(v);
                                }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="Naplatnost" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {!report || report.TipReportaID != 4 ? (
                    <Grid item xs={12} sm={12} md={4} marginTop="3px">
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={fakturiranostiEvidencija}
                                value={fakturiranostEvidencija}
                                getOptionLabel={(option) => option.Naziv}
                                onChange={(e, v) => {
                                    if (v) setFakturiranostEvidencija(v);
                                }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="Fakturiranost" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {!report || report.TipReportaID != 4 ? (
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={zaposlenici}
                                value={zaposlenik}
                                getOptionLabel={(option) => option.Ime + " " + option.Prezime}
                                onChange={(e, v) => {
                                    if (v) setZaposlenik(v);
                                }}
                                renderOption={(props, option) => {
                                    return option ? (
                                        <li {...props} style={{color:option.TipStatusaKorisnikaID == 1 || option.KorisnikID == 0 ? "black" : "red"}} key={"korisnik" + option.KorisnikID}>
                                            {option.Ime + " " + option.Prezime}
                                        </li>
                                    ) : null;
                                }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} sx={{ input: { color: zaposlenik && zaposlenik.TipStatusaKorisnikaID != 1 && zaposlenik.KorisnikID != 0 ? "red" : "black" } }} label="Korisnik" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {!report || report.TipReportaID != 4 ? (
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={odjeli}
                                value={odjel}
                                getOptionLabel={(option) => option.Naziv}
                                onChange={(e, v) => {
                                    if (v) setOdjel(v);
                                }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="Odjel" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {!report || report.TipReportaID != 4 ? (
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                options={klijenti}
                                autoHighlight
                                value={klijent}
                                getOptionLabel={(option) => (option.KlijentID > 0 ? option.Broj + " - " : "") + option.Naziv}
                                onChange={(e, v) => {
                                    if (v) setKlijent(v);
                                }}
                                disableClearable
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 || option.KlijentID <= 0 ? "black" : "red"}}>
                                            {(option.KlijentID > 0 ? option.Broj + " - " : "") + option.Naziv}
                                        </li>
                                    );
                                }}
                                filterOptions={(options, state) => options.filter((option) => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                                renderInput={(params) => <TextField {...params} sx={{input:{color: klijent && klijent.TipStatusaKlijentaID != 1 && klijent.KlijentID > 0 ? "red" : "black"}}} label="Klijent" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {!report || report.TipReportaID != 4 ? (
                    <Grid item xs={12} sm={12} md={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={aktivnostiPredmeta}
                                value={aktivnostPredmeta}
                                getOptionLabel={(option) => option.Naziv}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={"aktivnostpredmeta" + option.PredmetID}>
                                            {option.Naziv}
                                        </li>
                                    );
                                }}                                
                                onChange={(e, v) => {
                                    if (v) setAktivnostPredmeta(v);
                                }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} label="Aktivnost predmeta" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {!report || report.TipReportaID != 4 ? (
                    <Grid item xs={12} sm={12} md={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={!klijent || klijent.KlijentID == 0 ? predmeti.filter((p) => p.PredmetID == 0) : (aktivnostPredmeta.Vrijednost == 0 ? (predmeti.filter((p) => p.KlijentID == klijent.KlijentID || p.PredmetID == 0)) : (predmeti.filter((p) => (p.KlijentID == klijent.KlijentID && p.Aktivan) || p.PredmetID == 0)))}
                                value={predmet}
                                getOptionLabel={(option) => (option.PredmetID > 0 ? option.Broj + " - " : "") + option.Naziv}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={"predmet" + option.PredmetID} style={{color: option.PredmetID <= 0 ? "black" : (option.TipStanjaPredmetaID == 1 ? (option.TipNaplateID == 3 ? "green" : (option.TipNaplateID == 2 ? "magenta" : "black")) : "red")}}>
                                            {(option.PredmetID > 0 ? option.Broj + " - " : "") + option.Naziv}
                                        </li>
                                    );
                                }}                                
                                onChange={(e, v) => {
                                    if (v) setPredmet(v);
                                }}
                                disableClearable
                                renderInput={(params) => <TextField {...params} sx={{input:{color: predmet && predmet.PredmetID <= 0 ? "black" : (predmet && predmet.TipStanjaPredmetaID != 1 ? "red" : (predmet && predmet.TipNaplateID == 3 ? "green" : (predmet && predmet.TipNaplateID == 2 ? "magenta" : "black")))}}} label="Predmet" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Box style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                        <Button variant="contained" startIcon={<QueryStatsIcon></QueryStatsIcon>} color="success" onClick={handleIzvjestajClick}>
                            Pogledaj izvještaj
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            <Menu
                anchorEl={anchorEl}
                open={formatsOpened}
                onClose={handleIzvjestajClose}
            >
                
                <MenuItem key={0} onClick={() => {
                    setAnchorEl(null);
                    izvjestaj("PDF");
                }}><img src={pdf} style={{width:'24px', marginRight:'8px'}}></img>PDF</MenuItem>
                <MenuItem key={1} onClick={() => {
                    setAnchorEl(null);
                    izvjestaj("Word");
                }}><img src={word} style={{width:'24px', marginRight:'8px'}}></img>Word</MenuItem>
                <MenuItem key={2} onClick={() => {
                    setAnchorEl(null);
                    izvjestaj("Excel");
                }}><img src={excel} style={{width:'24px', marginRight:'8px'}}></img>Excel</MenuItem>
                   
            </Menu>

            {logoutAlert ? <AlertDialog title="Odjava" message={"Upravo se događa odjava inicirana od strane administratora."} cancelHidden={true} confirmHidden={true} okHidden={false} okLabel={"OK"} okAction={startLogout}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default Izvjestaji;
