import { Alert, Autocomplete, Box, Button, Checkbox, ClickAwayListener, Divider, FormControl, FormControlLabel, FormLabel, Grid, ListItem, ListItemText, Menu, MenuItem, Modal, Paper, TextField, Tooltip, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ComponentContainer from "./componentContainer";
import ReceiptIcon from '@mui/icons-material/Receipt';
import Util from "../util/util";
import LineIcon from "react-lineicons";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import globalContext from "./globalContext";
import api from "../util/api";
import { List } from "antd";
import AddEditDelForm from "./addEditDelForm";
import RacunskaStavkaForm from "./racunskaStavkaForm";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import AlertDialog from "./alertdialog";
import PrebaciStavkuNaDrugiRacunForm from "./prebaciStavkuNaDrugiRacunForm";
import dateFormat, { masks } from "dateformat";
import DeleteIcon from '@mui/icons-material/Delete';
import RacuniIzvjestajBrisanje from "./racuniBrisanjeIzvjestaj";
import ErrorIcon from '@mui/icons-material/Error';

const styleUslugaRoba = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    //minHeight: '80%',
    maxHeight: '95%',
    minWidth: '65%',
    maxWidth: '95%'
};

const styleSpecifikacija = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    //minHeight: '80%',
    maxHeight: '95%',
    minWidth: '80%',
    maxWidth: '95%'
};

const styleDelete = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '95%',
    maxWidth: '95%'
};

function RacunForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formRacun = props.formRacun;
    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;
    const klijenti = props.klijenti || [];

    const [loading, setLoading] = useState(true);
    const [edited, setEdited] = useState(false);
    const [valid, setValid] = useState(false);
    const [broj, setBroj] = useState(null);
    const [pnb, setPnb] = useState("");
    const [domaci, setDomaci] = useState(true);
    const [firmaValute, setFirmaValute] = useState([]);
    const [firmaValuta, setFirmaValuta] = useState(null);
    const [klijent, setKlijent] = useState(null);
    const [konta, setKonta] = useState([]);
    const [konto, setKonto] = useState(null);
    const [zaposlenici, setZaposlenici] = useState([]);
    const [izradioRacun, setIzradioRacun] = useState(global.drugiZaposlenik.PotpisnaOsoba ? global.drugiZaposlenik : null);
    const [kontrolorNaplate, setKontrolorNaplate] = useState(null);
    const [napomena, setNapomena] = useState(null);
    const [stavke, setStavke] = useState([]);
    const [currentStavka, setCurrentStavka] = useState(null);
    const [formRacunskaStavka, setFormRacunskaStavka] = useState(null);
    const [racunskaStavkaFormOpened, setRacunskaStavkaFormOpened] = useState(false);
    const [formTipStavkeID, setFormTipStavkeID] = useState(0);
    const [formTipRacunskeStavkeNaziv, setFormTipRacunskeStavkeNaziv] = useState("");
    const [anchorAddEl, setAnchorAddEl] = useState(null);
    const addOptionsOpened = Boolean(anchorAddEl);
    const [initial, setInitial] = useState(true);
    const [datum, setDatum] = useState(new Date());
    const [datumPlacanja, setDatumPlacanja] = useState(new Date());
    const [brojDanaPlacanje, setBrojDanaPlacanje] = useState(0);
    const [naciniPlacanja, setNaciniPlacanja] = useState([]);
    const [nacinPlacanja, setNacinPlacanja] = useState(null);
    const [ugovori, setUgovori] = useState([]);
    const [ugovor, setUgovor] = useState(null);
    const [naglasiNapomenu, setNaglasiNapomenu] = useState(false);
    const [naslov, setNaslov] = useState("");
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [postojeAktivnosti, setPostojeAktivnosti] = useState(false);
    const [postojeTroskovi, setPostojeTroskovi] = useState(false);
    const [vratiAktivnosti, setVratiAktivnosti] = useState(true);
    const [vratiTroskove, setVratiTroskove] = useState(true);
    const [afterDeleteAlert, setAfterDeleteAlert] = useState(false);
    const [brojNeprenesenihAktivnosti, setBrojNeprenesenihAktivnosti] = useState(0);
    const [vrstaRacuna, setVrstaRacuna] = useState(null);
    const [stavkaForPrebacajID, setStavkaForPrebacajID] = useState(0);
    const [prebaciStavkuNaDrugiRacunFormOpened, setPrebaciStavkuNaDrugiRacunFormOpened] = useState(false);
    const [stavkaForDeleteID, setStavkaForDeleteID] = useState(0);
    const [brisanjeIzvjestajOpened, setBrisanjeIzvjestajOpened] = useState(false);
    const [brisanjeIzvjestajData, setBrisanjeIzvjestajData] = useState(false);
    const [tecajeviExist, setTecajeviExist] = useState(true);
    const [racunskaStavkaFormDetalji, setRacunskaStavkaFormDetalji] = useState(null);
    const [firmaJezici, setFirmaJezici] = useState([]);
    const [firmaJezik, setFirmaJezik] = useState(null);
    const [kontoVidljivZaStavku, setKontoVidljivZaStavku] = useState(null);
    const [defaultKonto, setDefaultKonto] = useState(null);
    const [defaultKontoStrani, setDefaultKontoStrani] = useState(null);
    const [tipoviKorisnika, setTipoviKorisnika] = useState([]);
    const [tipProvjerePromjeneCijene, setTipProvjerePromjeneCijene] = useState(1);
    const [porezneNapomene, setPorezneNapomene] = useState([]);
    const [poreznaNapomena, setPoreznaNapomena] = useState({ PoreznaNapomenaID: 0, Naziv: null, TipPorezneNapomeneNaziv: null, JezikID: 0, Opis: null, FirmaID: null, TipPorezneNapomeneID: null});    
    const [poreznaNapomenaAlert, setPoreznaNapomenaAlert] = useState(false);
    const [ukupniIznos, setUkupniIznos] = useState("");
    const [ukupniPorez, setUkupniPorez] = useState("");
    const [sveukupno, setSveukupno] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [tipoviPonude, setTipoviPonude] = useState([]);
    const [tipPonude, setTipPonude] = useState(null);

    useConstructor(() => {
        api.get("racun/getformdetalji/" + global.user.firmaID, loadFormDetaljiSuccess, loadFormDetaljiFail);
    });

    function loadFormDetaljiSuccess(data) {
        setKontoVidljivZaStavku(data.KontoVidljivZaStavku);
        setFirmaValute(data.FirmaValute);
        setZaposlenici(data.Zaposlenici);
        setFirmaJezici(data.FirmaJezici);
        setDefaultKonto(data.DefaultKonto);
        setDefaultKontoStrani(data.DefaultKontoStrani);
        setKonta(data.Konta);
        setNaciniPlacanja(data.NaciniPlacanja);
        setUgovori(data.Ugovori);
        setRacunskaStavkaFormDetalji(data.RacunskaStavkaFormDetalji);
        setTipoviKorisnika(data.TipoviKorisnika);
        setTipProvjerePromjeneCijene(data.TipProvjerePromjeneCijene);
        setPorezneNapomene([{ PoreznaNapomenaID: 0, Naziv: null, TipPorezneNapomeneNaziv: null, JezikID: 0, Opis: null, FirmaID: null, TipPorezneNapomeneID: null}, ...data.PorezneNapomene]);
        setTipoviPonude(data.TipoviPonude);
        let tipRacunaID = 0;
        let vrstaRacunaID = 0;
        if (formRacun) {
            setBroj(formRacun.Broj);
            data.FirmaValute.forEach(fv => {
                if (fv.FirmaValutaID == formRacun.FirmaValutaID) {
                    setFirmaValuta(fv);
                }
            })
            setDomaci(formRacun.TipDrzavnostiID == 1);
            klijenti.forEach(k => {
                if (k.KlijentID == formRacun.KlijentID) {
                    setKlijent(k);
                }
            })
            setNapomena(formRacun.Napomena);
            data.Zaposlenici.forEach(z => {
                if (z.KorisnikID == formRacun.KorisnikID) {
                    setIzradioRacun(z);
                }
            })
            setStavke(formRacun.RacunskeStavke);
            
            //selektiranje prve stavke, ako postoji
            if(formRacun.RacunskeStavke.length > 0) setCurrentStavka(formRacun.RacunskeStavke[0]);
            
            setDatum(formRacun.Datum);
            setDatumPlacanja(formRacun.DatumPlacanja);
            data.NaciniPlacanja.forEach(np => {
                if (np.NacinPlacanjaID == formRacun.NacinPlacanjaID) {
                    setNacinPlacanja(np);
                }
            })
            data.Ugovori.forEach(u => {
                if (u.UgovorID == formRacun.UgovorID) {
                    setUgovor(u);
                }
            })
            setNaglasiNapomenu(formRacun.NapomenaNaglaseno);
            data.Zaposlenici.forEach(z => {
                if (z.KorisnikID == formRacun.KontrolorNaplateKorisnikID) {
                    setKontrolorNaplate(z);
                }
            });
            setPnb(formRacun.PozivNaBroj);
            tipRacunaID = formRacun.TipRacunaID;
            vrstaRacunaID = formRacun.VrstaRacunaID;
            setVrstaRacuna(formRacun.VrstaRacuna);
            setFirmaJezik(formRacun.FirmaJezik);
            setKonto(formRacun.Konto);
            setBrojDanaPlacanje(formRacun.DatumPlacanjaBrojDana);
            setPoreznaNapomena(formRacun.PoreznaNapomena ? formRacun.PoreznaNapomena : { PoreznaNapomenaID: 0, Naziv: null, TipPorezneNapomeneNaziv: null, JezikID: 0, Opis: null, FirmaID: null, TipPorezneNapomeneID: null});
            setTipPonude(formRacun.TipPonude);
        } else {
            setFirmaValuta(data.DefaultFirmaValuta);
            setBrojDanaPlacanje(data.BrojDanaPlacanje);
            setDatumPlacanja(addDays(new Date(), data.BrojDanaPlacanje));
            setNacinPlacanja(data.DefaultNacinPlacanja);
            tipRacunaID = props.tipRacunaID;
            vrstaRacunaID = props.vrstaRacuna.VrstaRacunaID;
            setVrstaRacuna(props.vrstaRacuna);
            setKonto(data.DefaultKonto);
            setFirmaJezik(data.FirmaJezici.find(fj => fj.JezikID == 1) || data.FirmaJezici[0]);
            if (vrstaRacunaID == 3) {
                setTipPonude(data.TipoviPonude[0]);
            }
        }
        if (tipRacunaID == 1) {
            if (vrstaRacunaID == 1) {
                setNaslov("Izlazni račun");
            } else if (vrstaRacunaID == 2) {
                setNaslov("Izlazni račun za predujam");
            } else if (vrstaRacunaID == 3) {
                setNaslov("Izlazna ponuda");
            }
        } else {
            if (vrstaRacunaID == 1) {
                setNaslov("Ulazni račun");
            } else if (vrstaRacunaID == 2) {
                setNaslov("Ulazni račun za predujam");
            } else if (vrstaRacunaID == 3) {
                setNaslov("Ulazna ponuda");
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (formRacun) {
            setStavke(formRacun.RacunskeStavke);
        }
    }, [formRacun])

    useEffect(() => {
        if (stavke) {
            let iznos = Util.izracunajIznosRacunaIzStavki(stavke);
            let porez = Util.izracunajPorezRacunaIzStavki(stavke);

            setUkupniIznos(Util.toStringDecimal(iznos));
            setUkupniPorez(Util.toStringDecimal(porez));
            setSveukupno(Util.toStringDecimal(iznos + porez));
        } else {
            setUkupniIznos("0.00");
            setUkupniPorez("0.00");
            setSveukupno("0.00");
        }
    }, [stavke]);

    function loadFormDetaljiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        if (stavke) {
            api.post("tecaj/checkforracunskestavke", stavke, checkTecajeviExistSuccess, checkTecajeviExistFail);
        }
    }, [stavke, firmaValuta]);

    function checkTecajeviExistSuccess(data) {
        setTecajeviExist(data);
    }

    function checkTecajeviExistFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    useEffect(() => {
        if (!isNaN(parseInt(brojDanaPlacanje))) {
            setDatumPlacanja(addDays(datum, parseInt(brojDanaPlacanje)));
        }
    }, [datum, brojDanaPlacanje]);

    useEffect(() => {
        if (!formRacun && klijent) {
            setBrojDanaPlacanje(klijent.DatumPlacanjaBrojDana);
        }
    }, [klijent])

    function handleAddClick(event) {
        setAnchorAddEl(event.currentTarget);
    };

    function handleAddClose(event) {
        setAnchorAddEl(null);
    };

    function save() {
        let racun = {
            RacunID : formRacun ? formRacun.RacunID : -1,
            KlijentID : klijent.KlijentID,
            Datum : datum,
            DatumPlacanja : datumPlacanja,
            Broj : broj,
            TipRacunaID : formRacun ? formRacun.TipRacunaID : props.tipRacunaID,
            VrstaRacunaID : formRacun ? formRacun.VrstaRacunaID : props.vrstaRacuna.VrstaRacunaID,
            VrstaRacuna : formRacun ? formRacun.VrstaRacuna : props.vrstaRacuna,
            FirmaValutaID : firmaValuta.FirmaValutaID,
            FirmaValutaNaziv : firmaValuta.FirmaValutaNaziv,
            KorisnikID : izradioRacun.KorisnikID,
            FirmaID : global.user.firmaID,
            Napomena : napomena,
            TipDrzavnostiID : domaci == 1 ? 1 : 2,
            RacunskeStavke : stavke,
            FirmaJezikID : firmaJezik.FirmaJezikID,
            NacinPlacanjaID : nacinPlacanja.NacinPlacanjaID,
            NacinPlacanjaNaziv : nacinPlacanja.Naziv,
            KontrolorNaplateKorisnikID : kontrolorNaplate ? kontrolorNaplate.KorisnikID : null,
            NapomenaNaglaseno : naglasiNapomenu,
            UgovorID : ugovor ? ugovor.UgovorID : null,
            PozivNaBroj : pnb,
            Konto : konto,
            KontoID : konto != null ? konto.KontoID : null,
            DatumPlacanjaBrojDana : brojDanaPlacanje,
            PoreznaNapomenaID : poreznaNapomena.PoreznaNapomenaID > 0 ? poreznaNapomena.PoreznaNapomenaID : null,
            TipPonude: tipPonude
        };
        setLoading(true);
        if (formRacun && !(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2) && broj)) {
            api.post("racun/checkporeznanapomena", racun, checkPoreznaNapomenaSuccess, checkPoreznaNapomenaFail);
        } else {
            saveConfirmed();
        }
    }

    function checkPoreznaNapomenaSuccess(data) {
        if (!(data == null && poreznaNapomena.PoreznaNapomenaID == 0 || data == poreznaNapomena.TipPorezneNapomeneID)) {
            setPoreznaNapomenaAlert(true);
        } else {
            saveConfirmed();
        }
    }

    function checkPoreznaNapomenaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function saveConfirmed() {
        let racun = {
            RacunID : formRacun ? formRacun.RacunID : -1,
            KlijentID : klijent.KlijentID,
            Datum : datum,
            DatumPlacanja : datumPlacanja,
            Broj : broj,
            TipRacunaID : formRacun ? formRacun.TipRacunaID : props.tipRacunaID,
            VrstaRacunaID : formRacun ? formRacun.VrstaRacunaID : props.vrstaRacuna.VrstaRacunaID,
            VrstaRacuna : formRacun ? formRacun.VrstaRacuna : props.vrstaRacuna,
            FirmaValutaID : firmaValuta.FirmaValutaID,
            FirmaValutaNaziv : firmaValuta.FirmaValutaNaziv,
            KorisnikID : izradioRacun.KorisnikID,
            FirmaID : global.user.firmaID,
            Napomena : napomena,
            TipDrzavnostiID : domaci == 1 ? 1 : 2,
            RacunskeStavke : stavke,
            FirmaJezikID : firmaJezik.FirmaJezikID,
            NacinPlacanjaID : nacinPlacanja.NacinPlacanjaID,
            NacinPlacanjaNaziv : nacinPlacanja.Naziv,
            KontrolorNaplateKorisnikID : kontrolorNaplate ? kontrolorNaplate.KorisnikID : null,
            NapomenaNaglaseno : naglasiNapomenu,
            UgovorID : ugovor ? ugovor.UgovorID : null,
            PozivNaBroj : pnb,
            Konto : konto,
            KontoID : konto != null ? konto.KontoID : null,
            DatumPlacanjaBrojDana : brojDanaPlacanje,
            PoreznaNapomenaID : poreznaNapomena.PoreznaNapomenaID > 0 ? poreznaNapomena.PoreznaNapomenaID : null,
            TipPonude: tipPonude
        };
        api.post("racun/save", racun, saveSuccess, saveFail);
    }
    
    function saveSuccess(data) {
        setLoading(false);
        setCanceled(false);
        props.filterRacuni(null, null, null);
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function edit() {
        setFormTipStavkeID(currentStavka.TipRacunskeStavkeID);
        setFormRacunskaStavka(currentStavka);
        setRacunskaStavkaFormOpened(true);
    }
    useEffect(() => {
        if (initial) {
            setInitial(false);
        } else if (!racunskaStavkaFormOpened && formRacun && formRacun.RacunID > 0 && !initial) {
            setLoading(true);
            api.get("racunskastavka/getforracun/" + formRacun.RacunID, refreshStavkeSuccess, refreshStavkeFail);
        }
    }, [racunskaStavkaFormOpened]);

    function refreshStavkeSuccess(data) {
        setStavke(data);
        setCurrentStavka(null);
        setFormRacunskaStavka(null);
        setLoading(false);
    }

    function refreshStavkeFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        let checkValid = klijent && datum && datumPlacanja && firmaValuta && izradioRacun && firmaJezik && (vrstaRacuna && (vrstaRacuna.VrstaRacunaID != 3 || broj)) && brojDanaPlacanje != null && !isNaN(parseInt(brojDanaPlacanje)) && parseInt(brojDanaPlacanje) >= 0 && (vrstaRacuna && (vrstaRacuna.VrstaRacunaID != 3 || tipPonude != null));
        setValid(checkValid);
    }, [
        klijent, 
        datum,
        datumPlacanja, 
        firmaValuta, 
        izradioRacun, 
        broj, 
        vrstaRacuna,
        firmaJezik, 
        brojDanaPlacanje, 
        tipPonude,
        formRacun,
        napomena,
        domaci,
        stavke,
        nacinPlacanja,
        kontrolorNaplate,
        naglasiNapomenu,
        ugovor,
        pnb,
        konto,
        poreznaNapomena,
        tipPonude
    ]);

    function deleteRacunskaStavka() {
        setStavkaForDeleteID(currentStavka.RacunskaStavkaID);
        if (currentStavka.TipRacunskeStavkeID == 1) {
            let postojeStavkeNastaleIzEvidencija = false;
            currentStavka.StavkeRacunskeStavke.forEach(stavka => {
                if (stavka.EvidencijaDetaljID) {
                    if (stavka.TipEvidencijeID == 1) {
                        setPostojeAktivnosti(true);
                        setVratiAktivnosti(true);
                    } else if (stavka.TipEvidencijeID == 2) {
                        setPostojeTroskovi(true);
                        setVratiTroskove(true);
                    }
                }
            });
        }
        setDeleteAlert(true);
    }

    function deleteConfirmed() {
        if (stavkaForDeleteID > 0) {
            let resource = {
                RacunskaStavkaID : stavkaForDeleteID,
                VratiAktivnosti : vratiAktivnosti,
                VratiTroskove : vratiTroskove
            }
            setLoading(true);
            api.post("racunskaStavka/delete", resource, deleteSuccess, deleteFail);
        } else {
            let newStavke = stavke.filter(s => currentStavka ? (s.GUID != currentStavka.GUID) : true);
            setStavke(newStavke);
            setDeleteAlert(false);
            setPostojeAktivnosti(false);
            setPostojeTroskovi(false);
        }
    }

    function deleteSuccess(data) {
        let newStavke = stavke.filter(s => s.RacunskaStavkaID != data.RacunskaStavkaID);
        setStavke(newStavke);
        setLoading(false);
        setDeleteAlert(false);
        setPostojeAktivnosti(false);
        setPostojeTroskovi(false);
        setBrisanjeIzvjestajData(data);
        setBrisanjeIzvjestajOpened(true);
    }

    function deleteFail(data) {
        setPostojeAktivnosti(false);
        setPostojeTroskovi(false);
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }
    
    function deleteCanceled() {
        setDeleteAlert(false);
        setPostojeAktivnosti(false);
        setPostojeTroskovi(false);
    }

    function prebaciNaDrugiRacun() {
        setStavkaForPrebacajID(currentStavka.RacunskaStavkaID);
        setPrebaciStavkuNaDrugiRacunFormOpened(true);
    }

    function handleKlijentChange(e,v) {
        if (v) {
            setKlijent(v);
            setFirmaJezik(firmaJezici.find(fj => fj.FirmaJezikID == v.FirmaJezikID));
        }
    }
    
    function findKontoRacuna() {
        if (klijent) {
            if (klijent.TipDrzavnostiID == 1) {
                setKonto(defaultKonto);
            } else {
                setKonto(defaultKontoStrani);
            }
        }
    }

    useEffect(() => findKontoRacuna(), [klijent]);

    const handleKeyPressShorcuts = useCallback((event) => {
        if (event.altKey) {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if (charCode == 'p') {
                event.preventDefault();
                if (!loading && valid) {
                    save();
                }
            }
        }
    }, [
        loading,
        klijent, 
        datum,
        datumPlacanja, 
        firmaValuta, 
        izradioRacun, 
        broj, 
        vrstaRacuna,
        firmaJezik, 
        brojDanaPlacanje, 
        tipPonude,
        formRacun,
        napomena,
        domaci,
        stavke,
        nacinPlacanja,
        kontrolorNaplate,
        naglasiNapomenu,
        ugovor,
        pnb,
        konto,
        poreznaNapomena,
        tipPonude
    ]);


    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressShorcuts);

        return () => {
            document.removeEventListener('keydown', handleKeyPressShorcuts);
        };
    }, [handleKeyPressShorcuts]);

    return (
        <ComponentContainer loading={loading || props.loadingRacuni}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            <ReceiptIcon fontSize="medium" style={{marginRight:"15px"}}></ReceiptIcon>
                            {naslov + (formRacun ? (" (ID: " + formRacun.RacunID + ")") : "")}
                        </div>  
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3} sx={{display: { xs: vrstaRacuna && vrstaRacuna.VrstaRacunaID == 3 ? "block" : "none" }}}>
                    <FormControl fullWidth >
                        <Autocomplete
                            disablePortal
                            disableClearable
                            options={tipoviPonude}
                            autoHighlight
                            value={tipPonude}
                            getOptionLabel={option => option.Naziv}
                            onChange={(e,v) => setTipPonude(v)}
                            renderInput={(params) => <TextField {...params} label="Tip ponude" size="small" style={{width:"100%"}} error={!tipPonude} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={vrstaRacuna && vrstaRacuna.VrstaRacunaID == 3 ? 3 : 6}>
                    <FormControl fullWidth >
                        <TextField label="Broj" inputProps={{ readOnly: vrstaRacuna && vrstaRacuna.VrstaRacunaID == 3 || (formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2) ? false : true }} value={broj || ""} onChange={e => setBroj(e.target.value)} size="small" error={!broj && vrstaRacuna && vrstaRacuna.VrstaRacunaID == 3} style={{width:"99%"}}></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            disabled={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2) && broj )}
                            options={konta}
                            autoHighlight
                            value={konto}
                            getOptionLabel={(option) => option.Naziv + " (" + option.ForeignKontoID + ")" + (option.MjestoTroskaNaziv ? " - " + option.MjestoTroskaNaziv : "") + (option.MjestoTroskaForeignID ? (" (" + option.MjestoTroskaForeignID + ")") : "")}
                            onChange={(e,v) => setKonto(v)}
                            renderInput={(params) => <TextField {...params} label="Konto" size="small" style={{width:"100%"}} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            disableClearable
                            disabled={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2)  && broj) || klijenti.length == 0}
                            options={klijenti}
                            autoHighlight
                            value={klijent}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={handleKlijentChange}
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 ? "black" : "red"}}>
                                    {option.Broj + " - " + option.Naziv}
                                  </li>
                                );
                            }}
                            filterOptions={(options, state) => options.filter(option => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            renderInput={(params) => <TextField {...params} sx={{input:{color: klijent && klijent.TipStatusaKlijentaID != 1 ? "red" : "black"}}} label="Klijent" size="small" style={{width:"99%"}} error={!klijent} required />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={4} marginTop="2px" marginBottom="2px">
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker
                            label="Datum"
                            value={datum}
                            inputFormat="dd.MM.yyyy"
                            onChange={(v) => {if (v) setDatum(v)}}
                            disabled={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2)  && broj)}
                            renderInput={(params) => <TextField style={{width:"99%"}} {...params} size="small" error={!datum || isNaN(new Date(datum))} required />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={3} sm={3} md={2}>
                    <FormControl fullWidth>
                        <TextField label={vrstaRacuna && vrstaRacuna.VrstaRacunaID == 3 ? "Broj dana važenja ponude" : "Broj dana za plaćanje"} type="number" inputProps={{min:0}} disabled={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2)  && broj)} value={brojDanaPlacanje} onChange={(e) => {setBrojDanaPlacanje(e.target.value);}} error={brojDanaPlacanje == null || isNaN(parseInt(brojDanaPlacanje)) || parseInt(brojDanaPlacanje) < 0} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3} sm={3} md={2} marginTop="2px" marginBottom="2px">
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker
                            label="Datum plaćanja"
                            value={datumPlacanja}
                            inputFormat="dd.MM.yyyy"
                            onChange={(v) => {if (v) setDatumPlacanja(v)}}
                            disabled
                            renderInput={(params) => <TextField style={{width:"99%"}} {...params} size="small" error={!datumPlacanja || isNaN(new Date(datumPlacanja))} required />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6} sm={6} md={2} >
                    <FormControlLabel 
                        labelPlacement="end" 
                        label="Domaći"
                        style={{marginLeft:"0px"}}
                        disabled={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2)  && broj)}
                        control={<Checkbox checked={domaci} onChange={e => setDomaci(e.target.checked)}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={6} sm={6} md={2} >
                    <FormControlLabel 
                        labelPlacement="end" 
                        label="Strani"
                        disabled={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2) && broj)}
                        control={<Checkbox checked={!domaci} onChange={e => setDomaci(!e.target.checked)}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={4} >
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            id="valuta-select"
                            disabled={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2) && broj) || firmaValute.length == 0}
                            options={firmaValute}
                            autoHighlight
                            value={firmaValuta}
                            getOptionLabel={(option) => option.FirmaValutaNaziv}
                            onChange={(e,v) => {if (v) setFirmaValuta(v)}}
                            disableClearable
                            renderInput={(params) => <TextField {...params} label="Valuta" size="small" style={{width:"99%"}} error={!firmaValuta} required />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} >
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            id="jezik-select"
                            disabled={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2) && broj) || firmaJezici.length == 0}
                            options={firmaJezici}
                            value={firmaJezik}
                            autoHighlight
                            getOptionLabel={(option) => option.FirmaJezikNaziv}
                            onChange={(e,v) => {if (v) setFirmaJezik(v)}}
                            disableClearable
                            renderInput={(params) => <TextField {...params} label="Jezik" size="small" style={{width:"99%"}} error={!firmaJezik} required />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                    <FormControl fullWidth>
                        <TextField label="Napomena" size="small" multiline rows={4} inputProps={{ maxLength: 1024 }} value={napomena || ""} disabled={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2) && broj)} onChange={e => setNapomena(e.target.value)} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4} display="flex" justifyContent="center" alignItems="center">
                    <FormControlLabel 
                        labelPlacement="end" 
                        label="Naglasi napomenu"
                        control={<Checkbox checked={naglasiNapomenu} onChange={e => setNaglasiNapomenu(e.target.checked)}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                {formRacun ? (
                    <Grid item xs={12} sm={12} md={12} >
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                options={porezneNapomene.filter(pn => pn.PoreznaNapomenaID == 0 || pn.JezikID == 1)}
                                value={poreznaNapomena}
                                autoHighlight
                                disabled={vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2) && broj}
                                getOptionLabel={(option) => option.PoreznaNapomenaID == 0 ? "Bez napomene" : option.TipPorezneNapomeneNaziv}
                                renderOption={(props, option) => {
                                    return (
                                        <Tooltip enterDelay={200} enterNextDelay={200} title={
                                            option.PoreznaNapomenaID != 0 ? (
                                                <Box style={{ height:"100%" }}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Divider color="white"></Divider>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            Tip:
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            {option.TipPorezneNapomeneNaziv}
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Divider color="white"></Divider>
                                                        </Grid>
                                                    </Grid>
                                                    {porezneNapomene.filter(pn => firmaJezici.map(fj => fj.JezikID).includes(pn.JezikID) && pn.TipPorezneNapomeneID == option.TipPorezneNapomeneID).map((n, i) => {
                                                        return (
                                                            <Grid container>
                                                                <Grid item xs={4}>
                                                                    Jezik:
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    {firmaJezici.find(fj => fj.JezikID == n.JezikID).FirmaJezikNaziv}
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Naslov:
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    {n.Naziv}
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    Napomena:
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    {n.Opis}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Divider color="white"></Divider>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Box>
                                            ) : ""
                                        }>
                                            <li {...props} key={option.PoreznaNapomenaID}>
                                                <div style={{width:"100%"}}>{option.PoreznaNapomenaID == 0 ? "Bez napomene" : option.TipPorezneNapomeneNaziv}</div>                                        
                                            </li>
                                        </Tooltip>
                                    );
                                }}
                                onChange={(e,v) => {if (v) setPoreznaNapomena(v)}}
                                renderInput={(params) => 
                                    <Tooltip enterDelay={200} enterNextDelay={200} title={
                                        poreznaNapomena.PoreznaNapomenaID != 0 ? (
                                            <Box>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Divider color="white"></Divider>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        Tip:
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        {poreznaNapomena.TipPorezneNapomeneNaziv}
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Divider color="white"></Divider>
                                                    </Grid>
                                                </Grid>
                                                {porezneNapomene.filter(pn => firmaJezici.map(fj => fj.JezikID).includes(pn.JezikID) && pn.TipPorezneNapomeneID == poreznaNapomena.TipPorezneNapomeneID).map((n, i) => {
                                                    return (
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                Jezik:
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {firmaJezici.find(fj => fj.JezikID == n.JezikID).FirmaJezikNaziv}
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                Naslov:
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {n.Naziv}
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                Napomena:
                                                            </Grid>
                                                            <Grid item xs={8}>
                                                                {n.Opis}
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Divider color="white"></Divider>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Box>
                                        ) : ""
                                    }>
                                        <TextField {...params} label="Porezna napomena" size="small" style={{width:"99%"}} error={!poreznaNapomena} required />
                                    </Tooltip>
                                }
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                <Grid item xs={12} sm={12} md={3} >
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            disabled={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2) && broj) || zaposlenici.length == 0}
                            options={zaposlenici.filter(z => z.PotpisnaOsoba && z.TipStatusaKorisnikaID == 1)}
                            value={izradioRacun}
                            autoHighlight
                            getOptionLabel={(option) => option.Ime + " " + option.Prezime}
                            onChange={(e,v) => setIzradioRacun(v)}
                            renderInput={(params) => <TextField {...params} label="Potpisna osoba" size="small" style={{width:"99%"}} error={!izradioRacun} required />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={3} >
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            disabled={zaposlenici.length == 0}
                            options={zaposlenici.filter(z => z.TipStatusaKorisnikaID == 1)}
                            value={kontrolorNaplate}
                            autoHighlight
                            getOptionLabel={(option) => option.Ime + " " + option.Prezime}
                            onChange={(e,v) => setKontrolorNaplate(v)}
                            renderInput={(params) => <TextField {...params} label="Kontrolor naplate" size="small" style={{width:"99%"}} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            options={naciniPlacanja}
                            value={nacinPlacanja}
                            autoHighlight
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {if (v) setNacinPlacanja(v)}}
                            disabled={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2) && broj)}
                            disableClearable
                            renderInput={(params) => <TextField {...params} label="Nacin plaćanja" size="small" style={{width:"99%"}} error={!nacinPlacanja} required />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            disabled={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2) && broj) || ugovori.length == 0 }
                            options={ugovori}
                            autoHighlight
                            value={ugovor}
                            getOptionLabel={(option) => option.TipUgovoraNaziv + " (" + dateFormat(option.DatumPocetka, "dd.mm.yyyy") + " - " + dateFormat(option.DatumKraja, "dd.mm.yyyy")}
                            onChange={(e,v) => setKlijent(v)}
                            renderInput={(params) => <TextField {...params} label="Ugovor" size="small" style={{width:"99%"}} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} marginTop="8px">
                    <Box>
                        <FormLabel style={{marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 2, width: "9em", backgroundColor: "white", position: "absolute", fontSize: "0.75em"}}>Računske stavke</FormLabel>
                        <Box sx={{ borderRadius: 1, border: 1, borderColor: 'grey.400', "&:hover": { borderColor: 'black' }}} padding="5px">
                            
                                    <Box style={{width:"100%"}} >
                                        <AddEditDelForm disableDeleteButton={formRacun && formRacun.FiksnaNaplata && currentStavka && (currentStavka.TipVrsteStavkeID == 4 || currentStavka.TipVrsteStavkeID == 5)} ukupniIznos={ukupniIznos} ukupniPorez={ukupniPorez} sveukupno={sveukupno} dataType="racunskeStavke" filterRacuni={props.filterRacuni} prebaciNaDrugiRacun={prebaciNaDrugiRacun} vrstaRacuna={vrstaRacuna} data={stavke} setData={setStavke} current={currentStavka} setCurrent={setCurrentStavka} add={handleAddClick} edit={edit} del={deleteRacunskaStavka} fiksnaNaplata={formRacun && formRacun.FiksnaNaplata} disableTakeALook={!formRacun || formRacun.Broj==null} disableButtons={(vrstaRacuna && vrstaRacuna.VrstaRacunaID != 3 && !(formRacun ? formRacun.TipRacunaID == 2 : props.tipRacunaID == 2) && broj)} ></AddEditDelForm>
                                    </Box>
                                    
                        </Box>
                    </Box>
                </Grid>
                {!tecajeviExist ? (
                    <Grid item xs={12} marginTop="5px">
                        <Alert variant="filled" sx={{ paddingTop:0, paddingBottom:0 }} severity="error">Postoji jedna ili više računskih stavki za čiji datum obračuna nije poznat tečaj za preračun valute računske stavke u valutu računa. Takve računske stavke poprimaju iznos 0.00.</Alert>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Tooltip title={!valid ? "Nisu dobro uneseni svi podaci." : ""}>
                            <span><Button onClick={save} variant="contained" style={{marginRight:"10px"}} color="success" disabled={loading || !valid}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <SaveIcon style={{marginRight:"10px"}} />} Sp&#818;remi</Button></span>
                        </Tooltip>
                        <Button variant="outlined" color="error" onClick={() => {setCanceled(true); setFormOpened(false);}} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>{formRacun ? 'Zatvori' : 'Odustani'}</Button>
                    </Box>
                </Grid>
            </Grid>

            <Modal open={racunskaStavkaFormOpened}>
                <Box sx={formTipStavkeID == 1 ? styleSpecifikacija : styleUslugaRoba} overflow={"auto"} >
                    <RacunskaStavkaForm
                        kontoVidljivZaStavku={kontoVidljivZaStavku} 
                        racunskeStavke={stavke} 
                        defaultFirmaValuta={firmaValuta}
                        firmaValute={firmaValute}
                        konta={konta}
                        defaultDatumObracuna={datum} 
                        setRacunskeStavke={setStavke} 
                        racunID={formRacun ? formRacun.RacunID : -1} 
                        racunFirmaValutaID={firmaValuta ? firmaValuta.FirmaValutaID : -1} 
                        formRacunskaStavka={formRacunskaStavka} 
                        tipRacunskeStavkeID={formTipStavkeID} 
                        tipRacunskeStavkeNaziv={formTipRacunskeStavkeNaziv} 
                        setFormOpened={setRacunskaStavkaFormOpened} 
                        setCanceled={setCanceled}
                        tipNaplatnostiNaplatnoBoja={props.tipNaplatnostiNaplatnoBoja}
                        tipNaplatnostiNenaplatnoBoja={props.tipNaplatnostiNenaplatnoBoja}
                        racunskaStavkaFormDetalji={racunskaStavkaFormDetalji}
                        tipoviKorisnika={tipoviKorisnika}
                        zaposlenici={zaposlenici}
                        klijenti={props.klijenti}
                        klijent={klijent}
                        predmeti={props.predmeti}
                        broj={(formRacun && formRacun.VrstaRacunaID != 3 && formRacun.TipRacunaID != 2) ? formRacun.Broj : -1}
                        firmaJezici={firmaJezici}
                        firmaJezikID={firmaJezik ? firmaJezik.FirmaJezikID : 0}
                        fiksnaNaplata={formRacun && formRacun.FiksnaNaplata}
                        tipProvjerePromjeneCijene={tipProvjerePromjeneCijene}
                        filterRacuni={props.filterRacuni}
                    ></RacunskaStavkaForm>
                </Box>
            </Modal>

            <Menu
                anchorEl={anchorAddEl}
                open={addOptionsOpened}
                onClose={handleAddClose}
            >
                <MenuItem onClick={() => {
                    setAnchorAddEl(null);
                    setFormTipStavkeID(1);
                    setFormTipRacunskeStavkeNaziv("Specifikacija");
                    setFormRacunskaStavka(null);
                    setRacunskaStavkaFormOpened(true);
                }} disabled={vrstaRacuna && vrstaRacuna.VrstaRacunaID == 3}>Specifikacija</MenuItem>
                <MenuItem onClick={() => {
                    setAnchorAddEl(null);
                    setFormTipStavkeID(2);
                    setFormTipRacunskeStavkeNaziv("Usluga");
                    setFormRacunskaStavka(null);
                    setRacunskaStavkaFormOpened(true);
                }}>Usluga</MenuItem>
                <MenuItem onClick={() => {
                    setAnchorAddEl(null);
                    setFormTipStavkeID(3);
                    setFormTipRacunskeStavkeNaziv("Roba");
                    setFormRacunskaStavka(null);
                    setRacunskaStavkaFormOpened(true);
                }}>Robna stavka</MenuItem>
            </Menu>

            <Modal open={deleteAlert}>
                <Box sx={styleDelete} overflow={"auto"} >
                    <Grid container spacing={1}>
                        <Typography variant="h6" gutterBottom component="div" style={{marginBottom:"15px"}}>
                            Jeste li sigurni da želite obrisati odabranu računsku stavku?
                        </Typography>
                        {postojeAktivnosti ? (
                        <Grid item xs={12} >
                            <FormControlLabel 
                                labelPlacement="end" 
                                label="Vrati aktivnosti iz kojih je specifikacija nastala u nepreneseno stanje."
                                style={{marginLeft:"0px"}}
                                control={<Checkbox checked={vratiAktivnosti} onChange={e => setVratiAktivnosti(e.target.checked)}></Checkbox>}>
                            </FormControlLabel>
                        </Grid>
                        ) : null}
                        {postojeTroskovi ? (
                        <Grid item xs={12} >
                            <FormControlLabel 
                                labelPlacement="end" 
                                label="Vrati troškove iz kojih je specifikacija nastala u nepreneseno stanje."
                                style={{marginLeft:"0px"}}
                                control={<Checkbox checked={vratiTroskove} onChange={e => setVratiTroskove(e.target.checked)}></Checkbox>}>
                            </FormControlLabel>
                        </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                                <Button variant="outlined" style={{marginRight:"10px"}} onClick={deleteCanceled} >Odustani</Button>
                                <Button onClick={deleteConfirmed} startIcon={<DeleteIcon></DeleteIcon>} color="error" variant="contained" > Obriši</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <Modal className="modal" open={afterDeleteAlert}>
                <Box sx={styleDelete} overflow="auto">
                    <Typography variant="subtitle1" gutterBottom component="div">
                        Brisanjem računske stavke {brojNeprenesenihAktivnosti} evidentiranih aktivnosti vraćeno je u nepreneseno stanje.
                    </Typography>
                        <Box style={{display:"flex", justifyContent:"flex-end"}}>
                            <Button variant="contained" onClick={() => setAfterDeleteAlert(false)}>U redu</Button>
                        </Box>
                </Box>
            </Modal>

            <Modal className="modal" open={prebaciStavkuNaDrugiRacunFormOpened}>
                <Box sx={{...styleDelete, minHeight:"60%"}} overflow="auto">
                    <PrebaciStavkuNaDrugiRacunForm filterRacuni={props.filterRacuni} stavke={stavke} setStavke={setStavke} setFormOpened={setPrebaciStavkuNaDrugiRacunFormOpened} racunskaStavkaID={stavkaForPrebacajID}></PrebaciStavkuNaDrugiRacunForm>
                </Box>
            </Modal>

            <Modal open={brisanjeIzvjestajOpened}>
                <Box sx={styleDelete} overflow={"auto"} >
                    <RacuniIzvjestajBrisanje data={brisanjeIzvjestajData} close={() => setBrisanjeIzvjestajOpened(false)}></RacuniIzvjestajBrisanje>
                </Box>
            </Modal>

            {poreznaNapomenaAlert ? <AlertDialog message={"Odabrana porezna napomena ne odgovara očekivanoj. Jeste li sigurni da želite spremiti dokument?"} title={"Upozorenje o poreznoj napomeni"} cancelLabel={"Povratak na uređivanje dokumenta"} confirmLabel={"Spremi"} cancelAction={() => {setPoreznaNapomenaAlert(false); setLoading(false);}} confirmAction={saveConfirmed}></AlertDialog> : null}
           
            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default RacunForm;