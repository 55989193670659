import React, { useEffect, useState, useContext } from "react";
import { Autocomplete, Box, Button, FormControl, Grid, TextField, Typography, Select, MenuItem, InputLabel, FormControlLabel, Switch, InputAdornment, Tooltip, FormHelperText } from "@mui/material";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import SaveIcon from "@mui/icons-material/Save";
import ExitToApp from "@mui/icons-material/ExitToApp";
import api from "../util/api";
import EditIcon from "@mui/icons-material/Edit";
import AddchartIcon from "@mui/icons-material/Addchart";
import database from "../util/database";
import OutlinedInput from "@mui/material/OutlinedInput";
import GroupIcon from "@mui/icons-material/Group";
import AutocompleteAsync from "../controls/autocompleteasync";
import Chip from "@mui/material/Chip";
import CurrencyTextField from "../controls/currencyTextField";
import { SketchPicker } from "react-color";
import ColorPicker from "../controls/colorPicker";
import { validateDate } from "@mui/x-date-pickers/internals";
import { SortDescendingOutlined } from "@ant-design/icons";
import Snack from "../controls/snack";
import SettingsIcon from "@mui/icons-material/Settings";
import $ from "jquery";
import GlobalContext from "../components/globalContext";
import { ResetTvRounded } from "@mui/icons-material";

function FirmaForm(props) {
    const global = useContext(GlobalContext);
    const useConstructor = Util.useConstructor();
    const form = props.form;
    const odvjetnicka = global && global.user.TipTvrtkeID == 2;
    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;
    const [loading, setLoading] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackType, setSnackType] = useState("success");
    const [snackMessage, setSnackMessage] = useState("Uspješno spremljeno");

    const [edited, setEdited] = useState(false);
    const [valid, setValid] = useState(true);
    const [data, setData] = useState({ Adresa: { MjestoResource: {}, AdresaNaziv: "", AdresaID: 0, DefaultBankaID: 0 } });

    const [adresa, setAdresa] = useState();
    const [mjestoApi, setMjestoApi] = useState();
    const [adresaApi, setAdresaApi] = useState();
    const [logo, setLogo] = useState();

    const image64prefix = "data:image/png;base64,";

    const style1 = {
        backgroundColor: 'pink',
        ':hover': {
          backgroundColor: 'red'
        },
        ':active': {
            backgroundColor: 'blue'
          }
      };

      const style = {
        control: styles => ({ ...styles, backgroundColor: 'yellow' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isSelected ? 'red' : 'blue',
            color: '#FFF',
            cursor: isDisabled ? 'not-allowed' : 'default',
          };
        },
      };


    useConstructor(() => {
        // alert("constructor")
    });

    useEffect(() => {
        if (!data.FirmaID && props.data) {
            setData(props.data);
            setAdresa(props.data.Adresa);
        }
    });

    function handleTextChange(e) {
        setEdited(true);
        let newData = { ...data };
        newData[e.target.id] = e.target.value;
        setData(newData);

        validate(newData);
        if (data[e.target.id + "Error"]) {
            // validate(newData);
        }
    }

    function handleColorChange(color, id) {
        setEdited(true);
        let newData = { ...data };
        newData[id] = color;
        setData(newData);
    }

    function handleSwitchChange(e) {
        setEdited(true);
        let newData = { ...data };
        newData[e.target.id] = e.target.checked;
        setData(newData);
    }

    function handleSelectChange(e, id) {
    


        setEdited(true);
        let newData = { ...data };
        newData[e.target.name] = e.target.value;
        setData(newData);

        if(e.target.name == "DefaultRacunValutaID") {
            validate(newData);
        }
    }

    function onMjestoChange(e, newValue) {
        setEdited(true);
        let newData = { ...data };
        if (newValue) {
            newData.MjestoID = newValue.MjestoID;
            newData.Mjesto = newValue;
        } else {
            newData.MjestoID = 0;
            newData.Mjesto = newValue;
        }

        setData(newData);

        if (data["MjestoError"]) {
            validate(newData);
        }
    }

    function onMjestoInputChange(e, value) {
        setMjestoApi("mjesta/search/" + value + "/" + database.get("user").firmaID);
    }

    function onAdresaChange(e, newVal) {
        setEdited(true);
        let newData = { ...data };
        newData.Adresa = newVal;
        if (newVal) {
            newData.AdresaID = newVal.AdresaID;
            if (newVal) {
                newData.Mjesto = newVal.MjestoResource; //props.data.Mjesta.filter(x=>x.MjestoID == newVal.MjestoResource.MjestoID)[0];
                newData.MjestoID = newVal.MjestoResource.MjestoID;
                newData.AdresaNaziv = newVal.AdresaNaziv;
            }
        } else {
            newData.AdresaNaziv = null;
        }
        setData(newData);

        validate(newData);
        if (data["AdresaError"]) {
            // validate(newData);
        }
    }

    function onAdresaNazivChange(e) {
        setEdited(true);
        let newData = { ...data };

        if (e && e.target) {
            newData["AdresaNaziv"] = e.target.value;
            setData(newData);
            let apis = "firma/searchadresa?naziv=" + e.target.value + "&firmaID=" + database.get("user").firmaID;
            setAdresaApi(apis);
        }

        if (data["AdresaError"]) {
            validate(newData);
        }
    }

    function onSwitchChange(e) {
        let newData = { ...data };
        newData[e.target.name] = e.target.checked;
        setData(newData);
    }

    function validate(dataToValidate) {
        let newData = {};
        if (dataToValidate) {
            newData = dataToValidate;
        } else {
            newData = { ...data };
        }

        let isValid = true;
        let field = "";
        let value = "";
        let isError = false;

        field = "Naziv";
        value = newData[field];
        isError = !newData["Naziv"];
        setError(isError, field, newData);
        if (isError) isValid = false;

        field = "Adresa";
        value = newData[field];
        isError = !(newData["Adresa"] || newData["AdresaNaziv"]);
        setError(isError, field, newData);
        if (isError) isValid = false;

        field = "AktivnostDefTrajanjeMin";
        value = newData[field];
        isError = value < 0 || value > 1440;
        setError(isError, field, newData);
        if (isError) isValid = false;

        field = "DefaultDatumPlacanjaBrojDana";
        value = newData[field];
        isError = value == null || value < 0 || value > 120;
        setError(isError, field, newData);
        if (isError) isValid = false;

        field = "DefaultBrojRegistratora";
        value = newData[field];
        isError = !value || value < 1;
        setError(isError, field, newData);
        if (isError) isValid = false;

        field = "DefaultSatnicaZaokruzivanje";
        value = newData[field];
        isError = !value || value < 1 || value > 60;
        setError(isError, field, newData);
        if (isError) isValid = false;

        field = "DefaultIstekKolacicaDani";
        value = newData[field];
        isError = value == null || value < 0;
        setError(isError, field, newData);
        if (isError) isValid = false;

        field = "DefaultRacunValutaID";
        value = newData[field];
        isError = value == null || value < 1;
        setError(isError, field, newData);
        if (isError) isValid = false;
        
        field = "OdabraneMinute";
        value = newData[field];
        isError = !value || value.length == 0 || value.length > 6;
        setError(isError, field, newData);
        if (isError) isValid = false;

        field = "OIB";
        value = newData[field];
        isError = !value || !Util.OIBCheck(value);
        setError(isError, field, newData);
        if (isError) isValid = false;
        
        field = "DefEvidencijaBrojRedovaPredlozaka";
        value = newData[field];
        isError = data.DefEvidencijaBrojRedovaPredlozaka == null || isNaN(parseInt(data.DefEvidencijaBrojRedovaPredlozaka)) || !Number.isInteger(Number(data.DefEvidencijaBrojRedovaPredlozaka)) || Number(data.DefEvidencijaBrojRedovaPredlozaka) < 0  || Number(data.DefEvidencijaBrojRedovaPredlozaka) > 8;
        setError(isError, field, newData);
        if (isError) isValid = false;

        setData(newData);

        setValid(isValid);

        // if(!isValid){
        //     setSnackMessage("Neispravni ili nepotpuni podaci");
        //     setSnackType("error");
        //     setSnackOpen(true);
        // }

        return isValid;
    }

    function setError(isError, field, data) {
        if (isError) {
            data[field + "Error"] = true;
        } else {
            data[field + "Error"] = false;
        }
    }

    function save() {
        const isValid = validate();
        if (isValid) {
            setLoading(true);

            api.post("firma/update", data, (result) => {
                if (result.Success) {
                    global.setLogoFile(data.Logo);
                    setData(result);
                    let usr = database.get("user");
                    usr.tipPrikazaSatnice = data.TipPrikazaSatniceID; 
                    database.set("user", usr);
                    global.setUser(usr);
                    //alert("Uspješno spremljeno");
                    setSnackMessage("Uspješno spremljeno");
                    setSnackType("success");
                    setLoading(false);
                    setEdited(false);
                    setSnackOpen(true);
                } else {
                    setLoading(false);
                    setSnackMessage(result.ErrorMessage);
                    setSnackType("error");
                    setSnackOpen(true);
                }
            });
        }
    }

    function send() {
        fileToBase64(logo, (file) => {
            alert(file);
            //console.log(file);
            let data = { File: file, Filename: "test" };
            api.post("firma/uploadfile", data, (result) => {});
        });
    }

    function fileToBase64(file, callback) {
        var reader = new FileReader();
        reader.onload = function () {
            var base64String = reader.result.split(",")[1];
            callback(base64String);
            URL.revokeObjectURL(fileURL); // revoke the object URL to free up memory
        };
        var fileURL = URL.createObjectURL(file); // create a temporary URL for the file
        if (file.type.indexOf("image") === 0) {
            reader.readAsDataURL(file);
        } else {
            var fileReader = new FileReader();
            fileReader.onload = function (event) {
                var buffer = event.target.result;
                var binary = "";
                var bytes = new Uint8Array(buffer);
                var len = bytes.byteLength;
                for (var i = 0; i < len; i++) {
                    binary += String.fromCharCode(bytes[i]);
                }

                var base64String = btoa(binary);
                //console.log("binaryyyyyyyyyyyyyyyy");
                //console.log(binary);
                callback(base64String);
            };
            fileReader.readAsArrayBuffer(file);
        }
    }

    const url = "http://localhost:56895/firma/uploadimagetodb";

    function onLogoChange(e) {
        setLogo(e.target.files[0]);
        fileToBase64(e.target.files[0], (file) => {
            let newData = { ...data };
            newData.Logo = file;
            setData(newData);
        });
        setEdited(true);
    }

    function handleMultiSelectChange(e, id) {


        // if(e.target.name == "OdabraneMinute" && e.target.value.length > 6){
        //     return;
        // }

        setEdited(true);
        let newData = { ...data };
        let defaultFirmaValute = [];
        if(e.target.name == "FirmaValute") {
  
            // e.target.value.map((valutaID, index)=>{
            //     let valuta = data.Valute.filter(x=>x.ValutaID == valutaID)[0];
            //     defaultFirmaValute.push(valuta);
            // })
            

            let iof = e.target.value.indexOf(data.DefaultRacunValutaID);
            if(iof == -1) {
                newData["DefaultRacunValutaID"] = 0;
            }

            // newData["DefaultFirmaValute"] = defaultFirmaValute;

            validate(newData);
        }
        
        if(e.target.name == "OdabraneMinute"){
            newData[e.target.name] = e.target.value;
            validate(newData);
        }

        newData[e.target.name] = e.target.value;
        setData(newData);
    }

    return (
        <ComponentContainer loading={loading} style={{ height: "200px" }}>
            <Grid container style={{ maxWidth: "2000px" }} spacing={1}>
                <Snack open={snackOpen} type={snackType} onClose={() => setSnackOpen(false)} message={snackMessage} />
                <Grid item xs={12}>
                    <table style={{ width: "100%" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <Typography variant="h4" gutterBottom component="div" style={{ marginBottom: "15px" }}>
                                        <SettingsIcon fontSize="medium" style={{ marginRight: "15px" }}></SettingsIcon>
                                        Postavke firme
                                    </Typography>
                                </td>
                                <td style={{ width: "120px" }}>
                                    <FormControl margin="normal" className="logopicker" fullWidth style={{ textAlign: "center" }}>
                                        <input style={{ display: "none" }} type="file" id="files" onChange={onLogoChange} name="FileUpload1" />
                                        {/* <button onClick={() => send()}>send</button> */}
                                        {data.Logo ? (
                                            <img
                                                style={{ maxWidth: "200px", marginLeft: "auto", marginRight: "auto", cursor: "pointer" }}
                                                src={data ? image64prefix + data.Logo : null}
                                                onClick={(e) => {
                                                    document.getElementById("files").click();
                                                }}
                                            ></img>
                                        ) : null}
                                        <a
                                            style={{ color: "gray", cursor: "pointer" }}
                                            onClick={(e) => {
                                                document.getElementById("files").click();
                                            }}
                                        >
                                            Odaberi logo
                                        </a>
                                    </FormControl>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>

                <Grid item xs={1}>
                    <FormControl fullWidth>
                        <TextField 
                            id="FirmaID" 
                            label="ID" 
                            value={data && data.FirmaID} 
                            size="small"
                            style={{backgroundColor:'cornsilk'}}
                            InputProps={{ 
                                readOnly: true
                            }}
                            inputProps={{
                                style: { textAlign: 'center' }
                            }}                            
                            InputLabelProps={{ shrink: !!(data && data.FirmaID) }}>
                        </TextField>
                    </FormControl>
                </Grid>

                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <TextField id="Naziv" error={data && data.NazivError} inputProps={{ maxLength: 250 }} helperText={data && !data.Naziv && "Obavezno polje"} label="Naziv" value={data && data.Naziv} onChange={handleTextChange} size="small" InputLabelProps={{ shrink: !!(data && data.Naziv) }}></TextField>
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField id="OIB" error={data && (!data.OIB || !Util.OIBCheck(data.OIB))} helperText={data && !data.OIB ? "Obavezno polje" : (data && data.OIB && !Util.OIBCheck(data.OIB) ? "Neispravan OIB!" : "")} label="OIB" value={data && data.OIB} onChange={handleTextChange} size="small" InputLabelProps={{ shrink: !!(data && data.OIB) }}></TextField>
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="Da bi se prikazala promjena pozicije logotipa, potrebno se ponovno prijaviti" arrow placement="top">
                        <FormControl fullWidth>
                            <InputLabel>Pozicija logotipa</InputLabel>
                            <Select name="LogoPositionID" size="small" value={(data != null && data.LogoPositionID) || []} label={"Pozicija logotipa"} onChange={(e) => handleSelectChange(e)}>
                                {data != null &&
                                    data.LogoPositions &&
                                    data.LogoPositions.map((o, index) => {
                                        return (
                                            <MenuItem key={"lps" + index} value={o.LogoPositionID}>
                                                {o.Naziv}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Tooltip>
                </Grid>

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        {/* <Autocomplete renderOption={(props, option) => <li {...props}>{option.AdresaNaziv}</li>} disablePortal id="combo-box-demo" options={props.data.Adrese} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Movie" />} /> */}
                        {/* <Autocomplete freeSolo onInputChange={onAdresaNazivChange} value={adresa} onChange={onAdresaChange} size="small" options={adrese} name="Adresa" getOptionLabel={(option) => option.AdresaNaziv} renderInput={(params) => <TextField {...params} label="Adresa" />} /> */}
                        <AutocompleteAsync
                            renderOption={(props, option) => {
                                return option ? (
                                    <li {...props} key={"adresals" + option.AdresaID}>
                                        {option.AdresaNaziv}
                                    </li>
                                ) : null;
                            }}
                            api={adresaApi}
                            onChange={onAdresaChange}
                            onInputChange={onAdresaNazivChange}
                            size="small"
                            value={data && data.Adresa}
                            getOptionLabel={(option) => (option && option.AdresaNaziv ? option.AdresaNaziv : "")}
                            name="Adresa"
                            renderInput={(params) => <TextField error={data && data.AdresaError} {...params} label="Adresa" />}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <AutocompleteAsync
                            renderOption={(props, option) => {
                                return option ? (
                                    <li {...props} key={"mjsto" + option.MjestoID}>
                                        {option.PostanskiBroj + " " + option.Naziv + ", " + option.DrzavaNaziv}
                                    </li>
                                ) : null;
                            }}
                            api={mjestoApi}
                            onChange={onMjestoChange}
                            onInputChange={onMjestoInputChange}
                            size="small"
                            value={data && data.Adresa && data.Adresa.MjestoResource}
                            getOptionLabel={(option) => (option && option.PostanskiBroj ? option.PostanskiBroj + " " + option.Naziv + ", " + option.DrzavaNaziv : "")}
                            name="Mjesto"
                            renderInput={(params) => <TextField {...params} label="Mjesto" />}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <Tooltip title="Ukoliko nije odabrana banka potrebno je ručno upisivati tečajnu listu" arrow placement="top">
                        <FormControl fullWidth>
                            <InputLabel>Banka</InputLabel>
                            <Select name="DefaultBankaID" size="small" value={parseInt(data.DefaultBankaID)} label={"Banka"} onChange={(e) => handleSelectChange(e)}>
                                {data &&
                                    data.Banke &&
                                    data.Banke.map((o, index) => {
                                        return (
                                            <MenuItem key={"mnuitz" + index} value={parseInt(o.BankaID)}>
                                                {o.Naziv}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Tooltip>
                </Grid>

                <Grid item xs={4}>
                    <FormControl fullWidth error={!data.DefaultRacunValutaID} >
                        <InputLabel>Defaultna valuta</InputLabel>
                        <Select  size="small" name="DefaultRacunValutaID" InputLabelProps={{ shrink: false}} placeholdersize="small" value={(data && data.DefaultRacunValutaID) || []} label={"Defaultna valuta"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.Valute &&
                                data.FirmaValute && 
                                data.Valute.filter(v => data.FirmaValute.includes(v.ValutaID)).map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitz" + index} value={o.ValutaID}>
                                            {o.Kratica}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        {!data.DefaultRacunValutaID && <FormHelperText>Obavezno polje!</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField id="IBAN" label="IBAN" inputProps={{ maxLength: 50 }} value={data && data.IBAN} onChange={handleTextChange} size="small" InputLabelProps={{ shrink: !!(data && data.IBAN) }}></TextField>
                    </FormControl>
                </Grid>

                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel>Defaultni tip oporezivosti za predmete</InputLabel>
                        <Select name="TipOporezivostiID" size="small" value={(data && data.TipOporezivostiID) || []} label={"Defaultni tip oporezivosti za predmete"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.TipoviOporezivosti &&
                                data.TipoviOporezivosti.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitz" + index} value={o.TipOporezivostiID}>
                                            {o.TipPorezaNaziv} / {o.Stopa * 100}%
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel>Defaultni tip oporezivosti za troškove</InputLabel>
                        <Select name="DefaultTrosakTipOporezivostiID" size="small" value={(data && data.DefaultTrosakTipOporezivostiID) || []} label={"Defaultni tip oporezivosti za troškove"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.TipoviOporezivosti &&
                                data.TipoviOporezivosti.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitz" + index} value={o.TipOporezivostiID}>
                                            {o.TipPorezaNaziv} / {o.Stopa * 100}%
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                {/* <Grid item xs={6}>
                    <FormControl fullWidth>
                    <Tooltip title="Vrijednost u minutama koja se nudi pri stvaranju novog korisnika za njegovu personaliziranu postavku pri unosu aktivnosti" arrow followCursor>
                        <CurrencyTextField error={data && data.AktivnostDefTrajanjeMinError} helperText={data && data.AktivnostDefTrajanjeMinError && "Mora biti od 0 do 1440"} id="AktivnostDefTrajanjeMin" size={"small"} variant="outlined" label="Defaultno trajanje aktivosti (min)" value={data && data.AktivnostDefTrajanjeMin} onChange={handleTextChange}></CurrencyTextField>
                    </Tooltip>
                    </FormControl>
                </Grid> */}

                <Grid item xs={3}>
                    <Tooltip title="Vrijednost u minutama koja se nudi pri stvaranju novog predmeta" enterDelay={200} enterNextDelay={200} arrow followCursor>
                        <FormControl fullWidth>
                            <CurrencyTextField id="DefaultSatnicaZaokruzivanje" name="DefaultSatnicaZaokruzivanje" error={data.DefaultSatnicaZaokruzivanjeError} helperText={data.DefaultSatnicaZaokruzivanjeError && "Vrijednost mora biti od 1 do 60"} size={"small"} variant="outlined" label="Defaultno zaokruživanje satnice (min)" value={data && data.DefaultSatnicaZaokruzivanje} onChange={handleTextChange}></CurrencyTextField>                        
                        </FormControl>
                    </Tooltip>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="Defaultna količina registratora koja se nudi pri stvaranju materijalne arhive" enterDelay={200} enterNextDelay={200} arrow followCursor>
                        <FormControl fullWidth>
                            <CurrencyTextField id="DefaultBrojRegistratora" name="DefaultBrojRegistratora" error={data.DefaultBrojRegistratoraError} helperText={data.DefaultBrojRegistratoraError && "Mora biti veći od 0"} size={"small"} variant="outlined" label="Defaultna količina materijalne arhive (registratora...)" value={data && data.DefaultBrojRegistratora} onChange={handleTextChange}></CurrencyTextField>
                        </FormControl>
                    </Tooltip>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="Defaultni broj dana za dospijeće plaćanja računa" enterDelay={200} enterNextDelay={200} arrow followCursor>
                        <FormControl fullWidth>
                            <CurrencyTextField error={data && data.DefaultDatumPlacanjaBrojDanaError} helperText={data && data.DefaultDatumPlacanjaBrojDanaError && "Mora biti od 1 do 120"} id="DefaultDatumPlacanjaBrojDana" name="DefaultDatumPlacanjaBrojDana" size={"small"} variant="outlined" label="Defaultni broj dana za dospijeće plaćanja" value={data && data.DefaultDatumPlacanjaBrojDana} onChange={handleTextChange}></CurrencyTextField>
                        </FormControl>
                    </Tooltip>
                </Grid>

                <Grid item xs={3}>
                    <Tooltip title="Defaultni broj redova predložaka evidencija pri unosu aktivnosti" enterDelay={200} enterNextDelay={200} arrow followCursor>
                        <FormControl fullWidth>
                            <TextField id="DefEvidencijaBrojRedovaPredlozaka" label="Broj redova predložaka" type="number" inputProps={{ min: 0, max: 8 }} value={data && data.DefEvidencijaBrojRedovaPredlozaka || ""} error={!data || data.DefEvidencijaBrojRedovaPredlozaka == null || isNaN(parseInt(data.DefEvidencijaBrojRedovaPredlozaka)) || !Number.isInteger(Number(data.DefEvidencijaBrojRedovaPredlozaka)) || Number(data.DefEvidencijaBrojRedovaPredlozaka) < 0  || Number(data.DefEvidencijaBrojRedovaPredlozaka) > 8} onChange={handleTextChange} size="small"></TextField>
                        </FormControl>
                    </Tooltip>
                </Grid>

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Defaultni tip jedinice mjere</InputLabel>
                        <Select name="DefaultTipJediniceMjereID" size="small" value={(data && data.DefaultTipJediniceMjereID) || []} label={"Defaultni tip jedinice mjere"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.TipoviJediniceMjere &&
                                data.TipoviJediniceMjere.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitz" + index} value={o.TipJediniceMjereID}>
                                            {o.Opis} ({o.Kratica})
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Defaultni način plaćanja</InputLabel>
                        <Select name="DefaultNacinPlacanjaID" size="small" value={(data && data.DefaultNacinPlacanjaID) || []} label={"Defaultni način plaćanja"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.NaciniPlacanja &&
                                data.NaciniPlacanja.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitz" + index} value={o.NacinPlacanjaID}>
                                            {o.Naziv}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Defaultni negotovinski način plaćanja</InputLabel>
                        <Select name="DefaultNegotovinskiNacinPlacanjaID" size="small" value={(data && data.DefaultNegotovinskiNacinPlacanjaID) || []} label={"Defaultni negotovinski način plaćanja"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.NaciniPlacanja &&
                                data.NaciniPlacanja.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitz" + index} value={o.NacinPlacanjaID}>
                                            {o.Naziv}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <CurrencyTextField id="DefaultIstekKolacicaDani" name="DefaultIstekKolacicaDani" error={data.DefaultIstekKolacicaDaniError} helperText={data.DefaultIstekKolacicaDaniError && "Mora biti pozitivan broj"} size={"small"} variant="outlined" label="Vrijeme trajanja kolačića (dani)" value={data && data.DefaultIstekKolacicaDani} onChange={handleTextChange}></CurrencyTextField>
                    </FormControl>
                </Grid>

                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <InputLabel>Odvezivanje računa je onemogućeno:</InputLabel>
                        <Select name="TipAlgoritmaKontroleOdvezivanjaID" size="small" value={(data && data.TipAlgoritmaKontroleOdvezivanjaID) || []} label={"Odvezivanje računa je onemogućeno:"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.TipoviAlgoritmaKontroleOdvezivanja &&
                                data.TipoviAlgoritmaKontroleOdvezivanja.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitz3" + index} value={o.TipAlgoritmaKontroleOdvezivanjaID}>
                                            {o.Naziv}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Konto za strane račune</InputLabel>
                        <Select name="DefaultKontoStraniID" size="small" value={(data && data.DefaultKontoStraniID) || []} label={"Konto za strane račune"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.NullableKontos &&
                                data.NullableKontos.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitzkonto" + index} value={o.KontoID}>
                                            {o.Naziv + " (" + o.ForeignKontoID + ")"} {o.MjestoTroskaNaziv ? " - " + o.MjestoTroskaNaziv + (o.MjestoTroskaForeignID ? " (" + o.MjestoTroskaForeignID + ")" : "") : ""}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "center" }}></Grid>

                {/* <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel>Konto za strane račune</InputLabel>
                        <Select name="DefaultKontoStraniID" size="small" value={(data && data.DefaultKontoStraniID) || []}  label={"Konto za strane račune"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.NullableKontos &&
                                data.NullableKontos.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitzkonto" + index} value={o.KontoID}>
                                            {o.Naziv + ' (' + o.ForeignKontoID + ')'} {o.MjestoTroskaNaziv ? " - " + o.MjestoTroskaNaziv + (o.MjestoTroskaForeignID ? ' (' + o.MjestoTroskaForeignID + ')' : '') : ''}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid> */}

                <Grid item xs={3}>
                    <Tooltip title="Od-Do: striktni upis (bez 'rupa' u dnevnoj satnici); Period: komotni upis: samo trajanje" arrow placement="top">
                        <FormControl fullWidth>
                            <InputLabel>Tip prikaza i upisa satnice</InputLabel>
                            <Select name="TipPrikazaSatniceID" size="small" value={parseInt(data.TipPrikazaSatniceID)} label={"TipPrikaza i upisa satnice"} onChange={(e) => handleSelectChange(e)}>
                                {data &&
                                    data.TipoviPrikazaSatnice &&
                                    data.TipoviPrikazaSatnice.map((o, index) => {
                                        return (
                                            <MenuItem key={"mnuitz" + index} value={parseInt(o.TipPrikazaSatniceID)}>
                                                {o.Naziv}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Tooltip>
                </Grid>


                <Grid item xs={odvjetnicka ? 6 : 9}>
                    <FormControl fullWidth>
                        <InputLabel>Kratice za odabir vremena trajanja (minuta)</InputLabel>
                        <Select
                            renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    {(selected.sort((prvi, drugi) => (prvi - drugi))).map((value, index) => (
                                        <Chip sx={{height:'24px'}} key={value} label={props.data && props.data.Minute && props.data.Minute.filter((x) => x == value)[0] + " min"} />
                                    ))}
                                </Box>
                            )}
                            multiple
                            // style={style}
                            name="OdabraneMinute"
                            size="small"
                            value={(data && data.OdabraneMinute) || []}
                            label={"Kratice za odabir vremena trajanja (minuta)"}
                            error={data && data.OdabraneMinute && (data.OdabraneMinute.length == 0 || data.OdabraneMinute.length > 6)}
                            required
                            onChange={(e) => handleMultiSelectChange(e)}
                        >
                            {props.data &&
                                props.data.Minute.map((o, index) => {
                                    return (
                                        <MenuItem id="mirko" key={"minute" + index} value={o}>
                                            {/* <span className={data && data.Prava && data.Prava.includes(o.PravoID) ? "pill" : ""}>{o.Naziv}</span> */}
                                            {o + " min"}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                {odvjetnicka ? (
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                options={data.TipoviVrstePredmeta || []}
                                value={data.TipoviVrstePredmeta ? data.TipoviVrstePredmeta.find(t => t.TipVrstePredmetaID == data.DefaultTipVrstePredmetaID) : null}
                                autoHighlight
                                getOptionLabel={(option) => option.Naziv}
                                onChange={(e,v) => {
                                    setEdited(true);
                                    let newData = { ...data };
                                    if (v) {
                                        newData.DefaultTipVrstePredmetaID = v.TipVrstePredmetaID;
                                    } else {
                                        newData.DefaultTipVrstePredmetaID = null;
                                    }
                                    setData(newData);
                                }}
                                renderInput={(params) => <TextField {...params} label="Defaultna vrsta predmeta" size="small" style={{width:"100%"}} />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Jezici</InputLabel>
                        <Select
                            renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    {selected.map((value, index) => (
                                        <Chip sx={{height:'24px'}} key={value} label={props.data && props.data.Jezici && props.data.Jezici.filter((x) => x.JezikID == value)[0].Naziv} />
                                    ))}
                                </Box>
                            )}
                            multiple
                            name="FirmaJezici"
                            size="small"
                            value={(data && data.FirmaJezici) || []}
                            label={"Jezici"}
                            error={data && data.FirmaJezici && data.FirmaJezici.length == 0}
                            required
                            onChange={(e) => handleMultiSelectChange(e)}
                        >
                            {props.data &&
                                props.data.Jezici.map((o, index) => {
                                    return (
                                        <MenuItem disabled={o.DisableForDelete} key={"mnuije" + index} value={o.JezikID}>
                                            {/* <span className={data && data.Prava && data.Prava.includes(o.PravoID) ? "pill" : ""}>{o.Naziv}</span> */}
                                            {o.Naziv}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Valute</InputLabel>
                        <Select
                            renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    {selected.map((value, index) => (
                                        <Chip sx={{height:'24px'}} key={value} label={props.data && props.data.Valute && props.data.Valute.filter((x) => x.ValutaID == value)[0].Naziv} />
                                    ))}
                                </Box>
                            )}
                            multiple
                            name="FirmaValute"
                            size="small"
                            value={data && data.FirmaValute || []}
                            label={"Valute"}
                            error={data && data.FirmaValute && data.FirmaValute.length == 0}
                            required
                            onChange={(e) => handleMultiSelectChange(e)}
                        >
                            {props.data &&
                                props.data.Valute &&
                                props.data.Valute.map((o, index) => {
                                    return (
                                        <MenuItem disabled={o.DisableForDelete} key={"mnuije" + index} value={o.ValutaID}>
                                            {/* <span className={data && data.Prava && data.Prava.includes(o.PravoID) ? "pill" : ""}>{o.Naziv}</span> */}
                                            {o.Naziv}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>


                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <FormControl fullWidth>Defaultne vrijednosti konta</FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel>Za jedinstvenu specifikaciju</InputLabel>
                        <Select name="PrebacajDefaultKontoID" size="small" value={(data && data.PrebacajDefaultKontoID) || []} label={"Za jedinstvenu specifikaciju"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.Kontos &&
                                data.Kontos.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitz" + index} value={o.KontoID}>
                                            {o.Naziv + " (" + o.ForeignKontoID + ")"} {o.MjestoTroskaNaziv ? " - " + o.MjestoTroskaNaziv + (o.MjestoTroskaForeignID ? " (" + o.MjestoTroskaForeignID + ")" : "") : ""}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel>Za specifikaciju satnica</InputLabel>
                        <Select name="PrebacajDefaultSatniceKontoID" size="small" value={(data && data.PrebacajDefaultSatniceKontoID) || []} label={"Za specifikaciju satnica"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.Kontos &&
                                data.Kontos.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitz" + index} value={o.KontoID}>
                                            {o.Naziv + " (" + o.ForeignKontoID + ")"} {o.MjestoTroskaNaziv ? " - " + o.MjestoTroskaNaziv + (o.MjestoTroskaForeignID ? " (" + o.MjestoTroskaForeignID + ")" : "") : ""}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel>Za specifikaciju troškova</InputLabel>
                        <Select name="PrebacajDefaultTroskoviKontoID" size="small" value={(data && data.PrebacajDefaultTroskoviKontoID) || []} label={"Za specifikaciju troškova"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.Kontos &&
                                data.Kontos.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitz" + index} value={o.KontoID}>
                                            {o.Naziv + " (" + o.ForeignKontoID + ")"} {o.MjestoTroskaNaziv ? " - " + o.MjestoTroskaNaziv + (o.MjestoTroskaForeignID ? " (" + o.MjestoTroskaForeignID + ")" : "") : ""}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel>Za specifikaciju tarifa</InputLabel>
                        <Select name="PrebacajDefaultTarifeKontoID" size="small" value={(data && data.PrebacajDefaultTarifeKontoID) || []} label={"Za specifikaciju tarifa"} onChange={(e) => handleSelectChange(e)}>
                            {data &&
                                data.Kontos &&
                                data.Kontos.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnuitz" + index} value={o.KontoID}>
                                            {o.Naziv + " (" + o.ForeignKontoID + ")"} {o.MjestoTroskaNaziv ? " - " + o.MjestoTroskaNaziv + (o.MjestoTroskaForeignID ? " (" + o.MjestoTroskaForeignID + ")" : "") : ""}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} style={{ textAlign: "center" }}></Grid>

                <Grid item xs={4} style={{paddingLeft:'25px'}}>
                    <FormControl>
                        {/* <FormControlLabel control={<ColorPicker id="TipNaplatnostiNaplatnoBoja" color={data && data.TipNaplatnostiNaplatnoBoja} onChange={handleColorChange} />} label={<Typography style={{ fontSize: 12, paddingLeft: '8px' }}>Tip naplatnosti naplatno boja</Typography>} />                         */}
                        <ColorPicker id="TipNaplatnostiNaplatnoBoja" label="Tip naplatnosti naplatno boja" color={data && data.TipNaplatnostiNaplatnoBoja} onChange={handleColorChange}></ColorPicker>
                    </FormControl>
                </Grid>

                <Grid item xs={4} style={{paddingLeft:'25px'}}>
                    <FormControl>
                        {/* <FormControlLabel control={<ColorPicker id="TipNaplatnostiNenaplatnoBoja" color={data && data.TipNaplatnostiNenaplatnoBoja} onChange={handleColorChange} />} label={<Typography style={{ fontSize: 12, paddingLeft: '8px' }}>Tip naplatnosti nenaplatno boja</Typography>} />                         */}
                        <ColorPicker id="TipNaplatnostiNenaplatnoBoja" label="Tip naplatnosti nenaplatno boja" color={data && data.TipNaplatnostiNenaplatnoBoja} onChange={handleColorChange}></ColorPicker>
                    </FormControl>
                </Grid>

                <Grid item xs={4} style={{ textAlign: "left", paddingTop: "0px" }}>
                    <FormControl fullWidth>
                        <FormControlLabel control={<Switch id="MogucePrebacitiAktivnostiBezCijene" checked={(data && data.MogucePrebacitiAktivnostiBezCijene) || false} onChange={handleSwitchChange} />} label={<Typography style={{ fontSize: 12 }}>Moguće prebaciti aktivnosti bez cijene</Typography>} />
                    </FormControl>
                </Grid>

                <Grid item xs={4} style={{ textAlign: "left", paddingTop: "0px" }}>
                    <FormControl fullWidth>
                        <FormControlLabel control={<Switch id="MogucePrebacitiEvidencijeBezDefiniranogTecaja" checked={(data && data.MogucePrebacitiEvidencijeBezDefiniranogTecaja) || false} onChange={handleSwitchChange} />} label={<Typography style={{ fontSize: 12 }}>Moguće prebaciti evidencije bez definiranog tečaja</Typography>} />
                    </FormControl>
                </Grid>

                <Grid item xs={4} style={{ textAlign: "left", paddingTop: "0px" }}>
                    <FormControl fullWidth>
                        <FormControlLabel control={<Switch id="MoguceFakturiratiRacuneSaSatnicamaBezCijene" checked={(data && data.MoguceFakturiratiRacuneSaSatnicamaBezCijene) || false} onChange={handleSwitchChange} />} label={<Typography style={{ fontSize: 12 }}>Moguće fakturirati račune koji imaju satnice bez cijene (s cijenom nula)</Typography>} />
                    </FormControl>
                </Grid>

                <Grid item xs={4} style={{ textAlign: "left", paddingTop: "0px" }}>
                    <FormControl fullWidth>
                        <FormControlLabel control={<Switch id="EvidencijePrikazTroskovaZaSveZaposlenike" checked={(data && data.EvidencijePrikazTroskovaZaSveZaposlenike) || false} onChange={handleSwitchChange} />} label={<Typography style={{ fontSize: 12 }}>U filtru evidencija prikazuje se opcija troškova za sve korisnike</Typography>} />
                    </FormControl>
                </Grid>

                <Grid item xs={4} style={{ textAlign: "left", paddingTop: "0px" }}>
                    <FormControl fullWidth>
                        <FormControlLabel control={<Switch id="RacuniPrikazSvihRacunaDefault" checked={(data && data.RacuniPrikazSvihRacunaDefault) || false} onChange={handleSwitchChange} />} label={<Typography style={{ fontSize: 12 }}>Pri dolasku na upravljanje računima prikazuju svi računi</Typography>} />
                    </FormControl>
                </Grid>

                <Grid item xs={4} style={{ textAlign: "left", paddingTop: "0px" }}>
                    <FormControl fullWidth>
                        <FormControlLabel control={<Switch id="DefaultTarifnaAktivnostOmoguciIznosNula" checked={(data && data.DefaultTarifnaAktivnostOmoguciIznosNula) || false} onChange={handleSwitchChange} />} label={<Typography style={{ fontSize: 12 }}>Omogućen je unos tarifnih aktivnosti s iznosom nula</Typography>} />
                    </FormControl>
                </Grid>

                <Grid item xs={4} style={{ textAlign: "left", paddingTop: "0px" }}>
                    <FormControl fullWidth>
                        <Tooltip title="Ukoliko je ova opcija uključena, tijekom unosa evidencije pojavljuje se gumb Pohrani, koji omogućuje pohranu trenutne evidencije bez izlaska iz prozora, te mogućnost dodavanja nove evidencije uz promjenu podataka i gumb Trošak koji omogućuje dodavanje troška na bazi podataka trenutno upisanih u evidenciji." arrow placement="bottom">
                            <FormControlLabel control={<Switch id="EvidencijaOmoguciPohrani" checked={(data && data.EvidencijaOmoguciPohrani) || false} onChange={handleSwitchChange} />} label={<Typography style={{ fontSize: 12 }}>Moguć brzi unos dodatnih evidencija i troškova</Typography>} />
                        </Tooltip>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Button style={{ maxWidth: "300px", float: "right" }} variant={"contained"} disabled={!edited || !valid} onClick={save}>
                        Spremi izmjene
                    </Button>
                    {/* <Button style={{ maxWidth: "300px", float: "right" }} variant={"contained"} disabled={!edited || !valid} onClick={()=>global.setSelectedTab("firmapostavke")}>
                        Spremi izmjene11
                    </Button> */}
                </Grid>
            </Grid>

        </ComponentContainer>
    );
}

export default FirmaForm;
