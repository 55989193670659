import { Autocomplete, Button, FormControl, Grid, ListItem, ListItemText, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import ExitToApp from "@mui/icons-material/ExitToApp";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import { List } from "antd";
import dateFormat, { masks } from "dateformat";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function PrebaciStavkuRacunskeStavkeNaDruguSpecifikacijuForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const setFormOpened = props.setFormOpened;
    const stavkaRacunskeStavkeID = props.stavkaRacunskeStavkeID;
    const refresh = props.refresh;

    const [loading, setLoading] = useState(true);
    const [racunskeStavke, setRacunskeStavke] = useState([]);
    const [racunskaStavka, setRacunskaStavka] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        api.get("racunskastavka/specifikacijenefakturiranihracuna/" + global.user.firmaID, loadRacunskeStavkeSuccess, loadRacunskeStavkeFail);
    });

    function loadRacunskeStavkeSuccess(data) {
        setRacunskeStavke(data);
        setLoading(false);
    }
    
    function loadRacunskeStavkeFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save() {
        let resource = {
            StavkaRacunskeStavkeID : stavkaRacunskeStavkeID,
            RacunskaStavkaID : racunskaStavka.RacunskaStavkaID
        }
        setLoading(true);
        api.post("racunskastavka/prebacistavkuracunskestavkenadruguspecifikaciju", resource, prebaciSuccess, prebaciFail);
    }

    function prebaciSuccess() {
        refresh();
        setFormOpened(false);
    }

    function prebaciFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid>
                    <Typography variant="h6" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        Odabranu stavku specifikacije prebacite na:
                    </Typography>   
                </Grid>
                <Grid item xs={12}>
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="specifikacije-title" style={{backgroundColor:"lightblue"}}>
                            <Grid container spacing={1}>
                                <Tooltip title="ID stavke">
                                    <Grid item xs={1}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="ID stavke" />
                                    </Grid>
                                </Tooltip>
                                <Tooltip title="Klijent">
                                    <Grid item xs={3}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Klijent" />
                                    </Grid>
                                </Tooltip>
                                <Tooltip title="ID računa">
                                    <Grid item xs={1}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="ID računa" />
                                    </Grid>
                                </Tooltip>
                                <Tooltip title="Datum računa">
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Datum računa" />
                                    </Grid>
                                </Tooltip>
                                <Tooltip title="Naziv">
                                    <Grid item xs={5}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Stavka računa" />
                                    </Grid>
                                </Tooltip>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {racunskeStavke.map((stavka, index) => {
                                    return <ListItem className="list-item" key={"specifikacija" + index}  onClick={() => setRacunskaStavka(stavka)} >
                                            <Grid container spacing={1}>
                                                    <Tooltip title={stavka.RacunskaStavkaID} enterDelay={300} enterNextDelay={300}>
                                                        <Grid item xs={1}>
                                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: stavka == racunskaStavka ? "bold" : "normal" }} primary={stavka.RacunskaStavkaID} />
                                                        </Grid>
                                                    </Tooltip>
                                                    <Tooltip title={stavka.KlijentNaziv} enterDelay={300} enterNextDelay={300}>
                                                        <Grid item xs={3}>
                                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: stavka == racunskaStavka ? "bold" : "normal" }} primary={stavka.KlijentNaziv} />
                                                        </Grid>
                                                    </Tooltip>
                                                    <Tooltip title={stavka.RacunID} enterDelay={300} enterNextDelay={300}>
                                                        <Grid item xs={1}>
                                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: stavka == racunskaStavka ? "bold" : "normal" }} primary={stavka.RacunID} />
                                                        </Grid>
                                                    </Tooltip>
                                                    <Tooltip title={dateFormat(stavka.DatumRacuna, "dd.mm.yyyy")} enterDelay={300} enterNextDelay={300}>
                                                        <Grid item xs={2}>
                                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: stavka == racunskaStavka ? "bold" : "normal" }} primary={dateFormat(stavka.DatumRacuna, "dd.mm.yyyy")} />
                                                        </Grid>
                                                    </Tooltip>
                                                    <Tooltip title={stavka.Opis} enterDelay={300} enterNextDelay={300}>
                                                        <Grid item xs={5}>
                                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: stavka == racunskaStavka ? "bold" : "normal" }} primary={stavka.Opis} />
                                                        </Grid>
                                                    </Tooltip>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} startIcon={<MoveUpIcon></MoveUpIcon>} variant="contained" style={{marginRight:"10px"}} color="success" disabled={loading || !racunskaStavka}> Prebaci</Button>
                        <Button variant="outlined" color="error" onClick={() =>  setFormOpened(false)} ><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Odustani</Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default PrebaciStavkuRacunskeStavkeNaDruguSpecifikacijuForm;