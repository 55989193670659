import { Autocomplete, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box } from "@mui/system";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import api from "../util/api";
import globalContext from "./globalContext";
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function ArhivaFileForm(props) {
    
    const useConstructor = Util.useConstructor();
    const formArhivaFile = props.formArhivaFile;
    const global = useContext(globalContext);
    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;
    const tipArhiveID = props.tipArhiveID;
    const predmetID = props.predmetID;
    const newMaster = props.newMaster;
    const arhivaID = props.arhivaID;
    const arhivaPredmet = props.arhivaPredmet;

    const [loading, setLoading] = useState(true);
    const [naziv, setNaziv] = useState("");
    const [tipDokumentaOptions, setTipDokumentaOptions] = useState([]);
    const [tipDokumenta, setTipDokumenta] = useState(null);
    const [datoteka, setDatoteka] = useState(null);
    const [datotekaNaziv, setDatotekaNaziv] = useState("");
    const [opis, setOpis] = useState("");
    const [dokumentNaziv, setDokumentNaziv] = useState("");
    const [file, setFile] = useState([]);
    const [contentType, setContentType] = useState("");
    const [fileSize, setFileSize] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
 
    useConstructor(() => {
        setLoading(true);
        api.get("tipDokumenta/" + global.user.firmaID, loadTipDokumentaOptionsSuccess, loadTipDokumentaOptionsFail);
    });

    function loadTipDokumentaOptionsSuccess(data) {
        setTipDokumentaOptions(data);
        if (formArhivaFile) {
            setNaziv(formArhivaFile.Naziv);
            data.forEach(tip => {
                if (tip.TipDokumentaID == formArhivaFile.TipDokumentaID) {
                    setTipDokumenta(tip);
                }
            });
            setDatotekaNaziv(formArhivaFile.DokumentNaziv);
            setDokumentNaziv(formArhivaFile.DokumentNaziv);
            setFile(formArhivaFile.File);
            setContentType(formArhivaFile.ContentType);
            setFileSize(formArhivaFile.FileSize);
            setOpis(formArhivaFile.Opis);
        }
        setLoading(false);
    }

    function loadTipDokumentaOptionsFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleNazivChange(e) {
        setNaziv(e.target.value);
    }

    function handleTipDokumentaChange(e,v) {
        setTipDokumenta(v);
    }

    function handleDatotekaChange(e) {
        ;
        let files = Array.from(e.target.files)
        setDatoteka(files.at(0));
    }

    useEffect(() => {
        if (datoteka) {
            setDokumentNaziv(datoteka.name);

            const reader = new FileReader();
            const fileByteArray = [];
    
            reader.readAsArrayBuffer(datoteka);
    
            reader.onloadend = (evt) => {
                if (evt.target.readyState === FileReader.DONE) {
                    const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                    for (const a of array) {
                        fileByteArray.push(a);
                    }

                    setFile(fileByteArray);
                }
            }

            setFileSize(datoteka.size);
            setContentType(datoteka.type);
        }
    }, [datoteka])

    function save() {
        setLoading(true);

        let resource = {
            Arhiva : {
                TipArhiveID : tipArhiveID,
                FirmaID : global.user.firmaID,
                PredmetID : predmetID,
                Broj : 0,
                Kolicina : 0,
                Elektronicka : true
            },
            ArhivaFile : {
                ArhivaFileID : formArhivaFile ? formArhivaFile.ArhivaFileID : -1,
                ArhivaID : arhivaID,
                Naziv : naziv,
                TipDokumentaID : tipDokumenta.TipDokumentaID,
                TipDokumentaNaziv : tipDokumenta.Naziv,
                DokumentNaziv : dokumentNaziv,
                Opis : opis,
                File : file,
                Ekstenzija : dokumentNaziv.substring(datoteka.name.lastIndexOf('.'), datoteka.name.length),
                FileSize : fileSize,
                ContentType : contentType
            },
            NewMaster : newMaster
        }

        api.post("archive/newelektronickaarhiva", resource, saveSuccess, saveFail);
    }

    function saveSuccess() {
        setLoading(false);
        setCanceled(false);
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container className="padding-grid">
                {!arhivaPredmet ? 
                    <Grid item xs={12} sx={{textAlign:'center'}}>
                        <Typography variant="h6" gutterBottom component="div" >
                            Stvaranje elektroničke/digitalne arhive
                        </Typography>    
                    </Grid>                
                : null}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Naziv" value={naziv} inputProps={{ maxLength: 50 }} onChange={handleNazivChange} error={!naziv} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            autoHighlight
                            id="tip-dokumenta-select"
                            options={tipDokumentaOptions}
                            getOptionLabel={(option) => option.Naziv}
                            value={tipDokumenta}
                            onChange={handleTipDokumentaChange}
                            renderInput={(params) => <TextField {...params} label="Tip dokumenta" error={!tipDokumenta} required size="small"/>}
                        />
                    </FormControl>
                </Grid>
                {datoteka || datotekaNaziv != "" ? (
                    <Grid item xs={8}>
                        <Typography variant="subtitle1" textAlign={"center"} style={{marginTop:"5px", marginBottom:"5px", width:"100%"}}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                marginLeft: '10px',
                                marginBottom: '10px'
                            }}>
                                <InsertDriveFileIcon fontSize="small" style={{marginRight:"10px"}}></InsertDriveFileIcon>
                                Odabrana datoteka: {datoteka ? datoteka.name : datotekaNaziv}
                            </div>
                        </Typography>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                            <Button variant="outlined" component="label" startIcon={<UploadFileIcon />} style={{marginTop:"5px", marginBottom:"5px"}}>
                                Prenesi datoteku
                                <input type="file" hidden onChange={handleDatotekaChange}></input>
                            </Button>
                        </Box>
                    </Grid>
                )}
                {datoteka || datotekaNaziv != "" ? (
                    <Grid item xs={4}>
                        <Button color="error" onClick={() => {setDatoteka(null); setDatotekaNaziv("")}} startIcon={<RemoveIcon></RemoveIcon>} style={{width:"100%"}}>
                            Ukloni datoteku
                        </Button>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <FormControl style={{ width: "100%" }}>
                        <TextField size="small" label="Opis" inputProps={{ maxLength: 500 }} multiline rows={4} value={opis} onChange={(e) => setOpis(e.target.value)} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" marginTop="10px">
                        <Button startIcon={<SaveIcon></SaveIcon>} color="success" variant="contained" disabled={!naziv || !tipDokumenta || !datoteka} onClick={save}>
                            Spremi
                        </Button>
                        <Button startIcon={<CloseIcon></CloseIcon>} color="error" variant="outlined" style={{marginLeft:"10px"}} onClick={() => {setCanceled(true); setFormOpened(false)}}>
                            Odustani
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );


}

export default ArhivaFileForm;