import { Alert, Autocomplete, Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormLabel, Grid, Grow, IconButton, InputAdornment, ListItem, Menu, MenuItem, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState, useRef} from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import ExitToApp from "@mui/icons-material/ExitToApp";
import LineIcon from "react-lineicons";
import AddIcon from '@mui/icons-material/Add';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from "@mui/icons-material/Search";
import globalContext from "./globalContext";
import AlertDialog from "./alertdialog";
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Tooltip } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from '@mui/icons-material/Edit';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import ErrorIcon from '@mui/icons-material/Error';
import auth_Header from "../util/authHeader";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';

function UnosAktivnostiPredmetiForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const korisnikID = props.korisnikID;
    const setDodavanjePredmeta = props.setDodavanjePredmeta;
    const dodaniPredmeti = props.dodaniPredmeti;
    const setDodaniPredmeti = props.setDodaniPredmeti;
    const formEvidencijaDetalj = props.formEvidencijaDetalj;
    const predmeti = props.predmeti;
    const klijenti = props.klijenti;
    const kontakti = props.kontakti;
    const setSamoOtvoreniPredmeti = props.setSamoOtvoreniPredmeti;
    const setSamoAktivniKlijenti = props.setSamoAktivniKlijenti;
    const unsavedChanges = props.unsavedChanges;
    
    const firmaValute = props.firmaValute;
    const tipOporezivostiOptions = props.tipoviOporezivosti;
    const tipNaplatnostiOptions = props.tipoviNaplatnosti;
    const tipNaplatnostiNaplatnoBoja = props.tipNaplatnostiNaplatnoBoja;
    const tipNaplatnostiNenaplatnoBoja = props.tipNaplatnostiNenaplatnoBoja;
    const mogucePrebacitiAktivnostiBezCijene = props.mogucePrebacitiAktivnostiBezCijene;
    const defaultTarifnaAktivnostOmoguciIznosNula = props.defaultTarifnaAktivnostOmoguciIznosNula;
    const elektronickaArhivaDostupnoMiB = props.elektronickaArhivaDostupnoMiB;
    const elektronickaArhivaMiBPerFile = props.elektronickaArhivaMiBPerFile;

    const [loading, setLoading] = useState(true);
    const [loadingPredmeti, setLoadingPredmeti] = useState(true);
    const [loadingKontakti, setLoadingKontakti] = useState(true);
    const [selectedKlijent, setSelectedKlijent] = useState(null);
    const [selectedPredmet, setSelectedPredmet] = useState(null);
    const [selectedKontakt, setSelectedKontakt] = useState(null);
    const [tarifa, setTarifa] = useState(null);
    const [iznos, setIznos] = useState("0.00");
    const [firmaValuta, setFirmaValuta] = useState(null);
    const [tipNaplatnosti, setTipNaplatnosti] = useState(null);
    const [tipOporezivosti, setTipOporezivosti] = useState(null);
    const [dodaniPredmetiIDs, setDodaniPredmetiIDs] = useState([]);
    const [disabledPredmetIDs, setDisabledPredmetIDs] = useState([]);
    const [samoOtvoreniPredmetiCheckBox, setSamoOtvoreniPredmetiCheckBox] = useState(props.samoOtvoreniPredmeti);
    const samoOtvoreniInitial = props.samoOtvoreniPredmeti;
    const [samoAktivniKlijentiCheckBox, setSamoAktivniKlijentiCheckBox] = useState(props.samoAktivniKlijenti);
    const samoAktivniInitial = props.samoAktivniKlijenti;
    const [cjenikExists, setCjenikExists] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [formEvidencijaDetaljReferencaID, setFormEvidencijaDetaljReferencaID] = useState(0);
    const [tarifeHistory, setTarifeHistory] = useState(null);
    const [anchorAddEl, setAnchorAddEl] = useState(null);
    const historyOpened = Boolean(anchorAddEl);
    const [historyFilter, setHistoryFilter] = useState("");
    const [loadingTarifeHistory, setLoadingTarifeHistory] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [tariffToDelete, setTariffToDelete] = useState(null);
    const [selectedTariff, setSelectedTariff] = useState(null);
    const [datoteka, setDatoteka] = useState(null);
    const [datotekaNaziv, setDatotekaNaziv] = useState("");
    const [dokumentNaziv, setDokumentNaziv] = useState("");
    const [file, setFile] = useState([]);
    const [ekstenzija, setEkstenzija] = useState("");
    const [contentType, setContentType] = useState("");
    const [fileSize, setFileSize] = useState(0);
    const [kapacitetError, setKapacitetError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        props.setTitleVisible(false);
        let list = []
        dodaniPredmeti.forEach(p => {
            list.push(p.ReferencaID);
        });
        ;
        setDodaniPredmetiIDs(list);
        let disabledList = [];

        if (formEvidencijaDetalj) {
            predmeti.forEach(p => {
                var test = list.includes(p.PredmetID && !formEvidencijaDetalj.ReferencaID);
                if(test){
                    disabledList.push(p.PredmetID);
                }
            });
            setDisabledPredmetIDs(disabledList);
            setTarifa(formEvidencijaDetalj.Tarifa);
            setIznos(formEvidencijaDetalj.Iznos);
            setFormEvidencijaDetaljReferencaID(formEvidencijaDetalj.ReferencaID);
            if (formEvidencijaDetalj.Datoteka) {
                setDatotekaNaziv(formEvidencijaDetalj.Datoteka.DokumentNaziv);
                setDokumentNaziv(formEvidencijaDetalj.Datoteka.DokumentNaziv);
                setFile(formEvidencijaDetalj.Datoteka.File);
                setContentType(formEvidencijaDetalj.Datoteka.ContentType);
                setFileSize(formEvidencijaDetalj.Datoteka.FileSize);
            }
            loadFormEvidencijaDetaljData();
        } else {
            predmeti.forEach(p => {
                var test = list.includes(p.PredmetID);
                if(test){
                    disabledList.push(p.PredmetID);
                }
            });
            setDisabledPredmetIDs(disabledList);
            setFirmaValuta(props.defaultFirmaValuta);
            if (props.zadatakKlijentID) {
                setSelectedKlijent(klijenti.find(k => k.KlijentID == props.zadatakKlijentID));
            }
            setLoadingPredmeti(false);
            setLoadingKontakti(false);
            setLoading(false);
        }
    });

    //useEffect(() => alert(datotekaNaziv), [datotekaNaziv])

    const searchRef = useRef(null);

    function focusOnSearch() {
        setTimeout(() => {
            if (searchRef.current) {
                searchRef.current.focus();
            }
        }, 400);
    }


    function loadFormEvidencijaDetaljData() {
        let detalj = {
            KlijentID : formEvidencijaDetalj.KlijentID,
            ReferencaID : formEvidencijaDetalj.ReferencaID,
            KontaktID : formEvidencijaDetalj.KontaktID,
            TipNaplatnostiID : formEvidencijaDetalj.TipNaplatnostiID,
            TipOporezivostiID : formEvidencijaDetalj.TipOporezivostiID,
            FirmaValutaID : formEvidencijaDetalj.FirmaValutaID
        }
        api.post("evidencija/getevidencijadetaljdata", detalj, loadFormEvidencijaDetaljDataSuccess, loadFormEvidencijaDetaljDataFail);
    }

    function loadFormEvidencijaDetaljDataSuccess(data) {
        ;
        setSelectedKlijent(data.Klijent);
        setSelectedPredmet(data.Predmet);
        setSelectedKontakt(data.Kontakt);
        setTipNaplatnosti(data.TipNaplatnosti);
        setTipOporezivosti(data.TipOporezivosti);
        setFirmaValuta(data.FirmaValuta);
        setLoading(false);
    }

    function loadFormEvidencijaDetaljDataFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function loadTarifeHistory() {
        setLoadingTarifeHistory(true);
        api.get("evidencija/gettarifehistory/" + global.user.firmaID, loadTarifeHistorySuccess, loadTarifeHistoryFail);
    }

    function loadTarifeHistorySuccess(data) {
        setTarifeHistory(data);
        setLoadingTarifeHistory(false);
        setTariffToDelete(null);
    }

    function loadTarifeHistoryFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleHistoryClick(event) {
        if (tarifeHistory == null) {
            loadTarifeHistory();
        }
        setAnchorAddEl(event.currentTarget);
        focusOnSearch();
    };

    function handleHistoryClose(event) {
        setAnchorAddEl(null);
        setSelectedTariff(null);
    };

    function handleTarifaHistoryClick(t) {
        setTarifa(t.TarifniBroj);
        setIznos(Util.toStringDecimal(t.Iznos));
        setFirmaValuta(t.FirmaValuta);
        setAnchorAddEl(null);
    }

    function handleTarifaHistoryDelete(t){
        //alert(t.TarifniBroj);
        setTariffToDelete(t);
        setDeleteAlert(true);
    }

    useEffect(() => {
        if (!formEvidencijaDetalj) {
            setSelectedPredmet(null);
        }
        if (!formEvidencijaDetalj) {
            setSelectedKontakt(null);
        }
    }, [selectedKlijent]);

    useEffect(() => {
        if (selectedPredmet) {
            if (!formEvidencijaDetalj) {
                setTipNaplatnosti(tipNaplatnostiOptions.at(selectedPredmet.TipNaplatnostiID - 1));
                setTipOporezivosti(tipOporezivostiOptions.at(selectedPredmet.TipOporezivostiID - 1));
            }
            if (selectedPredmet.KlijentovVoditeljPredmetaKontaktID) {
                findKontakt();
            }
            checkIfCjenikDefined();
        }
    }, [selectedPredmet]);

    function checkIfCjenikDefined() {
        setLoading(true);
        api.get("predmet/unosaktivnostipredmetdetails?predmetID=" + selectedPredmet.PredmetID + "&korisnikID=" + global.drugiZaposlenik.KorisnikID, checkIfCjenikDefinedSuccess, checkIfCjenikDefinedFail);
    }

    function checkIfCjenikDefinedSuccess(data) {
        setCjenikExists(data.CjenikExists);
        findKontakt(data.KontaktID);
        setLoading(false);
    }

    function checkIfCjenikDefinedFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    //useEffect(() => findKontakt(), [kontakti, selectedPredmet]);

    function findKontakt(id) {
        if (kontakti) { 
            if (!formEvidencijaDetalj && id) {
                setSelectedKontakt(kontakti.find(k => k.KlijentovVoditeljPredmetaKontaktID == id));
            } else if (id == -1) {
                setSelectedKontakt(null);
            } else if (!formEvidencijaDetalj && selectedPredmet && selectedPredmet.KlijentovVoditeljPredmetaKontaktID) {
                setSelectedKontakt(null);
                kontakti.forEach(kontakt => {
                    if (kontakt.KlijentovVoditeljPredmetaKontaktID == selectedPredmet.KlijentovVoditeljPredmetaKontaktID) {
                        setSelectedKontakt(kontakt);
                    }
                });
            }
        }
    }

    function onKlijentChange(data, newValue) {
        setSelectedKlijent(newValue);
        setSelectedPredmet(null);
    }

    function onPredmetChange(data, newValue) {
        unsavedChanges.current = true;
        if(newValue){
            setSelectedPredmet(newValue);
            setTipOporezivosti(tipOporezivostiOptions.find(to => to.TipOporezivostiID == newValue.TipOporezivostiID));    
        }
        else{
            setSelectedPredmet(null);
            setTipOporezivosti(null);    
        }
    }

    function onKontaktChange(e,v) {
        unsavedChanges.current = true;
        setSelectedKontakt(v);
    }

    function onTarifaChange(e) {
        unsavedChanges.current = true;
        setTarifa(e.target.value);
    }

    function onIznosChange(e) {
        unsavedChanges.current = true;
        setIznos(e.target.value);
    }

    function handleIznosFocusOut() {
        // if (!isNaN(parseFloat(iznos)) && parseFloat(iznos) > 0) {
        //     setIznos(parseFloat(iznos));
        // } else {
        //     setIznos(0);
        // }

        if (iznos == "" || iznos.charAt(0) == '-') {
            setIznos("0.00");
        } else if (!isNaN(parseFloat(iznos))) {
            let value = iznos;
            let i = 0;
            while (iznos.length > i + 1 && iznos.charAt(i) == '0' && iznos.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            ;
            value = Util.toStringDecimal(value);
            setIznos(value);
        }

    }



    function onValutaChange(e,v) {
        unsavedChanges.current = true;
        setFirmaValuta(v);
    }

    function onTipNaplatnostiChange(e,v) {
        unsavedChanges.current = true;
        setTipNaplatnosti(v);
    }

    function onTipOporezivostiChange(e,v) {
        setTipOporezivosti(v);
    }

    function onSelectedTariffChange(e,v){
        setSelectedTariff(v);
    }

    const handleKeyPressShorcuts = useCallback((event) => {
        event.stopPropagation();   
        if (event.altKey) {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if (charCode == 'd') {
                event.preventDefault();
                if (!loading && (kapacitetError == null || kapacitetError == "") && selectedKlijent && selectedPredmet && (selectedPredmet.TipNaplateID != 2 || defaultTarifnaAktivnostOmoguciIznosNula || parseFloat(iznos) > 0 && tarifa != "" && tarifa != null)) {
                    savePredmet();
                }
            }
            if (charCode == 'o') {
                event.preventDefault();
                if (!loading) {
                    setDodavanjePredmeta(false); 
                    props.setTitleVisible(true);
                }
            }    
        }
    }, [
        selectedKlijent, 
        selectedPredmet, 
        loading, 
        defaultTarifnaAktivnostOmoguciIznosNula, 
        iznos, 
        tarifa,
        formEvidencijaDetalj,
        selectedKontakt,
        tipNaplatnosti,
        tipOporezivosti,
        firmaValuta,
        datotekaNaziv,
        file,
        dokumentNaziv,
        fileSize,
        contentType,
        dodaniPredmeti
    ]);

    const handleEsc = useCallback((event) => {
        event.stopPropagation();        
        if(event.keyCode == 27)
        {
            event.preventDefault();
            if (!loading) {
                setDodavanjePredmeta(false); 
                props.setTitleVisible(true);
            }
        }
    }, [loading]);
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressShorcuts);
        document.addEventListener('keyup', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleKeyPressShorcuts);
            document.removeEventListener('keyup', handleEsc);
        };
    }, [handleKeyPressShorcuts]);

    function savePredmet() {
        let newPredmet = {
            Naziv : selectedKlijent.Naziv + " - " + selectedPredmet.Naziv + (selectedKontakt ? (" - (" + selectedKontakt.Naziv + ")") : "") + (selectedPredmet.TipNaplateID == 2 ? (" - tbr. " + tarifa + " - Iznos: " + iznos + " " + firmaValuta.FirmaValutaNaziv) : ""),
            DetaljID : formEvidencijaDetalj ? formEvidencijaDetalj.DetaljID : -1,
            KlijentID : selectedKlijent.KlijentID,
            ReferencaID : selectedPredmet.PredmetID,
            KontaktID : selectedKontakt ? selectedKontakt.KlijentovVoditeljPredmetaKontaktID : null,
            TipNaplatnostiID : tipNaplatnosti ? tipNaplatnosti.TipNaplatnostiID : 0,
            TipOporezivostiID : tipOporezivosti ? tipOporezivosti.TipOporezivostiID : 0,
            Tarifa : tarifa,
            Iznos : iznos,
            FirmaValutaID : firmaValuta ? firmaValuta.FirmaValutaID : null,
            Edited : true,
            PredmetTipStanjaPredmetaID: selectedPredmet.TipStanjaPredmetaID,
            PredmetTipNaplateID: selectedPredmet.TipNaplateID,
            Datoteka: datotekaNaziv ? 
                {
                    ArhivaFileID: formEvidencijaDetalj && formEvidencijaDetalj.Datoteka ? formEvidencijaDetalj.Datoteka.ArhivaFileID : -1,
                    ArhivaID: formEvidencijaDetalj && formEvidencijaDetalj.Datoteka ? formEvidencijaDetalj.Datoteka.ArhivaID : -1,
                    TipDokumentaID: 3,
                    TipDokumentaNaziv: "Ostalo",
                    DokumentNaziv: dokumentNaziv,
                    Opis: props.opis,
                    File: file,
                    Ekstenzija: dokumentNaziv.substring(dokumentNaziv.lastIndexOf("."), dokumentNaziv.length),
                    FileSize: fileSize,
                    ContentType: contentType,
                }
                : null
        }
        let newDodaniPredmeti = dodaniPredmeti.filter(p => p.ReferencaID != formEvidencijaDetaljReferencaID);
        newDodaniPredmeti.push(newPredmet);
        setDodaniPredmeti(newDodaniPredmeti);
        props.setTitleVisible(true);
        setDodavanjePredmeta(false);
        props.focusOnOpis();
    }

    function formIsValid() {
        let checkValid = selectedKlijent && selectedPredmet;
        if (selectedPredmet && selectedPredmet.TipNaplateID == 2) {
            checkValid = checkValid && tarifa != null && tarifa != "" && iznos != null && !isNaN(parseFloat(iznos)) && (parseFloat(iznos) > 0 || (parseFloat(iznos) == 0 && defaultTarifnaAktivnostOmoguciIznosNula));
        }
        setFormValid(checkValid);
    }

    useEffect(() => formIsValid(), [selectedKlijent, selectedPredmet, iznos, tarifa]);

    useEffect(() => {
        if (samoOtvoreniInitial != samoOtvoreniPredmetiCheckBox) {
            setSamoOtvoreniPredmeti(samoOtvoreniPredmetiCheckBox);
            //api.get("korisnik/spremidefsamootvorenipredmeti/" + samoOtvoreniPredmeti, setSamoOtvoreniSuccess)
        }
    }, [samoOtvoreniPredmetiCheckBox, samoOtvoreniInitial]);

    useEffect(() => {
        if (samoAktivniInitial != samoAktivniKlijentiCheckBox) {
            setSamoAktivniKlijenti(samoAktivniKlijentiCheckBox);
            //api.get("korisnik/spremidefsamootvorenipredmeti/" + samoOtvoreniPredmeti, setSamoOtvoreniSuccess)
        }
    }, [samoAktivniKlijentiCheckBox, samoAktivniInitial]);


    useEffect(() => {
        if(samoAktivniKlijentiCheckBox && selectedKlijent && selectedKlijent.TipStatusaKlijentaID != 1){
            setSelectedKlijent(null);
        } 
    }, [selectedKlijent, samoAktivniKlijentiCheckBox]);

    useEffect(() => {
        if(samoOtvoreniPredmetiCheckBox && selectedPredmet && selectedPredmet.TipStanjaPredmetaID != 1){
            setSelectedPredmet(null);
        } 
    }, [selectedPredmet, samoOtvoreniPredmetiCheckBox]);

    function deleteHistoryItem(){
        setDeleteAlert(false);
        let resource = {
            TarifniBroj : tariffToDelete.TarifniBroj,
            Iznos: tariffToDelete.Iznos,
            FirmaValuta: tariffToDelete.FirmaValuta
        }
        api.post("evidencija/deleteevidencijadetaljhistory", resource, deleteEvidencijaDetaljHistorySuccess, deleteEvidencijaDetaljHistoryFail);
    }

    function deleteEvidencijaDetaljHistorySuccess(){
        //osvježiti listu
        loadTarifeHistory();
    }

    function deleteEvidencijaDetaljHistoryFail(data){
        setTariffToDelete(null);
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleDatotekaChange(e) {
        let files = Array.from(e.target.files);
        setDatoteka(files.at(0));
        setDatotekaNaziv(files.at(0).name);
        loadDatoteka(files.at(0))
    }

    function loadDatoteka(d) {
        setDokumentNaziv(d.name);

        const reader = new FileReader();
        const fileByteArray = [];

        reader.readAsArrayBuffer(d);

        reader.onloadend = (evt) => {
            if (evt.target.readyState === FileReader.DONE) {
                const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                for (const a of array) {
                    fileByteArray.push(a);
                }

                setFile(fileByteArray);
            }
        };
        
        setFileSize(d.size);
        setContentType(d.type);

        let sizeMiB = d.size / (1024 * 1024);
        if (sizeMiB > elektronickaArhivaMiBPerFile) {
            setKapacitetError("Veličina datoteke (" + Util.toStringDecimal(sizeMiB) + " MB) veća je od maksimalne dopuštene veličine datoteke (" + elektronickaArhivaMiBPerFile + " MB).");
        } else if (sizeMiB > elektronickaArhivaDostupnoMiB) {
            setKapacitetError("Veličina datoteke (" + Util.toStringDecimal(sizeMiB) + " MB) veća je od preostalog dostupnog prostora elektroničke arhive (" + elektronickaArhivaDostupnoMiB + " MB).");
        } else {
            setKapacitetError("");
        }

    }

    function preuzmi(fileID, fileName) {
        // fetch("http://localhost:56895/api/arhivaFile/download/" + fileID, {headers: auth_Header.authHeader()})
        // .then((res) => { return res.blob(); })
        // .then((data) => {
        //     var url = window.URL.createObjectURL(data);
        //     var a = document.createElement('a');
        //     a.href = url;
        //     a.download = fileName;
        //     document.body.appendChild(a);
        //     a.click();
        //     a.remove();
        // });
        //;
        setLoading(true);
        var req = new XMLHttpRequest();
        let auth = auth_Header.authHeader();
        req.open("GET", api.api + "/arhivaFile/download/" + fileID, true);
        req.setRequestHeader("Content-Type", "application/json");
        req.setRequestHeader("Authorization", auth.Authorization);
        req.responseType = "blob";

        req.onload = function (event) {
            var blob = req.response;
            //var fn = req.getResponseHeader("Content-Disposition"); //if you have the fileName header available
            var link = document.createElement("a");
            var url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = fileName;
            link.click();
            setLoading(false);
            //if (success) success({ Message: "Datoteka: " + fileName + " je uspješno preuzeta.", Status: 200, ResponseData: null });
            // } else {
            //     //if (fail) fail({ Message: "Greška pri generiranju ili preuzimanju datoteke.", Status: 505, ResponseData: blob });
            // }
        };

        // req.onreadystatechange = function() {
        //     if (this.readyState == 4 && this.status == 200) {
        //     //console.log(this.responseText);
        //     }
        // }

        req.send();
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container className="padding-grid">
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{ textAlign: "center", marginBottom: "15px" }}>
                        <div
                            style={{
                                display: "inline-flex",
                                verticalAlign: "text-bottom",
                                boxSizing: "inherit",
                                textAlign: "center",
                                alignItems: "center",
                            }}
                        >

                            {formEvidencijaDetalj ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <MoreTimeIcon fontSize="medium" style={{marginRight:"15px"}}></MoreTimeIcon>
                            )}
                            {formEvidencijaDetalj ? (
                                "Uređivanje evidencije aktivnosti za predmet"
                            ) : (
                                "Dodavanje evidencije aktivnosti za predmet" 
                            )}

                            {/* <AddIcon fontSize="medium" style={{ marginRight: "10px" }}></AddIcon>
                            Dodavanje evidencije aktivnosti za predmet */}
                        </div>
                    </Typography>
                </Grid>
                {props.zadatakMessage ? (
                    <Grid item xs={12}>
                        <Alert severity="info">{props.zadatakMessage}</Alert>
                    </Grid>
                ) : null}
                <Grid item xs={12} sm={12} md={9}>
                    <FormControl sx={{ width: { xs: "100%", sm: "100%", md: "95%" } }}>
                        <Autocomplete
                            size="small"
                            disablePortal
                            //disableClearable
                            options={samoAktivniKlijentiCheckBox ? klijenti.filter((k) => k.TipStatusaKlijentaID == 1) : klijenti}
                            autoHighlight
                            onChange={onKlijentChange}
                            value={selectedKlijent}
                            ListboxProps={{ style: { maxHeight: "10rem" } }}
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={"klijent" + option.KlijentID} style={{ color: option.TipStatusaKlijentaID == 1 ? "black" : "red" }}>
                                        {option.Broj + " - " + option.Naziv}
                                    </li>
                                );
                            }}
                            filterOptions={(options, state) => options.filter((option) => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            renderInput={(params) => (
                                <TextField
                                    autoFocus
                                    error={!selectedKlijent}
                                    {...params}
                                    label="Klijent"
                                    sx={{ input: { color: selectedKlijent && selectedKlijent.TipStatusaKlijentaID != 1 ? "red" : "black" } }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={3} display="flex" justifyContent="flex-end">
                    <FormControlLabel
                        tabIndex={-1}
                        labelPlacement="start"
                        label={<span style={{ fontSize: "0.8em" }}>Samo aktivni klijenti</span>}
                        control={<Checkbox tabIndex={-1} checked={samoAktivniKlijentiCheckBox} onChange={(e) => setSamoAktivniKlijentiCheckBox(e.target.checked)}></Checkbox>}
                    ></FormControlLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={9}>
                    <FormControl sx={{ width: { xs: "100%", sm: "100%", md: "95%" } }}>
                        <Autocomplete
                            size="small"
                            disablePortal
                            options={
                                selectedKlijent
                                    ? samoOtvoreniPredmetiCheckBox
                                        ? predmeti.filter((p) => p.KlijentID == selectedKlijent.KlijentID && p.TipStanjaPredmetaID == 1)
                                        : predmeti.filter((p) => p.KlijentID == selectedKlijent.KlijentID)
                                    : []
                            }
                            noOptionsText={
                                !selectedKlijent
                                    ? "Za prikaz predmeta odaberite klijenta."
                                    : "Za odabranog klijenta ne postoji niti jedan " + (samoOtvoreniPredmetiCheckBox ? "otvoreni " : "") + "predmet."
                            }
                            onChange={onPredmetChange}
                            value={selectedPredmet}
                            ListboxProps={{ style: { maxHeight: "10rem" } }}
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            //getOptionDisabled={(option) => dodaniPredmetiIDs.includes(option.PredmetID)}
                            getOptionDisabled={(option) => disabledPredmetIDs.includes(option.PredmetID)}
                            autoHighlight
                            renderOption={(props, option) => {
                                return (
                                    <li
                                        {...props}
                                        key={"predmet" + option.PredmetID}
                                        style={{ color: option.TipStanjaPredmetaID == 1 ? (option.TipNaplateID == 3 ? "green" : option.TipNaplateID == 2 ? "magenta" : "black") : "red" }}
                                    >
                                        {option.Broj + " - " + option.Naziv}
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    label="Predmet"
                                    sx={{
                                        input: {
                                            color:
                                                selectedPredmet && selectedPredmet.TipStanjaPredmetaID != 1
                                                    ? "red"
                                                    : selectedPredmet && selectedPredmet.TipNaplateID == 3
                                                    ? "green"
                                                    : selectedPredmet && selectedPredmet.TipNaplateID == 2
                                                    ? "magenta"
                                                    : "black",
                                        },
                                    }}
                                    error={!selectedPredmet}
                                />
                            )}
                            filterOptions={(options, state) => options.filter((option) => (option.Broj + " " + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={3} display="flex" justifyContent="flex-end">
                    <FormControlLabel
                        tabIndex={-1}
                        labelPlacement="start"
                        label={<span style={{ fontSize: "0.8em" }}>Samo otvoreni predmeti</span>}
                        control={<Checkbox tabIndex={-1} checked={samoOtvoreniPredmetiCheckBox} onChange={(e) => setSamoOtvoreniPredmetiCheckBox(e.target.checked)}></Checkbox>}
                    ></FormControlLabel>
                </Grid>
                {selectedPredmet && selectedPredmet.TipNaplateID == 1 && !mogucePrebacitiAktivnostiBezCijene && !cjenikExists && !loading ? (
                    <Grow in={selectedPredmet && selectedPredmet.TipNaplateID == 1 && !mogucePrebacitiAktivnostiBezCijene && !cjenikExists}>
                        <Grid item xs={12}>
                            <Alert severity="error">
                                Za Vašu razinu zaposlenika nije definirana cijena rada. Aktivnost se može evidentirati, ali je neće biti moguće prebaciti u račun prije definicije cijene rada.
                            </Alert>
                        </Grid>
                    </Grow>
                ) : null}
                {selectedPredmet && selectedPredmet.TipNaplateID == 3 && selectedPredmet.Iznos == null && !loading ? (
                    <Grow in={selectedPredmet && selectedPredmet.TipNaplateID == 3 && selectedPredmet.Iznos == null}>
                        <Grid item xs={12}>
                            <Alert severity="error">
                                Za odabrani predmet s fiksnom naplatom nije definiran iznos u definiciji predmeta. Aktivnost se može evidentirati, ali je neće biti moguće prebaciti u račun prije definicije fiksnog iznosa naplate.
                            </Alert>
                        </Grid>
                    </Grow>
                ) : null}                
                <Grid item xs={12}>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete
                            size="small"
                            disablePortal
                            autoHighlight
                            options={selectedKlijent ? kontakti.filter((k) => k.KlijentID == selectedKlijent.KlijentID) : []}
                            onChange={onKontaktChange}
                            getOptionLabel={(option) => option.Naziv}
                            noOptionsText={selectedKlijent ? "Za odabranog klijenta ne postoji niti jedan kontakt." : "Za prikaz kontakata odaberite klijenta."}
                            value={selectedKontakt}
                            ListboxProps={{ style: { maxHeight: "10rem" } }}
                            renderInput={(params) => <TextField size="small" {...params} inputProps={{ ...params.inputProps, tabIndex: -1 }} label="Kontakt osoba" />}
                        />
                    </FormControl>
                </Grid>

                {selectedPredmet && selectedPredmet.TipNaplateID == 2 ? (
                    <Grid item xs={11}>
                        <FormControl style={{ width: "100%" }}>
                            <TextField size="small" label="Tarifni broj (tarifa)" inputProps={{ maxLength: 500 }} value={tarifa || ""} error={!tarifa} onChange={onTarifaChange} />
                        </FormControl>
                    </Grid>
                ) : null}

                {selectedPredmet && selectedPredmet.TipNaplateID == 2 ? (
                    <Tooltip title="Lista prethodno korištenih tarifa" arrow followCursor>
                        <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                            <IconButton onClick={handleHistoryClick}>
                                <HistoryIcon></HistoryIcon>
                            </IconButton>
                        </Grid>
                    </Tooltip>
                ) : null}

                {selectedPredmet && selectedPredmet.TipNaplateID == 2 ? (
                    <Grid item xs={6}>
                        <FormControl style={{ width: "100%" }}>
                            <TextField
                                type="number"
                                size="small"
                                label="Iznos"
                                value={iznos || ""}
                                error={iznos == null || isNaN(parseFloat(iznos)) || parseFloat(iznos) < 0 || (selectedPredmet && selectedPredmet.TipNaplateID == 2 && parseFloat(iznos) == 0 && !defaultTarifnaAktivnostOmoguciIznosNula)}
                                onChange={onIznosChange}
                                onBlur={handleIznosFocusOut}
                                required
                            />
                        </FormControl>
                    </Grid>
                ) : null}

                {selectedPredmet && selectedPredmet.TipNaplateID == 2 ? (
                    <Grid item xs={6}>
                        <FormControl style={{ width: "100%" }}>
                            <Autocomplete
                                size="small"
                                disablePortal
                                autoHighlight
                                options={firmaValute}
                                onChange={onValutaChange}
                                disabled={selectedPredmet && selectedPredmet.Cap}
                                getOptionLabel={(option) => option.FirmaValutaNaziv}
                                ListboxProps={{ style: { maxHeight: "10rem" } }}
                                value={firmaValuta}
                                renderInput={(params) => <TextField size="small" {...params} label="Valuta" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}

                <Grid item xs={12} sm={12} md={6}>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete
                            size="small"
                            disablePortal
                            autoHighlight
                            options={tipNaplatnostiOptions}
                            onChange={onTipNaplatnostiChange}
                            getOptionLabel={(option) => option.Naziv}
                            value={tipNaplatnosti}
                            ListboxProps={{ style: { maxHeight: "10rem" } }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props}>
                                        <Brightness1Icon sx={{ color: "rgb(" + (option.TipNaplatnostiID == 1 ? tipNaplatnostiNaplatnoBoja : tipNaplatnostiNenaplatnoBoja) + ")" }}></Brightness1Icon>
                                        <span>&nbsp;&nbsp;{option.Naziv}</span>
                                    </li>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    {...params}
                                    label="Tip naplatnosti"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: tipNaplatnosti ? (
                                            <InputAdornment position="end">
                                                <Brightness1Icon
                                                    sx={{ color: "rgb(" + (tipNaplatnosti.TipNaplatnostiID == 1 ? tipNaplatnostiNaplatnoBoja : tipNaplatnostiNenaplatnoBoja) + ")" }}
                                                ></Brightness1Icon>
                                            </InputAdornment>
                                        ) : null,
                                    }}
                                    inputProps={{ ...params.inputProps, tabIndex: -1 }}
                                />
                            )}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete
                            size="small"
                            disablePortal
                            options={tipOporezivostiOptions}
                            onChange={onTipOporezivostiChange}
                            getOptionLabel={(option) => option.TipPorezaNaziv + " (" + option.Stopa * 100 + " %)"}
                            value={tipOporezivosti}
                            disabled
                            autoHighlight
                            ListboxProps={{ style: { maxHeight: "10rem" } }}
                            renderInput={(params) => <TextField size="small" {...params} inputProps={{ ...params.inputProps, tabIndex: -1 }} label="Tip oporezivosti" />}
                        />
                    </FormControl>
                </Grid>
                {datoteka == null && datotekaNaziv == "" ? (
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center">
                            <Button tabIndex="-1" variant="outlined" component="label" startIcon={<UploadFileIcon />} style={{ marginTop: "5px", marginBottom: "5px" }}>
                                Prenesi datoteku
                                <input type="file" hidden onChange={handleDatotekaChange}></input>
                            </Button>
                        </Box>
                    </Grid>
                ) : null}

                {datoteka || datotekaNaziv != "" ? (
                    <Grid item xs={12} style={{marginTop:"2px"}}>
                        <FormLabel style={{ marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 2, width: "10em", backgroundColor: "white", position: "absolute", fontSize: "0.75em" }}>Priložena datoteka</FormLabel>
                        <Grid container sx={{ borderRadius: 1, border: 1, borderColor: "grey.400", "&:hover": { borderColor: "black" } }}>
                            <Grid item xs={6}>
                                <Typography variant="button" gutterBottom component="div">
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "6px",
                                        }}
                                    >
                                        <AttachFileIcon fontSize="small"></AttachFileIcon>
                                        {datotekaNaziv}
                                    </div>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} display="flex" justifyContent="space-evenly">
                                <Button
                                    startIcon={<DeleteForeverIcon></DeleteForeverIcon>}
                                    color="error"
                                    tabIndex="-1"
                                    onClick={() => {
                                        unsavedChanges.current = true;
                                        setDatoteka(null);
                                        setDatotekaNaziv("");
                                        setKapacitetError("");
                                    }}
                                >
                                    Obriši datoteku
                                </Button>
                                <Button
                                    startIcon={<DownloadIcon></DownloadIcon>}
                                    tabIndex="-1"
                                    onClick={() => {
                                        if (datoteka != null) {
                                            var link = document.createElement("a");
                                            var url = window.URL.createObjectURL(datoteka);
                                            link.href = url;
                                            link.download = datotekaNaziv;
                                            link.click();
                                        } else if (formEvidencijaDetalj && formEvidencijaDetalj.Datoteka) {
                                            preuzmi(formEvidencijaDetalj.Datoteka.ArhivaFileID, formEvidencijaDetalj.Datoteka.Naziv + formEvidencijaDetalj.Datoteka.DokumentNaziv);
                                        }
                                    }}
                                >
                                    Preuzmi
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}

                {kapacitetError != null && kapacitetError != "" ? (
                    <Grid item xs={12}>
                        <Alert severity="error">{kapacitetError}</Alert>
                    </Grid>
                ) : null}

                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px">
                        <Button onClick={savePredmet} variant="contained" style={{ marginRight: "10px" }} color="success" disabled={!formValid || (kapacitetError != null && kapacitetError != "")}>
                            {loading ? (
                                <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{ marginRight: "10px" }} />
                            ) : formEvidencijaDetalj ? (
                                <DoneOutlineIcon style={{ marginRight: "10px" }}></DoneOutlineIcon>
                            ) : (
                                <AddIcon style={{ marginRight: "10px" }} />
                            )}{" "}
                            {formEvidencijaDetalj ? "Prihvati" : <span>D&#818;odaj</span>}
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                setDodavanjePredmeta(false);
                                props.setTitleVisible(true);
                                if (props.zadatakMessage) {
                                    props.closeEvidencija();
                                }
                            }}
                            disabled={loading}
                        >
                            <ExitToApp style={{ marginRight: "10px" }}></ExitToApp>O&#818;dustani
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {/* <Menu anchorEl={anchorAddEl} open={historyOpened} onClose={handleHistoryClose} PaperProps={{ style: { maxWidth: "50vw" } }}>
                <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                    <FormControl fullWidth>
                        <TextField
                            inputRef={searchRef}
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                            }}
                            value={historyFilter}
                            onChange={(e) => setHistoryFilter(e.target.value)}
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon></SearchIcon>
                                    </InputAdornment>
                                ),
                            }}
                            onKeyDown={(e) => e.stopPropagation()}
                        ></TextField>
                    </FormControl>
                </MenuItem>
                <Divider></Divider>
                
                <Box style={{ maxHeight: "7em", minHeight: "5em", overflow: "auto" }} tabIndex={-1}>
                    {tarifeHistory ? (
                        tarifeHistory
                            .filter((th) => (historyFilter ? th.TarifniBroj.toLowerCase().includes(historyFilter.toLowerCase()) : true))
                            .map((t, i) => {
                                return (
                                    <MenuItem style={{ paddingTop: "3px", paddingBottom: "3px" }} tabIndex={i} onKeyDown={(e) => e.stopPropagation()}>
                                        <div style={{ display: "inline-block", width: "100%" }}>
                                            <Tooltip title="Ukloni stavku">
                                                <div style={{ float: "right", color: "#d32f2f", height: "24px" }} onClick={() => handleTarifaHistoryDelete(t)}>
                                                    <span style={{ textAlign: "right" }}>{<HighlightOffOutlinedIcon></HighlightOffOutlinedIcon>}</span>
                                                </div>
                                            </Tooltip>

                                            <div
                                                style={{ overflow: "hidden", textOverflow: "ellipsis", float: "left", width: "80%", fontSize: "0.7em", paddingTop: "0.4em" }}
                                                onClick={() => handleTarifaHistoryClick(t)}
                                            >
                                                {t.TarifniBroj + " (" + Util.toStringDecimal(t.Iznos) + " " + (t.FirmaValuta ? t.FirmaValuta.FirmaValutaNaziv : "") + ")"}
                                            </div>
                                        </div>
                                    </MenuItem>
                                );
                            })
                    ) : (
                        <CircularProgress></CircularProgress>
                    )}
                </Box>
                
            </Menu> */}

            <Menu anchorEl={anchorAddEl} open={historyOpened} onClose={handleHistoryClose} PaperProps={{ style: { maxWidth: "50vw", minWidth: "20vw", minHeight:"14em" } }}>
                {tarifeHistory ? (
                    <div onKeyDown={(e) => e.stopPropagation()}>
                        <MenuItem onKeyDown={(e) => e.stopPropagation()}>

                            <FormControl style={{ width: "100%" }}>
                                <Autocomplete
                                    size="small"
                                    disablePortal

                                    options={tarifeHistory}
                                    getOptionLabel={(option) => option.TarifniBroj + " (" + Util.toStringDecimal(option.Iznos) + " " + (option.FirmaValuta ? option.FirmaValuta.FirmaValutaNaziv : "") + ")"}
                                    renderOption={(props, option) => {
                                        return (
                                            <li style={{fontSize: "0.7em"}} {...props} key={"tbr" + option.TarifniBroj + option.Iznos + (option.FirmaValuta ? option.FirmaValuta.ValutaID : "")}>
                                                {option.TarifniBroj + " (" + Util.toStringDecimal(option.Iznos) + " " + (option.FirmaValuta ? option.FirmaValuta.FirmaValutaNaziv : "") + ")"}
                                            </li>
                                        );
                                    }}
                                    
                                    onChange={onSelectedTariffChange}

                                    value={selectedTariff}
                                    autoHighlight
                                    ListboxProps={{ style: { maxHeight: "8.5em" } }}
                                    renderInput={(params) => 
                                        <TextField
                                            {...params}
                                            size="small"  
                                            inputProps={{ ...params.inputProps }} 
                                            label="Tarife" 
                                            inputRef={searchRef}
                                        />}
                                    filterOptions={(options, state) => options.filter((option) => option.TarifniBroj.toLowerCase().includes(state.inputValue.toLowerCase()))}
                                />
                            </FormControl>

                        </MenuItem>
                        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                            {/* <div style={{textAlign:"center"}}><Button variant="outlined" color="success" startIcon={<SearchIcon></SearchIcon>} onClick={() => handleTarifaHistoryClick(selectedTariff)}>Filtriraj</Button></div> */}

                            <div style={{ display: "inline-block", width: "100%" }}>
                                <div style={{ float: "right"}} tabIndex={-1}>
                                    <Button style={{width:"100%"}} disabled={!selectedTariff} tabIndex={-1} variant="outlined" color="error" startIcon={<DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>} onClick={() => handleTarifaHistoryDelete(selectedTariff)}>Ukloni</Button>
                                </div>

                                <div
                                    style={{ overflow: "hidden", textOverflow: "ellipsis", float: "left", width: "50%" }}
                                >
                                    <Button variant="outlined" disabled={!selectedTariff} color="success" startIcon={<ArrowBackOutlinedIcon></ArrowBackOutlinedIcon>} onClick={() => handleTarifaHistoryClick(selectedTariff)}>Preuzmi</Button>
                                </div>
                            </div>

                        </MenuItem>
                    </div>
                ) : (
                    <CircularProgress></CircularProgress>
                )}
            </Menu>

            {deleteAlert ? (
                <AlertDialog
                    title={"Brisanje povijesnog podatka"}
                    message={"Odabrali ste obrisati povijesni podatak. Evidencija s ovom tarifom će ostati, ali se više neće prikazivati u povijesnoj listi. Da li želite nastaviti?"}
                    cancelLabel={"Otkaži brisanje"}
                    confirmLabel={"Nastavi"}
                    cancelAction={() => setDeleteAlert(false)}
                    confirmAction={() => deleteHistoryItem()}
                ></AlertDialog>
            ) : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default UnosAktivnostiPredmetiForm;