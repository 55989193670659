import { Autocomplete, Button, Divider, FormControl, Grid, InputAdornment, List, ListItem, ListItemText, Paper, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import ComponentContainer from "./componentContainer";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from "@mui/system";
import api from "../util/api";
import Util from "../util/util";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const prikazi = [
    { Naziv: "Firme", Vrijednost: 0 },
    { Naziv: "Kanali prodaje", Vrijednost: 1 }
];

export default function Licence(props) {

    const useConstructor = Util.useConstructor();

    const [loading, setLoading] = useState(true);
    const [prikaz, setPrikaz] = useState(0);
    const [search, setSearch] = useState("");
    const [firme, setFirme] = useState([]);
    const [kanaliProdaje, setKanaliProdaje] = useState([]);
    const [valute, setValute] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        loadInfo();
    });

    function loadInfo() {
        setLoading(true);
        api.get("postavke/licenceinfo", loadInfoSuccess, fail);
    }

    function loadInfoSuccess(data) {
        setFirme(data.Firme.sort((f1, f2) => f1.Naziv.localeCompare(f2.Naziv)));
        setKanaliProdaje(data.KanaliProdaje.sort((f1, f2) => f1.Naziv.localeCompare(f2.Naziv)));
        setValute(data.Valute);
        setLoading(false);
    }

    function fail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleCijenaChange(f, v) {
        if (prikaz == 0) {
            let newFirma = JSON.parse(JSON.stringify(f));
            let newFirme = JSON.parse(JSON.stringify(firme));
    
            newFirma.LicencaCijena = v;
    
            newFirme = newFirme.filter(frm => frm.FirmaID != f.FirmaID);
            newFirme.push(newFirma);
            
            newFirme.sort((f1, f2) => f1.Naziv.localeCompare(f2.Naziv));
            setFirme(newFirme);
        } else {
            let newKanalProdaje = JSON.parse(JSON.stringify(f));
            let newKanaliProdaje = JSON.parse(JSON.stringify(kanaliProdaje));
    
            newKanalProdaje.LicencaCijena = v;
    
            newKanaliProdaje = newKanaliProdaje.filter(k => k.KanalProdajeID != f.KanalProdajeID);
            newKanaliProdaje.push(newKanalProdaje);
            
            setKanaliProdaje(newKanaliProdaje);
        }
    }

    function handleValutaChange(f, v) {
        if (prikaz == 0) {
            let newFirma = JSON.parse(JSON.stringify(f));
            let newFirme = JSON.parse(JSON.stringify(firme));
    
            newFirma.LicencaValutaID = v.ValutaID;
    
            newFirme = newFirme.filter(frm => frm.FirmaID != f.FirmaID);
            newFirme.push(newFirma);
            
            newFirme.sort((f1, f2) => f1.Naziv.localeCompare(f2.Naziv));
            setFirme(newFirme);
        } else {
            let newKanalProdaje = JSON.parse(JSON.stringify(f));
            let newKanaliProdaje = JSON.parse(JSON.stringify(kanaliProdaje));
    
            newKanalProdaje.LicencaValutaID = v.ValutaID;
    
            newKanaliProdaje = newKanaliProdaje.filter(k => k.KanalProdajeID != f.KanalProdajeID);
            newKanaliProdaje.push(newKanalProdaje);
            
            setKanaliProdaje(newKanaliProdaje);
        }
    }

    function save() {
        setLoading(true);
        let resource = {
            Firme: firme,
            KanaliProdaje: kanaliProdaje,
            Valute: []
        };
        api.post("postavke/savelicence", resource, loadInfo, fail);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{marginBottom:"20px", display:"flex", alignItems:"center"}}>
                        <LocalOfferIcon fontSize="medium" style={{marginRight:"15px"}}></LocalOfferIcon>
                        Licence i kanali prodaje
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Tabs value={prikaz} variant="fullWidth" style={{width:"100%", marginBottom:"15px"}} centered onChange={(e,v) => {
                        setSearch("");
                        setPrikaz(v);
                    }}>
                        {prikazi.map((p) => {
                            return (
                                <Tab value={p.Vrijednost} style={{width:"100%"}} label={p.Naziv}></Tab>
                            );
                        })}
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField value={search} size="small" InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }} onChange={(e) => setSearch(e.target.value)} label="Pretraga"></TextField>
                    </FormControl>
                </Grid>
                {valute.length > 0 ? (
                    <Grid item xs={12}>
                        <Box style={{width:"100%"}}>
                            <ListItem className="list-title" key="firme-title" >
                                <Grid container>
                                    <Grid item xs={6}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Naziv"} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Cijena"} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Valuta"} />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                                <List dense>
                                    {(search ? (prikaz == 0 ? firme : kanaliProdaje).filter(f => f.Naziv.toLowerCase().includes(search.toLowerCase())) : (prikaz == 0 ? firme : kanaliProdaje)).map((firma, index) => {
                                        return <ListItem className="list-item" key={"firma" + index} style={{paddingTop: "5px"}} >
                                                <Grid container style={{display:"flex", alignItems:"center"}}>
                                                    <Grid item xs={6}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={firma.Naziv} />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <FormControl fullWidth>
                                                            <TextField value={firma.LicencaCijena} size="small" onChange={(e) => handleCijenaChange(firma, e.target.value)}></TextField>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={3} style={{display:"flex",justifyContent:"center"}}>
                                                        <FormControl style={{ paddingLeft:"15px", width:"98%" }}>
                                                            <Autocomplete
                                                                disablePortal
                                                                disableClearable
                                                                options={valute}
                                                                getOptionLabel={(option) => option.Kratica}
                                                                value={valute.find(v => v.ValutaID == firma.LicencaValutaID)}
                                                                hiddenLabel={true}
                                                                onChange={(e,v) => {
                                                                    handleValutaChange(firma, v);
                                                                }}                           
                                                                renderInput={(params) => <TextField {...params} label="" size="small"/>}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    {index != (search ? (prikaz == 0 ? firme : kanaliProdaje).filter(f => f.Naziv.toLowerCase().includes(search.toLowerCase())) : (prikaz == 0 ? firme : kanaliProdaje)).length - 1 ? (
                                                        <Grid item xs={12} marginTop="5px">
                                                            <Divider></Divider>
                                                        </Grid>
                                                    ) : null}
                                                </Grid>
                                            </ListItem>
                                    })}
                                </List>
                            </Paper>
                        </Box>
                    </Grid>
                ) : null}
                <Grid item xs={12} style={{textAlign:"right", marginTop:"10px"}}>
                    <Button style={{marginRight:"5px"}} onClick={loadInfo} startIcon={<CancelIcon></CancelIcon>} variant="outlined" color="error">Poništi</Button>
                    <Button variant="contained" onClick={save} startIcon={<SaveIcon></SaveIcon>} color="success">Spremi</Button>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
        </ComponentContainer>
    );
}