import { Alert, Button, ClickAwayListener, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddEditDelForm from "./addEditDelForm";
import ComponentContainer from "./componentContainer";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { Box } from "@mui/system";
import api from "../util/api";
import Util from "../util/util";
import KlijentovVoditeljPredmetaKontaktForm from "./klijentovVoditeljPredmetaKontaktForm";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

function Kontakti(props) {
    const klijent = props.klijent;
    const kontakti = props.kontakti;
    const load = props.load;

    const useConstructor = Util.useConstructor();
    const isMobileDevice = Util.isMobileDevice();

    const [currentKontakt, setCurrentKontakt] = useState(null);
    const [kontaktFormOpened, setKontaktFormOpened] = useState(false);
    const [formKontakt, setFormKontakt] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [currentForDelete, setCurrentForDelete] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        setLoading(false);
    });

    const openKontaktForm = type => {
        if (type == "add") {
            setFormKontakt(null);
        } else if (type == "edit") {
            setFormKontakt(currentKontakt);
        }
        setKontaktFormOpened(true);
    }

    useEffect(() => {
        if (currentKontakt != null) {
            setCurrentForDelete(currentKontakt);
        }
    }, [currentKontakt]);

    const deleteKontakt = () => {
        setDeleteAlert(true);
    }

    const deleteConfirmed = () => {
        setLoading(true)
        api.post("kli-vod-pred-kontakt-by-klijent-delete/" + currentForDelete.KlijentovVoditeljPredmetaKontaktID, null, deleteSuccess, deleteFail);
        setDeleteAlert(false);
    }

    const deleteCanceled = () => {
        setDeleteAlert(false);
    }

    const deleteSuccess = (data) => {
        if (data.Message) {
            setAlertMessage(data.Message);
        }
        setLoading(false);
        load();
    }

    const deleteFail = (data) => {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (        
        <ComponentContainer loading={loading}>
             <Typography variant="h5" gutterBottom component="div" style={{width:"100%"}}>
                <ContactPhoneIcon fontSize="small" style={{marginRight:"15px"}}></ContactPhoneIcon>
                Kontakt osobe
            </Typography>
            <ClickAwayListener onClickAway={() => setCurrentKontakt(null)}>
                <Box style={{width:"100%"}}>
                    <AddEditDelForm current={currentKontakt} setCurrent={setCurrentKontakt} loading={loading} dataType="kontakti" data={kontakti != null ? kontakti : []} add={() => openKontaktForm("add")} edit={() => openKontaktForm("edit")} del={deleteKontakt} ></AddEditDelForm>
                </Box>
            </ClickAwayListener>
          
            {kontaktFormOpened ? (
                <Modal className="modal" open={kontaktFormOpened}>
                    <Box sx={style} overflow="auto" style={isMobileDevice ? {padding:"10px", width:"90%"} : {}}>
                        <KlijentovVoditeljPredmetaKontaktForm setFormOpened={setKontaktFormOpened} load={load} formKontakt={formKontakt} klijentID={klijent.KlijentID} firmaID={klijent.FirmaID} />
                    </Box>
                </Modal>
            ) : null}

            {deleteAlert ? <AlertDialog title="Brisanje kontakt osobe" message={"Jeste li sigurni da želite obrisati odabranu kontakt osobu?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={deleteCanceled} confirmAction={deleteConfirmed}></AlertDialog> : null}
        
            {alertMessage ? (
                <Modal className="modal" open={alertMessage}>
                    <Box sx={style}>
                        <Alert severity="error">{alertMessage}</Alert>
                        <Box style={{width:"100%"}} display="flex" justifyContent="flex-end" marginTop="10px">
                                <Button variant="contained" onClick={() => setAlertMessage(null)}>OK</Button>
                        </Box>
                    </Box>
                </Modal>
            ) : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default Kontakti;