import { Autocomplete, Button, Checkbox, Divider, FormControl, Grid, InputAdornment, InputLabel, List, ListItem, ListItemText, MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box } from "@mui/system";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import api from "../util/api";
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
};

export default function Aktivnost(props) {

    const useConstructor = Util.useConstructor();

    const [loading, setLoading] = useState(true);
    const [aktivnostKorisnika, setAktivnostKorisnika] = useState(false);
    const [izvjestajMjesec, setIzvjestajMjesec] = useState(new Date());
    const [tipoviIzracunaAktivnosti, setTipoviIzracunaAktivnosti] = useState([]);
    const [firme, setFirme] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [search, setSearch] = useState("");

    useConstructor(() => {
        let newMjesec = new Date();
        newMjesec.setMonth(newMjesec.getMonth() - 1);
        setIzvjestajMjesec(newMjesec);
        
        loadInfo();
    });

    function loadInfo(data) {
        setLoading(true);

        api.get("korisnici/getaktivnostinfo", loadInfoSuccess, loadInfoFail);
    }

    function loadInfoSuccess(data) {
        data.Firme.sort((f1, f2) => f1.Naziv.localeCompare(f2.Naziv));
        setFirme(data.Firme);
        setTipoviIzracunaAktivnosti(data.TipoviIzracuna);
        setLoading(false);
    }

    function loadInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleTipChange(firma, value) {
        let newFirma = JSON.parse(JSON.stringify(firma));
        let newFirme = JSON.parse(JSON.stringify(firme));

        newFirma.TipIzracunaAktivnosti = value;
        newFirma.TipIzracunaAktivnostiID = value.TipIzracunaAktivnostiID;

        newFirme = newFirme.filter(f => f.FirmaID != firma.FirmaID);
        newFirme.push(newFirma);
        
        newFirme.sort((f1, f2) => f1.Naziv.localeCompare(f2.Naziv));
        setFirme(newFirme);
    }

    function handleUkljuciChange(firma, value) {
        let newFirma = JSON.parse(JSON.stringify(firma));
        let newFirme = JSON.parse(JSON.stringify(firme));

        newFirma.UkljuciUIzvjestajOAktivnosti = value;

        newFirme = newFirme.filter(f => f.FirmaID != firma.FirmaID);
        newFirme.push(newFirma);
        
        newFirme.sort((f1, f2) => f1.Naziv.localeCompare(f2.Naziv));
        setFirme(newFirme);
    }

    function save() {
        setLoading(true);

        api.post("firma/updateaktivnostparameters", firme, (data) => loadInfo(), (data) => {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        });
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{marginBottom:"10px", display:"flex", alignItems:"center"}}>
                        <AccountCircleIcon fontSize="medium" style={{marginRight:"15px"}}></AccountCircleIcon>
                        Aktivnost korisnika
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => setAktivnostKorisnika(true)}>Dohvati izvještaj o aktivnosti korisnika</Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider></Divider>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div">
                        Upravljanje firmama
                    </Typography>   
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Pretraži" InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>)
                        }} value={search} onChange={e => setSearch(e.target.value)} size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="firme-title" >
                            <Grid container>
                                <Grid item xs={5}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Naziv"} />
                                </Grid>
                                <Grid item xs={5}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Tip izračuna aktivnosti"} />
                                </Grid>
                                <Grid item xs={2}>
                                    <ListItemText style={{paddingLeft:"15px", textAlign:"center"}} primaryTypographyProps={{ noWrap:true }} primary={"Uključi u izvještaj"} />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {(search ? firme.filter(f => f.Naziv.toLowerCase().includes(search.toLowerCase())) : firme).map((firma, index) => {
                                    return <ListItem className="list-item" key={"firma" + index} style={{paddingTop: "5px"}} >
                                            <Grid container style={{display:"flex", alignItems:"center"}}>
                                                <Grid item xs={5}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={firma.Naziv} />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            disablePortal
                                                            disableClearable
                                                            options={tipoviIzracunaAktivnosti}
                                                            getOptionLabel={(option) => option.Naziv}
                                                            value={firma.TipIzracunaAktivnosti}
                                                            hiddenLabel={true}
                                                            onChange={(e,v) => {
                                                                handleTipChange(firma, v);
                                                            }}                           
                                                            renderInput={(params) => <TextField {...params} label="" size="small"/>}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2} style={{display:"flex",justifyContent:"center"}}>
                                                    <Checkbox checked={firma.UkljuciUIzvjestajOAktivnosti} onChange={e => handleUkljuciChange(firma, e.target.checked)}></Checkbox>
                                                </Grid>
                                                {index != (search ? firme.filter(f => f.Naziv.toLowerCase().includes(search.toLowerCase())) : firme).length - 1 ? (
                                                    <Grid item xs={12} marginTop="5px">
                                                        <Divider></Divider>
                                                    </Grid>
                                                ) : null}
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" startIcon={<SaveIcon></SaveIcon>} style={{marginRight:"10px"}} disabled={loading} color="success">Spremi</Button>
                        <Button variant="outlined" color="error" onClick={loadInfo} startIcon={<CancelIcon></CancelIcon>} disabled={loading}>Poništi</Button>
                    </Box>
                </Grid>
            </Grid>

            <Modal className="modal" open={aktivnostKorisnika}>
                <Box sx={style}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom component="div">
                                Mjesečni izvještaj o aktivnosti korisnika
                            </Typography>   
                        </Grid>
                        <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                <DatePicker
                                    views={['year', 'month']}
                                    label="Mjesec"
                                    value={izvjestajMjesec}
                                    inputFormat="MM.yyyy."
                                    onChange={(v) => setIzvjestajMjesec(v)}
                                    maxDate={new Date()}
                                    renderInput={(params) => <TextField {...params} style={{width:"100%"}} size="small" />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} style={{ display:"flex", justifyContent:"flex-end" }}>
                            <Button style={{ marginRight:"5px" }} startIcon={<PlayCircleIcon></PlayCircleIcon>} onClick={() => 
                                {
                                    setLoading(true);
                                    api.getReport(
                                        "reportsapi/aktivnostkorisnika",
                                        {
                                            Mjesec : izvjestajMjesec
                                        },
                                        ".pdf",
                                        "blank",
                                        () => setLoading(false),
                                        (data) => {
                                            setErrorMessage(data ? (data.Message || data.toString()) : "");
                                            setLoading(false);}
                                    );
                                }
                            } variant="contained">Dohvati izvještaj</Button>
                            <Button variant="outlined" startIcon={<CloseIcon></CloseIcon>} onClick={() => setAktivnostKorisnika(false)} color="error">Zatvori</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}