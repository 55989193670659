import React, { useState, useEffect, useContext } from "react";
import { Line } from "@ant-design/charts";
import { FieldTimeOutlined } from "@ant-design/icons";
import api from "../util/api";
import { Button, Row, Col } from "antd";
import Util from "../util/util";
import GlobalContext from "../components/globalContext";
import ComponentContainer from "../components/componentContainer";
import Box from "@mui/material/Box";
import { SettingsRemoteRounded, ThreeDRotation } from "@mui/icons-material";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import SettingsIcon from "@mui/icons-material/Settings";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import moment from "moment";
import GroupIcon from "@mui/icons-material/Group";
import { getRadioUtilityClass } from "@mui/material";
import { UserOutlined, SettingOutlined, EditOutlined, LoginOutlined, LogoutOutlined, BarChartOutlined, CalendarOutlined, KeyOutlined } from "@ant-design/icons";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import EditIcon from "@mui/icons-material/Edit";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import TimelineIcon from "@mui/icons-material/Timeline";
import database from "../util/database";
import KeyIcon from "@mui/icons-material/Key";
import DeleteIcon from "@mui/icons-material/Delete";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PeopleIcon from "@mui/icons-material/People";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import SearchIcon from "@mui/icons-material/Search";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';

// import {Draggable} from './Draggable';
// import {Droppable} from './Droppable';

function Home(props) {
    const useConstructor = Util.useConstructor();
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const roles = props.roles || [];
    const [buttons, setButtons] = useState([]);

    const [dragging, setDragging] = useState(false);
    const [offsetX, setOffsetX] = useState(0);
    const [offsetY, setOffsetY] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    let user = {};
    let isLoggedIn = user != null;
    //let roles = [];
    let cookie = Util.getCookie("roles");
    //if (cookie) roles = cookie.split(",");

    let isMobileDevice = Util.isMobileDevice();

    useConstructor(() => {
        api.get("korisnici/gethomebuttons/" + global.user.korisnikID, getButtonsSuccess, fail);
    });

    function getButtonsSuccess(data) {
        if (data != null) {
            let btns = [];
            data.forEach(b => {
                btns.push({
                    type: b.Type,
                    x: b.PositionX,
                    y: b.PositionY
                });
            });
            setButtons(btns);
        } else {
            let btns = database.get("buttons");

            if (btns) {
                setButtons(btns);
            } else {
                //...postaviti defaultne buttone
                btns = [];
                btns.push({ type: "Evidencija", x: 300, y: 100 });
                if ((global.roles || []).includes("1")) {
                    btns.push({ type: "Klijenti", x: 500, y: 100 });
                    btns.push({ type: "Predmeti", x: 700, y: 100 });
                    btns.push({ type: "Postavke", x: 900, y: 100 });
                }
                setButtons(btns);
            }

            var buttonsResource = []
            btns.forEach(b => buttonsResource.push({
                Type: b.type,
                PositionX: b.x,
                PositionY: b.y
            }));
            var resource = {
                Buttons: buttonsResource,
                KorisnikID: global.user.korisnikID
            };
            api.post("korisnici/savehomebuttons", resource, null, null);
        }
    }

    function fail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function onTrashDrop(e) {
        let index = e.dataTransfer.getData("existingbutton");
        if (index) {
            let btns = [...buttons];
            btns.splice(index, 1);
            setButtons(btns);
            database.set("buttons", btns);

            var buttonsResource = []
            btns.forEach(b => buttonsResource.push({
                Type: b.type,
                PositionX: b.x,
                PositionY: b.y
            }));
            var resource = {
                Buttons: buttonsResource,
                KorisnikID: global.user.korisnikID
            };
            api.post("korisnici/savehomebuttons", resource, null, null);

            setDragging(false);
        }
    }

    function onItemDrop(e) {
        console.log(e);
        let btn = e.dataTransfer.getData("menulink");
        let btns = [...buttons];
        if (btn) {
            btns.push({
                type: btn,
                x: e.clientX,
                y: e.clientY,
            });
            setButtons(btns);

            console.log(e);
        }

        let index = e.dataTransfer.getData("existingbutton");
        if (index) {
            let id = "startbutton" + index;
            let b = document.getElementById(id);

            const left = parseInt(b.style.left);
            const top = parseInt(b.style.top);

            let x = e.clientX - offsetX; //- ((e.clientX  - offsetX) % 20);
            let y = e.clientY - offsetY; //- ((e.clientY  - offsetY) % 20);

            let w = 140;
            let t = 40;
            let r = 10;

            btns.forEach((element) => {
                // if(x > element.x + w && x < element.x + w + t){
                //     x = element.x + w + r;
                // }
                // if(y > element.y + w && y < element.y + w + t){
                //     y = element.y + w + r;
                // }
                // if(y < element.y && y > element.y - t){
                //     y = element.y + w + r;
                // }
                // if(y < element.y && y > element.y - t){
                //     y = element.y + r;
                // }
                // if(y > element.y && y < element.y + t){
                //     y = element.y;
                // }
            });

            const prevX = x - (x % 10);
            const prevY = y - (y % 10);

            btns[index].x = x > prevX + 15 ? prevX + 10 : prevX;
            btns[index].y = y > prevY + 15 ? prevY + 10 : prevY;
            setButtons(btns);
        }

        database.set("buttons", btns);

        var buttonsResource = []
        btns.forEach(b => buttonsResource.push({
            Type: b.type,
            PositionX: b.x,
            PositionY: b.y
        }));
        var resource = {
            Buttons: buttonsResource,
            KorisnikID: global.user.korisnikID
        };
        api.post("korisnici/savehomebuttons", resource, null, null);
    }

    function onStartDragItem(e, index) {
        // let links = e.target.href.split("#");

        // console.log(e);
        const rect = e.target.getBoundingClientRect();
        setDragging(true);

        setOffsetX(e.clientX - rect.x);
        setOffsetY(e.clientY - rect.y);

        e.dataTransfer.setData("existingbutton", index);
    }

    function getButton(btn, index) {
        let url = getButtonUrl(btn.type);

        return (
            <button
                onDragStart={(e) => {
                    onStartDragItem(e, index);
                }}
                onDragEnd={(e) => setDragging(false)}
                draggable={true}
                id={"startbutton" + index}
                key={"startbutton" + index}
                className="start-button"
                style={{ left: btn.x, top: btn.y, position: "absolute", color: getButtonColor(btn.type) }}
                onClick={() => {
                    window.location = url;
                    console.log("tab - " + btn.type);
                    global.setSelectedTab(btn.type);
                }}
            >
                {getButtonIcon(btn.type)}
                <p className="home-button-label-">{btn.type}</p>
            </button>
        );
    }

    function getButtonUrl(type) {
        switch (type.replace(" ", "")) {
            case "Izvještaji":
                return "#/izvjestaj";
            case "Upravljanje":
                return "#/racuni";
            case "Načiniplaćanja":
                return "#/nacinplacanja";
            default:
                return "#/" + type.replace(" ", "").replace("č", "c");
        }
    }

    function getButtonColor(type) {
        let dc = "#1391db";

        // return "rgb(94 94 94)";

        switch (type) {
            case "Korisnici":
                return "#1391db";
            case "Arhiva":
                return "rgb(161 119 74)";
            case "Evidencija":
                return "#19d56d";
            case "Pretraga evidencije":
                return "#d91b7b";
            case "Izvještaji":
                return "#19d56d";
            case "Prijenos":
                return "rgb(115 28 211 / 77%)";
            case "Upravljanje":
                return "rgb(219 19 19)";
            case "Postavke":
                return dc;
            case "Predmeti":
                return "rgb(219 121 19)";
            case "Klijenti":
                return "rgb(24 178 203)";
            default:
                return dc;
        }
    }

    function getButtonIcon(type) {
        let fs = "100px";
        switch (type) {
            case "Korisnici":
                return <GroupIcon style={{ fontSize: fs }}></GroupIcon>;
            case "Arhiva":
                return <AllInboxIcon style={{ fontSize: fs }}></AllInboxIcon>;
            case "Evidencija":
                return <EditIcon style={{ fontSize: fs }}></EditIcon>;
            case "Izvještaji":
                return <AssessmentIcon style={{ fontSize: fs }}></AssessmentIcon>;
            case "Prijenos":
                return <MoveUpIcon style={{ fontSize: fs }}></MoveUpIcon>;
            case "Upravljanje":
                return <PointOfSaleIcon style={{ fontSize: fs }}></PointOfSaleIcon>;
            case "Postavke":
                return <SettingsIcon style={{ fontSize: fs }}></SettingsIcon>;
            case "Predmeti":
                return <LibraryBooksIcon style={{ fontSize: fs }}></LibraryBooksIcon>;
            case "Klijenti":
                return <PeopleIcon style={{ fontSize: fs }}></PeopleIcon>;
            case "Financijska kartica":
                return <CreditScoreIcon style={{ fontSize: fs }}></CreditScoreIcon>;
            case "Tečaj":
                return <TimelineIcon style={{ fontSize: fs }}></TimelineIcon>;
            case "Pretraga evidencije":
                return <SearchIcon style={{ fontSize: fs }}></SearchIcon>;
            case "Zadaci":
                return <TaskAltIcon style={{ fontSize: fs }}></TaskAltIcon>;
            default:
                return <SwitchAccessShortcutIcon style={{ fontSize: fs }}></SwitchAccessShortcutIcon>;
        }
    }

    function dragOverr(e) {
        e.preventDefault();
    }

    return (
        <>
            <div className="desktop-trash" style={dragging ? { opacity: 1 } : { opacity: 0.2 }} onDragOver={dragOverr} onDrop={onTrashDrop}>
                <DeleteIcon style={{ fontSize: "74px" }}></DeleteIcon>
            </div>
            <div className="button-container" onDrop={onItemDrop} onDragOver={dragOverr}>
                {buttons.map((item, index) => {
                    return getButton(item, index);
                })}
            </div>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
        </>
    );
}
export default Home;
