import { Alert, Autocomplete, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Paper, Switch, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ComponentContainer from "./componentContainer";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import { Box } from "@mui/system";
import Util from "../util/util";
import globalContext from "./globalContext";
import api from "../util/api";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import DownloadIcon from "@mui/icons-material/Download";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import StartIcon from "@mui/icons-material/Start";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import database from "../util/database";
import ArhivaForm from "./arhivaForm";
import ArhivaChildForm from "./arhivaChildForm";
import AlertDialog from "./alertdialog";
import auth_Header from "../util/authHeader";
import dateFormat, { masks } from "dateformat";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    border: "2px solid #000",
    p: 6,
    maxHeight: "80%",
    minWidth: "50%",
};

function Arhive(props) {
    const useConstructor = Util.useConstructor();

    const global = useContext(globalContext);
    const roles = database.get("roles") || [];

    const [loading, setLoading] = useState(true);
    const [loadingArhive, setLoadingArhive] = useState(false);
    const [loadingPredmeti, setLoadingPredmeti] = useState(false);
    const [tipArhiveOptions, setTipArhiveOptions] = useState([]);
    const [tipArhive, setTipArhive] = useState(null);
    const [klijenti, setKlijenti] = useState([]);
    const [klijent, setKlijent] = useState(null);
    const [predmeti, setPredmeti] = useState([]);
    const [predmet, setPredmet] = useState(null);
    const [currentArhivaMaster, setCurrentArhivaMaster] = useState(null);
    const [currentArhivaChild, setCurrentArhivaChild] = useState(null);
    const [arhive, setArhive] = useState([]);
    const [alertOpened, setAlertOpened] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [cijelaArhiva, setCijelaArhiva] = useState(false);
    const [arhivaMasterFormOpened, setArhivaMasterFormOpened] = useState(false);
    const [formArhivaMaster, setFormArhivaMaster] = useState(null);
    const [arhivaChildFormOpened, setArhivaChildFormOpened] = useState(false);
    const [formArhivaChild, setFormArhivaChild] = useState(null);
    const [korisnici, setKorisnici] = useState([]);
    const [korisnik, setKorisnik] = useState(null);
    const [posudbaAlert, setPosudbaAlert] = useState(false);
    const [vracanjeAlert, setVracanjeAlert] = useState(false);
    const [canceled, setCanceled] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        loadTipoviArhiveAndKlijenti();
    });

    function loadTipoviArhiveAndKlijenti() {
        api.get("archive/getarhivainfo/" + global.user.firmaID, loadTipoviArhiveAndKlijentiSuccess, loadTipoviArhiveAndKlijentiFail);
    }

    function loadTipoviArhiveAndKlijentiSuccess(data) {
        setTipArhiveOptions(data.TipArhiveOptions);
        setKlijenti(data.Klijenti);
        setKorisnici(data.Korisnici);
        setLoading(false);
    }

    function loadTipoviArhiveAndKlijentiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => loadPredmeti(), [klijent]);

    useEffect(() => loadArhive(), [tipArhive, klijent, predmet]);

    function loadArhive() {
        setLoadingArhive(true);
        setCurrentArhivaMaster(null);
        setCurrentArhivaChild(null);
        if (klijent || tipArhive || predmet) {
            let resource = {
                KlijentID: klijent ? klijent.KlijentID : -1,
                TipArhiveID: tipArhive ? tipArhive.TipArhiveID : -1,
                PredmetID: predmet ? predmet.PredmetID : -1,
            };
            api.post("archive/getarhive", resource, loadArhiveSuccess, loadArhiveFail);
        } else {
            setArhive([]);
            setCurrentArhivaMaster(null);
            setLoadingArhive(false);
        }
    }

    function loadArhiveSuccess(data) {
        setArhive(data);
        if(data.length > 0){
            setCurrentArhivaMaster(data.at(0));
            if(data[0].Elektronicka){
                if(data[0].ArhivaFile.length > 0){
                    setCurrentArhivaChild(data[0].ArhivaFile.at(0));
                }
            }
            else{
                if(data[0].ArhivaStavka.length > 0){
                    setCurrentArhivaChild(data[0].ArhivaStavka.at(0));
                }
            }
        }
        setLoadingArhive(false);
    }

    function loadArhiveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function loadPredmeti() {
        setLoadingPredmeti(true);
        if (klijent) {
            api.get("predmeti/klijent/" + klijent.KlijentID, loadPredmetiSuccess, loadPredmetiFail);
        } else {
            setPredmeti([]);
            setLoadingPredmeti(false);
        }
    }

    function loadPredmetiSuccess(data) {
        setPredmeti(data);
        setLoadingPredmeti(false);
    }

    function loadPredmetiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleTipArhiveChange(e, v) {
        setTipArhive(v);
    }

    function handleKlijentChange(e, v) {
        setKlijent(v);
    }

    function handlePredmetChange(e, v) {
        setPredmet(v);
    }

    function add() {
        let resource = {
            TipArhiveID: tipArhive.TipArhiveID,
            KlijentID: klijent.KlijentID,
            PredmetID: predmet.PredmetID,
        };
        api.post("archive/checkifunique", resource, uniqueSuccess, uniqueFail);
    }

    function uniqueSuccess(data) {
        if (!data) {
            setAlertMessage('Već postoji arhiva odabranog tipa za odabranog klijenta i odabrani predmet.');
            setAlertOpened(true);
        } else {
            setAlertMessage("");
            setAlertOpened(false);
            setFormArhivaMaster(null);
            setArhivaMasterFormOpened(true);
        }
    }

    function uniqueFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function addStavkaOrDatoteka() {
        setCurrentArhivaChild(null);
        setFormArhivaChild(null);
        setArhivaChildFormOpened(true);
    }

    function editMaster() {
        setFormArhivaMaster(currentArhivaMaster);
        setArhivaMasterFormOpened(true);
    }

    function editChild() {
        if(!((currentArhivaMaster && cijelaArhiva && currentArhivaMaster.PosudjenoKom && currentArhivaMaster.PosudjenoKom.charAt(0) != "0") || (currentArhivaChild && !cijelaArhiva && currentArhivaChild.PosudioKorisnikName != null && currentArhivaChild.PosudioKorisnikName != ""))){
            setFormArhivaChild(currentArhivaChild);
            setArhivaChildFormOpened(true);    
        }
    }

    function edit() {}

    function del() {
        setDeleteAlert(false);
        setLoading(true);
        if (cijelaArhiva) {
            api.get("archive/deletearhiva/" + currentArhivaMaster.ArhivaID, deleteSuccess, deleteFail);
        } else {
            if (currentArhivaMaster.Elektronicka) {
                api.get("archive/deletearhivafile/" + currentArhivaChild.ArhivaFileID, deleteSuccess, deleteFail);
            } else {
                api.get("archive/deletearhivastavka/" + currentArhivaChild.ArhivaStavkaID, deleteSuccess, deleteFail);
            }
        }
    }

    function deleteSuccess() {
        setLoading(false);
        loadArhive();
    }

    function deleteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => setCurrentArhivaChild(null), [currentArhivaMaster]);

    useEffect(() => setPredmet(null), [tipArhive, klijent]);

    useEffect(() => {
        if (!canceled && !arhivaMasterFormOpened) loadArhive();
    }, [arhivaMasterFormOpened]);

    useEffect(() => {
        if (!canceled && !arhivaChildFormOpened) loadArhive();
    }, [arhivaChildFormOpened]);

    function posudi() {
        if (cijelaArhiva) {
            let resource = {
                KorisnikID: korisnik ? korisnik.KorisnikID : -1,
                ArhivaID: currentArhivaMaster ? currentArhivaMaster.ArhivaID : -1,
            };
            api.post("archive/posudi-sve-stavke", resource, posudiSuccess, posudiFail);
        } else {
            let resource = {
                KorisnikID: korisnik ? korisnik.KorisnikID : -1,
                ArhivaID: currentArhivaChild && currentArhivaChild.ArhivaStavkaID ? currentArhivaChild.ArhivaStavkaID : -1,
            };
            api.post("archive/posudi", resource, posudiSuccess, posudiFail);
        }
    }

    function posudiSuccess() {
        setPosudbaAlert(false);
        loadArhive();
    }

    function posudiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function vrati() {
        if (cijelaArhiva) {
            api.get("archive/vrati-sve-stavke/" + currentArhivaMaster.ArhivaID, vratiSuccess, vratiFail);
        } else {
            api.get("archive/vrati/" + currentArhivaChild.ArhivaStavkaID, vratiSuccess, vratiFail);
        }
    }

    function vratiSuccess() {
        loadArhive();
        setVracanjeAlert(false);
    }

    function vratiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleKorisnikChange(e, v) {
        setKorisnik(v);
    }

    function preuzmi(fileID, fileName) {
        // fetch("http://localhost:56895/api/arhivaFile/download/" + fileID, {headers: auth_Header.authHeader()})
        // .then((res) => { return res.blob(); })
        // .then((data) => {
        //     var url = window.URL.createObjectURL(data);
        //     var a = document.createElement('a');
        //     a.href = url;
        //     a.download = fileName;
        //     document.body.appendChild(a);
        //     a.click();
        //     a.remove();
        // });
        //;
        var req = new XMLHttpRequest();
        let auth = auth_Header.authHeader();
        req.open("GET", api.api + "/arhivaFile/download/" + fileID, true);
        req.setRequestHeader("Content-Type", "application/json");
        req.setRequestHeader("Authorization", auth.Authorization);
        req.responseType = "blob";

        req.onload = function (event) {
            var blob = req.response;
            //var fn = req.getResponseHeader("Content-Disposition"); //if you have the fileName header available
            var link = document.createElement("a");
            var url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = fileName;
            link.click();
            //if (success) success({ Message: "Datoteka: " + fileName + " je uspješno preuzeta.", Status: 200, ResponseData: null });
            // } else {
            //     //if (fail) fail({ Message: "Greška pri generiranju ili preuzimanju datoteke.", Status: 505, ResponseData: blob });
            // }
        };

        // req.onreadystatechange = function() {
        //     if (this.readyState == 4 && this.status == 200) {
        //     //console.log(this.responseText);
        //     }
        // }

        req.send();
    }

    function preuzmiSuccess(data) {}

    function preuzmiFail() {}

    return (
        <ComponentContainer loading={loading || loadingArhive || loadingPredmeti || global.loading}>
            <Grid container spacing={2} className="padding-grid">
                <Grid item xs={5}>
                    <Typography variant="h4" gutterBottom component="div" style={{ width: "100%" }}>
                        <AllInboxIcon fontSize="medium" style={{ marginRight: "15px" }}></AllInboxIcon>
                        Arhiva
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Box style={{ width: "100%" }}>
                        <Grid item md={12}>
                            <FormControl fullWidth>
                                <Autocomplete disablePortal id="tip-arhive-select" autoHighlight disabled={tipArhiveOptions.length == 0} options={tipArhiveOptions} value={tipArhive} getOptionLabel={(option) => option.Naziv + " (" + option.Oznaka + ")"} onChange={handleTipArhiveChange} renderInput={(params) => <TextField {...params} label="Tip arhive" size="small" />} />
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal
                                    id="klijent-select"
                                    disabled={klijenti.length == 0}
                                    options={klijenti}
                                    autoHighlight
                                    value={klijent}
                                    getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                                    onChange={handleKlijentChange}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 ? "black" : "red"}}>
                                                {option.Broj + " - " + option.Naziv}
                                            </li>
                                        );
                                    }}
                                    filterOptions={(options, state) => options.filter((option) => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                                    renderInput={(params) => <TextField {...params} sx={{input:{color: klijent && klijent.TipStatusaKlijentaID != 1 ? "red" : "black"}}} label="Klijent" size="small" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={12}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal
                                    id="predmet-select"
                                    disabled={predmeti.length == 0}
                                    options={predmeti}
                                    value={predmet}
                                    autoHighlight
                                    getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                                    onChange={handlePredmetChange}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={"predmet" + option.PredmetID} style={{color: option.TipStanjaPredmetaID == 1 ? (option.TipNaplateID == 3 ? "green" : (option.TipNaplateID == 2 ? "magenta" : "black")) : "red"}}>
                                                {option.Broj + " - " + option.Naziv}
                                            </li>
                                        );
                                    }}
                                    filterOptions={(options, state) => options.filter((option) => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                                    renderInput={(params) => <TextField {...params} sx={{input:{color: predmet && predmet.TipStanjaPredmetaID != 1 ? "red" : (predmet && predmet.TipNaplateID == 3 ? "green" : (predmet && predmet.TipNaplateID == 2 ? "magenta" : "black"))}}} label="Predmet" size="small" />}
                                />
                            </FormControl>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <Tooltip title="Ako se opcija uključi, funkcije posudbe ili brisanja vrijede za cijelu arhivu, a uređivanje nije moguće. Ako se opcija isključi, uređuje se ili briše, odnosno posuđuje, pojedina stavka, odnosno datoteka." enterDelay={300} enterNextDelay={300}>
                            <FormControlLabel control={<Switch checked={cijelaArhiva} onChange={(e) => setCijelaArhiva(e.target.checked)}></Switch>} labelPlacement="start" label={"Posudba/brisanje cijele arhive"}></FormControlLabel>
                        </Tooltip>
                    </Box>
                </Grid>
                {roles.includes("7") ? (
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <Tooltip title={!(tipArhive && klijent && predmet) ? "Za dodavanje arhive potrebno je odabrati tip arhive, klijenta i predmet." : ""}>
                                <span>
                                    <Button color="success" variant="contained" onClick={add} disabled={!(tipArhive && klijent && predmet)}>
                                        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                            <AddIcon />
                                            Nova arhiva
                                        </div>
                                    </Button>
                                </span>
                            </Tooltip>
                            <Button color="success" variant="contained" style={{ marginLeft: "10px" }} onClick={addStavkaOrDatoteka} disabled={!currentArhivaMaster}>
                                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                    <AddIcon />
                                    {currentArhivaMaster && currentArhivaMaster.Elektronicka ? "Nova datoteka" : "Nova stavka"}
                                </div>
                            </Button>
                            <Tooltip title={(currentArhivaMaster && cijelaArhiva && currentArhivaMaster.PosudjenoKom && currentArhivaMaster.PosudjenoKom.charAt(0) != "0") || (currentArhivaChild && !cijelaArhiva && currentArhivaChild.PosudioKorisnikName != null && currentArhivaChild.PosudioKorisnikName != "") ? "Nije moguće uređivati posuđenu arhivu." : ""}>
                                <span>
                                    <Button variant="contained" onClick={currentArhivaChild ? editChild : null} style={{ marginLeft: "10px" }} disabled={cijelaArhiva || !currentArhivaMaster || (currentArhivaMaster && currentArhivaMaster.Elektronicka) || (!currentArhivaChild && !cijelaArhiva) || (currentArhivaMaster && cijelaArhiva && currentArhivaMaster.PosudjenoKom && currentArhivaMaster.PosudjenoKom.charAt(0) != "0") || (currentArhivaChild && !cijelaArhiva && currentArhivaChild.PosudioKorisnikName != null && currentArhivaChild.PosudioKorisnikName != "")}>
                                        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                            <EditIcon />
                                            Uredi
                                        </div>
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title={(currentArhivaMaster && cijelaArhiva && currentArhivaMaster.PosudjenoKom && currentArhivaMaster.PosudjenoKom.charAt(0) != "0") || (currentArhivaChild && !cijelaArhiva && currentArhivaChild.PosudioKorisnikName != null && currentArhivaChild.PosudioKorisnikName != "") ? "Nije moguće obrisati posuđenu arhivu." : ""}>
                                <span>
                                    <Button color="error" variant="contained" onClick={() => setDeleteAlert(true)} style={{ marginLeft: "10px" }} disabled={(!currentArhivaMaster && cijelaArhiva) || (!currentArhivaChild && !cijelaArhiva) || (currentArhivaMaster && cijelaArhiva && currentArhivaMaster.PosudjenoKom && currentArhivaMaster.PosudjenoKom.charAt(0) != "0") || (currentArhivaChild && !cijelaArhiva && currentArhivaChild.PosudioKorisnikName != null && currentArhivaChild.PosudioKorisnikName != "")}>
                                        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                                            <DeleteIcon />
                                            Briši
                                        </div>
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                    </Grid>
                ) : null}
                {roles.includes("7") ? (
                    <Grid item xs={12}>
                        <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                            <FormControl style={{ width: "30%" }}>
                                <Autocomplete disablePortal id="korisnik-select" disabled={korisnici.length == 0} options={(korisnici || []).filter(k => k.TipStatusaKorisnikaID == 1)} autoHighlight value={korisnik} getOptionLabel={(option) => option.Ime + " " + option.Prezime} onChange={handleKorisnikChange} renderInput={(params) => <TextField {...params} label="Korisnik" size="small" />} />
                            </FormControl>
                            <Tooltip title={cijelaArhiva ? "Posudi cijelu arhivu." : "Posudi odabranu stavku."}>
                                <span>
                                    <Button startIcon={<StartIcon></StartIcon>} variant="outlined" style={{ marginLeft: "10px" }} disabled={!korisnik || (!currentArhivaMaster && cijelaArhiva) || (currentArhivaMaster && currentArhivaMaster.Elektronicka) || (currentArhivaMaster && cijelaArhiva && currentArhivaMaster.PosudjenoKom != null && currentArhivaMaster.PosudjenoKom.split("/").at(0) == currentArhivaMaster.PosudjenoKom.split("/").at(1)) || (!currentArhivaChild && !cijelaArhiva) || (currentArhivaChild && !cijelaArhiva && currentArhivaChild.PosudioKorisnikName != null && currentArhivaChild.PosudioKorisnikName != "")} onClick={() => setPosudbaAlert(true)}>
                                        Posudi
                                    </Button>
                                </span>
                            </Tooltip>
                            <Tooltip title={cijelaArhiva ? "Vrati cijelu arhivu." : "Vrati odabranu stavku."}>
                                <span>
                                    <Button startIcon={<KeyboardTabIcon></KeyboardTabIcon>} variant="outlined" style={{ marginLeft: "10px" }} disabled={(!currentArhivaMaster && cijelaArhiva) || (currentArhivaMaster && currentArhivaMaster.Elektronicka) || (currentArhivaMaster && cijelaArhiva && (currentArhivaMaster.PosudjenoKom == null || currentArhivaMaster.PosudjenoKom.charAt(0) == "0")) || (!currentArhivaChild && !cijelaArhiva) || (currentArhivaChild && !cijelaArhiva && (currentArhivaChild.PosudioKorisnikName == null || currentArhivaChild.PosudioKorisnikName == ""))} onClick={() => setVracanjeAlert(true)}>
                                        Vrati
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                    </Grid>
                ) : null}
                {klijent || tipArhive || predmet ? (
                    <Grid item xs={8}>
                        {arhive && arhive.length > 0 ? (
                            <Box style={{ width: "100%" }}>
                                <ListItem className="list-title" key={"listHeader"} style={{ backgroundColor: "lightblue" }}>
                                    <Grid container style={{marginTop:'-10px', marginBottom:'-10px'}}>
                                        {/* <Grid item xs={2}>
                                            <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={"Tip arhive"}></ListItemText>
                                        </Grid> */}
                                        <Grid item xs={1}>
                                            <ListItemText style={{ textAlign: "center" }} primaryTypographyProps={{ noWrap: true }} primary={"Broj"}></ListItemText>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={"Klijent"}></ListItemText>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={"Predmet"}></ListItemText>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={"Datum arhiviranja"}></ListItemText>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <Paper variant="outlined" style={{ width: "100%", maxHeight: 400, overflow: "auto" }}>
                                    <List dense>
                                        {arhive.map((arhiva, index) => {
                                            return (
                                                <ListItem className="list-item" style={{backgroundColor: arhiva == currentArhivaMaster ? 'rgba(25, 118, 210, 0.08)' : 'white'}} key={"arhiva" + index} onClick={() => setCurrentArhivaMaster(arhiva)}>
                                                    <Grid container style={{marginTop:'-10px', marginBottom:'-10px'}}>
                                                        {/* <Grid item xs={2}>
                                                            <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={arhiva == currentArhivaMaster ? <b>{arhiva.TipNaziv}</b> : arhiva.TipNaziv}></ListItemText>
                                                        </Grid> */}
                                                        {/* <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} /> */}
                                                        <Grid item xs={1}>
                                                            <ListItemText style={{ textAlign: "center" }} primaryTypographyProps={{ noWrap: true }} primary={arhiva == currentArhivaMaster ? <b>{arhiva.TipOznaka + (arhiva.Broj != null ? arhiva.Broj.toString().padStart(6,'0') : "")}</b> : arhiva.TipOznaka + (arhiva.Broj != null ? arhiva.Broj.toString().padStart(6,'0') : "")}></ListItemText>
                                                        </Grid>
                                                        {/* <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} /> */}
                                                        <Grid item xs={4}>
                                                            <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={arhiva == currentArhivaMaster ? <b>{arhiva.KlijentNaziv}</b> : arhiva.KlijentNaziv}></ListItemText>
                                                        </Grid>
                                                        {/* <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} /> */}
                                                        <Grid item xs={5}>
                                                            <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={arhiva == currentArhivaMaster ? <b>{arhiva.PredmetNaziv}</b> : arhiva.PredmetNaziv}></ListItemText>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={arhiva == currentArhivaMaster ? <b>{dateFormat(arhiva.DatumArhive, "dd.mm.yyyy")}</b> : dateFormat(arhiva.DatumArhive, "dd.mm.yyyy")}></ListItemText>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Paper>
                            </Box>
                        ) : (
                            <Typography variant="subtitle1" textAlign="center">
                                {!loadingArhive ? "Ne postoji niti jedna arhiva." : ""}
                            </Typography>
                        )}
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" textAlign="center">
                            Za prikaz arhiva odaberite klijenta.
                        </Typography>
                    </Grid>
                )}
                {klijent || tipArhive || predmet ? (
                    <Grid item xs={4}>
                        {currentArhivaMaster ? (
                            <Box style={{ width: "100%" }}>
                                {currentArhivaMaster.Elektronicka ? (
                                    <ListItem className="list-title" key={"listHeader"} style={{ backgroundColor: "lightblue" }}>
                                        <Grid container style={{marginTop:'-10px', marginBottom:'-10px'}}>
                                            <Grid item xs={4}>
                                                <ListItemText primaryTypographyProps={{ noWrap: true }} primary={"Tip dokumenta"}></ListItemText>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={"Naziv"}></ListItemText>
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                        </Grid>
                                    </ListItem>
                                ) : (
                                    <ListItem className="list-title" key={"listHeader"} style={{ backgroundColor: "lightblue" }}>
                                        <Grid container style={{marginTop:'-10px', marginBottom:'-10px'}}>
                                            <Grid item xs={4}>
                                                <ListItemText primaryTypographyProps={{ noWrap: true }} primary={"Podbroj"}></ListItemText>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={"Opis"}></ListItemText>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={"Posuđeno"}></ListItemText>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                )}
                                <Paper variant="outlined" style={{ width: "100%", maxHeight: 400, overflow: "auto" }}>
                                    <List dense>
                                        {currentArhivaMaster.ArhivaStavka.map((arhivaStavka, index) => {
                                            return (
                                                <ListItem className="list-item" style={{backgroundColor: (arhivaStavka == currentArhivaChild ? 'rgba(25, 118, 210, 0.08)': 'white')}} key={"arhiva" + index} onClick={() => setCurrentArhivaChild(arhivaStavka)} onDoubleClick={editChild}>
                                                    <Grid container style={{marginTop:'-10px', marginBottom:'-10px'}}>
                                                        <Grid item xs={2}>
                                                            <ListItemText primaryTypographyProps={{ noWrap: true }} primary={arhivaStavka == currentArhivaChild ? <b>{arhivaStavka.Broj}</b> : arhivaStavka.Broj}></ListItemText>
                                                        </Grid>
                                                        {/* <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} /> */}
                                                        <Tooltip title={arhivaStavka.Opis != null && arhivaStavka.Opis != '' ? arhivaStavka.Opis : 'Dvostrukim klikom ovdje možete ući u upis opisa arhivske stavke'} enterDelay={300} enterNextDelay={300}>
                                                            <Grid item xs={8}>
                                                                <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={arhivaStavka == currentArhivaChild ? <b>{arhivaStavka.Opis}</b> : arhivaStavka.Opis}></ListItemText>
                                                            </Grid>
                                                        </Tooltip>
                                                        {/* <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} /> */}
                                                        <Tooltip title={arhivaStavka.PosudioKorisnikName != null && arhivaStavka.PosudioKorisnikName != "" ? "Posudio korisnik " + arhivaStavka.PosudioKorisnikName + " dana " + dateFormat(arhivaStavka.DatumPosudbe, "dd.mm.yyyy") : ""}>
                                                            <Grid item xs={2}>
                                                                <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>{arhivaStavka.PosudioKorisnikName != null && arhivaStavka.PosudioKorisnikName != "" ? <CheckBoxIcon style={{ marginTop: "3px" }} /> : <CheckBoxOutlineBlankIcon style={{ marginTop: "3px" }} />}</ListItemIcon>
                                                            </Grid>
                                                        </Tooltip>
                                                    </Grid>
                                                </ListItem>
                                            );
                                        })}
                                        {currentArhivaMaster.ArhivaFile.map((arhivaFile, index) => {
                                            return (
                                                <ListItem className="list-item" key={"arhiva" + index} onClick={() => setCurrentArhivaChild(arhivaFile)} onDoubleClick={editChild}>
                                                    <Grid container style={{marginTop:'-10px', marginBottom:'-10px'}}>
                                                        <Grid item xs={4}>
                                                            <ListItemText primaryTypographyProps={{ noWrap: true }} primary={arhivaFile == currentArhivaChild ? <b>{arhivaFile.TipDokumentaNaziv}</b> : arhivaFile.TipDokumentaNaziv}></ListItemText>
                                                        </Grid>
                                                        {/* <Divider orientation="vertical" flexItem style={{ marginRight: "-1px" }} /> */}
                                                        <Grid item xs={6}>
                                                            <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={arhivaFile == currentArhivaChild ? <b>{arhivaFile.Naziv}</b> : arhivaFile.Naziv}></ListItemText>
                                                        </Grid>
                                                        <Tooltip title={arhivaFile.Opis}>
                                                            <Grid item xs={1} style={{ display: "flex", justifyContent: "center" }}>
                                                                <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
                                                                    <InfoIcon style={{ marginTop: "3px" }} />
                                                                </ListItemIcon>
                                                            </Grid>
                                                        </Tooltip>
                                                        <Tooltip title="Preuzmi">
                                                            <Grid item xs={1}>
                                                                <ListItemButton style={{ display: "flex", justifyContent: "center", padding: "0" }} onClick={() => preuzmi(arhivaFile.ArhivaFileID, arhivaFile.DokumentNaziv)}>
                                                                    <DownloadIcon style={{ marginTop: "3px" }} />
                                                                </ListItemButton>
                                                            </Grid>
                                                        </Tooltip>
                                                    </Grid>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </Paper>
                            </Box>
                        ) : null}
                    </Grid>
                ) : (
                    <Grid item xs={6}></Grid>
                )}
            </Grid>

            <Modal className="modal" open={alertOpened}>
                <Box sx={style}>
                    <Alert severity="warning">{alertMessage}</Alert>
                    <Box style={{ widht: "100%", display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                        <Button variant="outlined" onClick={() => setAlertOpened(false)}>
                            Zatvori
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <Modal className="modal" open={arhivaMasterFormOpened}>
                <Box sx={style}>
                    <ArhivaForm genericUsage={true} predmetTipStanjaPredmetaID={predmet ? predmet.TipStanjaPredmetaID : 0} setCanceled={setCanceled} setFormOpened={setArhivaMasterFormOpened} formArhivaMaster={formArhivaMaster} tipArhive={tipArhive} brojRegistratora={predmet && predmet.BrojRegistratora ? predmet.BrojRegistratora : 1} predmetID={predmet ? predmet.PredmetID : null} predmetNaziv={predmet ? predmet.Naziv : ""} klijentID={klijent ? klijent.KlijentID : null}></ArhivaForm>
                </Box>
            </Modal>

            <Modal className="modal" open={arhivaChildFormOpened}>
                <Box sx={style}>
                    <ArhivaChildForm arhivaID={currentArhivaMaster ? currentArhivaMaster.ArhivaID : -1} arhivaBroj={currentArhivaMaster ? currentArhivaMaster.Broj : 0} elektronicka={currentArhivaMaster ? currentArhivaMaster.Elektronicka : false} setFormOpened={setArhivaChildFormOpened} formArhivaChild={formArhivaChild} tipArhive={tipArhive} predmetID={predmet ? predmet.PredmetID : null} predmetNaziv={predmet ? predmet.Naziv : ""} klijentID={klijent ? klijent.KlijentID : null} setCanceled={setCanceled}></ArhivaChildForm>
                </Box>
            </Modal>

            {posudbaAlert && cijelaArhiva && korisnik ? <AlertDialog title="Posudba cijele arhive" message={currentArhivaMaster && currentArhivaMaster.PosudjenoKom && currentArhivaMaster.PosudjenoKom.split("/").at(0) != "0" && currentArhivaMaster.PosudjenoKom.split("/").at(0) != currentArhivaMaster.PosudjenoKom.split("/").at(1) ? "Odabrana arhiva već je djelomično posuđena. Moguće je posuditi samo preostale dijelove. Želite li posuditi preostale stavke korisniku " + korisnik.Ime + " " + korisnik.Prezime + "?" : "Želite li posuditi cijelu odabranu arhivu korisniku " + korisnik.Ime + " " + korisnik.Prezime + "?"} cancelLabel={"Odustani"} confirmLabel={"Posudi"} cancelAction={() => setPosudbaAlert(false)} confirmAction={posudi}></AlertDialog> : null}

            {posudbaAlert && !cijelaArhiva && korisnik ? <AlertDialog title="Posudba stavke arhive" message={"Želite li posuditi odabranu stavku korisniku " + korisnik.Ime + " " + korisnik.Prezime + "?"} cancelLabel={"Odustani"} confirmLabel={"Posudi"} cancelAction={() => setPosudbaAlert(false)} confirmAction={posudi}></AlertDialog> : null}

            {vracanjeAlert && cijelaArhiva ? <AlertDialog title="Povrat cijele arhive" message={"Želite li vratiti cijelu odabranu arhivu (sve njezine posuđene stavke)?"} cancelLabel={"Odustani"} confirmLabel={"Vrati"} cancelAction={() => setVracanjeAlert(false)} confirmAction={vrati}></AlertDialog> : null}

            {vracanjeAlert && !cijelaArhiva ? <AlertDialog title="Povrat stavke arhive" message={"Želite li vratiti odabranu stavku?"} cancelLabel={"Odustani"} confirmLabel={"Vrati"} cancelAction={() => setVracanjeAlert(false)} confirmAction={vrati}></AlertDialog> : null}

            {deleteAlert && !cijelaArhiva ? <AlertDialog title="Brisanje stavke arhive" message={"Želite li obrisati odabranu stavku?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => setDeleteAlert(false)} confirmAction={del}></AlertDialog> : null}

            {deleteAlert && cijelaArhiva ? <AlertDialog title="Brisanje cijele arhive" message={"Želite li obrisati cijelu odabranu arhivu?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => setDeleteAlert(false)} confirmAction={del}></AlertDialog> : null}
        
            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default Arhive;
