import { Alert, Autocomplete, Box, Button, Divider, FormControl, Grid, imageListClasses, imageListItemClasses, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import api from "../util/api";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutocompleteAsync from "../controls/autocompleteasync";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorAlert from "./errorAlert";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import GlobalContext from "../components/globalContext";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { P } from "@antv/g2plot";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';

const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9.]+$/;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

const pasusStyle = {
    textAlign: 'center',
    display: 'inline-flex',
    verticalAlign: 'text-bottom',
    boxSizing: 'inherit',
    textAlign: 'justify',
    alignItems: 'justify',
    marginBottom: '14px'
};

const clanakStyle = {
    fontWeight: 'bold',
    marginBottom: '14px'
}

function UvjetiKoristenja(props) {

    const useConstructor = Util.useConstructor();

    const form = props.form;
    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;
    const global = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);
    const [uvjeti, setUvjeti] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        api.get("postavke/getuvjetikoristenja", getUvjetiSuccess, getUvjetiFail);
    });

    function getUvjetiSuccess(data) {
        setUvjeti(data);
        setLoading(false);
    }

    function getUvjetiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1} style={{maxWidth:"90vw"}}>
                <Grid item xs={12} style={{textAlign:"left", marginBottom:"15px"}}>
                    <Button variant="outlined" onClick={() => {!global.loggedOutComponent || global.loggedOutComponent == "login" ? window.location = "#/login" : window.location = "#/registracija"}} startIcon={<ArrowBackIcon></ArrowBackIcon>}>{!global.loggedOutComponent || global.loggedOutComponent == "login" ? "Prijava" : "Registracija"}</Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={pasusStyle}>
                            <CardMembershipIcon fontSize="medium" style={{marginRight:"15px"}}></CardMembershipIcon>
                            <b>Uvjeti korištenja</b>
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="p" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <div dangerouslySetInnerHTML={{ __html:uvjeti }}></div>        
                    </Typography>                    
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
        </ComponentContainer>
    );
}

export default UvjetiKoristenja;