import { Alert, Autocomplete, Button, FormControl, Grid, Grow, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import PrintIcon from '@mui/icons-material/Print';
import ExitToApp from "@mui/icons-material/ExitToApp";
import { ApiOutlined } from "@ant-design/icons";
import OsvjeziAdresuAlert from "./osvjeziAdresuAlert";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    minWidth:"80vw"
};

const stvarnoEvidentiranoVrijemeOptions = [
    { Naziv: "Prikaži stvarno evidentirano vrijeme", Vrijednost: true },
    { Naziv: "Nemoj prikazati stvarno evidentirano vrijeme", Vrijednost: false }
];

const formatOptions = [
    { Naziv: "PDF dokument (.pdf)", ReportType: "PDF", MediaTypeHeaderValue: "application/pdf", Extension: ".pdf" },
    { Naziv: "Microsoft Word dokument (.docx)", ReportType: "WORDOPENXML", MediaTypeHeaderValue: "application/msword", Extension: ".docx" },
    { Naziv: "Microsoft Excel dokument (.xlsx)", ReportType: "EXCELOPENXML", MediaTypeHeaderValue: "application/vnd.ms-excel", Extension: ".xlsx" }
];

function IspisRacunaISpecifikacijaForm(props) {

    const racun = props.racun;
    const racuniIDs = props.racuniIDs;
    const ispisiRacun = props.ispisiRacun;
    const skupniIspisRacuna = props.skupniIspisRacuna;
    const close = props.close;

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [firmaJezici, setFirmaJezici] = useState([]);
    const [firmaJezik, setFirmaJezik] = useState(null);
    const [tipoviIspisa, setTipoviIspisa] = useState([]);
    const [tipIspisa, setTipIspisa] = useState(null);
    const [reportPredlosci, setReportPredlosci] = useState([]);
    const [reportPredlozak, setReportPredlozak] = useState(null);
    const [defaultRacunPredlozak, setDefaultRacunPredlozak] = useState(null);
    const [defaultSpecifikacijaPredlozak, setDefaultSpecifikacijaPredlozak] = useState(null);
    const [format, setFormat] = useState(formatOptions.at(0));
    const [prikaziStvarnoEvidentiranoVrijeme, setPrikaziStvarnoEvidentiranoVrijeme] = useState(stvarnoEvidentiranoVrijemeOptions[0]);
    const [nacinOtvaranjaRacuna, setNacinOtvaranjaRacuna] = useState("_blank");
    const [kontakti, setKontakti] = useState([]);
    const [naRuke, setNaRuke] = useState(null);
    const [osvjeziPodatkeAlert, setOsvjeziPodatkeAlert] = useState(false);
    const [osvjeziPodatke, setOsvjeziPodatke] = useState(false);
    const [prikaziBarkod, setPrikaziBarkod] = useState(false);
    const [barkodOmogucen, setBarkodOmogucen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [aktivnostiOptions, setAktivnostiOptions] = useState([
        { TipNaplatnostiID: 1, Naziv: "Naplatne" },
        { TipNaplatnostiID: 2, Naziv: "Nenaplatne" }
    ]);
    const [aktivnostiOption, setAktivnostiOption] = useState({ TipNaplatnostiID: 1, Naziv: "Naplatne" });

    useConstructor(() => {
        let tipIspisaID = 1;
        let generateTipoviIspisa = [
            { TipIspisaID: tipIspisaID, Naziv: "Račun", RacunskaStavkaID : -1 }     // -1 : ispis racuna
        ];
        tipIspisaID++;
        if (racuniIDs.length == 1) {
            let brojSpecifikacija = racun.RacunskeStavke.filter(s => s.TipRacunskeStavkeID == 1).length;
            if (brojSpecifikacija > 1) {
                generateTipoviIspisa.push({ TipIspisaID: tipIspisaID, Naziv: "Skupni ispis svih specifikacija" , RacunskaStavkaID: -2 })    // -2 : skupni ispis specifikacija
                tipIspisaID++;
            }
            racun.RacunskeStavke.forEach(racunskaStavka => {
                if (racunskaStavka.TipRacunskeStavkeID == 1) {
                    generateTipoviIspisa.push({ TipIspisaID: tipIspisaID, Naziv: "Specifikacija - " + racunskaStavka.Opis, RacunskaStavkaID: racunskaStavka.RacunskaStavkaID })
                    tipIspisaID++;
                }
            });
        }
        setTipoviIspisa(generateTipoviIspisa);
        setTipIspisa(generateTipoviIspisa.at(0));
        let resource = {
            FirmaID : global.user.firmaID,
            RacuniIDs : racuniIDs
        };
        api.post("racun/print/getformdetalji", resource, loadFirmaJeziciSuccess, loadFirmaJeziciFail);
    });

    function loadFirmaJeziciSuccess(data) {
        setFirmaJezici(data.FirmaJezici);
        if (racuniIDs.length == 1) {
            setFirmaJezik(data.FirmaJezici.find(fj => fj.FirmaJezikID == racun.FirmaJezikID) || data.FirmaJezici.find(fj => fj.JezikID == 1) || data.FirmaJezici.at(0));
        } else {
            setFirmaJezik(data.FirmaJezici.find(fj => fj.JezikID == 1) || data.FirmaJezici.at(0));
        }
        setBarkodOmogucen(data.BarkodOmogucen)
        setPrikaziBarkod(data.PrikaziBarkod);
        setReportPredlosci(data.ReportPutanjeNazivi);
        setDefaultRacunPredlozak(data.DefaultRacunReportPutanjaNaziv);
        setDefaultSpecifikacijaPredlozak(data.DefaultSpecifikacijaReportPutanjaNaziv);
        if (data.NacinOtvaranjaRacuna) {
            setNacinOtvaranjaRacuna(data.NacinOtvaranjaRacuna);
        }
        setPrikaziStvarnoEvidentiranoVrijeme(stvarnoEvidentiranoVrijemeOptions.find(o => o.Vrijednost == data.DefaultPrikaziStvarnoEvidentiranoVrijeme));
        setOsvjeziPodatkeAlert(data.OsvjeziPodatkeAlert);
        if (data.OmoguciIspisSvihEvidencija) {
            let newAktivnostiOptions = JSON.parse(JSON.stringify(aktivnostiOptions));
            let sveAktivnostiOption = { TipNaplatnostiID: 0, Naziv: "Sve" };
            newAktivnostiOptions.push(sveAktivnostiOption);
            setAktivnostiOptions(newAktivnostiOptions);
            if (data.DefaultIspisSpecifikacijaTipNaplatnostiID != null) {
                setAktivnostiOption(newAktivnostiOptions.find(o => o.TipNaplatnostiID == data.DefaultIspisSpecifikacijaTipNaplatnostiID));
            }
        }
        if (racuniIDs.length == 1) {
            api.get("kli-vod-pred-kontakt-by-racun/" + racuniIDs[0], loadKontaktiSuccess, loadKontaktiFail);
        }
        else {
            setLoading(false);
        }
    }

    function loadKontaktiSuccess(data) {
        setKontakti(data);
        setLoading(false);
    }

    function loadKontaktiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function loadFirmaJeziciFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function ispisi() {
        if (racuniIDs.length == 1) {
            let resource = {
                RacunskaStavkaID : tipIspisa.RacunskaStavkaID,
                RacunID : racun.RacunID,
                TipNaplatnostiID : aktivnostiOption ? aktivnostiOption.TipNaplatnostiID : 1,
                FirmaJezik : firmaJezik,
                PutanjaDoReporta : reportPredlozak.Putanja,
                Extension : format.Extension,
                MediaTypeHeaderValue : format.MediaTypeHeaderValue,
                ReportType : format.ReportType,
                NaRuke : naRuke,
                PrikaziStvarnoEvidentiranoVrijeme : prikaziStvarnoEvidentiranoVrijeme.Vrijednost,
                PrikaziBarkod : barkodOmogucen
            };
            ispisiRacun(resource, format.Extension, nacinOtvaranjaRacuna);
        } else {
            let resource = {
                RacunskaStavkaID : tipIspisa.RacunskaStavkaID,
                RacuniIDs : racuniIDs,
                TipNaplatnostiID : aktivnostiOption ? aktivnostiOption.TipNaplatnostiID : 1,
                FirmaJezik : firmaJezik,
                PutanjaDoReporta : reportPredlozak.Putanja,
                Extension : format.Extension,
                MediaTypeHeaderValue : format.MediaTypeHeaderValue,
                ReportType : format.ReportType,
                PrikaziStvarnoEvidentiranoVrijeme : prikaziStvarnoEvidentiranoVrijeme.Vrijednost,
                PrikaziBarkod : barkodOmogucen
            };
            skupniIspisRacuna(resource, format.Extension, nacinOtvaranjaRacuna);
        }
        //close();
    }

    useEffect(() => {
        if (tipIspisa && tipIspisa.RacunskaStavkaID == -1) {
            setReportPredlozak(defaultRacunPredlozak);
        } else if (tipIspisa && (tipIspisa.RacunskaStavkaID > 0 || tipIspisa.RacunskaStavkaID == -2)) {
            setReportPredlozak(defaultSpecifikacijaPredlozak);
        }
    }, [tipIspisa, defaultRacunPredlozak, defaultSpecifikacijaPredlozak]);

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12} marginBottom="5px">
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center"}}>
                        Ispis računa i specifikacija
                    </Typography>
                </Grid>
                {osvjeziPodatkeAlert ? (
                    <Grow in={osvjeziPodatkeAlert}>
                        <Grid item xs={8}>
                            <Alert severity="warning">
                                {racuniIDs.length > 1 ? 
                                "Podaci na jednom ili više računa ne odgovaraju trenutnim podacima klijenta. " 
                                : 
                                "Podaci na računu ne odgovaraju trenutnim podacima klijenta. "}
                                Za osvježavanje podataka pritisnite gumb s desne strane.
                            </Alert>
                        </Grid>
                    </Grow>
                ) : null}
                {osvjeziPodatkeAlert ? (
                    <Grow in={osvjeziPodatkeAlert}>
                        <Grid item xs={4} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <Button onClick={() => setOsvjeziPodatke(true)} variant="contained">Osvježi podatke</Button>
                        </Grid>   
                    </Grow>
                ) : null}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            options={tipoviIspisa}
                            value={tipIspisa}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {if (v) setTipIspisa(v)}}
                            disableClearable
                            ListboxProps={{ style: { maxHeight: '8rem' } }}
                            renderInput={(params) => <TextField {...params} label="Tip ispisa" size="small" error={!tipIspisa} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            disabled={kontakti && kontakti.length == 0}
                            options={kontakti}
                            value={naRuke}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {if (v) setNaRuke(v)}}
                            ListboxProps={{ style: { maxHeight: '8rem' } }}
                            renderInput={(params) => <TextField {...params} label="Na ruke" size="small" />}
                        />
                    </FormControl>
                </Grid>
                {tipIspisa && tipIspisa.RacunskaStavkaID != -1 ? (
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={aktivnostiOptions}
                                value={aktivnostiOption}
                                getOptionLabel={(option) => option.Naziv}
                                onChange={(e,v) => {if (v) setAktivnostiOption(v)}}
                                disableClearable
                                ListboxProps={{ style: { maxHeight: '8rem' } }}
                                renderInput={(params) => <TextField {...params} label="Aktivnosti" size="small" error={!aktivnostiOption} />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {tipIspisa && tipIspisa.RacunskaStavkaID != -1 ? (
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={stvarnoEvidentiranoVrijemeOptions}
                                value={prikaziStvarnoEvidentiranoVrijeme}
                                getOptionLabel={(option) => option.Naziv}
                                onChange={(e,v) => {if (v) setPrikaziStvarnoEvidentiranoVrijeme(v)}}
                                disableClearable
                                ListboxProps={{ style: { maxHeight: '8rem' } }}
                                renderInput={(params) => <TextField {...params} label="Prikaz stvarnog evidentiranog vremena" size="small" error={prikaziStvarnoEvidentiranoVrijeme == null} />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                <Grid item xs={12} marginBottom="5px">
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            options={firmaJezici}
                            value={firmaJezik}
                            getOptionLabel={(option) => option.FirmaJezikNaziv}
                            onChange={(e,v) => {if (v) setFirmaJezik(v)}}
                            disableClearable
                            ListboxProps={{ style: { maxHeight: '8rem' } }}
                            renderInput={(params) => <TextField {...params} label="Jezik" size="small" error={!firmaJezik} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} marginBottom="5px">
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            options={tipIspisa && reportPredlosci ? ((tipIspisa.RacunskaStavkaID > 0 || tipIspisa.RacunskaStavkaID == -2) ? reportPredlosci.filter(rp => rp.TipReportaID == 2) : reportPredlosci.filter(rp => rp.TipReportaID == 1)) : []}
                            value={reportPredlozak}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {if (v) setReportPredlozak(v)}}
                            disableClearable
                            ListboxProps={{ style: { maxHeight: '8rem' } }}
                            renderInput={(params) => <TextField {...params} label="Predložak" size="small" error={!reportPredlozak} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} marginBottom="5px">
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            options={formatOptions}
                            value={format}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {if (v) setFormat(v)}}
                            disableClearable
                            ListboxProps={{ style: { maxHeight: '8rem' } }}
                            renderInput={(params) => <TextField {...params} label="Format" size="small" error={!format} />}
                        />
                    </FormControl>
                </Grid>
                {/* {barkodOmogucen ? (
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={[{ Naziv: "DA", Vrijednost: true }, { Naziv: "NE", Vrijednost: false }]}
                                value={prikaziBarkod ? { Naziv: "DA", Vrijednost: true } : { Naziv: "NE", Vrijednost: false }}
                                getOptionLabel={(option) => option.Naziv}
                                onChange={(e,v) => {if (v) setPrikaziBarkod(v.Vrijednost)}}
                                disableClearable
                                ListboxProps={{ style: { maxHeight: '8rem' } }}
                                renderInput={(params) => <TextField {...params} label="Prikaži barkod" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null} */}
                <Grid item xs={12}>
                    <Box style={{display:"flex", justifyContent:"flex-end"}}>
                        <Button variant="contained" color="success" disabled={!tipIspisa || !firmaJezik || !format || !reportPredlozak || (tipIspisa.RacunskaStavkaID > 0 && (!aktivnostiOption || prikaziStvarnoEvidentiranoVrijeme == null))} onClick={ispisi} startIcon={<PrintIcon></PrintIcon>}>Ispiši</Button>
                        <Button variant="outlined" color="error" startIcon={<ExitToApp></ExitToApp>} style={{marginLeft:"5px"}} onClick={props.close}>Odustani</Button>
                    </Box>
                </Grid>
            </Grid>

            <Modal open={osvjeziPodatke}>
                <Box sx={style} overflow={"auto"} >
                   <OsvjeziAdresuAlert racuniIDs={racuniIDs} removeMessage={() => setOsvjeziPodatkeAlert(false)} filterRacuni={props.filterRacuni} close={() => {setOsvjeziPodatke(false)}}></OsvjeziAdresuAlert>
                </Box>
            </Modal>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default IspisRacunaISpecifikacijaForm;