import { Box, ButtonBase, ClickAwayListener, Container, Divider, Grid, Hidden, Icon, IconButton, ListItem, ListItemIcon, ListItemText, Paper, TextField, Tooltip } from "@mui/material";
import dateFormat, { masks } from "dateformat";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import React from "react";

function PretragaEvidencijeListItem(props) {

    const item = props.item;
    const fontWeight = props.fontWeight;
    const handleTableClick = props.handleTableClick;

    return( 
    <ListItem className="list-item" id={item.UniqueKey} key={"listItem" + item.UniqueKey} style={{paddingTop:0, paddingBottom:0, backgroundColor: fontWeight == 700 ? 'rgba(25, 118, 210, 0.08)' : 'white'}} onClick={() => handleTableClick(item)}>
    <Grid container>
       
       <Grid item md={1}>

           <Grid container>

               <Grid item md={8} sx={{display: { xs: "none", sm: "flex"}, alignItems:"center"}}>
                   <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={dateFormat(item.Datum, "dd.mm.yyyy")} />
               </Grid>
               <Grid item md={4} sx={{display: { xs: "none", sm: "block"}}}>
                   <Tooltip title={item.TipNaziv} enterDelay={300} enterNextDelay={300}>
                       <ListItemText style={{paddingLeft:"15px"}} >
                           <Brightness1Icon sx={{ color: item.Boja, marginTop:"5px" }}></Brightness1Icon>
                       </ListItemText>
                   </Tooltip>
               </Grid>


           </Grid>

       </Grid>
       
       <Grid item md={2} sx={{display: { xs: "none", sm: "flex"}, alignItems:"center"}}>
           <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={item.Korisnik.Ime + " " + item.Korisnik.Prezime} />
       </Grid>
       <Grid item md={2} sx={{display: { xs: "none", sm: "flex"}, alignItems:"center"}}>
           <Tooltip title={item.TipPredmetnosti.TipPredmetnostiID == 1 ? item.Klijent.Broj + " - " + item.Klijent.Naziv : ""} enterDelay={300} enterNextDelay={300}>
               <ListItemText style={{paddingLeft:"15px", color: item.TipPredmetnosti.TipPredmetnostiID == 1 && item.Klijent.TipStatusaKlijentaID == 2 ? 'red' : 'black'}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={item.TipPredmetnosti.TipPredmetnostiID == 1 ? item.Klijent.Broj + " - " + item.Klijent.Naziv : ""} />
           </Tooltip>
       </Grid>                                                        
       <Grid item md={2} sx={{display: { xs: "none", sm: "flex"}, alignItems:"center"}}>
           <Tooltip title={item.TipPredmetnosti.TipPredmetnostiID == 1 ? item.Predmet.Broj + " - " + item.Predmet.Naziv : ""} enterDelay={300} enterNextDelay={300}>
               <ListItemText style={{paddingLeft:"15px", color: item.TipPredmetnosti.TipPredmetnostiID == 1 ? (item.Predmet.TipaStanjaPredmetaID > 1 ? 'red' : (item.Predmet.TipNaplateID == 3 ? 'green' :(item.Predmet.TipNaplateID == 2 ? 'magenta' : 'black'))): 'black'}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={item.TipPredmetnosti.TipPredmetnostiID == 1 ? item.Predmet.Broj + " - " + item.Predmet.Naziv : ""} />
           </Tooltip>
       </Grid>

       <Grid item md={4} sx={{display: { xs: "none", sm: "flex"}, alignItems:"center"}}>
           <Tooltip title={item.Opis} enterDelay={300} enterNextDelay={300} arrow>
               <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={item.Opis} />
           </Tooltip>
       </Grid>

       <Grid item md={1} sx={{display: { xs: "none", sm: "block"}}}>
           <Tooltip title={item.TipNaplatnosti && item.TipNaplatnosti.TipNaplatnostiID == 1 ? "Naplatno" : "Nenaplatno"} enterDelay={300} enterNextDelay={300}>
               <ListItemText style={{paddingLeft:"15px", textAlign:"right"}} primaryTypographyProps={{ noWrap:true }}>
                   <Brightness1Icon sx={{ color: item.NaplatnostBoja, marginTop: "5px" }}></Brightness1Icon>
               </ListItemText>
           </Tooltip>
       </Grid>

   </Grid>
</ListItem>);

   
   
    // return <ListItem 
    //     className={"list-item"} 
    //     key={"racun" + racun.RacunID} style={{ paddingTop: 0, paddingBottom: 0, color: (racun.Storniran || racun.VrstaRacunaID == 7) ? "red" : (racun.Broj ? (racun.VrstaRacunaID == 3 ? "green" : "black") : "blue") }}  onClick={(e) => { props.handleRacunClick(e, racun.RacunID, props.index, props.referenca.current)}} onDoubleClick={edit}>
    //                                     <Grid container>
    //                                         <Grid item xs={2}>
    //                                             <Grid container>
    //                                                 <Tooltip title={racun.RacunID}>
    //                                                     <Grid item xs={2} >
    //                                                         <ListItemText primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={racun.RacunID} ></ListItemText>
    //                                                     </Grid>
    //                                                 </Tooltip>
    //                                                 <Tooltip title={racun.VrstaRacunaNaziv} enterDelay={300} enterNextDelay={300}>
    //                                                     <Grid item xs={4}>
    //                                                         <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={racun.VrstaRacunaNaziv} />
    //                                                     </Grid>
    //                                                 </Tooltip>
    //                                                 <Tooltip title={racun.Broj || ""} enterDelay={300} enterNextDelay={300}>
    //                                                     <Grid item xs={6}>
    //                                                         <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={racun.Broj || ""} />
    //                                                     </Grid>
    //                                                 </Tooltip>
    //                                             </Grid>
    //                                         </Grid>
    //                                         <Tooltip title={racun.KlijentBroj + ' - ' + racun.KlijentNaziv} enterDelay={300} enterNextDelay={300}>
    //                                             <Grid item xs={2}>
    //                                                 <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={(racun.KlijentNaziv)} />
    //                                             </Grid>
    //                                         </Tooltip>
    //                                         <Tooltip title={dateFormat(racun.Datum, "dd.mm.yyyy")} enterDelay={300} enterNextDelay={300}>
    //                                             <Grid item xs={1}>
    //                                                 <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={(dateFormat(racun.Datum, "dd.mm.yyyy"))} />
    //                                             </Grid>
    //                                         </Tooltip>
    //                                         <Grid item xs={7}>
    //                                             <Grid container>
    //                                                 <Tooltip title={racun.FirmaValutaNaziv} enterDelay={300} enterNextDelay={300}>
    //                                                     <Grid item xs={1}>
    //                                                         <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={(racun.FirmaValutaNaziv)} />
    //                                                     </Grid>
    //                                                 </Tooltip>
    //                                                 <Grid item xs={10}>
    //                                                     <Grid container>
    //                                                         <Tooltip title={formatter.format(racunDetalji.Iznos)} enterDelay={300} enterNextDelay={300}>
    //                                                             <Grid item xs={2}>
    //                                                                 <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight, textAlign:"right" }} primary={formatter.format(racunDetalji.Iznos)} ></ListItemText>
    //                                                             </Grid>
    //                                                         </Tooltip>
    //                                                         <Tooltip title={formatter.format(racunDetalji.Porez)} enterDelay={300} enterNextDelay={300}>
    //                                                             <Grid item xs={2}>
    //                                                                 <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight, textAlign:"right" }} primary={formatter.format(racunDetalji.Porez)} ></ListItemText>
    //                                                             </Grid>
    //                                                         </Tooltip>
    //                                                         <Tooltip title={formatter.format(racunDetalji.Ukupno)} enterDelay={300} enterNextDelay={300}>
    //                                                             <Grid item xs={2}>
    //                                                                 <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight, textAlign:"right" }} primary={formatter.format(racunDetalji.Ukupno)} ></ListItemText>
    //                                                             </Grid>
    //                                                         </Tooltip>
    //                                                         <Tooltip title={formatter.format(racunDetalji.Placeno)} enterDelay={300} enterNextDelay={300}>
    //                                                             <Grid item xs={2}>
    //                                                                 <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight, textAlign:"right" }} primary={formatter.format(racunDetalji.Placeno)} ></ListItemText>
    //                                                             </Grid>
    //                                                         </Tooltip>
    //                                                         <Tooltip title={formatter.format(racunDetalji.Ostalo)} enterDelay={300} enterNextDelay={300}>
    //                                                             <Grid item xs={2}>
    //                                                                 <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight, textAlign:"right" }} primary={formatter.format(racunDetalji.Ostalo)} ></ListItemText>
    //                                                             </Grid>
    //                                                         </Tooltip>
    //                                                         <Tooltip title={racun.Napomena ? racun.Napomena: ''} enterDelay={300} enterNextDelay={300}>
    //                                                             <Grid item xs={2}>
    //                                                                 <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={racun.Napomena} ></ListItemText>
    //                                                             </Grid>
    //                                                         </Tooltip>
    //                                                     </Grid>
    //                                                 </Grid>
    //                                                 <Tooltip title={racun.IzvezenaDatotekaNaziv} enterDelay={300} enterNextDelay={300} sx={{ display:{xs:"none", sm:"none", md:"none" , lg:"none", xl:"block"} }}>
    //                                                     <Grid item xs={1}>
    //                                                         <ListItemIcon style={{display:"flex", justifyContent:"center"}}>
    //                                                             {racun.IzvezenaDatotekaID ? (
    //                                                                 <CheckBoxIcon fontSize="small" style={{marginTop:"3px"}} />
    //                                                             ) : (
    //                                                                 <CheckBoxOutlineBlankIcon fontSize="small" style={{marginTop:"3px"}} />
    //                                                             )}
    //                                                         </ListItemIcon>
    //                                                     </Grid>
    //                                                 </Tooltip>
    //                                             </Grid>
    //                                         </Grid>
    //                                         {!isLast ? (
    //                                             <Grid item xs={12}>
    //                                                 <Divider></Divider>
    //                                             </Grid>
    //                                         ) : null}
    //                                     </Grid>
    //                                 </ListItem>

}

export default React.memo(PretragaEvidencijeListItem, (oldProps, newProps) => { 
    return oldProps.fontWeight === newProps.fontWeight
        && oldProps.item.Loaded === newProps.item.Loaded
});