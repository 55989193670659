import { Menu } from "antd";
import React from "react";
import { UserOutlined, DownOutlined, PercentageOutlined, EditOutlined, LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";
import { Row, Dropdown, Divider, Button, Modal, Form, Input, Popconfirm, Select, InputNumber, Switch } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import Util from "../util/util";

const { SubMenu } = Menu;

class MainMenuMobile extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
    }

    logout = () => {
        Util.logout();
        window.location = "/#/login";
    };

    setOpen = (data) => {
        this.setState({ isOpen: data });
        if (this.props.toggle) {
            this.props.toggle(data);
        }
    };

    onClick = (data) => {
        this.setState({ isOpen: false });
        if (data === "evidencija") {
            window.location = "/#/evidencija";
        } else if (data === "zadaci") {
            window.location = "/#/zadaci";
        } else if (data === "odjava") {
            Util.logout();
            window.location = "/#/login";
        }
    };

    render() {
        let isLoggedIn = this.props.isLoggedIn;
        let roles = [];
        let cookie = Util.getCookie("roles");
        if (cookie) roles = cookie.split(",");

        let isMobileDevice = Util.isMobileDevice();
        let standarniUpisnik = roles.includes("1");
        let administratorProjekata = roles.includes("2");
        let administratorKorisnika = roles.includes("4");
        let voditelj = roles.includes("8");

        const menu = (
            <Menu>
                {/* <Menu.Item
                    key="mbm0"
                    onClick={() => {
                        window.location = "#/passwordchange";
                    }}
                >
                    Promjena lozinke
                </Menu.Item> */}
                {/* <Menu.Divider /> */}
                <Menu.Item
                    key="mbm1"
                    onClick={() => {
                        Util.logout();
                    }}
                >
                    Odjava
                </Menu.Item>
            </Menu>
        );

        return (
            <Row style={{ display: "block", backgroundColor: "#2c3742", color: "white", textAlign: "right!important" }}>
                {/* {isLoggedIn && (
                    <div style={{ margin: "10px", position: "fixed", textAlign:"right", backgroundColor: "#2c3742", color: "#f0f2f5", fontWeight: "bold" }}>
                        <Dropdown onClick={() => {this.setState({isOpen: false})}} overlay={menu} trigger={["click"]}>
                            <div>
                                <UserOutlined></UserOutlined> {this.props.username || ""}
                            </div>
                        </Dropdown>
                    </div>
                )} */}
                {isLoggedIn ? (
                    <div style={{ margin: "10px", display:"flex", justifyContent:"space-between", backgroundColor: "#2c3742", color: "#f0f2f5", fontWeight: "bold" }}>
                        <span style={{display:"flex"}}>
                            <span className="hamby">
                                <Hamburger toggled={this.state.isOpen} toggle={this.setOpen} size={25} direction="right" />
                            </span>
                            <span style={{fontSize:"18pt", display:"flex", alignItems:"center"}}>
                                Chato
                            </span>
                        </span>
                        <Dropdown onClick={() => {this.setState({isOpen: false})}} overlay={menu} trigger={["click"]}>
                            <span style={{display:"flex", alignItems:"center"}}>
                                <UserOutlined></UserOutlined> {this.props.username || ""}
                            </span>
                        </Dropdown>
                    </div>
                ) : <div style={{ margin: "10px", fontSize:"18pt", backgroundColor: "#2c3742", color: "#f0f2f5", fontWeight: "bold" }}>
                        Chato
                    </div>
                }
                {/* {isLoggedIn && (
                    <span style={{width:"50vw"}}>
                        <UserOutlined></UserOutlined> {this.props.username || ""}
                    </span>
                )} */}
                {this.state.isOpen && (
                    <div className="hamby-content" >
                        {isLoggedIn ? (
                            <Row onClick={() => this.onClick("evidencija")} style={{height:"3.5em", display:"flex", alignItems:"center", backgroundColor: !window.location.href.includes("zadaci") ? "#CCCCFF" : "#2c3742"}}>
                                <EditIcon style={{marginLeft:"10px", marginRight:"10px"}}></EditIcon>
                                <Link className="hamby-item" style={{fontSize:"16pt", color:"white"}} to="/evidencija">
                                    Evidencija
                                </Link>
                            </Row>
                        ) : null}
                        {isLoggedIn ? (
                            <Row onClick={() => this.onClick("zadaci")} style={{height:"3.5em", display:"flex", alignItems:"center", backgroundColor: window.location.href.includes("zadaci") ? "#CCCCFF" : "#2c3742"}}>
                                <TaskAltIcon style={{marginLeft:"10px", marginRight:"10px"}}></TaskAltIcon>
                                <Link className="hamby-item" style={{fontSize:"16pt", color:"white"}} to="/zadaci">
                                    Zadaci
                                </Link>
                            </Row>
                        ) : null}
                    </div>
                )}
            </Row>
        );
    }
}
export default MainMenuMobile;
