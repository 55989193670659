import { Alert, Autocomplete, Box, Button, Checkbox, ClickAwayListener, Divider, FormControl, FormControlLabel, FormLabel, getFormGroupUtilityClass, Grid, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Modal, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import api from "../util/api";
import globalContext from "./globalContext";
import AddEditDelForm from "./addEditDelForm";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import RacunForm from "./racunForm";
import OdobrenjeZaduzenjeForm from "./odobrenjeZaduzenjeForm";
import FakturiranjeForm from "./fakturiranjeForm";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AlertDialog from "./alertdialog";
import BlockIcon from '@mui/icons-material/Block';
import StornoForm from "./stornoForm";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import DeleteIcon from '@mui/icons-material/Delete';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GroupsIcon from '@mui/icons-material/Groups';
import database from "../util/database";
import RacuniIzvjestajBrisanje from "./racuniBrisanjeIzvjestaj";
import PrintIcon from '@mui/icons-material/Print';
import auth_Header from '../util/authHeader'
import IspisRacunaISpecifikacijaForm from "./ispisRacunaISpecifikacijaForm";
import IosShareIcon from '@mui/icons-material/IosShare';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import OsvjeziAdresuAlert from "./osvjeziAdresuAlert";
import dateFormat, { masks } from "dateformat";
import ErrorIcon from '@mui/icons-material/Error';

const periodOptions = [
    { Naziv: "Svi", Vrijednost: 1},
    { Naziv: "Za godinu", Vrijednost: 2},
    { Naziv: "Za mjesec", Vrijednost: 3},
    { Naziv: "Za datum", Vrijednost: 4},
    { Naziv: "Za razdoblje", Vrijednost: 5}
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    // minHeight: '80%',
    maxHeight: '99%',
    minWidth: '80%',
    maxWidth: '95%'
};

const styleDelete = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6
};

function Racuni(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);
    const zabranjenoFakturiranje = !global.roles || !global.roles.includes("16");

    const klijentiPredmeti = props.klijentiPredmeti || {
        Klijenti : [],
        Predmeti: [],
        KlijentiLastUpdate : null,
        PredmetiLastUpdate : null
    };
    const loadKlijentiAndPredmeti = props.loadKlijentiAndPredmeti;

    const [loading, setLoading] = useState(true);
    const [ulazni, setUlazni] = useState(false);
    const [izlazni, setIzlazni] = useState(true);
    const [domaci, setDomaci] = useState(true);
    const [strani, setStrani] = useState(true);
    const [fakturirani, setFakturirani] = useState(true);
    const [nefakturirani, setNefakturirani] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const filterOpened = Boolean(anchorEl);
    const [anchorSortEl, setAnchorSortEl] = useState(null);
    const sortOpened = Boolean(anchorSortEl);
    const [anchorAddEl, setAnchorAddEl] = useState(null);
    const addOptionsOpened = Boolean(anchorAddEl);
    const [grupe, setGrupe] = useState([]);
    const [grupa, setGrupa] = useState(null);
    const [klijent, setKlijent] = useState(null);
    const [racuni, setRacuni] = useState([]);
    const [currentRacun, setCurrentRacun] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [datumOd, setDatumOd] = useState(new Date());
    const [datumDo, setDatumDo] = useState(new Date());
    const [mjesec, setMjesec] = useState(new Date());
    const [datum, setDatum] = useState(new Date());
    const [godina, setGodina] = useState(new Date());
    const [periodOption, setPeriodOption] = useState(periodOptions.at(2));
    const [racunFormOpened, setRacunFormOpened] = useState(false);
    const [formRacun, setFormRacun] = useState(null);
    const [canceled, setCanceled] = useState(true);
    const [formRacunTipRacunaID, setFormRacunTipRacunaID] = useState(0);
    const [formRacunVrstaRacuna, setFormRacunVrstaRacuna] = useState(0);
    const [vrsteRacuna, setVrsteRacuna] = useState([]);
    const [tipUpisaRacunaID, setTipUpisaRacunaID] = useState(1);
    const [fakturiranjeFormOpened, setFakturiranjeFormOpened] = useState(false);
    const [fakturiranjeFormRacun, setFakturiranjeFormRacun] = useState(null);
    const [fakturiranjeFormRacuni, setFakturiranjeFormRacuni] = useState([]);
    const [odveziAlert, setOdveziAlert] = useState(false);
    const [odveziID, setOdveziID] = useState(0);
    const [stornoFormOpened, setStornoFormOpened] = useState(false);
    const [stornoFormRacun, setStornoFormRacun] = useState(null);
    const [prebaciURacunPonuda, setPrebaciURacunPonuda] = useState(null);
    const [prebaciURacunAlert, setPrebaciURacunAlert] = useState(false);
    const [postojeAktivnosti, setPostojeAktivnosti] = useState(false);
    const [postojeTroskovi, setPostojeTroskovi] = useState(false);
    const [vratiAktivnosti, setVratiAktivnosti] = useState(true);
    const [vratiTroskove, setVratiTroskove] = useState(true);
    const [deleteTekst, setDeleteTekst] = useState("");
    const [racunForDeleteID, setRacunForDeleteID] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [opcijaSortiranja, setOpcijaSortiranja] = useState(2);  // brojevi redom kao u padajucoj listi "sortiraj", pocevsi od 1
    const [sortiranjeUzlazno, setSortiranjeUzlazno] = useState(true);
    const [kopirajAlert, setKopirajAlert] = useState(false);
    const [kopirajRacunID, setKopirajRacunID] = useState(0);
    const [replicirajAlert, setReplicirajAlert] = useState(false);
    const [replicirajRacunID, setReplicirajRacunID] = useState(0);
    const [brisanjeRacunaIzvjestajOpened, setBrisanjeRacunaIzvjestajOpened] = useState(false);
    const [brisanjeRacunaIzvjestajData, setBrisanjeRacunaIzvjestajData] = useState(null);
    const [forceListItemToReRender, setForceListItemToReRender] = useState(new Date());
    const [oznaceni, setOznaceni] = useState([]);
    const [fakturirajDisabled, setFakturirajDisabled] = useState(true);
    const [odveziDisabled, setOdveziDisabled] = useState(true);
    const [tipNaplatnostiNaplatnoBoja, setTipNaplatnostiNaplatnoBoja] = useState("");
    const [tipNaplatnostiNenaplatnoBoja, setTipNaplatnostiNenaplatnoBoja] = useState("");
    const [tipAlgoritmaKontroleOdvezivanjaID, setTipAlgoritmaKontroleOdvezivanjaID] = useState(4);
    const [ispisFormOpened, setIspisFormOpened] = useState(false);
    const [exportDisabled, setExportDisabled] = useState(false);
    const [osvjeziAdresuAlert, setOsvjeziAdresuAlert] = useState(false);
    const [moguceFakturiratiRacuneSaSatnicamaBezCijene, setMoguceFakturiratiRacuneSaSatnicamaBezCijene] = useState(true);
    const [sviKlijenti, setSviKlijenti] = useState([]);
    const [prikaziSveKlijente, setPrikaziSveKlijente] = useState(null);
    const [replicirajDisabled, setReplicirajDisabled] = useState(true);
    const [kopirajDisabled, setKopirajDisabled] = useState(true);
    const [prebaciURacunDisabled, setPrebaciURacunDisabled] = useState(true);
    const [stornirajDisabled, setStornirajDisabled] = useState(true);
    const [logoutAlert, setLogoutAlert] = useState(false);
    const [borderStyle, setBorderStyle] = useState("2px solid #000");
    const [neuspjesnaFiskalizacija, setNeuspjesnaFiskalizacija] = useState(false);
    const [filterLabel, setFilterLabel] = useState(""); 
    const [errorMessage, setErrorMessage] = useState(null);
    const [grupeZaReplikaciju, setGrupeZaReplikaciju] = useState([]);
    const [grupaZaReplikaciju, setGrupaZaReplikaciju] = useState(null);
    const [vrstaRacuni, setVrstaRacuni] = useState(true);
    const [vrstaPonude, setVrstaPonude] = useState(true);
    const [ispisDisabled, setIspisDisabled] = useState(true);

    const ref = useRef(0);

    useConstructor(() => {

        if(klijentiPredmeti && klijentiPredmeti.Klijenti && klijentiPredmeti.Klijenti.filter(x=>x.KlijentID == 0).length == 0){
            let sviKlijentiOption = {};
            sviKlijentiOption.Naziv = 'Svi klijenti';
            sviKlijentiOption.KlijentID = 0;
            sviKlijentiOption.Broj = 0;
            sviKlijentiOption.GrupeIDs = [];
            let newKlijenti = JSON.parse(JSON.stringify(klijentiPredmeti.Klijenti));
            newKlijenti.unshift(sviKlijentiOption);
            setSviKlijenti(newKlijenti);
        }


        // if (klijentiPredmeti.KlijentiLastUpdate != "" && klijentiPredmeti.KlijentiLastUpdate != null && klijentiPredmeti.PredmetiLastUpdate != "" && klijentiPredmeti.PredmetiLastUpdate != null) {
        //     let resource = {
        //         FirmaID : global.user.firmaID,
        //         KlijentiLastUpdate : klijentiPredmeti.KlijentiLastUpdate,
        //         PredmetiLastUpdate : klijentiPredmeti.PredmetiLastUpdate
        //     }
        //     api.post("firma/checkifklijentiorpredmetiunchanged", resource, checkIfKlijentiOrPredmetiUnchangedSuccess, checkIfKlijentiOrPredmetiUnchangedFail);
        // }

        if (klijentiPredmeti.KlijentiLastUpdate != "" && klijentiPredmeti.KlijentiLastUpdate != null && klijentiPredmeti.PredmetiLastUpdate != "" && klijentiPredmeti.PredmetiLastUpdate != null) {
            let resource = {
                FirmaID : global.user.firmaID,
                KlijentiLastUpdate : klijentiPredmeti.KlijentiLastUpdate,
                PredmetiLastUpdate : klijentiPredmeti.PredmetiLastUpdate,
                KorisnikID : global.user.korisnikID
            }
            api.post("firma/checkeverything", resource, checkEverythingSuccess, checkEverythingFail);
        }

    });

    function checkIfKlijentiOrPredmetiUnchangedSuccess(data) {
        if (data.KlijentiChanged || data.PredmetiChanged) {
            loadKlijentiAndPredmeti(global.user.firmaID, loadInfo, data.KlijentiChanged, data.PredmetiChanged);
        } else {
            loadInfo();
        }
    }

    function checkIfKlijentiOrPredmetiUnchangedFail() {

    }

    function startLogout(){
        setLogoutAlert(false);
        Util.logout();
        window.location = "/#/login";
    }

    function checkEverythingSuccess(data) {
        if(data && (data.UserAccountValid == false || data.LogoutRequired)){
            setLoading(false);
            setLogoutAlert(true);
            return;          
        }

        if (data.CheckIfKlijentiOrPredmetiUnchangedResource == false) {
            loadKlijentiAndPredmeti(global.user.firmaID, loadInfo, data.KlijentiChanged, data.PredmetiChanged);
        } else {
            loadInfo();
        }
    }

    function checkEverythingFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }


    function loadInfo(loadedKlijenti) {
        if (loadedKlijenti) {
            let sviKlijentiOption = {};
            sviKlijentiOption.Naziv = 'Svi klijenti';
            sviKlijentiOption.KlijentID = 0;
            sviKlijentiOption.Broj = 0;
            sviKlijentiOption.GrupeIDs = [];
            let newKlijenti = JSON.parse(JSON.stringify(loadedKlijenti));
            newKlijenti.unshift(sviKlijentiOption);
            setSviKlijenti(newKlijenti);
        }
        //ovaj poziv koristi se zapravo za svašta - uglavnom za parametre firme
        api.get("racun/getklijentigrupe/" + global.user.firmaID, loadInfoSuccess, loadInfoFail);
    }

    function loadInfoSuccess(data) {
        setGrupe(data.Grupe);
        setVrsteRacuna(data.VrsteRacuna);
        setTipNaplatnostiNaplatnoBoja(data.TipNaplatnostiNaplatnoBoja);
        setTipNaplatnostiNenaplatnoBoja(data.TipNaplatnostiNenaplatnoBoja);
        setTipAlgoritmaKontroleOdvezivanjaID(data.TipAlgoritmaKontroleOdvezivanjaID);
        setMoguceFakturiratiRacuneSaSatnicamaBezCijene(data.MoguceFakturiratiRacuneSaSatnicamaBezCijene);
        setPrikaziSveKlijente(data.PrikaziSveKlijente);
        api.get("racun/getpreferredfilterracunainfo/" + global.user.korisnikID, (d) => loadDefaultFilterRacunaSuccess(d, data.PrikaziSveKlijente, data.Grupe), loadDefaultFilterRacunaFail);
    }
    
    function loadInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        if (prikaziSveKlijente) {
            setKlijent(sviKlijenti.find(k => k.KlijentID == 0));
        }
    }, [prikaziSveKlijente, sviKlijenti]);

    function loadDefaultFilterRacunaSuccess(data, initialFilter, grupeList) {
        if (data) {
            setDomaci(data.Domaci);
            setStrani(data.Strani);
            setUlazni(data.Ulazni);
            setIzlazni(!data.Ulazni);
            setVrstaRacuni(data.VrstaRacuni);
            setVrstaPonude(data.VrstaPonude);
            setFakturirani(data.Fakturirani);
            setNefakturirani(data.Nefakturirani);
            setPeriodOption(periodOptions.at(data.PeriodType - 1));
            setDatumOd(data.DatumOd);
            setDatumDo(data.DatumDo);
            setMjesec(data.DatumOd);
            setGodina(data.DatumOd);
            setDatum(data.DatumOd);
            if (!initialFilter) {
                let filterLabelNew = " Odabrani filter: ";
                if (data.VrstaRacuni) {
                    filterLabelNew += "računi, ";
                }
                if (data.VrstaPonude) {
                    filterLabelNew += "ponude, ";
                } 
                if (data.Domaci) {
                    filterLabelNew += "domaći, ";
                }
                if (data.Strani) {
                    filterLabelNew += "strani, ";
                }
                if (data.Ulazni) {
                    filterLabelNew += "ulazni, ";
                }
                if (data.Izlazni) {
                    filterLabelNew += "izlazni, ";
                }
                if (data.Fakturirani) {
                    filterLabelNew += "fakturirani, ";
                }
                if (data.Nefakturirani) {
                    filterLabelNew += "nefakturirani, ";
                }
                switch (data.PeriodType) {
                    case 1:
                        filterLabelNew += "bilokad";
                        break;
                    case 2:
                        filterLabelNew += "za godinu " + dateFormat(data.DatumOd, "yyyy.");
                        break;
                    case 3:
                        filterLabelNew += "za mjesec " + dateFormat(data.DatumOd, "mm.yyyy.");
                        break;
                    case 4:
                        filterLabelNew += "za datum " + dateFormat(data.DatumOd, "dd.mm.yyyy.");
                        break;
                    case 5:
                        filterLabelNew += "za razdoblje " + dateFormat(data.DatumOd, "dd.mm.yyyy.") + " - " + dateFormat(data.DatumDo, "dd.mm.yyyy.");
                        break;
                }
                setFilterLabel(filterLabelNew);   
            }
            setOpcijaSortiranja(data.OpcijaSortiranja);
            setSortiranjeUzlazno(data.SortiranjeUzlazno);
            setGrupa(grupeList.find(g => g.GrupaID == data.GrupaID));
            if (initialFilter || data.GrupaID) {
                data = {
                    ...data,
                    KlijentID: 0
                };
                filter(data, data.OpcijaSortiranja, data.SortiranjeUzlazno);
            } else {
                setLoading(false);
            }
        } else {
            if (initialFilter) {
                filter(null, null, null);
            } else {
                setLoading(false);
            }
        }
    }

    function loadDefaultFilterRacunaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }
    
    function handleFilterClick(event) {
        setAnchorEl(event.currentTarget);
    };

    function handleFilterClose() {
        setAnchorEl(null);
    };
    
    function handleSortClick(event) {
        setAnchorSortEl(event.currentTarget);
    };

    function handleSortClose() {
        setAnchorSortEl(null);
    };

    function handleAddClick(event) {
        setAnchorAddEl(event.currentTarget);
    };

    function handleAddClose(event) {
        setAnchorAddEl(null);
    };

    function handleUlazniClick() {
        setUlazni(true);
        setIzlazni(false);
    }

    function handleIzlazniClick() {
        setUlazni(false);
        setIzlazni(true);
    }

    function handleFakturiraniClick() {
        setFakturirani(true);
        setNefakturirani(false);
    }

    function handleNefakturiraniClick() {
        setFakturirani(false);
        setNefakturirani(true);
    }

    function handleFakturiraniNefakturiraniClick() {
        setFakturirani(true);
        setNefakturirani(true);
    }

    function handleDomaciClick() {
        setDomaci(true);
        setStrani(false);
    }

    function handleStraniClick() {
        setDomaci(false);
        setStrani(true);
    }

    function handleDomaciStraniClick() {
        setDomaci(true);
        setStrani(true);
    }

    function handleRacuniClick() {
        setVrstaRacuni(true);
        setVrstaPonude(false);
    }

    function handlePonudeClick() {
        setVrstaRacuni(false);
        setVrstaPonude(true);
    }

    function handleRacuniPonudeClick() {
        setVrstaRacuni(true);
        setVrstaPonude(true);
    }

    function filter(filterResource, defSortiranjeOpcija, defSortiranjeUzlazno) {
        setLoading(true);
        var resourceDatumOd;
        let filterLabelNew = " Odabrani filter: ";
        switch (periodOption.Vrijednost) {
            case 1:
                resourceDatumOd = datumOd;
                break;
            case 2:
                resourceDatumOd = godina;
                break;
            case 3:
                resourceDatumOd = mjesec;
                break;
            case 4:
                resourceDatumOd = datum;
                break;
            case 5:
                resourceDatumOd = datumOd;
                break;
        }
        let resource;
        if (filterResource) {
            if (filterResource.VrstaRacuni) {
                filterLabelNew += "računi, ";
            }
            if (filterResource.VrstaPonude) {
                filterLabelNew += "ponude, ";
            } 
            if (filterResource.Domaci) {
                filterLabelNew += "domaći, ";
            }
            if (filterResource.Strani) {
                filterLabelNew += "strani, ";
            }
            if (filterResource.Ulazni) {
                filterLabelNew += "ulazni, ";
            }
            if (filterResource.Izlazni) {
                filterLabelNew += "izlazni, ";
            }
            if (filterResource.Fakturirani) {
                filterLabelNew += "fakturirani, ";
            }
            if (filterResource.Nefakturirani) {
                filterLabelNew += "nefakturirani, ";
            }
            switch (filterResource.PeriodType) {
                case 1:
                    filterLabelNew += "bilokad";
                    break;
                case 2:
                    filterLabelNew += "za godinu " + dateFormat(filterResource.DatumOd, "yyyy.");
                    break;
                case 3:
                    filterLabelNew += "za mjesec " + dateFormat(filterResource.DatumOd, "mm.yyyy.");
                    break;
                case 4:
                    filterLabelNew += "za datum " + dateFormat(filterResource.DatumOd, "dd.mm.yyyy.");
                    break;
                case 5:
                    filterLabelNew += "za razdoblje " + dateFormat(filterResource.DatumOd, "dd.mm.yyyy.") + " - " + dateFormat(filterResource.DatumDo, "dd.mm.yyyy.");
                    break;
            }
            resource = {
                ...filterResource, 
                FirmaID : global.user.firmaID
            };
        } else {
            if (vrstaRacuni) {
                filterLabelNew += "računi, ";
            }
            if (vrstaPonude) {
                filterLabelNew += "ponude, ";
            } 
            if (domaci) {
                filterLabelNew += "domaći, ";
            }
            if (strani) {
                filterLabelNew += "strani, ";
            }
            if (ulazni) {
                filterLabelNew += "ulazni, ";
            }
            if (izlazni) {
                filterLabelNew += "izlazni, ";
            }
            if (fakturirani) {
                filterLabelNew += "fakturirani, ";
            }
            if (nefakturirani) {
                filterLabelNew += "nefakturirani, ";
            }
            switch (periodOption.Vrijednost) {
                case 1:
                    filterLabelNew += "bilokad";
                    break;
                case 2:
                    filterLabelNew += "za godinu " + dateFormat(godina, "yyyy.");
                    break;
                case 3:
                    filterLabelNew += "za mjesec " + dateFormat(mjesec, "mm.yyyy.");
                    break;
                case 4:
                    filterLabelNew += "za datum " + dateFormat(datum, "dd.mm.yyyy.");
                    break;
                case 5:
                    filterLabelNew += "za razdoblje " + dateFormat(datumOd, "dd.mm.yyyy.") + " - " + dateFormat(datumDo, "dd.mm.yyyy.");
                    break;
            }
            resource = {
                FirmaID : global.user.firmaID,
                Fakturirani : fakturirani,
                Nefakturirani : nefakturirani,
                Domaci : domaci,
                Strani : strani,
                Ulazni : ulazni,
                Izlazni : izlazni,
                VrstaRacuni : vrstaRacuni,
                VrstaPonude : vrstaPonude,
                DatumOd : resourceDatumOd,
                DatumDo : datumDo,
                PeriodType : periodOption.Vrijednost,
                KlijentID : klijent ? klijent.KlijentID : null,
                GrupaID : grupa ? grupa.GrupaID : null
            };
        }
        setFilterLabel(filterLabelNew);
        api.post("racun/filter", resource, data => filterSuccess(data, defSortiranjeOpcija, defSortiranjeUzlazno), filterFail);
    }

    function filterSuccess(data, defSortiranjeOpcija, defSortiranjeUzlazno) {
        setForceListItemToReRender(new Date());
        setRacuni(data);
        setNeuspjesnaFiskalizacija(data.filter(r => r.NeuspjesnaFiskalizacija).length > 0);
        if (defSortiranjeOpcija != null && defSortiranjeUzlazno != null) {
            switch (defSortiranjeOpcija) {
                case 1:
                    sortRacuni("vrsta", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 2:
                    sortRacuni("broj", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 3:
                    sortRacuni("klijent", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 4:
                    sortRacuni("datum", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 5:
                    sortRacuni("porez", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 6:
                    sortRacuni("ukupno", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 9:
                    sortRacuni("napomena", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 10:
                    sortRacuni("id", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
            }
        } else {
            switch (opcijaSortiranja) {
                case 1:
                    sortRacuni("vrsta", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 2:
                    sortRacuni("broj", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 3:
                    sortRacuni("klijent", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 4:
                    sortRacuni("datum", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 5:
                    sortRacuni("porez", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 6:
                    sortRacuni("ukupno", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 9:
                    sortRacuni("napomena", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
                case 10:
                    sortRacuni("id", data, defSortiranjeUzlazno != null ? defSortiranjeUzlazno : sortiranjeUzlazno);
                    break;
            }
        }
        setLoading(false);
        setAnchorEl(false);
    }

    function filterFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleGrupaChange(e,v) {
        setGrupa(v);

        if(v){
            let grupa = sviKlijenti.filter(x => x.GrupeIDs.includes(v.GrupaID));
            
            // if(grupa.length > 0){
            //     setKlijent(grupa[0]);
            // }
            // else{
            //     setKlijent(null);

            // }

            if (klijent && klijent.GrupeIDs.includes(v.GrupaID)) {
                setKlijent(null);
            }
            
            var resourceDatumOd;
            switch (periodOption.Vrijednost) {
                case 1:
                    resourceDatumOd = datumOd;
                    break;
                case 2:
                    resourceDatumOd = godina;
                    break;
                case 3:
                    resourceDatumOd = mjesec;
                    break;
                case 4:
                    resourceDatumOd = datum;
                    break;
                case 5:
                    resourceDatumOd = datumOd;
                    break;
            }
            let resource = {
                FirmaID : global.user.firmaID,
                Fakturirani : fakturirani,
                Nefakturirani : nefakturirani,
                Domaci : domaci,
                Strani : strani,
                Ulazni : ulazni,
                Izlazni : izlazni,
                VrstaRacuni : vrstaRacuni,
                VrstaPonude : vrstaPonude,
                DatumOd : resourceDatumOd,
                DatumDo : datumDo,
                PeriodType : periodOption.Vrijednost,
                GrupaID : v.GrupaID
            };
            filter(resource, null, null);
        } else if (klijent) {
            var resourceDatumOd;
            switch (periodOption.Vrijednost) {
                case 1:
                    resourceDatumOd = datumOd;
                    break;
                case 2:
                    resourceDatumOd = godina;
                    break;
                case 3:
                    resourceDatumOd = mjesec;
                    break;
                case 4:
                    resourceDatumOd = datum;
                    break;
                case 5:
                    resourceDatumOd = datumOd;
                    break;
            }
            let resource = {
                FirmaID : global.user.firmaID,
                Fakturirani : fakturirani,
                Nefakturirani : nefakturirani,
                Domaci : domaci,
                Strani : strani,
                Ulazni : ulazni,
                Izlazni : izlazni,
                VrstaRacuni : vrstaRacuni,
                VrstaPonude : vrstaPonude,
                DatumOd : resourceDatumOd,
                DatumDo : datumDo,
                PeriodType : periodOption.Vrijednost,
                KlijentID : klijent.KlijentID,
                GrupaID : null
            };
            filter(resource, null, null);
        } else {
            setRacuni([]);
        }
    }

    function handleKlijentChange(e,v) {
        setKlijent(v);
    
        var resourceDatumOd;
        switch (periodOption.Vrijednost) {
            case 1:
                resourceDatumOd = datumOd;
                break;
            case 2:
                resourceDatumOd = godina;
                break;
            case 3:
                resourceDatumOd = mjesec;
                break;
            case 4:
                resourceDatumOd = datum;
                break;
            case 5:
                resourceDatumOd = datumOd;
                break;
        }
        if (v) {
            let resource = {
                FirmaID : global.user.firmaID,
                Fakturirani : fakturirani,
                Nefakturirani : nefakturirani,
                Domaci : domaci,
                Strani : strani,
                Ulazni : ulazni,
                Izlazni : izlazni,
                VrstaRacuni : vrstaRacuni,
                VrstaPonude : vrstaPonude,
                DatumOd : resourceDatumOd,
                DatumDo : datumDo,
                PeriodType : periodOption.Vrijednost,
                KlijentID : v.KlijentID
            };
            filter(resource, null, null);
        } else {
            if (grupa) {
                let resource = {
                    FirmaID : global.user.firmaID,
                    Fakturirani : fakturirani,
                    Nefakturirani : nefakturirani,
                    Domaci : domaci,
                    Strani : strani,
                    Ulazni : ulazni,
                    Izlazni : izlazni,
                    VrstaRacuni : vrstaRacuni,
                    VrstaPonude : vrstaPonude,
                    DatumOd : resourceDatumOd,
                    DatumDo : datumDo,
                    PeriodType : periodOption.Vrijednost,
                    GrupaID : grupa.GrupaID
                };
                filter(resource, null, null);
            } else {
                setRacuni([]);
                setNeuspjesnaFiskalizacija(false);
            }
        }
    }

    function openForm(type) {

    }

    function handleDatumOdChange(v) {
        setDatumOd(v);
    }

    function handleDatumDoChange(v) {
        setDatumDo(v);
    }

    function edit() {
        let racunForEdit = racuni.find(r => r.RacunID == oznaceni.at(0));
        if (racunForEdit) {
            setFormRacun(racunForEdit);
            setTipUpisaRacunaID(racunForEdit.VrstaRacunaID != 7 ? racunForEdit.VrstaRacuna.TipUpisaRacunaID : 1);
            setFormRacunVrstaRacuna(racunForEdit.VrstaRacuna);
            setRacunFormOpened(true);
        }
    }

    function odvezi() {
        setLoading(true);
        api.post("fakturiranje/odvezi/", oznaceni, odveziSuccess, odveziFail);
    }

    function odveziSuccess() {
        setOdveziAlert(false);
        filter(null, null, null);
    }

    function odveziFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function storno() {
        setStornoFormRacun(currentRacun);
        setStornoFormOpened(true);
    }

    // function prebaciURacun() {
    //     setLoading(true);
    //     api.get("racun/prebaciponuduuracun/" + currentRacun.RacunID, prebaciURacunSuccess, prebaciURacunFail);
    // }

    function prebaciURacun() {
        setLoading(true);
        api.post("racun/prebaciponudeuracun/", oznaceni, prebaciURacunSuccess, prebaciURacunFail);
    }

    function prebaciURacunSuccess() {
        setPrebaciURacunAlert(false);
        filter(null, null, null);
    }

    function prebaciURacunFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setPrebaciURacunAlert(false);
        setLoading(false);
    }


    function deleteRacun() {
        let racunForDelete = racuni.find(r => r.RacunID == oznaceni.at(0));
        if (racunForDelete) {
            setRacunForDeleteID(racunForDelete.RacunID);
            if (racunForDelete.VrstaRacunaID != 7) {
                racunForDelete.RacunskeStavke.forEach(racunskaStavka => {
                    if (racunskaStavka.TipRacunskeStavkeID == 1) {
                        racunskaStavka.StavkeRacunskeStavke.forEach(stavkaRacunskeStavke => {
                            if (stavkaRacunskeStavke.EvidencijaDetaljID) {
                                if (stavkaRacunskeStavke.TipEvidencijeID == 1) {
                                    setPostojeAktivnosti(true);
                                    setVratiAktivnosti(true);
                                } else if (stavkaRacunskeStavke.TipEvidencijeID == 2) {
                                    setPostojeTroskovi(true);
                                    setVratiTroskove(true);
                                }
                            }
                        });
                    }
                });
            }
            if (racunForDelete.VrstaRacunaID == 1 || racunForDelete.VrstaRacunaID == 2) {
                setDeleteTekst("Želite li obrisati odabrani račun?");
            } else if (racunForDelete.VrstaRacunaID == 3) {
                setDeleteTekst("Želite li obrisati odabranu ponudu?");
            } else if (racunForDelete.VrstaRacunaID == 4) {
                setDeleteTekst("Želite li obrisati odabrano odobrenje?");
            } else if (racunForDelete.VrstaRacunaID == 5) {
                setDeleteTekst("Želite li obrisati odabrano zaduženje?");
            } else if (racunForDelete.VrstaRacunaID == 7) {
                setDeleteTekst("Želite li obrisati odabrani storno račun?");
            }
            setDeleteAlert(true);
        }
    }

    function deleteConfirmed() {
        let resource = {
            RacunID : racunForDeleteID,
            VratiAktivnostiUNeprenesenoStanje : postojeAktivnosti ? vratiAktivnosti : false,
            VratiTroskoveUNeprenesenoStanje : postojeTroskovi ? vratiTroskove : false
        }
        setLoading(true);
        api.post("racun/obrisi", resource, deleteSuccess, deleteFail);
    }

    function deleteCanceled() {
        setDeleteAlert(false);
        setPostojeAktivnosti(false);
        setPostojeTroskovi(false);
    }

    function deleteSuccess(data) {
        setDeleteAlert(false);
        setPostojeAktivnosti(false);
        setPostojeTroskovi(false);
        filter(null, null, null);
        setBrisanjeRacunaIzvjestajData(data);
        setBrisanjeRacunaIzvjestajOpened(true);
    }

    function deleteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
        setPostojeAktivnosti(false);
        setPostojeTroskovi(false);
    }

    function handleSearchChange(e) {
        setSearchText(e.target.value);
    }

    function compareStrings(a, b) {
        ;
        if ((a || "") > (b || "")) {
            return 1;
        } else if ((a || "") < (b || "")) {
            return -1;
        } else {
            return 0;
        }
    }

    function compareNumbers(a, b) {
        ;
        if ((a || 0) > (b || 0)) {
            return 1;
        } else if ((a || 0) < (b || 0)) {
            return -1;
        } else {
            return 0;
        }
    }

    function sortRacuni(field, filteredRacuni, defSortiranjeUzlazno) {
        ;
        setLoading(true);
        let sortiraniRacuni = filteredRacuni == null ? [...racuni] : [...filteredRacuni];
        let faktor = 1;
        if (field == "id") {
            if (defSortiranjeUzlazno != null) {
                if (!defSortiranjeUzlazno) {
                    faktor = -1;
                    setSortiranjeUzlazno(false);
                } else {
                    setSortiranjeUzlazno(true);
                }
            } else {
                if (opcijaSortiranja == 10) {
                    if (sortiranjeUzlazno) {
                        faktor = -1;
                        setSortiranjeUzlazno(false);
                    } else {
                        setSortiranjeUzlazno(true);
                    }
                } else {
                    setSortiranjeUzlazno(true);
                    setOpcijaSortiranja(10);
                }
            }
            sortiraniRacuni.sort((prvi, drugi) => faktor * (prvi.RacunID - drugi.RacunID));
        } else if (field == "vrsta") {
            if (defSortiranjeUzlazno != null) {
                if (!defSortiranjeUzlazno) {
                    faktor = -1;
                    setSortiranjeUzlazno(false);
                } else {
                    setSortiranjeUzlazno(true);
                }
            } else { 
                if (opcijaSortiranja == 1) {
                    if (sortiranjeUzlazno) {
                        faktor = -1;
                        setSortiranjeUzlazno(false);
                    } else {
                        setSortiranjeUzlazno(true);
                    }
                } else {
                    setSortiranjeUzlazno(true);
                    setOpcijaSortiranja(1);
                }
            }            
            sortiraniRacuni.sort((prvi, drugi) => faktor * compareStrings(prvi.VrstaRacuna.Naziv, drugi.VrstaRacuna.Naziv));
        } else if (field=="broj") {
            if (defSortiranjeUzlazno != null) {
                if (!defSortiranjeUzlazno) {
                    faktor = -1;
                    setSortiranjeUzlazno(false);
                } else {
                    setSortiranjeUzlazno(true);
                }
            } else { 
                if (opcijaSortiranja == 2) {
                    if (sortiranjeUzlazno) {
                        faktor = -1;
                        setSortiranjeUzlazno(false);
                    } else {
                        setSortiranjeUzlazno(true);
                    }
                } else {
                    setSortiranjeUzlazno(true);
                    setOpcijaSortiranja(2);
                }
            }
            sortiraniRacuni.sort((prvi, drugi) => faktor * compareStrings(prvi.Broj, drugi.Broj));
        } else if (field=="klijent") {
            if (defSortiranjeUzlazno != null) {
                if (!defSortiranjeUzlazno) {
                    faktor = -1;
                    setSortiranjeUzlazno(false);
                } else {
                    setSortiranjeUzlazno(true);
                }
            } else { 
                if (opcijaSortiranja == 3) {
                    if (sortiranjeUzlazno) {
                        faktor = -1;
                        setSortiranjeUzlazno(false);
                    } else {
                        setSortiranjeUzlazno(true);
                    }
                } else {
                    setSortiranjeUzlazno(true);
                    setOpcijaSortiranja(3);
                }
            }
            sortiraniRacuni.sort((prvi, drugi) => faktor * compareStrings(prvi.KlijentNaziv, drugi.KlijentNaziv));
        } else if (field == "datum") {
            if (defSortiranjeUzlazno != null) {
                if (!defSortiranjeUzlazno) {
                    faktor = -1;
                    setSortiranjeUzlazno(false);
                } else {
                    setSortiranjeUzlazno(true);
                }
            } else { 
                if (opcijaSortiranja == 4) {
                    if (sortiranjeUzlazno) {
                        faktor = -1;
                        setSortiranjeUzlazno(false);
                    } else {
                        setSortiranjeUzlazno(true);
                    }
                } else {
                    setSortiranjeUzlazno(true);
                    setOpcijaSortiranja(4);
                }
            }
            sortiraniRacuni.sort((prvi, drugi) => faktor * (new Date(prvi.Datum) - new Date(drugi.Datum)));
        } else if (field == "porez") {
            if (defSortiranjeUzlazno != null) {
                if (!defSortiranjeUzlazno) {
                    faktor = -1;
                    setSortiranjeUzlazno(false);
                } else {
                    setSortiranjeUzlazno(true);
                }
            } else { 
                if (opcijaSortiranja == 5) {
                    if (sortiranjeUzlazno) {
                        faktor = -1;
                        setSortiranjeUzlazno(false);
                    } else {
                        setSortiranjeUzlazno(true);
                    }
                } else {
                    setSortiranjeUzlazno(true);
                    setOpcijaSortiranja(5);
                }
            }
            sortiraniRacuni.sort((prvi, drugi) => faktor * (Util.getRacunDetalji(prvi).Porez - Util.getRacunDetalji(drugi).Porez));
        } else if (field == "ukupno") {
            if (defSortiranjeUzlazno != null) {
                if (!defSortiranjeUzlazno) {
                    faktor = -1;
                    setSortiranjeUzlazno(false);
                } else {
                    setSortiranjeUzlazno(true);
                }
            } else { 
                if (opcijaSortiranja == 6) {
                    if (sortiranjeUzlazno) {
                        faktor = -1;
                        setSortiranjeUzlazno(false);
                    } else {
                        setSortiranjeUzlazno(true);
                    }
                } else {
                    setSortiranjeUzlazno(true);
                    setOpcijaSortiranja(6);
                }
            }
            sortiraniRacuni.sort((prvi, drugi) => faktor * (Util.getRacunDetalji(prvi).Ukupno - Util.getRacunDetalji(drugi).Ukupno));
        } else if (field == "napomena") {
            if (defSortiranjeUzlazno != null) {
                if (!defSortiranjeUzlazno) {
                    faktor = -1;
                    setSortiranjeUzlazno(false);
                } else {
                    setSortiranjeUzlazno(true);
                }
            } else { 
                if (opcijaSortiranja == 9) {
                    if (sortiranjeUzlazno) {
                        faktor = -1;
                        setSortiranjeUzlazno(false);
                    } else {
                        setSortiranjeUzlazno(true);
                    }
                } else {
                    setSortiranjeUzlazno(true);
                    setOpcijaSortiranja(9);
                }
            }
            sortiraniRacuni.sort((prvi, drugi) => faktor * compareStrings(prvi.Napomena, drugi.Napomena));
        }
        setRacuni(sortiraniRacuni);
        let resource;
        if (field == "id") {
            resource = {
                OpcijaSortiranja : 10,
                SortiranjeUzlazno : faktor > 0
            };
        } else if (field == "vrsta") {
            resource = {
                OpcijaSortiranja : 1,
                SortiranjeUzlazno : faktor > 0
            };
        } else if (field=="broj") {
            resource = {
                OpcijaSortiranja : 2,
                SortiranjeUzlazno : faktor > 0
            };
        } else if (field=="klijent") {
            resource = {
                OpcijaSortiranja : 3,
                SortiranjeUzlazno : faktor > 0
            };
        } else if (field == "datum") {
            resource = {
                OpcijaSortiranja : 4,
                SortiranjeUzlazno : faktor > 0
            };
        } else if (field == "porez") {
            resource = {
                OpcijaSortiranja : 5,
                SortiranjeUzlazno : faktor > 0
            };
        } else if (field == "ukupno") {
            resource = {
                OpcijaSortiranja : 6,
                SortiranjeUzlazno : faktor > 0
            };
        } else if (field == "napomena") {
            resource = {
                OpcijaSortiranja : 9,
                SortiranjezUzlazno : faktor > 0
            };
        }
        api.post("racun/savesort", resource, () => setLoading(false), (data) => {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        });
    }

    function kopirajRacune() {
        setLoading(true);
        api.post("racun/kopiraj/", oznaceni, kopirajRacuneSuccess, kopirajRacuneFail);
    }

    function kopirajRacuneSuccess(data) {
        setKopirajAlert(false);
        filter(null, null, null);
    }

    function kopirajRacuneFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    // function replicirajNaGrupu() {
    //     setLoading(true);
    //     api.get("racun/repliciraj/" + replicirajRacunID, replicirajSuccess, replicirajFail);
    // }

    function replicirajNaGrupu() {
        setLoading(true);
        let resource = {
            RacunID: currentRacun.RacunID,
            GrupaID: grupaZaReplikaciju.GrupaID
        }
        api.post("racun/replicirajnagrupu/", resource, replicirajSuccess, replicirajFail);
    }

    function replicirajSuccess() {
        setReplicirajAlert(false);
        filter(null, null, null);
    }

    function replicirajFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleRacunClick (event, racunID, indexRacuna, lastIndexRacuna) {
        if (event.ctrlKey && !event.shiftKey) {
            if (oznaceni.includes(racunID)) {
                setOznaceni(oldOznaceni => oldOznaceni.filter(id => id !== racunID));
            } else {
                setOznaceni(oldOznaceni => [...oldOznaceni, racunID]);
            }
        } else if (event.shiftKey && !event.ctrlKey) {
            if (window.getSelection) {
                if (window.getSelection().empty) {  // Chrome
                  window.getSelection().empty();
                } else if (window.getSelection().removeAllRanges) {  // Firefox
                  window.getSelection().removeAllRanges();
                }
            } else if (document.selection) {  // IE?
                document.selection.empty();
            }
            let newOznaceni = [];
            if (indexRacuna > lastIndexRacuna) {
                for (let i = lastIndexRacuna; i <= indexRacuna; i++) {
                    newOznaceni.push(racuni.at(i).RacunID);
                }
            } else {
                for (let i = lastIndexRacuna; i >= indexRacuna; i--) {
                    newOznaceni.push(racuni.at(i).RacunID);
                }
            }
            setOznaceni(oldOznaceni => oldOznaceni.concat(newOznaceni));
        } else if (!event.ctrlKey && !event.shiftKey) {
            let newOznaceni = [];
            newOznaceni.push(racunID);
            ref.current = indexRacuna;
            setOznaceni(oldOznaceni => {
                let helpArray = oldOznaceni.filter(id => id == -1);
                return [...helpArray, racunID];
            });
        }
    };

    function postojeSatniceSNulom(currRacun){
        //ako je ova firma osjetljiva na satnice s nulom na način da nije moguće fakturirati račun ukoliko takve stavke postoje, onda vrati true, inače vrati false
        var brojac = 0;
        var BreakException = {};

        try{
            currRacun.RacunskeStavke.forEach(stavka => {
                if (stavka.TipRacunskeStavkeID == 1 && stavka.StavkaSeNaplacuje !== false) {
                    try{
                        stavka.StavkeRacunskeStavke.forEach(stavkaRacunskeStavke => {
                            if(stavkaRacunskeStavke.Iznos == 0 || stavkaRacunskeStavke.Vrijednost == 0){
                                //radi se o evidenciji koja ima isznos ili vrijednost jednaku nuli, pa treba provjeravati
                                if(stavkaRacunskeStavke.TipEvidencijeID && stavkaRacunskeStavke.TipEvidencijeID == 1){
                                    // radi se o aktivnosti -> nađimo da li se radi o aktivnosti po predmetu koji ima satnice
                                    var pr = klijentiPredmeti.Predmeti.find(p => p.PredmetID == stavkaRacunskeStavke.PredmetID);
                                    if(pr && pr.TipNaplateID != 3){
                                        //radi se o predmetu koji ima tip naplate različit od fiksno (satnica ili tarifa), pa mora imati cijenu
                                        throw BreakException;
                                    }
                                }
                                else{
                                    //radi se o trošku ili nije definirano - kako god nije dobro da je cijena jednaka nuli
                                    throw BreakException;
                                }    
                            }
                        });
                    } catch(e){
                        if (e === BreakException){
                            //imamo satnicu, tarifu ili trošak s iznosom nula -> brejkamo dalje
                            throw BreakException;
                        }
                    }
                }
            });    
        }
        catch(e){
            if (e === BreakException) {
                return true;
            }
        }

        //prošli smo kroz sve specifikacije, koje se naplaćuju i nema niti jedne stavke koja bi bila satnica/tarifa s iznosom ili vrijednošću jednakom nula
        return false;
    }

    useEffect(() => {
        if (oznaceni.length == 1) {
            let currRacun = racuni.find(r => r.RacunID == oznaceni.at(0));
            setCurrentRacun(currRacun);
            setFormRacun(formRacun ? racuni.find(r => r.RacunID == formRacun.RacunID) : null);
            if (currRacun) {
                setPrebaciURacunDisabled(currRacun.VrstaRacunaID != 3);
                setKopirajDisabled(currRacun.VrstaRacunaID == 7);
                setFakturirajDisabled(currRacun.Broj || currRacun.VrstaRacunaID == 3 || Util.izracunajIznosRacuna(currRacun) == 0 || (!moguceFakturiratiRacuneSaSatnicamaBezCijene && postojeSatniceSNulom(currRacun)));
                setOdveziDisabled(!currRacun.Broj 
                                    || currRacun.Storniran
                                    || currRacun.Fiskaliziran
                                    || currRacun.VrstaRacunaID == 3
                                    || currRacun.VrstaRacunaID == 7 
                                    || currRacun.PostojeOdobrenjaIliZaduzenja
                                    || ((currRacun.VrstaRacunaID == 1 || currRacun.VrstaRacunaID == 2) && (currRacun.VezaRacun || currRacun.Storniran))
                                    || tipAlgoritmaKontroleOdvezivanjaID == 5
                                    || (tipAlgoritmaKontroleOdvezivanjaID == 4  && (Util.izracunajPlacenoRacuna(currRacun) > 0 || currRacun.IzvezenaDatotekaID != null))
                                    || (tipAlgoritmaKontroleOdvezivanjaID == 3 && Util.izracunajPlacenoRacuna(currRacun) > 0)
                                    || (tipAlgoritmaKontroleOdvezivanjaID == 2 && currRacun.IzvezenaDatotekaID != null)
                                );
                setIspisDisabled(currRacun.TipRacunaID == 2);
                //setExportDisabled(!currRacun.Broj || currRacun.IzvezenaDatotekaID || currRacun.VrstaRacunaID == 3);
                setExportDisabled(!currRacun.Broj || currRacun.VrstaRacunaID == 3);
                setStornirajDisabled(!currRacun.Broj || currRacun.Storniran || currRacun.PostojeOdobrenjaIliZaduzenja || currRacun.VrstaRacunaID == 7 || currRacun.VrstaRacunaID == 3 || currRacun.RasporediPlacanja.length != 0);
                // let klijentiGrupe = [];
                // if(currRacun.KlijentID != null || klijentiPredmeti != null || klijentiPredmeti.Klijenti != null){
                //     let klnt = klijentiPredmeti.Klijenti.find(k=>k.KlijentID == currRacun.KlijentID);
                //     //sad mi daj sve klijente koji imaju taj grupaID
                //     if(klnt != null || klnt.GrupaID != null){
                //         klijentiGrupe = klijentiPredmeti.Klijenti.filter(k => k.GrupaID == klnt.GrupaID);
                //     }
                // }
                // setReplicirajDisabled(!currRacun || currRacun.VrstaRacunaID == 7 || currRacun.Storniran || klijentiGrupe == [] || klijentiGrupe.length <= 1);

                //ili riješiti u jednom
                // setReplicirajDisabled(
                //     currRacun.VrstaRacunaID == 7 || 
                //     (currRacun.KlijentID &&
                //          klijentiPredmeti && 
                //          klijentiPredmeti.Klijenti && 
                //          klijentiPredmeti.Klijenti.find(k=>k.KlijentID == currRacun.KlijentID) && 
                //          klijentiPredmeti.Klijenti.find(k=>k.KlijentID == currRacun.KlijentID).GrupaID && 
                //          (klijentiPredmeti.Klijenti.filter(k => k.GrupaID == klijentiPredmeti.Klijenti.find(k=>k.KlijentID == currRacun.KlijentID).GrupaID) == [] ||
                //           klijentiPredmeti.Klijenti.filter(k => k.GrupaID == klijentiPredmeti.Klijenti.find(k=>k.KlijentID == currRacun.KlijentID).GrupaID).length <= 1)
                //     )
                // )
                setReplicirajDisabled(
                    currRacun.VrstaRacunaID == 7 || 
                    (currRacun.KlijentID &&
                        klijentiPredmeti && 
                        klijentiPredmeti.Klijenti && 
                        klijentiPredmeti.Klijenti.find(k=>k.KlijentID == currRacun.KlijentID) && 
                        klijentiPredmeti.Klijenti.find(k=>k.KlijentID == currRacun.KlijentID).GrupeIDs && 
                        (klijentiPredmeti.Klijenti.filter(k => k.GrupeIDs.some(g => klijentiPredmeti.Klijenti.find(k=>k.KlijentID == currRacun.KlijentID).GrupeIDs.includes(g))) == [] ||
                        klijentiPredmeti.Klijenti.filter(k => k.GrupeIDs.some(g => klijentiPredmeti.Klijenti.find(k=>k.KlijentID == currRacun.KlijentID).GrupeIDs.includes(g))).length <= 1)
                    )
                )
            } else {
                setOznaceni([]);   
            }
        } else if (oznaceni.length > 1) {
            setCurrentRacun(null);
            //setReplicirajDisabled(true);
            let oznaceniRacuni = racuni.filter(r => oznaceni.includes(r.RacunID));
            if (oznaceniRacuni.length > 0) {
            
                // let oznaceniRacuniKojiSeMoguReplicirati = oznaceniRacuni.filter(r =>
                //     !(r.VrstaRacunaID == 7 || 
                //         (r.KlijentID &&
                //             klijentiPredmeti && 
                //             klijentiPredmeti.Klijenti && 
                //             klijentiPredmeti.Klijenti.find(k=>k.KlijentID == r.KlijentID) && 
                //             klijentiPredmeti.Klijenti.find(k=>k.KlijentID == r.KlijentID).GrupeIDs && 
                //             (klijentiPredmeti.Klijenti.filter(k => k.GrupeIDs.some(g => klijentiPredmeti.Klijenti.find(k=>k.KlijentID == r.KlijentID).GrupeIDs.includes(g))) == [] ||
                //             klijentiPredmeti.Klijenti.filter(k => k.GrupeIDs.some(g => klijentiPredmeti.Klijenti.find(k=>k.KlijentID == r.KlijentID).GrupeIDs.includes(g))).length <= 1)
                //         )
                //     )
                // );
            
                let oznacenePonudeKojeSeMoguPrebacitiURacun = oznaceniRacuni.filter(r => r.VrstaRacunaID == 3);
                let oznaceniRacuniKojiSeMoguKopirati = oznaceniRacuni.filter(r => r.VrstaRacunaID != 7);
                let oznaceniRacuniKojiSeMoguFakturirati = oznaceniRacuni.filter(r => !r.Broj && r.VrstaRacunaID == 1 && (moguceFakturiratiRacuneSaSatnicamaBezCijene || !postojeSatniceSNulom(r)));
                let oznaceniRacuniKojiSeMoguOdvezati = oznaceniRacuni.filter(r => r.Broj 
                                                                                    && !r.Storniran 
                                                                                    && r.VrstaRacunaID != 3
                                                                                    && r.VrstaRacunaID != 7 
                                                                                    && !r.Fiskaliziran
                                                                                    && !r.PostojeOdobrenjaIliZaduzenja
                                                                                    && !((r.VrstaRacunaID == 1 || r.VrstaRacunaID == 2) && (r.VezaRacun || r.Storniran))
                                                                                    && !(tipAlgoritmaKontroleOdvezivanjaID == 5)
                                                                                    && !(tipAlgoritmaKontroleOdvezivanjaID == 4  && (Util.izracunajPlacenoRacuna(r) > 0 || r.IzvezenaDatotekaID != null))
                                                                                    && !(tipAlgoritmaKontroleOdvezivanjaID == 3 && Util.izracunajPlacenoRacuna(r) > 0)
                                                                                    && !(tipAlgoritmaKontroleOdvezivanjaID == 2 && r.IzvezenaDatotekaID != null)
                                                                            );
                setFakturirajDisabled(oznaceniRacuni.length != oznaceniRacuniKojiSeMoguFakturirati.length || oznaceniRacuni.filter(r => Util.izracunajIznosRacuna(r) == 0).length != 0);
                setOdveziDisabled(oznaceniRacuni.length != oznaceniRacuniKojiSeMoguOdvezati.length);
                setIspisDisabled(oznaceniRacuni.filter(r => r.TipRacunaID == 2).length > 0)
                //let oznaceniRacuniKojiSeMoguExportirati = oznaceniRacuni.filter(r => r.Broj && !r.IzvezenaDatotekaID && r.VrstaRacunaID != 3);
                let oznaceniRacuniKojiSeMoguExportirati = oznaceniRacuni.filter(r => r.Broj && r.VrstaRacunaID != 3);
                setExportDisabled(oznaceniRacuni.length != oznaceniRacuniKojiSeMoguExportirati.length);
                setKopirajDisabled(oznaceniRacuni.length != oznaceniRacuniKojiSeMoguKopirati.length);
                //setReplicirajDisabled(oznaceniRacuni.length != oznaceniRacuniKojiSeMoguReplicirati.length);
                setReplicirajDisabled(true);
                setPrebaciURacunDisabled(oznaceniRacuni.length != oznacenePonudeKojeSeMoguPrebacitiURacun.length);
            } else {
                setOznaceni([]);
            }
        } else {
            setCurrentRacun(null);
            setFakturirajDisabled(true);
            setOdveziDisabled(true);
            setExportDisabled(true);
            setReplicirajDisabled(true);
            setIspisDisabled(true);
        }
    }, [oznaceni, racuni, moguceFakturiratiRacuneSaSatnicamaBezCijene]);

    function fakturiraj() {
        setFakturiranjeFormRacun(currentRacun);
        let oznaceniRacuni = racuni.filter(r => oznaceni.includes(r.RacunID));
        setFakturiranjeFormRacuni(oznaceniRacuni);
        setFakturiranjeFormOpened(true);
    }

    function ispisiRacun(printResource, extension, nacinOtvaranja) {
        setLoading(true);
        ;
        api.getReport("ispisiRacun/", printResource, extension, nacinOtvaranja, ispisiRacunSuccess, ispisiRacunFail);
    }

    function skupniIspisRacuna(printResource, extension, nacinOtvaranja) {
        setLoading(true);
        api.getReport("reportsapi/skupniispisracuna", printResource, extension, nacinOtvaranja, ispisiRacunSuccess, ispisiRacunFail);
    }

    function ispisiRacunSuccess() {
        setLoading(false);
    }

    function ispisiRacunFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function exportRacuni() {

        setLoading(true);
        ;
        let resource = {
            RacuniIDs : oznaceni,
            KorisnikID : global.user.korisnikID
            // KorisnikID : global.drugiZaposlenik.KorisnikID
        };
        api.post("racunovodstvo/export", resource, exportSuccess, exportFail);
    }

    function exportSuccess() {
        filter(null, null, null);
    }

    function exportFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <ReceiptLongIcon fontSize="medium" style={{marginRight:"15px"}}></ReceiptLongIcon>
                        Računi
                    </Typography>
                </Grid>
                <Grid item xs={8} style={{display:"flex", alignItems:"center"}}>
                    <div>{filterLabel}</div>
                </Grid>
                <Grid item xs={4}>
                    <div style={{display:"flex", justifyContent:"flex-end", width:"100%", maxHeight:"40px"}}>
                        <Button
                            id="sort-button"
                            aria-controls={sortOpened ? 'sort-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={sortOpened ? 'true' : undefined}
                            onClick={handleSortClick}
                            size="medium"
                            style={{marginBottom:"5px", marginRight:"5px"}}
                            variant="outlined"
                            endIcon={sortOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        >
                            Sortiraj
                        </Button>
                        <Menu
                            id="sort-menu"
                            anchorEl={anchorSortEl}
                            open={sortOpened}
                            onClose={handleSortClose}
                            MenuListProps={{
                            'aria-labelledby': 'sort-button',
                            }}
                        >

                            <MenuItem>
                                <ListItemText primary="Po ID" onClick={() => sortRacuni("id", null)}></ListItemText>
                                { opcijaSortiranja == 10 ? (
                                    <ListItemIcon style={{textAlign:"center", display:"flex", justifyContent:"center"}}>
                                        { sortiranjeUzlazno ? <NorthEastIcon /> : <SouthEastIcon /> }
                                    </ListItemIcon>
                                ) : null}
                            </MenuItem>

                            <MenuItem>
                                <ListItemText primary="Po vrsti" onClick={() => sortRacuni("vrsta", null)}></ListItemText>
                                { opcijaSortiranja == 1 ? (
                                    <ListItemIcon style={{textAlign:"center", display:"flex", justifyContent:"center"}}>
                                        { sortiranjeUzlazno ? <NorthEastIcon /> : <SouthEastIcon /> }
                                    </ListItemIcon>
                                ) : null}
                            </MenuItem>
                            <MenuItem>
                                <ListItemText primary="Po broju" onClick={() => sortRacuni("broj", null)}></ListItemText>
                                { opcijaSortiranja == 2 ? (
                                    <ListItemIcon style={{textAlign:"center", display:"flex", justifyContent:"center"}}>
                                        { sortiranjeUzlazno ? <NorthEastIcon /> : <SouthEastIcon /> }
                                    </ListItemIcon>
                                ) : null}
                            </MenuItem>
                            <MenuItem>
                                <ListItemText primary="Po klijentu" onClick={() => sortRacuni("klijent", null)}></ListItemText>
                                { opcijaSortiranja == 3 ? (
                                    <ListItemIcon style={{textAlign:"center", display:"flex", justifyContent:"center"}}>
                                        { sortiranjeUzlazno ? <NorthEastIcon /> : <SouthEastIcon /> }
                                    </ListItemIcon>
                                ) : null}
                            </MenuItem>
                            <MenuItem>
                                <ListItemText primary="Po datumu" onClick={() => sortRacuni("datum", null)}></ListItemText>
                                { opcijaSortiranja == 4 ? (
                                    <ListItemIcon style={{textAlign:"center", display:"flex", justifyContent:"center"}}>
                                        { sortiranjeUzlazno ? <NorthEastIcon /> : <SouthEastIcon /> }
                                    </ListItemIcon>
                                ) : null}
                            </MenuItem>
                            <MenuItem>
                                <ListItemText primary="Po porezu" onClick={() => sortRacuni("porez", null)}></ListItemText>
                                { opcijaSortiranja == 5 ? (
                                    <ListItemIcon style={{textAlign:"center", display:"flex", justifyContent:"center"}}>
                                        { sortiranjeUzlazno ? <NorthEastIcon /> : <SouthEastIcon /> }
                                    </ListItemIcon>
                                ) : null}
                            </MenuItem>
                            <MenuItem>
                                <ListItemText primary="Po ukupnom iznosu" onClick={() => sortRacuni("ukupno", null)}></ListItemText>
                                { opcijaSortiranja == 6 ? (
                                    <ListItemIcon style={{textAlign:"center", display:"flex", justifyContent:"center"}}>
                                        { sortiranjeUzlazno ? <NorthEastIcon /> : <SouthEastIcon /> }
                                    </ListItemIcon>
                                ) : null}
                            </MenuItem>
                            {/* <MenuItem>
                                <ListItemText primary="Po plaćenom iznosu" onClick={() => sortRacuni("placeno")}></ListItemText>
                                { opcijaSortiranja == 7 ? (
                                    <ListItemIcon style={{textAlign:"center", display:"flex", justifyContent:"center"}}>
                                        { sortiranjeUzlazno ? <NorthEastIcon /> : <SouthEastIcon /> }
                                    </ListItemIcon>
                                ) : null}
                            </MenuItem>
                            <MenuItem>
                                <ListItemText primary="Po preostalom iznosu" onClick={() => sortRacuni("ostalo")}></ListItemText>
                                { opcijaSortiranja == 8 ? (
                                    <ListItemIcon style={{textAlign:"center", display:"flex", justifyContent:"center"}}>
                                        { sortiranjeUzlazno ? <NorthEastIcon /> : <SouthEastIcon /> }
                                    </ListItemIcon>
                                ) : null}
                            </MenuItem> */}
                            <MenuItem>
                                <ListItemText primary="Po napomeni" onClick={() => sortRacuni("napomena", null)}></ListItemText>
                                { opcijaSortiranja == 9 ? (
                                    <ListItemIcon style={{textAlign:"center", display:"flex", justifyContent:"center"}}>
                                        { sortiranjeUzlazno ? <NorthEastIcon /> : <SouthEastIcon /> }
                                    </ListItemIcon>
                                ) : null}
                            </MenuItem>
                        </Menu>
                        <Button
                            id="basic-button"
                            aria-controls={filterOpened ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={filterOpened ? 'true' : undefined}
                            onClick={handleFilterClick}
                            size="medium"
                            style={{marginBottom:"5px"}}
                            variant="outlined"
                            endIcon={filterOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        >
                            Filter
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={filterOpened}
                            onClose={handleFilterClose}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            <FormControl fullWidth>
                                <MenuItem><Autocomplete style={{width:"100%"}}
                                    disablePortal
                                    options={periodOptions}
                                    getOptionLabel={(option) => option.Naziv}
                                    value={periodOption}
                                    onChange={(e,v) => {if (v) setPeriodOption(v)}}
                                    renderInput={(params) => <TextField {...params} label="Vremensko razdoblje"  size="small"/>}
                                /></MenuItem>
                            </FormControl> 
                            {periodOption.Vrijednost == 2 ? (
                                <MenuItem>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                        <DatePicker
                                            views={['year']}
                                            label="Godina"
                                            value={godina}
                                            inputFormat="yyyy"
                                            onChange={(v) => setGodina(v)}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </LocalizationProvider>
                                </MenuItem>
                            ) : null}
                            {periodOption.Vrijednost == 3 ? (
                                <MenuItem>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                        <DatePicker
                                            views={['year', 'month']}
                                            label="Mjesec"
                                            value={mjesec}
                                            inputFormat="MM.yyyy"
                                            onChange={(v) => setMjesec(v)}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </LocalizationProvider>
                                </MenuItem>
                            ) : null}
                            {periodOption.Vrijednost == 4 ? (
                                <MenuItem>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                        <DatePicker
                                            label="Datum"
                                            value={datum}
                                            inputFormat="dd.MM.yyyy"
                                            onChange={(v) => setDatum(v)}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </LocalizationProvider>
                                </MenuItem>
                            ) : null}
                            {periodOption.Vrijednost == 5 ? (
                                <MenuItem>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                        <DatePicker
                                            label="Od"
                                            value={datumOd}
                                            inputFormat="dd.MM.yyyy"
                                            onChange={handleDatumOdChange}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </LocalizationProvider>
                                </MenuItem>
                            ) : null}
                            {periodOption.Vrijednost == 5 ? (
                                <MenuItem>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                        <DatePicker
                                            label="Do"
                                            value={datumDo}
                                            inputFormat="dd.MM.yyyy"
                                            onChange={handleDatumDoChange}
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                        />
                                    </LocalizationProvider>
                                </MenuItem>
                            ) : null}
                            <Divider></Divider>
                            <FormControl fullWidth>
                                <RadioGroup aria-labelledby="filter-stanje">
                                        <MenuItem style={{cursor:"pointer"}} onClick={handleUlazniClick}><Radio style={{paddingTop:"3px", paddingBottom:"3px"}} checked={ulazni}></Radio><ListItemText>Ulazni</ListItemText></MenuItem>
                                        <MenuItem style={{cursor:"pointer"}} onClick={handleIzlazniClick}><Radio style={{paddingTop:"3px", paddingBottom:"3px"}} checked={izlazni}></Radio><ListItemText>Izlazni</ListItemText></MenuItem>      
                                </RadioGroup>
                            </FormControl>
                            <Divider></Divider>
                            <FormControl fullWidth>
                                <RadioGroup aria-labelledby="filter-naplata">
                                    <MenuItem style={{cursor:"pointer"}} onClick={handleDomaciClick}><Radio style={{paddingTop:"3px", paddingBottom:"3px"}} checked={domaci && !strani}></Radio><ListItemText>Domaći</ListItemText></MenuItem>
                                    <MenuItem style={{cursor:"pointer"}} onClick={handleStraniClick}><Radio style={{paddingTop:"3px", paddingBottom:"3px"}} checked={!domaci && strani}></Radio><ListItemText>Strani</ListItemText></MenuItem>
                                    <MenuItem style={{cursor:"pointer"}} onClick={handleDomaciStraniClick}><Radio style={{paddingTop:"3px", paddingBottom:"3px"}} checked={domaci && strani}></Radio><ListItemText>Svi</ListItemText></MenuItem>                   
                                </RadioGroup>
                            </FormControl>
                            <Divider></Divider>
                            <FormControl fullWidth>
                                <RadioGroup aria-labelledby="filter-naplata">
                                    <MenuItem style={{cursor:"pointer"}} onClick={handleFakturiraniClick}><Radio style={{paddingTop:"3px", paddingBottom:"3px"}} checked={fakturirani && !nefakturirani}></Radio><ListItemText>Fakturirani</ListItemText></MenuItem>
                                    <MenuItem style={{cursor:"pointer"}} onClick={handleNefakturiraniClick}><Radio style={{paddingTop:"3px", paddingBottom:"3px"}} checked={!fakturirani && nefakturirani}></Radio><ListItemText>Nefakturirani</ListItemText></MenuItem>
                                    <MenuItem style={{cursor:"pointer"}} onClick={handleFakturiraniNefakturiraniClick}><Radio style={{paddingTop:"3px", paddingBottom:"3px"}} checked={fakturirani && nefakturirani}></Radio><ListItemText>Svi</ListItemText></MenuItem>                   
                                </RadioGroup>
                            </FormControl>
                            <Divider></Divider>
                            <FormControl fullWidth>
                                <RadioGroup aria-labelledby="filter-vrsta">
                                    <MenuItem style={{cursor:"pointer"}} onClick={handleRacuniClick}><Radio style={{paddingTop:"3px", paddingBottom:"3px"}} checked={vrstaRacuni && !vrstaPonude}></Radio><ListItemText>Računi</ListItemText></MenuItem>
                                    <MenuItem style={{cursor:"pointer"}} onClick={handlePonudeClick}><Radio style={{paddingTop:"3px", paddingBottom:"3px"}} checked={!vrstaRacuni && vrstaPonude}></Radio><ListItemText>Ponude</ListItemText></MenuItem>
                                    <MenuItem style={{cursor:"pointer"}} onClick={handleRacuniPonudeClick}><Radio style={{paddingTop:"3px", paddingBottom:"3px"}} checked={vrstaRacuni && vrstaPonude}></Radio><ListItemText>Svi</ListItemText></MenuItem>                   
                                </RadioGroup>
                            </FormControl>
                            <div style={{textAlign:"center"}}><Button variant="outlined" color="success" startIcon={<SearchIcon></SearchIcon>} onClick={() => filter(null, null, null)}>Filtriraj</Button></div>
                        </Menu>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth >
                        <Autocomplete 
                            disablePortal
                            autoHighlight
                            //options={grupa ? klijentiPredmeti.Klijenti.filter(k => k.GrupaID == grupa.GrupaID) : klijentiPredmeti.Klijenti}
                            //options={klijentiPredmeti.Klijenti}
                            options={grupa ? sviKlijenti.filter(k => k.GrupeIDs.includes(grupa.GrupaID)) : sviKlijenti}
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            value={klijent}
                            onChange={handleKlijentChange}
                            renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 || option.KlijentID == 0 ? "black" : "red"}}>
                                        {option.Broj + " - " + option.Naziv}
                                      </li>
                                    );
                                }}                            
                            filterOptions={(options, state) => options.filter(option => (option.Broj + " - " + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            renderInput={(params) => <TextField {...params} sx={{input:{color: klijent && klijent.TipStatusaKlijentaID != 1 && klijent.KlijentID != 0 ? "red" : "black"}}} label="Klijent"  size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth >
                        <Autocomplete 
                            disablePortal
                            options={grupe}
                            autoHighlight
                            getOptionLabel={(option) => option.Naziv}
                            value={grupa}
                            onChange={handleGrupaChange}
                            renderInput={(params) => <TextField {...params} label="Grupa"  size="small"/>}
                        />
                    </FormControl>
                </Grid>
                {neuspjesnaFiskalizacija ? (
                    <Grid item marginTop="10px" xs={12}>
                        <Alert severity="error">
                            Računi naglašeni crvenom pozadinom nisu uspješno fiskalizirani, odnosno nisu dobili JIR.
                        </Alert>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Box style={{width:"100%", marginTop:"20px"}}>
                        <AddEditDelForm
                            referenca={ref}
                            dataType="racuni" 
                            // data={klijent ? (klijent.KlijentID != 0 ? racuni.filter(r => r.KlijentID == klijent.KlijentID) : racuni) : (grupa ? racuni.filter(r => r.KlijentGrupaID == grupa.GrupaID) : null)}
                            data={klijent || grupa ? racuni : []}
                            setData={setRacuni}
                            current={oznaceni && oznaceni.length == 1 ? oznaceni.at(0) : null}
                            oznaceni={oznaceni}
                            lastIndex={oznaceni.length > 0 ? oznaceni.indexOf(oznaceni.at(oznaceni.length - 1)) : 0}
                            setOznaceni={setOznaceni}
                            add={handleAddClick}
                            handleRacunClick={handleRacunClick}
                            edit={edit} 
                            del={deleteRacun}
                            //disableButtons={currentRacun && (currentRacun.IzvezenaDatotekaID)}
                            //disableButtons={!currentRacun}
                            disableEdit={!currentRacun}
                            disableDeleteButton={currentRacun && (((currentRacun.VrstaRacunaID == 1 || currentRacun.VrstaRacunaID == 2 || currentRacun.VrstaRacunaID == 4 || currentRacun.VrstaRacunaID == 5) && currentRacun.TipRacunaID == 1) && currentRacun.Broj || currentRacun.IzvezenaDatotekaID || currentRacun.Zatvoreno != 0)}
                            handleSearchTextChange={handleSearchChange}
                            forceListItemToReRender={forceListItemToReRender ? 1 : 2}
                            loading={loading}
                            setLoading={setLoading}
                        ></AddEditDelForm>
                        <Box style={{width:"100%", display:"flex", justifyContent:"flex-end", marginTop:"5px"}}>
                            <Button startIcon={<LockIcon></LockIcon>} style={{marginLeft:"5px", paddingTop:"5px", paddingBottom:"5px", paddingRight:"10px", paddingLeft:"10px"}} variant="contained" disabled={fakturirajDisabled || zabranjenoFakturiranje} onClick={fakturiraj} >
                                Fakturiraj
                            </Button>
                            <Button 
                                startIcon={<MoveUpIcon></MoveUpIcon>} 
                                style={{marginLeft:"5px", paddingTop:"5px", paddingBottom:"5px", paddingRight:"10px", paddingLeft:"10px"}} 
                                variant="contained" 
                                disabled={prebaciURacunDisabled} 
                                onClick={() => {
                                    //setPrebaciURacunPonuda(currentRacun);
                                    setPrebaciURacunAlert(true);
                            }}>
                                Prebaci u račun
                            </Button>
                            <Button startIcon={<LockOpenIcon></LockOpenIcon>} style={{marginLeft:"5px", paddingTop:"5px", paddingBottom:"5px", paddingRight:"10px", paddingLeft:"10px"}} variant="contained" disabled={odveziDisabled || zabranjenoFakturiranje} onClick={() => {setOdveziAlert(true);}}>
                                Odveži
                            </Button>
                            <Button startIcon={<BlockIcon></BlockIcon>} style={{marginLeft:"5px", paddingTop:"5px", paddingBottom:"5px", paddingRight:"10px", paddingLeft:"10px"}} variant="contained" disabled={!currentRacun || stornirajDisabled || zabranjenoFakturiranje} onClick={storno}>
                                Storno
                            </Button>
                            <Button startIcon={<RotateRightIcon></RotateRightIcon>} style={{marginLeft:"5px", paddingTop:"5px", paddingBottom:"5px", paddingRight:"10px", paddingLeft:"10px"}} variant="contained" disabled={!currentRacun || !currentRacun.Broj || currentRacun.VrstaRacunaID == 3 || currentRacun.Fiskaliziran} onClick={() => {
                                setOsvjeziAdresuAlert(true);
                            }}>
                                Osvježi podatke
                            </Button>
                            {/* <Button startIcon={<GroupsIcon></GroupsIcon>} style={{marginLeft:"5px", paddingTop:"5px", paddingBottom:"5px", paddingRight:"10px", paddingLeft:"10px"}} variant="contained" disabled={!currentRacun || currentRacun.VrstaRacunaID == 7 || currentRacun.Storniran || replicirajDisabled} onClick={() => { */}
                            <Button startIcon={<GroupsIcon></GroupsIcon>} style={{marginLeft:"5px", paddingTop:"5px", paddingBottom:"5px", paddingRight:"10px", paddingLeft:"10px"}} variant="contained" disabled={replicirajDisabled} onClick={() => {
                                //setReplicirajRacunID(currentRacun.RacunID);
                                let klijenti = currentRacun ? klijentiPredmeti.Klijenti.filter(k => k.KlijentID != currentRacun.KlijentID && k.GrupeIDs.some(g => klijentiPredmeti.Klijenti.find(k=>k.KlijentID == currentRacun.KlijentID).GrupeIDs.includes(g))) : [];

                                let grupeIDs = [];
                                klijenti.forEach(k => {
                                    k.GrupeIDs.forEach(g => {
                                        if (klijentiPredmeti.Klijenti.find(k=>k.KlijentID == currentRacun.KlijentID).GrupeIDs.includes(g) && !grupeIDs.includes(g)) {
                                            grupeIDs.push(g);
                                        }
                                    });
                                });

                                if (grupeIDs.length == 1) {
                                    setGrupaZaReplikaciju(grupe.find(g => g.GrupaID == grupeIDs[0]));
                                } else {
                                    setGrupaZaReplikaciju(null);
                                }
                                setGrupeZaReplikaciju(grupe.filter(g => grupeIDs.includes(g.GrupaID)));

                                setReplicirajAlert(true);
                            }}>
                                Repliciraj na grupu
                            </Button>
                            <Button
                                onClick={() => {
                                    setKopirajAlert(true);
                                }}
                                style={{marginLeft:"5px", paddingTop:"5px", paddingBottom:"5px", paddingRight:"10px", paddingLeft:"10px"}}
                                variant="contained"
                                //disabled ={!oznaceni || oznaceni.length == 0}
                                disabled = {kopirajDisabled}
                                startIcon={<ContentCopyIcon />}
                            >
                                Kopiraj
                            </Button>
                            <Button
                                onClick={() => {
                                    setIspisFormOpened(true);
                                }}
                                style={{marginLeft:"5px", paddingTop:"5px", paddingBottom:"5px", paddingRight:"10px", paddingLeft:"10px"}}
                                variant="contained"
                                disabled={oznaceni.length == 0 || ispisDisabled}
                                startIcon={<PrintIcon />}
                            >
                                Ispiši
                            </Button>
                            <Button
                                onClick={() => {
                                    exportRacuni();
                                }}
                                style={{marginLeft:"5px", paddingTop:"5px", paddingBottom:"5px", paddingRight:"10px", paddingLeft:"10px"}}
                                variant="contained"
                                disabled={exportDisabled}
                                startIcon={<IosShareIcon />}
                            >
                                Wand export
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Menu
                anchorEl={anchorAddEl}
                open={addOptionsOpened}
                onClose={handleAddClose}
            >
                <ListItem><b>Ulazni</b></ListItem>
                {vrsteRacuna.map((vrsta, index) => {
                    if (vrsta.VrstaRacunaID != 7) {
                        return <MenuItem key={index} onClick={() => {
                            setAnchorAddEl(null);
                            setFormRacun(null);
                            setFormRacunTipRacunaID(2);
                            setFormRacunVrstaRacuna(vrsta);
                            setTipUpisaRacunaID(vrsta.TipUpisaRacunaID);
                            setRacunFormOpened(true);
                        }}>{vrsta.Naziv}</MenuItem>
                    }
                })}
                <Divider></Divider>
                <ListItem><b>Izlazni</b></ListItem>
                {vrsteRacuna.map((vrsta, index) => {
                    if (vrsta.VrstaRacunaID != 7) {
                        return <MenuItem key={index} disabled={(vrsta.VrstaRacunaID == 4 || vrsta.VrstaRacunaID == 5) && zabranjenoFakturiranje} onClick={() => {
                            setAnchorAddEl(null);
                            setFormRacun(null);
                            setFormRacunTipRacunaID(1);
                            setFormRacunVrstaRacuna(vrsta);
                            setTipUpisaRacunaID(vrsta.TipUpisaRacunaID);
                            setRacunFormOpened(true);
                        }}>{vrsta.Naziv}</MenuItem>
                    }
                })}
            </Menu>

            <Modal open={racunFormOpened}>
                <Box sx={style} style={{padding:"15px"}} overflow={"auto"} >
                    {tipUpisaRacunaID == 1 ? (
                        <RacunForm klijenti={klijentiPredmeti.Klijenti} 
                            formRacun={formRacun} 
                            setFormOpened={setRacunFormOpened} 
                            setCanceled={setCanceled} 
                            tipRacunaID={formRacunTipRacunaID} 
                            vrstaRacuna={formRacunVrstaRacuna}
                            tipNaplatnostiNaplatnoBoja={tipNaplatnostiNaplatnoBoja}
                            tipNaplatnostiNenaplatnoBoja={tipNaplatnostiNenaplatnoBoja}
                            filterRacuni={filter}
                            loadingRacuni={loading}
                            predmeti={klijentiPredmeti.Predmeti}
                            ></RacunForm>
                    ) : (
                        tipUpisaRacunaID == 2 ? (
                            <OdobrenjeZaduzenjeForm klijenti={klijentiPredmeti.Klijenti} setFormOpened={setRacunFormOpened} filter={() => filter(null, null, null)} setCanceled={setCanceled} formOdobrenjeZaduzenje={formRacun} tipRacunaID={formRacunTipRacunaID} vrstaRacuna={formRacunVrstaRacuna}></OdobrenjeZaduzenjeForm>
                        ) : (
                            tipUpisaRacunaID == 3 ? (
                                <StornoForm setFormOpened={setRacunFormOpened} filter={() => filter(null, null, null)} racun={stornoFormRacun}></StornoForm>
                            ) : null
                        )
                    )}
                </Box>
            </Modal>

            <Modal open={fakturiranjeFormOpened}>
                <Box sx={{...style, border: borderStyle}} overflow={"auto"} >
                    <FakturiranjeForm setBorderStyle={setBorderStyle} vrstaRacunaID={fakturiranjeFormRacun ? fakturiranjeFormRacun.VrstaRacunaID : 1} odobrenjeZaduzenje={fakturiranjeFormRacun && (fakturiranjeFormRacun.VrstaRacunaID == 4 || fakturiranjeFormRacun.VrstaRacunaID == 5)} racun={fakturiranjeFormRacun} racuni={fakturiranjeFormRacuni} filterRacuni={() => filter(null, null, null)} setFormOpened={setFakturiranjeFormOpened} odobrenjeZaduzenjeStorno={false}></FakturiranjeForm>
                </Box>
            </Modal>

            {odveziAlert ? <AlertDialog title="Odvezivanje računa" message={"Jeste li sigurni da želite odvezati " + (oznaceni.length > 1 ? "odabrane račune?" : "odabrani račun?")} cancelLabel={"Odustani"} confirmLabel={"Odveži"} cancelAction={() => setOdveziAlert(false)} confirmAction={odvezi}></AlertDialog> : null}

            <Modal open={stornoFormOpened}>
                <Box sx={style} overflow={"auto"} >
                    <StornoForm racun={stornoFormRacun} setFormOpened={setStornoFormOpened} filter={() => filter(null, null, null)}></StornoForm>
                </Box>
            </Modal>

            {prebaciURacunAlert ? <AlertDialog title="Prebacivanje ponude u račun" message={"Jeste li sigurni da želite prebaciti odabranu ponudu u račun?"} cancelLabel={"Odustani"} confirmLabel={"Prebaci"} cancelAction={() => setPrebaciURacunAlert(false)} confirmAction={prebaciURacun}></AlertDialog> : null}


            <Modal open={deleteAlert}>
                <Box sx={styleDelete} overflow={"auto"} >
                    <Grid container>
                        <Typography variant="h6" gutterBottom component="div" style={{marginBottom:"15px"}}>
                            {deleteTekst}
                        </Typography>
                        {postojeAktivnosti ? (
                        <Grid item xs={12} >
                            <FormControlLabel 
                                labelPlacement="end" 
                                label="Vrati aktivnosti iz kojih su nastale specifikacije u nepreneseno stanje."
                                style={{marginLeft:"0px"}}
                                control={<Checkbox checked={vratiAktivnosti} onChange={e => setVratiAktivnosti(e.target.checked)}></Checkbox>}>
                            </FormControlLabel>
                        </Grid>
                        ) : null}
                        {postojeTroskovi ? (
                        <Grid item xs={12} >
                            <FormControlLabel 
                                labelPlacement="end" 
                                label="Vrati troškove iz kojih su nastale specifikacije u nepreneseno stanje."
                                style={{marginLeft:"0px"}}
                                control={<Checkbox checked={vratiTroskove} onChange={e => setVratiTroskove(e.target.checked)}></Checkbox>}>
                            </FormControlLabel>
                        </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                                <Button variant="outlined" style={{marginRight:"10px"}} onClick={deleteCanceled} >Odustani</Button>
                                <Button onClick={deleteConfirmed} startIcon={<DeleteIcon></DeleteIcon>} color="error" variant="contained" > Obriši</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            {kopirajAlert ? <AlertDialog title="Kopiranje računa" message={"Želite li kopirati " + (oznaceni.length > 1 ? "odabrane račune u nove nefakturirane zajedno s njihovim stavkama?" : "odabrani račun u novi nefakturirani zajedno s njegovim stavkama?")} cancelLabel={"Odustani"} confirmLabel={"Kopiraj"} cancelAction={() => setKopirajAlert(false)} confirmAction={() => kopirajRacune()}></AlertDialog> : null}
        
            {replicirajAlert ? <AlertDialog style={{minWidth:"50vw", minHeight:"50vh"}} title="Repliciranje na grupu" message={
                <div>
                    <div>
                        Odaberite grupu na čije članove želite replicirati odabrani račun.
                    </div>
                    <Autocomplete 
                        style={{width:"100%", marginTop:"15px"}}
                        disablePortal
                        options={grupeZaReplikaciju}
                        getOptionLabel={(option) => option.Naziv}
                        value={grupaZaReplikaciju}
                        onChange={(e,v) => setGrupaZaReplikaciju(v)}
                        renderInput={(params) => <TextField {...params} label="Grupa" error={!grupaZaReplikaciju} size="small"/>}
                    ></Autocomplete>
                </div>
            } cancelLabel={"Odustani"} confirmLabel={"Repliciraj na grupu"} confirmDisabled={!grupaZaReplikaciju} cancelAction={() => setReplicirajAlert(false)} confirmAction={replicirajNaGrupu}></AlertDialog> : null}

            <Modal open={brisanjeRacunaIzvjestajOpened}>
                <Box sx={styleDelete} overflow={"auto"} >
                    <RacuniIzvjestajBrisanje data={brisanjeRacunaIzvjestajData} close={() => setBrisanjeRacunaIzvjestajOpened(false)}></RacuniIzvjestajBrisanje>
                </Box>
            </Modal>

            <Modal open={ispisFormOpened}>
                <Box sx={styleDelete} overflow={"auto"} >
                    <IspisRacunaISpecifikacijaForm racun={currentRacun} filterRacuni={() => filter(null, null, null)} racuniIDs={oznaceni} ispisiRacun={ispisiRacun} skupniIspisRacuna={skupniIspisRacuna} close={() => setIspisFormOpened(false)}></IspisRacunaISpecifikacijaForm>
                </Box>
            </Modal>

            <Modal open={osvjeziAdresuAlert}>
                <Box sx={{...styleDelete, minWidth:"80vw"}} overflow={"auto"} >
                   <OsvjeziAdresuAlert racun={currentRacun} filterRacuni={() => filter(null, null, null)} close={() => setOsvjeziAdresuAlert(false)}></OsvjeziAdresuAlert>
                </Box>
            </Modal>

            {logoutAlert ? <AlertDialog title="Odjava" message={"Upravo se događa odjava inicirana od strane administratora."} cancelHidden={true} confirmHidden={true} okHidden={false} okLabel={"OK"} okAction={startLogout}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default Racuni;