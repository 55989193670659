import { Autocomplete, Button, FormControl, Grid, Modal, TextField, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useContext, useEffect, useRef, useState } from "react";
import ComponentContainer from "./componentContainer";
import TranslateIcon from '@mui/icons-material/Translate';
import Util from "../util/util";
import globalContext from "./globalContext";
import api from "../util/api";
import SaveIcon from '@mui/icons-material/Save';
import LineIcon from "react-lineicons";
import { Box } from "@mui/system";
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReportRjecnikPogledajPrimjerForm from "./reportRjecnikPogledajPrimjerForm";
import ReportRjecnikDodajUvjetForm from "./reportRjecnikDodajUvjetForm";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '90%'
};

export default function ReportRjecnik(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [reportKomponente, setReportKomponente] = useState([]);
    const [firmaJezici, setFirmaJezici] = useState([]);
    const [reportKomponenta, setReportKomponenta] = useState(null);
    const [firmaJezik, setFirmaJezik] = useState(null);
    const [rjecnik, setRjecnik] = useState([]);
    const [vrijednost, setVrijednost] = useState("");
    const [reportRjecnikID, setReportRjecnikID] = useState(null);
    const [selectionStart, setSelectionStart] = useState();
    const [lastPosition, setLastPosition] = useState();
    const [addCondition, setAddCondition] = useState(false);
    const [pogledajPrimjer, setPogledajPrimjer] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const inputRef = useRef();

    useConstructor(() => {
        loadInfo();
    });

    function loadInfo() {
        api.get("reportsapi/rjecnikinfo/" + global.user.firmaID, loadInfoSuccess, loadInfoFail);
    }

    function loadInfoSuccess(data) {
        setRjecnik(data.Rjecnik);
        setReportKomponente(data.ReportKomponente);
        setFirmaJezici(data.FirmaJezici);
        if (!reportKomponenta && !firmaJezik && data.ReportKomponente.length > 0 && data.FirmaJezici.length > 0) {
            setReportKomponenta(data.ReportKomponente[0]);
            setFirmaJezik(data.FirmaJezici[0]);
        }
        setLoading(false);
    }

    function loadInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        postaviVrijednost();
    }, [reportKomponenta, firmaJezik, rjecnik]);

    function postaviVrijednost() {
        if (reportKomponenta && firmaJezik) {
            let currentRjecnik = rjecnik.find(r => r.ReportKomponentaID == reportKomponenta.ReportKomponentaID && r.FirmaJezikID == firmaJezik.FirmaJezikID);
            setVrijednost(currentRjecnik ? currentRjecnik.Vrijednost : "");
            setReportRjecnikID(currentRjecnik ? currentRjecnik.ReportRjecnikID : -1);
        }
    }

    function save() {
        setLoading(true);
        let resource = {
            ReportRjecnikID : reportRjecnikID,
            Vrijednost : vrijednost
        };
        api.post("reportsapi/save", resource, saveSuccess, saveFail);
    }
 
    function saveSuccess(data) {
        loadInfo();
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function updateSelectionStart() {
        setSelectionStart(inputRef.current.selectionStart);
    }

    //useEffect(() => alert(selectionStart), [selectionStart]);

    function insertExpression(expression) {
        let newVrijednost = '';
        if(selectionStart){
            newVrijednost = [vrijednost.slice(0, selectionStart), expression, vrijednost.slice(selectionStart)].join('');
        }
        else{
            newVrijednost = [vrijednost.slice(0, selectionStart), expression].join('');
        }
        setVrijednost(newVrijednost);
        setLastPosition((selectionStart || 0) + expression.length);
    }

    useEffect(() => {
        if (lastPosition) {
            inputRef.current.focus();
        }
    }, [lastPosition]);

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <TranslateIcon fontSize="medium" style={{marginRight:"15px"}}></TranslateIcon>
                        Uređivanje komponenti dokumenata i njihovih prijevoda
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>  
                        <Autocomplete
                            disablePortal
                            disableClearable
                            options={reportKomponente}
                            getOptionLabel={(option) => option.Naziv}
                            value={reportKomponenta}
                            autoHighlight
                            onChange={(e,v) => setReportKomponenta(v)}
                            renderInput={(params) => <TextField {...params} label="Komponenta računa" size="small"/>}>
                        </Autocomplete>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>  
                        <Autocomplete
                            disablePortal
                            disableClearable
                            options={firmaJezici}
                            getOptionLabel={(option) => option.FirmaJezikNaziv}
                            value={firmaJezik}
                            autoHighlight
                            onChange={(e,v) => setFirmaJezik(v)}
                            renderInput={(params) => <TextField {...params} label="Jezik" size="small"/>}>
                        </Autocomplete>
                    </FormControl>
                </Grid>
                {reportKomponenta && firmaJezik ? (
                    <>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField label="Sadržaj" inputRef={inputRef} onSelect={updateSelectionStart} onFocus={() => {
                                    if (lastPosition != null) {
                                        inputRef.current.setSelectionRange(lastPosition, lastPosition);
                                        setLastPosition(null);
                                    }
                                }} value={vrijednost} onChange={e => setVrijednost(e.target.value)} multiline rows={8} size="small"></TextField>
                            </FormControl>
                        </Grid>
                    </>
                ) : null}
                {reportKomponenta && (reportKomponenta.ReportKomponentaID == 3 || reportKomponenta.ReportKomponentaID == 5) && firmaJezik ? (
                    <>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px">
                                <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[PocUsl]]]")}>Datum početka usluge</Button>
                                <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[ZavUsl]]]")}>Datum kraja usluge</Button>
                                <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[DP]]]")}>Datum plaćanja</Button>
                                <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[Mdl]]")}>Model plaćanja</Button>
                                <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[PNB]]]")}>Poziv na broj</Button>
                                <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[Npmn]]]")}>Napomena</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end" width="100%">
                                <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[BrDana]]]")}>Broj dana za plaćanje</Button>
                                <Button variant="outlined" startIcon={<AddIcon></AddIcon>} onClick={() => setAddCondition(true)}>Uvjet</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end" width="100%">
                                <Button variant="contained" startIcon={<VisibilityIcon></VisibilityIcon>} onClick={() => setPogledajPrimjer(true)}>Pogledaj primjer</Button>                          
                            </Box>
                        </Grid>
                    </>
                ) : null}
                {reportKomponenta && firmaJezik ? (
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                            <Button onClick={save} variant="contained" startIcon={loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning"></LineIcon> : <SaveIcon></SaveIcon>} style={{marginRight:"10px"}} color="success" disabled={!global.roles.includes("20") || loading}>Spremi</Button>
                            <Button variant="outlined" color="error" onClick={postaviVrijednost} startIcon={<CancelIcon></CancelIcon>} disabled={loading}>Poništi</Button>
                        </Box>
                    </Grid>
                ) : null}
            </Grid>

            <Modal className="modal" open={addCondition}>
                <Box sx={{...style, minWidth:"80vw"}} >
                    <ReportRjecnikDodajUvjetForm dodaj={insertExpression} close={() => setAddCondition(false)}></ReportRjecnikDodajUvjetForm>
                </Box>
            </Modal>

            <Modal className="modal" open={pogledajPrimjer}>
                <Box sx={{...style, minWidth:"80vw"}} >
                    <ReportRjecnikPogledajPrimjerForm prijevod={vrijednost} jezikID={firmaJezik ? firmaJezik.JezikID : 0} close={() => setPogledajPrimjer(false)}></ReportRjecnikPogledajPrimjerForm>
                </Box>
            </Modal>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}