import { Autocomplete, Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import SaveIcon from "@mui/icons-material/Save";
import ExitToApp from "@mui/icons-material/ExitToApp";
import api from "../util/api";
import EditIcon from "@mui/icons-material/Edit";
import AddchartIcon from "@mui/icons-material/Addchart";
import database from "../util/database";
import globalContext from "./globalContext";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function MjestoForm(props) {
    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const form = props.form;
    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;

    const [loading, setLoading] = useState(true);
    const [naziv, setNaziv] = useState("");
    const [foreignKontoID, setForeignKontoID] = useState();
    const [mjestoTroskaID, setMjestoTroskaID] = useState();
    const [mjestoTroskaNaziv, setMjestoTroskaNaziv] = useState();
    const [edited, setEdited] = useState(false);
    const [valid, setValid] = useState(false);
    const [drzave, setDrzave] = useState([]);
    const [postanskiBroj, setPostanskiBroj] = useState("");
    const [drzava, setDrzava] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        api.get("Drzava/GetAll", (data) => {
            setLoading(false);
            setDrzave(data);

            if (form) {
                setNaziv(form.Naziv);
                setPostanskiBroj(form.PostanskiBroj);
                let d = data.filter((x) => x.DrzavaID == form.DrzavaID)[0];
                setDrzava(d);
            } else {
                if (props.mjestoNaziv) {
                    setNaziv(props.mjestoNaziv);
                }
            }
        }, (data) => {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        });
    });

    function handleNazivChange(e) {
        setEdited(true);
        setNaziv(e.target.value);
    }

    function handleNazivFocusOut() {
        setNaziv(naziv.trim());
    }

    function handlePostanskiBrojChange(e) {
        setEdited(true);
        setPostanskiBroj(e.target.value);
    }

    function handlePostanskiBrojFocusOut() {
        setPostanskiBroj(postanskiBroj.trim());
    }

    function handleDrzavaChange(e, data) {
        setEdited(true);
        setDrzava(data);
    }

    function save() {
        setLoading(true);
        let data = {
            MjestoID: form ? form.MjestoID : -1,
            FirmaID: database.get("user").firmaID,
            Naziv: naziv,
            PostanskiBroj: postanskiBroj,
            DrzavaID: drzava.DrzavaID,
            DrzavaNaziv: drzava.Naziv
        };
        if (data.MjestoID == -1) {
            api.post("Mjesto/Add", data, saveSuccess, saveFail);
        } else {
            api.post("Mjesto/Update", data, saveSuccess, saveFail);
        }
    }

    function saveSuccess(data) {
        setLoading(false);
        setEdited(false);
        if (setCanceled) {
            setCanceled(false);
        }
        if (props.povratakNaKlijentForm) {
            props.povratakNaKlijentForm(data);
        }
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        let formValid = !!drzava && ((!postanskiBroj && !naziv) || (!postanskiBroj && !!naziv) || (postanskiBroj && naziv));
        setValid(formValid);
    });

    return (
        <ComponentContainer loading={loading}>
            <Grid container className="padding-grid">
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{ textAlign: "center", marginBottom: "15px" }}>
                        <div
                            style={{
                                display: "inline-flex",
                                verticalAlign: "text-bottom",
                                boxSizing: "inherit",
                                textAlign: "center",
                                alignItems: "center",
                            }}
                        >
                            {form ? <EditIcon fontSize="medium" style={{ marginRight: "15px" }}></EditIcon> : <AddchartIcon fontSize="medium" style={{ marginRight: "15px" }}></AddchartIcon>}
                            {form ? "Uređivanje mjesta" : "Novo mjesto"}
                        </div>
                    </Typography>
                </Grid>
                <Grid container className="padding-grid">
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField label="Naziv" value={naziv} inputProps={{ maxLength: 50 }} onChange={handleNazivChange} onBlur={handleNazivFocusOut} size="small"></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField label="Poštanski broj" value={postanskiBroj} inputProps={{ maxLength: 50 }} onChange={handlePostanskiBrojChange} onBlur={handlePostanskiBrojFocusOut} size="small"></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                // disablePortal
                                options={drzave}
                                getOptionLabel={(option) => option.Naziv}
                                value={drzava}
                                autoHighlight
                                onChange={handleDrzavaChange}
                                renderInput={(params) => <TextField {...params} label="Država" required size="small" />}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px">
                        <Button onClick={save} variant="contained" style={{ marginRight: "10px" }} color="success" disabled={!edited || !global.roles.includes("20") || loading || !valid}>
                            {loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{ marginRight: "10px" }} /> : <SaveIcon style={{ marginRight: "10px" }} />} Spremi
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => {
                                if (setCanceled) {
                                    setCanceled(true);
                                }
                                setFormOpened(false);
                            }}
                            disabled={loading}
                        >
                            <ExitToApp style={{ marginRight: "10px" }}></ExitToApp>Zatvori
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default MjestoForm;
