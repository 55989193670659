import { Alert, Grow, Autocomplete, ClickAwayListener, FormControl, ListItem, ListItemText, Modal, Paper, Popper, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState, useCallback, useContext } from "react";
import api from "../util/api";
import Util from "../util/util";
import AddEditDelForm from "./addEditDelForm";
import ComponentContainer from "./componentContainer";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CjenikForm from "./cjenikForm";
import AlertDialog from "./alertdialog";
import globalContext from "./globalContext";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};
const CustomPaper = (props) => {
    return <Paper {...props} sx={{ maxHeight: 200}} />;
  };

function Cjenik(props) {

    const useConstructor = Util.useConstructor();
    const isMobileDevice = Util.isMobileDevice();

    const global = useContext(globalContext);

    const firmaID = props.firmaID;
    const predmetNaziv = props.predmetNaziv;
    const predmeti = props.predmeti;
    const setCjenikOpened = props.setCjenikOpened;
    
    const [cjenici, setCjenici] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingPredmet, setLoadingPredmet] = useState(true);
    const [enableScrolling, setEnableScrolling] = useState(false);
    const [current, setCurrent] = useState(null);
    const [predmetID, setPredmetID] = useState(props.predmetID)
    const [predmet, setPredmet] = useState(props.predmet);
    const [valutaKonzistentna, setValutaKonzistentna] = useState(true);

    const setCjenikChanged = props.setCjenikChanged;
    const cjenikChanged = props.cjenikChanged;

    const [formOpened, setFormOpened] = useState(false);
    const [formCjenik, setFormCjenik] = useState(null);
    const [postojeciTipoviKorisnika, setPostojeciTipoviKorisnika] = useState([]);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [postojeNepreneseneAktivnosti, setPostojeNepreneseneAktivnosti] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");


    useConstructor(() => {
        load();
    });

    function load() {
        setLoading(true);
        api.get("cjenik/predmet/" + predmetID, loadSuccess, loadFail);
    }

    function loadSuccess(data) {
        setCjenici(data);
        setLoading(false);
        setEnableScrolling(true);
        if(current){
            let test = data.find(c=>c.TipKorisnikaID == current.TipKorisnikaID);
            if(test){
                setCurrent(test);
            }
            else{
                if(data.length > 0){
                    setCurrent(data[0]);
                }                
            }
        }
        else{
            if(data.length > 0){
                setCurrent(data[0]);
            }
        }
    }

    function loadFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
        setEnableScrolling(true);
    }

    function loadPredmet() {
        setLoadingPredmet(true);
        predmeti.forEach(predmet => {
            if (predmet.PredmetID == predmetID) {
                setPredmet(predmet);
            }
        })
        setLoadingPredmet(false);
    }

    useEffect(() => loadPredmet(), [predmeti, predmetID]);
    useEffect(() => load(), [predmet]);

    function handlePredmetchange(e,v) {
        if (v) {
            setPredmetID(v.PredmetID);
        }
    }

    function openForm(type) {
        if (type == "add") {
            setFormCjenik(null);
        } else {
            setFormCjenik(current);
        }
        setFormOpened(true);
    }

    function getPostojeciTipoviKorisnika() {
        if (cjenici) {
            let postojeciTipovi = [];
            cjenici.forEach(cjenik => {
                postojeciTipovi.push(cjenik.TipKorisnikaID);
            })
            setPostojeciTipoviKorisnika(postojeciTipovi);
        }
    }

    useEffect(() => getPostojeciTipoviKorisnika(), [cjenici]);
    useEffect(() => {
        if(!formOpened){
            load();
        }
    }, [formOpened]);

    useEffect(() => {
        let konzistentna = true;
        if(cjenikChanged){
            if(predmet){
                //ako se promijenio predmet, idemo pogledati da li je valuta konzistentna
                if(predmet.Cap){
                    //ovdje se ne bi smjela dogoditi greška, ali ipak idemo provjeriti
                    konzistentna = Util.valutaKonzistentna(predmet, cjenici, predmet.FirmaValuta);
                }
                else{
                    //ovo je provjera na nivou da li ima različitih valuta
                    konzistentna = Util.valutaKonzistentna(predmet, cjenici, null);
                }
            }
            setValutaKonzistentna(konzistentna);    
        }
    }, [predmet, cjenikChanged]);

    function deleteCjenik() {
        setLoading(true);
        api.get("cjenik/delete/" + current.CjenikID, deleteSuccess, deleteFail);
    }

    function deleteSuccess(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
        setDeleteAlert(false);
        load();
        setCjenikChanged(true);
    }

    function deleteFail() {
        setLoading(false);
    }

    const handleKeyPressShorcuts = useCallback((event) => {
        if (event.altKey) {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if (charCode == 'd' && global.roles.includes("19")) {
                event.preventDefault();
                if (!loading) {
                    openForm("add");
                }
            }     
            if(charCode == 'k'){
                event.preventDefault();
                if (!loading) {
                    props.setPredmetCreatedAlert(false)
                }            
            }      
        }
    }, [loading, props.predmetCreatedAlert]);

    const handleEsc = useCallback((event) => {
        if(!formOpened){
            if(event.keyCode == 27){
                event.preventDefault();
                if (!loading) {
                    setCjenikOpened(false);
                }            
            }
        }
    }, [loading]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressShorcuts);
        document.addEventListener('keyup', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleKeyPressShorcuts);
            document.removeEventListener('keyup', handleEsc);
        };
    }, [handleKeyPressShorcuts]);

    function handleDeleteClick() {
        setLoading(true);
        api.get("cjenik/checkbeforedelete?cjenikID=" + current.CjenikID, checkBeforeDeleteSuccess, checkBeforeDeleteFail);
    }

    function checkBeforeDeleteSuccess(data) {
        setLoading(false);
        if (data) {
            setPostojeNepreneseneAktivnosti(true);
        } else {
            setDeleteAlert(true);
        }
    }

    function checkBeforeDeleteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading || loadingPredmet}>
            <Typography variant="h5" gutterBottom component="div" style={{width:"40%"}}>
                <MenuBookIcon fontSize="small" style={{marginRight:"15px"}}></MenuBookIcon>
                Cjenik
            </Typography>

            <div style={{width:"60%"}}>
                <FormControl fullWidth>
                    <Autocomplete style={{width:"100%"}} 
                        ListboxProps={{
                            sx: {
                                minHeight: 0,
                                maxHeight: "11rem"
                            }
                        }}
                        disablePortal
                        id="predmet-select"
                        disabled={!predmeti}
                        options={predmeti.filter(p => p.TipNaplateID == 1)}
                        autoHighlight
                        value = {predmetID != null && predmet != null ? predmet : null}
                        getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                        onChange={handlePredmetchange}
                        renderOption={(props, option) => {
                            return (
                              <li {...props} key={"predmet" + option.PredmetID}>
                                {option.Broj + " - " + option.Naziv}
                              </li>
                            );
                        }}
                        filterOptions={(options, state) => options.filter(option => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                        renderInput={(params) => <TextField {...params} label="Predmet" size="small" />}
                    />
                </FormControl>
            </div>

          
            <Box style={{width:"100%", marginTop:"20px"}}>
                <AddEditDelForm dataType="cjenik" data={cjenici} current={current} setCurrent={setCurrent} add={() => openForm("add")} edit={() => openForm("edit")} del={handleDeleteClick}></AddEditDelForm>
            </Box>

            {!valutaKonzistentna ? (
                <Box item xs={12} style={{paddingTop:'16px'}}>
                    <Alert severity="error">
                        Za odabrani predmet postoje cjenici koji nemaju istu valutu. To nije zabranjeno, ali je neobično, pa obratite pažnju i provjerite da li je to željeno stanje. Dodatno, s takvim stanjem nije moguće predmet definirati s Cap/Max iznosom.
                    </Alert>
                </Box>
            ) : null}  


            <Modal open={formOpened}>
                <Box sx={style} overflow={enableScrolling ? "auto" : "hidden"} style={isMobileDevice ? {padding:"10px", width:"90%"} : {}}>
                    <CjenikForm setCjenikChanged={setCjenikChanged} predmet={predmet} firmaID={firmaID} formCjenik={formCjenik} postojeciTipoviKorisnika={postojeciTipoviKorisnika} setFormOpened={setFormOpened}></CjenikForm>
                </Box>
            </Modal>

            {deleteAlert ? <AlertDialog title={"Brisanje cjenika"} message={"Jeste li sigurni da želite obrisati cjenik za predmet '" + predmet.Naziv + "' i tip korisnika '" + current.TipKorisnika + "'?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => setDeleteAlert(false)} confirmAction={deleteCjenik}></AlertDialog> : null}
        
            {postojeNepreneseneAktivnosti ? <AlertDialog title={"Upozorenje na neprenesene aktivnosti"} message={"Postoje neprenesene aktivnosti po cjeniku za predmet '" + predmet.Naziv + "' i tip korisnika '" + current.TipKorisnika  + "'. Ukoliko nastavite, aktivnosti će poprimiti iznos 0,00. Želite li nastaviti?"} cancelLabel={"Odustani"} confirmLabel={"Nastavi"} cancelAction={() => {setPostojeNepreneseneAktivnosti(false);}} confirmAction={() => {setPostojeNepreneseneAktivnosti(false); setDeleteAlert(true);}}></AlertDialog> : null}

            {props.predmetCreatedAlert ? <AlertDialog title={"Upis cjenika za novi predmet"} message={"Upravo je kreiran predmet: '" + predmet.Naziv + "', koji ima tip naplate 'Satnica' pa se preporuča upis cjenika."} cancelHidden={true} confirmHidden={true} okHidden={false} okLabel={<span>OK&#818;</span>} okAction={() => {props.setPredmetCreatedAlert(false);}}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default Cjenik;