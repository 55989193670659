import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

export default function Test() {
  const data  = [{ID: 1, Naziv:"1"},{ID: 2, Naziv:"2"},{ID: 3, Naziv:"3"},{ID: 4, Naziv:"4"},{ID: 5, Naziv:"5"},{ID: 6, Naziv:"6"},{ID: 7, Naziv:"7"},{ID: 8, Naziv:"8"}]

    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(5);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        onPageSizeChange={(pgsz, details) => {
          setPageSize(pgsz);
        }}
        pageSize={pageSize}
        onPageChange={(p, details) => {
            setPage(p)
        }}
        page={page}
        rowsPerPageOptions={[5, 10, 100, 200, 1000]}      
        columns={[
            {field: 'ID',
            headerName: 'ID',
            width: 75},
            {field: 'Naziv',
            headerName: 'Naziv',                                   
            width: 650},
        ]}
        getRowId={row => row.ID}
        rows={data}
      />
    </div>
  );
}