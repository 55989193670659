import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import ComponentContainer from "./componentContainer";

function RacuniIzvjestajBrisanje(props) {

    const data = props.data;
    const close = props.close;

    return (
        <ComponentContainer loading={false}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center"}}>
                        Izvještaj brisanja
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="button" gutterBottom component="div" style={{textAlign:"center"}}>
                        Broj aktivnosti vraćenih u nepreneseno stanje: {data.VracenoAktivnosti}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="button" gutterBottom component="div" style={{textAlign:"center"}}>
                        Broj troškova vraćenih u nepreneseno stanje: {data.VracenoTroskova}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button style={{float:"right"}} variant="contained" onClick={close}>U redu</Button>
                </Grid>
            </Grid>
        </ComponentContainer>
    );
}

export default RacuniIzvjestajBrisanje;