import { Button, FormControl, FormControlLabel, Grid, Input, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from "@mui/system";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import api from "../util/api";
import globalContext from "./globalContext";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function NepredmetnaKategorijaForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);
    
    const formKategorija = props.formKategorija;
    const setFormOpened = props.setFormOpened;
    const loadKategorije = props.loadKategorije;
    const firmaID = props.firmaID;

    const [loading, setLoading] = useState(false);
    const [naziv, setNaziv] = useState("");
    const [boja, setBoja] = useState("0,0,0");
    const [bojaHex, setBojaHex] = useState("#000000");
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        if (formKategorija) {
            ;
            setNaziv(formKategorija.Naziv);
            setBoja(formKategorija.Boja);
            let dec = formKategorija.Boja.split(",");
            let red = parseInt(dec[0]).toString(16).padStart(2, "0");
            let green = parseInt(dec[1]).toString(16).padStart(2, "0");
            let blue = parseInt(dec[2]).toString(16).padStart(2, "0");
            setBojaHex("#" + red + green + blue);
        }
    });

    function handleColorChange(e) {
        let hex = e.target.value;
        setBojaHex(hex);
        let red = parseInt(hex.substr(1,2), 16);
        let green = parseInt(hex.substr(3,2), 16);
        let blue = parseInt(hex.substr(5,2), 16);
        setBoja(red + "," + green + "," + blue);
        ;
    }

    function save() {
        ;
        let kategorija = {
            NepredmetnaKategorijaID : formKategorija ? formKategorija.NepredmetnaKategorijaID : -1,
            Naziv : naziv,
            FirmaID : firmaID,
            Boja : boja
        }
        setLoading(true);
        api.post("nepredmetnaKategorija/add", kategorija, saveSuccess, saveFail);
    }

    function saveSuccess() {
        loadKategorije();
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formKategorija ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <AddIcon fontSize="medium" style={{marginRight:"15px"}}></AddIcon>
                            )}
                            {formKategorija ? (
                                "Uređivanje nepredmetne kategorije"
                            ) : (
                                "Nova nepredmetna kategorija"
                            )}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField value={naziv} label="Naziv" inputProps={{ maxLength: 50 }} onChange={e => setNaziv(e.target.value)} error={!naziv} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12} marginTop="5px">
                    <Typography variant="button" gutterBottom component="span" style={{height:"100%", marginLeft:"5px", marginRight:"10px"}}>Boja: </Typography>
                    <span style={{border:"solid", borderWidth:"1px", borderRadius:"4px", fontSize:"17px", display:"inline-block", verticalAlign:"middle"}}>
                        <input type="color" id="colorpicker" value={bojaHex} onChange={handleColorChange} style={{border:"none", marginTop:"2px", marginBottom:"2px", marginLeft:"2px", cursor:"pointer"}}></input>
                        <label htmlFor="colorpicker" style={{marginBottom:"4px", marginRight:"4px", cursor:"pointer"}}>&nbsp;&nbsp;Odaberi</label>
                    </span>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" color="success" style={{marginRight:"10px"}} disabled={!naziv || !global.roles.includes("20")}><SaveIcon style={{marginRight:"10px"}} /> Spremi</Button>
                        <Button variant="outlined" color="error" onClick={() => {setFormOpened(false);}} ><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default NepredmetnaKategorijaForm;