import { Autocomplete, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import globalContext from "./globalContext";
import api from "../util/api";
import AddCardIcon from '@mui/icons-material/AddCard';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function NacinPlacanjaForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formNacin = props.formNacin;
    const setFormOpened = props.setFormOpened;

    const [loading, setLoading] = useState(true);
    const [naziv, setNaziv] = useState("");
    const [fiskalniNaciniPlacanja, setFiskalniNaciniPlacanja] = useState([]);
    const [fiskalniNacinPlacanja, setFiskalniNacinPlacanja] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        api.get("nacinplacanja/getfiskalni", loadInfoSuccess, loadInfoFail);
    });

    function loadInfoSuccess(data) {
        setFiskalniNaciniPlacanja(data);
        if (formNacin) {
            setNaziv(formNacin.Naziv);
            setFiskalniNacinPlacanja(formNacin.FiskalniNacinPlacanja);
        }
        setLoading(false);
    }

    function loadInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save() {
        let nacinPlacanja = {
            NacinPlacanjaID : formNacin ? formNacin.NacinPlacanjaID : -1,
            Naziv : naziv,
            FirmaID : global.user.firmaID,
            FiskalniNacinPlacanjaID : fiskalniNacinPlacanja.FiskalniNacinPlacanjaID
        }
        setLoading(true);
        api.post("nacinplacanja/save", nacinPlacanja, saveSuccess, saveFail);
    }

    function saveSuccess() {
        setLoading(false);
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formNacin ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <AddCardIcon fontSize="medium" style={{marginRight:"15px"}}></AddCardIcon>
                            )}
                            {formNacin ? (
                                "Uređivanje načina plaćanja"
                            ) : (
                                "Novi način plaćanja"
                            )}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Naziv" value={naziv} inputProps={{ maxLength: 250 }} onChange={e => setNaziv(e.target.value)} size="small" error={!naziv} required></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            options={fiskalniNaciniPlacanja}
                            autoHighlight
                            getOptionLabel={(option) => option.Naziv + " (" + option.Oznaka + ")"}
                            value={fiskalniNacinPlacanja}
                            onChange={(e,v) => {if (v) setFiskalniNacinPlacanja(v)}}
                            renderInput={(params) => <TextField {...params} label="Vrsta" error={!fiskalniNacinPlacanja} required size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" color="success" style={{marginRight:"10px"}} disabled={!naziv || !global.roles.includes("20") || !fiskalniNacinPlacanja}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <SaveIcon style={{marginRight:"10px"}} />} Spremi</Button>
                        <Button variant="outlined" color="error" onClick={() => {setFormOpened(false);}} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default NacinPlacanjaForm;