import { Alert, Autocomplete, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, Grow, List, ListItem, ListItemText, Modal, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useContext, useEffect, useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import { Box } from "@mui/system";
import SearchIcon from '@mui/icons-material/Search';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DoneIcon from '@mui/icons-material/Done';
import LineIcon from "react-lineicons";
import dateFormat, { masks } from "dateformat";
import database from "../util/database";
import AlertDialog from "./alertdialog";
import IzvjestajPrijenosa from "./izvjestajPrijenosa";
import Stack from '@mui/material/Stack';
import { hr } from "date-fns/locale";
import ErrorIcon from '@mui/icons-material/Error';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

const prijenosNaSpecifikacijuOptions = [
    { Naziv : "Prebaci sve na istu specifikaciju", Vrijednost : 1},
    { Naziv : "Prebaci evidencije na specifikaciju definiranu za njihov tip", Vrijednost : 2},
    { Naziv : "Prebaci evidencije na specifikaciju definiranu za njihov tip, uz spajanje specifikacija s istim nazivom", Vrijednost: 3}
];

const fiksnaNaplataOptions = [
    { Naziv : "Samo predmeti s cjenicima i tarifama", FiksnaNaplata : false },
    { Naziv : "Samo predmeti s fiksnom naplatom", FiksnaNaplata : true }
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '95%',
    maxWidth: '95%'
};

function PrijenosAktivnosti(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const klijentiPredmeti = props.klijentiPredmeti || {
        Klijenti : [],
        Predmeti: [],
        KlijentiLastUpdate : null,
        PredmetiLastUpdate : null
    };
    const loadKlijentiAndPredmeti = props.loadKlijentiAndPredmeti;

    const [loading, setLoading] = useState(true);
    const [razdoblje, setRazdoblje] = useState(false);
    const [datumOd, setDatumOd] = useState(null);
    const [datumDo, setDatumDo] = useState(null);
    const [samoAktivniKlijenti, setSamoAktivniKlijenti] = useState(true);
    const [samoNaplatneAktivnosti, setSamoNaplatneAktivnosti] = useState(true);
    const [samoZaZaposlenika, setSamoZaZaposlenika] = useState(false);
    const [zaposlenik, setZaposlenik] = useState(null);
    const [zaposlenici, setZaposlenici] = useState([]);
    const [samoPoGrupi, setSamoPoGrupi] = useState(false);
    const [grupa, setGrupa] = useState(null);
    const [grupe, setGrupe] = useState([]);
    const [klijenti, setKlijenti] = useState([]);
    const [klijent, setKlijent] = useState(null);
    const [predmeti, setPredmeti] = useState([]);
    const [predmet, setPredmet] = useState(null);
    const [predmetAktivnosti, setPredmetAktivnosti] = useState([]);
    const [odabraniPredmetiIDs, setOdabraniPredmetiIDs] = useState([]);
    const [naDrugogKlijenta, setNaDrugogKlijenta] = useState(false);
    const [drugiKlijent, setDrugiKlijent] = useState(null);
    const [noviRacun, setNoviRacun] = useState(true);
    const [novaSpecifikacija, setNovaSpecifikacija] = useState(true);
    const [racuni, setRacuni] = useState([]);
    const [postojeciRacun, setPostojeciRacun] = useState(null);
    const [postojecaSpecifikacija, setPostojecaSpecifikacija] = useState(null);
    const [opis, setOpis] = useState("");
    const [nacinPlacanjaOptions, setNacinPlacanjaOptions] = useState([]);
    const [nacinPlacanja, setNacinPlacanja] = useState(null);
    const [kontoOptions, setKontoOptions] = useState([]);
    const [konto, setKonto] = useState(null);
    const [kontoSatnice, setKontoSatnice] = useState(null);
    const [kontoTroskovi, setKontoTroskovi] = useState(null);
    const [kontoTarife, setKontoTarife] = useState(null);
    const [datumPrijenosa, setDatumPrijenosa] = useState(new Date());
    const [domaci, setDomaci] = useState(true);
    const [valute, setValute] = useState([]);
    const [valuta, setValuta] = useState(null);
    const [izradioZaposlenik, setIzradioZaposlenik] = useState(null);
    const [specifikacijaOption, setSpecifikacijaOption] = useState(prijenosNaSpecifikacijuOptions.at(0));
    const [opisSatnice, setOpisSatnice] = useState("");
    const [opisTarife, setOpisTarife] = useState("");
    const [opisTroskovi, setOpisTroskovi] = useState("");
    const [tecajExists, setTecajExists] = useState(true);
    const [prebaciSveAlert, setPrebaciSveAlert] = useState(false);
    const [izvjestajOpened, setIzvjestajOpened] = useState(false);
    const [izvjestajData, setIzvjestajData] = useState(null);
    const [mogucePrebacitiEvidencijeBezTecaja, setMogucePrebacitiEvidencijeBezTecaja] = useState(false);
    const [fiksnaNaplata, setFiksnaNaplata] = useState(fiksnaNaplataOptions[0]);
    const [kontoRacuna, setKontoRacuna] = useState(null);
    const [defaultKonto, setDefaultKonto] = useState(null);
    const [defaultKontoStrani, setDefaultKontoStrani] = useState(null);
    const [kontoVidljivZaStavku, setKontoVidljivZaStavku] = useState(null);
    const [analitikaTroskova, setAnalitikaTroskova] = useState(false);
    const [prijevodi, setPrijevodi] = useState({
        ZajednickaSpecifikacija : [],
        Satnice : [],
        Tarife : [],
        Troskovi : []
    });
    const [nemaNicegaZaPrebacaj, setNemaNicegaZaPrebacaj] = useState(false);
    const [odabraniPredmetiZaKlijenta, setOdabraniPredmetiZaKlijenta] = useState(false);
    const [formValid, setFormValid] = useState(false);
    const [logoutAlert, setLogoutAlert] = useState(false);
    const [filterHistory, setFilterHistory] = useState({
        DatumOd : null,
        DatumDo : null,
        SamoAktivniKlijenti : null,
        SamoNaplatneAktivnosti : null,
        Zaposlenik : null,
        Grupa : null,
        FiksnaNaplata : null
    });
    const [errorMessage, setErrorMessage] = useState(null);
    const [automatskiFilter, setAutomatskiFilter] = useState(false);
    const [nepreneseniKlijenti, setNepreneseniKlijenti] = useState(null);

    useConstructor(() => {

        // if (klijentiPredmeti.KlijentiLastUpdate != "" && klijentiPredmeti.KlijentiLastUpdate != null && klijentiPredmeti.PredmetiLastUpdate != "" && klijentiPredmeti.PredmetiLastUpdate != null) {
        //     let resource = {
        //         FirmaID : global.user.firmaID,
        //         KlijentiLastUpdate : klijentiPredmeti.KlijentiLastUpdate,
        //         PredmetiLastUpdate : klijentiPredmeti.PredmetiLastUpdate
        //     }
        //     api.post("firma/checkifklijentiorpredmetiunchanged", resource, checkIfKlijentiOrPredmetiUnchangedSuccess, checkIfKlijentiOrPredmetiUnchangedFail);
        // }

        if (klijentiPredmeti.KlijentiLastUpdate != "" && klijentiPredmeti.KlijentiLastUpdate != null && klijentiPredmeti.PredmetiLastUpdate != "" && klijentiPredmeti.PredmetiLastUpdate != null) {
            let resource = {
                FirmaID : global.user.firmaID,
                KlijentiLastUpdate : klijentiPredmeti.KlijentiLastUpdate,
                PredmetiLastUpdate : klijentiPredmeti.PredmetiLastUpdate,
                KorisnikID : global.user.korisnikID
            }
            api.post("firma/checkeverything", resource, checkEverythingSuccess, checkEverythingFail);
        }

    });

    function checkIfKlijentiOrPredmetiUnchangedSuccess(data) {
        if (data.KlijentiChanged || data.PredmetiChanged) {
            loadKlijentiAndPredmeti(global.user.firmaID, loadFilterInfo, data.KlijentiChanged, data.PredmetiChanged);
        } else {
            loadFilterInfo();
        }
    }

    function checkIfKlijentiOrPredmetiUnchangedFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function startLogout(){
        setLogoutAlert(false);
        Util.logout();
        window.location = "/#/login";
    }

    function checkEverythingSuccess(data) {
        if(data && (data.UserAccountValid == false || data.LogoutRequired)){
            setLoading(false);
            setLogoutAlert(true);
            return;          
        }

        if (data.CheckIfKlijentiOrPredmetiUnchangedResource == false) {
            loadKlijentiAndPredmeti(global.user.firmaID, loadFilterInfo, data.KlijentiChanged, data.PredmetiChanged);
        } else {
            loadFilterInfo();
        }
    }

    function checkEverythingFail() {

    }

    function loadFilterInfo() {
        api.get("evidencije-into-racun/getfilterinfo/" + global.user.firmaID, loadFilterInfoSuccess, loadFilterInfoFail);
    }

    function loadFilterInfoSuccess(data) {
        
        setZaposlenik(data.Zaposlenik);

        setIzradioZaposlenik(data.Zaposlenik.PotpisnaOsoba ? data.Zaposlenik : null);
        
        setZaposlenici(data.Zaposlenici);
        setGrupe(data.Grupe);
        setOpis(data.NazivNoveZajednickeSpecifikacije);
        setOpisSatnice(data.NazivNoveSatnicaSpecifikacije);
        setOpisTarife(data.NazivNoveTarifaSpecifikacije);
        setOpisTroskovi(data.NazivNoveTrosakSpecifikacije);
        setNacinPlacanja(data.DefaultNacinPlacanja);
        setNacinPlacanjaOptions(data.NacinPlacanjaOptions);
        setKontoOptions(data.KontoOptions);
        setValute(data.FirmaValutaOptions);
        setValuta(data.FirmaValuta);
        setKonto(data.DefaultKonto);
        setKontoSatnice(data.DefaultSatniceKonto);
        setKontoTroskovi(data.DefaultTroskoviKonto);
        setKontoTarife(data.DefaultTarifeKonto);
        setRazdoblje(data.KorisnikFilter.DatumOd && data.KorisnikFilter.DatumDo);
        setDatumOd(data.KorisnikFilter.DatumOd);
        setDatumDo(data.KorisnikFilter.DatumDo);
        setSamoAktivniKlijenti(data.KorisnikFilter.SamoAktivniKlijenti);
        setSamoNaplatneAktivnosti(data.KorisnikFilter.SamoNaplatneAktivnosti);
        setSamoZaZaposlenika(data.KorisnikFilter.Zaposlenik != null);
        setZaposlenik(data.KorisnikFilter.Zaposlenik);
        setSamoPoGrupi(data.KorisnikFilter.Grupa != null);
        setGrupa(data.KorisnikFilter.Grupa);
        setMogucePrebacitiEvidencijeBezTecaja(data.MogucePrebacitiEvidencijeBezTecaja);
        setDefaultKonto(data.DefaultKonto);
        setDefaultKontoStrani(data.DefaultKontoStrani);
        setKontoVidljivZaStavku(data.KontoVidljivZaStavku);
        setAnalitikaTroskova(data.AnalitikaTroskova);
        setPrijevodi(data.Prijevodi);
        
        if (data.PotpisnaOsobaID) {
            setIzradioZaposlenik(data.Zaposlenici.find(z => z.KorisnikID == data.PotpisnaOsobaID));
        }

        if (data.OpcijaPrijenosa) {
            setSpecifikacijaOption(prijenosNaSpecifikacijuOptions.find(o => o.Vrijednost == data.OpcijaPrijenosa));
        }

        setLoading(false);
    }

    function loadFilterInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function filter() {
        setLoading(true);
        setKlijent(null);
        setDrugiKlijent(null);
        let resource = {
            DatumOd : razdoblje ? datumOd : null,
            DatumDo : razdoblje ? datumDo : null,
            SamoAktivniKlijenti : samoAktivniKlijenti,
            SamoNaplatneAktivnosti : samoNaplatneAktivnosti,
            Zaposlenik : samoZaZaposlenika ? zaposlenik : null,
            Grupa : samoPoGrupi ? grupa : null,
            FiksnaNaplata : fiksnaNaplata.FiksnaNaplata
        }
        setFilterHistory(JSON.parse(JSON.stringify(resource)));
        api.post("evidencije-into-racun/filter", resource, filterSuccess, filterFail);
    }

    function filterSuccess(data) {
        let klijentiList = [];
        let predmetAktivnostiList = [];
        data.forEach(d => {
            klijentiList.push(d.Klijent);
            d.PredmetAktivnosti.forEach(a => {
                predmetAktivnostiList.push(a);
            });
        });
        setKlijenti(klijentiList);
        setPredmetAktivnosti(predmetAktivnostiList);
        if (predmetAktivnostiList.filter(pa => pa.MogucePrebaciti).length == 0) {
            setNemaNicegaZaPrebacaj(true);
        }
        setLoading(false);
    }

    useEffect(() => oznaciSve(), [predmetAktivnosti]);

    useEffect(() => {
        if (klijenti.length > 0) {
            setKlijent(klijenti.at(0));
        }
    }, [klijenti]);

    useEffect(() => {
        if (fiksnaNaplata.FiksnaNaplata) {
            setNovaSpecifikacija(true);
            setPostojecaSpecifikacija(null);
        }
    }, [fiksnaNaplata]);

    useEffect(() => {
        if (klijent) {
            setOdabraniPredmetiZaKlijenta(predmetAktivnosti.filter(pa => pa.KlijentID == klijent.KlijentID && odabraniPredmetiIDs.includes(pa.PredmetID)).length > 0);
        } else {
            setOdabraniPredmetiZaKlijenta(false);
        }
    }, [klijent, odabraniPredmetiIDs]);

    useEffect(() => 
    {
        let valid = datumPrijenosa && !isNaN(new Date(datumPrijenosa)) && izradioZaposlenik && nacinPlacanja && !(specifikacijaOption.Vrijednost == 1 && !opis) && !(specifikacijaOption.Vrijednost != 1 && (!opisSatnice || !opisTarife || !opisTroskovi));
        setFormValid(valid);
    }, [datumPrijenosa, izradioZaposlenik, nacinPlacanja, specifikacijaOption, opis, opisSatnice, opisTroskovi, opisTarife]);

    function filterFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handlePredmetClick(predmetID) {
        if (fiksnaNaplata.FiksnaNaplata) {
            let newOdabraniPredmetiIDs = [];
            let pa = predmetAktivnosti.find(pa => pa.PredmetID == predmetID)
            if(pa.Iznos != null){
                newOdabraniPredmetiIDs.push(predmetID);
                setOdabraniPredmetiIDs(newOdabraniPredmetiIDs);    
            }
            else{
                setOdabraniPredmetiIDs([]);    
            }
        } else {
            if (odabraniPredmetiIDs.includes(predmetID)) {
                let newOdabraniPredmetiIDs = odabraniPredmetiIDs.filter(p => {
                    return p != predmetID;
                });
                setOdabraniPredmetiIDs(newOdabraniPredmetiIDs);
            } else {
                setOdabraniPredmetiIDs(ogOdabraniPredmetiIDs => [...ogOdabraniPredmetiIDs, predmetID]);
            }
        }
    }

    function oznaciSve() {
        let newOdabraniPredmetiIDs = [];
        if (fiksnaNaplata.FiksnaNaplata) {
            let prviPredmet = predmetAktivnosti.find(pa => klijent && klijent.KlijentID == pa.KlijentID && pa.MogucePrebaciti == true && pa.Iznos != null);
            if (prviPredmet) {
                newOdabraniPredmetiIDs.push(prviPredmet.PredmetID);
            }
        } else {
            predmetAktivnosti.forEach(pa => {
                if (pa.MogucePrebaciti) {
                    newOdabraniPredmetiIDs.push(pa.PredmetID);
                }
            })
        }
        setOdabraniPredmetiIDs(newOdabraniPredmetiIDs);
    }

    function odznaciSve() {
        setOdabraniPredmetiIDs([]);
    }

    useEffect(() => loadDetalji(), [klijent, naDrugogKlijenta, drugiKlijent]);

    function loadDetalji() {
        if (naDrugogKlijenta && drugiKlijent) {
            setLoading(true);
            api.get("evidencije-into-racun/detalji/" + drugiKlijent.KlijentID, loadDetaljiSuccess, loadDetaljiFail);

        } else if (!naDrugogKlijenta && klijent) {
            setLoading(true);
            api.get("evidencije-into-racun/detalji/" + klijent.KlijentID, loadDetaljiSuccess, loadDetaljiFail);
        }
    }

    function loadDetaljiSuccess(data) {
        setRacuni(data.Racuni);
        setDomaci(data.Domaci);
        setValuta(data.FirmaValuta);
        if (naDrugogKlijenta && drugiKlijent) {
            var zajednickaSpecifikacijaPrijevod = prijevodi.ZajednickaSpecifikacija.find(p => p.FirmaJezikID == drugiKlijent.FirmaJezikID);
            if (zajednickaSpecifikacijaPrijevod) {
                setOpis(zajednickaSpecifikacijaPrijevod.Prijevod);
            }
            var satnicePrijevod = prijevodi.Satnice.find(p => p.FirmaJezikID == drugiKlijent.FirmaJezikID);
            if (satnicePrijevod) {
                setOpisSatnice(satnicePrijevod.Prijevod);
            }
            var tarifePrijevod = prijevodi.Tarife.find(p => p.FirmaJezikID == drugiKlijent.FirmaJezikID);
            if (tarifePrijevod) {
                setOpisTarife(tarifePrijevod.Prijevod);
            }
            var troskoviPrijevod = prijevodi.Troskovi.find(p => p.FirmaJezikID == drugiKlijent.FirmaJezikID);
            if (troskoviPrijevod) {
                setOpisTroskovi(troskoviPrijevod.Prijevod);
            }
        } else if (!naDrugogKlijenta && klijent) {
            var zajednickaSpecifikacijaPrijevod = prijevodi.ZajednickaSpecifikacija.find(p => p.FirmaJezikID == klijent.FirmaJezikID);
            if (zajednickaSpecifikacijaPrijevod) {
                setOpis(zajednickaSpecifikacijaPrijevod.Prijevod);
            }
            var satnicePrijevod = prijevodi.Satnice.find(p => p.FirmaJezikID == klijent.FirmaJezikID);
            if (satnicePrijevod) {
                setOpisSatnice(satnicePrijevod.Prijevod);
            }
            var tarifePrijevod = prijevodi.Tarife.find(p => p.FirmaJezikID == klijent.FirmaJezikID);
            if (tarifePrijevod) {
                setOpisTarife(tarifePrijevod.Prijevod);
            }
            var troskoviPrijevod = prijevodi.Troskovi.find(p => p.FirmaJezikID == klijent.FirmaJezikID);
            if (troskoviPrijevod) {
                setOpisTroskovi(troskoviPrijevod.Prijevod);
            }
        }
        setLoading(false);
    }

    function loadDetaljiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        setPostojecaSpecifikacija(null);
        if (postojeciRacun) {
            setDomaci(postojeciRacun.TipDrzavnostiID == 1);
            valute.forEach(v => {
                if (v.FirmaValutaID == postojeciRacun.FirmaValutaID) {
                    setValuta(v);
                }
            })
        }
        else{
            setNovaSpecifikacija(true);
        }
    }, [postojeciRacun]);

    useEffect(() => {
        if (!naDrugogKlijenta) {
            setDrugiKlijent(null);
        }
    }, [naDrugogKlijenta])

    useEffect(() => {
        if (drugiKlijent) {
            setLoading(true); 
            api.get("racun/getnefakturirani/" + drugiKlijent.KlijentID, loadRacuniSuccess, loadRacuniFail);
        } else if (klijent) {
            setLoading(true);
            api.get("racun/getnefakturirani/" + klijent.KlijentID, loadRacuniSuccess, loadRacuniFail);
        }
    }, [drugiKlijent]);

    function loadRacuniSuccess(data) {
        setRacuni(data.filter(r => r.TipRacunaID == 1));
        setLoading(false);
    }

    function loadRacuniFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function prebaciSve() {
        setLoading(true);
        setAutomatskiFilter(true);
        let resource = {
            KlijentID : klijent ? klijent.KlijentID : -1,
            DrugiKlijentID : -1,
            OdabraniPredmetiIDs : odabraniPredmetiIDs,
            RacunID : -1,
            Opis : opis,
            OpisSatnice : opisSatnice,
            OpisTarife : opisTarife,
            OpisTroskovi : opisTroskovi,
            RacunKontoID : kontoRacuna ? kontoRacuna.KontoID : null,
            KontoID : konto ? konto.KontoID : -1,
            SatniceKontoID : kontoSatnice ? kontoSatnice.KontoID : -1,
            TroskoviKontoID : kontoTroskovi ? kontoTroskovi.KontoID : -1,
            TarifeKontoID : kontoTarife ? kontoTarife.KontoID : -1,
            DatumPrijenosa : datumPrijenosa,
            FirmaValutaID : valuta.FirmaValutaID,
            TipDrzavnostiID : -1,
            KorisnikID : izradioZaposlenik ? izradioZaposlenik.KorisnikID : -1,
            NacinStvaranjaSpecifikacija : specifikacijaOption.Vrijednost,
            PredmetAktivnostiList : predmetAktivnosti,
            NacinPlacanjaID: nacinPlacanja ? nacinPlacanja.NacinPlacanjaID : -1,
            Prijevodi : prijevodi,
            AnalitikaTroskova : analitikaTroskova,
            FilterHistory : filterHistory
        }
        api.post("racun/prebacisve", resource, prebaciSuccess, prebaciFail);
    }

    function prebaci() {
        setLoading(true);
        setAutomatskiFilter(true);
        let resource = {
            KlijentID : klijent ? klijent.KlijentID : -1,
            DrugiKlijentID : drugiKlijent ? drugiKlijent.KlijentID : -1,
            OdabraniPredmetiIDs : odabraniPredmetiIDs,
            RacunID : noviRacun ? -1 : postojeciRacun.RacunID,
            SpecifikacijaID: novaSpecifikacija ? -1 : postojecaSpecifikacija.RacunskaStavkaID,
            Opis : opis,
            OpisSatnice : opisSatnice,
            OpisTarife : opisTarife,
            OpisTroskovi : opisTroskovi,
            RacunKontoID : kontoRacuna ? kontoRacuna.KontoID : null,
            KontoID : konto ? konto.KontoID : -1,
            SatniceKontoID : kontoSatnice ? kontoSatnice.KontoID : -1,
            TroskoviKontoID : kontoTroskovi ? kontoTroskovi.KontoID : -1,
            TarifeKontoID : kontoTarife ? kontoTarife.KontoID : -1,
            DatumPrijenosa : datumPrijenosa,
            FirmaValutaID : valuta.FirmaValutaID,
            TipDrzavnostiID : domaci ? 1 : 2,
            KorisnikID : izradioZaposlenik ? izradioZaposlenik.KorisnikID : -1,
            FirmaID : global.user.firmaID,
            NacinStvaranjaSpecifikacija : specifikacijaOption.Vrijednost,
            PredmetAktivnostiList : predmetAktivnosti,
            NacinPlacanjaID: nacinPlacanja ? nacinPlacanja.NacinPlacanjaID: -1,
            Prijevodi : prijevodi,
            AnalitikaTroskova : analitikaTroskova,
            FilterHistory : filterHistory
        }
        api.post("racun/prebaci", resource, prebaciSuccess, prebaciFail);
    }
    
    function prebaciSuccess(data) {
        setPrebaciSveAlert(false);
        setIzvjestajData(data);
        setIzvjestajOpened(true);
        filter();    
    }

    function prebaciFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        if (noviRacun) {
            setNovaSpecifikacija(true);
            setPostojecaSpecifikacija(null);
            setPostojeciRacun(null);
        }
    }, [noviRacun]);

    useEffect(() => {
        if (novaSpecifikacija) {
            setPostojecaSpecifikacija(null);
        }
        else{
            setAnalitikaTroskova(false);
        }
    }, [novaSpecifikacija]);

    useEffect(() => {
        if (valuta && !isNaN(datumPrijenosa)) {
            checkTecaj()
        }
    }, [valuta, datumPrijenosa, odabraniPredmetiIDs]);

    function checkTecaj() {
        if (valuta) {
            let resource = {
                OdabraniPredmetiIDs : odabraniPredmetiIDs,
                RacunFirmaValutaID : valuta.FirmaValutaID,
                PredmetAktivnostiList : predmetAktivnosti,
                Datum : datumPrijenosa
            }
            api.post("evidencije-into-racun/checktecaj", resource, checkTecajSuccess, checkTecajFail);
        }
    }

    function checkTecajSuccess(data) {
        setTecajExists(data);
    }

    function checkTecajFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function findKontoRacuna() {
        if (klijent) {
            if (klijent.TipDrzavnostiID == 1) {
                setKontoRacuna(defaultKonto);
            } else {
                setKontoRacuna(defaultKontoStrani);
            }
        }
    }

    useEffect(() => findKontoRacuna(), [klijent]);

    function handleOpisChange(e) {
        setOpis(e.target.value);

        let firmaJezikID = 0;
        if (!naDrugogKlijenta && klijent) {
            firmaJezikID = klijent.FirmaJezikID;
        } else if (naDrugogKlijenta && drugiKlijent) {
            firmaJezikID = drugiKlijent.FirmaJezikID;
        }

        let zajednickaSpecifikacijaNew = [...prijevodi.ZajednickaSpecifikacija];
        let i = zajednickaSpecifikacijaNew.findIndex(p => p.FirmaJezikID == firmaJezikID);
        if (i >= 0) {
            zajednickaSpecifikacijaNew[i].Prijevod = e.target.value;
        }
        let prijevodiNew = {...prijevodi};
        prijevodiNew.ZajednickaSpecifikacija = zajednickaSpecifikacijaNew;
        setPrijevodi(prijevodiNew);
    }

    function handleOpisSatniceChange(e) {
        setOpisSatnice(e.target.value);

        let firmaJezikID = 0;
        if (!naDrugogKlijenta && klijent) {
            firmaJezikID = klijent.FirmaJezikID;
        } else if (naDrugogKlijenta && drugiKlijent) {
            firmaJezikID = drugiKlijent.FirmaJezikID;
        }

        let satniceNew = [...prijevodi.Satnice];
        let i = satniceNew.findIndex(p => p.FirmaJezikID == firmaJezikID);
        if (i >= 0) {
            satniceNew[i].Prijevod = e.target.value;
        }
        let prijevodiNew = {...prijevodi};
        prijevodiNew.Satnice = satniceNew;
        setPrijevodi(prijevodiNew);
    }

    function handleOpisTroskoviChange(e) {
        setOpisTroskovi(e.target.value);

        let firmaJezikID = 0;
        if (!naDrugogKlijenta && klijent) {
            firmaJezikID = klijent.FirmaJezikID;
        } else if (naDrugogKlijenta && drugiKlijent) {
            firmaJezikID = drugiKlijent.FirmaJezikID;
        }

        let troskoviNew = [...prijevodi.Troskovi];
        let i = troskoviNew.findIndex(p => p.FirmaJezikID == firmaJezikID);
        if (i >= 0) {
            troskoviNew[i].Prijevod = e.target.value;
        }
        let prijevodiNew = {...prijevodi};
        prijevodiNew.Troskovi = troskoviNew;
        setPrijevodi(prijevodiNew);
    }

    function handleOpisTarifeChange(e) {
        setOpisTarife(e.target.value);

        let firmaJezikID = 0;
        if (!naDrugogKlijenta && klijent) {
            firmaJezikID = klijent.FirmaJezikID;
        } else if (naDrugogKlijenta && drugiKlijent) {
            firmaJezikID = drugiKlijent.FirmaJezikID;
        }

        let tarifeNew = [...prijevodi.Tarife];
        let i = tarifeNew.findIndex(p => p.FirmaJezikID == firmaJezikID);
        if (i >= 0) {
            tarifeNew[i].Prijevod = e.target.value;
        }
        let prijevodiNew = {...prijevodi};
        prijevodiNew.Tarife = tarifeNew;
        setPrijevodi(prijevodiNew);
    }

    useEffect(() => {
        if (fiksnaNaplata.FiksnaNaplata) {
            setNoviRacun(true);
        }
        setKlijent(null);
        setPredmetAktivnosti([]);
    }, [fiksnaNaplata]);

    function provjeriNeprenesene() {
        setLoading(true);
        api.get("evidencije-into-racun/provjerineprenesene/" + global.user.firmaID, provjeriNepreneseneSuccess, loadFilterInfoFail);
    }

    function provjeriNepreneseneSuccess(data) {
        setNepreneseniKlijenti(data);
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            
            <Grid container className="padding-grid">
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <MoveUpIcon fontSize="medium" style={{marginRight:"15px"}}></MoveUpIcon>
                        Prijenos evidencija u račune
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={8} marginTop="-7px">
                    <Box style={{width:"100%"}}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={4} style={{display:"flex"}}>
                                <FormControlLabel 
                                    labelPlacement="end" 
                                    label="Razdoblje" 
                                    control={<Checkbox checked={razdoblje} onChange={e => setRazdoblje(e.target.checked)}></Checkbox>}>
                                </FormControlLabel>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="od"
                                        value={datumOd}
                                        inputFormat="dd.MM.yyyy"
                                        onChange={(newValue) => {
                                            setDatumOd(newValue);
                                        }}
                                        disabled={!razdoblje}
                                        renderInput={(params) => <TextField size="small" {...params} error={razdoblje && (!datumOd || isNaN(new Date(datumOd)))} sx={{width: '100%'}} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="do"
                                        value={datumDo}
                                        inputFormat="dd.MM.yyyy"
                                        onChange={(newValue) => {
                                            setDatumDo(newValue);
                                        }}
                                        
                                        disabled={!razdoblje}
                                        renderInput={(params) => <TextField size="small" {...params} error={razdoblje && (!datumDo || isNaN(new Date(datumDo)))} sx={{width: '100%'}} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={4} marginTop="-7px"></Grid>
                <Grid item xs={12} sm={12} md={8} marginTop="-7px">
                    <Box style={{width:"100%"}}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} marginTop="3px">
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        autoHighlight
                                        disableClearable
                                        options={fiksnaNaplataOptions}
                                        value={fiksnaNaplata}
                                        getOptionLabel={(option) => option.Naziv}
                                        onChange={(e,v) => setFiksnaNaplata(v)}
                                        renderInput={(params) => <TextField {...params} label="Tip naplate" size="small" />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={4} marginTop="-7px"></Grid>
                <Grid item xs={12} sm={12} md={8} marginTop="-7px">
                    <Box style={{width:"100%"}}>
                        <Grid container>
                            <Grid item xs={6}>
                                <FormControlLabel 
                                    labelPlacement="end" 
                                    label="Samo aktivni klijenti" 
                                    control={<Checkbox checked={samoAktivniKlijenti} onChange={e => setSamoAktivniKlijenti(e.target.checked)}></Checkbox>}>
                                </FormControlLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel 
                                    labelPlacement="end" 
                                    label="Samo naplatne evidencije" 
                                    control={<Checkbox checked={samoNaplatneAktivnosti} onChange={e => setSamoNaplatneAktivnosti(e.target.checked)}></Checkbox>}>
                                </FormControlLabel>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} marginTop="-7px">
                    <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <Button startIcon={<SearchIcon></SearchIcon>} variant="outlined" onClick={() => {
                            setAutomatskiFilter(false);
                            filter();
                        }} disabled={
                            (razdoblje && (!datumOd || !datumDo || isNaN(new Date(datumOd)) || isNaN(new Date(datumDo)))) || (samoZaZaposlenika && !zaposlenik) || (samoPoGrupi && !grupa)
                        }>Filtriraj</Button>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={8} marginTop="-7px">
                    <Box style={{width:"100%"}}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={4} >
                                <FormControlLabel 
                                    labelPlacement="end" 
                                    label="Samo za korisnika"
                                    control={<Checkbox checked={samoZaZaposlenika} onChange={e => setSamoZaZaposlenika(e.target.checked)}></Checkbox>}>
                                </FormControlLabel>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8} >
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        autoHighlight
                                        id="zaposlenik-select"
                                        disabled={!samoZaZaposlenika || zaposlenici.length == 0}
                                        options={zaposlenici}
                                        value={zaposlenik}
                                        getOptionLabel={(option) => option.Ime + " " + option.Prezime}
                                        onChange={(e,v) => setZaposlenik(v)}
                                        renderOption={(props, option) => {
                                            return option ? (
                                                <li {...props} style={{color:option.TipStatusaKorisnikaID == 1 || option.KorisnikID == 0 ? "black" : "red"}} key={"korisnik" + option.KorisnikID}>
                                                    {option.Ime + " " + option.Prezime}
                                                </li>
                                            ) : null;
                                        }}
                                        renderInput={(params) => <TextField {...params} sx={{ input: { color: zaposlenik && zaposlenik.TipStatusaKorisnikaID != 1 && zaposlenik.KorisnikID != 0 ? "red" : "black" } }} label="Korisnik" size="small" error={samoZaZaposlenika && !zaposlenik} />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={4} marginTop="-7px"></Grid>
                <Grid item xs={12} sm={12} md={8} marginTop="-7px">
                    <Box style={{width:"100%"}}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControlLabel 
                                    labelPlacement="end" 
                                    label={"Samo po grupi"} 
                                    control={<Checkbox checked={samoPoGrupi} onChange={e => setSamoPoGrupi(e.target.checked)}></Checkbox>}>
                                </FormControlLabel>
                            </Grid>
                            <Grid item xs={12} sm={6} md={8} >
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        autoHighlight
                                        id="grupa-select"
                                        disabled={!samoPoGrupi || grupe.length == 0}
                                        options={grupe}
                                        value={grupa}
                                        getOptionLabel={(option) => option.Naziv}
                                        onChange={(e,v) => setGrupa(v)}
                                        renderInput={(params) => <TextField {...params} label="Grupa" size="small" error={samoPoGrupi && !grupa} />}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} marginTop="-7px">
                    <Tooltip title="Provjera svih neprenesenih evidencija u posljednja 3 mjeseca" enterDelay={300} enterNextDelay={300}>
                        <span>
                            <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                <Button startIcon={<ContentPasteSearchIcon></ContentPasteSearchIcon>} variant="outlined" onClick={() => {
                                    provjeriNeprenesene();
                                }}>Provjeri neprenesene</Button>
                            </Box>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Divider style={{marginTop:"10px", marginBottom:"10px"}}></Divider>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            id="klijent-select"
                            disabled={klijenti.length == 0}
                            options={klijenti}
                            value={klijent}
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            onChange={(e,v) => setKlijent(v)}
                           
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 ? "black" : "red"}}>
                                    {option.Broj + " - " + option.Naziv}
                                  </li>
                                );
                            }}
                            filterOptions={(options, state) => options.filter(option => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            renderInput={(params) => <TextField {...params} sx={{input:{color: klijent && klijent.TipStatusaKlijentaID != 1 ? "red" : "black"}}} label="Klijent" size="small" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={10}>
                    {klijent ? (
                        predmetAktivnosti.length > 0 ? (
                            <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                                <List dense>
                                    {predmetAktivnosti.map((item, index) => {
                                            if (item.KlijentID == klijent.KlijentID) {
                                                return <Tooltip title={!item.MogucePrebaciti ? "Nije moguće prebaciti evidencije ovoga predmeta jer za njega nije definirana cijena rada." : (item.TipNaplateID == 3 && item.Iznos == null ? "Nije moguće prebaciti evidencije ovog predmeta s fiksnom naplatom jer za njega nije definiran iznos u predmetu." : "")} enterDelay={200} enterNextDelay={200}>
                                                        <ListItem className="list-item" style={{color: !item.Aktivan ? "orange" : (item.Zatvoren ? "red" : (item.TipNaplateID == 1 ? "black" : (item.TipNaplateID == 2 ? "magenta" : "green")))}} key={"listItem" + index} onClick={() => {if (item.MogucePrebaciti && !(item.TipNaplateID == 3 && item.Iznos == null)) handlePredmetClick(item.PredmetID)}}>
                                                            <Grid container>
                                                                <Grid item xs={11}>
                                                                    <Tooltip 
                                                                        title={<span style={{ whiteSpace: 'pre-line' }}>{item.EvidencijaDetaljOpises}</span>}
                                                                        placement="right"
                                                                        // classes={{
                                                                        //     tooltip: classes.tooltip,
                                                                        // }}
                                                                        arrow 
                                                                        followCursor
                                                                    >
                                                                        <ListItemText primary={item.PredmetBroj + " - " + item.PredmetNaziv + " (" + item.Kolicina + ")"}></ListItemText>
                                                                    </Tooltip>
                                                                </Grid>
                                                                <Grid item xs={1} style={{display:"flex", alignItems:"center"}}>
                                                                    <Checkbox disabled={!item.MogucePrebaciti || (item.TipNaplateID == 3 && item.Iznos == null)} style={{padding:"0px"}} checked={odabraniPredmetiIDs.includes(item.PredmetID)}></Checkbox>
                                                                </Grid>
                                                            </Grid>
                                                        </ListItem>
                                                    </Tooltip>;
                                            }
                                    })}
                                </List>
                            </Paper>
                        ) : (
                            <Typography variant="subtitle1" gutterBottom component="div" style={{textAlign:"center", marginTop:"10px", marginBottom:"10px"}}>
                                Ne postoji niti jedna evidencija koja odgovara zadanim kriterijima.
                            </Typography>
                        )
                    ) : (
                        <Typography variant="subtitle1" gutterBottom component="div" style={{textAlign:"center", marginTop:"10px", marginBottom:"10px"}}>
                            Odaberite klijenta za prikaz evidencija.
                        </Typography>
                    )}
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                    {klijent && predmetAktivnosti.length > 0 ? (
                        <Box>
                            <div style={{width:"100%", cursor:"pointer"}}><Tooltip title="Označi sve"><CheckBoxIcon onClick={oznaciSve}></CheckBoxIcon></Tooltip></div>
                            <div style={{width:"100%", cursor:"pointer"}}><Tooltip title="Odznači sve"><CheckBoxOutlineBlankIcon onClick={odznaciSve}></CheckBoxOutlineBlankIcon></Tooltip></div>
                        </Box>
                    ) : null}
                </Grid>
                {klijent && predmetAktivnosti.filter(pa => pa.KlijentID == klijent.KlijentID && !pa.Aktivan).length > 0 ? (
                    <Grow in={klijent && predmetAktivnosti.filter(pa => pa.KlijentID == klijent.KlijentID && !pa.Aktivan).length > 0}>
                        <Grid item xs={12}>
                            <Alert severity="warning">Predmeti s neaktivnom naplatom označeni su narančastom bojom.</Alert>
                        </Grid>
                    </Grow>
                ) : null}
                <Grid item xs={12}>
                    <Divider style={{marginTop:"10px", marginBottom:"10px"}}></Divider>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom component="div" style={{marginBottom:"10px"}}>
                        Postavke računa
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} >
                    <FormControlLabel 
                        labelPlacement="end" 
                        label="Na drugog klijenta"
                        control={<Checkbox checked={naDrugogKlijenta} onChange={e => setNaDrugogKlijenta(e.target.checked)}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={12} sm={6} md={8} >
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            id="drugi-klijent-select"
                            disabled={!naDrugogKlijenta || klijentiPredmeti.Klijenti.length == 0}
                            options={klijent ? klijentiPredmeti.Klijenti.filter(k => k.KlijentID != klijent.KlijentID) : klijentiPredmeti.Klijenti}
                            value={drugiKlijent}
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 ? "black" : "red"}}>
                                    {option.Broj + " - " + option.Naziv}
                                  </li>
                                );
                            }}
                            onChange={(e,v) => setDrugiKlijent(v)}
                            renderInput={(params) => <TextField {...params} label="Klijent" sx={{input:{color: drugiKlijent && drugiKlijent.TipStatusaKlijentaID != 1 ? "red" : "black"}}} size="small" error={naDrugogKlijenta && !drugiKlijent} />}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControlLabel 
                                labelPlacement="end" 
                                label={<Typography style={{fontSize:'unset'}}>Novi račun</Typography>}
                                control={<Checkbox checked={noviRacun} disabled={fiksnaNaplata.FiksnaNaplata} onChange={e => setNoviRacun(e.target.checked)}></Checkbox>}>
                            </FormControlLabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControlLabel 
                                labelPlacement="end" 
                                label={<Typography style={{fontSize:'unset'}}>Postojeći račun</Typography>}
                                control={<Checkbox checked={!noviRacun} disabled={fiksnaNaplata.FiksnaNaplata} onChange={e => setNoviRacun(!e.target.checked)}></Checkbox>}>
                            </FormControlLabel>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid item xs={6} sm={6} md={3}>

                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            id="racun-select"
                            autoHighlight
                            disabled={noviRacun || racuni.length == 0}
                            options={racuni.filter(r => !r.FiksnaNaplata)}
                            value={postojeciRacun}
                            getOptionLabel={(option) => dateFormat(option.Datum, "dd.mm.yyyy") + " (ID: " + option.RacunID + ")"}
                            onChange={(e,v) => setPostojeciRacun(v)}
                            renderInput={(params) => <TextField {...params} label="Račun" size="small" error={!noviRacun && !postojeciRacun} />}
                        />
                    </FormControl>

                </Grid>

                <Grid item xs={6} sm={6} md={3}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControlLabel 
                                disabled={noviRacun || racuni.length == 0 || !postojeciRacun || fiksnaNaplata.FiksnaNaplata}
                                labelPlacement="end" 
                                label={<Typography style={{fontSize:'unset'}}>Nova specifikacija</Typography>}
                                control={<Checkbox checked={novaSpecifikacija} onChange={e => setNovaSpecifikacija(e.target.checked)}></Checkbox>}>
                            </FormControlLabel>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControlLabel
                                disabled={noviRacun || racuni.length == 0 || !postojeciRacun || fiksnaNaplata.FiksnaNaplata} 
                                labelPlacement="end" 
                                label={<Typography style={{fontSize:'unset'}}>Postojeća specifikacija</Typography>}
                                control={<Checkbox checked={!novaSpecifikacija} onChange={e => setNovaSpecifikacija(!e.target.checked)}></Checkbox>}>
                            </FormControlLabel>
                        </Grid>
                    </Grid>
                </Grid>
                
                
                <Grid item xs={6} sm={6} md={3}>

                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            id="racun-select"
                            autoHighlight
                            disabled={noviRacun || !racuni || racuni.length == 0 || !postojeciRacun || fiksnaNaplata.FiksnaNaplata || novaSpecifikacija || (postojeciRacun && postojeciRacun.RacunskeStavke && postojeciRacun.RacunskeStavke.filter(rs => rs.TipRacunskeStavkeID == 1).length == 0)}
                            options={postojeciRacun && postojeciRacun.RacunskeStavke ? postojeciRacun.RacunskeStavke.filter(rs => rs.TipRacunskeStavkeID == 1) : []}
                            value={postojeciRacun ? postojecaSpecifikacija : null}
                            getOptionLabel={(option) => option.Opis + " (ID: " + option.RacunskaStavkaID + ")"}
                            onChange={(e,v) => setPostojecaSpecifikacija(v)}
                            renderInput={(params) => <TextField {...params} label="Specifikacija" size="small" error={!novaSpecifikacija && !postojecaSpecifikacija} />}
                        />
                    </FormControl>


                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth >
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            disabled={kontoOptions.length == 0}
                            options={kontoOptions}
                            value={kontoRacuna}
                            disableClearable
                            getOptionLabel={(option) => option.Naziv + ' (' + option.ForeignKontoID + ')' + (option.MjestoTroskaNaziv ? ' - ' + option.MjestoTroskaNaziv + (option.MjestoTroskaForeignID ? ' (' + option.MjestoTroskaForeignID + ')' : '') : '')}
                            onChange={(e,v) => setKontoRacuna(v)}
                            
                            renderInput={(params) => <TextField {...params} label="Konto računa" size="small" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Datum prijenosa"
                            value={datumPrijenosa}
                            inputFormat="dd.MM.yyyy"
                            onChange={(newValue) => {
                                setDatumPrijenosa(newValue);
                            }}
                            disabled={!noviRacun}
                            renderInput={(params) => <TextField sx={{width:"100%"}} size="small" {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            disabled={!noviRacun || zaposlenici.length == 0}
                            options={zaposlenici.filter(z => z.PotpisnaOsoba)}
                            value={izradioZaposlenik}
                            getOptionLabel={(option) => option.Ime + " " + option.Prezime}
                            onChange={(e,v) => setIzradioZaposlenik(v)}
                            
                            renderInput={(params) => <TextField {...params} label="Potpisna osoba" size="small" error={noviRacun && !izradioZaposlenik} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={nacinPlacanjaOptions}
                                value={nacinPlacanja}
                                getOptionLabel={(option) => option.Naziv}
                                onChange={(e,v) => setNacinPlacanja(v)}
                                renderInput={(params) => <TextField {...params} label="Način plaćanja" error={!nacinPlacanja} size="small" />}
                            />
                        </FormControl>
                    </Grid>                
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            options={prijenosNaSpecifikacijuOptions}
                            value={specifikacijaOption}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {
                                if (v) {
                                    setSpecifikacijaOption(v);
                                }  
                            }}
                            
                            disableClearable
                            renderInput={(params) => <TextField {...params} label="Opcije prijenosa" size="small" />}
                        />
                    </FormControl>
                </Grid>
                {specifikacijaOption && specifikacijaOption.Vrijednost == 1 ? (
                    <Grid item xs={kontoVidljivZaStavku == 1 ? 12 : 24} sm={kontoVidljivZaStavku == 1 ? 12 : 24} md={kontoVidljivZaStavku == 1 ? 6 : 12}>
                        <FormControl fullWidth>
                            <TextField label="Naziv specifikacije" value={opis} inputProps={{ maxLength: 255 }} error={!opis} size="small" onChange={handleOpisChange} ></TextField>
                        </FormControl>
                    </Grid>
                ) : null}
                {specifikacijaOption && specifikacijaOption.Vrijednost == 1 && kontoVidljivZaStavku == 1 ? (
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth >
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                disabled={kontoOptions.length == 0}
                                options={kontoOptions}
                                value={konto}
                                getOptionLabel={(option) => option.Naziv + ' (' + option.ForeignKontoID + ')' + (option.MjestoTroskaNaziv ? ' - ' + option.MjestoTroskaNaziv + (option.MjestoTroskaForeignID ? ' (' + option.MjestoTroskaForeignID + ')' : '') : '')}
                                onChange={(e,v) => setKonto(v)}
                                renderInput={(params) => <TextField {...params} label="Konto" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {specifikacijaOption && (specifikacijaOption.Vrijednost == 2 || specifikacijaOption.Vrijednost == 3) ? (
                    <Grid item xs={kontoVidljivZaStavku == 1 ? 12 : 24} sm={kontoVidljivZaStavku == 1 ? 12 : 24} md={kontoVidljivZaStavku == 1 ? 6 : 12}>
                        <FormControl fullWidth>
                            <TextField label="Naziv specifikacije za satnice" value={opisSatnice} inputProps={{ maxLength: 255 }} error={!opisSatnice} onChange={handleOpisSatniceChange} size="small"></TextField>
                        </FormControl>
                    </Grid>
                ) : null}
                {specifikacijaOption && (specifikacijaOption.Vrijednost == 2 || specifikacijaOption.Vrijednost == 3) && kontoVidljivZaStavku == 1 ? (
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth >
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                disabled={kontoOptions.length == 0}
                                options={kontoOptions}
                                value={kontoSatnice}
                                getOptionLabel={(option) => option.Naziv + ' (' + option.ForeignKontoID + ')' + (option.MjestoTroskaNaziv ? ' - ' + option.MjestoTroskaNaziv + (option.MjestoTroskaForeignID ? ' (' + option.MjestoTroskaForeignID + ')' : '') : '')}
                                onChange={(e,v) => setKontoSatnice(v)}
                                renderInput={(params) => <TextField {...params} label="Konto (satnice)" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {specifikacijaOption && (specifikacijaOption.Vrijednost == 2 || specifikacijaOption.Vrijednost == 3) ? (
                    <>

                        <Grid item xs={kontoVidljivZaStavku == 1 ? 12 : 24} sm={kontoVidljivZaStavku == 1 ? 12 : 24} md={kontoVidljivZaStavku == 1 ? 3 : 6}>
                            <FormControl fullWidth >
                                <TextField label="Naziv specifikacije za troškove" value={opisTroskovi} inputProps={{ maxLength: 255 }} error={!opisTroskovi} onChange={handleOpisTroskoviChange} size="small" ></TextField>
                            </FormControl>
                        </Grid>

                        <Grid item xs={kontoVidljivZaStavku == 1 ? 6 : 12} sm={kontoVidljivZaStavku == 1 ? 6 : 12} md={kontoVidljivZaStavku == 1 ? 3 : 6}>
                            <FormControlLabel 
                                labelPlacement="end" 
                                label={<Typography style={{fontSize:'unset'}}>Svaka porezna stopa na posebnu specifikaciju</Typography>}
                                control={<Checkbox disabled={!novaSpecifikacija} checked={analitikaTroskova} onChange={e => setAnalitikaTroskova(e.target.checked)}></Checkbox>}>
                            </FormControlLabel>
                        </Grid>	

                    </>
                ) : null}
                {specifikacijaOption && (specifikacijaOption.Vrijednost == 2 || specifikacijaOption.Vrijednost == 3) && kontoVidljivZaStavku == 1 ? (
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth >
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                disabled={kontoOptions.length == 0}
                                options={kontoOptions}
                                value={kontoTroskovi}
                                getOptionLabel={(option) => option.Naziv + ' (' + option.ForeignKontoID + ')' + (option.MjestoTroskaNaziv ? ' - ' + option.MjestoTroskaNaziv + (option.MjestoTroskaForeignID ? ' (' + option.MjestoTroskaForeignID + ')' : '') : '')}
                                onChange={(e,v) => setKontoTroskovi(v)}
                                renderInput={(params) => <TextField {...params} label="Konto (troškovi)" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {specifikacijaOption && (specifikacijaOption.Vrijednost == 2 || specifikacijaOption.Vrijednost == 3) ? (
                    <Grid item xs={kontoVidljivZaStavku == 1 ? 12 : 24} sm={kontoVidljivZaStavku == 1 ? 12 : 24} md={kontoVidljivZaStavku == 1 ? 6 : 12}>
                        <FormControl fullWidth >
                            <TextField label="Naziv specifikacija za tarife" value={opisTarife} error={!opisTarife} inputProps={{ maxLength: 255 }} onChange={handleOpisTarifeChange} size="small"></TextField>
                        </FormControl>
                    </Grid>
                ) : null}
                {specifikacijaOption && (specifikacijaOption.Vrijednost == 2 || specifikacijaOption.Vrijednost == 3) && kontoVidljivZaStavku == 1 ? (
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl fullWidth >
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                disabled={kontoOptions.length == 0}
                                options={kontoOptions}
                                value={kontoTarife}
                                getOptionLabel={(option) => option.Naziv + ' (' + option.ForeignKontoID + ')' + (option.MjestoTroskaNaziv ? ' - ' + option.MjestoTroskaNaziv + (option.MjestoTroskaForeignID ? ' (' + option.MjestoTroskaForeignID + ')' : '') : '')}
                                onChange={(e,v) => setKontoTarife(v)}
                                renderInput={(params) => <TextField {...params} label="Konto (tarife)" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                <Grid item xs={6} sm={6} md={3} >
                    <FormControlLabel 
                        labelPlacement="end" 
                        label="Domaći"
                        control={<Checkbox checked={domaci} onChange={e => setDomaci(e.target.checked)} disabled={!noviRacun}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={6} sm={6} md={3} >
                    <FormControlLabel 
                        labelPlacement="end" 
                        label="Strani"
                        control={<Checkbox checked={!domaci} onChange={e => setDomaci(!e.target.checked)} disabled={!noviRacun}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={12} sm={12} md={6} >
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            id="valuta-select"
                            autoHighlight
                            disabled={domaci || valute.length == 0 || !noviRacun}
                            options={valute}
                            value={valuta}
                            getOptionLabel={(option) => option.FirmaValutaNaziv}
                            onChange={(e,v) => {if (v) setValuta(v)}}
                            disableClearable
                            renderInput={(params) => <TextField {...params} label="Valuta" size="small" error={!valuta} />}
                        />
                    </FormControl>
                </Grid>
                {!mogucePrebacitiEvidencijeBezTecaja && !tecajExists ? (
                    <Grow in={!tecajExists}>
                        <Grid item xs={12}>
                            <Alert severity="error">Nije moguće prebaciti odabrane evidencije, jer za odabrani datum prijenosa nisu poznati svi tečajevi za preračun cijena rada u primarnu valutu ili nije poznat tečaj za preračun valute računa u primarnu valutu.</Alert>
                        </Grid>
                    </Grow>
                ) : null}
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" >
                        <Tooltip title="Prebaci sve odabrane evidencije.">
                            <span>
                                <Button tabIndex={-1} onClick={() => setPrebaciSveAlert(true)} style={{marginRight:"15px"}} startIcon={<DoneAllIcon></DoneAllIcon>} variant="contained" color="success" disabled={loading || (fiksnaNaplata && fiksnaNaplata.FiksnaNaplata) || drugiKlijent || !noviRacun || (!mogucePrebacitiEvidencijeBezTecaja && !tecajExists) || odabraniPredmetiIDs.length == 0 || (naDrugogKlijenta && !drugiKlijent) || (!noviRacun && !postojeciRacun) || (!novaSpecifikacija && !postojecaSpecifikacija) || !formValid}>Prebaci sve</Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="Prebaci odabrane evidencije samo za odabranog klijenta.">
                            <span>
                                <Button onClick={prebaci} startIcon={<DoneIcon></DoneIcon>} variant="contained" color="success" disabled={loading || (!mogucePrebacitiEvidencijeBezTecaja && !tecajExists) || odabraniPredmetiIDs.length == 0 || !odabraniPredmetiZaKlijenta || (!naDrugogKlijenta && !klijent) || (naDrugogKlijenta && !drugiKlijent) || !klijent || (!noviRacun && !postojeciRacun) || (!novaSpecifikacija && !postojecaSpecifikacija) || !formValid}> Prebaci</Button>
                            </span>
                        </Tooltip>
                    </Box>
                </Grid>
            </Grid>

            {prebaciSveAlert ? <AlertDialog title="Prebacivanje svih evidencija" message={"Jeste li sigurni da želite prebaciti sve evidencije? Odabirom \"Prebaci sve\" prebacit će se sve odabrane evidencije za sve klijente. Za svakog klijenta stvorit će se novi račun, domaći za domaće klijente te strani za strane, s odabranom valutom (" + (valuta ? valuta.FirmaValutaNaziv : "") + ")."} cancelLabel={"Odustani"} confirmLabel={"Prebaci sve"} cancelAction={() => setPrebaciSveAlert(false)} confirmAction={prebaciSve}></AlertDialog> : null}
        
            <Modal open={izvjestajOpened}>
                <Box sx={style} overflow={"auto"} >
                    <IzvjestajPrijenosa data={izvjestajData} close={() => setIzvjestajOpened(false)}></IzvjestajPrijenosa>
                </Box>
            </Modal>

            <Modal open={!izvjestajOpened && nemaNicegaZaPrebacaj}>
                <Box sx={style} overflow={"auto"} >
                    <Alert severity="info">{!automatskiFilter ? 
                        "Filtriranjem nije pronađena niti jedna evidencija koju je moguće prenijeti u račun."
                        :
                        "Automatskim filtriranjem nakon prijenosa nije pronađena niti jedna preostala evidencija koju je moguće prenijeti u račun, odnosno sve su evidencije koje odgovaraju filteru prenesene u račune."
                    }</Alert>
                    <Box style={{width:"100%", display:"flex", justifyContent:"flex-end", marginTop:"10px"}}>
                        <Button variant="contained" onClick={() => setNemaNicegaZaPrebacaj(false)}>U redu</Button>
                    </Box>
                </Box>
            </Modal>

            {logoutAlert ? <AlertDialog title="Odjava" message={"Upravo se događa odjava inicirana od strane administratora."} cancelHidden={true} confirmHidden={true} okHidden={false} okLabel={"OK"} okAction={startLogout}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
            {nepreneseniKlijenti != null ? <AlertDialog title={"Provjera neprenesenih evidencija"} minWidth={"70vw"} message={
                <Grid container>
                    {nepreneseniKlijenti.length > 0 ? (
                        <>
                            <Grid item xs={6}>
                                Klijent     
                            </Grid>
                            <Grid item xs={6} style={{textAlign:"center"}}>
                                Broj neprenesenih evidencija       
                            </Grid>
                            <Grid item xs={12} marginBottom="15px">
                                <Divider></Divider>
                            </Grid>
                            {nepreneseniKlijenti.map((k, i) => {
                                return (
                                    <>
                                        <Grid item xs={6}>
                                            {k.Klijent}
                                        </Grid>
                                        <Grid item xs={6} style={{textAlign:"center"}}>
                                            {k.BrojEvidencija}
                                        </Grid>
                                        {i != nepreneseniKlijenti.length - 1 ? (
                                            <Grid item xs={12}>
                                                <Divider></Divider>
                                            </Grid>
                                        ) : null}
                                    </>
                                );                        
                            })}
                        </>
                    ) : (
                        <Grid item xs={12}>
                            Sve evidencije u posljednja 3 mjeseca su prenesene.    
                        </Grid>
                    )}
                </Grid>
            } cancelHidden={true} confirmHidden={true} okHidden={false} okLabel={"U redu"} okAction={() => setNepreneseniKlijenti(null)}></AlertDialog> : null}
        </ComponentContainer>
    );

}

export default PrijenosAktivnosti;