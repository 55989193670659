import { Autocomplete, Box, Button, Checkbox, ClickAwayListener, Divider, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputAdornment, ListItem, Menu, MenuItem, Modal, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState, useCallback, useEffect, useRef } from "react";
import Util from "../util/util";
import AddEditDelForm from "./addEditDelForm";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import api from "../util/api";
import globalContext from "./globalContext";
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import RemoveIcon from '@mui/icons-material/Remove';
import StavkaRacunskeStavkeForm from "./stavkaRacunskeStavkeForm";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import HistoryIcon from '@mui/icons-material/History';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import AlertDialog from "./alertdialog";
import SearchIcon from "@mui/icons-material/Search";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import PrebaciStavkuRacunskeStavkeNaDruguSpecifikacijuForm from "./prebaciStavkuRacunskeStavkeNaDruguSpecifikacijuForm";
import database from "../util/database"
import TranslateIcon from '@mui/icons-material/Translate';
import PrijevodRacunskeStavkeForm from "./prijevodRacunskeStavkeForm";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    //minHeight: '80%',
    maxHeight: '95%',
    minWidth: '80%',
    maxWidth: '95%'
};

const styleDelete = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '95%',
    minWidth: '75%',
    maxWidth: '95%'
};

const styleNew = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    //minHeight: '80%',
    maxHeight: '95%',
    minWidth: '60%',
    maxWidth: '65%'
};

const vrstePregleda = [
    { Naziv : "Sve evidencije", Vrijednost : 0},
    { Naziv : "Samo naplatne evidencije", Vrijednost : 1},
    { Naziv : "Samo nenaplatne evidencije", Vrijednost : 2}  
];

const vrstePregledaPoTipuEvidencije = [
    { Naziv : "Sve evidencije", Vrijednost : 0},
    { Naziv : "Samo aktivnosti", Vrijednost : 1},
    { Naziv : "Samo troškovi", Vrijednost : 2}  
];

function RacunskaStavkaForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formStavka = props.formRacunskaStavka;
    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;
    const racunID = props.racunID;
    const klijent = props.klijent;
    const racunskeStavke = props.racunskeStavke;
    const setRacunskeStavke = props.setRacunskeStavke;
    const predmeti = props.predmeti;
    const firmaJezici = props.firmaJezici;
    const firmaJezikID = props.firmaJezikID;
    const tipoviKorisnika = props.tipoviKorisnika;
    const fiksnaNaplata = props.fiksnaNaplata;

    const [takeALook, setTakeALook] = useState(props.broj && props.broj != -1);
    const [loading, setLoading] = useState(true);
    const [tipRacunskeStavkeID, setTipRacunskeStavkeID] = useState(props.tipRacunskeStavkeID);
    const [stavke, setStavke] = useState([]);
    const [currentStavka, setCurrentStavka] = useState(null);
    const [usluge, setUsluge] = useState([]);
    const [robneStavke, setRobneStavke] = useState([]);
    const [predmet, setPredmet] = useState(null);
    const [jediniceMjere, setJediniceMjere] = useState([]);
    const [jedinicaMjere, setJedinicaMjere] = useState(null);
    const [kolicina, setKolicina] = useState("1.00");
    const [cijena, setCijena] = useState("0.00");
    const [ukupno, setUkupno] = useState("0.00");
    const [rabat, setRabat] = useState("0.00");
    const [rabatPosto, setRabatPosto] = useState("0.00")
    const [tipoviOporezivosti, setTipoviOporezivosti] = useState([]);
    const [tipOporezivosti, setTipOporezivosti] = useState(null);
    const [porez, setPorez] = useState("0.00");
    const [ulaznaCijena, setUlaznaCijena] = useState("0.00");
    const [opis, setOpis] = useState("");
    const [konta, setKonta] = useState(props.konta);
    const [konto, setKonto] = useState(null);
    const [marza, setMarza] = useState("0.00");
    const [marzaPosto, setMarzaPosto] = useState("0.00");
    const [stavkaRacunskeStavkeFormOpened, setStavkaRacunskeStavkeFormOpened] = useState(false);
    const [formStavkaRacunskeStavke, setFormStavkaRacunskeStavke] = useState(null);
    const [tipRacunskeStavkeNaziv, setTipRacunskeStavkeNaziv] = useState("");
    const [datumObracuna, setDatumObracuna] = useState(new Date());
    const [tipoviNaplatnosti, setTipoviNaplatnosti] = useState([]);
    const [tipNaplatnosti, setTipNaplatnosti] = useState(null);
    const [redniBroj, setRedniBroj] = useState(-1);
    const [tipVrsteStavkeID, setTipVrsteStavkeID] = useState(0);
    const [anchorAddEl, setAnchorAddEl] = useState(null);
    const historyOpened = Boolean(anchorAddEl);
    const [numberOfLoads, setNumberOfLoads] = useState(0);
    const [vrstaPregleda, setVrstaPregleda] = useState(vrstePregleda.at(0));
    const [vrstaPregledaPoTipuEvidencije, setVrstaPregledaPoTipuEvidencije] = useState(vrstePregledaPoTipuEvidencije.at(0));
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [postojeAktivnosti, setPostojeAktivnosti] = useState(false);
    const [postojeTroskovi, setPostojeTroskovi] = useState(false);
    const [vratiUNeprenesenoStanje, setVratiUNeprenesenoStanje] = useState(true);
    const [stavkaForDeleteID, setStavkaForDeleteID] = useState(true);
    const [firmaValute, setFirmaValute] = useState(props.firmaValute);
    const [firmaValuta, setFirmaValuta] = useState(null);
    const [povijestFilter, setPovijestFilter] = useState("");
    const [stavkaForPrebacajID, setStavkaForPrebacajID] = useState(0);
    const [prebaciStavkuFormOpened, setPrebaciStavkuFormOpened] = useState(false);
    const [stavkaSeNaplacuje, setStavkaSeNaplacuje] = useState(true);
    const [kontoVidljivZaStavku, setKontoVidljivZaStavku] = useState(props.kontoVidljivZaStavku);
    const [prijevodOpened, setPrijevodOpened] = useState(false);
    const [prijevodi, setPrijevodi] = useState([]);
    const [fiksnaNaplataPredmetID, setFiksnaNaplataPredmetID] = useState(null);
    const [fiksnaNaplataKlijentID, setFiksnaNaplataKlijentID] = useState(null);
    const [formValid, setFormValid] = useState(false);
    const [uslugaHistory, setUslugaHistory] = useState(null);
    const [robnaStavkaHistory, setRobnaStavkaHistory] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        if (!formStavka && props.tipRacunskeStavkeID == 1) {    // dohvati defaultne prijevode
            api.get("racunskastavka/getdefaultprijevodispecifikacije/" + global.user.firmaID, loadDefaultPrijevodiSuccess, loadDefaultPrijevodiFail);
        } else {
            loadFormDetaljiSuccess(props.racunskaStavkaFormDetalji);
        }
    });

    function loadDefaultPrijevodiSuccess(data) {
        setPrijevodi(data);
        data.forEach(p => {
            if (p.FirmaJezikID == firmaJezikID) {
                setOpis(p.Prijevod);
            }
        });
        loadFormDetaljiSuccess(props.racunskaStavkaFormDetalji);
    }

    function loadDefaultPrijevodiFail() {
        loadFormDetaljiSuccess(props.racunskaStavkaFormDetalji);
    }

    function loadFormDetaljiSuccess(data) {
        setUsluge(data.Usluge);
        setRobneStavke(data.RobneStavke);
        setJediniceMjere(data.JediniceMjere);
        setTipoviOporezivosti(data.TipoviOporezivosti);
        setTipoviNaplatnosti(data.TipoviNaplatnosti);
        if (formStavka) {
            setOpis(formStavka.Opis);
            setTipRacunskeStavkeID(formStavka.TipRacunskeStavkeID);
            setTipRacunskeStavkeNaziv(formStavka.TipRacunskeStavkeNaziv);
            setStavke(formStavka.StavkeRacunskeStavke);
            if(formStavka.StavkeRacunskeStavke.length > 0) {
                setCurrentStavka(formStavka.StavkeRacunskeStavke[0]);
            }
            setTipOporezivosti(formStavka.TipOporezivosti);
            setJedinicaMjere(formStavka.TipJediniceMjere);
            setKonto(formStavka.Konto);
            setKolicina(Util.toStringDecimal(formStavka.Kolicina));
            setCijena(Util.toStringDecimal(formStavka.Cijena));
            let ukupnoValue = Util.toStringDecimal(formStavka.Kolicina * formStavka.Cijena);
            setUkupno(ukupnoValue)
            setRabatPosto(Util.toStringDecimal(formStavka.Rabat != null ? formStavka.Rabat : 0));       // polje Rabat u bazi sadrzi POSTOTAK rabata, isto vrijedi za marzu
            if (formStavka.TipRacunskeStavkeID == 2) {
                let rabatValue = Util.toStringDecimal((parseFloat(formStavka.Rabat) / 100) * parseFloat(ukupnoValue));
                setRabat(rabatValue);
                let porezValue = Util.toStringDecimal((parseFloat(ukupnoValue) - parseFloat(rabatValue)) * formStavka.TipOporezivosti.Stopa);
                setPorez(porezValue);
                ;
                setUlaznaCijena(Util.toStringDecimal((Math.round((parseFloat(ukupnoValue) - parseFloat(rabatValue) + parseFloat(porezValue)) * 100 ) / 100).toFixed(2)));
            } else if (formStavka.TipRacunskeStavkeID == 3) {
                ;
                let marzaValue = Util.toStringDecimal((parseFloat(formStavka.Marza) / 100) * parseFloat(ukupnoValue));
                setMarza(marzaValue);
                let rabatValue = Util.toStringDecimal((parseFloat(formStavka.Rabat) / 100) * (parseFloat(ukupnoValue) + parseFloat(marzaValue)));
                setRabat(rabatValue);
                let porezValue = Util.toStringDecimal((parseFloat(ukupnoValue) + parseFloat(marzaValue) - parseFloat(rabatValue)) * formStavka.TipOporezivosti.Stopa);
                setPorez(porezValue);
                setUlaznaCijena(Util.toStringDecimal(parseFloat(ukupnoValue) + parseFloat(marzaValue) - parseFloat(rabatValue) + parseFloat(porezValue)));
            }
            setMarzaPosto(Util.toStringDecimal(formStavka.Marza != null ? formStavka.Marza : 0));
            setDatumObracuna(formStavka.DatumObracuna);
            setTipNaplatnosti(formStavka.TipNaplatnosti);
            setRedniBroj(formStavka.RedniBroj);
            setPredmet(formStavka.Predmet);
            setTipVrsteStavkeID(formStavka.TipVrsteStavkeID);
            setFirmaValuta(formStavka.FirmaValuta);
            setStavkaSeNaplacuje(formStavka.StavkaSeNaplacuje);
            setPrijevodi(formStavka.Prijevodi);
            if (fiksnaNaplata) {
                if (formStavka.TipRacunskeStavkeID == 1 && formStavka.TipVrsteStavkeID == 4) {
                    if (formStavka.StavkeRacunskeStavke.length > 0) {
                        setFiksnaNaplataPredmetID(formStavka.StavkeRacunskeStavke[0].PredmetID);
                        setFiksnaNaplataKlijentID(formStavka.StavkeRacunskeStavke[0].Predmet ? formStavka.StavkeRacunskeStavke[0].Predmet.KlijentID : null)
                    }
                } else if (formStavka.TipRacunskeStavkeID == 2 && formStavka.TipVrsteStavkeID == 5) {
                    setFiksnaNaplataPredmetID(formStavka.PredmetID);
                }
            }
        } else {
            ;
            setTipRacunskeStavkeID(props.tipRacunskeStavkeID);
            setTipRacunskeStavkeNaziv(props.tipRacunskeStavkeNaziv);
            setTipOporezivosti(data.DefaultTipOporezivosti);
            setTipNaplatnosti(data.TipoviNaplatnosti.at(0));
            setJedinicaMjere(data.DefaultTipJediniceMjere);
            setKonto(data.DefaultKonto);
            setFirmaValuta(props.defaultFirmaValuta);
            setDatumObracuna(props.defaultDatumObracuna);
            if (props.tipRacunskeStavkeID != 1) {
                let prijevodiList = [];
                firmaJezici.forEach(fj => {
                    if (fj.FirmaJezikID == firmaJezikID) {
                        prijevodiList.push({
                            FirmaJezikID : fj.FirmaJezikID,
                            Prijevod : opis,
                            FirmaJezikNaziv : fj.FirmaJezikNaziv
                        });
                    } else {
                        prijevodiList.push({
                            FirmaJezikID : fj.FirmaJezikID,
                            Prijevod : "",
                            FirmaJezikNaziv : fj.FirmaJezikNaziv
                        });
                    }
                });
                setPrijevodi(prijevodiList);
            }
        }

        setLoading(false);
    }

    function loadFormDetaljiFail() {
    }

    const historyRef = useRef(null);

    function focusOnHistory() {
        setTimeout(() => {
            if (historyRef.current) {
                historyRef.current.focus();
            }
        }, 350);
    }

    function editStavkaRacunskeStavke() {
        setFormStavkaRacunskeStavke(currentStavka);
        setStavkaRacunskeStavkeFormOpened(true);
    }
    
    function save() {
        let racunskaStavka = {
            RacunskaStavkaID : formStavka ? formStavka.RacunskaStavkaID : -1,
            RacunID : racunID,
            TipRacunskeStavkeID : tipRacunskeStavkeID,
            TipRacunskeStavkeNaziv : tipRacunskeStavkeNaziv,
            Opis : opis,
            TipJediniceMjereID : jedinicaMjere.TipJediniceMjereID,
            TipJediniceMjere : jedinicaMjere,
            Kolicina : tipRacunskeStavkeID != 1 ? parseFloat(kolicina) : null,
            Cijena : tipRacunskeStavkeID != 1 ? parseFloat(cijena) : null,
            Rabat : tipRacunskeStavkeID != 1 ? parseFloat(rabatPosto) : null,
            Marza : tipRacunskeStavkeID == 3 ? parseFloat(marzaPosto) : null,
            DatumObracuna : datumObracuna,
            TipNaplatnostiID : tipNaplatnosti ? tipNaplatnosti.TipNaplatnostiID : null,
            TipNaplatnosti : tipNaplatnosti,
            TipOporezivostiID : tipOporezivosti ?  tipOporezivosti.TipOporezivostiID : null,
            TipOporezivosti : tipOporezivosti,
            RedniBroj : redniBroj,
            TipVrsteStavkeID : tipVrsteStavkeID,
            KontoID : konto ? konto.KontoID : null,
            Konto : konto,
            PredmetID : predmet ? predmet.PredmetID : null,
            Predmet : predmet,
            StavkeRacunskeStavke : stavke,
            FirmaValutaID : firmaValuta ? firmaValuta.FirmaValutaID : null,
            FirmaValuta : firmaValuta,
            RacunFirmaValutaID : props.racunFirmaValutaID,
            StavkaSeNaplacuje : stavkaSeNaplacuje,
            Prijevodi : prijevodi,
            GUID : formStavka ? formStavka.GUID : Util.generateUUID()
        };
        if ((formStavka && formStavka.RacunskaStavkaID > 0) || racunID > 0) {
            setLoading(true);
            api.post("racunskastavka/save", racunskaStavka, saveSuccess, saveFail);
        } else if (formStavka && formStavka.RacunskaStavkaID <= 0) {
            let newRacunskeStavke = racunskeStavke.filter(s => !equalRacunskeStavke(formStavka, s));
            newRacunskeStavke.push(racunskaStavka);
            setRacunskeStavke(newRacunskeStavke);
            setFormOpened(false);
        } else {
            setRacunskeStavke(ogRacunskeStavke => [...ogRacunskeStavke, racunskaStavka]);
            setFormOpened(false);
        }
    }

    function saveSuccess() {
        setLoading(false);
        props.filterRacuni(null, null, null);
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function equalRacunskeStavke(stavka1, stavka2) {
        return (
            stavka1.RacunID == stavka2.RacunID
            && stavka1.TipRacunskeStavkeID == stavka2.TipRacunskeStavkeID
            && stavka1.Opis == stavka2.Opis
            && stavka1.TipJediniceMjereID == stavka2.TipJediniceMjereID
            && stavka1.Kolicina == stavka2.Kolicina
            && stavka1.Cijena == stavka2.Cijena
            && stavka1.Rabat == stavka2.Rabat
            && stavka1.Marza == stavka2.Marza
            && stavka1.DatumObracuna == stavka2.DatumObracuna
            && stavka1.TipNaplatnostiID == stavka2.TipNaplatnostiID
            && stavka1.TipOporezivostiID == stavka2.TipOporezivostiID
            && stavka1.RedniBroj == stavka2.RedniBroj
            && stavka1.TipVrsteStavkeID == stavka2.TipVrsteStavkeID
            && stavka1.KontoID == stavka2.KontoID
            && stavka1.PredmetID == stavka2.PredmetID
            && (stavka1.TipRacunskeStavkeID == 1 ? (stavka1.StavkeRacunskeStavke.length == stavka2.StavkeRacunskeStavke.length) : true)
        );
    }

    // function countDecimals(value) {
    //     if (value.indexOf(".") == -1) {
    //         return 0;
    //     } else if (Math.floor(parseFloat(value)) !== parseFloat(value)) {
    //         return value.toString().split(".")[1].length;
    //     } else {
    //         return 2;
    //     }
    // }

    function handleKolicinaFocusOut() {
        if (kolicina == "" || kolicina.charAt(0) == '-') {
            setKolicina("0.00");
            handleUkupnoChange("0.00", cijena);
        } else if (!isNaN(parseFloat(kolicina))) {
            let value = kolicina;
            let i = 0;
            while (kolicina.length > i + 1 && kolicina.charAt(i) == '0' && kolicina.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            // var decimals = Util.countDecimals(value);
            // if (decimals == 0) {
            //     value = value + ".00";
            // } else if (decimals == 1) {
            //     value = value + "0";
            // }
            value = Util.toStringDecimal(value);
            setKolicina(value);
            handleUkupnoChange(value.toString(), cijena);
        }
    }

    function handleCijenaFocusOut() {
        if (cijena == "" || cijena.charAt(0) == '-') {
            setCijena("0.00");
            handleUkupnoChange(kolicina, "0.00");
        } else if (!isNaN(parseFloat(cijena))) {
            let value = cijena;
            let i = 0;
            while (cijena.length > i + 1 && cijena.charAt(i) == '0' && cijena.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            ;
            value = Util.toStringDecimal(value);
            setCijena(value);
            handleUkupnoChange(kolicina, value);
        }
    }

    function handleUkupnoChange(kolicinaArg, cijenaArg) {
        if (!isNaN(parseFloat(cijenaArg)) && !isNaN(parseFloat(kolicinaArg))) {
            let value = parseFloat(kolicinaArg) * parseFloat(cijenaArg);
            value = Util.toStringDecimal(value);
            setUkupno(value);
            let marzaValue = tipRacunskeStavkeID == 3 ? (parseFloat(marzaPosto) / 100) * parseFloat(value.toString()) : 0;
            setMarza(Util.toStringDecimal(marzaValue));
            let rabatValue = (parseFloat(value) + marzaValue) * (parseFloat(rabatPosto) / 100);
            setRabat((Util.toStringDecimal(rabatValue)));
            let porezValue = (parseFloat(value) + parseFloat(marzaValue) - parseFloat(rabatValue)) * tipOporezivosti.Stopa;
            setPorez(Util.toStringDecimal(porezValue));
            handleUlaznaCijenaChange(value.toString(), Util.toStringDecimal(rabatValue), Util.toStringDecimal(marzaValue), Util.toStringDecimal(porezValue));
        }
    }


    function handleRabatFocusOut() {
        if (rabat == "" || rabat.charAt(0) == '-') {
            setRabat("0.00");
            handleUlaznaCijenaChange(ukupno, "0.00", marza, porez);
        } else if (!isNaN(parseFloat(rabat))) {
            let value = rabat;
            let i = 0;
            while (rabat.length > i + 1 && rabat.charAt(i) == '0' && rabat.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            let rabatValue = Util.toStringDecimal(value);
            setRabat(rabatValue);
            let ukupnoSMarzom = parseFloat(ukupno);
            if (tipRacunskeStavkeID == 3) {
                ukupnoSMarzom += parseFloat(marza);
            }
            ;
            let valueRabatPosto = parseFloat(value) / ukupnoSMarzom * 100;
            valueRabatPosto = Util.toStringDecimal(valueRabatPosto);
            setRabatPosto(valueRabatPosto);
            let porezValue = (parseFloat(ukupno) + parseFloat(marza) - parseFloat(rabatValue)) * tipOporezivosti.Stopa;
            setPorez(Util.toStringDecimal(porezValue));
            handleUlaznaCijenaChange(ukupno, Util.toStringDecimal(rabatValue), marza, Util.toStringDecimal(porezValue));
        }
    }

    function handleUlaznaCijenaChange(ukupnoArg, rabatArg, marzaArg, porezArg) {
        let value = parseFloat(ukupnoArg) - parseFloat(rabatArg);
        if (tipRacunskeStavkeID == 3) {
            value = value + parseFloat(marzaArg);
        }
        value = value + parseFloat(porezArg);
        value = Util.toStringDecimal(value);
        setUlaznaCijena(value);       
    }

    function handleRabatPostoFocusOut() {
        if (rabatPosto == "" || rabatPosto.charAt(0) == '-') {
            setRabatPosto("0.00");
            handleUlaznaCijenaChange(ukupno, "0.00", marza, porez);
        } else if (!isNaN(parseFloat(rabatPosto))) {
            let value = rabatPosto;
            let i = 0;
            while (rabatPosto.length > i + 1 && rabatPosto.charAt(i) == '0' && rabatPosto.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            value = Util.toStringDecimal(value);
            setRabatPosto(value);
            let ukupnoSMarzom = parseFloat(ukupno);
            if (tipRacunskeStavkeID == 3) {
                ukupnoSMarzom += parseFloat(marza);
            }
            let valueRabat = parseFloat(value) / 100 * ukupnoSMarzom;
            ;
            valueRabat = Util.toStringDecimal(valueRabat);
            setRabat(valueRabat);
            let porezValue = (parseFloat(ukupno) + parseFloat(marza) - parseFloat(valueRabat)) * tipOporezivosti.Stopa;
            setPorez(Util.toStringDecimal(porezValue));
            handleUlaznaCijenaChange(ukupno, valueRabat, marza, porezValue);
        }
    }

    function handleTipOporezivostiChange(e,v) {
        if (v) {
            setTipOporezivosti(v);
            let value = parseFloat(ukupno) - parseFloat(rabat);
            if (tipRacunskeStavkeID == 3) {
                value = value + parseFloat(marza);
            }
            value = value * v.Stopa;
            value = Util.toStringDecimal(value);
            setPorez(value);
            handleUlaznaCijenaChange(ukupno, rabat, marza, value);
        }
    }

    function findKonto(pattern) {
        //setLoading(true);
        //api.post("konto/findbypattern", pattern, findKontoSuccess, findKontoFail);
    }

    function findKontoSuccess(data) {
        if (data) {
            setKonto(data);
        }
        setLoading(false);
    }

    function findKontoFail() {
        setLoading(false);
    }

    function addKontoPredlozak() {
        let resource = {
            Pattern : opis,
            KontoID : konto ? konto.KontoID : -1
        }
        setLoading(true);
        api.post("konto/addpredlozak", resource, addKontoPredlozakSuccess, addKontoPredlozakFail);
    }

    function addKontoPredlozakSuccess() {
        setLoading(false);
    }

    function addKontoPredlozakFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function deleteKontoPredlozak() {
        let resource = {
            Pattern : opis,
            KontoID : konto ? konto.KontoID : -1
        }
        setLoading(true);
        api.post("konto/deletepredlozak", resource, deleteKontoPredlozakSuccess, deleteKontoPredlozakFail);
    }

    function deleteKontoPredlozakSuccess() {
        setLoading(false);
    }

    function deleteKontoPredlozakFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleMarzaFocusOut() {
        if (marza == "" || marza.charAt(0) == '-') {
            setMarza("0.00");
            handleUlaznaCijenaChange(ukupno, rabat, "0.00", porez);
        } else if (!isNaN(parseFloat(marza))) {
            let value = marza;
            let i = 0;
            while (marza.length > i + 1 && marza.charAt(i) == '0' && marza.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            let valueMarza = Util.toStringDecimal(value);
            setMarza(valueMarza);
            let valueMarzaPosto = parseFloat(valueMarza) / parseFloat(ukupno) * 100;
            valueMarzaPosto = Util.toStringDecimal(valueMarzaPosto);
            setMarzaPosto(valueMarzaPosto.toString());
            let valueRabat = Util.toStringDecimal((parseFloat(rabatPosto) / 100) * (parseFloat(ukupno) + parseFloat(valueMarza)));
            setRabat(valueRabat);
            let porezValue = (parseFloat(ukupno) + parseFloat(valueMarza) - parseFloat(valueRabat)) * tipOporezivosti.Stopa;
            setPorez(Util.toStringDecimal(porezValue));
            handleUlaznaCijenaChange(ukupno, valueRabat, valueMarza, porezValue);
        }
    }

    function handleMarzaPostoFocusOut() {
        if (marzaPosto == "" || marzaPosto.charAt(0) == '-') {
            setMarzaPosto("0.00");
            handleUlaznaCijenaChange(ukupno, rabat, "0.00", porez);
        } else if (!isNaN(parseFloat(marzaPosto))) {
            let value = marzaPosto;
            let i = 0;
            while (marzaPosto.length > i + 1 && marzaPosto.charAt(i) == '0' && marzaPosto.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            value = Util.toStringDecimal(value);
            setMarzaPosto(value);
            let valueMarza = parseFloat(value) / 100 * parseFloat(ukupno);
            valueMarza = Util.toStringDecimal(valueMarza);
            setMarza(valueMarza);
            let valueRabat = Util.toStringDecimal((parseFloat(rabatPosto) / 100) * (parseFloat(ukupno) + parseFloat(valueMarza)));
            setRabat(valueRabat);
            let porezValue = (parseFloat(ukupno) + parseFloat(valueMarza) - parseFloat(valueRabat)) * tipOporezivosti.Stopa;
            setPorez(Util.toStringDecimal(porezValue));
            handleUlaznaCijenaChange(ukupno, valueRabat, valueMarza.toString(), porezValue);
        }
    }

    function addStavkaRacunskeStavke() {
        setFormStavkaRacunskeStavke(null);
        setStavkaRacunskeStavkeFormOpened(true);
    }
    
    function handleHistoryClick(event) {
        setAnchorAddEl(event.currentTarget);
        focusOnHistory();
    };

    function handleHistoryClose(event) {
        setAnchorAddEl(null);
    };

    function refresh() {
        setLoading(true);
        api.get("stavkaracunskestavke/getforracunskastavka/" + formStavka.RacunskaStavkaID, refreshStavkeSuccess, refreshStavkeFail);
    }

    function refreshStavkeSuccess(data) {
        setStavke(data);
        setCurrentStavka(null);
        setFormStavkaRacunskeStavke(null);
        setLoading(false);
    }

    function refreshStavkeFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function deleteStavkaRacunskeStavke() {
        setStavkaForDeleteID(currentStavka.StavkaRacunskeStavkeID);
        if (currentStavka.EvidencijaDetaljID && currentStavka.TipEvidencijeID == 1) {
            setPostojeAktivnosti(true);
            setVratiUNeprenesenoStanje(true);
        } else if (currentStavka.EvidencijaDetaljID && currentStavka.TipEvidencijeID == 2) {
            setPostojeTroskovi(true);
            setVratiUNeprenesenoStanje(true);
        } 
        setDeleteAlert(true);
    }

    function deleteConfirmed() {
        if (stavkaForDeleteID > 0) {
            let resource = {
                StavkaRacunskeStavkeID : stavkaForDeleteID,
                VratiUNeprenesenoStanje : vratiUNeprenesenoStanje
            }
            setLoading(true);
            api.post("stavkaracunskestavke/delete", resource, deleteSuccess, deleteFail);
        } else {
            let newStavke = [...stavke];
            newStavke = newStavke.filter(s => currentStavka ? (s.GUID != currentStavka.GUID) : true);
            setPostojeAktivnosti(false);
            setPostojeTroskovi(false);
            setDeleteAlert(false);
            setStavke(newStavke);
        }
    }

    function deleteSuccess() {
        setLoading(false);
        setPostojeAktivnosti(false);
        setPostojeTroskovi(false);
        setDeleteAlert(false);
        refresh();
    }

    function deleteFail(data) {
        setPostojeAktivnosti(false);
        setPostojeTroskovi(false);
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleOpisChange(e) {
        setOpis(e.target.value);
        let i = prijevodi.findIndex(p => p.FirmaJezikID == firmaJezikID);
        if (i >= 0) {
            let prijevodiNew = [...prijevodi];
            prijevodiNew[i].Prijevod = e.target.value;
            setPrijevodi(prijevodiNew);
        }
    }

    function handleHistoryStavkaClick(stavka) {
        let i = prijevodi.findIndex(p => p.FirmaJezikID == firmaJezikID);
        if (i >= 0) {
            let prijevodiNew = [...prijevodi];
            prijevodiNew[i].Prijevod = stavka.Opis;
            setPrijevodi(prijevodiNew);
        }

        setOpis(stavka.Opis);
        setTipOporezivosti(stavka.TipOporezivosti);
        setJedinicaMjere(stavka.TipJediniceMjere);
        setKolicina(Util.toStringDecimal(stavka.Kolicina));
        setCijena(Util.toStringDecimal(stavka.Cijena));
        let ukupnoValue = Util.toStringDecimal(stavka.Kolicina * stavka.Cijena);
        setUkupno(ukupnoValue)
        setRabatPosto(Util.toStringDecimal(stavka.Rabat));       // polje Rabat u bazi sadrzi POSTOTAK rabata, isto vrijedi za marzu
        if (props.tipRacunskeStavkeID == 2) {
            let rabatValue = Util.toStringDecimal((parseFloat(stavka.Rabat) / 100) * parseFloat(ukupnoValue));
            setRabat(rabatValue);
            let porezValue = Util.toStringDecimal((parseFloat(ukupnoValue) - parseFloat(rabatValue)) * stavka.TipOporezivosti.Stopa);
            setPorez(porezValue);
            setUlaznaCijena(Util.toStringDecimal((Math.round((parseFloat(ukupnoValue) - parseFloat(rabatValue) + parseFloat(porezValue)) * 100 ) / 100).toFixed(2)));
        } else if (props.tipRacunskeStavkeID == 3) {
            let marzaValue = Util.toStringDecimal((parseFloat(stavka.Marza) / 100) * parseFloat(ukupnoValue));
            setMarza(marzaValue);
            let rabatValue = Util.toStringDecimal((parseFloat(stavka.Rabat) / 100) * (parseFloat(ukupnoValue) + parseFloat(marzaValue)));
            setRabat(rabatValue);
            let porezValue = Util.toStringDecimal((parseFloat(ukupnoValue) + parseFloat(marzaValue) - parseFloat(rabatValue)) * stavka.TipOporezivosti.Stopa);
            setPorez(porezValue);
            setUlaznaCijena(Util.toStringDecimal(parseFloat(ukupnoValue) + parseFloat(marzaValue) - parseFloat(rabatValue) + parseFloat(porezValue)));
        }
        setMarzaPosto(Util.toStringDecimal(stavka.Marza));
        setFirmaValuta(stavka.FirmaValuta);

        setAnchorAddEl(null);
    }

    const handleKeyPressShorcuts = useCallback((event) => {
        if (event.altKey) {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if (charCode == 'p') {
                event.preventDefault();
                if (!loading && !takeALook) {
                    save();
                }
            }
            if (charCode == 'o') {
                event.preventDefault();
                if (!loading) {
                    setCanceled(true); 
                    setFormOpened(false);
                }
            }            
        }
    }, [
        loading, 
        tipRacunskeStavkeID, 
        tipRacunskeStavkeNaziv, 
        opis, 
        jedinicaMjere, 
        kolicina, 
        cijena, 
        rabatPosto, 
        marzaPosto, 
        datumObracuna, 
        tipNaplatnosti, 
        tipOporezivosti, 
        redniBroj, 
        tipVrsteStavkeID, 
        konto, 
        predmet, 
        stavke, 
        firmaValuta, 
        stavkaSeNaplacuje, 
        prijevodi,
        formStavka,
        racunID
    ]);

    const handleEsc = useCallback((event) => {
        if(event.keyCode == 27){
            event.preventDefault();
            if (!loading) {
                setCanceled(true); 
                setFormOpened(false);
            }            
        }
    }, [loading]);
    
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressShorcuts);
        document.addEventListener('keyup', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleKeyPressShorcuts);
            document.removeEventListener('keyup', handleEsc);
        };
    }, [handleKeyPressShorcuts]);


    useEffect(() => checkIfFormValid(), [cijena, kolicina, marza, marzaPosto, rabat, rabatPosto, opis]);

    function checkIfFormValid() {
        let valid = false;
        if (tipRacunskeStavkeID == 1) {
            valid = opis != null;
        } else {
            //ovo sam eksplicitno morao dovesti do booleana, jer u protivon varijabla valid nije nužno boolena s vrijednošću 'true', što se provjerava na setu state-a setFormValid
            valid = opis != null && opis !== '' && cijena && !isNaN(cijena) && cijena > 0 && kolicina && !isNaN(kolicina) && kolicina > 0 && marza && !isNaN(marza) && marza >= 0 && rabat && !isNaN(rabat) && rabat >= 0 && marzaPosto && !isNaN(marzaPosto) && marzaPosto >= 0 && rabatPosto && !isNaN(rabatPosto) && rabatPosto >= 0;
        }
        setFormValid(valid == true);
    }

    function calculateStavke(){
        if(vrstaPregleda.Vrijednost == 0){
            if(vrstaPregledaPoTipuEvidencije.Vrijednost == 0){
                return stavke;
            }           
            else{
                let retrn = stavke.filter(s => s.TipEvidencijeID == vrstaPregledaPoTipuEvidencije.Vrijednost);
                return retrn;
            }
        }
        else{
            if(vrstaPregledaPoTipuEvidencije.Vrijednost == 0){
                let retrn = stavke.filter(s => s.TipNaplatnostiID == vrstaPregleda.Vrijednost);
                return retrn;
            }
            else{
                let retrn = stavke.filter(s => s.TipNaplatnostiID == vrstaPregleda.Vrijednost && s.TipEvidencijeID == vrstaPregledaPoTipuEvidencije.Vrijednost);
                return retrn;
            }
        }

        //vrstaPregleda.Vrijednost == 0 ? (vrstaPregledaPoTipuEvidencije.Vrijednost == 0 ? stavke : (stavke.filter(s => s.TipEvidencijeID == vrstaPregledaPoTipuEvidencije.Vrijednost))) : (vrstaPregledaPoTipuEvidencije == 0 ? stavke.filter(s => s.TipNaplatnostiID == vrstaPregleda.Vrijednost) : stavke.filter(s => s.TipNaplatnostiID == vrstaPregleda.Vrijednost && s.TipEvidencijeID == vrstaPregledaPoTipuEvidencije.Vrijednost))
    }

    // useEffect(() => {
    //     if (predmet && predmet.FirmaValuta) {
    //         setFirmaValuta(predmet.FirmaValuta);
    //     }
    // }, [predmet]);

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formStavka ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <AddIcon fontSize="medium" style={{marginRight:"15px"}}></AddIcon>
                            )}
                            {formStavka ? (
                                tipRacunskeStavkeID == 1 ? "Uređivanje specifikacije" : (tipRacunskeStavkeID == 2 ? "Uređivanje uslužne stavke" : (tipRacunskeStavkeID == 3 ? "Uređivanje robne stavke" : ""))
                            ) : (
                                tipRacunskeStavkeID == 1 ? "Nova specifikacija" : (tipRacunskeStavkeID == 2 ? "Nova uslužna stavka" : (tipRacunskeStavkeID == 3 ? "Nova robna stavka" : ""))
                            )}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={tipRacunskeStavkeID == 1 ? 7 : 6}>
                    <FormControl fullWidth>
                        <TextField autoFocus label="Opis" value={opis} error={!opis || opis == ""} inputProps={{ maxLength: 255, readOnly: formStavka != null}} onChange={handleOpisChange} onBlur={e => findKonto(e.target.value)} size="small" />
                    </FormControl>
                </Grid>
                <Grid item xs={5} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <Button startIcon={<TranslateIcon></TranslateIcon>} onClick={() => setPrijevodOpened(true)} variant="contained" style={{textAlign:"center", width:"100%", marginLeft:"10px"}}>
                        Izmjena opisa i prijevoda stavke
                    </Button>
                </Grid>
                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                        <IconButton onClick={handleHistoryClick}><HistoryIcon></HistoryIcon></IconButton>
                    </Grid>
                ) : null}
                <Grid item xs={6} marginTop="2px" marginBottom="2px">
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker
                            label="Datum obračuna"
                            value={datumObracuna}
                            inputFormat="dd.MM.yyyy"
                            maxDate={new Date()}
                            onChange={(v) => {if (v) setDatumObracuna(v)}}
                            renderInput={(params) => <TextField style={{width:"99%"}} {...params} size="small" error={!datumObracuna || isNaN(new Date(datumObracuna))} required />}
                        />
                    </LocalizationProvider>           
                </Grid>
                {tipRacunskeStavkeID == 1 ? (
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                options={vrstePregledaPoTipuEvidencije}
                                value={vrstaPregledaPoTipuEvidencije}
                                autoHighlight
                                getOptionLabel={(option) => option.Naziv}
                                onChange={(e,v) => {if (v) setVrstaPregledaPoTipuEvidencije(v)}}
                                renderInput={(params) => <TextField {...params} label="Tip evidencije" size="small" style={{width:"99%"}}/>}
                            />
                        </FormControl>
                    </Grid>
                ) : null} 
                {tipRacunskeStavkeID == 1 ? (
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                options={vrstePregleda}
                                value={vrstaPregleda}
                                autoHighlight
                                getOptionLabel={(option) => option.Naziv}
                                onChange={(e,v) => {if (v) setVrstaPregleda(v)}}
                                renderInput={(params) => <TextField {...params} label="Tip naplatnosti" size="small" style={{width:"99%"}}/>}
                            />
                        </FormControl>
                    </Grid>
                ) : null}              
                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={6} >
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                autoHighlight
                                options={firmaValute}
                                value={firmaValuta}
                                disabled={predmet && predmet.Cap}
                                getOptionLabel={(option) => option.FirmaValutaNaziv}
                                onChange={(e,v) => {if (v) setFirmaValuta(v)}}
                                renderInput={(params) => <TextField {...params} label="Valuta" size="small" required error={!firmaValuta} />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 1 ? (
                    <Grid item xs={12} marginTop="8px">
                        <Box>
                            <FormLabel style={{marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 2, backgroundColor: "white", position: "absolute", fontSize: "0.75em"}}>Stavke specifikacije</FormLabel>
                            <Box sx={{ borderRadius: 1, border: 1, borderColor: 'grey.400', "&:hover": { borderColor: 'black' }}} padding="5px">
                                <Box style={{width:"100%", marginTop:"20px"}}>
                                    <AddEditDelForm tipNaplatnostiNaplatnoBoja={props.tipNaplatnostiNaplatnoBoja} tipNaplatnostiNenaplatnoBoja={props.tipNaplatnostiNenaplatnoBoja} dataType="stavkeSpecifikacije" data={calculateStavke()} current={currentStavka} setCurrent={setCurrentStavka} add={addStavkaRacunskeStavke} edit={editStavkaRacunskeStavke} formStavka={formStavkaRacunskeStavke} del={deleteStavkaRacunskeStavke} disableButtons={takeALook} disableTakeALook={!takeALook}></AddEditDelForm>
                                    <Box style={{display:"flex", justifyContent:"flex-end", marginTop:"5px"}}>
                                        <Button disabled={!currentStavka || takeALook} onClick={() => {
                                            setStavkaForPrebacajID(currentStavka.StavkaRacunskeStavkeID);
                                            setPrebaciStavkuFormOpened(true);
                                        }} startIcon={<MoveUpIcon></MoveUpIcon>} variant="contained">Prebaci na drugu specifikaciju</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 1 ? (
                    <Grid item xs={12} style={{display:"flex", justifyContent:"flex-end"}}>
                        <FormControlLabel
                            labelPlacement="end" 
                            label="Specifikacija se naplaćuje" 
                            control={<Checkbox checked={stavkaSeNaplacuje} disabled={fiksnaNaplata && formStavka && formStavka.TipVrsteStavkeID == 4} onChange={e => setStavkaSeNaplacuje(e.target.checked)}></Checkbox>}>
                        </FormControlLabel>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={1} >
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={6} sm={7} md={5} >
                        <FormControl fullWidth>
                            <TextField value={kolicina} type="number" error={kolicina == null || kolicina == "" || kolicina.charAt(0) == "-" || parseFloat(kolicina) <= 0} onChange={e => setKolicina(e.target.value)} onBlur={handleKolicinaFocusOut} label="Količina" size="small" required></TextField>    
                        </FormControl>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={5} sm={4} md={6} >
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                autoHighlight
                                options={jediniceMjere}
                                value={jedinicaMjere}
                                getOptionLabel={(option) => option.Opis + " (" + option.Kratica + ")"}
                                onChange={(e,v) => {if (v) setJedinicaMjere(v)}}
                                renderInput={(params) => <TextField {...params} label="Jedinica mjere" size="small" required error={!jedinicaMjere} />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}

                {/* {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={6} >
                    </Grid>
                ) : null} */}

                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={1} >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            height: '100%'
                        }}>
                            <CloseIcon></CloseIcon>
                        </div>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={5} >
                        <FormControl fullWidth>
                            <TextField value={cijena} type="number" error={cijena == null || cijena == "" || cijena.charAt(0) == "-" || parseFloat(cijena) <= 0} onChange={e => setCijena(e.target.value)} onBlur={handleCijenaFocusOut} label={tipRacunskeStavkeID == 2 ? "Cijena" : "Ulazna cijena"} size="small" required></TextField>    
                        </FormControl>
                    </Grid>
                ) : null}

                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={6} >
                    </Grid>
                ) : null}

                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={1} >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            height: '100%'
                        }}>
                            <DragHandleIcon></DragHandleIcon>
                        </div>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={5} >
                        <FormControl fullWidth>
                            <TextField value={ukupno} inputProps={{ readOnly: true }} label="Ukupno" size="small"></TextField>    
                        </FormControl>
                    </Grid>
                ) : null}

                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={6} >
                    </Grid>
                ) : null}

                {tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={1} >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            height: '100%'
                        }}>
                            <AddIcon></AddIcon>
                        </div>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={6} sm={7} md={5} >
                        <FormControl fullWidth>
                            <TextField value={marza} type="number" error={marza != null && marza != "" && marza.charAt(0) == "-"} onChange={e => setMarza(e.target.value)} onBlur={handleMarzaFocusOut} label="Marža" size="small"></TextField>    
                        </FormControl>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={5} sm={4} md={6} >
                        <FormControl fullWidth>
                            <TextField value={marzaPosto} type="number" error={marzaPosto != null && marzaPosto != "" && marzaPosto.charAt(0) == "-"} onChange={e => setMarzaPosto(e.target.value)} onBlur={handleMarzaPostoFocusOut} label="Marža stopa" size="small" InputProps={{endAdornment:(<InputAdornment position="end">%</InputAdornment>)}}></TextField>    
                        </FormControl>
                    </Grid>
                ) : null}

                {/* {tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={6} >
                    </Grid>
                ) : null} */}

                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={1} >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            height: '100%'
                        }}>
                            <RemoveIcon></RemoveIcon>
                        </div>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={6} sm={7} md={5} >
                        <FormControl fullWidth>
                            <TextField value={rabat} type="number" error={rabat != null && rabat != "" && rabat.charAt(0) == "-"} onChange={e => setRabat(e.target.value)} onBlur={handleRabatFocusOut} label="Rabat" size="small"></TextField>    
                        </FormControl>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={5} sm={4} md={6} >
                        <FormControl fullWidth>
                            <TextField value={rabatPosto} type="number" error={rabatPosto != null && rabatPosto != "" && rabatPosto.charAt(0) == "-"} onChange={e => setRabatPosto(e.target.value)} onBlur={handleRabatPostoFocusOut} label="Rabat stopa" size="small" InputProps={{endAdornment:(<InputAdornment position="end">%</InputAdornment>)}}></TextField>    
                        </FormControl>
                    </Grid>
                ) : null}

                {/* {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={6} >
                    </Grid>
                ) : null} */}

                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={1} >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            height: '100%'
                        }}>
                            <AddIcon></AddIcon>
                        </div>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={5} >
                        <FormControl fullWidth>
                            <TextField value={porez} inputProps={{ readOnly: true }} label="Porez" size="small"></TextField>  
                        </FormControl>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={6} >
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                autoHighlight
                                options={tipoviOporezivosti}
                                value={tipOporezivosti}
                                getOptionLabel={(option) => option.TipPorezaNaziv + " (" + option.Stopa * 100 + " %)"}
                                onChange={handleTipOporezivostiChange}
                                renderInput={(params) => <TextField {...params} label="Tip oporezivosti" size="small" error={!tipOporezivosti} required/>}
                            />
                        </FormControl>
                    </Grid>
                ) : null}

                {/* {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={6} >
                    </Grid>
                ) : null} */}

                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={1} >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            height: '100%'
                        }}>
                            <DragHandleIcon></DragHandleIcon>
                        </div>
                    </Grid>
                ) : null}
                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={5} >
                        <FormControl fullWidth>
                            <TextField value={ulaznaCijena} inputProps={{ readOnly: true }} label={tipRacunskeStavkeID == 2 ? "Ulazna cijena" : "Prodajna cijena"} size="small"></TextField>    
                        </FormControl>
                    </Grid>
                ) : null}

                {tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3 ? (
                    <Grid item xs={6} >
                    </Grid>
                ) : null}

                {tipRacunskeStavkeID == 2 ? (
                    <Grid item xs={12} >
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={fiksnaNaplataPredmetID ? predmeti.filter(p => p.PredmetID == fiksnaNaplataPredmetID) : predmeti}
                                value={predmet}
                                getOptionLabel={(option) => option.KlijentNaziv + " - " + option.Broj + " - " + option.Naziv}
                                renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={"predmet" + option.PredmetID}>
                                        {option.KlijentNaziv + " - " + option.Broj + " - " + option.Naziv}
                                      </li>
                                    );
                                }}
                                filterOptions={(options, state) => options.filter(option => (option.KlijentNaziv + " " + option.Broj + " " + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                                onChange={(e,v) => {setPredmet(v)}}
                                renderInput={(params) => <TextField {...params} label="Predmet" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {(tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3) && kontoVidljivZaStavku == 1 ? (
                    <Grid item xs={10} >
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                options={konta}
                                autoHighlight
                                value={konto}
                                getOptionLabel={(option) => option.Naziv + " (" + option.ForeignKontoID + ") - " + (option.MjestoTroskaNaziv ? option.MjestoTroskaNaziv : "") + " (" + (option.MjestoTroskaForeignID ? option.MjestoTroskaForeignID : "") + ")"}
                                onChange={(e,v) => {if (v) setKonto(v)}}
                                renderInput={(params) => <TextField {...params} label="Konto" size="small" />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {(tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3) && kontoVidljivZaStavku == 1 ? (
                    <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                        <Tooltip title="Dodaj predložak">
                            <span>
                                <IconButton onClick={addKontoPredlozak}><AddIcon></AddIcon></IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                ) : null}
                {(tipRacunskeStavkeID == 2 || tipRacunskeStavkeID == 3) && kontoVidljivZaStavku == 1 ? (
                    <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                        <Tooltip title="Obriši predložak">
                            <span>
                                <IconButton onClick={deleteKontoPredlozak}><RemoveIcon></RemoveIcon></IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" style={{marginRight:"10px"}} color="success" disabled={loading || takeALook || !formValid}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <DoneOutlineIcon style={{marginRight:"10px"}} />} Sp&#818;remi</Button>
                        <Button variant="outlined" color="error" onClick={() => {setCanceled(true); setFormOpened(false);}} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>O&#818;dustani</Button>
                    </Box>
                </Grid>
            </Grid>

            <Modal open={stavkaRacunskeStavkeFormOpened}>
                <Box sx={styleNew} overflow={"auto"} >
                    <StavkaRacunskeStavkeForm 
                        setStavke={setStavke} 
                        racunskeStavke={racunskeStavke} 
                        setRacunskeStavke={setRacunskeStavke}
                        stavke={stavke} 
                        racunFirmaValutaID={props.racunFirmaValutaID} 
                        racunskaStavkaDatumObracuna={datumObracuna} 
                        setFormOpened={setStavkaRacunskeStavkeFormOpened} 
                        formStavka={formStavkaRacunskeStavke} 
                        racunskaStavkaID={formStavka ? formStavka.RacunskaStavkaID : -1} 
                        racunID={racunID}
                        klijent={klijent}
                        refresh={refresh}
                        tipNaplatnostiNaplatnoBoja={props.tipNaplatnostiNaplatnoBoja}
                        tipNaplatnostiNenaplatnoBoja={props.tipNaplatnostiNenaplatnoBoja}
                        klijenti={fiksnaNaplataKlijentID ? props.klijenti.filter(k => k.KlijentID == fiksnaNaplataKlijentID) : props.klijenti}
                        predmeti={predmeti}
                        firmaValute={firmaValute}
                        defaultFirmaValuta={props.defaultFirmaValuta}
                        zaposlenici={props.zaposlenici}
                        tipoviNaplatnosti={tipoviNaplatnosti}
                        tipoviOporezivosti={tipoviOporezivosti}
                        tipoviKorisnika={tipoviKorisnika}
                        defaultTipOporezivosti={props.racunskaStavkaFormDetalji.DefaultTipOporezivosti}
                        takeALook={takeALook}
                        fiksnaNaplata={props.fiksnaNaplata}
                        fiksnaNaplataKlijentID={fiksnaNaplataKlijentID}
                        fiksnaNaplataPredmetID={fiksnaNaplataPredmetID}
                        tipProvjerePromjeneCijene={props.tipProvjerePromjeneCijene}>
                    </StavkaRacunskeStavkeForm>
                </Box>
            </Modal>

            {/* <Menu
                anchorEl={anchorAddEl}
                open={historyOpened}
                onClose={handleHistoryClose}
                PaperProps={{ style: { maxWidth: "50vw" } }} 
            >
                <ListItem>
                    <FormControl fullWidth>
                        <TextField 
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }} 
                            value={povijestFilter} 
                            onChange={e => setPovijestFilter(e.target.value)} 
                            size="small"
                            inputRef={historyRef}
                            InputProps={{endAdornment:(<InputAdornment position="end"><SearchIcon></SearchIcon></InputAdornment>)}}
                            onKeyDown={e => e.stopPropagation()}
                        ></TextField>
                    </FormControl>
                </ListItem>
                <Divider></Divider>
                {tipRacunskeStavkeID == 2 ? (
                    <Box style={{maxHeight:"50vh", overflow:"auto"}}>
                        {usluge.filter(u => u.Opis.toLowerCase().includes(povijestFilter.toLowerCase())).map((usluga, index) => {
                            return <Tooltip title={usluga.Opis} enterDelay={200} enterNextDelay={200}>
                                    <MenuItem onClick={() => {setOpis(usluga.Opis); findKonto(usluga.Opis); handleHistoryStavkaClick(usluga)}}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {usluga.Opis + ' (količina: ' + usluga.Kolicina + ', cijena: ' + Util.toStringDecimal(usluga.Cijena) + ' ' + (usluga.FirmaValuta ? usluga.FirmaValuta.FirmaValutaNaziv : "") + ')'}
                                        </div>
                                    </MenuItem>
                                </Tooltip>
                        })}
                    </Box>
                ) : null}
                {tipRacunskeStavkeID == 3 ? (
                    <Box style={{maxHeight:"50vh", overflow:"auto"}}>
                        {robneStavke.filter(s => s.Opis.toLowerCase().includes(povijestFilter.toLowerCase())).map((robnaStavka, index) => {
                            return <Tooltip title={robnaStavka.Opis} enterDelay={200} enterNextDelay={200}>
                                    <MenuItem onClick={() => {setOpis(robnaStavka.Opis); findKonto(robnaStavka.Opis); handleHistoryStavkaClick(robnaStavka);}}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {robnaStavka.Opis + ' (količina: ' + robnaStavka.Kolicina + ', cijena: ' + Util.toStringDecimal(robnaStavka.Cijena) + ' ' + robnaStavka.FirmaValuta.FirmaValutaNaziv + ')'}
                                        </div>
                                    </MenuItem>
                                </Tooltip>
                        })}
                    </Box>
                ) : null}
            </Menu> */}

            <Menu
                anchorEl={anchorAddEl}
                open={historyOpened}
                onClose={handleHistoryClose}
                PaperProps={{ style: { minWidth:"40vw", maxWidth: "50vw", minHeight:"45vh" } }} 
            >
                <MenuItem onKeyDown={(e) => e.stopPropagation()}>

                {tipRacunskeStavkeID == 2 ? (
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete
                            size="small"
                            disablePortal
                            options={usluge.filter(u => u.Opis.toLowerCase().includes(povijestFilter.toLowerCase()))}
                            getOptionLabel={(usluga) => usluga.Opis + ' (količina: ' + usluga.Kolicina + ', cijena: ' + Util.toStringDecimal(usluga.Cijena) + ' ' + (usluga.FirmaValuta ? usluga.FirmaValuta.FirmaValutaNaziv : "") + ')'}
                            onChange={(e,v) => handleHistoryStavkaClick(v)}
                            value={uslugaHistory}
                            autoHighlight
                            ListboxProps={{ style: { maxHeight: "35vh" } }}
                            openOnFocus
                            onClose={handleHistoryClose}
                            renderInput={(params) => 
                                <TextField
                                    {...params}
                                    size="small"  
                                    inputProps={{ ...params.inputProps }} 
                                    label="Nedavno kreirane uslužne stavke" 
                                    inputRef={historyRef}
                                />}
                            filterOptions={(options, state) => options.filter((option) => option.Opis.toLowerCase().includes(state.inputValue.toLowerCase()))}
                        />
                    </FormControl>
                ) : null} 

                {tipRacunskeStavkeID == 3 ? (
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete
                            size="small"
                            disablePortal
                            options={robneStavke.filter(u => u.Opis.toLowerCase().includes(povijestFilter.toLowerCase()))}
                            getOptionLabel={(robnaStavka) => robnaStavka.Opis + ' (količina: ' + robnaStavka.Kolicina + ', cijena: ' + Util.toStringDecimal(robnaStavka.Cijena) + ' ' + robnaStavka.FirmaValuta.FirmaValutaNaziv + ')'}
                            onChange={(e,v) => handleHistoryStavkaClick(v)}
                            value={robnaStavkaHistory}
                            autoHighlight
                            ListboxProps={{ style: { maxHeight: "35vh" } }}
                            openOnFocus
                            onClose={handleHistoryClose}
                            renderInput={(params) => 
                                <TextField
                                    {...params}
                                    size="small"  
                                    inputProps={{ ...params.inputProps }} 
                                    label="Nedavno kreirane robne stavke" 
                                    inputRef={historyRef}
                                />}
                            filterOptions={(options, state) => options.filter((option) => option.Opis.toLowerCase().includes(state.inputValue.toLowerCase()))}
                        />
                    </FormControl>
                ) : null}            

                </MenuItem>
            </Menu>

            <Modal className="modal" open={deleteAlert}>
                <Box sx={styleDelete} overflow="auto">
                    <Grid container spacing={1}>
                        <Grid item xs={12} marginBottom="15px">
                            <Typography variant="subtitle1" gutterBottom component="div" >
                                Jeste li sigurni da želite obrisati odabranu stavku specifikacije?
                            </Typography>
                        </Grid>
                        {postojeAktivnosti ? (
                            <Grid item xs={12} >
                                <FormControlLabel 
                                    labelPlacement="end" 
                                    label="Vrati aktivnost iz koje je stavka specifikacije nastala u nepreneseno stanje."
                                    style={{marginLeft:"0px"}}
                                    control={<Checkbox checked={vratiUNeprenesenoStanje} onChange={e => setVratiUNeprenesenoStanje(e.target.checked)}></Checkbox>}>
                                </FormControlLabel>
                            </Grid>
                        ) : null}
                        {postojeTroskovi ? (
                            <Grid item xs={12} >
                                <FormControlLabel 
                                    labelPlacement="end" 
                                    label="Vrati trošak iz kojeg je stavka specifikacije nastala u nepreneseno stanje."
                                    style={{marginLeft:"0px"}}
                                    control={<Checkbox checked={vratiUNeprenesenoStanje} onChange={e => setVratiUNeprenesenoStanje(e.target.checked)}></Checkbox>}>
                                </FormControlLabel>
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <Box style={{display:"flex", justifyContent:"flex-end"}}>
                                <Button variant="contained" onClick={deleteConfirmed} style={{marginRight:"10px"}}>Obriši</Button>
                                <Button onClick={() => setDeleteAlert(false)}>Odustani</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
           
            <Modal className="modal" open={prebaciStavkuFormOpened}>
                <Box sx={styleDelete} overflow="auto">
                    <PrebaciStavkuRacunskeStavkeNaDruguSpecifikacijuForm refresh={refresh} stavkaRacunskeStavkeID={stavkaForPrebacajID} setFormOpened={setPrebaciStavkuFormOpened}></PrebaciStavkuRacunskeStavkeNaDruguSpecifikacijuForm>
                </Box>
            </Modal>

            <Modal className="modal" open={prijevodOpened}>
                <Box sx={styleDelete} overflow="auto">
                    <PrijevodRacunskeStavkeForm prijevodi={prijevodi} takeALook={takeALook} setPrijevodi={setPrijevodi} opis={opis} setOpis={setOpis} firmaJezikID={firmaJezikID} close={() => setPrijevodOpened(false)}></PrijevodRacunskeStavkeForm>
                </Box>
            </Modal>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default RacunskaStavkaForm;