import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ErrorIcon from '@mui/icons-material/Error';
 
import api from "../util/api";

export default function AutocompleteAsync(props) {
    const [options, setOptions] = React.useState([]);
    const [tid, settid] = React.useState();
    const [value, setValue] = React.useState();
    
    function search() {
        if(typeof props.api !== 'undefined'){
            api.get(props.api, (result) => {
                setOptions(result);
            });
        }
    }

    

    useEffect(() => {

        if(tid)
            clearTimeout(tid);
        
        let id = setTimeout(() => {
            search();
        }, 400);

        if(tid != id)
            settid(id);

    }, [value]);

    return (
        <Autocomplete
            name={props.name}
            label={props.label}
            id={props.id}
            sx={{ width: 300 }}
            getOptionLabel={props.getOptionLabel}
            filterOptions={(x) => x}
            options={options || []}
            autoComplete
            freeSolo={false}
            size="small"
            includeInputInList
            filterSelectedOptions
            style={{ width: "100%" }}
            value={props.value}
            noOptionsText=""
            onChange={props.onChange}
            onInputChange={(event, newInputValue) => {
                if(event){
                    setValue(newInputValue);
                    if (props.onInputChange) props.onInputChange(event, newInputValue);
                }
            }}
            autoHighlight
            renderOption={(e,v) => {
                return (props.renderOption ? props.renderOption(e,v) : null);
            }}
            error="true"
            //helperText={props.helperText}
            renderInput={props.renderInput}
        />
    );
}
