import { Alert, Autocomplete, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, Grow, InputAdornment, Modal, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import SettingsIcon from '@mui/icons-material/Settings';
import api from "../util/api";
import EmailIcon from '@mui/icons-material/Email';
import { Box } from "@mui/system";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import PredlosciPoruka from "./predlosciPoruka";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
};

const tipoviPromjeneCijene = [
    { Naziv: "Prozor s promjenom cijene diže se ako postoji bar jedna stavka s različitim parametrima naplate (cijena, valuta, tip oporezivosti, tip naplatnosti) i ne postoji niti jedna s identičnim parametrima", Vrijednost: 1 },
    { Naziv: "Prozor s promjenom cijene diže se ako postoji bar jedna stavka s različitim parametrima naplate, neovisno o tome postoji li već neka stavka s identičnim parametrima", Vrijednost: 2 }
];

const vrsteEmailPoruka = [
    { Naziv: "Zahtjev za registracijom", Vrijednost: 1 },
    { Naziv: "Aktivacijski email (za korisnika)", Vrijednost: 2 },
    { Naziv: "Korisnički podaci", Vrijednost: 3 },
    { Naziv: "Firma aktivirana", Vrijednost: 4 },
    { Naziv: "Zahtjev za aktivacijom korisnika", Vrijednost: 5 },
    { Naziv: "Korisnik aktiviran", Vrijednost: 6 },
    { Naziv: "Korisnik aktiviran (s korisničkim podacima)", Vrijednost: 7 },
    { Naziv: "Korisnik reaktiviran", Vrijednost: 8 },
    { Naziv: "Zahtjev zaprimljen", Vrijednost: 9 },
    { Naziv: "Dojava o grešci", Vrijednost: 10 },
    { Naziv: "Neuspješna fiskalizacija", Vrijednost: 11 },
    { Naziv: "Računovodstvo export", Vrijednost: 12 },
    { Naziv: "Zahtjev odobren", Vrijednost: 13 },
    { Naziv: "Promjena lozinke", Vrijednost: 14 },
    { Naziv: "Izmjena zadatka", Vrijednost: 15 },
    { Naziv: "Podsjetnik na zadatak", Vrijednost: 16 },
    { Naziv: "Zadatak nije završen", Vrijednost: 17 },
];

const vrstePorukaSustava = [
    { Naziv: "Probni period", Vrijednost: 1 },
    { Naziv: "Forma za registraciju", Vrijednost: 2 },
    { Naziv: "Kapacitet elektroničke arhive", Vrijednost: 3 },
    { Naziv: "Probni period istekao", Vrijednost: 4 },
    { Naziv: "Naplata barkoda", Vrijednost: 5 },
    { Naziv: "Naplata fiskalnih blagajni", Vrijednost: 6 },
    { Naziv: "Uvjeti korištenja", Vrijednost: 7 },
    { Naziv: "Evidencija u potencijalno preneseno razdoblje", Vrijednost: 8 }
];

export default function PostavkeSustava(props) {

    const useConstructor = Util.useConstructor();

    const [loading, setLoading] = useState(true);
    const [probniPeriodBrojDana, setProbniPeriodBrojDana] = useState(1);
    const [registracijaEmailTo, setRegistracijaEmailTo] = useState("");
    const [smtpServer, setSmtpServer] = useState("");
    const [smtpPort, setSmtpPort] = useState(1);
    const [smtpUsername, setSmtpUsername] = useState("");
    const [smtpPassword, setSmtpPassword] = useState("");
    const [fromEmail, setFromEmail] = useState("");
    const [fromName, setFromName] = useState("");
    const [disableFileLogging, setDisableFileLogging] = useState(false);
    const [tipPromjeneCijene, setTipPromjeneCijene] = useState(tipoviPromjeneCijene[0]);
    const [aktivacijaKorisnikaZahtjevEmailTo, setAktivacijaKorisnikaZahtjevEmailTo] = useState("");
    const [passwordResetMessage, setPasswordResetMessage] = useState("");
    const [eServiceURL, setEServiceURL] = useState("");
    const [refiskalizacijaSleepTime, setRefiskalizacijaSleepTime] = useState(180000);
    const [PUPoveznica, setPUPoveznica] = useState("");
    const [iznosFormat, setIznosFormat] = useState("");
    const [iznosSeparator, setIznosSeparator] = useState("");
    const [iznosPutaSto, setIznosPutaSto] = useState(0);
    const [URLEncodeLink, setURLEncodeLink] = useState(0);
    const [izvjestajAktivnostKorisnikaEmailTo, setIzvjestajAktivnostKorisnikaEmailTo] = useState("");
    const [predlosciPoruka, setPredlosciPoruka] = useState(false);
    const [emailSubject, setEmailSubject] = useState("");
    const [emailBody, setEmailBody] = useState();
    const [zahtjevZaAktivacijomMailBody, setZahtjevZaAktivacijomMailBody] = useState("");
    const [zahtjevZaAktivacijomMailSubject, setZahtjevZaAktivacijomMailSubject] = useState("");
    const [aktivacijskiMailBody, setAktivacijskiMailBody] = useState("");
    const [aktivacijskiMailSubject, setAktivacijskiMailSubject] = useState("");
    const [credentialsMailBody, setCredentialsMailBody] = useState("");
    const [credentialsMailSubject, setCredentialsMailSubject] = useState("");
    const [firmaAktiviranaMailBody, setFirmaAktiviranaMailBody] = useState("");
    const [firmaAktiviranaMailSubject, setFirmaAktiviranaMailSubject] = useState("");
    const [zahtjevZaAktivacijomKorisnikaMailBody, setZahtjevZaAktivacijomKorisnikaMailBody] = useState("");
    const [zahtjevZaAktivacijomKorisnikaMailSubject, setZahtjevZaAktivacijomKorisnikaMailSubject] = useState("");
    const [korisnikAktiviranMailBody, setKorisnikAktiviranMailBody] = useState("");
    const [korisnikAktiviranMailSubject, setKorisnikAktiviranMailSubject] = useState("");
    const [korisnikAktiviranWithCredentialsMailBody, setKorisnikAktiviranWithCredentialsMailBody] = useState("");
    const [korisnikAktiviranWithCredentialsMailSubject, setKorisnikAktiviranWithCredentialsMailSubject] = useState("");
    const [korisnikReaktiviranMailBody, setKorisnikReaktiviranMailBody] = useState("");
    const [korisnikReaktiviranMailSubject, setKorisnikReaktiviranMailSubject] = useState("");
    const [zahtjevZaprimljenMailBody, setZahtjevZaprimljenMailBody] = useState("");
    const [zahtjevZaprimljenMailSubject, setZahtjevZaprimljenMailSubject] = useState("");
    const [errorAlertMailBody, setErrorAlertMailBody] = useState("");
    const [errorAlertMailSubject, setErrorAlertMailSubject] = useState("");
    const [neuspjesnaFiskalizacijaMailBody, setNeuspjesnaFiskalizacijaMailBody] = useState("");
    const [neuspjesnaFiskalizacijaMailSubject, setNeuspjesnaFiskalizacijaMailSubject] = useState("");
    const [errorAlertEmailTo, setErrorAlertEmailTo] = useState("");
    const [emailParametri, setEmailParametri] = useState([]);
    const [vrstaEmailPoruke, setVrstaEmailPoruke] = useState(vrsteEmailPoruka[0]);
    const [probniPeriodMessage, setProbniPeriodMessage] = useState([]);
    const [porukeSustavaParametri, setPorukeSustavaParametri] = useState([]);
    const [porukaSustava, setPorukaSustava] = useState("");
    const [vrstaPorukeSustava, setVrstaPorukeSustava] = useState(vrstePorukaSustava[0]);
    const [registracijaFormMessage, setRegistracijaFormMessage] = useState("");
    const [kapacitetElektronickeArhiveMessage, setKapacitetElektronickeArhiveMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [racunovodstvoExportMailBody, setRacunovostvoExportMailBody] = useState("");
    const [racunovodstvoExportMailSubject, setRacunovostvoExportMailSubject] = useState("");
    const [deniedLoginMessage, setDeniedLoginMessage] = useState("");
    const [aktivacijaURL, setAktivacijaURL] = useState("");
    const [aktivacijaReturnURL, setAktivacijaReturnURL] = useState("");
    const [barkodNaplataMessage, setBarkodNaplataMessage] = useState("");
    const [fiskalneBlagajneNaplataMessage, setFiskalneBlagajneNaplataMessage] = useState("");
    const [barkodBrojRacuna, setBarkodBrojRacuna] = useState(0);
    const [barkodCijena, setBarkodCijena] = useState(0);
    const [valuta, setValuta] = useState(null);
    const [valute, setValute] = useState([]);
    const [zahtjevOdobrenMailSubject, setZahtjevOdobrenMailSubject] = useState("");
    const [zahtjevOdobrenMailBody, setZahtjevOdobrenMailBody] = useState("");
    const [passwordResetURL, setPasswordResetURL] = useState("");
    const [passwordResetMailBody, setPasswordResetMailBody] = useState("");
    const [passwordResetMailSubject, setPasswordResetMailSubject] = useState("");
    const [fiskalnaBlagajnaCijena, setFiskalnaBlagajnaCijena] = useState(0);
    const [fiskalnaBlagajnaValuta, setFiskalnaBlagajnaValuta] = useState(null);
    const [licencaCijena, setLicencaCijena] = useState(0);
    const [licencaValuta, setLicencaValuta] = useState(null);
    const [uvjetiKoristenja, setUvjetiKoristenja] = useState("");
    const [zadatakMailBody, setZadatakMailBody] = useState("");
    const [zadatakMailSubject, setZadatakMailSubject] = useState("");
    const [zadatakReminderMailBody, setZadatakReminderMailBody] = useState("");
    const [zadatakReminderMailSubject, setZadatakReminderMailSubject] = useState("");
    const [zadatakFinalReminderMailBody, setZadatakFinalReminderMailBody] = useState("");
    const [zadatakFinalReminderMailSubject, setZadatakFinalReminderMailSubject] = useState("");
    const [evidencijaUPrenesenoRazdobljeMessage, setEvidencijaUPrenesenoRazdobljeMessage] = useState("");

    useConstructor(() => {
        loadPostavke();
    });

    function loadPostavke() {
        setLoading(true);
        api.get("postavke/get", loadPostavkeSuccess, loadPostavkeFail);
    }

    function loadPostavkeSuccess(data) {
        setProbniPeriodBrojDana(data.BrojDanaZaTestiranje);
        setRegistracijaEmailTo(data.RegistracijaEmailTo);
        setSmtpServer(data.SMTPServer);
        setSmtpPort(data.SMTPPort);
        setSmtpUsername(data.SMTPUsername);
        setSmtpPassword(data.SMTPPassword);
        setFromEmail(data.FromEmail);
        setFromName(data.FromName);
        setDisableFileLogging(data.DisableFileLogging);
        setTipPromjeneCijene(tipoviPromjeneCijene.find(tpc => tpc.Vrijednost == data.TipProvjerePromjeneCijeneStavkeSpecifikacije));
        setAktivacijaKorisnikaZahtjevEmailTo(data.AktivacijaKorisnikaZahtjevEmailTo);
        setPasswordResetMessage(data.PasswordResetMessageByAutomate);
        setEServiceURL(data.EserviceUrl);
        setRefiskalizacijaSleepTime(data.RefiskalizacijaSleepTime);
        setPUPoveznica(data.PUPoveznica);
        setIznosFormat(data.IznosFormat);
        setIznosSeparator(data.IznosSeparator);
        setIznosPutaSto(data.IznosPutaSto);
        setURLEncodeLink(data.URLEncodeLink);
        setIzvjestajAktivnostKorisnikaEmailTo(data.IzvjestajAktivnostKorisnikaEmailTo);
        setZahtjevZaAktivacijomMailBody(data.ZahtjevZaAktivacijomMailBody);
        setZahtjevZaAktivacijomMailSubject(data.ZahtjevZaAktivacijomMailSubject);
        setAktivacijskiMailBody(data.AktivacijskiMailBody);
        setAktivacijskiMailSubject(data.AktivacijskiMailSubject);
        setCredentialsMailBody(data.CredentialsMailBody);
        setCredentialsMailSubject(data.CredentialsMailSubject);
        setFirmaAktiviranaMailBody(data.FirmaAktiviranaMailBody);
        setFirmaAktiviranaMailSubject(data.FirmaAktiviranaMailSubject);
        setZahtjevZaAktivacijomKorisnikaMailBody(data.ZahtjevZaAktivacijomKorisnikaMailBody);
        setZahtjevZaAktivacijomKorisnikaMailSubject(data.ZahtjevZaAktivacijomKorisnikaMailSubject);
        setKorisnikAktiviranMailBody(data.KorisnikAktiviranMailBody);
        setKorisnikAktiviranMailSubject(data.KorisnikAktiviranMailSubject);
        setKorisnikAktiviranWithCredentialsMailBody(data.KorisnikAktiviranWithCredentialsMailBody);
        setKorisnikAktiviranWithCredentialsMailSubject(data.KorisnikAktiviranWithCredentialsMailSubject);
        setKorisnikReaktiviranMailBody(data.KorisnikReaktiviranMailBody);
        setKorisnikReaktiviranMailSubject(data.KorisnikReaktiviranMailSubject);
        setZahtjevZaprimljenMailBody(data.ZahtjevZaprimljenMailBody);
        setZahtjevZaprimljenMailSubject(data.ZahtjevZaprimljenMailSubject);
        setErrorAlertMailBody(data.ErrorAlertMailBody);
        setErrorAlertMailSubject(data.ErrorAlertMailSubject);
        setNeuspjesnaFiskalizacijaMailBody(data.NeuspjesnaFiskalizacijaMailBody);
        setNeuspjesnaFiskalizacijaMailSubject(data.NeuspjesnaFiskalizacijaMailSubject);
        setEmailBody(data.ZahtjevZaAktivacijomMailBody);
        setEmailSubject(data.ZahtjevZaAktivacijomMailSubject);
        setVrstaEmailPoruke(vrsteEmailPoruka[0]);
        setErrorAlertEmailTo(data.ErrorAlertEmailTo);
        setEmailParametri(data.EmailParametri);
        setProbniPeriodMessage(data.ProbniPeriodMessage);
        setPorukeSustavaParametri(data.PorukeSustavaParametri);
        setPorukaSustava(data.ProbniPeriodMessage);
        setVrstaPorukeSustava(vrstePorukaSustava[0]);
        setRegistracijaFormMessage(data.RegistracijaFormMessage);
        setKapacitetElektronickeArhiveMessage(data.KapacitetElektronickeArhiveMessage);
        setDeniedLoginMessage(data.DeniedLoginMessage);
        setRacunovostvoExportMailBody(data.RacunovodstvoExportMailBody);
        setRacunovostvoExportMailSubject(data.RacunovodstvoExportMailSubject);
        setAktivacijaURL(data.AktivacijaURL);
        setAktivacijaReturnURL(data.AktivacijaReturnURL);
        setBarkodNaplataMessage(data.BarkodNaplataMessage);
        setFiskalneBlagajneNaplataMessage(data.FiskalneBlagajneNaplataMessage);
        setBarkodCijena(data.BarkodNaplataCijena);
        setValute(data.Valute);
        setValuta(data.Valute.find(v => v.ValutaID == data.BarkodNaplataValutaID));
        setBarkodBrojRacuna(data.BarkodNaplataBrojRacuna);
        setZahtjevOdobrenMailBody(data.ZahtjevOdobrenMailBody);
        setZahtjevOdobrenMailSubject(data.ZahtjevOdobrenMailSubject);
        setPasswordResetURL(data.PasswordResetURL);
        setPasswordResetMailBody(data.PasswordResetMailBody);
        setPasswordResetMailSubject(data.PasswordResetMailSubject);
        setFiskalnaBlagajnaCijena(data.FiskalnaBlagajnaCijena);
        setFiskalnaBlagajnaValuta(data.Valute.find(v => v.ValutaID == data.FiskalnaBlagajnaValutaID));
        setLicencaCijena(data.LicencaCijena);
        setLicencaValuta(data.Valute.find(v => v.ValutaID == data.LicencaValutaID));
        setUvjetiKoristenja(data.UvjetiKoristenja);
        setZadatakMailSubject(data.ZadatakMailSubject);
        setZadatakMailBody(data.ZadatakMailBody);
        setZadatakReminderMailSubject(data.ZadatakReminderMailSubject);
        setZadatakReminderMailBody(data.ZadatakReminderMailBody);
        setZadatakFinalReminderMailSubject(data.ZadatakFinalReminderMailSubject);
        setZadatakFinalReminderMailBody(data.ZadatakFinalReminderMailBody);
        setEvidencijaUPrenesenoRazdobljeMessage(data.EvidencijaUPrenesenoRazdobljeMessage);

        setLoading(false);
    }

    function loadPostavkeFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        if (vrstaEmailPoruke) {
            switch (vrstaEmailPoruke.Vrijednost) {
                case 1:
                    setEmailBody(zahtjevZaAktivacijomMailBody);
                    setEmailSubject(zahtjevZaAktivacijomMailSubject);
                    break;
                case 2:
                    setEmailBody(aktivacijskiMailBody);
                    setEmailSubject(aktivacijskiMailSubject);
                    break;
                case 3:
                    setEmailBody(credentialsMailBody);
                    setEmailSubject(credentialsMailSubject);
                    break;
                case 4:
                    setEmailBody(firmaAktiviranaMailBody);
                    setEmailSubject(firmaAktiviranaMailSubject);
                    break;
                case 5:
                    setEmailBody(zahtjevZaAktivacijomKorisnikaMailBody);
                    setEmailSubject(zahtjevZaAktivacijomKorisnikaMailSubject);
                    break;
                case 6:
                    setEmailBody(korisnikAktiviranMailBody);
                    setEmailSubject(korisnikAktiviranMailSubject);
                    break;
                case 7:
                    setEmailBody(korisnikAktiviranWithCredentialsMailBody);
                    setEmailSubject(korisnikAktiviranWithCredentialsMailSubject);
                    break;
                case 8:
                    setEmailBody(korisnikReaktiviranMailBody);
                    setEmailSubject(korisnikReaktiviranMailSubject);
                    break;
                case 9:
                    setEmailBody(zahtjevZaprimljenMailBody);
                    setEmailSubject(zahtjevZaprimljenMailSubject);
                    break;
                case 10:
                    setEmailBody(errorAlertMailBody);
                    setEmailSubject(errorAlertMailSubject);
                    break;
                case 11:
                    setEmailBody(neuspjesnaFiskalizacijaMailBody);
                    setEmailSubject(neuspjesnaFiskalizacijaMailSubject);
                    break;
                case 12:
                    setEmailBody(racunovodstvoExportMailBody);
                    setEmailSubject(racunovodstvoExportMailSubject);
                    break;
                case 13:
                    setEmailBody(zahtjevOdobrenMailBody);
                    setEmailSubject(zahtjevOdobrenMailSubject);
                    break;
                case 14:
                    setEmailBody(passwordResetMailBody);
                    setEmailSubject(passwordResetMailSubject);
                    break;
                case 15:
                    setEmailBody(zadatakMailBody);
                    setEmailSubject(zadatakMailSubject);
                    break;
                case 16:
                    setEmailBody(zadatakReminderMailBody);
                    setEmailSubject(zadatakReminderMailSubject);
                    break;
                case 17:
                    setEmailBody(zadatakFinalReminderMailBody);
                    setEmailSubject(zadatakFinalReminderMailSubject);
                    break;
            }
        }
    }, [vrstaEmailPoruke]);

    useEffect(() => {
        if (vrstaPorukeSustava) {
            switch (vrstaPorukeSustava.Vrijednost) {
                case 1:
                    setPorukaSustava(probniPeriodMessage);
                    break;
                case 2:
                    setPorukaSustava(registracijaFormMessage);
                    break;
                case 3:
                    setPorukaSustava(kapacitetElektronickeArhiveMessage);
                    break;
                case 4:
                    setPorukaSustava(deniedLoginMessage);
                    break;
                case 5:
                    setPorukaSustava(barkodNaplataMessage);
                    break;
                case 6:
                    setPorukaSustava(fiskalneBlagajneNaplataMessage);
                    break;
                case 7:
                    setPorukaSustava(uvjetiKoristenja);
                    break;
                case 8:
                    setPorukaSustava(evidencijaUPrenesenoRazdobljeMessage);
                    break;
            }
        }
    }, [vrstaPorukeSustava]);

    function multipleEmailsValid(emails) {
        let emailsValid = true;
        if (emails) {
            emails.split(";").forEach(e => {
                if (!Util.isValidEmail(e) && e != "") {
                    emailsValid = false;
                }
            });
        } else {
            emailsValid = false;
        }
        return emailsValid;
    }

    function handleEmailSubjectChange(value) {
        setEmailSubject(value);
        switch (vrstaEmailPoruke.Vrijednost) {
            case 1:
                setZahtjevZaAktivacijomMailSubject(value);
                break;
            case 2:
                setAktivacijskiMailSubject(value);
                break;
            case 3:
                setCredentialsMailSubject(value);
                break;
            case 4:
                setFirmaAktiviranaMailSubject(value);
                break;
            case 5:
                setZahtjevZaAktivacijomKorisnikaMailSubject(value);
                break;
            case 6:
                setKorisnikAktiviranMailSubject(value);
                break;
            case 7:
                setKorisnikAktiviranWithCredentialsMailSubject(value);
                break;
            case 8:
                setKorisnikReaktiviranMailSubject(value);
                break;
            case 9:
                setZahtjevZaprimljenMailSubject(value);
                break;
            case 10:
                setErrorAlertMailSubject(value);
                break;
            case 11:
                setNeuspjesnaFiskalizacijaMailSubject(value);
                break;
            case 12:
                setRacunovostvoExportMailSubject(value);
                break;
            case 13:
                setZahtjevOdobrenMailSubject(value);
                break;
            case 14:
                setPasswordResetMailSubject(value);
                break;
            case 15:
                setZadatakMailSubject(value);
                break;
            case 16:
                setZadatakReminderMailSubject(value);
                break;
            case 17:
                setZadatakFinalReminderMailSubject(value);
                break;
        }
    }

    function handleEmailBodyChange(value) {
        setEmailBody(value);
        switch (vrstaEmailPoruke.Vrijednost) {
            case 1:
                setZahtjevZaAktivacijomMailBody(value);
                break;
            case 2:
                setAktivacijskiMailBody(value);
                break;
            case 3:
                setCredentialsMailBody(value);
                break;
            case 4:
                setFirmaAktiviranaMailBody(value);
                break;
            case 5:
                setZahtjevZaAktivacijomKorisnikaMailBody(value);
                break;
            case 6:
                setKorisnikAktiviranMailBody(value);
                break;
            case 7:
                setKorisnikAktiviranWithCredentialsMailBody(value);
                break;
            case 8:
                setKorisnikReaktiviranMailBody(value);
                break;
            case 9:
                setZahtjevZaprimljenMailBody(value);
                break;
            case 10:
                setErrorAlertMailBody(value);
                break;
            case 11:
                setNeuspjesnaFiskalizacijaMailBody(value);
                break;
            case 12:
                setRacunovostvoExportMailBody(value);
                break;
            case 13:
                setZahtjevOdobrenMailBody(value);
                break;
            case 14:
                setPasswordResetMailBody(value);
                break;
            case 15:
                setZadatakMailBody(value);
                break;
            case 16:
                setZadatakReminderMailBody(value);
                break;
            case 17:
                setZadatakFinalReminderMailBody(value);
                break;
        }
    }

    function handlePorukaSustavaChange(value) {
        setPorukaSustava(value);
        switch (vrstaPorukeSustava.Vrijednost) {
            case 1:
                setProbniPeriodMessage(value);
                break;
            case 2:
                setRegistracijaFormMessage(value);
                break;
            case 3:
                setKapacitetElektronickeArhiveMessage(value);
                break;
            case 4:
                setDeniedLoginMessage(value);
                break;
            case 5:
                setBarkodNaplataMessage(value);
                break;
            case 6:
                setFiskalneBlagajneNaplataMessage(value);
                break;
            case 7:
                setUvjetiKoristenja(value);
                break;
            case 8:
                setEvidencijaUPrenesenoRazdobljeMessage(value);
                break;
        }
    }

    function save() {
        setLoading(true);
        let resource = {
            AktivacijaKorisnikaZahtjevEmailTo: aktivacijaKorisnikaZahtjevEmailTo,
            AktivacijskiMailBody: aktivacijskiMailBody,
            AktivacijskiMailSubject: aktivacijskiMailSubject,
            BrojDanaZaTestiranje: probniPeriodBrojDana,
            CredentialsMailBody: credentialsMailBody,
            CredentialsMailSubject: credentialsMailSubject,
            DisableFileLogging: disableFileLogging,
            ErrorAlertEmailTo: errorAlertEmailTo,
            ErrorAlertMailBody: errorAlertMailBody,
            ErrorAlertMailSubject: errorAlertMailSubject,
            EserviceUrl: eServiceURL,
            FirmaAktiviranaMailBody: firmaAktiviranaMailBody,
            FirmaAktiviranaMailSubject: firmaAktiviranaMailSubject,
            FromEmail: fromEmail,
            FromName: fromName,
            IznosFormat: iznosFormat,
            IznosPutaSto: iznosPutaSto,
            IznosSeparator: iznosSeparator,
            IzvjestajAktivnostKorisnikaEmailTo: izvjestajAktivnostKorisnikaEmailTo,
            KorisnikAktiviranMailBody: korisnikAktiviranMailBody,
            KorisnikAktiviranMailSubject: korisnikAktiviranMailSubject,
            KorisnikAktiviranWithCredentialsMailBody: korisnikAktiviranWithCredentialsMailBody,
            KorisnikAktiviranWithCredentialsMailSubject: korisnikAktiviranWithCredentialsMailSubject,
            KorisnikReaktiviranMailBody: korisnikReaktiviranMailBody,
            KorisnikReaktiviranMailSubject: korisnikReaktiviranMailSubject,
            NeuspjesnaFiskalizacijaMailBody: neuspjesnaFiskalizacijaMailBody,
            NeuspjesnaFiskalizacijaMailSubject: neuspjesnaFiskalizacijaMailSubject,
            PasswordResetMessageByAutomate: passwordResetMessage,
            PUPoveznica: PUPoveznica,
            RefiskalizacijaSleepTime: refiskalizacijaSleepTime,
            RegistracijaEmailTo: registracijaEmailTo,
            SMTPPassword: smtpPassword,
            SMTPPort: smtpPort,
            SMTPServer: smtpServer,
            SMTPUsername: smtpUsername,
            TipProvjerePromjeneCijeneStavkeSpecifikacije: tipPromjeneCijene.Vrijednost,
            URLEncodeLink: URLEncodeLink,
            ZahtjevZaAktivacijomKorisnikaMailBody: zahtjevZaAktivacijomKorisnikaMailBody,
            ZahtjevZaAktivacijomKorisnikaMailSubject: zahtjevZaAktivacijomKorisnikaMailSubject,
            ZahtjevZaAktivacijomMailBody: zahtjevZaAktivacijomMailBody,
            ZahtjevZaAktivacijomMailSubject: zahtjevZaAktivacijomMailSubject,
            ZahtjevZaprimljenMailBody: zahtjevZaprimljenMailBody,
            ZahtjevZaprimljenMailSubject: zahtjevZaprimljenMailSubject,
            ProbniPeriodMessage: probniPeriodMessage,
            RegistracijaFormMessage: registracijaFormMessage,
            KapacitetElektronickeArhiveMessage: kapacitetElektronickeArhiveMessage,
            RacunovodstvoExportMailBody: racunovodstvoExportMailBody,
            RacunovodstvoExportMailSubject: racunovodstvoExportMailSubject,
            DeniedLoginMessage: deniedLoginMessage,
            AktivacijaURL: aktivacijaURL,
            AktivacijaReturnURL: aktivacijaReturnURL,
            BarkodNaplataMessage: barkodNaplataMessage,
            FiskalneBlagajneNaplataMessage: fiskalneBlagajneNaplataMessage,
            BarkodNaplataCijena: barkodCijena,
            BarkodNaplataValutaID: valuta.ValutaID,
            BarkodNaplataBrojRacuna: barkodBrojRacuna,
            ZahtjevOdobrenMailBody: zahtjevOdobrenMailBody,
            ZahtjevOdobrenMailSubject: zahtjevOdobrenMailSubject,
            PasswordResetURL: passwordResetURL,
            PasswordResetMailBody: passwordResetMailBody,
            PasswordResetMailSubject: passwordResetMailSubject,
            FiskalnaBlagajnaCijena: fiskalnaBlagajnaCijena,
            FiskalnaBlagajnaValutaID: fiskalnaBlagajnaValuta.ValutaID,
            LicencaCijena: licencaCijena,
            LicencaValutaID: licencaValuta.ValutaID,
            UvjetiKoristenja: uvjetiKoristenja,
            ZadatakMailBody: zadatakMailBody,
            ZadatakMailSubject: zadatakMailSubject,
            ZadatakReminderMailBody: zadatakReminderMailBody,
            ZadatakReminderMailSubject: zadatakReminderMailSubject,
            ZadatakFinalReminderMailBody: zadatakFinalReminderMailBody,
            ZadatakFinalReminderMailSubject: zadatakFinalReminderMailSubject,
            EvidencijaUPrenesenoRazdobljeMessage: evidencijaUPrenesenoRazdobljeMessage
        };
        api.post("postavke/save", resource, loadPostavke, (data) => {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        });
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{marginBottom:"20px", display:"flex", alignItems:"center"}}>
                        <SettingsIcon fontSize="medium" style={{marginRight:"15px"}}></SettingsIcon>
                        Postavke sustava
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField type="number" inputProps={{ min: 1 }} InputProps={{endAdornment:(<InputAdornment position="end">dan(a)</InputAdornment>)}} label="Trajanje probnog perioda" value={probniPeriodBrojDana} onChange={e => setProbniPeriodBrojDana(e.target.value)} error={!probniPeriodBrojDana || isNaN(parseInt(probniPeriodBrojDana) || parseInt(probniPeriodBrojDana) <= 0)} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField value={registracijaEmailTo} onChange={e => setRegistracijaEmailTo(e.target.value)} label="Email za registraciju" error={!registracijaEmailTo || !multipleEmailsValid(registracijaEmailTo)} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={fromEmail} onChange={e => setFromEmail(e.target.value)} label="Email za obavijesti (from)" error={!fromEmail || !Util.isValidEmail(fromEmail)} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={fromName} onChange={e => setFromName(e.target.value)} label="Ime za obavijesti (from)" error={!fromName} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3} style={{paddingLeft:"20px"}}>
                    <FormControlLabel control={<Checkbox checked={disableFileLogging} onChange={e => setDisableFileLogging(e.target.checked)} />} label="Onemogući loggiranje u datoteku" />
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={smtpServer} onChange={e => setSmtpServer(e.target.value)} label="SMTP server" error={!smtpServer} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField type="number" inputProps={{ min: 1 }} label="SMTP port" value={smtpPort} onChange={e => setSmtpPort(e.target.value)} error={!smtpPort || isNaN(parseInt(smtpPort) || parseInt(smtpPort) <= 0)} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={smtpUsername || ""} onChange={e => setSmtpUsername(e.target.value)} label="SMTP username" size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField label="SMTP password" value={smtpPassword || ""} onChange={e => setSmtpPassword(e.target.value)} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>  
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            options={tipoviPromjeneCijene}
                            getOptionLabel={(option) => option.Naziv}
                            value={tipPromjeneCijene}
                            autoHighlight
                            onChange={(e,v) => setTipPromjeneCijene(v)}
                            renderInput={(params) => <TextField {...params} label="Promjena cijene stavke specifikacije" error={!tipPromjeneCijene} size="small"/>}>
                        </Autocomplete>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField value={aktivacijaKorisnikaZahtjevEmailTo} onChange={e => setAktivacijaKorisnikaZahtjevEmailTo(e.target.value)} label="Email za aktivaciju korisnika" error={!aktivacijaKorisnikaZahtjevEmailTo || !multipleEmailsValid(aktivacijaKorisnikaZahtjevEmailTo)} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={passwordResetMessage} onChange={e => setPasswordResetMessage(e.target.value)} label="Password reset poruka" error={!passwordResetMessage} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField value={eServiceURL} onChange={e => setEServiceURL(e.target.value)} label="eServiceURL" error={!eServiceURL} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField value={PUPoveznica} onChange={e => setPUPoveznica(e.target.value)} label="PU poveznica" error={!PUPoveznica} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField inputProps={{ min: 1 }} InputProps={{endAdornment:(<InputAdornment position="end">ms</InputAdornment>)}} type="number" value={refiskalizacijaSleepTime} onChange={e => setRefiskalizacijaSleepTime(e.target.value)} label="Refiskalizacija sleep time" error={!refiskalizacijaSleepTime || isNaN(parseInt(refiskalizacijaSleepTime)) || parseInt(refiskalizacijaSleepTime) <= 0} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={iznosFormat} onChange={e => setIznosFormat(e.target.value)} label="Iznos format" error={!iznosFormat} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={iznosSeparator} onChange={e => setIznosSeparator(e.target.value)} label="Iznos separator" error={!iznosSeparator} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField value={izvjestajAktivnostKorisnikaEmailTo} onChange={e => setIzvjestajAktivnostKorisnikaEmailTo(e.target.value)} label="Email za izvještaj o aktivnosti korisnika" error={!izvjestajAktivnostKorisnikaEmailTo || !multipleEmailsValid(izvjestajAktivnostKorisnikaEmailTo)} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField value={errorAlertEmailTo} onChange={e => setErrorAlertEmailTo(e.target.value)} label="Email za dojavu o grešci" error={!errorAlertEmailTo || !multipleEmailsValid(errorAlertEmailTo)} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={URLEncodeLink} type="number" onChange={e => setURLEncodeLink(e.target.value)} label="URL encode link" error={URLEncodeLink == null} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={iznosPutaSto} type="number" onChange={e => setIznosPutaSto(e.target.value)} label="Množenje iznosa sa 100" error={iznosPutaSto == null} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={5} style={{display:"flex", alignItems:"center"}}>
                    <Button style={{height:"100%", width:"100%"}} variant="outlined" onClick={() => setPredlosciPoruka(true)} startIcon={<EmailIcon></EmailIcon>}>Uredi predloške poruka</Button>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField value={aktivacijaURL} onChange={e => setAktivacijaURL(e.target.value)} label="Aktivacija URL" error={!aktivacijaURL} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField value={aktivacijaReturnURL} onChange={e => setAktivacijaReturnURL(e.target.value)} label="Aktivacija return URL" error={!aktivacijaReturnURL} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField value={passwordResetURL} onChange={e => setPasswordResetURL(e.target.value)} label="Password reset URL" error={!passwordResetURL} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={barkodBrojRacuna} type="number" onChange={e => setBarkodBrojRacuna(e.target.value)} label="Barkod broj računa" error={barkodBrojRacuna == null} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={barkodCijena} type="number" onChange={e => setBarkodCijena(e.target.value)} label="Barkod cijena" error={barkodCijena == null} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>  
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            options={valute}
                            getOptionLabel={(option) => option.Kratica}
                            value={valuta}
                            autoHighlight
                            onChange={(e,v) => setValuta(v)}
                            renderInput={(params) => <TextField {...params} label="Valuta" error={!valuta} size="small"/>}>
                        </Autocomplete>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={fiskalnaBlagajnaCijena} type="number" onChange={e => setFiskalnaBlagajnaCijena(e.target.value)} label="Cijena fiskalne blagajne" error={fiskalnaBlagajnaCijena == null} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>  
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            options={valute}
                            getOptionLabel={(option) => option.Kratica}
                            value={fiskalnaBlagajnaValuta}
                            autoHighlight
                            onChange={(e,v) => setFiskalnaBlagajnaValuta(v)}
                            renderInput={(params) => <TextField {...params} label="Fiskalna blagajna valuta" error={!fiskalnaBlagajnaValuta} size="small"/>}>
                        </Autocomplete>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField value={licencaCijena} type="number" onChange={e => setLicencaCijena(e.target.value)} label="Cijena licence" error={licencaCijena == null} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>  
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            options={valute}
                            getOptionLabel={(option) => option.Kratica}
                            value={licencaValuta}
                            autoHighlight
                            onChange={(e,v) => setLicencaValuta(v)}
                            renderInput={(params) => <TextField {...params} label="Licenca valuta" error={!licencaValuta} size="small"/>}>
                        </Autocomplete>
                    </FormControl>
                </Grid>
                <Grid item xs={12} style={{textAlign:"right", marginTop:"10px"}}>
                    <Button style={{marginRight:"5px"}} onClick={loadPostavke} startIcon={<CancelIcon></CancelIcon>} variant="outlined" color="error">Poništi</Button>
                    <Button variant="contained" onClick={save} startIcon={<SaveIcon></SaveIcon>} color="success">Spremi</Button>
                </Grid>
            </Grid>

            <Modal className="modal" open={predlosciPoruka}>
                <Box sx={{...style, minWidth:"80vw"}}>

                    <PredlosciPoruka
                        emailSubject={emailSubject}
                        emailBody={emailBody}
                        emailParametri={emailParametri}
                        handleEmailSubjectChange={handleEmailSubjectChange}
                        handleEmailBodyChange={handleEmailBodyChange}
                        vrstaEmailPoruke={vrstaEmailPoruke}
                        setVrstaEmailPoruke={setVrstaEmailPoruke}
                        vrsteEmailPoruka={vrsteEmailPoruka}
                        vrstePorukaSustava={vrstePorukaSustava}
                        vrstaPorukeSustava={vrstaPorukeSustava}
                        setVrstaPorukeSustava={setVrstaPorukeSustava}
                        porukaSustava={porukaSustava}
                        handlePorukaSustavaChange={handlePorukaSustavaChange}
                        porukeSustavaParametri={porukeSustavaParametri}
                        close={() => setPredlosciPoruka(false)}
                    ></PredlosciPoruka>
                </Box>
            </Modal>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}