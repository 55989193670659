import { Autocomplete, Button, Divider, FormControl, Grid, InputAdornment, List, ListItem, ListItemText, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import SearchIcon from '@mui/icons-material/Search';
import api from "../util/api";
import { Box } from "@mui/system";
import dateFormat from "dateformat";
import { masks } from "dateformat";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";

const pretragaOptions = [
    { Naziv: "Korisničko ime", Vrijednost: 1 },
    { Naziv: "Email", Vrijednost: 2 },
    { Naziv: "Ime i prezime", Vrijednost: 3 },
    { Naziv: "Naziv firme", Vrijednost: 4 },
    { Naziv: "OIB firme", Vrijednost: 5 }
];

export default function KrajProbnogPerioda(props) {
    
    const [loading, setLoading] = useState(false);
    const [tekstPretrage, setTekstPretrage] = useState("");
    const [pretragaOption, setPretragaOption] = useState(pretragaOptions[0]);
    const [message, setMessage] = useState("Pokrenite pretragu tako da unesete traženi izraz u polje \"Pretraga\", odaberete kriterij pretrage te kliknete gumb \"Pretraži\" ili pritisnete tipku \"Enter\".");
    const [korisnici, setKorisnici] = useState([]);
    const [currentKorisnik, setCurrentKorisnik] = useState(null);
    const [verificirajAlert, setVerificirajAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [promijeniDatumVazenjaAlert, setPromijeniDatumVazenjaAlert] = useState(false);
    const [datumVazenja, setDatumVazenja] = useState(new Date());

    function pretraga() {
        setLoading(true);
        let resource = {
            TekstPretrage : tekstPretrage,
            Kriterij : pretragaOption.Vrijednost
        };
        api.post("korisnici/pretraziprobnekorisnike", resource, pretragaSuccess, pretragaFail);
    };
    
    function pretragaSuccess(data) {
        setLoading(false);
        setKorisnici(data);
        setCurrentKorisnik(data.length > 0 ? data[0] : null);
        setMessage(data.length > 0 ? "" : "Nije pronađen niti jedan korisnik u probnom periodu koji odgovara kriterijima pretrage.");
    }

    function pretragaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function verifikacija() {
        setLoading(true);
        api.get("korisnici/verificiraj/" + currentKorisnik.KorisnikID, verifikacijaSuccess, verifikacijaFail);
    }

    function verifikacijaSuccess() {
        setVerificirajAlert(false);
        pretraga();
    }

    function verifikacijaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setVerificirajAlert(false);
        setLoading(false);
    }

    useEffect(() => {
        if (currentKorisnik) {
            setDatumVazenja(currentKorisnik.DatumVazenja);
        }
    }, [currentKorisnik]);

    function promijeniDatumVazenja() {
        var resource = JSON.parse(JSON.stringify(currentKorisnik));
        resource.DatumVazenja = new Date(datumVazenja);
        api.post("korisnik/promijenidatumvazenja", resource, promijeniDatumVazenjaSuccess, promijeniDatumVazenjaFail);
    }

    function promijeniDatumVazenjaSuccess(data) {
        setPromijeniDatumVazenjaAlert(false);
        pretraga();
    }

    function promijeniDatumVazenjaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }
    
    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{marginBottom:"10px", display:"flex", alignItems:"center"}}>
                        <HowToRegIcon fontSize="medium" style={{marginRight:"15px"}}></HowToRegIcon>
                        Verifikacija korisnika
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <FormControl fullWidth>
                        <TextField label="Pretraga" size="small" value={tekstPretrage} onChange={e => setTekstPretrage(e.target.value)} onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    pretraga();
                                }
                            }
                        } />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth >
                        <Autocomplete
                            disablePortal
                            disableClearable
                            options={pretragaOptions}
                            getOptionLabel={(option) => option.Naziv}
                            value={pretragaOption}
                            hiddenLabel={true}
                            onChange={(e,v) => {
                                setPretragaOption(v);
                            }}                           
                            renderInput={(params) => <TextField {...params} label=""  size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" style={{width:"100%"}} startIcon={<SearchIcon></SearchIcon>} disabled={!tekstPretrage} onClick={pretraga}>Pretraži</Button>
                </Grid>
                <Grid item xs={12}>
                    <Divider></Divider>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption" gutterBottom component="div" style={{textAlign:"center"}}>
                        {message}
                    </Typography>
                </Grid>
                {korisnici.length > 0 ? (
                    <Grid item xs={12}>
                        <Box style={{width:"100%"}}>
                            <ListItem className="list-title" key="zahtjevi-title" >
                                <Grid container>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Ime"} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Korisničko ime"} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Email"} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Telefon"} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Firma"} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Datum važenja"} />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                                <List dense>
                                    {korisnici.map((korisnik, index) => {
                                        return <ListItem className="list-item" style={{paddingTop:0, paddingBottom:0, backgroundColor : korisnik == currentKorisnik ? "rgba(25, 118, 210, 0.08)" : "white" }} key={"korisnik" + index} onClick={e => setCurrentKorisnik(korisnik)}>
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: korisnik == currentKorisnik ? 700 : 400 }} primary={korisnik.Ime + " " + korisnik.Prezime} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: korisnik == currentKorisnik ? 700 : 400 }} primary={korisnik.Username} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: korisnik == currentKorisnik ? 700 : 400 }} primary={korisnik.Email} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: korisnik == currentKorisnik ? 700 : 400 }} primary={korisnik.Tel} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: korisnik == currentKorisnik ? 700 : 400 }} primary={korisnik.FirmaNaziv} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: korisnik == currentKorisnik ? 700 : 400 }} primary={korisnik.DatumVazenja ? dateFormat(korisnik.DatumVazenja, "dd.mm.yyyy.") : ""} />
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                    })}
                                </List>
                            </Paper>
                        </Box>
                    </Grid>
                ) : null}
                {korisnici.length > 0 ? (
                    <Grid item xs={12} style={{textAlign:"right"}}>
                        <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<CalendarTodayIcon></CalendarTodayIcon>} onClick={() => setPromijeniDatumVazenjaAlert(true)} disabled={!currentKorisnik}>Promijeni datum važenja</Button>
                        <Button variant="contained" color="success" startIcon={<TaskAltIcon></TaskAltIcon>} onClick={() => setVerificirajAlert(true)} disabled={!currentKorisnik}>Verificiraj</Button>
                    </Grid>
                ) : null}
            </Grid>

            {verificirajAlert ? <AlertDialog title="Verifikacija korisnika" message={"Jeste li sigurni da želite verificirati odabranog korisnika?"} cancelLabel={"Odustani"} confirmLabel={"Verificiraj"} cancelAction={() => setVerificirajAlert(false)} confirmAction={verifikacija}></AlertDialog> : null}
        
            {promijeniDatumVazenjaAlert ? <AlertDialog style={{minWidth:"50vw", minHeight:"50vh"}} title="Promjena datuma važenja" message={
                <div>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker label="Datum važenja" value={datumVazenja} inputFormat="dd.MM.yyyy" onChange={v => setDatumVazenja(v)} renderInput={(params) => <TextField style={{ width: "99%" }} {...params} size="small" error={!datumVazenja || isNaN(new Date(datumVazenja))} />} />
                    </LocalizationProvider>
                </div>
            } cancelLabel={"Odustani"} confirmLabel={"Prihvati"} confirmDisabled={!datumVazenja || isNaN(new Date(datumVazenja))} cancelAction={() => setPromijeniDatumVazenjaAlert(false)} confirmAction={promijeniDatumVazenja}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}