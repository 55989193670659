import { Autocomplete, Button, Checkbox, Divider, FormControl, FormControlLabel, Grid, ListItemText, Menu, MenuItem, Modal, Radio, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState, useCallback, useContext } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import ExitToApp from "@mui/icons-material/ExitToApp";
import PaymentsIcon from '@mui/icons-material/Payments';
import AddEditDelForm from "./addEditDelForm";
import PlacanjeForm from "./placanjeForm";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RasporedPlacanjaForm from "./rasporedPlacanjaForm";
import AlertDialog from "./alertdialog";
import database from "../util/database";
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import ReplayIcon from '@mui/icons-material/Replay';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import dateFormat, { masks } from "dateformat";
import globalContext from "./globalContext";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

const periodOptions = [
    { Naziv: "Unazad 2 mjeseca", Vrijednost: 6},
    { Naziv: "Svi", Vrijednost: 1},
    { Naziv: "Za godinu", Vrijednost: 2},
    { Naziv: "Za mjesec", Vrijednost: 3},
    { Naziv: "Za datum", Vrijednost: 4},
    { Naziv: "Za razdoblje", Vrijednost: 5}
];

function FinancijskaKartica(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [hosted, setHosted] = useState(false);

    const [klijenti, setKlijenti] = useState(props.klijenti || []);
    const klijentID = props.klijentID || 0;
    const close = props.close;

    const [placanja, setPlacanja] = useState([]);
    const [klijent, setKlijent] = useState(klijentID > 0 ? klijenti.find(k => k.KlijentID == klijentID) : null);
    const [racuni, setRacuni] = useState([]);
    const [dokument, setDokument] = useState(null);
    const [formPlacanje, setFormPlacanje] = useState(null);
    const [placanjeFormOpened, setPlacanjeFormOpened] = useState(false);
    const [rasporedPlacanjaFormOpened, setRasporedPlacanjaFormOpened] = useState(false);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [sakrijZatvorene, setSakrijZatvorene] = useState(false);
    const [sakrijStornirane, setSakrijStornirane] = useState(false);
    const [selektiraniKlijentID, setSelektiraniKlijentID] = useState([]);
    const [prisilnoZatvoriAlert, setPrisilnoZatvoriAlert] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const vrijemeOpened = Boolean(anchorEl);
    const [datumOd, setDatumOd] = useState(new Date());
    const [datumDo, setDatumDo] = useState(new Date());
    const [mjesec, setMjesec] = useState(new Date());
    const [datum, setDatum] = useState(new Date());
    const [godina, setGodina] = useState(new Date());
    const [periodOption, setPeriodOption] = useState(periodOptions[0]);
    const [periodLabel, setPeriodLabel] = useState("");
    const [errorMessage, setErrorMessage] = useState("");


    useConstructor(() => {
        if(!props.klijenti || props.klijenti.length == 0) {
            load();
        }
        else {
            //fin kartica je modal, dolazimo iz klijenata
            loadFilter();
            setHosted(true);
        } 
        //loadPlacanja();
    });

    function loadFilter(){
        setLoading(true);
        api.get("korisnik/loadfinkarticafilter", loadFilterSuccess, loadFilterFail);
    }

    function loadFilterSuccess(data) {
        if (data.PeriodType) {
            setPeriodOption(periodOptions.find(po => po.Vrijednost == data.PeriodType))       
        }
        setSakrijZatvorene(data.SakrijZatvorene);
        setSakrijStornirane(data.SakrijStornirane);
        if (klijentID) {
            loadPlacanjaAndRacuni(klijentID, data.PeriodType);
        } else {
            setLoading(false);
        }
    };

    function loadFilterFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    };
    
    function load() {
        //osim klijenata, vade se i podaci za filter
        setLoading(true);
        api.get("klijenti/allforfinkartica/" + database.get("user").firmaID, loadSuccess, loadFail);
    };

    function loadSuccess(data) {
        if (data.PeriodType) {
            setPeriodOption(periodOptions.find(po => po.Vrijednost == data.PeriodType))       
        }
        setKlijenti(data.Klijenti);
        setSakrijZatvorene(data.SakrijZatvorene);
        setSakrijStornirane(data.SakrijStornirane);
        setKlijent(data.SelektiraniKlijent);
        if (data.SelektiraniKlijent) {
            loadPlacanjaAndRacuni(data.SelektiraniKlijent.KlijentID, data.PeriodType);
        } else {
            setLoading(false);
        }
    };

    function loadFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    };

    // useEffect(() => {
    //     if (klijent) {
    //         loadPlacanjaAndRacuni(klijent.KlijentID);
    //     }
    // }, [klijent]);

    function loadPlacanjaAndRacuni(id, periodType) {
        setLoading(true);
        var resourceDatumOd;
        switch (periodType || periodOption.Vrijednost) {
            case 1:
                resourceDatumOd = datumOd;
                setPeriodLabel("(Svi)");
                break;
            case 2:
                resourceDatumOd = godina;
                setPeriodLabel("(Za godinu " + dateFormat(godina, "yyyy.") + ")")
                break;
            case 3:
                resourceDatumOd = mjesec;
                setPeriodLabel("(Za mjesec " + dateFormat(mjesec, "mm.yyyy.") + ")");
                break;
            case 4:
                resourceDatumOd = datum;
                setPeriodLabel("(Za datum " + dateFormat(datum, "dd.mm.yyyy.") + ")");
                break;
            case 5:
                resourceDatumOd = datumOd;
                setPeriodLabel("(Za razdoblje " + dateFormat(datumOd, "dd.mm.yyyy.") + " - " + dateFormat(datumDo, "dd.mm.yyyy.") + ")");
                break;
            case 6:
                setPeriodLabel("(Unazad 2 mjeseca)");
                break;
        }
        let resource = {
            KlijentID : id,
            PeriodType : periodType || periodOption.Vrijednost,
            DatumOd : resourceDatumOd,
            DatumDo : datumDo
        };
        api.post("placanje/getplacanjaandracuniforklijent", resource, (data) => loadPlacanjaAndRacuniSuccess(data, id), loadPlacanjaAndRacuniFail);
    }

    function loadPlacanjaAndRacuniSuccess(data, id) {
        setPlacanja(data.Placanja);
        if (formPlacanje) {
            setFormPlacanje(data.Placanja.find(p => p.PlacanjeID == formPlacanje.PlacanjeID));
        }
        setRacuni(data.Racuni);
        //mirko i slavko

        let svi = (data.Placanja.filter(p => sakrijZatvorene ? p.Otvoreno > 0 : true).concat(data.Racuni.filter(r => (sakrijStornirane ? (!r.Storniran && r.VrstaRacunaID != 7) : true) && (sakrijZatvorene ? Util.getRacunDetalji(r).Ostalo != 0 : true)))).sort((a,b) => new Date(a.Datum) - new Date(b.Datum));

        if(svi && svi.length > 0){
            setDokument(svi[0]);
        }
        
        //ovdje još snimiti ovog klijenta u filtru fin kartice
        //saveFilter(id, sakrijZatvorene, sakrijStornirane);

        setLoading(false);
    }

    function loadPlacanjaAndRacuniFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function add() {
        setFormPlacanje(null);
        setPlacanjeFormOpened(true);
    }

    function edit() {
        setFormPlacanje(dokument);
        setPlacanjeFormOpened(true);
    }

    function rasporedi() {
        setFormPlacanje(dokument);
        setRasporedPlacanjaFormOpened(true);
    }

    function deleteConfirmed() {
        setLoading(true);
        api.get("placanje/deleteplacanje/" + dokument.PlacanjeID, deleteSuccess, deleteFail);
    }

    function deleteSuccess() {
        if(klijent){
            loadPlacanjaAndRacuni(klijent.KlijentID, null);
            setDeleteAlert(false);
        }

    }

    function deleteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function saveFilter(kl, sz, ss){
        let resource = {
            SelektiraniKlijentID : kl,
            SakrijZatvorene : sz,
            SakrijStornirane : ss
        }
        api.post("korisnik/savefinkarticafilter", resource);
    }

    function handleSakrijZatvorene(e){
        if(e){
            setSakrijZatvorene(e.target.checked);
            saveFilter(klijent ? klijent.KlijentID : null, e.target.checked, sakrijStornirane);
        }
    }

    function handleSakrijStornirane(e){
        if(e){
            setSakrijStornirane(e.target.checked);
            saveFilter(klijent ? klijent.KlijentID : null, sakrijZatvorene, e.target.checked);
        }
    }

    const handleKeyPressShorcuts = useCallback((event) => {
        if(!placanjeFormOpened && !rasporedPlacanjaFormOpened){
            if (event.altKey) {
                let charCode = String.fromCharCode(event.which).toLowerCase();
                if (charCode == 'd') {
                    event.preventDefault();
                    if (!loading) {
                        add();
                    }
                }

                if (charCode == 'r') {
                    event.preventDefault();
                    if (!loading && dokument && dokument.RacunID == null) {
                        rasporedi();
                    }
                }

                if (charCode == 'z' && hosted) {
                    event.preventDefault();
                    if (!loading) {
                        close();
                    }
                }
            }    
        }
    }, [
        loading, 
        placanjeFormOpened, 
        rasporedPlacanjaFormOpened, 
        hosted, 
        dokument
    ]);

    const handleEsc = useCallback((event) => {
        if(!placanjeFormOpened && !rasporedPlacanjaFormOpened){
            if(event.keyCode == 27 && hosted){
                event.preventDefault();
                if (!loading) {
                    close();
                }            
            }
        }
    }, [loading, placanjeFormOpened, rasporedPlacanjaFormOpened, hosted]);
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressShorcuts);
        document.addEventListener('keyup', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleKeyPressShorcuts);
            document.removeEventListener('keyup', handleEsc);
        };
    }, [handleKeyPressShorcuts]);

    function prisilnoZatvori() {
        setLoading(true);
        if (dokument.RacunID) {
            api.get("racun/prisilnozatvori/" + dokument.RacunID, prisilnoZatvoriSuccess, prisilnoZatvoriFail);
        } else {
            api.get("placanje/prisilnozatvori/" + dokument.PlacanjeID, prisilnoZatvoriSuccess, prisilnoZatvoriFail);
        }
    }

    function prisilnoZatvoriSuccess() {
        if(klijent){
            loadPlacanjaAndRacuni(klijent.KlijentID, null);
            setPrisilnoZatvoriAlert(false);
        } else {
            setLoading(false);
        }
    }

    function prisilnoZatvoriFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function otvori() {
        setLoading(true);
        if (dokument.RacunID) {
            api.get("racun/otvori/" + dokument.RacunID, otvoriSuccess, otvoriFail);
        } else {
            api.get("placanje/otvori/" + dokument.PlacanjeID, otvoriSuccess, otvoriFail);
        }
    }

    function otvoriSuccess() {
        if(klijent){
            loadPlacanjaAndRacuni(klijent.KlijentID, null);
        } else {
            setLoading(false);
        }
    }

    function otvoriFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleDatumOdChange(v) {
        setDatumOd(v);
    }

    function handleDatumDoChange(v) {
        setDatumDo(v);
    }

    function handleVrijemeClick(event) {
        setAnchorEl(event.currentTarget);
    };

    function handleVrijemeClose() {
        setAnchorEl(null);
    };

    function handlePretraziClick() {
        if (klijent) {
            loadPlacanjaAndRacuni(klijent.KlijentID, null);
        }
    }

    function handleKlijentChange(v) {
        if (v) {
            setKlijent(v);
            saveFilter(v.KlijentID, sakrijZatvorene, sakrijStornirane);
            loadPlacanjaAndRacuni(v.KlijentID, null);
        } else {
            setPlacanja([]);
            setRacuni([]);
            setDokument(null);
            saveFilter(null, sakrijZatvorene, sakrijStornirane);
        }
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            <CreditScoreIcon fontSize="medium" style={{marginRight:"15px"}}></CreditScoreIcon>
                            Financijska kartica
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            options={klijenti}
                            value={klijent}
                            autoHighlight
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            onChange={(e,v) => {handleKlijentChange(v)}}
                            disableClearable
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 ? "black" : "red"}}>
                                    {option.Broj + " - " + option.Naziv}
                                  </li>
                                );
                            }}
                            filterOptions={(options, state) => options.filter(option => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            renderInput={(params) => <TextField {...params} sx={{input:{color: klijent && klijent.TipStatusaKlijentaID != 1 ? "red" : "black"}}} label="Klijent" size="small" style={{width:"100%"}} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        id="basic-button"
                        aria-controls={vrijemeOpened ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={vrijemeOpened ? 'true' : undefined}
                        onClick={handleVrijemeClick}
                        size="medium"
                        style={{marginBottom:"5px", width:"100%"}}
                        variant="outlined"
                        endIcon={vrijemeOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    >
                        Vremensko razdoblje&nbsp;{periodLabel}
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={vrijemeOpened}
                        onClose={handleVrijemeClose}
                        PaperProps={{
                            style: {
                                 width: "30vw",
                                 maxWidth: "30vw"
                               }
                             }}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriodOption(periodOptions.find(po => po.Vrijednost == 6));
                                }}
                            >
                                <Radio checked={periodOption.Vrijednost == 6}></Radio>
                                <ListItemText>{periodOptions.find(po => po.Vrijednost == 6).Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriodOption(periodOptions.find(po => po.Vrijednost == 1));
                                }}
                            >
                                <Radio checked={periodOption.Vrijednost == 1}></Radio>
                                <ListItemText>{periodOptions.find(po => po.Vrijednost == 1).Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriodOption(periodOptions.find(po => po.Vrijednost == 2));
                                }}
                            >
                                <Radio checked={periodOption.Vrijednost == 2}></Radio>
                                <ListItemText>{periodOptions.find(po => po.Vrijednost == 2).Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriodOption(periodOptions.find(po => po.Vrijednost == 3));
                                }}
                            >
                                <Radio checked={periodOption.Vrijednost == 3}></Radio>
                                <ListItemText>{periodOptions.find(po => po.Vrijednost == 3).Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriodOption(periodOptions.find(po => po.Vrijednost == 4));
                                }}
                            >
                                <Radio checked={periodOption.Vrijednost == 4}></Radio>
                                <ListItemText>{periodOptions.find(po => po.Vrijednost == 4).Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriodOption(periodOptions.find(po => po.Vrijednost == 5));
                                }}
                            >
                                <Radio checked={periodOption.Vrijednost == 5}></Radio>
                                <ListItemText>{periodOptions.find(po => po.Vrijednost == 5).Naziv}</ListItemText>
                            </MenuItem>
                            <Divider></Divider>
                        {periodOption.Vrijednost == 2 ? (
                            <MenuItem>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                    <DatePicker
                                        views={['year']}
                                        label="Godina"
                                        value={godina}
                                        inputFormat="yyyy"
                                        onChange={(v) => setGodina(v)}
                                        renderInput={(params) => <TextField {...params} sx={{width:"100%"}} size="small" />}
                                    />
                                </LocalizationProvider>
                            </MenuItem>
                        ) : null}
                        {periodOption.Vrijednost == 3 ? (
                            <MenuItem>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                    <DatePicker
                                        views={['year', 'month']}
                                        label="Mjesec"
                                        value={mjesec}
                                        inputFormat="MM.yyyy"
                                        onChange={(v) => setMjesec(v)}
                                        renderInput={(params) => <TextField {...params} sx={{width:"100%"}} size="small" />}
                                    />
                                </LocalizationProvider>
                            </MenuItem>
                        ) : null}
                        {periodOption.Vrijednost == 4 ? (
                            <MenuItem>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                    <DatePicker
                                        label="Datum"
                                        value={datum}
                                        inputFormat="dd.MM.yyyy"
                                        onChange={(v) => setDatum(v)}
                                        renderInput={(params) => <TextField {...params} sx={{width:"100%"}} size="small" />}
                                    />
                                </LocalizationProvider>
                            </MenuItem>
                        ) : null}
                        {periodOption.Vrijednost == 5 ? (
                            <MenuItem>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                    <DatePicker
                                        label="Od"
                                        value={datumOd}
                                        inputFormat="dd.MM.yyyy"
                                        onChange={handleDatumOdChange}
                                        renderInput={(params) => <TextField {...params} sx={{width:"100%"}} size="small" />}
                                    />
                                </LocalizationProvider>
                            </MenuItem>
                        ) : null}
                        {periodOption.Vrijednost == 5 ? (
                            <MenuItem>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                    <DatePicker
                                        label="Do"
                                        value={datumDo}
                                        inputFormat="dd.MM.yyyy"
                                        onChange={handleDatumDoChange}
                                        renderInput={(params) => <TextField {...params} sx={{width:"100%"}} size="small" />}
                                    />
                                </LocalizationProvider>
                            </MenuItem>
                        ) : null}
                        <Divider />
                        <div style={{ textAlign: "center" }}>
                            <Button variant="outlined" disabled={!klijent} onClick={() => {setAnchorEl(null); handlePretraziClick();}}>
                                <SearchIcon style={{ marginRight: "5px" }} />
                                Pretraži
                            </Button>
                        </div>
                    </Menu>
                </Grid>
                <Grid item xs={12}>
                    <AddEditDelForm
                        //data={(placanja.filter(p => sakrijZatvorene ? p.Otvoreno > 0 : true).concat(racuni.filter(r => (sakrijStornirane ? (!r.Storniran && r.VrstaRacunaID != 7) : true) && (sakrijZatvorene ? r.Otvoreno > 0 : true)))).sort((a,b) => new Date(a.Datum) - new Date(b.Datum))}
                        data={(placanja.filter(p => sakrijZatvorene ? p.Otvoreno > 0 : true).concat(racuni.filter(r => (sakrijStornirane ? (!r.Storniran && r.VrstaRacunaID != 7) : true) && (sakrijZatvorene ? Util.getRacunDetalji(r).Ostalo != 0 : true)))).sort((a,b) => new Date(a.Datum) - new Date(b.Datum))}
                        dataType="placanja"
                        current={dokument}
                        setCurrent={setDokument}
                        add={add}
                        edit={edit}
                        disable
                        del={() => setDeleteAlert(true)}
                        disableDeleteButton={!dokument || !dokument.PlacanjeID || (dokument.RasporediPlacanja && dokument.RasporediPlacanja.length > 0)}
                        disableEdit={!dokument || !dokument.PlacanjeID || (dokument.RasporediPlacanja && dokument.RasporediPlacanja.length > 0)}
                    ></AddEditDelForm>
                </Grid>
                <Grid item xs={5}>
                    <Box style={{display:"flex", justifyContent:"flex-end", marginTop:"5px"}}>
                        {dokument && dokument.PrisilnoZatvoreno ? (
                                <Button 
                                    variant="outlined" 
                                    startIcon={<ReplayIcon></ReplayIcon>} 
                                    style={{marginLeft:"5px"}} 
                                    onClick={otvori}
                                >Otvori</Button>
                        ) : null}
                        <Button 
                            variant="outlined" 
                            startIcon={<LayersClearIcon></LayersClearIcon>} 
                            style={{marginLeft:"5px"}} 
                            disabled={!dokument || (dokument.RacunID ? Util.getRacunDetalji(dokument).Ostalo == 0 : Math.abs(dokument.Otvoreno) == 0)} 
                            onClick={() => setPrisilnoZatvoriAlert(true)}
                        >Prisilno zatvori</Button>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <Box style={{display:"flex", justifyContent:"flex-end", marginTop:"5px"}}>
                        <FormControlLabel
                            labelPlacement="end" 
                            label="Sakrij zatvorene račune"
                            style={{marginLeft:"0px"}}
                            control={<Checkbox size="small" checked={sakrijZatvorene} onChange={e => handleSakrijZatvorene(e)}></Checkbox>}>
                        </FormControlLabel>
                        <FormControlLabel
                            labelPlacement="end" 
                            label="Sakrij stornirane račune"
                            style={{marginLeft:"0px"}}
                            control={<Checkbox size="small" checked={sakrijStornirane} onChange={e => handleSakrijStornirane(e)}></Checkbox>}>
                        </FormControlLabel>
                        <Button 
                            variant="contained" 
                            startIcon={<FormatListBulletedIcon></FormatListBulletedIcon>} 
                            style={{marginLeft:"5px"}} 
                            disabled={!dokument || dokument.RacunID != null} 
                            onClick={rasporedi}
                        >R&#818;asporedi</Button>
                        {hosted ? <Button variant="outlined" color="error" startIcon={<ExitToApp></ExitToApp>} style={{marginLeft:"5px"}} onClick={close}>Z&#818;atvori</Button> : null}
                    </Box>
                </Grid>
            </Grid>

            <Modal open={placanjeFormOpened}>
                <Box sx={style} overflow={"auto"} >
                    <PlacanjeForm loading={loading} setLoading={setLoading} loadPlacanja={loadPlacanjaAndRacuni} klijentID={klijent && klijent.KlijentID} formPlacanje={formPlacanje} close={() => setPlacanjeFormOpened(false)} ></PlacanjeForm>
                </Box>
            </Modal>

            <Modal open={rasporedPlacanjaFormOpened}>
                <Box sx={{...style, minWidth:"95%"}} overflow={"auto"} >
                    <RasporedPlacanjaForm loading={loading} setLoading={setLoading} refresh={() => loadPlacanjaAndRacuni(klijent && klijent.KlijentID)} formPlacanje={formPlacanje} close={() => setRasporedPlacanjaFormOpened(false)} racuni={formPlacanje ? racuni.filter(r => Util.getRacunDetalji(r).Ostalo != 0 && !r.Storniran && r.VrstaRacunaID != 7 && r.FirmaValutaID == formPlacanje.FirmaValutaID && r.TipRacunaID == (formPlacanje.Ulaz > 0 ? 1 : 2)) : []}></RasporedPlacanjaForm>
                </Box>
            </Modal>

            {deleteAlert ? <AlertDialog 
                                title="Brisanje plaćanja"
                                message={"Jeste li sigurni da želite obrisati odabrano plaćanje?"} 
                                cancelLabel={"Odustani"} 
                                confirmLabel={"Obriši"} 
                                cancelAction={() => setDeleteAlert(false)} 
                                confirmAction={deleteConfirmed}>
                            </AlertDialog> : null}

            {prisilnoZatvoriAlert ? <AlertDialog title={"Prisilno zatvaranje"} message={"Odabrali ste prisilno zatvaranje" + (dokument && dokument.RacunID ? " računa" : " plaćanja") + ", odnosno postavljanje njegovog otvorenog iznosa na 0,00. " + (dokument && dokument.RacunID ? "Plaćanja se neće moći raspoređivati na ovaj račun, niti će se moći obrisati postojeći rasporedi povezani s ovim računom, osim ako se račun ponovno ne otvori." : "Plaćanje se neće moći raspoređivati na račune, niti će se moći obrisati njegovi postojeći rasporedi, osim ako se plaćanje ponovno ne otvori.")} 
                cancelLabel={"Odustani"} 
                confirmLabel={"Zatvori"} 
                cancelAction={() => setPrisilnoZatvoriAlert(false)} 
                confirmAction={prisilnoZatvori}>
            </AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default FinancijskaKartica;