import { Alert, AppBar, Autocomplete, BottomNavigation, BottomNavigationAction, Box, Button, Divider, FormControl, FormLabel, Grid, Grow, IconButton, InputAdornment, InputLabel, ListItem, Menu, MenuItem, Modal, TextField, Tooltip, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useContext, useEffect, useRef, useState, useCallback } from "react";
import Util from "../util/util";
import api from "../util/api";
import ComponentContainer from "./componentContainer";
import Spinner from "./spinner";
import LineIcon from "react-lineicons";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SaveIcon from "@mui/icons-material/Save";
import ExitToApp from "@mui/icons-material/ExitToApp";
import globalContext from "./globalContext";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from "@mui/icons-material/History";
import AutocompleteAsync from "../controls/autocompleteasync";
import database from "../util/database";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import MjestoForm from "./mjestoForm";
import ErrorIcon from '@mui/icons-material/Error';

// const tipOsobeOptions = [
//     {TipOsobeID: "1", Naziv:"Pravna"},
//     {TipOsobeID: "2", Naziv:"Fizička"}
// ]

// const tipDrzavnostiOptions = [
//     {TipDrzavnostiID: "1", Naziv:"Domaći"},
//     {TipDrzavnostiID: "2", Naziv:"Strani"}
// ]

// const tipIdentifikacijskogBrojaOptions = [
//     {TipIdentifikacijskogBrojaID: "1", Naziv:"OIB"},
//     {TipIdentifikacijskogBrojaID: "2", Naziv:"JMBG"},
//     {TipIdentifikacijskogBrojaID: "3", Naziv:"Ostalo"}
// ]

// const voditeljOptions = [
//     {KorisnikID:"1", Ime:"Test", Prezime:"Tesst"}
// ]

// const tipStatusaKlijentaOptions = [
//     {TipStatusaKlijentaID:"1", Naziv:"Aktivan"},
//     {TipStatusaKlijentaID:"2", Naziv:"Neaktivan"}
// ]
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

function KlijentForm(props) {
    const useConstructor = Util.useConstructor();
    const setCurrent = props.setCurrent;
    const setEnableScrolling = props.setEnableScrolling;
    const global = useContext(globalContext);

    const isMobileDevice = Util.isMobileDevice();

    const klijenti = props.klijenti;
    const [formKlijent, setFormKlijent] = useState(props.klijent);
    const [firmaID, setFirmaID] = useState(props.firmaID);
    const [loading, setLoading] = useState(true);
    const [adreseHistory, setAdreseHistory] = useState([]);
    const [mjesta, setMjesta] = useState([]);
    const [adresa, setAdresa] = useState("");
    const [mjesto, setMjesto] = useState(null);
    const [grupe, setGrupe] = useState([]);
    const [broj, setBroj] = useState(null);
    const [brojUnique, setBrojUnique] = useState(true);
    const [voditelji, setVoditelji] = useState([]);
    const [naziv, setNaziv] = useState(null);
    const [idBroj, setIdBroj] = useState(null);
    //const [tipIdBrojaId, setTipIdBrojaId] = useState(null);
    const [tipIdentifikacijskogBroja, setTipIdentifikacijskogBroja] = useState(null);
    const [email, setEmail] = useState(null);
    const [telefon, setTelefon] = useState(null);
    const [fax, setFax] = useState(null);
    //const [tipOsobeId, setTipOsobeId] = useState(null);
    const [tipOsobe, setTipOsobe] = useState(null);
    //const [tipDrzavnostiId, setTipDrzavnostiId] = useState(null);
    const [tipDrzavnosti, setTipDrzavnosti] = useState(null);
    const [voditeljId, setVoditeljId] = useState(null);
    const [napomena, setNapomena] = useState(null);
    const [knjigovodstvoReferenca, setKnjigovodstvoReferenca] = useState(null);
    const [grupeIDs, setGrupeIDs] = useState([]);
    //const [statusKlijentaId, setStatusKlijentaId] = useState();
    const [tipStatusaKlijenta, setTipStatusaKlijenta] = useState(null);
    const [voditelj, setVoditelj] = useState(null);
    const [edited, setEdited] = useState(false);
    const [editedTipDrzavnosti, setEditedTipDrzavnosti] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [emailMessage, setEmailMessage] = useState("");
    const [idBrojValid, setIdBrojValid] = useState(true);
    const [idBrojMessage, setIdBrojMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const historyOpened = Boolean(anchorEl);
    const [historyFilter, setHistoryFilter] = useState("");
    const [tipOsobeOptions, setTipOsobeOptions] = useState([]);
    const [tipIdentifikacijskogBrojaOptions, setTipIdentifikacijskogBrojaOptions] = useState([]);
    const [tipDrzavnostiOptions, setTipDrzavnostiOptions] = useState([]);
    const [tipStatusaKlijentaOptions, setTipStatusaKlijentaOptions] = useState([]);
    const [firmaJezici, setFirmaJezici] = useState([]);
    const [firmaJezik, setFirmaJezik] = useState(null);
    const [mjestoApi, setMjestoApi] = useState();
    const [formValid, setFormValid] = useState(false);
    const [placanjeBrojDana, setPlacanjeBrojDana] = useState(0);
    const [tipOporezivosti, setTipOporezivosti] = useState(null);
    const [tipOporezivostiOptions, setTipOporezivostiOptions] = useState([]);
    const [dodajMjesto, setDodajMjesto] = useState(false);
    const [dodajMjestoNaziv, setDodajMjestoNaziv] = useState("");
    const [tipOporezivostiZaStraneKlijente, setTipOporezivostiZaStraneKlijente] = useState(null);

    const setFormOpened = props.setFormOpened;
    const filter = props.filter;

    const mjestoInputRef = useRef();

    useConstructor(() => {
        setEnableScrolling(false);
        if (formKlijent != null) {
            setNaziv(formKlijent.Naziv);
            setBroj(formKlijent.Broj);
            setIdBroj(formKlijent.IdentifikacijskiBroj);
            //setTipIdBrojaId(formKlijent.TipIdentifikacijskogBrojaID);
            setTipIdentifikacijskogBroja(formKlijent.TipIdentifikacijskogBroja);
            setEmail(formKlijent.Email);
            setTelefon(formKlijent.Telefon);
            setFax(formKlijent.Fax);
            //setTipOsobeId(formKlijent.TipOsobeID);
            setTipOsobe(formKlijent.TipOsobe);
            //setTipDrzavnostiId(formKlijent.TipDrzavnostiID);
            setTipDrzavnosti(formKlijent.TipDrzavnosti);
            setVoditeljId(formKlijent.Voditelj_KorisnikID);
            setNapomena(formKlijent.Napomena);
            setKnjigovodstvoReferenca(formKlijent.KnjigovodstvenaReferenca);
            setGrupeIDs(formKlijent.GrupeIDs);
            setAdresa(formKlijent.Adresa.AdresaNaziv);
            setMjesto(formKlijent.Adresa.MjestoResource);
            //setStatusKlijentaId(formKlijent.TipStatusaKlijentaID);
            setTipStatusaKlijenta(formKlijent.TipStatusaKlijenta);
            setFirmaJezik(formKlijent.FirmaJezik);
            setPlacanjeBrojDana(formKlijent.DatumPlacanjaBrojDana);
            setTipOporezivosti(formKlijent.TipOporezivosti);
        }
        loadDetalji();
    });

    function loadDetalji() {
        api.get("klijenti/getformdetalji/" + firmaID, loadDetaljiSuccess, loadDetaljiFail);
    }

    function loadDetaljiSuccess(data) {
        setGrupe(data.Grupe);
        setVoditelji(data.Voditelji);
        setAdreseHistory(data.AdreseHistory);
        setMjesta(data.Mjesta);
        setTipIdentifikacijskogBrojaOptions(data.TipIdentifikacijskogBrojaOptions);
        setTipDrzavnostiOptions(data.TipDrzavnostiOptions);
        setTipOsobeOptions(data.TipOsobeOptions);
        setTipStatusaKlijentaOptions(data.TipStatusaKlijentaOptions);
        setFirmaJezici(data.FirmaJezici);
        setTipOporezivostiOptions(data.TipOporezivostiOptions);
        setTipOporezivostiZaStraneKlijente(data.DefaultTipOporezivostiZaStraneKlijente);
        if (!formKlijent) {
            // addNew
            setTipIdentifikacijskogBroja(data.TipIdentifikacijskogBrojaOptions.at(3));
            setTipDrzavnosti(data.TipDrzavnostiOptions.at(0));
            setTipStatusaKlijenta(data.TipStatusaKlijentaOptions.at(0));
            setTipOsobe(data.TipOsobeOptions.at(0));
            setBroj(data.NextBroj);
            setFirmaJezik(data.DomaciFirmaJezik);
            setPlacanjeBrojDana(data.PlacanjeBrojDana);
            setTipOporezivosti(data.DefaultTipOporezivosti);
        }
        setLoading(false);
        setEnableScrolling(true);
    }

    function loadDetaljiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    const handleTipOsobeChange = (e, v) => {
        if (v) {
            setEdited(true);
            //setTipOsobeId(v.TipOsobeID);
            setTipOsobe(v);
        }
    };

    const handleTipDrzavnostiChange = (e, v) => {
        if (v) {
            //ako se radi o dodavanju stranog klijenta i prvoj promjeni, onda se postavlja tipOporezivosti za strane klijente
            if(!formKlijent && v.TipDrzavnostiID == 2 && !editedTipDrzavnosti){
                setTipOporezivosti(tipOporezivostiZaStraneKlijente);
            }
            setEdited(true);
            setEditedTipDrzavnosti(true);
            //setTipDrzavnostiId(v.TipDrzavnostiID);
            setTipDrzavnosti(v);
        }
    };

    const handleNazivChange = (e) => {
        setEdited(true);
        setNaziv(e.target.value);
    };

    useEffect(() => findVoditelj(), [voditelj, voditelji, voditeljId]);

    const handleIdBrojChange = (e) => {
        setEdited(true);
        setIdBroj(e.target.value);
    };

    const handleBrojChange = (e) => {
        setEdited(true);
        setBroj(e.target.value);
    };

    const validateIdBroj = () => {
        let retrn = false;
        if (tipIdentifikacijskogBroja && tipIdentifikacijskogBroja.TipIdentifikacijskogBrojaID == 1 && idBroj) {
            let valid = Util.OIBCheck(idBroj);
            retrn = valid;
            setIdBrojValid(valid);
            setIdBrojMessage(valid ? "" : "Pogrešan format OIB-a.");
        } else if (tipIdentifikacijskogBroja && tipIdentifikacijskogBroja.TipIdentifikacijskogBrojaID == 2 && idBroj) {
            let valid = Util.isValidJMBG(idBroj);
            retrn = valid;
            setIdBrojValid(valid);
            setIdBrojMessage(valid ? "" : "Pogrešan format JMBG-a.");
        } else if (idBroj || tipIdentifikacijskogBroja && tipIdentifikacijskogBroja.TipIdentifikacijskogBrojaID == 4) {
            retrn = true;
            setIdBrojValid(true);
            setIdBrojMessage("");
        } else {
            retrn = false;
            setIdBrojValid(false);
            setIdBrojMessage("");
        }
        return retrn;
    };

    useEffect(() => validateIdBroj(), [idBroj, tipIdentifikacijskogBroja]);

    const handleTipIdBrojaChange = (e, v) => {
        if (v) {
            setEdited(true);
            //setTipIdBrojaId(v.TipIdentifikacijskogBrojaID);
            setTipIdentifikacijskogBroja(v);
            if(v.TipIdentifikacijskogBrojaID == 4){
                setIdBroj('');
            }
        }
    };

    const handleEmailChange = (e) => {
        setEdited(true);
        setEmail(e.target.value);
    };

    const validateEmail = () => {
        let valid = Util.isValidEmail(email);
        setEmailValid(!email || valid);
        setEmailMessage(!email || valid ? "" : "Pogrešan format e-mail adrese!");
        return !email || valid;
    };

    useEffect(() => validateEmail(), [email]);

    const handleTelefonChange = (e) => {
        setEdited(true);
        setTelefon(e.target.value);
    };

    const handleFaxChange = (e) => {
        setEdited(true);
        setFax(e.target.value);
    };

    const handleVoditeljChange = (e, v) => {
        if (v) {
            setEdited(true);
            setVoditeljId(v.KorisnikID);
        }
    };

    const handleNapomenaChange = (e) => {
        setEdited(true);
        setNapomena(e.target.value);
    };

    const handleKnjigovodstvoReferencaChange = (e) => {
        setEdited(true);
        setKnjigovodstvoReferenca(e.target.value);
    };

    const handleAdresaChange = (e) => {
        setEdited(true);
        setAdresa(e.target.value);
    };

    const handleStatusChange = (e, v) => {
        if (v) {
            setEdited(true);
            //setStatusKlijentaId(v.TipStatusaKlijentaID);
            setTipStatusaKlijenta(v);
        }
    };

    function findVoditelj() {
        if (voditeljId == null || voditelji == null) {
            return;
        } else {
            voditelji.forEach((v) => {
                if (v.KorisnikID == voditeljId) {
                    setVoditelj(v);
                }
            });
        }
    }

    function checkKlijentIdBrojUnique() {
        let unique = true;
        
        if(tipIdentifikacijskogBroja.TipIdentifikacijskogBrojaID != 4){
            klijenti.forEach((klijent) => {
                if (klijent.IdentifikacijskiBroj == idBroj && klijent.TipIdentifikacijskogBrojaID == tipIdentifikacijskogBroja.TipIdentifikacijskogBrojaID && (formKlijent == null || klijent.KlijentID != formKlijent.KlijentID)) {
                    unique = false;
                }
            });    
        }
        else{
            setIdBroj('');
        }
        return unique;
    }

    function checkKlijentNazivUnique() {
        let unique = true;
        klijenti.forEach((klijent) => {
            if (klijent.Naziv == naziv && (formKlijent == null || klijent.KlijentID != formKlijent.KlijentID)) {
                unique = false;
            }
        });
        return unique;
    }


    useEffect(() => checkIfFormValid(), [naziv, tipIdentifikacijskogBroja, broj, idBroj, firmaJezik, tipOsobe, voditeljId, adresa, mjesto, tipStatusaKlijenta, knjigovodstvoReferenca, email, placanjeBrojDana, tipOporezivosti]);

    function checkIfFormValid() {
        let valid = false;
        if (!naziv || (tipIdentifikacijskogBroja && tipIdentifikacijskogBroja.TipIdentifikacijskogBrojaID != 4 && !idBroj) || !tipIdentifikacijskogBroja || !firmaJezik || !tipOsobe || !voditeljId || !adresa || !mjesto || !tipStatusaKlijenta || (global.user.knjigovodstvenaReferencaObvezno.toLowerCase() == "true" && !knjigovodstvoReferenca) || !placanjeBrojDana || isNaN(parseInt(placanjeBrojDana)) || parseInt(placanjeBrojDana) < 0 || !tipOporezivosti) {
            valid = false;
        } else if(broj == null || isNaN(broj) || broj < 1) {
            valid = false;
        } else if (!validateIdBroj()) {
            valid = false;
        } else if (!validateEmail()) {
            valid = false;
        } else if (!checkKlijentNazivUnique()) {
            valid = false;
        } else {
            valid = true;
        }
        setFormValid(valid == true);
    }

    function validateNewKlijent() {
        if (!naziv || (tipIdentifikacijskogBroja && tipIdentifikacijskogBroja.TipIdentifikacijskogBrojaID != 4 && !idBroj) || !tipIdentifikacijskogBroja || !firmaJezik || !tipOsobe || !voditeljId || !adresa || !mjesto || !tipStatusaKlijenta || (global.user.knjigovodstvenaReferencaObvezno.toLowerCase() == "true" && !knjigovodstvoReferenca)) {
            setErrorMessage("Molimo popunite sva obavezna polja (označena zvjezdicom).");
            return false;
        } else if(broj == null || isNaN(broj) || broj < 1){
            setErrorMessage(broj);
            return false;
        } else if (!validateIdBroj()) {
            setErrorMessage(idBrojMessage);
            return false;
        } else if (!validateEmail()) {
            setErrorMessage(emailMessage);
            return false;
        } else if (!checkKlijentNazivUnique()) {
            setErrorMessage("Naziv klijenta mora biti jedinstven!");
            return false;
        } else {
            setErrorMessage("");
            return true;
        }
    }

    function checkIfBrojUnique() {
        let resource = {
            FirmaID: global.user.firmaID,
            CurrentID: formKlijent ? formKlijent.KlijentID : -1,
            Broj: broj,
        };
        api.post(
            "klijenti/checkifbrojunique",
            resource,
            (data) => {setBrojUnique(data)},
            (data) => {
                setBrojUnique(false);
                setErrorMessage(data ? (data.Message || data.toString()) : "");
                setLoading(false);
            }
        );
    }

    const save = () => {
        setLoading(true);
        setEnableScrolling(false);
        if (validateNewKlijent()) {
            let newKlijent = {
                Naziv: naziv,
                Broj: broj,
                FirmaID: firmaID,
                FirmaJezikID: firmaJezik.FirmaJezikID,
                IdentifikacijskiBroj: idBroj,
                TipIdentifikacijskogBrojaID: tipIdentifikacijskogBroja.TipIdentifikacijskogBrojaID,
                Email: email,
                Telefon: telefon,
                Fax: fax,
                TipOsobeID: tipOsobe.TipOsobeID,
                Voditelj_KorisnikID: voditeljId,
                Napomena: napomena,
                KnjigovodstvoReferenca: knjigovodstvoReferenca,
                Adresa: adresa,
                Mjesto: mjesto,
                TipStatusaKlijentaID: tipStatusaKlijenta.TipStatusaKlijentaID,
                TipDrzavnostiID: tipDrzavnosti.TipDrzavnostiID,
                PlacanjeBrojDana : placanjeBrojDana !== null && placanjeBrojDana !== '' ? placanjeBrojDana : null,
                TipOporezivostiID : tipOporezivosti.TipOporezivostiID,
                GrupeIDs : grupeIDs
            };
            if (formKlijent == null) {
                newKlijent = { ...newKlijent, KlijentID: 0 };
                api.post("klijent", newKlijent, saveSuccess, saveFail);
            } else {
                newKlijent = { ...newKlijent, KlijentID: formKlijent.KlijentID };
                api.post("klijent/update", newKlijent, saveSuccess, saveFail);
            }
        } else {
            setLoading(false);
            setEnableScrolling(true);
        }
    };

    function saveSuccess() {
        setLoading(false);
        setEnableScrolling(true);
        setEdited(false);
        filter();
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
        setEnableScrolling(true);
    }

    function handleMjestoChange(e, v) {
        setMjesto(v);
        setEdited(true);
    }

    function onMjestoInputChange(e, value) {
        if (document.activeElement == mjestoInputRef.current) {
            setDodajMjestoNaziv(value);
        }
        setMjestoApi("/mjesta/search/" + value + "/" + database.get("user").firmaID);
    }

    function handleHistoryClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleHistoryClose(event) {
        setAnchorEl(null);
    }

    function handleFirmaJezikChange(e, v) {
        setEdited(true);
        setFirmaJezik(v);
    }

    const handleKeyPressShorcuts = useCallback((event) => {
        if (event.altKey) {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if (charCode == 'p') {
                event.preventDefault();
                if (!loading && formValid && global.roles.includes("18")) {
                    save();
                }
            }
            if (charCode == 'o') {
                event.preventDefault();
                if (!loading) {
                    setFormOpened(false);
                }
            }
            if (charCode == 'm') {
                event.preventDefault();
                if (!loading) {
                    setDodajMjesto(true);
                }
            }           
        }
    }, [
        loading, 
        formValid, 
        naziv, 
        broj, 
        firmaID, 
        firmaJezik, 
        idBroj, 
        tipIdentifikacijskogBroja, 
        email, 
        telefon, 
        fax, 
        tipOsobe, 
        voditeljId, 
        napomena, 
        knjigovodstvoReferenca, 
        adresa, 
        mjesto, 
        tipStatusaKlijenta, 
        tipDrzavnosti,
        placanjeBrojDana,
        tipOporezivosti,
        grupeIDs
    ]);
   
    const handleEsc = useCallback((event) => {
        if(event.keyCode == 27){
            event.preventDefault();
            if (!loading) {
                setFormOpened(false);
            }
        }     
    }, [loading]);
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressShorcuts);
        document.addEventListener('keyup', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleKeyPressShorcuts);
            document.removeEventListener('keyup', handleEsc);
        };
    }, [handleKeyPressShorcuts]);

    function handleTipOporezivostiChange(e, v) {
        if (v) {
            setEdited(true);
            setTipOporezivosti(v);
        }
    }

    function dodajMjestoClick() {
        setDodajMjesto(true);
    }

    function dodajMjestoSuccess(data) {
        handleMjestoChange(null, data);
    }

    return (
        <ComponentContainer loading={loading}>
            <Typography variant="h5" gutterBottom component="div">
                {formKlijent ? (
                    <div>
                        <ManageAccountsIcon fontSize="small" /> {formKlijent.Naziv}
                    </div>
                ) : (
                    <div>
                        <PersonAddIcon fontSize="small" /> Novi klijent
                    </div>
                )}
            </Typography>

            <Grid container className="padding-grid">
                <Grid item xs={4}>
                    <FormControl fullWidth style={{ marginTop: "5px !important" }}>
                        <TextField label="Naziv" value={naziv} inputProps={{ maxLength: 150 }} onChange={handleNazivChange} error={!naziv || !checkKlijentNazivUnique()} helperText={naziv && !checkKlijentNazivUnique() ? "Već postoji klijent s jednakim nazivom!" : ""} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth style={{ marginTop: "5px !important" }}>
                        <TextField label="Broj klijenta" type="number" value={broj || ""} onChange={handleBrojChange} error={!broj || !brojUnique} helperText={broj && !brojUnique ? "Broj klijenta mora biti jedinstven." : ""} onBlur={checkIfBrojUnique} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <Autocomplete disableClearable disablePortal id="tip-id-broja-select" autoHighlight options={tipIdentifikacijskogBrojaOptions} value={tipIdentifikacijskogBroja} getOptionLabel={(option) => option.Naziv} onChange={handleTipIdBrojaChange} renderInput={(params) => <TextField {...params} label="Tip identifikacijskog broja" error={!tipIdentifikacijskogBroja} required size="small" />} />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                        <TextField label="Identifikacijski broj" inputProps={{ maxLength: 200 }} value={idBroj} onChange={handleIdBrojChange} error={!idBrojValid} helperText={idBrojMessage} required={tipIdentifikacijskogBroja && tipIdentifikacijskogBroja.TipIdentifikacijskogBrojaID != 4} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField label="Email" value={email} inputProps={{ maxLength: 250 }} onChange={handleEmailChange} error={!emailValid} helperText={emailMessage} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField label="Telefon" value={telefon} inputProps={{ maxLength:50 }} onChange={handleTelefonChange} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField label="Fax" value={fax} onChange={handleFaxChange} inputProps={{ maxLength: 50 }} size="small"></TextField>
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <Autocomplete disablePortal id="jezik-select" disabled={firmaJezici.length == 0} options={firmaJezici} autoHighlight value={firmaJezik} getOptionLabel={(option) => option.FirmaJezikNaziv} onChange={handleFirmaJezikChange} disableClearable renderInput={(params) => <TextField {...params} label="Jezik" size="small" error={!firmaJezik} required />} />
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <Autocomplete  disableClearable disablePortal id="tip-osobe-select" autoHighlight options={tipOsobeOptions} value={tipOsobe} getOptionLabel={(option) => option.Naziv} onChange={handleTipOsobeChange} renderInput={(params) => <TextField {...params} label="Tip osobe" error={!tipOsobe} required size="small" />} />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <Autocomplete  disableClearable disablePortal id="tip-drzavnosti-select" autoHighlight options={tipDrzavnostiOptions} value={tipDrzavnosti} getOptionLabel={(option) => option.Naziv} onChange={handleTipDrzavnostiChange} renderInput={(params) => <TextField {...params} label="Tip državnosti" error={!tipDrzavnosti} required size="small" />} />
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <Autocomplete  disableClearable disablePortal id="tip-oporezivosti-select" autoHighlight options={tipOporezivostiOptions} value={tipOporezivosti} getOptionLabel={(option) => option.TipPorezaNaziv + " (" + option.Stopa * 100 + " %)"} onChange={handleTipOporezivostiChange} renderInput={(params) => <TextField {...params} label="Tip oporezivosti" error={!tipOporezivosti} required size="small" />} />
                    </FormControl>
                </Grid>

                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <Autocomplete  disableClearable disablePortal id="voditelj-select" options={(voditelji || []).filter(v => v.TipStatusaKorisnikaID == 1)} autoHighlight getOptionLabel={(option) => option.Ime + " " + option.Prezime} value={voditelj} onChange={handleVoditeljChange} renderInput={(params) => <TextField {...params} label="Voditelj" error={!voditeljId} required size="small" />} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Napomena" inputProps={{ maxLength: 1024 }} value={napomena} multiline rows={6} onChange={handleNapomenaChange} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField label="Knjigovodstvena referenca" value={knjigovodstvoReferenca} inputProps={{ maxLength: 250 }} onChange={handleKnjigovodstvoReferencaChange} size="small" required={global.user.knjigovodstvenaReferencaObvezno.toLowerCase() == "true"} error={global.user.knjigovodstvenaReferencaObvezno.toLowerCase() == "true" && !knjigovodstvoReferenca}></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField label="Broj dana za plaćanje" type="number" inputProps={{min:0}} value={placanjeBrojDana} onChange={(e) => {setEdited(true); setPlacanjeBrojDana(e.target.value);}} error={!placanjeBrojDana || isNaN(parseInt(placanjeBrojDana)) || parseInt(placanjeBrojDana) < 0} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Autocomplete 
                            disableClearable 
                            disablePortal 
                            options={tipStatusaKlijentaOptions} 
                            getOptionLabel={(option) => option.Naziv} 
                            renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.TipStatusaKlijentaID} style={{color: option.TipStatusaKlijentaID != 1 ? "red" : "black"}}>
                                    {option.Naziv}
                                </li>
                            );
                            }}                             
                            value={tipStatusaKlijenta} 
                            autoHighlight 
                            onChange={handleStatusChange} 
                            renderInput={(params) => <TextField {...params} label="Status klijenta" sx={{input:{color: tipStatusaKlijenta && tipStatusaKlijenta.TipStatusaKlijentaID != 1 ? "red" : "black"}}} error={!tipStatusaKlijenta} required size="small" />} 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete  
                            multiple
                            id="grupa-select"
                            value={grupe && grupeIDs ? grupe.filter(g => grupeIDs.includes(g.GrupaID)) : []}
                            onChange={(e,v) => {
                                setEdited(true);
                                setGrupeIDs(v.map(g => g.GrupaID));
                            }}
                            options={grupe} 
                            getOptionLabel={(option) => option.Naziv} 
                            autoHighlight
                            renderInput={(params) => <TextField {...params} 
                                                        label="Grupe kojima pripada" 
                                                        size="small" 
                                                    />} 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <TextField label="Adresa" inputProps={{ maxLength: 500 }} value={adresa} multiline onChange={handleAdresaChange} error={!adresa} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                    <IconButton onClick={handleHistoryClick}>
                        <HistoryIcon></HistoryIcon>
                    </IconButton>
                </Grid>
                {/* <Grid item xs={2}>
                    <FormControl fullWidth >
                        <Autocomplete style={{width:"99.4%"}}
                            disablePortal
                            options={mjesta}
                            getOptionLabel={option => option.PostanskiBroj || ""}
                            value={mjesto || null}
                            onChange={handleMjestoChange}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.MjestoID + "pbr"}>
                                    {option.PostanskiBroj || ""}
                                    </li>
                                );
                                }}
                            renderInput={(params) => <TextField {...params} label="Poštanski broj" error={!mjesto} required size="small"/>}
                        />
                    </FormControl>
                </Grid> */}
                <Grid item xs={5}>
                    <FormControl fullWidth>
                        {/* <Autocomplete style={{width:"99.4%"}}
                            disablePortal
                            options={mjesta}
                            autoHighlight
                            getOptionLabel={option => (option.PostanskiBroj ? (option.PostanskiBroj + " ") : "") + (option.Naziv ? (option.Naziv + ", ") : "") + option.DrzavaNaziv}
                            value={mjesto || null}
                            onChange={handleMjestoChange}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.MjestoID}>
                                    {(option.PostanskiBroj ? (option.PostanskiBroj + " ") : "") + (option.Naziv ? (option.Naziv + ", ") : "") + option.DrzavaNaziv}
                                    </li>
                                );
                                }}
                            renderInput={(params) => <TextField {...params} label="Mjesto" error={!mjesto} required size="small"/>}
                        /> */}

                        <AutocompleteAsync
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.MjestoID}>
                                        {(!option.PostanskiBroj ? "" : (option.PostanskiBroj + " ")) + (!option.Naziv? "" : (option.Naziv + ", ")) + option.DrzavaNaziv}
                                    </li>
                                );
                            }}
                            api={mjestoApi}
                            onChange={handleMjestoChange}
                            onInputChange={onMjestoInputChange}
                            size="small"
                            value={mjesto}
                            getOptionLabel={(option) => (!option.PostanskiBroj ? "" : (option.PostanskiBroj + " ")) + (!option.Naziv? "" : (option.Naziv + ", ")) + option.DrzavaNaziv}
                            name="Mjesto"
                            error={!mjesto || typeof(mjesto) != "object"}
                            renderInput={(params) => <TextField {...params} inputRef={mjestoInputRef} id="mjestoNaziv" label="Mjesto" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <IconButton style={{width:"100%"}} onClick={dodajMjestoClick}><AddLocationAltIcon></AddLocationAltIcon></IconButton>
                </Grid>
                {errorMessage != "" ? (
                    <Grid item xs={12}>
                        <Grow in={errorMessage != ""}>
                            <Alert severity="error">{errorMessage}</Alert>
                        </Grow>
                    </Grid>
                ) : null}
            </Grid>

            <Menu anchorEl={anchorEl} open={historyOpened} onClose={handleHistoryClose} PaperProps={{ style: { maxWidth: "50vw" } }}>
                <ListItem key={Util.generateUUID()}>
                    <FormControl fullWidth>
                        <TextField
                            sx={{
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                            }}
                            value={historyFilter}
                            onChange={(e) => setHistoryFilter(e.target.value)}
                            size="small"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon></SearchIcon>
                                    </InputAdornment>
                                ),
                            }}
                            onKeyDown={(e) => e.stopPropagation()}
                        ></TextField>
                    </FormControl>
                </ListItem>
                <Divider></Divider>
                {adreseHistory
                    .filter((a) => a.AdresaNaziv.toLowerCase().includes(historyFilter.toLowerCase()))
                    .map((adresa, index) => {
                        return (
                            <Tooltip title={adresa.AdresaNaziv + ", " + adresa.MjestoResource.PostanskiBroj + " " + adresa.MjestoResource.Naziv} enterDelay={200} enterNextDelay={200}>
                                <MenuItem
                                    onClick={() => {
                                        setAdresa(adresa.AdresaNaziv);
                                        setMjesto(adresa.MjestoResource);
                                    }}
                                    key={index}
                                >
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>{adresa.AdresaNaziv + ", " + adresa.MjestoResource.PostanskiBroj + " " + adresa.MjestoResource.Naziv}</div>
                                </MenuItem>
                            </Tooltip>
                        );
                    })}
            </Menu>

            {/* <Divider style={{width:'100%', marginTop:"5px"}} sx={{ borderBottomWidth: 3 }}></Divider> */}

            <Modal open={dodajMjesto}>
                <Box sx={style} overflow={"auto"} >
                    <MjestoForm mjestoNaziv={dodajMjestoNaziv} povratakNaKlijentForm={dodajMjestoSuccess} setFormOpened={setDodajMjesto}></MjestoForm>
                </Box>
            </Modal>

            <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px">
                <Button onClick={save} variant="contained" style={{ marginRight: "10px" }} color="success" disabled={!edited || !global.roles.includes("18") || loading || !formValid}>
                    {loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{ marginRight: "10px" }} /> : <SaveIcon style={{ marginRight: "10px" }} />} Sp&#818;remi
                </Button>
                <Button variant="outlined" color="error" onClick={() => setFormOpened(false)} disabled={loading}>
                    <ExitToApp style={{ marginRight: "10px" }}></ExitToApp>O&#818;dustani
                </Button>
            </Box>
        </ComponentContainer>
    );
}

export default KlijentForm;
