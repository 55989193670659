import { Autocomplete, Box, Button, FormControl, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, Paper, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ComponentContainer from "./componentContainer";
import AssignmentIcon from '@mui/icons-material/Assignment';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import Util from "../util/util";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';
import api from "../util/api";
import globalContext from "./globalContext";
import dateFormat, { masks } from "dateformat";
import SaveIcon from "@mui/icons-material/Save";
import ExitToApp from "@mui/icons-material/ExitToApp";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function ZadatakEvidencija(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [zadaci, setZadaci] = useState([]);
    const [povezani, setPovezani] = useState(JSON.parse(JSON.stringify(props.povezaniZadaci)));
    const [errorMessage, setErrorMessage] = useState("");
    const [currentZadatak, setCurrentZadatak] = useState(null);
    const [statusi, setStatusi] = useState([]);
    const [zadatakZavrsenAlert, setZadatakZavrsenAlert] = useState(false);
    const [valid, setValid] = useState(false);

    const korisnikID = global.drugiZaposlenik.KorisnikID;
    const pocetak = new Date();
    const kraj = new Date();
    const setPovezaniZadaci = props.setPovezaniZadaci;
    const close = props.close;
    const predmetiIDs = props.predmetiIDs;
    const klijentiIDs = props.klijentiIDs;

    useConstructor(() => {
        let a = JSON.parse(JSON.stringify(props.povezaniZadaci));
        let resource = {
            KorisnikID: korisnikID,
            Pocetak: pocetak,
            Kraj: kraj,
            PredmetiIDs: predmetiIDs,
            ZadatakZaEvidencijuID: props.zadatakZaEvidencijuID
        };
        api.post("zadatak/getinfoforevidencija", resource, loadSuccess, fail);
    });

    function loadSuccess(data) {
        setZadaci(data.Zadaci);
        let nepovezani = data.Zadaci.filter(z => props.povezaniZadaci.find(p => p.ZadatakID == z.ZadatakID) == null);
        if (nepovezani.length > 0) {
            setCurrentZadatak(nepovezani[0]);
        }
        setStatusi(data.Statusi);
        setLoading(false);
    }

    function fail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function povezi() {
        let statusID;
        let postotak;
        switch (currentZadatak.TipZadatakVrstaID) {
            case 1:
                statusID = 3;
                break;
            case 2:
                if (currentZadatak.TipZadatakStatusID == 1) {
                    statusID = 2;
                } else {
                    statusID = currentZadatak.TipZadatakStatusID;
                }
                postotak = null;
                break;
            case 3:
                if (currentZadatak.TipZadatakStatusID == 1) {
                    statusID = 2;
                } else {
                    statusID = currentZadatak.TipZadatakStatusID;
                }
                postotak = currentZadatak.PostotakZavrsenosti;
                break;
        }
        let newVeza = {
            ZadatakID: currentZadatak.ZadatakID,
            TipZadatakVrstaID: currentZadatak.TipZadatakVrstaID,
            TipZadatakStatusID: statusID,
            PostotakZavrsenosti: postotak,
            PredmetID: currentZadatak.PredmetID,
            KlijentID: currentZadatak.KlijentID
        }
        setPovezani([...povezani, newVeza]);
    }

    function odvezi() {
        let fejkZadaci = zadaci;
        setPovezani(povezani.filter(z => z.ZadatakID != currentZadatak.ZadatakID));
    }

    function handleStatusChange(zadatakID, status) {
        let newPovezani = JSON.parse(JSON.stringify(povezani));
        let zadatak = newPovezani.find(z => z.ZadatakID == zadatakID);
        zadatak.TipZadatakStatusID = status;
        if (zadatak.TipZadatakVrstaID == 3) {
            if (status == 1) {
                zadatak.PostotakZavrsenosti = 0;
            } else if (status == 3) {
                zadatak.PostotakZavrsenosti = 100;
            }
        }
        setPovezani(newPovezani);
    }

    function handlePostotakChange(zadatakID, postotak) {
        let newPovezani = JSON.parse(JSON.stringify(povezani));
        let zadatak = newPovezani.find(z => z.ZadatakID == zadatakID);
        zadatak.PostotakZavrsenosti = postotak;
        setPovezani(newPovezani);
    }

    function save() {
        setPovezaniZadaci(povezani);
        close();
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div">
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%'
                        }}>
                            <AssignmentIcon fontSize="medium" style={{marginRight:"15px"}}></AssignmentIcon>
                            Povezivanje evidencije sa zadacima
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12} marginTop="20px">
                    <Typography variant="h6" gutterBottom component="div">
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            Dostupni zadaci
                            <Tooltip enterDelay={200} enterNextDelay={200} title="Ovdje se prikazuju aktivni zadaci (nisu započeti ili su u toku) koji nemaju definiranog klijenta, predmet, početak i kraj te oni koji imaju navedene parametre, koji odgovaraju definiranim parametrima evidencije (vrijeme i dodani predmeti).">
                                <span style={{marginLeft:"10px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <HelpOutlineIcon fontSize="small"></HelpOutlineIcon>
                                </span>
                            </Tooltip>
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="dostupni-title" style={{backgroundColor:"lightblue", height:"2em"}}>
                            <Grid container spacing={1}>
                                <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Zadavatelj" />
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    <ListItemText style={{paddingLeft:"5px"}} primaryTypographyProps={{ noWrap:true }} primary="Opis" />
                                </Grid>
                                <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Klijent" />
                                </Grid>
                                <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Početak" />
                                </Grid>
                                <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Kraj" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {zadaci.filter(z => z.TipZadatakStatusID != 3 && z.TipZadatakStatusID != 4 && povezani.find(p => p.ZadatakID == z.ZadatakID) == null && !((z.PredmetID && !predmetiIDs.includes(z.PredmetID)) || (z.KlijentID && !klijentiIDs.includes(z.KlijentID)))).map((zadatak, index) => {
                                    return <ListItem className="list-item" key={"zad" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: zadatak == currentZadatak ? 'rgba(25, 118, 210, 0.08)' : 'white'}}  onClick={() => setCurrentZadatak(zadatak)}>
                                            <Grid container>
                                                <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == currentZadatak ? 700 : 400 }} primary={zadatak.ZadavateljIme} />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4}>
                                                    <ListItemText style={{paddingLeft:"5px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == currentZadatak ? 700 : 400 }} primary={zadatak.Opis} />
                                                </Grid>
                                                <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == currentZadatak ? 700 : 400 }} primary={zadatak.KlijentNaziv} />
                                                </Grid>
                                                <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == currentZadatak ? 700 : 400 }} primary={zadatak.Pocetak ? dateFormat(zadatak.Pocetak, "dd.mm.yyyy HH:MM") : ""} />
                                                </Grid>
                                                <Grid item xs={2} sx={{display: { xs: "none", sm: "none", md:"block"}}}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == currentZadatak ? 700 : 400 }} primary={zadatak.Kraj ? dateFormat(zadatak.Kraj, "dd.mm.yyyy HH:MM") : ""} />
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="space-evenly" alignItems="center">
                    <Tooltip title="Poveži s evidencijom" enterDelay={500} enterNextDelay={500}>
                        <span>
                        <IconButton style={{backgroundColor:"lightblue"}} onClick={povezi} disabled={!currentZadatak || (povezani.find(p => p.ZadatakID == currentZadatak.ZadatakID) != null)}><SouthIcon></SouthIcon></IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Odveži od evidencije" enterDelay={500} enterNextDelay={500}>
                        <span>
                            <IconButton style={{backgroundColor:"lightblue"}} onClick={() => {
                                if (currentZadatak.TipZadatakStatusID == 3) {
                                    setZadatakZavrsenAlert(true);
                                } else {
                                    odvezi();
                                }
                            }} disabled={!currentZadatak || (povezani.find(p => p.ZadatakID == currentZadatak.ZadatakID) == null)}><NorthIcon></NorthIcon></IconButton>
                        </span>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom component="div">
                        Povezani zadaci
                    </Typography>
                </Grid>
                {statusi.length > 0 ? (
                     <Grid item xs={12}>
                        <Box style={{width:"100%"}}>
                            <ListItem className="list-title" key="povezani-title" style={{backgroundColor:"lightblue", height:"2em"}}>
                                <Grid container spacing={1}>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"5px", paddingRight:"15px"}} primaryTypographyProps={{ noWrap:true}} primary="Zadavatelj" />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <ListItemText style={{paddingLeft:"5px", paddingRight:"15px"}} primaryTypographyProps={{ noWrap:true}} primary="Opis" />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <ListItemText style={{textAlign:"center"}} primaryTypographyProps={{ noWrap:true}} primary="Status" />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText style={{textAlign:"center"}} primaryTypographyProps={{ noWrap:true}} primary="Postotak" />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                                <List dense>
                                    {zadaci.filter(z => povezani.find(p => p.ZadatakID == z.ZadatakID) != null).map((zadatak, index) => {
                                        return <ListItem className="list-item" key={"zad" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: zadatak == currentZadatak ? 'rgba(25, 118, 210, 0.08)' : 'white'}}  onClick={() => setCurrentZadatak(zadatak)}>
                                                <Grid container spacing={1} display="flex" alignItems="center">
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"5px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == currentZadatak ? 700 : 400 }} primary={zadatak.ZadavateljIme} />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <ListItemText style={{paddingLeft:"5px"}} primaryTypographyProps={{ noWrap:true, fontWeight: zadatak == currentZadatak ? 700 : 400 }} primary={zadatak.Opis} />
                                                    </Grid>
                                                    <Grid item xs={3} style={{marginTop:"1px", marginBottom:"1px"}}>                                                    
                                                        <FormControl fullWidth>
                                                            <Autocomplete
                                                                disableClearable
                                                                options={statusi.filter(s => (zadatak.TipZadatakVrstaID != 1 || s.TipZadatakStatusID != 2) && s.TipZadatakStatusID != 4)}
                                                                getOptionLabel={(option) => option.Naziv}
                                                                value={statusi.find(s => s.TipZadatakStatusID == povezani.find(p => p.ZadatakID == zadatak.ZadatakID).TipZadatakStatusID)}
                                                                hiddenLabel={true}
                                                                onChange={(e,v) => {
                                                                    handleStatusChange(zadatak.ZadatakID, v.TipZadatakStatusID);
                                                                }}
                                                                renderInput={(params) => <TextField {...params} label="" size="small"/>}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {zadatak.TipZadatakVrstaID == 3 ? (
                                                            <FormControl fullWidth>
                                                                <TextField label="" value={povezani.find(p => p.ZadatakID == zadatak.ZadatakID).PostotakZavrsenosti} size="small" type="number" disabled={zadatak.TipZadatakVrstaID != 3 || povezani.find(z => z.ZadatakID == zadatak.ZadatakID).TipZadatakStatusID == 1} InputProps={{endAdornment:(<InputAdornment position="end">%</InputAdornment>), inputProps: { min: 0, max : 100 }}} onChange={e => handlePostotakChange(zadatak.ZadatakID, e.target.value)} error={(() => {
                                                                    let veza = povezani.find(p => p.ZadatakID == zadatak.ZadatakID);
                                                                    let postotak = veza.PostotakZavrsenosti;
                                                                    return veza.TipZadatakVrstaID == 3 && (postotak == null || isNaN(Number(postotak)) || !Number.isInteger(Number(postotak)) || Number(postotak) < 0 || Number(postotak) > 100);                                                        
                                                                })()}/>
                                                            </FormControl>                                                        
                                                        ) : (
                                                            <Tooltip title="Za ovu vrstu zadatka nije moguće upisati postotak završenosti." enterDelay={200} enterNextDelay={200}>
                                                                <div style={{textAlign:"center"}}>/</div>
                                                            </Tooltip>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                    })}
                                </List>
                            </Paper>
                        </Box>
                    </Grid>
                ) : null}
                <Grid item xs={12} style={{textAlign:"right", marginTop:"20px"}}>
                    <Button onClick={save} startIcon={<SaveIcon></SaveIcon>} disabled={povezani.filter(z => z.TipZadatakVrstaID == 3 && (z.PostotakZavrsenosti == null || z.PostotakZavrsenosti === "" || !Number.isInteger(Number(z.PostotakZavrsenosti)) || Number(z.PostotakZavrsenosti) < 0 || Number(z.PostotakZavrsenosti) > 100)).length > 0} variant="contained" style={{ marginRight: "10px" }} color="success">
                        Spremi
                    </Button>
                    <Button variant="outlined" startIcon={<ExitToApp></ExitToApp>} color="error" onClick={close} >
                        Odustani
                    </Button>
                </Grid>
            </Grid>

            {zadatakZavrsenAlert ? <AlertDialog 
                title={"Upozorenje"} 
                message={"Odabrani zadatak označen je kao \"Završen\". Takav zadatak nećete moći ponovno povezati s evidencijom, osim ako mu ne promijenite status u formi za uređivanje zadataka. Jeste li sigurni da ga želite odvezati bez promjene statusa?"} 
                cancelLabel={"Odustani"} 
                confirmLabel={"Odveži zadatak bez promjene statusa"} 
                cancelAction={() => setZadatakZavrsenAlert(false)} 
                confirmAction={() => {
                    setZadatakZavrsenAlert(false);
                    odvezi();
                }}>
            </AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
        </ComponentContainer>
    );
}