import { Box, Button, Grid, List, ListItem, ListItemText, Modal, Paper, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ComponentContainer from "./componentContainer";
import TimelineIcon from '@mui/icons-material/Timeline';
import Util from "../util/util";
import globalContext from "./globalContext";
import api from "../util/api";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import TecajForm from "./tecajForm";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

function Tecaj(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [tecajevi, setTecajevi] = useState([]);
    const [customTecaj, setCustomTecaj] = useState(false);
    const [currentTecaj, setCurrentTecaj] = useState(null);
    const [customTecajOpis, setCustomTecajOpis] = useState('');
    const [datum, setDatum] = useState(new Date());
    const [formTecaj, setFormTecaj] = useState(null);
    const [tecajFormOpened, setTecajFormOpened] = useState(false);
    const [firmaValute, setFirmaValute] = useState([]);
    const [primarnaValutaID, setPrimarnaValutaID] = useState(0);
    const [primarnaValutaKratica, setPrimarnaValutaKratica] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        let resource = {
            FirmaID : global.user.firmaID,
            Datum : datum
        };
        api.post("tecaj/getinfo", resource, loadInfoSuccess, loadInfoFail);
    });

    function loadInfoSuccess(data) {
        setFirmaValute(data.FirmaValute);
        setCustomTecaj(data.CustomTecaj);
        setCustomTecajOpis(data.CustomTecajOpis);
        setPrimarnaValutaID(data.PrimarnaValutaID);
        setPrimarnaValutaKratica(data.PrimarnaValutaKratica);
        loadTecajevi();
    }

    function loadInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function loadTecajevi() {
        setLoading(true);
        let resource = {
            FirmaID : global.user.firmaID,
            Datum : datum
        };
        api.post("tecaj/getforfirma", resource, getTecajeviSuccess, getTecajeviFail);
    }

    function getTecajeviSuccess(data) {
        setTecajevi(data);
        setLoading(false);
    }

    function getTecajeviFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        if (datum && !isNaN(new Date(datum))) {
            loadTecajevi();
        }
    }, [datum]);

    function edit() {
        setFormTecaj(currentTecaj);
        setTecajFormOpened(true);
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Typography variant="h4" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <TimelineIcon fontSize="medium" style={{marginRight:"15px"}}></TimelineIcon>
                        {'Tečajna lista (' + customTecajOpis + ')'}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker
                            label="Datum"
                            value={datum}
                            inputFormat="dd.MM.yyyy"
                            maxDate={new Date()}
                            onChange={v => setDatum(v)}
                            renderInput={(params) => <TextField style={{width:"99%"}} {...params} size="small" error={isNaN(new Date(datum))} helperText={!isNaN(new Date(datum)) ? "" : "Pogrešan format datuma!"}/>}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{width:"100%"}}>
                        <ListItem className="list-title" key="tecajevi-title" style={{backgroundColor:"lightblue"}}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Valuta" ></ListItemText>
                                </Grid>
                                <Grid item xs={3}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary="Kratica" ></ListItemText>
                                </Grid>
                                <Grid item xs={3}>
                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={'Tečaj (za 1 ' + primarnaValutaKratica + ')'} ></ListItemText>
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                            <List dense>
                                {tecajevi.map((tecaj, index) => {
                                    return <ListItem className="list-item" key={"tecaj" + index} style={{paddingTop:0, paddingBottom:0, backgroundColor: tecaj == currentTecaj ? 'rgba(25, 118, 210, 0.08)' : 'white', color: tecaj.TecajID > 0 ? "black" : "red"}}  onClick={() => setCurrentTecaj(tecaj)} onDoubleClick={customTecaj ? edit : null}>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: tecaj == currentTecaj ? 700 : 400 }} primary={tecaj.FirmaValuta.ValutaNaziv} ></ListItemText>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: tecaj == currentTecaj ? 700 : 400 }} primary={tecaj.FirmaValuta.FirmaValutaNaziv} ></ListItemText>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: tecaj == currentTecaj ? 700 : 400 }} primary={tecaj.TecajID > 0 ? tecaj.Tecaj : "-"} ></ListItemText>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                })}
                            </List>
                        </Paper>
                    </Box>
                </Grid>
                {customTecaj ? (
                    <Grid item xs={12}>
                        <Box style={{display:"flex", justifyContent:"flex-end", marginTop:"5px"}}>
                            <Button variant="contained" disabled={!currentTecaj} style={{marginLeft:"5px"}} startIcon={<EditIcon></EditIcon>} onClick={edit}>Uredi</Button>
                        </Box>
                    </Grid>
                ) : null}
            </Grid>

            <Modal open={tecajFormOpened}>
                <Box sx={style} overflow={"auto"} >
                    <TecajForm label={'Tečaj (za 1 ' + primarnaValutaKratica + ')'} formTecaj={formTecaj} datum={datum} close={() => setTecajFormOpened(false)} firmaValute={firmaValute.filter(fv => !tecajevi.map(t => t.FirmaValutaID).includes(fv.FirmaValutaID))} loadTecajevi={loadTecajevi} ></TecajForm>
                </Box>
            </Modal>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default Tecaj;