import React, { useState, useEffect, useContext, useRef, useCallback } from "react";
import { Layout, Menu, Dropdown, Select } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, UserOutlined, LineChartOutlined, FormOutlined, InfoCircleFilled, LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, Switch, Route, Redirect, HashRouter, useLocation } from "react-router-dom";
import { Grid, Link } from "@mui/material";
import About from "./about";
import Home from "./home";
import SampleForm from "./sampleForm";
import Calendar from "./calendar";
import UnosAktivnostiForm from "./unosAktivnostiForm";
import Login from "./login";
import database from "../util/database";
import MainMenu from "./mainmenu";
import MainMenuMobile from "./mainmenumobile";
import NotFound from "./notfound";
import Util from "../util/util";
import GlobalContext from "../components/globalContext";
import Klijenti from "./klijenti";
import PasswordChange from "./passwordchange";
import logo from "../images/Untitled-2.png";
import Predmeti from "./predmeti";
import Test from "./test";
import Evidencije from "./evidencije";
import Arhive from "./arhive";
import PrijenosAktivnosti from "./prijenosAktivnosti";
import Konto from "./konto";
import Odjel from "./odjel";
import Korisnik from "./korisnik";
import Mjesto from "./mjesto";
import TipKorisnika from "./tipKorisnika";
import Grupa from "./grupa";
import Racuni from "./racuni";
import NacinPlacanja from "./nacinPlacanja";
import FakturiranjeForm from "./fakturiranjeForm";
import UnosTroskaForm from "./unosTroskaForm";
import api from "../util/api";
import { Option } from "antd/lib/mentions";
import { Autocomplete, FormControl, TextField, Tooltip } from "@mui/material";
import NepredmetneKategorije from "./nepredmetneKategorije";
import IspisRacunaISpecifikacijaForm from "./ispisRacunaISpecifikacijaForm";
import PoslovniProstori from "./poslovniProstori";
import SatniceRada from "./satniceRada";
import Tecaj from "./tecaj";
import PlacanjeForm from "./placanjeForm";
import Izvjestaji from "./izvjestaji";
import ErrorAlert from "./errorAlert";
import Postavke from "./postavke";
import FinancijskaKartica from "./financijskaKartica";
import Registracija from "./registracija";
import RegistracijaConfirm from "./registracijaConfirm";
import UvjetiKoristenja from "./uvjetiKoristenja";
import Upute from "./upute";
import PretragaEvidencije from "./pretragaEvidencije";
import Spinner from "./spinner";
import ReportRjecnik from "./reportRjecnik";
import Zahtjevi from "./zahtjevi";
import Aktivnost from "./aktivnost";
import AlertDialog from "./alertdialog";
import KrajProbnogPerioda from "./krajProbnogPerioda";
import PostavkeSustava from "./postavkeSustava";
import RegistracijaInterno from "./registracijaInterno";
import ErrorIcon from '@mui/icons-material/Error';
import Fiskalizacija from "./fiskalizacija";
import DodatneNaplate from "./dodatneNaplate";
import Licence from "./licence";
import Zadaci from "./zadaci";
import Blagdani from "./blagdani";
import Sudovi from "./sudovi";

const { Header, Sider, Content, Footer } = Layout;

const { SubMenu } = Menu;

function Navigation() {
    const useConstructor = Util.useConstructor();
    const [collapsed, setCollapsed] = useState(false);
    const [isMobileDevice, setIsMobileDevice] = useState(Util.isMobileDevice());
    const [zahtjevOdobrenTrigger, setZahtjevOdobrenTrigger] = useState(null);
    const [roles, setRoles] = useState(database.get("roles") || []);
    const global = useContext(GlobalContext);

    const predmetiFilter = useRef(null);
    const predmetiDataGridFilterModel = useRef(null);
    const predmetiDataGridSortModel = useRef(null);
    const klijentiFilter = useRef(null);
    const klijentiDataGridFilterModel = React.useRef(null);
    const klijentiDataGridSortModel = useRef(null);
    const zahtjeviFilter = useRef(null);

    const [klijentiPredmeti, setKlijentiPredmeti] = useState({
        Klijenti: [],
        Predmeti: [],
        KlijentiLastUpdate: "",
        PredmetiLastUpdate: "",
    });
    const [errorMessage, setErrorMessage] = useState(null);

    const location = useLocation();

    useConstructor(() => {
        // if (global.selectedTab != "login") global.setSelectedTab("login");

        let u = database.get("user");
        let theme = database.get("theme");

        if (!theme) {
            global.setTheme("dark");
        } else {
            global.setTheme(theme);
        }

        if (u) {
            if (!global.selectedTab) {
                global.setSelectedTab("home");
            }

            let expires = u[".expires"];
            if (new Date(expires) <= new Date()) {
                Util.logout();
            }

            if (!global.logoFile) {
                api.get("firma/getlogo?firmaID=" + database.get("user").firmaID, (res) => {
                    global.setLogoFile(res);
                });
            }
            
            global.setLogoPosition(u.logoPosition ? u.logoPosition : 1);

            global.setUser(u);
            global.setUsername(u.username);

            let zaposlenici = database.get("zaposlenici");
            global.setZaposlenici(zaposlenici);

            // let klijenti = database.get("klijenti");
            // global.setKlijenti(klijenti);

            // let predmeti = database.get("predmeti");
            // global.setPredmeti(predmeti);

            if (zaposlenici) {
                zaposlenici.forEach((z) => {
                    if (z.KorisnikID == u.korisnikID) {
                        global.setDrugiZaposlenik(z);
                    }
                });
            }

            // refreshanje rolesa pri ulazu u aplikaciju
            api.get("korisnik/prava/" + u.korisnikID, (res) => {
                global.setRoles(res);
                database.set("roles", roles)
                loadKlijentiAndPredmeti(u.firmaID, null, true, true);
            }, () => {
                global.setRoles(roles);
                loadKlijentiAndPredmeti(u.firmaID, null, true, true);
            });
            
        } else {
            global.setSelectedTab("login");
            //window.location = "/#/login";       // maknuto zbog toga �to bi aktivacijski link vodio na /login stranicu
        }
    });

    function loadKlijentiAndPredmeti(firmaID, success, loadKlijenti, loadPredmeti) {
        global.setLoading(true);
        api.get("firma/getklijentiandpredmeti?firmaID=" + firmaID + "&loadKlijenti=" + loadKlijenti + "&loadPredmeti=" + loadPredmeti, (data) => loadKlijentiAndPredmetiSuccess(data, success), loadKlijentiAndPredmetiFail);
    }

    function loadKlijentiAndPredmetiSuccess(data, success) {
        let klijenti = JSON.parse(JSON.stringify(klijentiPredmeti.Klijenti));
        let predmeti = JSON.parse(JSON.stringify(klijentiPredmeti.Predmeti));
        if (data.KlijentiUpdated) {
            klijenti = data.Klijenti;
        }
        if (data.PredmetiUpdated) {
            predmeti = data.Predmeti;
        }
        setKlijentiPredmeti({
            Klijenti: klijenti,
            Predmeti: predmeti,
            KlijentiLastUpdate: data.KlijentiLastUpdate,
            PredmetiLastUpdate: data.PredmetiLastUpdate,
        });
        global.setLoading(false);
        if (success) {
            success(data.Klijenti);
        }
    }

    function loadKlijentiAndPredmetiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        global.setLoading(false);
    }

    function loadZaposleniciFail() {}

    function toggle() {
        Util.setCookie("navcollapsed", !collapsed);
        database.set("navcollapsed", !collapsed);
        setCollapsed(!collapsed);
        global.setNavCollapsed(!collapsed);
    }

    function login() {}

    function onLoginSuccess(user) {
        // var roles = user.roles.split(",");
        // setUser(user);
        // setUsername(user.username);
        // setRoles(roles);
        // window.location = "/#";
        //window.location.reload();
        global.setSelectedTab("home");
        loadKlijentiAndPredmeti(user.firmaID, () => {
            if (window.location.href.includes("/login")) {
                window.location.href = "/#/home";
                // if (user.probniPeriod == "True") {
                //     setProbniPeriodAlert(true);
                // }
            }
        }, true, true);
    }

    useEffect(() => {
        if (global.user && global.zaposlenici) {
            let korisnikID = global.user.korisnikID;
            if (!roles.includes("2") && roles.includes("3")) {
                global.setDostupniZaposlenici([...global.zaposlenici].filter((z) => z.KorisnikID != global.user.korisnikID));
            } else {
                global.setDostupniZaposlenici([...global.zaposlenici]);
            }

            if (!isMobileDevice && roles.includes("3")) {
                api.get("evidencija/getodabranizaposlenik", getOdabraniZaposlenikSuccess, getOdabraniZaposlenikFail);
            } else {
                global.setDrugiZaposlenik(global.zaposlenici.find((z) => z.KorisnikID == global.user.korisnikID));
            }
        }
    }, [global.user, global.zaposlenici]);

    function getOdabraniZaposlenikSuccess(data) {
        global.setDrugiZaposlenik(data);
    }

    function getOdabraniZaposlenikFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        global.setLoading(false);
    }

    function handleDrugiZaposlenikChange(e, v) {
        if (v) {
            api.get("evidencija/setodabranizaposlenik/" + v.KorisnikID, setDrugiZaposlenikSuccess, setDrugiZaposlenikFail);
        }
    }

    function setDrugiZaposlenikSuccess(data) {
        global.setDrugiZaposlenik(data);
    }

    function setDrugiZaposlenikFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        global.setLoading(false);
    }

    let logoClassName = collapsed ? "logoCollapsed" : "logo";
    logoClassName = "logo";

    const menu = (
        <Menu>
            <Menu.Item
                key="mbm0"
                onClick={() => {
                    window.location = "#/passwordchange";
                }}
            >
                Promjena lozinke
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item
                key="mbm1"
                onClick={() => {
                    window.location = "#/login";
                    Util.logout();
                    //global.setLogoFile(null);
                }}
            >
                Odjava
            </Menu.Item>
        </Menu>
    );

    function loginPage() {
        return global.selectedTab == "login" || global.selectedTab == "upute" || global.selectedTab == "uvjetikoristenja";
    }

    // useEffect(() => {
    //     alert(location.pathname)
    // }, [location]);

    return (
            <Layout>
                {isMobileDevice ? (
                    <MainMenuMobile username={global.user ? global.user.username : ""} roles={roles} isLoggedIn={!!global.user}></MainMenuMobile>
                ) : !loginPage() ? (
                    <Sider style={global.theme == "light" ? { backgroundColor: "white", minHeight: "100vh" } : { backgroundColor: "rgb(0 21 41)", minHeight: "100vh" }} collapsedWidth={50} trigger={null} collapsible collapsed={collapsed}>
                        {collapsed ? (
                            <img
                                src={logo}
                                style={{ margin: "auto", height: "50px", width: "50px", float: "left", cursor: "pointer" }}
                                onClick={() => {
                                    window.location = "#/home";
                                    global.setSelectedTab("home");
                                }}
                            ></img>
                        ) : (
                            <div
                                className="menu-logo"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    window.location = "#/home";
                                    global.setSelectedTab("home");
                                }}
                            >
                                <span style={{ marginTop: "10px", color: "white", display: "inline-block" }}>
                                    <p style={global.theme == "light" ? { color: "#626262", fontFamily: "Righteous", fontSize: "24px" } : { color: "white", fontFamily: "Righteous", fontSize: "24px" }}>CHATO</p>
                                    <p style={{ marginTop: "-35px", fontSize: "9px", color: "#757575" }}>Copyright 2023 IPT d.o.o.</p>
                                </span>
                                <img src={logo} style={{ height: "64px", width: "64px", float: "left" }}></img>
                            </div>
                            // <Image className={"logo"} style={{ height: "64px", width: "64px", float: "right" }} src={logo}></Image>
                        )}
                        <MainMenu roles={roles} zahtjevOdobrenTrigger={zahtjevOdobrenTrigger} isLoggedIn={!!global.user}></MainMenu>
                    </Sider>
                ) : null}

                <Layout className="site-layout">
                    {!isMobileDevice && !loginPage() && (
                        <Header className="site-layout-background" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", textAlign: "right" }}>
                            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                className: "trigger",
                                onClick: toggle,
                            })}

                            <div style={{height:'100%', width:'100%', textAlign:'left', marginLeft:'1em'}}>
                                {global.logoFile && (global.logoPosition == 1 || global.logoPosition == 3) ? (
                                    <>
                                        <img src={"data:image/png;base64," + global.logoFile} style={{height: "95%" }}></img>
                                    </>
                                ) : 
                                    null
                                }
                            </div>





                            &nbsp;&nbsp;&nbsp;
                            {global.user ? (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ float: "right", display: "flex", alignItems: "center", marginRight: "20px", width:'70%' }}>
                                        <FormControl style={{ width: "30vw" }}>
                                            <Autocomplete 
                                                disablePortal 
                                                style={{ display: "flex", alignItems: "center" }} 
                                                options={global.dostupniZaposlenici} 
                                                disabled={!global.dostupniZaposlenici} 
                                                hidden={!location || !(location.pathname.toLowerCase().includes("evidencija") || location.pathname.toLowerCase().includes("zadaci")) || !global.roles || !global.roles.includes("3")} 
                                                getOptionLabel={(option) => option && option.Ime + " " + option.Prezime}
                                                renderOption={(props, option) => {
                                                    return (
                                                    <li {...props} key={"korisnik" + option.KorisnikID} style={{color: option.TipStatusaKorisnikaID == 1 ? (option.KorisnikID == global.user.korisnikID ? "blue" : "black") : "red"}}>
                                                        {option.Ime + " " + option.Prezime}
                                                    </li>
                                                    );
                                                }}                                                 
                                                value={global.drugiZaposlenik} 
                                                onChange={handleDrugiZaposlenikChange} 
                                                autoHighlight 
                                                disableClearable 
                                                renderInput={(params) => <TextField sx={{ 
                                                    backgroundColor: global.drugiZaposlenik && global.drugiZaposlenik.TipStatusaKorisnikaID == 1 ? (global.drugiZaposlenik && global.drugiZaposlenik.KorisnikID != global.user.korisnikID ? "rgb(255, 255, 0, 0.3)" : "white") : "red",
                                                    "input":{color: global.drugiZaposlenik && global.drugiZaposlenik.TipStatusaKorisnikaID == 1 ?  (global.drugiZaposlenik && global.drugiZaposlenik.KorisnikID != global.user.korisnikID ? "#d32f2f" : "black") : "white"}, 
                                                    "& legend": { display: "none" }, 
                                                    "& fieldset": { top: 0 } }} 
                                                    {...params} size="small" />} />
                                        </FormControl>
                                    </span>

                                    {/* <Tooltip title={global.user.firstName + " " + global.user.lastName}> */}
                                    <span style={{ float: "right", width: "30%" }}>
                                        <Dropdown overlay={menu} trigger={["click"]}>
                                            <div style={{ fontSize: "13px", color: "gray", lineHeight: "20px", cursor: "pointer" }}>
                                                {global.logoFile && (global.logoPosition == 2 || global.logoPosition == 3) ? (
                                                    <>
                                                        
                                                        <img src={"data:image/png;base64," + global.logoFile} style={{ marginTop: "10px", height: "20px" }}></img>
                                                        <br></br>

                                                        {global.user ? (
                                                            <>
                                                                {/* {global.user.username} ({global.user.firmaNaziv}){"   "} */}
                                                                {global.user.username}
                                                                {"    "}
                                                            </>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                   <div> <UserOutlined style={{marginRight:'2px'}}></UserOutlined>{global.user.username}</div><Tooltip title={global.user.firmaNaziv} enterDelay={300} enterNextDelay={300}><div style={{ textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap" }}>{"(" + global.user.firmaNaziv + ")"}</div></Tooltip>
                                                    </>
                                                )}
                                            </div>
                                        </Dropdown>
                                    </span>
                                    {/* </Tooltip> */}
                                </div>
                            ) : null}
                        </Header>
                    )}
                    <Content
                        className="site-layout-background site-content"
                        style={{
                            padding: 24,
                            minHeight: 280,
                        }}
                    >
                        {global.user && global.loading ? (
                            <Spinner></Spinner>
                        ) : (
                            <Switch>
                                {<Route path="/passwordchange" component={() => <PasswordChange></PasswordChange>}></Route>}
                                {<Route path="/confirm" component={() => <RegistracijaConfirm></RegistracijaConfirm>}></Route>}
                                {!global.user && <Route path="/upute" component={() => <Upute></Upute>}></Route>}
                                {!global.user && <Route path="/uvjetikoristenja" component={() => <UvjetiKoristenja></UvjetiKoristenja>}></Route>}
                                {!global.user && <Route path="/registracija" component={() => <Registracija></Registracija>}></Route>}
                                {!global.user && <Route path="/" component={() => <Login success={onLoginSuccess}></Login>}></Route>} 
                                {!global.user && <Route path="/login" component={() => <Login success={onLoginSuccess}></Login>}></Route>}
                                {global.user && !isMobileDevice && global.roles && (global.roles.includes("2") || global.roles.includes("3") || global.roles.includes("15")) && <Route path="/pretragaevidencije" component={() => <PretragaEvidencije klijentiPredmeti={klijentiPredmeti} loadKlijentiAndPredmeti={loadKlijentiAndPredmeti}></PretragaEvidencije>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("13") && <Route path="/postavke" component={() => <Postavke></Postavke>}></Route>}
                                {global.user && !isMobileDevice && <Route path="/home" component={() => <Home roles={roles}></Home>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/klijenti" component={() => <Klijenti filterRef={klijentiFilter} dataGridFilterRef={klijentiDataGridFilterModel} dataGridSortRef={klijentiDataGridSortModel} global={global} klijentiPredmeti={klijentiPredmeti} loadKlijentiAndPredmeti={loadKlijentiAndPredmeti}></Klijenti>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("9") && <Route path="/financijskakartica" component={() => <FinancijskaKartica></FinancijskaKartica>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/predmeti" component={() => <Predmeti filterRef={predmetiFilter} dataGridFilterRef={predmetiDataGridFilterModel} dataGridSortRef={predmetiDataGridSortModel} global={global} klijentiPredmeti={klijentiPredmeti} loadKlijentiAndPredmeti={loadKlijentiAndPredmeti} firmaID={global.user.firmaID} tipTvrtkeID={global.user.TipTvrtkeID}></Predmeti>}></Route>}
                                {global.user && !isMobileDevice && <Route path="/about" component={() => <About></About>}></Route>}
                                {/* {global.user && !isMobileDevice && <Route path="/calendar" component={() => <Calendar></Calendar>}></Route>} */}
                                {/* {global.user && !isMobileDevice && <Route path="/sampleForm" component={() => <SampleForm></SampleForm>}></Route>} */}
                                {global.user && global.roles && (global.roles.includes("2") || (!isMobileDevice && global.roles.includes("3"))) && <Route path="/evidencija" component={() => <Evidencije klijentiPredmeti={klijentiPredmeti} loadKlijentiAndPredmeti={loadKlijentiAndPredmeti}></Evidencije>}></Route>}
                                {global.user && global.roles && (global.roles.includes("23") || global.roles.includes("24")) && <Route path="/zadaci" component={() => <Zadaci klijentiPredmeti={klijentiPredmeti} loadKlijentiAndPredmeti={loadKlijentiAndPredmeti}></Zadaci>}></Route>}
                                {global.user && isMobileDevice && global.roles && (global.roles.includes("2")) && <Route path="/" component={() => <Evidencije klijentiPredmeti={klijentiPredmeti} loadKlijentiAndPredmeti={loadKlijentiAndPredmeti}></Evidencije>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles && global.roles.includes("7") && <Route path="/arhiva" component={() => <Arhive></Arhive>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles && global.roles.includes("8") && <Route path="/prijenos" component={() => <PrijenosAktivnosti klijentiPredmeti={klijentiPredmeti} loadKlijentiAndPredmeti={loadKlijentiAndPredmeti}></PrijenosAktivnosti>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/konto" component={() => <Konto></Konto>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("9") && <Route path="/racuni" component={() => <Racuni klijentiPredmeti={klijentiPredmeti} loadKlijentiAndPredmeti={loadKlijentiAndPredmeti}></Racuni>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/odjeli" component={() => <Odjel></Odjel>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("12") && <Route path="/korisnici" component={() => <Korisnik></Korisnik>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/mjesta" component={() => <Mjesto></Mjesto>}></Route>}
                                {/* {global.user && !isMobileDevice && <Route path="/passwordchange" component={() => <PasswordChange></PasswordChange>}></Route>} */}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/grupe" component={() => <Grupa></Grupa>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/tipovikorisnika" component={() => <TipKorisnika></TipKorisnika>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("10") && <Route path="/tecaj" component={() => <Tecaj></Tecaj>}></Route>}
                                {global.user && !isMobileDevice && <Route path="/test" component={() => <Test klijenti={klijentiPredmeti.Klijenti}></Test>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("6") && <Route path="/izvjestaj" component={() => <Izvjestaji klijentiPredmeti={klijentiPredmeti} loadKlijentiAndPredmeti={loadKlijentiAndPredmeti}></Izvjestaji>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/nacinplacanja" component={() => <NacinPlacanja></NacinPlacanja>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/nepredmetnekategorije" component={() => <NepredmetneKategorije></NepredmetneKategorije>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/poslovniprostori" component={() => <PoslovniProstori></PoslovniProstori>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/blagdani" component={() => <Blagdani></Blagdani>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/satnicerada" component={() => <SatniceRada></SatniceRada>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/reportrjecnik" component={() => <ReportRjecnik></ReportRjecnik>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("1") && <Route path="/sudovi" component={() => <Sudovi></Sudovi>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("21") && <Route path="/zahtjevi" component={() => <Zahtjevi filterRef={zahtjeviFilter} setZahtjevOdobrenTrigger={setZahtjevOdobrenTrigger}></Zahtjevi>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("21") && <Route path="/aktivnost" component={() => <Aktivnost></Aktivnost>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("21") && <Route path="/verifikacija" component={() => <KrajProbnogPerioda></KrajProbnogPerioda>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("21") && <Route path="/registracijainterno" component={() => <RegistracijaInterno></RegistracijaInterno>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("21") && <Route path="/postavkesustava" component={() => <PostavkeSustava></PostavkeSustava>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("21") && <Route path="/fiskalizacija" component={() => <Fiskalizacija></Fiskalizacija>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("21") && <Route path="/dodatnenaplate" component={() => <DodatneNaplate></DodatneNaplate>}></Route>}
                                {global.user && !isMobileDevice && global.roles && global.roles.includes("21") && <Route path="/licence" component={() => <Licence></Licence>}></Route>}
                                {global.user && !isMobileDevice && <Route component={() => <Home roles={roles}></Home>}></Route>}
                                {global.user && isMobileDevice && <Route component={() => <div style={{textAlign:"center"}}>Nije Vam dodijeljeno pravo evidentiranja.</div>}></Route>}
                                {global.user && <Route path="/" component={() => <Login success={onLoginSuccess}></Login>}></Route>}
                                {/* {isLoggedIn && <Route component={NotFound}></Route>} */}
                            </Switch>
                        )}
                    </Content>
                    {loginPage() ? (
                        <Footer style={{ textAlign: "center", color: "gray" }}>
                            <Grid container>
                                <Grid item xs={4} style={{textAlign:"left"}}>
                                    <Link onClick={() => {window.location = "#/uvjetikoristenja"}}>Uvjeti korištenja</Link>
                                </Grid>
                                <Grid item xs={4}>
                                    {"Chato, copyright 1998 - " + (new Date()).getFullYear() + " - IPT d.o.o."}
                                </Grid>
                                <Grid item xs={4} style={{textAlign:"right"}}>
                                    <Link onClick={() => {if(!window.location.toString().includes("upute")) window.location = "#/upute";}}>Upute</Link>
                                </Grid>
                            </Grid>
                        </Footer>
                    ) : null}
                </Layout>
            </Layout>
    );
}

export default Navigation;
