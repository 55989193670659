import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ComponentContainer from "./componentContainer";
import ErrorIcon from '@mui/icons-material/Error';
import { Box } from "@mui/system";
import Util from "../util/util";
import api from "../util/api";
import AlertDialog from "./alertdialog";

function OsvjeziAdresuAlert(props) {

    const useConstructor = Util.useConstructor();

    const racun = props.racun;
    const racuniIDs = props.racuniIDs;
    const removeMessage = props.removeMessage;
    const close = props.close;
    const filterRacuni = props.filterRacuni;

    const [loading, setLoading] = useState(true);
    const [staraAdresa, setStaraAdresa] = useState("");
    const [novaAdresa, setNovaAdresa] = useState("");
    const [stariNaziv, setStariNaziv] = useState("");
    const [noviNaziv, setNoviNaziv] = useState("");
    const [stariIDBroj, setStariIDBroj] = useState("");
    const [noviIDBroj, setNoviIDBroj] = useState(""); 
    const [klijenti, setKlijenti] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        let resource = racuniIDs || [];
        if (racun) {
            resource.push(racun.RacunID);
        }
        api.post("racun/getadrese", resource, getAdreseSuccess, getAdreseFail);
    });

    function getAdreseSuccess(data) {
        setKlijenti(data);
        setLoading(false);
    }

    function getAdreseFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function osvjeziAdresu() {
        setLoading(true);
        let resource = racuniIDs || [];
        if (racun) {
            resource.push(racun.RacunID);
        }
        api.post("racun/osvjeziadresu", resource, osvjeziAdresuSuccess, osvjeziAdresuFail);
    }

    function osvjeziAdresuSuccess() {
        if (filterRacuni) {
            filterRacuni();
        }
        if (removeMessage) {
            removeMessage();
        }
        close();
    }

    function osvjeziAdresuFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container>
                {klijenti.map((k, i) => {
                    return (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={2}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                &nbsp;
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider></Divider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                Naziv:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                Adresa:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div">
                                                ID:
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div" style={{textAlign:"center", fontWeight:"bold"}}>
                                                RAČUN
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider></Divider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div" style={{textAlign:"center"}}>
                                                {k.StariNaziv}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div" style={{textAlign:"center"}}>
                                                {k.StaraAdresa}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div" style={{textAlign:"center"}}>
                                                {k.StariIDBroj}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div" style={{textAlign:"center", fontWeight:"bold"}}>
                                                TRENUTNI PODACI KLIJENTA
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider></Divider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div" style={{textAlign:"center"}}>
                                                {k.NoviNaziv}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div" style={{textAlign:"center"}}>
                                                {k.NovaAdresa}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div" style={{textAlign:"center"}}>
                                                {k.NoviIDBroj}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                        </Grid>
                        </Grid>
                    );
                })}
                <Grid item xs={12}>
                    <Divider></Divider>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom component="div" marginTop="15px">
                        {racun ? 
                        "Želite li prenijeti trenutne podatke klijenta na odabrani račun?"
                        :
                        "Želite li prenijeti trenutne podatke klijenata na odabrane račune?"}
                    </Typography>
                </Grid>
                <Grid item xs={12} marginTop="15px">
                    <Box style={{display:"flex", justifyContent:"flex-end"}}>
                        <Button variant="contained" style={{marginRight:"5px"}} onClick={osvjeziAdresu}>Osvježi podatke</Button>
                        <Button color="error" variant="outlined" onClick={close}>Odustani</Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default OsvjeziAdresuAlert;