import { Box, ButtonBase, ClickAwayListener, Container, Divider, Grid, Hidden, Icon, IconButton, ListItem, ListItemIcon, ListItemText, Paper, TextField, Tooltip } from "@mui/material";
import { Button } from "@mui/material";
import { List } from "@mui/material";
import React, { useContext, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import InfoIcon from '@mui/icons-material/Info';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import dateFormat, { masks } from "dateformat";
import Brightness1Icon from '@mui/icons-material/Brightness1';

function RacunListItem(props) {

    const racun = props.racun;
    const setCurrent = props.setCurrent;
    const fontWeight = props.fontWeight;
    const backColor = props.backColor;
    const racunDetalji = Util.getRacunDetalji(props.racun);
    const edit = props.edit;
    const forceReRender = props.forceReRender;
    const formatter = Util.formatter();
    const isLast = props.isLast;
    const index = props.index;
    
    return <ListItem className={"list-item"} key={"racun" + racun.RacunID} style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: racun.NeuspjesnaFiskalizacija ? "red" : backColor, color: racun.NeuspjesnaFiskalizacija ? "white" : ((racun.Storniran || racun.VrstaRacunaID == 7) ? "red" : (racun.Broj ? (racun.VrstaRacunaID == 3 ? "green" : "black") : "blue")) }}  onClick={(e) => { props.handleRacunClick(e, racun.RacunID, props.index, props.referenca.current)}} onDoubleClick={edit}>
                                        <Grid container>
                                            <Grid item xs={2}>
                                                <Grid container>
                                                    <Tooltip title={racun.RacunID}>
                                                        <Grid item xs={2} >
                                                            <ListItemText primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={racun.RacunID} ></ListItemText>
                                                        </Grid>
                                                    </Tooltip>
                                                    <Tooltip title={racun.VrstaRacunaNaziv} enterDelay={300} enterNextDelay={300}>
                                                        <Grid item xs={4}>
                                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={racun.VrstaRacunaNaziv} />
                                                        </Grid>
                                                    </Tooltip>
                                                    <Tooltip title={racun.Broj || ""} enterDelay={300} enterNextDelay={300}>
                                                        <Grid item xs={6}>
                                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={racun.Broj || ""} />
                                                        </Grid>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Tooltip title={racun.KlijentBroj + ' - ' + racun.KlijentNaziv} enterDelay={300} enterNextDelay={300}>
                                                <Grid item xs={2}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={(racun.KlijentNaziv)} />
                                                </Grid>
                                            </Tooltip>
                                            <Tooltip title={dateFormat(racun.Datum, "dd.mm.yyyy")} enterDelay={300} enterNextDelay={300}>
                                                <Grid item xs={1}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={(dateFormat(racun.Datum, "dd.mm.yyyy"))} />
                                                </Grid>
                                            </Tooltip>
                                            <Grid item xs={7}>
                                                <Grid container>
                                                    <Tooltip title={racun.FirmaValutaNaziv} enterDelay={300} enterNextDelay={300}>
                                                        <Grid item xs={1}>
                                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={(racun.FirmaValutaNaziv)} />
                                                        </Grid>
                                                    </Tooltip>
                                                    <Grid item xs={10}>
                                                        <Grid container>
                                                            <Tooltip title={formatter.format(racunDetalji.Iznos)} enterDelay={300} enterNextDelay={300}>
                                                                <Grid item xs={2}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight, textAlign:"right" }} primary={formatter.format(racunDetalji.Iznos)} ></ListItemText>
                                                                </Grid>
                                                            </Tooltip>
                                                            <Tooltip title={formatter.format(racunDetalji.Porez)} enterDelay={300} enterNextDelay={300}>
                                                                <Grid item xs={2}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight, textAlign:"right" }} primary={formatter.format(racunDetalji.Porez)} ></ListItemText>
                                                                </Grid>
                                                            </Tooltip>
                                                            <Tooltip title={formatter.format(racunDetalji.Ukupno)} enterDelay={300} enterNextDelay={300}>
                                                                <Grid item xs={2}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight, textAlign:"right" }} primary={formatter.format(racunDetalji.Ukupno)} ></ListItemText>
                                                                </Grid>
                                                            </Tooltip>
                                                            <Tooltip title={formatter.format(racunDetalji.Placeno)} enterDelay={300} enterNextDelay={300}>
                                                                <Grid item xs={2}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight, textAlign:"right" }} primary={formatter.format(racunDetalji.Placeno)} ></ListItemText>
                                                                </Grid>
                                                            </Tooltip>
                                                            <Tooltip title={formatter.format(racunDetalji.Ostalo)} enterDelay={300} enterNextDelay={300}>
                                                                <Grid item xs={2}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight, textAlign:"right" }} primary={formatter.format(racunDetalji.Ostalo)} ></ListItemText>
                                                                </Grid>
                                                            </Tooltip>
                                                            <Tooltip title={racun.Napomena ? racun.Napomena: ''} enterDelay={300} enterNextDelay={300}>
                                                                <Grid item xs={2}>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, fontWeight: fontWeight }} primary={racun.Napomena} ></ListItemText>
                                                                </Grid>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Tooltip title={racun.IzvezenaDatotekaNaziv} enterDelay={300} enterNextDelay={300} sx={{ display:{xs:"none", sm:"none", md:"none" , lg:"none", xl:"block"} }}>
                                                        <Grid item xs={1}>
                                                            <ListItemIcon style={{display:"flex", justifyContent:"center"}}>
                                                                {racun.IzvezenaDatotekaID ? (
                                                                    <CheckBoxIcon fontSize="small" style={{marginTop:"3px"}} />
                                                                ) : (
                                                                    <CheckBoxOutlineBlankIcon fontSize="small" style={{marginTop:"3px"}} />
                                                                )}
                                                            </ListItemIcon>
                                                        </Grid>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            {!isLast ? (
                                                <Grid item xs={12}>
                                                    <Divider></Divider>
                                                </Grid>
                                            ) : null}
                                        </Grid>
                                    </ListItem>

}

export default React.memo(RacunListItem, (oldProps, newProps) => { 
    return oldProps.fontWeight === newProps.fontWeight 
            && oldProps.racun.Loaded === newProps.racun.Loaded 
            && oldProps.isLast === newProps.isLast
    });