import { Alert, Autocomplete, Box, Button, FormControl, Grid, Modal, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ExitToApp from "@mui/icons-material/ExitToApp";
import globalContext from "./globalContext";
import api from "../util/api";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AlertDialog from "./alertdialog";
import ErrorAlert from "./errorAlert"
import { hr } from "date-fns/locale";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9_.]+$/;

export default function KorisnikAktivacijaForm(props) {
    
    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);
    const close = props.close;
    const aktivacija = props.aktivacija;    // ako je false -> deaktivacija
    const loadKorisnici = props.loadKorisnici;

    const [loading, setLoading] = useState(true);
    const [vrstaAktivacije, setVrstaAktivacije] = useState(0);
    const [postojeciKorisnik, setPostojeciKorisnik] = useState(null);
    const [postojeciKorisnici, setPostojeciKorisnici] = useState([]);
    const [formValid, setFormValid] = useState(false);
    const [ime, setIme] = useState("");
    const [prezime, setPrezime] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [telefon, setTelefon] = useState("");
    const [tipKorisnika, setTipKorisnika] = useState(null);
    const [tipoviKorisnika, setTipoviKorisnika] = useState([]);
    const [datumAktivacije, setDatumAktivacije] = useState(new Date());
    const [minDatumAktivacije, setMinDatumAktivacije] = useState(new Date());
    const [datumAktivacijeError, setDatumAktivacijeError] = useState("");
    const [existingUsernames, setExistingUsernames] = useState([]);
    const [deaktivacijaAlert, setDeaktivacijaAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [postojePosudeneArhive, setPostojePosudeneArhive] = useState(false);

    useConstructor(() => {
        api.get("korisnik/aktivacija/info/" + global.user.firmaID, loadInfoSuccess, loadInfoFail);
    });

    function loadInfoSuccess(data) {
        setPostojeciKorisnici(data.Korisnici);
        if (!aktivacija && props.currentID != global.user.korisnikID) {
            setPostojeciKorisnik(data.Korisnici.find(k => k.KorisnikID == props.currentID));
        }
        setTipoviKorisnika(data.TipoviKorisnika);
        setMinDatumAktivacije(new Date(data.MinDatumAktivacije));
        setDatumAktivacije(new Date(data.MinDatumAktivacije));
        setExistingUsernames(data.ExistingUsernames);
        setLoading(false);
    }

    function loadInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function prihvati() {
        if (aktivacija) {
            podnesiZahtjev();
        } else {
            setDeaktivacijaAlert(true);
        }
    }

    function checkIfFormValid() {
        let valid = datumAktivacijeError != "";
        if (aktivacija) {
            valid = !(vrstaAktivacije == 1 && !postojeciKorisnik) && !(vrstaAktivacije == 0 && (!ime || !prezime || (!username || existingUsernames.includes(username.trim().toLowerCase())) || !tipKorisnika || !email || (email && !Util.isValidEmail(email))));
        } else {
            valid = postojeciKorisnik && postojeciKorisnik.KorisnikID != global.user.korisnikID;
        }
        setFormValid(valid);
    }

    function checkIfDatumAktivacijeValid() {
        if (datumAktivacije) {
            if (datumAktivacije instanceof Date && !isNaN(datumAktivacije)) {
                if (datumAktivacije >= minDatumAktivacije) {
                    setDatumAktivacijeError("");
                } else {
                    setDatumAktivacijeError("Zahtjev mora biti podnesen barem 5 dana prije datuma" + (aktivacija ? " aktivacije." : " deaktivacije."));
                }
            } else {
                setDatumAktivacijeError("Neispravan format datuma.");
            }
        } else {
            setDatumAktivacijeError("Odaberite datum" + (aktivacija ? " aktivacije." : " deaktivacije."));
        }
    }

    useEffect(() => {
        checkIfDatumAktivacijeValid();
    }, [datumAktivacije, minDatumAktivacije]);

    useEffect(() => {
        checkIfFormValid();
    }, [postojeciKorisnik, vrstaAktivacije, ime, prezime, username, existingUsernames, email, datumAktivacijeError, tipKorisnika]);

    function podnesiZahtjev() {
        setLoading(true);
        let resource = {
            TipAktivacijskeRadnjeID : aktivacija ? 1 : 2,
            FirmaID : global.user.firmaID,
            ZatrazioKorisnikID : global.user.korisnikID,
            KorisnikID : aktivacija ? (vrstaAktivacije == 0 ? null : postojeciKorisnik.KorisnikID) : postojeciKorisnik.KorisnikID,
            Ime : ime,
            Prezime : prezime,
            Email : email,
            Telefon : telefon,
            TipKorisnikaID : tipKorisnika ? tipKorisnika.TipKorisnikaID : null,
            DatumPrimjene : datumAktivacije,
            Username : username
        };
        api.post("korisnik/aktivacija/zahtjev", resource, podnesiZahtjevSuccess, podnesiZahtjevFail);
    }

    function podnesiZahtjevSuccess(data) {
        setDeaktivacijaAlert(false);
        setErrorMessage(null);
        setLoading(false);
        loadKorisnici();
        close();
    }

    function podnesiZahtjevFail(data) {
        setDeaktivacijaAlert(false);
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function provjeriImaLiPosudenihArhiva() {
        api.get("korisnici/provjeriimaliposudeniharhiva/" + postojeciKorisnik.KorisnikID, provjeriImaLiPosudenihArhivaSuccess, provjeriImaLiPosudenihArhivaFail);
    }
    
    function provjeriImaLiPosudenihArhivaSuccess(data) {
        if (data) {
            setPostojePosudeneArhive(true);
        } else {
            podnesiZahtjev();
        }
     }
    
    function provjeriImaLiPosudenihArhivaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
        setDeaktivacijaAlert(false);
    }

    return (
        <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
            <ComponentContainer loading={loading}>
                <Grid container spacing={1}>
                    {!aktivacija ? (
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom component="div" style={{ textAlign: "center", marginBottom: "15px" }}>
                                <div
                                    style={{
                                        display: "inline-flex",
                                        verticalAlign: "text-bottom",
                                        boxSizing: "inherit",
                                        textAlign: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <PersonRemoveIcon fontSize="medium" style={{marginRight:"10px"}}></PersonRemoveIcon>
                                    Deaktivacija korisnika
                                </div>
                            </Typography>
                        </Grid>                        
                    ) : null}
                    {aktivacija ? (
                        <>
                            <Grid item xs={12}>
                                <Box style={{width:"100%", marginBottom:"15px"}}>
                                <Tabs value={vrstaAktivacije} onChange={(e, i) => setVrstaAktivacije(i)} variant="fullWidth">
                                    <Tab icon={<PersonAddAltIcon></PersonAddAltIcon>} iconPosition="start" label="Novi korisnik (dodavanje)"/>
                                    <Tab icon={<RotateLeftIcon></RotateLeftIcon>} iconPosition="start" label="Postojeći korisnik (reaktivacija)"/>
                                </Tabs>
                            </Box>
                            </Grid>
                            {vrstaAktivacije == 0 ? (
                                <>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField label="Ime" value={ime} inputProps={{ maxLength: 50 }} onChange={e => setIme(e.target.value)} error={!ime} required size="small"></TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField label="Prezime" value={prezime} inputProps={{ maxLength: 50 }} onChange={e => setPrezime(e.target.value)} error={!prezime} required size="small"></TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <Tooltip title="Započinje sa slovom, barem jedno slovo, može sadržavati brojeve, minimalno 8 znakova" arrow>
                                                <TextField
                                                    label="Korisničko ime" 
                                                    value={username} 
                                                    inputProps={{ maxLength: 50 }}
                                                    size="small"
                                                    onChange= {(e) => setUsername(e.target.value)}
                                                    error={!username || existingUsernames.includes(username.trim().toLowerCase()) || !Util.isValidNewUsername(username)} 
                                                    onKeyDown={(event) => {if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {event.preventDefault();}}}
                                                    helperText={username && existingUsernames.includes(username.trim().toLowerCase()) ? "Korisničko je ime zauzeto." : ""}                  
                                                ></TextField>
                                            </Tooltip>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <TextField label="E-mail" value={email} inputProps={{ maxLength: 150 }} onChange={e => setEmail(e.target.value)} size="small" error={!email || (email && !Util.isValidEmail(email))} helperText={email && !Util.isValidEmail(email) ? "Neispravan e-mail" : ""}></TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <TextField label="Telefon" value={telefon} inputProps={{ maxLength: 50 }} onChange={e => setTelefon(e.target.value)} size="small"></TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl style={{ width: "100%" }}>
                                            <Autocomplete size="small" 
                                                disablePortal
                                                autoHighlight
                                                disableClearable
                                                options={tipoviKorisnika} 
                                                onChange={(e,v) => setTipKorisnika(v)}
                                                getOptionLabel={option => option.Naziv}
                                                value={tipKorisnika}
                                                ListboxProps={{ style: { maxHeight: '6rem' } }}
                                                renderInput={(params) => (
                                                    <TextField size="small" {...params} label="Tip korisnika" error={!tipKorisnika} />
                                                )}
                                            />
                                        </FormControl>                    
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth>
                                            <Autocomplete
                                                disablePortal
                                                options={postojeciKorisnici.filter(k => k.TipStatusaKorisnikaID != 1 && k.KorisnikID != global.user.korisnikID)}
                                                autoHighlight
                                                value={postojeciKorisnik}
                                                ListboxProps={{ style: { maxHeight: '6rem' } }}
                                                getOptionLabel={(option) => option.Ime + " " + option.Prezime + " (" + option.Username + ")"}
                                                onChange={(e,v) => setPostojeciKorisnik(v)}
                                                renderInput={(params) => <TextField {...params} label="Korisnik" error={!postojeciKorisnik} size="small" style={{width:"100%"}} />}
                                            />
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disablePortal
                                        options={postojeciKorisnici.filter(k => k.TipStatusaKorisnikaID == 1 && k.KorisnikID != global.user.korisnikID)}
                                        autoHighlight
                                        value={postojeciKorisnik}
                                        getOptionLabel={(option) => option.Ime + " " + option.Prezime + " (" + option.Username + ")"}
                                        onChange={(e,v) => setPostojeciKorisnik(v)}
                                        ListboxProps={{ style: { maxHeight: '6rem' } }}
                                        renderInput={(params) => <TextField {...params} label="Korisnik" error={!postojeciKorisnik || postojeciKorisnik.KorisnikID == global.user.korisnikID} size="small" style={{width:"100%"}} />}
                                    />
                                </FormControl>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={6} style={{display:"flex", justifyContent:"center"}}>
                        <DatePicker onChange={setDatumAktivacije} label={aktivacija ? "Datum aktivacije - najkasnije" : "Datum deaktivacije - najkasnije"} value={datumAktivacije} inputFormat="dd.MM.yyyy" minDate={minDatumAktivacije} renderInput={(params) => <TextField style={{width:"100%"}} {...params} size="small" error={datumAktivacijeError != ""} helperText={datumAktivacijeError} />} /> 
                    </Grid>
                    {aktivacija ? (
                        <Grid item xs={12} marginTop="10px">
                            <Alert severity="info">Novom će korisniku, nakon aktivacije, pristupni podaci biti poslani na navedenu e-mail adresu, stoga je bitno da je unesena ispravna e-mail adresa novog korisnika.</Alert>
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                            <Button onClick={prihvati} variant="contained" style={{marginRight:"10px"}} startIcon={<DoneOutlineIcon></DoneOutlineIcon>} color="success" disabled={loading || !formValid}>Prihvati</Button>
                            <Button variant="outlined" color="error" onClick={close} startIcon={<ExitToApp></ExitToApp>}>Zatvori</Button>
                        </Box>
                    </Grid>
                </Grid>

                {deaktivacijaAlert ? <AlertDialog title="Deaktivacija korisnika" message={"Pokrenuli ste postupak deaktivacije korisnika " + (postojeciKorisnik ? (postojeciKorisnik.Ime + " " + postojeciKorisnik.Prezime + " (" + postojeciKorisnik.Username + ")") : "") + ". Korisniku će se onemogućiti pristup aplikaciji i neće ga biti moguće odabrati kao voditelja klijenata i predmeta, potpisnu osobu i kontrolora naplate u računima te neće biti moguć upis evidencija za njega. Navedene komponente koje su već postojeće i povezane s korisnikom, ostat će nepromijenjene. Želite li nastaviti?"} cancelLabel={"Odustani"} confirmLabel={"Nastavi"} cancelAction={() => {setDeaktivacijaAlert(false); close();}} confirmAction={provjeriImaLiPosudenihArhiva}></AlertDialog> : null}
            
                {postojePosudeneArhive ? <AlertDialog title="Upozorenje o posuđenim arhivama" message={"Korisniku " + (postojeciKorisnik ? (postojeciKorisnik.Ime + " " + postojeciKorisnik.Prezime + " (" + postojeciKorisnik.Username + ")") : "") + " posuđene su jedna ili više stavki arhive. Želite li nastaviti s deaktivacijom?"} cancelLabel={"Odustani"} confirmLabel={"Nastavi"} cancelAction={() => {setPostojePosudeneArhive(false); close();}} confirmAction={podnesiZahtjev}></AlertDialog> : null}
            
                <Modal className="modal" open={errorMessage != null}>
                    <Box sx={style}>
                        <ErrorAlert message={errorMessage} close={() => setErrorMessage(null)}></ErrorAlert>
                    </Box>
                </Modal>
            </ComponentContainer>
        </LocalizationProvider>
    );
}