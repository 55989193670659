import { Autocomplete, Button, Divider, FormControl, Grid, IconButton, InputAdornment, ListItem, Menu, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from "@mui/system";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import api from "../util/api";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from '@mui/icons-material/History';
import globalContext from "./globalContext";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";
import AutocompleteAsync from "../controls/autocompleteasync";

function PoslovniProstorForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formProstor = props.formProstor;
    const setFormOpened = props.setFormOpened;

    const [loading, setLoading] = useState(true);
    const [adreseHistory, setAdreseHistory] = useState([]);
    const [mjesta, setMjesta] = useState([]);
    const [adresa, setAdresa] = useState("");
    const [mjesto, setMjesto] = useState(null);
    const [fiskalnaOznaka, setFiskalnaOznaka] = useState("");
    const [naziv, setNaziv] = useState("");
    const [blagajne, setBlagajne] = useState(props.blagajne);
    const [anchorEl, setAnchorEl] = useState(null);
    const historyOpened = Boolean(anchorEl);
    const [historyFilter, setHistoryFilter] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [mjestoApi, setMjestoApi] = useState("");

    useConstructor(() => {
        api.get("poslovniprostor/getformdetalji/" + props.firmaID, loadDetaljiSuccess, loadDetaljiFail);
    });

    function loadDetaljiSuccess(data) {
        setAdreseHistory(data.AdreseHistory);
        setMjesta(data.Mjesta);
        if (formProstor) {
            setAdresa(formProstor.Adresa.AdresaNaziv);
            setMjesto(formProstor.Adresa.MjestoResource);
            setFiskalnaOznaka(formProstor.FiskalnaOznaka);
            setNaziv(formProstor.Naziv);
        }
        setLoading(false);
    }

    function loadDetaljiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save() {
        let resource = {
            PoslovniProstorID : formProstor ? formProstor.PoslovniProstorID : -1,
            Naziv : naziv,
            FiskalnaOznaka : fiskalnaOznaka,
            Adresa : {
                AdresaNaziv : adresa,
                MjestoResource : mjesto
            },
            FirmaID : props.firmaID
        };
        setLoading(true);
        api.post("poslovniprostor/save", resource, saveSuccess, saveFail);
    }

    function saveSuccess() {
        setLoading(false);
        props.loadPoslovniProstori();
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleHistoryClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleHistoryClose(event) {
        setAnchorEl(null);
    }

    function onMjestoInputChange(e, value) {
        setMjestoApi("/mjesta/search/" + value + "/" + global.user.firmaID);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formProstor ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <AddIcon fontSize="medium" style={{marginRight:"15px"}}></AddIcon>
                            )}
                            {formProstor ? (
                                "Uređivanje poslovnog prostora"
                            ) : (
                                "Novi poslovni prostor"
                            )}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField value={naziv} label="Naziv" inputProps={{ maxLength: 250 }} onChange={e => setNaziv(e.target.value)} error={!naziv} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField value={fiskalnaOznaka} label="Fiskalna oznaka" inputProps={{ maxLength: 250 }} onChange={e => setFiskalnaOznaka(e.target.value)} error={!fiskalnaOznaka} size="small"></TextField>
                    </FormControl>
                </Grid>
                    <Grid item xs={11}>
                        <FormControl fullWidth> 
                            <TextField label="Adresa" value={adresa} onChange={e => setAdresa(e.target.value)} inputProps={{ maxLength: 500 }} error={!adresa} required size="small"></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                        <IconButton onClick={handleHistoryClick}><HistoryIcon></HistoryIcon></IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <AutocompleteAsync
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.MjestoID}>
                                            {(!option.PostanskiBroj ? "" : (option.PostanskiBroj + " ")) + (!option.Naziv? "" : (option.Naziv + ", ")) + option.DrzavaNaziv}
                                        </li>
                                    );
                                }}
                                api={mjestoApi}
                                onChange={(e,v) => setMjesto(v)}
                                onInputChange={onMjestoInputChange}
                                size="small"
                                value={mjesto}
                                getOptionLabel={(option) => (!option.PostanskiBroj ? "" : (option.PostanskiBroj + " ")) + (!option.Naziv? "" : (option.Naziv + ", ")) + option.DrzavaNaziv}
                                name="Mjesto"
                                error={!mjesto}
                                renderInput={(params) => <TextField {...params} id="mjestoNaziv" error={!mjesto} label="Mjesto" />}
                            />
                        </FormControl>
                    </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" color="success" style={{marginRight:"10px"}} disabled={!naziv || !fiskalnaOznaka || !adresa || !mjesto || !global.roles.includes("20")}><SaveIcon style={{marginRight:"10px"}} /> Spremi</Button>
                        <Button variant="outlined" color="error" onClick={() => {setFormOpened(false);}} ><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>

            <Menu
                anchorEl={anchorEl}
                open={historyOpened}
                onClose={handleHistoryClose}
                PaperProps={{ style: { maxWidth: "50vw" } }} 
            >
                <ListItem key="historyFilter">
                    <FormControl fullWidth>
                        <TextField 
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }} 
                            value={historyFilter} 
                            onChange={e => setHistoryFilter(e.target.value)} 
                            size="small"
                            InputProps={{endAdornment:(<InputAdornment position="end"><SearchIcon></SearchIcon></InputAdornment>)}}
                            onKeyDown={e => e.stopPropagation()}
                        ></TextField>
                    </FormControl>
                </ListItem>
                <Divider></Divider>
                   {adreseHistory.filter(a => a.AdresaNaziv.toLowerCase().includes(historyFilter.toLowerCase())).map((adresa, index) => {
                        return <Tooltip title={adresa.AdresaNaziv + ", " + adresa.MjestoResource.PostanskiBroj + " " + adresa.MjestoResource.Naziv} enterDelay={200} enterNextDelay={200}>
                                <MenuItem key={index} onClick={() => {setAdresa(adresa.AdresaNaziv); setMjesto(adresa.MjestoResource);}}>
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                        {adresa.AdresaNaziv + ", " + adresa.MjestoResource.PostanskiBroj + " " + adresa.MjestoResource.Naziv}
                                    </div>
                                </MenuItem>
                            </Tooltip>
                    })}
            </Menu>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default PoslovniProstorForm;