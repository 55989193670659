import { Autocomplete, Box, Button, FormControl, Grid, TextField, Typography, Select, MenuItem, InputLabel, FormControlLabel, Switch, FormHelperText } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import SaveIcon from "@mui/icons-material/Save";
import ExitToApp from "@mui/icons-material/ExitToApp";
import api from "../util/api";
import EditIcon from "@mui/icons-material/Edit";
import AddchartIcon from "@mui/icons-material/Addchart";
import database from "../util/database";
import OutlinedInput from "@mui/material/OutlinedInput";
import GroupIcon from "@mui/icons-material/Group";
import AutocompleteAsync from "../controls/autocompleteasync";
import Chip from "@mui/material/Chip";
import globalContext from "./globalContext";
import { TramRounded } from "@mui/icons-material";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';

const LogoutPwdChangeOptions = [
    { Vrijednost: 0, Naziv: "Bez akcije" },
    { Vrijednost: 1, Naziv: "Potrebna obnova lozinke pri sljedećoj prijavi" },
    { Vrijednost: 2, Naziv: "Prisilna odjava uz potrebnu obnovu lozinke" },
    { Vrijednost: 3, Naziv: "Prisilna odjava bez obvezne obnove lozinke"}
];

function KorisnikForm(props) {
    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);
    const form = props.form;
    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;
    const load = props.load;

    const [loading, setLoading] = useState(false);
    const [naziv, setNaziv] = useState("");
    const [foreignKontoID, setForeignKontoID] = useState();
    const [mjestoTroskaID, setMjestoTroskaID] = useState();
    const [mjestoTroskaNaziv, setMjestoTroskaNaziv] = useState();
    const [redoslijed, setRedoslijed] = useState();
    const [edited, setEdited] = useState(false);
    const [valid, setValid] = useState(false);
    const [data, setData] = useState();
    const [adrese, setAdrese] = useState([]);
    const [mjestoApi, setMjestoApi] = useState();
    const [aktivnostTrajanjeMin, setAktivnostTrajanjeMin] = useState(null);
    const [blokAlert, setBlokAlert] = useState(false);
    const [oldEmail, setOldEmail] = useState(form.Email);
    const [logoutPwdChange, setLogoutPwdChange] = useState(LogoutPwdChangeOptions.find(x=>x.Vrijednost == 0));
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        if (form) {
            if (form.Adresa) form.Mjesto = form.Adresa.MjestoResource;
            setData(form);
            setNaziv(form.Naziv);
        }
    });

    function loadDefaultTrajanjeFail() {
        setLoading(false);
    }

    function handleTextChange(e) {
        setEdited(true);
        let newData = { ...data };
        newData[e.target.id] = e.target.value;
        setData(newData);
    }

    function handleSelectChange(e, id) {
        setEdited(true);
        let newData = { ...data };
        newData[e.target.name] = e.target.value;
        setData(newData);
    }

    function handleTipPeriodicnostiSlanjaEmailaChange(e,v){
        setEdited(true);
        let newData = { ...data };
        newData["TipPeriodicnostiSlanjaEmailaID"] = v ? v.TipPeriodicnostiSlanjaEmailaID : null;
        setData(newData);    
    }

    function handleNacinOtvaranjaReportaChange(e,v){
        setEdited(true);
        let newData = { ...data };
        newData["NacinOtvaranjaReportaID"] = v ? v.NacinOtvaranjaReportaID : null;
        setData(newData);    
    }

    function handleLogoutPwdChangeOptionsChange(e,v){
        setEdited(true);
        let newData = { ...data };
        if(v.Vrijednost == 0){
            newData["ForceLogout"] = false;
            newData["PasswordResetRequired"] = false;
        }
        if(v.Vrijednost == 1){
            newData["ForceLogout"] = false;
            newData["PasswordResetRequired"] = true;            
        }
        if(v.Vrijednost == 2){
            newData["ForceLogout"] = true;
            newData["PasswordResetRequired"] = true;            
        }
        if(v.Vrijednost == 3){
            newData["ForceLogout"] = true;
            newData["PasswordResetRequired"] = false;            
        }
        setLogoutPwdChange(v);
        setData(newData);    
    }

    function handleOdjelChange(e,v){
        setEdited(true);
        let newData = { ...data };
        newData["OdjelID"] = v ? v.OdjelID : null;
        setData(newData);    
    }

    function handleSatnicaRadaChange(e,v){
        setEdited(true);
        let newData = { ...data };
        newData["SatnicaRadaID"] = v ? v.SatnicaRadaID : null;
        setData(newData);    
    }

    function handleTipKorisnikaChange(e,v){
        setEdited(true);
        let newData = { ...data };
        newData["TipKorisnikaID"] = v ? v.TipKorisnikaID : null;
        setData(newData);    
    }

    function handleTipStatusaKorisnikaChange(e,v){
        setEdited(true);
        let newData = { ...data };
        newData["TipStatusaKorisnikaID"] = v ? v.TipStatusaKorisnikaID : null;
        setData(newData);    
    }

    function handleTipPoslovnogOdnosaKorisnikaChange(e,v){
        setEdited(true);
        let newData = { ...data };
        newData["TipPoslovnogOdnosaKorisnikaID"] = v ? v.TipPoslovnogOdnosaKorisnikaID : null;
        setData(newData);    
    }

    function handleMultiSelectChange(e, id) {
        setEdited(true);
        let newData = { ...data };
        newData[e.target.name] = e.target.value;
        setData(newData);
    }

    function save() {
        setLoading(true);

        let newData = { ...data };
        newData.FirmaID = database.get("user").firmaID;

        if (data.KorisnikID == -1) {
            api.post("korisnik/update", newData, saveSuccess, saveFail);
        } else {
            api.post("korisnik/update", newData, saveSuccess, saveFail);
        }
    }

    function saveSuccess() {
        load();
        setLoading(false);
        setEdited(false);
        setCanceled(false);
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        let formValid = data && data.Ime && data.Prezime && data.Prava && data.Prava.length > 0 && !(data.Prava.includes(24) && !data.Prava.includes(23)) && (!data.Prava.includes(16) || (data.FiskalnaSifra != null && data.FiskalnaSifra !== '')) && data.MjestoID && data.AdresaNaziv && (data.OIB ? Util.OIBCheck(data.OIB) : true) && (data.Email && Util.isValidEmail(data.Email)) && (data.TipPeriodicnostiSlanjaEmailaID == null || (data.EmailNadredenog && Util.isValidEmail(data.EmailNadredenog))) && (!data.EmailNadredenog || (Util.isValidEmail(data.EmailNadredenog) && data.TipPeriodicnostiSlanjaEmailaID != null)) && (!data.PasswordResetRequired || (data.PasswordResetMessage && data.PasswordResetMessage != null && data.PasswordResetMessage != '')) && !(data.EvidencijaBrojRedovaPredlozaka == null || isNaN(parseInt(data.EvidencijaBrojRedovaPredlozaka)) || !Number.isInteger(Number(data.EvidencijaBrojRedovaPredlozaka)) || Number(data.EvidencijaBrojRedovaPredlozaka) < 0  || Number(data.EvidencijaBrojRedovaPredlozaka) > 8);        
        formValid = formValid && ((oldEmail && oldEmail.toLowerCase() == data.Email.toLowerCase()) || (data && !props.data.PostojeceEmailAdrese.includes(data.Email.toLowerCase())));
        setValid(formValid);
    });

    function onMjestoChange(e, newValue) {
        let newData = { ...data };
        if (newValue) {
            newData.MjestoID = newValue.MjestoID;
            newData.Mjesto = newValue;
        } else {
            newData.MjestoID = 0;
            newData.Mjesto = newValue;
        }

        setData(newData);
    }

    function onMjestoInputChange(e, value) {
        setMjestoApi("/mjesta/search/" + value + "/" + database.get("user").firmaID);
    }

    function onAdresaChange(e, newVal) {
        setEdited(true);
        let newData = { ...data };
        newData.Adresa = newVal;
        if (newVal) {
            newData.AdresaID = newVal.AdresaID;
            if (newVal) {
                newData.Mjesto = newVal.MjestoResource; //props.data.Mjesta.filter(x=>x.MjestoID == newVal.MjestoResource.MjestoID)[0];
                newData.MjestoID = newVal.MjestoResource.MjestoID;
                newData.AdresaNaziv = newVal.AdresaNaziv;
            }
        } else {
            newData.AdresaNaziv = null;
        }
        setData(newData);
        console.log(newVal);
    }

    function onAdresaNazivChange(e) {
        setEdited(true);
        if (e && e.target) {
            let newData = { ...data };
            newData.AdresaID = 0;
            newData.AdresaNaziv = e.target.value;
            setData(newData);
        }
    }

    function onSwitchChange(e) {
        let newData = { ...data };
        newData[e.target.name] = e.target.checked;
        setData(newData);
    }

    function setBlok(){
        let newData = { ...data };
        newData["InvalidateAccount"] = true;
        setData(newData);
        setBlokAlert(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{ textAlign: "center", marginBottom: "15px" }}>
                        <div
                            style={{
                                display: "inline-flex",
                                verticalAlign: "text-bottom",
                                boxSizing: "inherit",
                                textAlign: "center",
                                alignItems: "center",
                            }}
                        >
                            {form ? <GroupIcon fontSize="medium" style={{ marginRight: "15px" }}></GroupIcon> : <AddchartIcon fontSize="medium" style={{ marginRight: "15px" }}></AddchartIcon>}
                            {form ? "Uređivanje korisnika" : "Novi korisnik"}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField 
                            id="Username" 
                            label="Korisničko ime" 
                            value={data && data.Username} 
                            size="small"
                            style={{backgroundColor:'cornsilk'}}
                            InputProps={{ 
                                readOnly: true
                            }}>                            
                            </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <TextField id="Ime" label="Ime" inputProps={{ maxLength: 50 }} value={data && data.Ime} onChange={handleTextChange} error={data && !data.Ime} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <TextField id="Prezime" label="Prezime" inputProps={{ maxLength: 50 }} value={data && data.Prezime} onChange={handleTextChange} error={data && !data.Prezime} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField id="OIB" label="OIB" value={data && data.OIB} error={data && data.OIB && !Util.OIBCheck(data.OIB)} helperText={data && data.OIB && !Util.OIBCheck(data.OIB) ? "Neispravan OIB" : ""} onChange={handleTextChange} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField 
                            id="Email" 
                            label="E-mail" 
                            inputProps={{ maxLength: 150 }}
                            value={data && data.Email} 
                            onChange={handleTextChange} 
                            size="small" 
                            error={data && (!data.Email || (data.Email && !Util.isValidEmail(data.Email)) || !((oldEmail && oldEmail.toLowerCase() == data.Email.toLowerCase()) || (data && !props.data.PostojeceEmailAdrese.includes(data.Email.toLowerCase()))))} 
                            helperText={data && (!data.Email || (data.Email && !Util.isValidEmail(data.Email)) || !((oldEmail && oldEmail.toLowerCase() == data.Email.toLowerCase()) || (data && !props.data.PostojeceEmailAdrese.includes(data.Email.toLowerCase())))) ? (!((oldEmail && oldEmail.toLowerCase() == data.Email.toLowerCase()) || (data && !props.data.PostojeceEmailAdrese.includes(data.Email.toLowerCase()))) ? "Ova e-mail adresa se već koristi" : "Neispravan e-mail" ) : ""}></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField id="Tel" label="Telefon" inputProps={{ maxLength: 150 }} value={data && data.Tel} onChange={handleTextChange} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField id="Fax" label="Fax" inputProps={{ maxLength: 150 }} value={data && data.Fax} onChange={handleTextChange} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField id="FiskalnaSifra" label="Fiskalna šifra" inputProps={{ maxLength: 150 }} value={data && data.FiskalnaSifra} error={(data && data.Prava && data.Prava.includes(16) && (data.FiskalnaSifra == null || data.FiskalnaSifra == ''))} helperText={(data && data.Prava && data.Prava.includes(16) && (data.FiskalnaSifra == null || data.FiskalnaSifra == '')) ? 'Obavezno polje za odabarano pravo fakturiranja' : ''} onChange={handleTextChange} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField id="EvidencijaBrojRedovaPredlozaka" label="Broj redova predložaka" type="number" inputProps={{ min: 0, max: 8 }} value={data && data.EvidencijaBrojRedovaPredlozaka} error={!data || data.EvidencijaBrojRedovaPredlozaka == null || isNaN(parseInt(data.EvidencijaBrojRedovaPredlozaka)) || !Number.isInteger(Number(data.EvidencijaBrojRedovaPredlozaka)) || Number(data.EvidencijaBrojRedovaPredlozaka) < 0  || Number(data.EvidencijaBrojRedovaPredlozaka) > 8} onChange={handleTextChange} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete size="small" 
                            disablePortal
                            autoHighlight
                            disableClearable
                            options={props.data.NaciniOtvaranjaReporta} 
                            onChange={(e,v) => handleNacinOtvaranjaReportaChange(e,v)}
                            getOptionLabel={option => option.Naziv}
                            value={data && props.data.NaciniOtvaranjaReporta.find(x=>x.NacinOtvaranjaReportaID == data.NacinOtvaranjaReportaID)}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            renderInput={(params) => (
                                <TextField size="small" {...params} label="Način otvaranja reporta (računa)" />
                            )}
                        />
                    </FormControl>            
                </Grid>                
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField id="EmailNadredenog" label="E-mail nadređenog" inputProps={{ maxLength: 150 }} value={data && data.EmailNadredenog} onChange={handleTextChange} size="small" error={(data && data.EmailNadredenog && !Util.isValidEmail(data.EmailNadredenog)) || (data && data.TipPeriodicnostiSlanjaEmailaID != null && (!data.EmailNadredenog || !Util.isValidEmail(data.EmailNadredenog)))} helperText={data && data.EmailNadredenog && !Util.isValidEmail(data.EmailNadredenog) ? "Neispravan e-mail" : (data && data.TipPeriodicnostiSlanjaEmailaID != null && (!data.EmailNadredenog || !Util.isValidEmail(data.EmailNadredenog)) ? "E-mail nadređenog mora postojati ukoliko je definiran tip periodičnosti" : "")}></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete size="small" 
                            disablePortal
                            autoHighlight
                            options={props.data.TipoviPeriodicnostiSlanjaEmaila} 
                            onChange={(e,v) => handleTipPeriodicnostiSlanjaEmailaChange(e,v)}
                            getOptionLabel={option => option.Naziv}
                            value={data && props.data.TipoviPeriodicnostiSlanjaEmaila.find(x=>x.TipPeriodicnostiSlanjaEmailaID == data.TipPeriodicnostiSlanjaEmailaID)}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            renderInput={(params) => (
                                <TextField size="small" {...params} label="Tip periodičnosti slanja" error={data && data.EmailNadredenog && Util.isValidEmail(data.EmailNadredenog) && data.TipPeriodicnostiSlanjaEmailaID == null} helperText= {data && data.EmailNadredenog && Util.isValidEmail(data.EmailNadredenog) && data.TipPeriodicnostiSlanjaEmailaID == null ? "Tip periodičnosti slanja mora postojati ukoliko je definiran e-mail nadređenog" : ""} />
                            )}
                        />
                    </FormControl>            
                </Grid>
                <Grid item xs={3}>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete size="small" 
                            disablePortal
                            autoHighlight
                            options={props.data.Odjeli} 
                            onChange={(e,v) => handleOdjelChange(e,v)}
                            getOptionLabel={option => option.Naziv}
                            value={data && props.data.Odjeli.find(x=>x.OdjelID == data.OdjelID)}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            renderInput={(params) => (
                                <TextField size="small" {...params} label="Odjel" />
                            )}
                        />
                    </FormControl>                     
                </Grid>
                <Grid item xs={3}>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete size="small" 
                            disablePortal
                            autoHighlight
                            disableClearable
                            options={props.data.SatniceRada} 
                            onChange={(e,v) => handleSatnicaRadaChange(e,v)}
                            getOptionLabel={option => option.Naziv}
                            value={data && props.data.SatniceRada.find(x=>x.SatnicaRadaID == data.SatnicaRadaID)}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            renderInput={(params) => (
                                <TextField size="small" {...params} label="Satnica rada" />
                            )}
                        />
                    </FormControl>                     
                </Grid>
                <Grid item xs={4}>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete size="small" 
                            disablePortal
                            autoHighlight
                            disableClearable
                            options={props.data.TipoviKorisnika} 
                            onChange={(e,v) => handleTipKorisnikaChange(e,v)}
                            getOptionLabel={option => option.Naziv}
                            value={data && props.data.TipoviKorisnika.find(x=>x.TipKorisnikaID == data.TipKorisnikaID)}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            renderInput={(params) => (
                                <TextField size="small" {...params} label="Tip korisnika" />
                            )}
                        />
                    </FormControl>                    
                </Grid>
                {/* <Grid item xs={4}>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete size="small" 
                            disablePortal
                            autoHighlight
                            disableClearable
                            options={props.data.TipoviStatusaKorisnika} 
                            onChange={(e,v) => handleTipStatusaKorisnikaChange(e,v)}
                            getOptionLabel={option => option.Naziv}
                            value={data && props.data.TipoviStatusaKorisnika.find(x=>x.TipStatusaKorisnikaID == data.TipStatusaKorisnikaID)}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            renderInput={(params) => (
                                <TextField size="small" {...params} label="Status korisnika" />
                            )}
                        />
                    </FormControl>                    
                </Grid> */}
                <Grid item xs={4}>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete size="small" 
                            disablePortal
                            autoHighlight
                            disableClearable
                            options={props.data.TipoviPoslovnogOdnosaKorisnika} 
                            onChange={(e,v) => handleTipPoslovnogOdnosaKorisnikaChange(e,v)}
                            getOptionLabel={option => option.Naziv}
                            value={data && props.data.TipoviPoslovnogOdnosaKorisnika.find(x=>x.TipPoslovnogOdnosaKorisnikaID == data.TipPoslovnogOdnosaKorisnikaID)}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            renderInput={(params) => (
                                <TextField size="small" {...params} label="Poslovni odnos" />
                            )}
                        />
                    </FormControl>                     
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField id="DefTrajanjeAktivnostiMin" label="Trajanje aktivnosti (default)" type="number" inputProps={{min:0}} InputProps={{endAdornment: "min"}} value={data && data.DefTrajanjeAktivnostiMin} onChange={handleTextChange} size="small" error={data && data.DefTrajanjeAktivnostiMin && (isNaN(parseInt(data.DefTrajanjeAktivnostiMin)) || parseInt(data.DefTrajanjeAktivnostiMin) <= 0)}></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth error={data && data.Prava && (data.Prava.length == 0 || (data.Prava.includes(24) && !data.Prava.includes(23)))}>
                        <InputLabel>Prava</InputLabel>
                        <Select
                            renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }} overflow="auto">
                                    {selected.map((value, index) => (
                                        <Chip key={value} label={props.data && props.data.Prava && props.data.Prava.filter((x) => x.PravoID == value)[0].Naziv} />
                                    ))}
                                </Box>
                            )}
                            multiple
                            name="Prava"
                            size="small"
                            value={(data && data.Prava) || []}
                            label={"Prava"}
                            error={data && data.Prava && (data.Prava.length == 0 || (data.Prava.includes(24) && !data.Prava.includes(23)))}
                            required
                            onChange={(e) => handleMultiSelectChange(e)}
                        >
                            {props.data &&
                                props.data.Prava.map((o, index) => {
                                    return (
                                        <MenuItem key={"mnui" + index} value={o.PravoID}>
                                            {/* <span className={data && data.Prava && data.Prava.includes(o.PravoID) ? "pill" : ""}>{o.Naziv}</span> */}
                                            {o.Naziv}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        {data && data.Prava && (data.Prava.includes(24) && !data.Prava.includes(23)) ? (
                            <FormHelperText co>Nije moguće posjedovati pravo "Zadatak zadavatelj" bez posjedovanja prava "Zadatak izvršitelj".</FormHelperText>
                        ) : null}                        
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth style={{ maxWidth: "80vw" }}>
                        {/* <Autocomplete renderOption={(props, option) => <li {...props}>{option.AdresaNaziv}</li>} disablePortal id="combo-box-demo" options={props.data.Adrese} sx={{ width: 300 }} renderInput={(params) => <TextField {...params} label="Movie" />} /> */}
                        <Autocomplete freeSolo onInputChange={onAdresaNazivChange} value={data && data.Adresa} onChange={onAdresaChange} size="small" options={props.data.Adrese} name="Adresa" getOptionLabel={(option) => option.AdresaNaziv} renderInput={(params) => <TextField {...params} label="Adresa" />} />
                    </FormControl>
                </Grid>

                {/* <Grid item xs={12}>
                    <FormControl fullWidth style={{ maxWidth: "80vw" }}>
                        <Autocomplete onChange={onMjestoChange} size="small" options={props.data.Mjesta} value={data && data.Mjesto} getOptionLabel={(option) => option.PostanskiBroj + " " + option.Naziv + ", " + option.DrzavaNaziv} name="Mjesto" renderInput={(params) => <TextField {...params} label="Mjesto" />} />
                    </FormControl>
                </Grid> */}

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <AutocompleteAsync
                            renderOption={(props, option) => {
                                return option ? (
                                    <li {...props} key={"korisnik" + option.KorisnikID}>
                                        {option.PostanskiBroj + " " + option.Naziv + ", " + option.DrzavaNaziv}
                                    </li>
                                ) : null;
                            }}
                            api={mjestoApi}
                            onChange={onMjestoChange}
                            onInputChange={onMjestoInputChange}
                            size="small"
                            value={data && data.Mjesto}
                            getOptionLabel={(option) => option.PostanskiBroj ? option.PostanskiBroj + " " + option.Naziv + ", " + option.DrzavaNaziv : ""}
                            name="Mjesto"
                            renderInput={(params) => <TextField {...params} label="Mjesto" />}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <FormControlLabel control={<Switch checked={data && data.PotpisnaOsoba} onChange={onSwitchChange} name="PotpisnaOsoba" />} label="Potpisna osoba" />
                    </FormControl>
                </Grid>

                {/* <Grid item xs={6}>
                    <FormControl fullWidth>
                        <FormControlLabel control={<Switch checked={data && data.PasswordResetRequired} onChange={onSwitchChange} name="PasswordResetRequired" />} label="Potrebna obnova lozinke pri sljedećoj prijavi" />
                    </FormControl>
                </Grid> */}

                <Grid item xs={6}>
                    <FormControl fullWidth style={{paddingLeft:"10px"}}>
                        <FormControlLabel control={<Switch disabled={data && data.KorisnikID == global.user.korisnikID} checked={data && data.InvalidateAccount} onChange={(e,v)=> {e.target.checked ? setBlokAlert(true) : onSwitchChange(e)}} name="InvalidateAccount" />} label="Korisnički račun blokiran" />
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                    <FormControl style={{ width: "100%" }}>
                        <Autocomplete size="small" 
                            disablePortal
                            autoHighlight
                            disableClearable
                            options={LogoutPwdChangeOptions} 
                            onChange={(e,v) => handleLogoutPwdChangeOptionsChange(e,v)}
                            getOptionLabel={option => option.Naziv}
                            renderOption={(props, option) => {
                                return option ? (
                                    <li {...props} key={"lgpwdc" + option.Vrijednost}>
                                        {option.Naziv}
                                    </li>
                                ) : null;
                            }}                            
                            value={logoutPwdChange}
                            ListboxProps={{ style: { maxHeight: '10rem' } }}
                            renderInput={(params) => (
                                <TextField size="small" {...params} label="Odjava i obnova lozinke" />
                            )}
                        />
                    </FormControl>                     
                </Grid>

                <Grid item xs={6}>
                    <FormControl fullWidth style={{paddingLeft:"10px"}}>
                        <FormControlLabel control={<Switch checked={data && data.EvidencijaUPrenesenoRazdobljeNePrikazuj} onChange={(e,v)=> {onSwitchChange(e)}} name="EvidencijaUPrenesenoRazdobljeNePrikazuj" />} label="Ne prikazuj upozorenje o evidenciji u potencijalno preneseno razdoblje" />
                    </FormControl>
                </Grid>

                {data && data.PasswordResetRequired ? (
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField id="PasswordResetMessage" label="Poruka pri obveznoj obnovi lozinke" inputProps={{ maxLength: 1024 }} value={data && data.PasswordResetMessage} onChange={handleTextChange} size="small" error={!data || !data.PasswordResetMessage} helperText={!data || !data.PasswordResetMessage ? "Obvezan je tekst poruke pri obveznoj obnovi lozinke" : ""}></TextField>
                        </FormControl>
                    </Grid>
                ) : null}

                {/* <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormControlLabel control={<Switch checked={data && data.DefSamoOtvoreniPredmetiUnosAktivnosti} onChange={onSwitchChange} name="DefSamoOtvoreniPredmetiUnosAktivnosti" />} label="DefSamoOtvoreniPredmetiUnosAktivnosti" />
                    </FormControl>
                </Grid> */}

                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px">
                        <Button onClick={save} variant="contained" style={{ marginRight: "10px" }} color="success" disabled={!edited || loading || !valid}>
                            {loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{ marginRight: "10px" }} /> : <SaveIcon style={{ marginRight: "10px" }} />} Spremi
                        </Button>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={(e) => {
                                e.stopPropagation();
                                setCanceled(true);
                                setFormOpened(false);
                            }}
                            disabled={loading}
                        >
                            <ExitToApp style={{ marginRight: "10px" }}></ExitToApp>Zatvori
                        </Button>
                    </Box>
                </Grid>

            </Grid>

            {blokAlert ? <AlertDialog title={"Blokiranje korisnika"} message={"Odabrali ste blokiranje korisnika. Pri prvom sljedećem pristupu evidencijama, računima, prijenosu ili izvještajima, korisnik će biti odjavljen automatski i više se neće moći prijaviti. Korisnički račun možete odblokirati u bilo kojem trenutku. Da li želite nastaviti s blokiranjem korisničkog računa?"} cancelLabel={"Otkaži blokiranje"} confirmLabel={"Nastavi"} cancelAction={() => setBlokAlert(false)} confirmAction={()=>setBlok()}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default KorisnikForm;
