import React, { useContext, useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Box, ClickAwayListener, Grid, Modal, Typography } from "@mui/material";
import api from "../util/api";
import globalContext from "./globalContext";
import AddEditDelForm from "./addEditDelForm";
import KontoForm from "./kontoForm";
import AlertDialog from "./alertdialog";
import ErrorAlert from "./errorAlert";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

function Konto(props) {
    
    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);
    
    const [loading, setLoading] = useState(true);
    const [konta, setKonta] = useState([]);
    const [currentKonto, setCurrentKonto] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [formOpened, setFormOpened] = useState(false);
    const [formKonto, setFormKonto] = useState(null);
    const [canceled, setCanceled] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        loadKonta();
    });

    function loadKonta() {
        api.get("konto/" + global.user.firmaID, loadKontaSuccess, loadKontaFail);
    }

    function loadKontaSuccess(data) {
        setKonta(data);
        setLoading(false);
    }

    function loadKontaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function openForm(type) {
        if (type == "add") {
            setFormKonto(null);
            setFormOpened(true);
        } else {
            setFormKonto(currentKonto);
            setFormOpened(true);
        }
    }

    useEffect(() => {if (!formOpened && !deleteAlert && !canceled) loadKonta()}, [formOpened, deleteAlert]);

    function deleteKonto() {
        setLoading(true);
        api.post("konto/delete/" + currentKonto.KontoID, null, deleteSuccess, deleteFail);
    }

    function deleteSuccess() {
        setCanceled(false);
        setDeleteAlert(false);
    }

    function deleteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
        setDeleteAlert(false);
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <AssessmentIcon fontSize="medium" style={{marginRight:"15px"}}></AssessmentIcon>
                        Konto
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{width:"100%", marginTop:"20px"}}>
                        <AddEditDelForm dataType="konto" data={konta} current={currentKonto} setCurrent={setCurrentKonto} add={() => openForm("add")} edit={() => openForm("edit")} del={() => setDeleteAlert(true)}></AddEditDelForm>
                    </Box>
                </Grid>
            </Grid>

            <Modal open={formOpened}>
                <Box sx={style} overflow={"auto"} >
                    <KontoForm formKonto={formKonto} setFormOpened={setFormOpened} setCanceled={setCanceled}></KontoForm>
                </Box>
            </Modal>

            {deleteAlert ? <AlertDialog title="Brisanje konta" message={"Jeste li sigurni da želite obrisati odabrani konto?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => {setCanceled(true); setDeleteAlert(false);}} confirmAction={deleteKonto}></AlertDialog> : null}
        
            <Modal className="modal" open={errorMessage != null}>
                <Box sx={style}>
                    <ErrorAlert message={errorMessage} close={() => setErrorMessage(null)}></ErrorAlert>
                </Box>
            </Modal>
        </ComponentContainer>
    );
}

export default Konto;