import { Autocomplete, Button, Divider, FormControl, FormLabel, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, Menu, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from "@mui/system";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import api from "../util/api";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from '@mui/icons-material/History';
import globalContext from "./globalContext";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function BlagajnaForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formBlagajna = props.formBlagajna;
    const poslovniProstorID = props.poslovniProstorID;
    const setFormOpened = props.setFormOpened;
    const fiskalna = props.fiskalna;
    const poslovniProstori = props.poslovniProstori;

    const [loading, setLoading] = useState(false);
    const [fiskalnaOznaka, setFiskalnaOznaka] = useState("");
    const [naziv, setNaziv] = useState("");
    const [aktivna, setAktivna] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [poslovniProstor, setPoslovniProstor] = useState(poslovniProstori ? poslovniProstori[0] : null);

    useConstructor(() => {
        ;
        if (formBlagajna) {
            setNaziv(formBlagajna.Naziv);
            setAktivna(formBlagajna.Aktivna);
            setFiskalnaOznaka(formBlagajna.FiskalnaOznaka);
        }
    });

    function save() {
        let resource = {
            BlagajnaID : formBlagajna ? formBlagajna.BlagajnaID : -1,
            Naziv : naziv,
            FiskalnaOznaka : fiskalnaOznaka,
            PoslovniProstorID : poslovniProstorID ? poslovniProstorID : poslovniProstor.PoslovniProstorID,
            Aktivna : aktivna,
            Fiskalna: fiskalna
        }
        setLoading(true);
        api.post("poslovniprostor/saveblagajna", resource, saveSuccess, saveFail);
    }

    function saveSuccess() {
        setLoading(false);
        props.loadPoslovniProstori();
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container className="padding-grid">
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formBlagajna ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <AddIcon fontSize="medium" style={{marginRight:"15px"}}></AddIcon>
                            )}
                            {formBlagajna ? (
                                "Uređivanje blagajne"
                            ) : (
                                "Nova blagajna"
                            )}
                        </div>
                    </Typography>
                </Grid>
                {poslovniProstorID == null ? (
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete
                                disablePortal
                                autoHighlight
                                options={poslovniProstori}
                                value={poslovniProstor}
                                getOptionLabel={(option) => option.Naziv}
                                disableClearable
                                onChange={(e,v) => {if (v) setPoslovniProstor(v)}}
                                renderInput={(params) => <TextField {...params} label="Poslovni prostor" size="small" style={{width:"100%"}} />}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField value={naziv} label="Naziv" inputProps={{ maxLength: 250 }} onChange={e => setNaziv(e.target.value)} error={!naziv} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField value={fiskalnaOznaka} disabled={formBlagajna && formBlagajna.KoristiSe} inputProps={{ maxLength: 250 }} label="Fiskalna oznaka" onChange={e => setFiskalnaOznaka(e.target.value)} error={!fiskalnaOznaka} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            options={[{Naziv:"Aktivna", Aktivna:true}, {Naziv:"Neaktivna", Aktivna:false}]}
                            value={aktivna ? {Naziv:"Aktivna", Aktivna:true} : {Naziv:"Neaktivna", Aktivna:false}}
                            getOptionLabel={(option) => option.Naziv}
                            disableClearable
                            onChange={(e,v) => {if (v) setAktivna(v.Aktivna)}}
                            renderInput={(params) => <TextField {...params} label="Aktivnost" size="small" style={{width:"99%"}} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <TextField value={fiskalna ? "Da" : "Ne"} label="Fiskalna" InputProps={{readOnly:true}} size="small"></TextField>
                    </FormControl>
                </Grid>
                {formBlagajna ? (
                    <Grid item xs={12} marginTop="5px" paddingLeft="12px !important">                                    
                        <FormLabel style={{ marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 2, width: "10em", backgroundColor: "white", position: "absolute", fontSize: "0.75em" }}>Vrste dokumenata</FormLabel>
                        <List dense sx={{ borderRadius: 1, border: 1, borderColor: "grey.400", "&:hover": { borderColor: "black" } }}>
                            {formBlagajna.VrsteRacuna.map((item, index) => {
                                return (
                                    <ListItem className="list-item" key={"listItem" + index}>
                                        <ListItemText primary={item}></ListItemText>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" color="success" style={{marginRight:"10px"}} disabled={!global.roles.includes("20") || !naziv || !fiskalnaOznaka}><SaveIcon style={{marginRight:"10px"}} /> Spremi</Button>
                        <Button variant="outlined" color="error" onClick={() => {setFormOpened(false);}} ><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>
            
            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default BlagajnaForm;