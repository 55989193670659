import { Autocomplete, Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import dateFormat, { masks } from "dateformat";
import api from "../util/api";
import TranslateIcon from '@mui/icons-material/Translate';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import CloseIcon from '@mui/icons-material/Close';
import Util from "../util/util";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function PrijevodRacunskeStavkeForm(props) {

    const useConstructor = Util.useConstructor();

    const close = props.close;
    const prijevodi = props.prijevodi;
    const setPrijevodi = props.setPrijevodi;
    const takeALook = props.takeALook;
    const opis = props.opis;
    const setOpis = props.setOpis;
    const firmaJezikID = props.firmaJezikID;

    const [loading, setLoading] = useState(false);
    const [pocetniPrijevodi, setPocetniPrijevodi] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        let newPrijevodi = JSON.parse(JSON.stringify(prijevodi));
        setPocetniPrijevodi(newPrijevodi);
    });

    function save() {
        let resource = {
        }
        setLoading(true);
        api.post("", resource, saveSuccess, saveFail);
    }

    function saveSuccess() {
        setLoading(false);
        close();
    }

    function saveFail() {
        setLoading(false);
    }

    function handleOpisChange(e, index) {
        let prijevodiNew = [...prijevodi];
        prijevodiNew[index].Prijevod = e.target.value;
        if (prijevodiNew[index].FirmaJezikID == firmaJezikID) {
            setOpis(e.target.value);
        }
        setPrijevodi(prijevodiNew);
    }

    function handleOdustaniClick() {
        setPrijevodi(pocetniPrijevodi);
        let i = pocetniPrijevodi.findIndex(p => p.FirmaJezikID == firmaJezikID);
        if (i >= 0) {
            setOpis(pocetniPrijevodi[i].Prijevod);
        }
        close();
    }

    return (
        <ComponentContainer>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            <TranslateIcon fontSize="medium" style={{marginRight:"15px"}}></TranslateIcon>
                            Izmjena opisa računske stavke i njegovih prijevoda
                        </div>
                    </Typography>
                </Grid>
                {prijevodi.map((p, i) => {
                    return (
                        <Grid key={i} item xs={12}>
                            <FormControl fullWidth>
                                <TextField label={"Opis - " + p.FirmaJezikNaziv} value={prijevodi[i].Prijevod} inputProps={{ maxLength: 255 }} onChange={e => handleOpisChange(e, i)} size="small" />
                            </FormControl>
                        </Grid>
                    );
                })}
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button variant="contained" color="success" disabled={takeALook} onClick={close} startIcon={<DoneOutlineIcon></DoneOutlineIcon>}>U redu</Button>
                        <Button variant="outlined" color="error" style={{marginLeft:"5px"}} onClick={handleOdustaniClick} startIcon={<CloseIcon></CloseIcon>}>odustani</Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default PrijevodRacunskeStavkeForm;