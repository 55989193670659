import { ClickAwayListener, Grid, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import PaymentsIcon from '@mui/icons-material/Payments';
import AddEditDelForm from "./addEditDelForm";
import { Box } from "@mui/system";
import NacinPlacanjaForm from "./nacinPlacanjaForm";
import AlertDialog from "./alertdialog";
import ErrorAlert from "./errorAlert";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

function NacinPlacanja(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [naciniPlacanja, setNaciniPlacanja] = useState([]);
    const [currentNacin, setCurrentNacin] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [formOpened, setFormOpened] = useState(false);
    const [formNacin, setFormNacin] = useState(null);
    const [initial, setInitial] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        loadNaciniPlacanja();
    });

    function loadNaciniPlacanja() {
        setLoading(true);
        api.get("nacinplacanja/getforfirma/" + global.user.firmaID, loadNaciniPlacanjaSuccess, loadNaciniPlacanjaFail);
    }

    function loadNaciniPlacanjaSuccess(data) {
        setNaciniPlacanja(data);
        setLoading(false);
    }

    function loadNaciniPlacanjaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function add() {
        setFormNacin(null);
        setFormOpened(true);
    }

    function edit() {
        setFormNacin(currentNacin);
        setFormOpened(true);
    }

    useEffect(() => {
        if (!formOpened && !initial) {
            loadNaciniPlacanja();
        } else {    
            setInitial(false);
        }
    }, [formOpened]);

    function deleteNacin() {
        setLoading(true);
        api.post("nacinplacanja/delete/" + currentNacin.NacinPlacanjaID, null, deleteSuccess, deleteFail);
    }

    function deleteSuccess() {
        setLoading(false);
        setDeleteAlert(false);
        loadNaciniPlacanja();
    }   

    function deleteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setDeleteAlert(false);
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <PaymentsIcon fontSize="medium" style={{marginRight:"15px"}}></PaymentsIcon>
                        Načini plaćanja
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ClickAwayListener onClickAway={() => setCurrentNacin(null)}>
                        <Box style={{width:"100%", marginTop:"20px"}}>
                            <AddEditDelForm dataType="naciniplacanja" data={naciniPlacanja} current={currentNacin} setCurrent={setCurrentNacin} add={add} edit={edit} del={() => setDeleteAlert(true)}></AddEditDelForm>
                        </Box>
                    </ClickAwayListener>
                </Grid>
            </Grid>

            <Modal open={formOpened}>
                <Box sx={style} overflow={"auto"} >
                    <NacinPlacanjaForm formNacin={formNacin} setFormOpened={setFormOpened} ></NacinPlacanjaForm>
                </Box>
            </Modal>

            {deleteAlert ? <AlertDialog title="Brisanje načina plaćanja" message={"Jeste li sigurni da želite obrisati odabrani način plaćanja?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => setDeleteAlert(false)} confirmAction={deleteNacin}></AlertDialog> : null}
        
            <Modal className="modal" open={errorMessage != null}>
                <Box sx={style}>
                    <ErrorAlert message={errorMessage} close={() => setErrorMessage(null)}></ErrorAlert>
                </Box>
            </Modal>
        </ComponentContainer>
    );
}

export default NacinPlacanja;