import React, { useState, useRef, useContext, useEffect } from "react";
import { Line } from "@ant-design/charts";
import { AccessAlarm, SettingsRemoteRounded, ThreeDRotation } from "@mui/icons-material";
import api from "../util/api";
import Button from "@mui/material/Button";
import util from "../util/util";
import GlobalContext from "../components/globalContext";
import ComponentContainer from "../components/componentContainer";
import TextField from "@mui/material/TextField";
import { FormControl, FormGroup } from "@mui/material";
import Box from "@mui/material/Box";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction";
import database from "../util/database";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import UnosAktivnostiForm from "./unosAktivnostiForm";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function Calendar() {
    const useConstructor = util.useConstructor();
    //const global = useContext(GlobalContext);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date().toString());
    const [data, setData] = useState(null);
    const [showUnos, setShowUnos] = useState(false);
    const [nepredmetneKategorije, setNepredmetneKategorije] = useState([]);
    const [klijenti, setKlijenti] = useState([]);
    const [predmeti, setPredmeti] = useState([]);
    const fullCalendar = useRef();

    useConstructor(() => {
        setLoading(true);
        let user = database.get("user");
        //api.get("evidencija/evdidencijeAndPredlosci/" + user.KorisnikID, success, fail);
        success("data");
    });

    function success(data) {
        setData([]);
        setNepredmetneKategorije([]);
        setKlijenti([]);
        setPredmeti([]);
        setLoading(false);
    }

    function fail(data) {
        setLoading(false);
    }

    function handleDateClick(arg) {
        setShowUnos(true);
        setSelectedDate(util.getDateFromDateTimeString(arg.date));
    }

    function onSaveUnos(data) {
        setShowUnos(false);
    }

    function onCancelUnos(data) {
        setShowUnos(false);
        alert("SA");
        fullCalendar.current.render();
    }

    function onSave(data) {
        setShowUnos(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Box sx={{ width: "100%", height: "100" }}>
                <FullCalendar
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    editable={true}
                    droppable={true}
                    dateClick={handleDateClick}
                    windowResize={ function(arg) {
                        alert('The calendar has adjusted to a window resize. Current view: ' + arg.view.type);
                      }}
                    events={[
                        { title: "09:00 - Chato instalacija", date: "2022-06-08" },
                        { title: "09:30 - Rad na projektu ChatoWeb", date: "2022-06-08" },
                    ]}
                />

                <Dialog onClose={() => setShowUnos(false)} open={showUnos}>
                    <DialogTitle>{selectedDate}</DialogTitle>
                    <UnosAktivnostiForm onSave={onSaveUnos} onCancel={onCancelUnos} nepredmetneKategorije={nepredmetneKategorije} klijenti={klijenti} predmeti={predmeti}></UnosAktivnostiForm>
                </Dialog>
            </Box>
        </ComponentContainer>
    );
}
export default Calendar;
