import { Autocomplete, Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import dateFormat, { masks } from "dateformat";
import api from "../util/api";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function TecajForm(props) {

    const formTecaj = props.formTecaj;
    const firmaValute = props.firmaValute;
    const close = props.close;
    const loadTecajevi = props.loadTecajevi;
    const datum = props.datum;
    const label = props.label

    const [loading, setLoading] = useState(false);
    const [firmaValuta, setFirmaValuta] = useState(formTecaj ? formTecaj.FirmaValuta : null);
    const [tecaj, setTecaj] = useState(formTecaj ? formTecaj.Tecaj : 0);
    const [errorMessage, setErrorMessage] = useState("");

    function save() {
        let resource = {
            TecajID : formTecaj ? formTecaj.TecajID : -1,
            FirmaID : firmaValuta.FirmaID,
            FirmaValutaID : firmaValuta.FirmaValutaID,
            Datum : datum,
            Tecaj : tecaj
        }
        setLoading(true);
        api.post("tecaj/add", resource, saveSuccess, saveFail);
    }

    function saveSuccess() {
        setLoading(false);
        loadTecajevi();
        close();
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            {"Uređivanje tečaja za datum " + dateFormat(datum, "dd.mm.yyyy.")}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl style={{ width: "100%" }} >
                        <Autocomplete size="small" 
                            disablePortal
                            options={firmaValute} 
                            onChange={(e,v) => setFirmaValuta(v)}
                            disableClearable
                            autoHighlight
                            getOptionLabel={option => option.ValutaNaziv + " (" + option.FirmaValutaNaziv + ")"}
                            value={firmaValuta}
                            disabled={formTecaj}
                            renderInput={(params) => <TextField size="small" {...params} label="Valuta" ></TextField>} 
                        />
                    </FormControl>
                </Grid><Grid item xs={12}>
                    <FormControl style={{ width: "100%" }} >
                        <TextField size="small" label={label || 'Tečaj'} inputProps={{step:"0.000001"}} value={tecaj} type="number" onChange={e => {if (!isNaN(e.target.value)) setTecaj(parseFloat(e.target.value))}} error={tecaj <= 0} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" color="success" style={{marginRight:"10px"}} disabled={tecaj <= 0}><SaveIcon style={{marginRight:"10px"}} /> Spremi</Button>
                        <Button variant="outlined" color="error" onClick={close} ><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default TecajForm;