import { Autocomplete, Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import api from "../util/api";
import EditIcon from '@mui/icons-material/Edit';
import AddchartIcon from '@mui/icons-material/Addchart';
import globalContext from "./globalContext";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';

function KontoForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formKonto = props.formKonto;
    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;

    const [loading, setLoading] = useState(true);
    const [naziv, setNaziv] = useState("");
    const [foreignKontoID, setForeignKontoID] = useState();
    const [mjestoTroskaID, setMjestoTroskaID] = useState();
    const [mjestoTroskaNaziv, setMjestoTroskaNaziv] = useState();
    const [redoslijed, setRedoslijed] = useState();
    const [edited, setEdited] = useState(false);
    const [valid, setValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        if (formKonto) {
            setNaziv(formKonto.Naziv);
            setForeignKontoID(formKonto.ForeignKontoID);
            setMjestoTroskaID(formKonto.MjestoTroskaForeignID);
            setMjestoTroskaNaziv(formKonto.MjestoTroskaNaziv);
            setRedoslijed(formKonto.Redoslijed);
        }
        setLoading(false);
    });

    function handleNazivChange(e) {
        setEdited(true);
        setNaziv(e.target.value);
    }

    function handleForeignKontoIDChange(e) {
        setEdited(true);
        setForeignKontoID(parseInt(e.target.value));
    }

    function handleMjestoTroskaIDChange(e) {
        setEdited(true);
        setMjestoTroskaID(parseInt(e.target.value));
    }

    function handleMjestoTroskaNazivChange(e) {
        setEdited(true);
        setMjestoTroskaNaziv(e.target.value);
    }

    function handleRedoslijedChange(e) {
        setEdited(true);
        setRedoslijed(parseInt(e.target.value));
    }

    function save() {
        setLoading(true);
        let konto = {
            KontoID : formKonto ? formKonto.KontoID : -1,
            Naziv : naziv,
            ForeignKontoID : foreignKontoID,
            MjestoTroskaForeignID : mjestoTroskaID,
            MjestoTroskaNaziv : mjestoTroskaNaziv,
            Redoslijed : redoslijed
        }
        api.post("konto/save", konto, saveSuccess, saveFail);
    }

    function saveSuccess() {
        setLoading(false);
        setEdited(false);
        setCanceled(false);
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        let formValid = naziv && foreignKontoID && foreignKontoID > 0 && (!mjestoTroskaID || mjestoTroskaID > 0) && redoslijed && redoslijed > 0;
        setValid(formValid);
    })

    return (
        <ComponentContainer loading={loading}>
            <Grid container className="padding-grid">
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formKonto ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <AddchartIcon fontSize="medium" style={{marginRight:"15px"}}></AddchartIcon>
                            )}
                            {formKonto ? (
                                "Uređivanje konta"
                            ) : (
                                "Novi konto"
                            )}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <TextField label="Naziv" value={naziv || ""} inputProps={{ maxLength: 50 }} onChange={handleNazivChange} error={!naziv} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <TextField type="number" label="Računovodstvena referenca" value={foreignKontoID || ""} onChange={handleForeignKontoIDChange} error={!foreignKontoID || foreignKontoID <= 0} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <TextField type="number" label="ID mjesta troška" value={mjestoTroskaID || ""} onChange={handleMjestoTroskaIDChange} error={mjestoTroskaID && mjestoTroskaID <= 0} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <TextField label="Naziv mjesta troška" inputProps={{ maxLength: 50 }} value={mjestoTroskaNaziv || ""} onChange={handleMjestoTroskaNazivChange} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <TextField type="number" label="Redoslijed" value={redoslijed || ""} onChange={handleRedoslijedChange} error={!redoslijed || redoslijed <= 0} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" style={{marginRight:"10px"}} color="success" disabled={!edited || !global.roles.includes("20") || loading || !valid}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <SaveIcon style={{marginRight:"10px"}} />} Spremi</Button>
                        <Button variant="outlined" color="error" onClick={() => {setCanceled(true); setFormOpened(false);}} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default KontoForm;