import React, { useContext, useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import AlertDialog from './alertdialog';
import ErrorIcon from '@mui/icons-material/Error';
import { Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, Modal, TextField, Typography } from "@mui/material";
import GavelIcon from '@mui/icons-material/Gavel';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from "@mui/system";
import AddEditDelForm from "./addEditDelForm";
import SudForm from "./sudForm";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

export default function Sudovi(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [sudovi, setSudovi] = useState([]);
    const [currentSud, setCurrentSud] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [formOpened, setFormOpened] = useState(false);
    const [sudForEdit, setSudForEdit] = useState(null);
    const [ukljuciDefaulte, setUkljuciDefaulte] = useState(false);

    useConstructor(() => {
        getSudovi();
    });

    function getSudovi() {
        setLoading(true);
        api.get("sud/getsudovi/" + global.user.firmaID, getSudoviSuccess, fail);
    }

    function getSudoviSuccess(data) {
        setSudovi(data);
        if (data.length > 0) {
            setCurrentSud(data[0]);
        }
        setLoading(false);
    }

    function fail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
        setDeleteAlert(false);
    }

    function add() {
        setSudForEdit(null);
        setFormOpened(true);
    }

    function edit() {
        setSudForEdit(currentSud);
        setFormOpened(true);
    }

    function deleteConfirmed() {
        setLoading(true);
        api.get("sud/deletesud/" + currentSud.SudID, deleteSuccess, fail);
    }

    function deleteSuccess() {
        setDeleteAlert(false);
        getSudovi();
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom component="div" style={{display:"flex", alignItems:"center"}}>
                        <GavelIcon fontSize="medium" style={{marginRight:"15px"}}></GavelIcon>
                        Sudovi
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FormControl fullWidth>
                        <TextField value={searchText} onChange={e => setSearchText(e.target.value)} InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }} size="small" variant="standard" label="Pretraga"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4} style={{display:"flex", justifyContent:"center"}}>
                    <FormControlLabel 
                        labelPlacement="start" 
                        label="Samo korisnički dodani sudovi"
                        control={<Checkbox checked={!ukljuciDefaulte} onChange={e => setUkljuciDefaulte(!e.target.checked)}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{width:"100%"}}>
                        <AddEditDelForm dataType="sudovi" disableDeleteButton={!currentSud || !currentSud.FirmaID} disableEdit={!currentSud || !currentSud.FirmaID} data={sudovi.filter(s => searchText == null || searchText == "" || s.Naziv.toLowerCase().includes(searchText.toLowerCase())).filter(s => ukljuciDefaulte ? true : s.FirmaID != null)} current={currentSud} setCurrent={setCurrentSud} add={add} edit={edit} del={() => setDeleteAlert(true)}></AddEditDelForm>
                    </Box>
                </Grid>
            </Grid>

            <Modal open={formOpened}>
                <Box sx={style} overflow={"auto"} >
                    <SudForm formSud={sudForEdit} getSudovi={getSudovi} close={() => setFormOpened(false)}></SudForm>
                </Box>
            </Modal>

            {deleteAlert ? <AlertDialog title="Brisanje suda" message={"Jeste li sigurni da želite obrisati odabrani sud?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() =>setDeleteAlert(false)} confirmAction={deleteConfirmed}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
        </ComponentContainer>
    );
}