import { Typography, Autocomplete, Button, FormControl, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import ComponentContainer from "./componentContainer";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const uvjeti = [
    { Naziv: "postoji specifikacija - kratica SP", Kratica: "SP" },
    { Naziv: "ne postoji specifikacija - kratica SNP", Kratica: "SNP" },
    { Naziv: "postoji napomena - kratica NP", Kratica: "NP" },
    { Naziv: "ne postoji napomena - kratica NNP", Kratica: "NNP" },
    { Naziv: "je račun domaći - kratica DR", Kratica: "DR" },
    { Naziv: "je račun strani - kratica SR", Kratica: "SR" },,
    { Naziv: "je ponuda - kratica PND", Kratica: "PND" },,
    { Naziv: "je predračun - kratica PRDRCN", Kratica: "PRDRCN" },
];

export default function ReportRjecnikDodajUvjetForm(props) {

    const close = props.close;
    const dodaj = props.dodaj;

    const [uvjet, setUvjet] = useState(uvjeti[0]);
    const [vrijednost, setVrijednost] = useState("");
    const [selectionStart, setSelectionStart] = useState();
    const [lastPosition, setLastPosition] = useState();

    const inputRef = useRef();

    function updateSelectionStart() {
        setSelectionStart(inputRef.current.selectionStart);
    }

    //useEffect(() => alert(selectionStart), [selectionStart]);

    function insertExpression(expression) {
        let newVrijednost = [vrijednost.slice(0, selectionStart), expression, vrijednost.slice(selectionStart)].join('');
        setVrijednost(newVrijednost);
        setLastPosition((selectionStart || 0) + expression.length);
    }

    useEffect(() => {
        if (lastPosition) {
            inputRef.current.focus();
        }
    }, [lastPosition]);

    return (
        <ComponentContainer>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            Uređivanje uvjetnog ispisa dijela sadržaja napomene
                        </div>
                    </Typography>
                </Grid>            
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete size="small" 
                            disablePortal
                            disableClearable
                            options={uvjeti} 
                            onChange={(e,v) => {if (v) setUvjet(v)}}
                            getOptionLabel={option => option.Naziv}
                            value={uvjet}
                            autoHighlight
                            ListboxProps={{ style: { maxHeight: '14rem' } }}
                            renderInput={(params) => <TextField sx={{width:"100%"}} size="small" {...params} label="Ako" error={!uvjet} />} 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField autoFocus label="dodaj" inputRef={inputRef} onFocus={() => {
                                    if (lastPosition != null) {
                                        inputRef.current.setSelectionRange(lastPosition, lastPosition);
                                        setLastPosition(null);
                                    }
                                }} onSelect={updateSelectionStart} value={vrijednost} onChange={e => setVrijednost(e.target.value)} multiline rows={2} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px">
                        <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[PocUsl]]]")}>Datum početka usluge</Button>
                        <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[ZavUsl]]]")}>Datum kraja usluge</Button>
                        <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[DP]]]")}>Datum plaćanja</Button>
                        <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[Mdl]]]")}>Model plaćanja</Button>
                        <Button variant="outlined" style={{marginRight:"5px"}} startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[PNB]]]")}>Poziv na broj</Button>
                        <Button variant="outlined" startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[Npmn]]]")}>Napomena</Button>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="5px">
                        <Button variant="outlined" startIcon={<AddIcon></AddIcon>} onClick={() => insertExpression("[[[BrDana]]]")}>Broj dana za plaćanje</Button>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{display:"flex", justifyContent:"flex-end", marginTop:"10px"}}>
                        <Button variant="contained" style={{marginRight:"5px"}} color="success" startIcon={<AddIcon></AddIcon>} onClick={() => {
                            dodaj("{{{" + uvjet.Kratica + "}" + vrijednost + "}}");
                            close();
                        }}>Dodaj</Button>
                        <Button variant="outlined" color="error" startIcon={<CloseIcon></CloseIcon>} onClick={close}>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>
        </ComponentContainer>
    );
}