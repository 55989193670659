import React, { useContext, useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Box, Button, ClickAwayListener, Grid, List, ListItem, ListItemText, Modal, Paper, Tooltip, Typography } from "@mui/material";
import api from "../util/api";
import globalContext from "./globalContext";
import AddEditDelForm from "./addEditDelForm";
import KorisnikForm from "./korisnikForm";
import AlertDialog from "./alertdialog";
import KorisnikAktivacijaForm from "./korisnikAktivacijaForm";
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import dateFormat, { masks } from "dateformat";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    padding:'15px',
    minWidth:'60%',
    maxHeight: '95%',
};

function Korisnik(props) {
    
    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);
    
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [formOpened, setFormOpened] = useState(false);
    const [form, setForm] = useState(null);
    const [canceled, setCanceled] = useState(true);
    const [odjeli,setOdjeli] = useState();
    const [korisnikEditData, setKorisnikEditData] = useState();
    const [dodavanjeKorisnika, setDodavanjeKorisnika] = useState(false);
    const [brisanjeKorisnika, setBrisanjeKorisnika] = useState(false);
    const [postojeciZahtjevi, setPostojeciZahtjevi] = useState([]);
    const [sakrijDeaktivirane, setSakrijDeaktivirane] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        load();
    });

    function load() {
        api.get("korisnici/edit/" + global.user.firmaID, loadSuccess, loadFail);
    }

    function loadSuccess(data) {
        setKorisnikEditData(data);
        setItems(data.Korisnici);
        setPostojeciZahtjevi(data.PostojeciZahtjevi);
        if(data.Korisnici && data.Korisnici.length > 0){
            setSelectedItem(data.Korisnici[0]);
        }
        setOdjeli(data.Odjeli);
        setLoading(false);
    }

    function loadFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function openForm(type) {
        if (type == "add") {
            setDodavanjeKorisnika(true);
        } else if (type == "del") {
            setBrisanjeKorisnika(true);
        } 
        else {
            setForm(selectedItem);
            setFormOpened(true);
        }
    }

    // useEffect(() => {
    //     if (!formOpened && !deleteAlert){
    //         if(!canceled){
    //             load();
    //         } else{
    //             if(items && items.length > 0){
    //                 //setSelectedItem(items[0]);
    //             }
    //         }
    //     }
    // }, [formOpened, deleteAlert, items]);

    function remove() {
        setLoading(true);
        api.post("Odjel/delete", {OdjelID: selectedItem.OdjelID}, deleteSuccess, deleteFail);
    }

    function deleteSuccess(data) {
        setCanceled(false);
        setDeleteAlert(false);

        if(!data.Success){
            alert(data.ErrorMessage);
        }
    }

    function deleteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <AssessmentIcon fontSize="medium" style={{marginRight:"15px"}}></AssessmentIcon>
                        Korisnici
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{width:"100%", marginTop:"20px"}}>
                        <AddEditDelForm dataType="korisnik" deleteText="Deaktiviraj" sakrijDeaktivirane={sakrijDeaktivirane} setSakrijDeaktivirane={setSakrijDeaktivirane} data={sakrijDeaktivirane ? items.filter(k => k.TipStatusaKorisnikaID == 1) : items} current={selectedItem} setCurrent={setSelectedItem} add={() => openForm("add")} edit={() => openForm("edit")} del={() => openForm("del")}></AddEditDelForm>
                    </Box>
                </Grid>
                {postojeciZahtjevi && postojeciZahtjevi.filter(z => !(z.TipAktivacijskeRadnjeID == 2 && z.KorisnikID == global.user.korisnikID)).length > 0 ? (
                    <Grid item xs={12}>
                        <Box style={{width:"100%"}}>
                            <Typography variant="h6" gutterBottom component="div" style={{marginTop:"15px", marginBottom:"15px"}}>
                                <PendingActionsIcon fontSize="small" style={{marginRight:"15px"}}></PendingActionsIcon>
                                Postojeći zahtjevi
                            </Typography>
                        </Box>
                        <Box style={{width:"100%"}}>
                            <ListItem className="list-title" key="zahtjevi-title" >
                                <Grid container>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Ime"} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Korisničko ime"} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"E-mail"} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Tip zahtjeva"} />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Tooltip title="Datum zahtjeva">
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Datum zahtjeva"} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Tooltip title="Datum primjene">
                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Datum primjene"} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Status"} />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <Paper variant="outlined" style={{width:"100%", maxHeight: 350, overflow: 'auto'}}>
                                <List dense>
                                    {postojeciZahtjevi.filter(z => !(z.TipAktivacijskeRadnjeID == 2 && z.KorisnikID == global.user.korisnikID)).map((zahtjev, index) => {
                                        return <ListItem className="list-item" style={{paddingTop:0, paddingBottom:0}} key={"zahtjev" + index}>
                                                <Grid container>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={zahtjev.Ime} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={zahtjev.Username} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={zahtjev.Email} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, color: zahtjev.TipAktivacijskeRadnjeID == 1 ? "green" : "red" }} primary={zahtjev.TipAktivacijskeRadnje} />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={dateFormat(zahtjev.Datum, "dd.mm.yyyy")} />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={dateFormat(zahtjev.DatumPrimjene, "dd.mm.yyyy")} />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true, color: zahtjev.TipStatusaAktivacijeID == 1 ? "black" : (zahtjev.TipStatusaAktivacijeID == 2 ? "blue" : (zahtjev.TipStatusaAktivacijeID == 3 ? "green" : "red")) }} primary={zahtjev.TipStatusaAktivacije} />
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                    })}
                                </List>
                            </Paper>
                        </Box>
                    </Grid>
                ) : null}
            </Grid>

            <Modal open={formOpened}>
                <Box sx={style} overflow="auto">
                    <KorisnikForm data={korisnikEditData} form={form} load={load} setFormOpened={setFormOpened} setCanceled={setCanceled}></KorisnikForm>
                </Box>
            </Modal>

            <Modal open={dodavanjeKorisnika || brisanjeKorisnika}>
                <Box sx={style} overflow={"auto"}>
                    <KorisnikAktivacijaForm aktivacija={dodavanjeKorisnika && !brisanjeKorisnika} close={() => {
                        setDodavanjeKorisnika(false);
                        setBrisanjeKorisnika(false);
                    }} currentID={selectedItem ? selectedItem.KorisnikID : null} loadKorisnici={load}></KorisnikAktivacijaForm>
                </Box>
            </Modal>

            {deleteAlert ? <AlertDialog title="Brisanje odjela" message={"Jeste li sigurni da želite obrisati odabrani odjel?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => {setCanceled(true); setDeleteAlert(false);}} confirmAction={remove}></AlertDialog> : null}
        
            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default Korisnik;