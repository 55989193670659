import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { NumericFormat } from "react-number-format";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputAdornment from '@mui/material/InputAdornment';

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    // floatValue: 23243423
    // formattedValue: "23.243.423"
    // value: "23243423"

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        id: props.id,
                        name: props.name,
                        floatValue: values.floatValue,
                        value: values.floatValue,
                        formattedValue: values.formattedValue,
                    },
                });
            }}
            thousandSeparator="."
            // isNumericString
            decimalSeparator=","
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function CurrencyTextField(props) {

    useEffect(() => {

    });

    const handleChange = (event) => {

        if(props.min){
            if(event.target.value < props.min) {    
                event.target.value = props.min;
            }
        }

        if(props.max){
            if(event.target.value > props.max) {    
                event.target.value = props.max;
            }
        }

        // setVals(event.target.value);
         
        // setVals(event.target.value);
         if(props.onChange){
             props.onChange(event);
         }
    };

    return (
        <TextField
            {...props}
            
            InputLabelProps={{ shrink: props.value || props.value == 0 ? true : false }}
            onChange={(e) => handleChange(e) }
            id={props.id}
            InputProps={{
                inputComponent: NumberFormatCustom,
                endAdornment: props.suffix ? (<InputAdornment position="end">{props.suffix}</InputAdornment>) : null
            }}
            
        />
    );
}
