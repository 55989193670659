import { Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import api from "../util/api";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function ArhivaStavkaForm(props) {

    const useConstructor = Util.useConstructor();
    const formStavka = props.formStavka;
    const setCanceled = props.setCanceled;
    const setFormOpened = props.setFormOpened;
    const arhivaID = props.arhivaID;
    const arhivaBroj = props.arhivaBroj;
    const oznaka = props.oznaka;

    const [arhivaOznaka, setArhivaOznaka] = useState("");
    const [loading, setLoading] = useState(true);
    const [opis, setOpis] = useState("");
    const [broj, setBroj] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        ;
        if (formStavka) {
            let length = arhivaBroj.toString().length;
            let padding = 6 - length;
            setArhivaOznaka(oznaka + "0".repeat(padding) + arhivaBroj + "/" + formStavka.Broj);
            setOpis(formStavka.Opis);
            setBroj(formStavka.Broj);
            setLoading(false);
        } else {
            api.get("arhivaStavka/nextbroj/" + arhivaID, loadBrojSuccess, loadBrojFail);
        }
        
    });

    function loadBrojSuccess(data) {
        setBroj(data);
        let length = arhivaBroj.toString().length;
        let padding = 6 - length;
        setArhivaOznaka(oznaka + "0".repeat(padding) + arhivaBroj + "/" + data)
        setLoading(false);
    }

    function loadBrojFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save() {
        let stavka = {
            ArhivaStavkaID : formStavka ? formStavka.ArhivaStavkaID : -1,
            ArhivaID : arhivaID,
            Broj : broj,
            Opis : opis
        }
        if (formStavka) {
            api.post("arhivaStavka/edit", stavka, saveSuccess, saveFail);
        } else {
            api.post("arhivaStavka/new", stavka, saveSuccess, saveFail);
        }
    }

    function saveSuccess() {
        setCanceled(false);
        setFormOpened(false);
        setLoading(false);

    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {"Arhiva " + arhivaOznaka}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl style={{ width: "100%" }}>
                        <TextField size="small" label="Opis" multiline rows={4} inputProps={{ maxLength: 500 }} value={opis} onChange={(e) => setOpis(e.target.value)} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" marginTop="10px">
                        <Button startIcon={<SaveIcon></SaveIcon>} color="success" variant="contained" disabled={false} onClick={save}>
                            Spremi
                        </Button>
                        <Button startIcon={<CloseIcon></CloseIcon>} color="error" variant="outlined" style={{marginLeft:"10px"}} onClick={() => {setCanceled(true); setFormOpened(false)}}>
                            Odustani
                        </Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );

}

export default ArhivaStavkaForm;