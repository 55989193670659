import React, { useContext, useEffect, useCallback, useState, useRef } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import AddEditDelForm from "./addEditDelForm";
import api from "../util/api";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { InputLabel, Autocomplete, Box, Button, ClickAwayListener, Divider, FormControl, Grid, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Modal, Paper, Radio, Select, TextField, Tooltip, Typography, FormControlLabel, Checkbox } from "@mui/material";
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import { validateDateTime } from "@mui/x-date-pickers/internals/hooks/validation/useDateTimeValidation";
import { isValidDate } from "@fullcalendar/core";
import { NodeExpandOutlined } from "@ant-design/icons";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import globalContext from "./globalContext";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import AlertDialog from "./alertdialog";
import UnosAktivnostiForm from "./unosAktivnostiForm";
import EvidencijaForm from "./evidencijaForm";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import database from "../util/database";
import dateFormat, { masks } from "dateformat";
import AutocompleteAsync from "../controls/autocompleteasync";
import ErrorIcon from '@mui/icons-material/Error';

const StyledTextField = styled(TextField)`
    .MuiOutlinedInput-input {
        cursor: pointer;
    }
    .MuiOutlinedInput-root {
        :hover fieldset {
            cursor: pointer;
        }
    }
`;
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    border: "2px solid #000",
    p: 6,
    maxHeight: "95%",
    maxidth: "99%",
    minWidth: "60%",
};

const periodOptions = [
    { Naziv: "Svi", Vrijednost: 1},
    { Naziv: "Za godinu", Vrijednost: 2},
    { Naziv: "Za mjesec", Vrijednost: 3},
    { Naziv: "Za datum", Vrijednost: 4},
    { Naziv: "Za razdoblje", Vrijednost: 5}
];

const monthNames = ["siječanj", "veljača", "ožujak", "travanj", "svibanj", "lipanj",
  "srpanj", "kolovoz", "rujan", "listopad", "studeni", "prosinac"
];

const CustomPaper = (props) => {
    return <Paper elevation={24} variant="outlined" style={{borderStyle:'solid', borderWidth:'2px'}} {...props} />;
  };

function Evidencije(props) {
    const useConstructor = Util.useConstructor();
    const isMobileDevice = Util.isMobileDevice();
    const global = useContext(globalContext);

    var periodOptions = [
        { KorisnikID: global.user.korisnikID, PeriodID: 1, Naziv: "1 dan", Vrijednost: 1, TipJediniceID: 1 },
        { KorisnikID: global.user.korisnikID, PeriodID: 2, Naziv: "1 tjedan", Vrijednost: 1, TipJediniceID: 2 },
        { KorisnikID: global.user.korisnikID, PeriodID: 3, Naziv: "2 tjedna", Vrijednost: 2, TipJediniceID: 2 },
        { KorisnikID: global.user.korisnikID, PeriodID: 4, Naziv: "1 mjesec", Vrijednost: 1, TipJediniceID: 3 },
        { KorisnikID: global.user.korisnikID, PeriodID: 5, Naziv: "1 godina", Vrijednost: 1, TipJediniceID: 4 },
        { KorisnikID: global.user.korisnikID, PeriodID: 6, Naziv: "Sve aktivnosti oduvijek", Vrijednost: 0, TipJediniceID: 1 },
        { KorisnikID: global.user.korisnikID, PeriodID: 7, Naziv: "Kalendarski odabir", Vrijednost: 0, TipJediniceID: 1 },
    ];

    const calendarPeriodOptions = [
        //{ Naziv: "Svi", Vrijednost: 1},
        { Naziv: "Za godinu", Vrijednost: 2},
        { Naziv: "Za mjesec", Vrijednost: 3},
        { Naziv: "Za datum", Vrijednost: 4},
        { Naziv: "Za razdoblje", Vrijednost: 5}
    ];
    
    const klijentiPredmeti = props.klijentiPredmeti || {
        Klijenti : [],
        Predmeti: [],
        KlijentiLastUpdate : null,
        PredmetiLastUpdate : null
    };
    const loadKlijentiAndPredmeti = props.loadKlijentiAndPredmeti;

    const [evidencije, setEvidencije] = useState(null);
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(null);
    const [dateValid, setDateValid] = useState(true);
    const [datumOd, setDatumOd] = useState(date);
    const [period, setPeriod] = useState(periodOptions[0]);
    const [initialPeriod, setInitialPeriod] = useState(true);
    const [periodHistory, setPeriodHistory] = useState(periodOptions[0]);
    const [customPeriod, setCustomPeriod] = useState(false);
    const [customPeriodHistory, setCustomPeriodHistory] = useState(false);
    const [customPeriodVrijednost, setCustomPeriodVrijednost] = useState(1);
    const [customPeriodVrijednostError, setCustomPeriodVrijednostError] = useState("");
    const [customPeriodJedinica, setCustomPeriodJedinica] = useState(1);
    const [currentEvidencija, setCurrentEvidencija] = useState(null);
    const [formEvidencija, setFormEvidencija] = useState(null);
    const [formOpened, setFormOpened] = useState(null);
    const [initialFormOpened, setInitialFormOpened] = useState(true);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [canceled, setCanceled] = useState(false);
    const [nepredmetneKategorije, setNepredmetneKategorije] = useState([]);
    const [kontakti, setKontakti] = useState([]);
    const [predlosci, setPredlosci] = useState([]);
    const [aktivnostDefTrajanjeMin, setAktivnostDefTrajanjeMin] = useState(0);
    const [tipoviOporezivosti, setTipoviOporezivosti] = useState([]);
    const [tipoviNaplatnosti, setTipoviNaplatnosti] = useState([]);
    const [firmaValute, setFirmaValute] = useState([]);
    const [tipoviEvidencije, setTipoviEvidencije] = useState([]);
    const [defaultFirmaValuta, setDefaultFirmaValuta] = useState(null);
    const [defaultTrosakTipOporezivosti, setDefaultTrosakTipOporezivosti] = useState(null);
    const [formTipEvidencijeID, setFormTipEvidencijeID] = useState(1);
    const [tipNaplatnostiNaplatnoBoja, setTipNaplatnostiNaplatnoBoja] = useState("0,0,0");
    const [tipNaplatnostiNenaplatnoBoja, setTipNaplatnostiNenaplatnoBoja] = useState("0,0,0");
    const [mogucePrebacitiAktivnostiBezCijene, setMogucePrebacitiAktivnostiBezCijene] = useState(false);
    const [defaultTarifnaAktivnostOmoguciIznosNula, setDefaultTarifnaAktivnostOmoguciIznosNula] = useState(false);
    const [cantBeDeleted, setCantBeDeleted] = useState(true);
    const [defaultSamoOtvoreniPredmeti, setDefaultSamoOtvoreniPredmeti] = useState(true);
    const [defaultSamoAktivniKlijenti, setDefaultSamoAktivniKlijenti] = useState(true);
    const [evidentiranoVrijeme, setEvidentiranoVrijeme] = useState("");
    const [ukupnoTroskova, setUkupnoTroskova] = useState("");
    const [tipEvidencijeID, setTipEvidencijeID] = useState(0);
    const [klijentSearchApi, setKlijentSearchApi] = useState();
    const [klijent, setKlijent] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [akcija, setAkcija] = useState(null);
    const periodMenuOpened = Boolean(anchorEl);
    const [prikazTroskovaZaSveZaposlenike, setPrikazTroskovaZaSveZaposlenike] = useState(false);
    const [deleteFile, setDeleteFile] = useState(false);
    const [tipPrikazaSatniceID, setTipPrikazaSatniceID] = useState(2);
    const [minsButtons, setMinsButtons] = useState([]);
    const [omoguciPohrani, setOmoguciPohrani] = useState(false);
    const [datumOd1, setDatumOd1] = useState(new Date());
    const [datumDo, setDatumDo] = useState(new Date());
    const [mjesec, setMjesec] = useState(new Date());
    const [datum, setDatum] = useState(new Date());
    const [godina, setGodina] = useState(new Date());
    const [calendarPeriodOption, setCalendarPeriodOption] = useState(calendarPeriodOptions.at(3));
    const [logoutAlert, setLogoutAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [brojRedovaPredlozaka, setBrojRedovaPredlozaka] = useState(1);
    const [evidencijaUPrenesenoRazdobljeMessage, setEvidencijaUPrenesenoRazdobljeMessage] = useState("");
    const [ukupnoTarifa, setUkupnoTarifa] = useState("");

    function handlePeriodClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handlePretraziAktivnostiClick() {
        setAnchorEl(null);
        setPeriodHistory(period);
        setCustomPeriodHistory(customPeriod);
        loadEvidencije(null);
    }

    useConstructor(() => {

        if (klijentiPredmeti.KlijentiLastUpdate != "" && klijentiPredmeti.KlijentiLastUpdate != null && klijentiPredmeti.PredmetiLastUpdate != "" && klijentiPredmeti.PredmetiLastUpdate != null) {
            let resource = {
                FirmaID : global.user.firmaID,
                KlijentiLastUpdate : klijentiPredmeti.KlijentiLastUpdate,
                PredmetiLastUpdate : klijentiPredmeti.PredmetiLastUpdate,
                KorisnikID : global.user.korisnikID
            }
            api.post("firma/checkeverything", resource, checkEverythingSuccess, checkEverythingFail);
        }


    });

    // useEffect(() => {
    //     window.onbeforeunload = confirmExit;
    //     function confirmExit()
    //     {
    //         return unsavedChanges.current ? "ALO" : null;
    //     }
    // }, []);

    function checkIfKlijentiOrPredmetiUnchangedSuccess(data) {
        if (data.KlijentiChanged || data.PredmetiChanged) {
            loadKlijentiAndPredmeti(global.user.firmaID, loadEvidencijeInfo, data.KlijentiChanged, data.PredmetiChanged);
        } else {
            loadEvidencijeInfo();
        }
    }

    function checkIfKlijentiOrPredmetiUnchangedFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function startLogout(){
        setLogoutAlert(false);
        Util.logout();
        window.location = "/#/login";
    }

    function checkEverythingSuccess(data) {
        if(data && (data.UserAccountValid == false || data.LogoutRequired)){
            setLoading(false);
            setLogoutAlert(true);
            return;          
        }

        if (data.CheckIfKlijentiOrPredmetiUnchangedResource == false) {
            loadKlijentiAndPredmeti(global.user.firmaID, loadEvidencijeInfo, data.KlijentiChanged, data.PredmetiChanged);
        } else {
            loadEvidencijeInfo();
        }
    }

    function checkEverythingFail() {

    }


    function loadEvidencijeInfo() {
        api.get("evidencija/info/" + global.user.korisnikID, (data) => loadRangeSuccess(data), loadRangeFail);
    }

    function loadRangeSuccess(data) {
        if(data.TipPregleda == 1){
            if (data.Vrijednost || data.TipJediniceID) {
                if (data.Vrijednost == 1 && data.TipJediniceID == 1) {
                    setPeriod(periodOptions[0]);
                    setPeriodHistory(periodOptions[0]);
                } else if (data.Vrijednost == 1 && data.TipJediniceID == 2) {
                    setPeriod(periodOptions[1]);
                    setPeriodHistory(periodOptions[1]);
                } else if (data.Vrijednost == 2 && data.TipJediniceID == 2) {
                    setPeriod(periodOptions[2]);
                    setPeriodHistory(periodOptions[2]);
                } else if (data.Vrijednost == 1 && data.TipJediniceID == 3) {
                    setPeriod(periodOptions[3]);
                    setPeriodHistory(periodOptions[3]);
                } else if (data.Vrijednost == 1 && data.TipJediniceID == 4) {
                    setPeriod(periodOptions[4]);
                    setPeriodHistory(periodOptions[4]);
                } else if (data.Vrijednost == 0 && data.TipJediniceID == 1) {
                    setPeriod(periodOptions[5]);
                    setPeriodHistory(periodOptions[5]);
                } else {
                    let newPeriod = {
                        KorisnikID: data.KorisnikID,
                        PeriodID: data.PeriodID,
                        Naziv: data.Naziv,
                        Vrijednost: data.Vrijednost,
                        TipJediniceID: data.TipJediniceID,
                    };
                    setPeriod(newPeriod);
                    setCustomPeriod(true);
                    setCustomPeriodVrijednost(data.Vrijednost);
                    setCustomPeriodJedinica(data.TipJediniceID);
                    setPeriodHistory(data);
                    setCustomPeriodHistory(newPeriod);
                }
            }
        }
        else{
            //kalendarski set, period options postavimo na zadnji odabir
            setPeriod(periodOptions[6]);
            setPeriodHistory(periodOptions[6]);
            //potrebno je postaviti još i kalendarske podatke
            setDatumOd1(data.DatumOd);
            setDatumDo(data.DatumDo);
            setMjesec(data.DatumOd);
            setGodina(data.DatumOd);
            setDatum(data.DatumOd);
            setCalendarPeriodOption(data.TipPregledaKalendara ? calendarPeriodOptions.find(x=>x.Vrijednost == data.TipPregledaKalendara) : calendarPeriodOptions.find(x=>x.Vrijednost == 3));
        }

        setNepredmetneKategorije(data.NepredmetneKategorije);
        setDate(new Date());
        setKontakti(data.Kontakti);
        setAktivnostDefTrajanjeMin(data.AktivnostDefTrajanjeMin);
        setTipoviOporezivosti(data.TipoviOporezivosti);
        setTipoviNaplatnosti(data.TipoviNaplatnosti);
        setFirmaValute(data.FirmaValute);
        setTipoviEvidencije(data.TipoviEvidencije);
        setDefaultFirmaValuta(data.DefaultFirmaValuta);
        setDefaultTrosakTipOporezivosti(data.DefaultTrosakTipOporezivosti);
        setTipNaplatnostiNaplatnoBoja(data.TipNaplatnostiNaplatnoBoja);
        setTipNaplatnostiNenaplatnoBoja(data.TipNaplatnostiNenaplatnoBoja);
        setMogucePrebacitiAktivnostiBezCijene(data.MogucePrebacitiAktivnostiBezCijene);
        setDefaultTarifnaAktivnostOmoguciIznosNula(data.DefaultTarifnaAktivnostOmoguciIznosNula);
        setDefaultSamoOtvoreniPredmeti(data.SamoOtvoreniPredmeti);
        setDefaultSamoAktivniKlijenti(data.SamoAktivniKlijenti);
        setPrikazTroskovaZaSveZaposlenike(data.EvidencijePrikazTroskovaZaSveZaposlenike)
        setMinsButtons(data.MinsButtons);
        setTipPrikazaSatniceID(data.TipPrikazaSatniceID);
        setOmoguciPohrani(data.OmoguciPohrani);
        setBrojRedovaPredlozaka(data.BrojRedovaPredlozaka);
        setEvidencijaUPrenesenoRazdobljeMessage(data.EvidencijaUPrenesenoRazdobljeMessage);

        //postaviti globalno tip prikaza satnice
        
        if(data.TipPrikazaSatniceID != global.user.tipPrikazaSatnice){
            let usr = database.get("user");
            usr.tipPrikazaSatnice = data.TipPrikazaSatniceID;
            database.set("user", usr);
            global.setUser(usr);    
        }

        
    }

    function loadRangeFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function loadEvidencije(oznacenaEvidencijaID) {
        if (date) {
            setLoading(true);
            var resourceDatumOd;
            switch (calendarPeriodOption.Vrijednost) {
                case 1:
                    resourceDatumOd = datumOd1;
                    break;
                case 2:
                    resourceDatumOd = godina;
                    break;
                case 3:
                    resourceDatumOd = mjesec;
                    break;
                case 4:
                    resourceDatumOd = datumOd1;
                    break;
                case 5:
                    resourceDatumOd = datumOd1;
                    break;
            }            
            let resource = {
                KorisnikID: global.drugiZaposlenik.KorisnikID,
                Date: date,
                Range: period,
                TipEvidencijeID: tipEvidencijeID,
                KlijentID: klijent ? klijent.KlijentID : 0,
                DatumOd: resourceDatumOd,
                DatumDo: datumDo,
                TipPregledaKalendara: calendarPeriodOption.Vrijednost
            };

            api.post("evidencija", resource, data => loadEvidencijeSuccess(data, oznacenaEvidencijaID), loadEvidencijeFail);
        }
    }

    function loadEvidencijeSuccess(data, oznacenaEvidencijaID) {
        setEvidencije(data.Evidencije);
        if (oznacenaEvidencijaID && data.Evidencije.find(e => e.EvidencijaID == oznacenaEvidencijaID) != null) {
            setCurrentEvidencija(data.Evidencije.find(e => e.EvidencijaID == oznacenaEvidencijaID));
        } else if (data.Evidencije.length > 0) {
            setCurrentEvidencija(data.Evidencije[data.Evidencije.length - 1]);
        } else {
            setCurrentEvidencija(null);
        }
        setDatumOd(data.DatumOd);
        setEvidentiranoVrijeme(data.EvidentiranoVrijeme);
        setUkupnoTroskova(data.UkupnoTroskova);
        setUkupnoTarifa(data.UkupnoTarifa);
        savePrefferedPeriod();
    }

    function loadEvidencijeFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function isDateValid(date) {
        if (!(date instanceof Date && !isNaN(date))) {
            setDateValid(false);
            return false;
        } else {
            if (date > new Date(1753, 1, 1) && date <= new Date()) {
                setDateValid(true);
                return true;
            } else {
                setDateValid(false);
                return false;
            }
        }
    }

    function handleDatumChange(v) {
        if (isDateValid(v)) {
            setDate(v);
        }
    }

    function handleTipEvidencijeChange(e) {
        
        setTipEvidencijeID(e.target.value)
    }

    function handleCustomPeriodVrijednostChange(e) {
        if (parseInt(e.target.value) > 0) {
            setCustomPeriodVrijednostError("");
        } else if (e.target.value == "") {
            setCustomPeriodVrijednostError("");
        } else {
            setCustomPeriodVrijednostError("Period ne može biti negativan broj ili nula.");
        }
        setCustomPeriodVrijednost(e.target.value);
    }

    function handleCustomPeriodJedinicaChange(e) {
        setCustomPeriodJedinica(e.target.value);
    }

    useEffect(() => loadEvidencije(null), [date, klijent, tipEvidencijeID]);

    // useEffect(() => setInitialFormOpened(false), [formOpened]);
    // useEffect(() => {
    //     if (!formOpened && !initialFormOpened && !canceled) loadEvidencije();
    // }, [formOpened]);

    useEffect(() => {
        if (customPeriod) setNewCustomPeriod();
    }, [customPeriodVrijednost, customPeriodJedinica]);

    function setNewCustomPeriod() {
        if (parseInt(customPeriodVrijednost) > 0 && customPeriodJedinica) {
            let newCustomPeriod = {
                KorisnikID: global.user.korisnikID,
                PeriodID: 7,
                Naziv: "Prilagođeno",
                Vrijednost: customPeriodVrijednost,
                TipJediniceID: customPeriodJedinica,
            };
            setCustomPeriod(true);
            setPeriod(newCustomPeriod);
        }
    }

    function savePrefferedPeriod() {
        var resourceDatumOd;
        switch (calendarPeriodOption.Vrijednost) {
            case 1:
                resourceDatumOd = datumOd1;
                break;
            case 2:
                resourceDatumOd = godina;
                break;
            case 3:
                resourceDatumOd = mjesec;
                break;
            case 4:
                resourceDatumOd = datumOd1;
                break;
            case 5:
                resourceDatumOd = datumOd1;
                break;
        }   
        
        let resource = {
            KorisnikID: global.user.korisnikID,
            Vrijednost: period.Vrijednost,
            TipJediniceID: period.TipJediniceID,
            TipPregleda: period.PeriodID == 7 ? 2 : 1,
            DatumOd: resourceDatumOd,
            DatumDo: datumDo,
            TipPregledaKalendara: calendarPeriodOption.Vrijednost
        };
        api.post("evidencija/range", resource, savePrefferedPeriodSuccess, savePrefferedPeriodFail);
    }

    function savePrefferedPeriodSuccess() {
        setLoading(false);
    }

    function savePrefferedPeriodFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function openForm(akcija, tipEvidencijeID) {
        setAkcija(akcija);
        if (akcija == "add") {
            setFormEvidencija(null);
            setFormTipEvidencijeID(tipEvidencijeID);
        } else if (akcija == "edit") {
            setFormEvidencija(currentEvidencija);
            setFormTipEvidencijeID(currentEvidencija.TipEvidencijeID);
        }
        setFormOpened(true);
    }

    function deleteConfirmed() {
        setLoading(true);
        api.get("evidencija/delete?evidencijaID=" + currentEvidencija.EvidencijaID + "&delFile=" + deleteFile, deleteSuccess, deleteFail);
        //api.post("evidencija/delete/" + currentEvidencija.EvidencijaID, null, deleteSuccess, deleteFail);
    }

    function deleteSuccess() {
        //setOznacenaEvidencija(evidencije.length > 1 ? evidencije[evidencije.length - 2].EvidencijaID : 0);
        setDeleteAlert(false);
        setDeleteFile(false);
        loadEvidencije(null);
    }

    function deleteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function deleteCanceled() {
        setDeleteAlert(false);
    }

    function onKlijentInputChange(e, val){
        setKlijentSearchApi("klijent/search?firmaID=" + database.get("user").firmaID + "&query=" + val);
    }

    function onKlijentChange(e, val){
        setKlijent(val);
    }

    useEffect(() => {
        if (currentEvidencija) {
            if (global.user.tipPrikazaSatnice != 1 || currentEvidencija.TipEvidencijeID == 2) {
                setCantBeDeleted(false);
            } else {
                let aktivnostiNaDatum = [...evidencije].filter((e) => e.TipEvidencijeID == 1 && new Date(e.PocetakRada).getFullYear() == new Date(currentEvidencija.PocetakRada).getFullYear() && new Date(e.PocetakRada).getMonth() == new Date(currentEvidencija.PocetakRada).getMonth() && new Date(e.PocetakRada).getDate() == new Date(currentEvidencija.PocetakRada).getDate()).sort((e) => new Date(e.PocetakRada));
                setCantBeDeleted(aktivnostiNaDatum[aktivnostiNaDatum.length - 1].EvidencijaID != currentEvidencija.EvidencijaID);
            }
        } else {
            setCantBeDeleted(true);
        }
    }, [currentEvidencija]);

    
    const handleKeyPressShorcuts = useCallback(
        (event) => {
            if(!formOpened){
                if (event.altKey) {
                    let charCode = String.fromCharCode(event.which).toLowerCase();
                    if (charCode == "d") {
                        event.preventDefault();
                        if (!loading) {
                            openForm("add", 1);
                        }
                    }
                    if (charCode == "t") {
                        event.preventDefault();
                        if (!loading) {
                            openForm("add", 2);
                        }
                    }                    
                }    
            }
        },
        [loading, formOpened]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPressShorcuts);

        return () => {
            document.removeEventListener("keydown", handleKeyPressShorcuts);
        };
    }, [handleKeyPressShorcuts]);


    function handleDatumOdChange(v) {
        if (!isNaN(new Date(v))) {
            setDatumOd1(v)
        };
    }

    function handleDatumDoChange(v) {
        if (!isNaN(new Date(v))) {
            setDatumDo(v);
        }
    }
    
    function getLabel(){
        return periodOptions[6].Naziv + '(mjesec)';
    }
    function setPeriodLabel(){
        var retrn = "";
        if (period && period.PeriodID == 6) {
            retrn = "";
        } else if (period && period.PeriodID != 7) {
            retrn = "Period: " + datumOd && date ? ("od " + dateFormat(datumOd, "dd.mm.yyyy.") + " do " + dateFormat(date, "dd.mm.yyyy.")) : "";
        } else {
            if (datumOd1) {
                let dteFrom = new Date(datumOd1);
                //console.log(dteFrom);
                if(calendarPeriodOption.Vrijednost == 2){
                    retrn = "Period: " + dteFrom.getFullYear() + ". godina";
                }    
                if(calendarPeriodOption.Vrijednost == 3){
                    //retrn = "Period: ";
                    retrn = "Period: " + monthNames[dteFrom.getMonth()] + " " + dteFrom.getFullYear() + ". godine";
                }    
                if(calendarPeriodOption.Vrijednost == 4){
                    //retrn = "Period: ";
                    retrn = "Datum: " + dteFrom.getDate() + ". "  + monthNames[dteFrom.getMonth()] + " " + dteFrom.getFullYear() + ". godine";
                }    
                if(calendarPeriodOption.Vrijednost == 5){
                    retrn = "Period: " + "od " + dateFormat(datumOd1, "dd.mm.yyyy.") + " do " + dateFormat(datumDo, "dd.mm.yyyy.");
                }    
            } else {
                retrn = "Period: "
            }
        }
        return retrn;
    }

    function saveSamoOtvoreniPredmeti(samoOtvoreniPredmeti){
            api.get("korisnik/spremidefsamootvorenipredmeti/" + samoOtvoreniPredmeti, (data) => setSamoOtvoreniSuccess(samoOtvoreniPredmeti))
        }

    function setSamoOtvoreniSuccess(samoOtvoreniPredmeti){
        setDefaultSamoOtvoreniPredmeti(samoOtvoreniPredmeti);
    }

    function saveSamoAktivniKlijenti(samoAktivniKlijenti){
        api.get("korisnik/spremidefsamoaktivniklijenti/" + samoAktivniKlijenti, (data) => setSamoAktivniSuccess(samoAktivniKlijenti))
    }

    function setSamoAktivniSuccess(samoAktivniKlijenti){
        setDefaultSamoAktivniKlijenti(samoAktivniKlijenti);
    }

    function gotoKlijenti(klijent){
        if(klijent){
            window.location = "#/klijenti?klijentID=" + klijent.KlijentID; 
        }
        else{
            window.location = "#/klijenti"; 
        }
        global.setSelectedTab("klijenti");        
    }

    function gotoPredmeti(predmet){
        if(predmet){
            window.location = "#/predmeti?predmetID=" + predmet.PredmetID + "&klijentID=" + predmet.KlijentID; 
        }
        else{
            window.location = "#/predmeti"; 
        }
        global.setSelectedTab("predmeti");        
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container rowSpacing={{ xs: 1, sm: 1, md: 2 }} columnSpacing={1}>
                <Grid item xs={12} sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
                    <Typography variant="h4" gutterBottom component="div" style={{ width: "100%" }}>
                        <QueryBuilderIcon fontSize="medium" style={{ marginRight: "15px" }}></QueryBuilderIcon>
                        Evidencija aktivnosti i troškova
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ display: { xs: "block", sm: "block", md: "none" } }} marginBottom="5px">
                    <Typography variant="h5" gutterBottom component="div" style={{ width: "100%", fontWeight: "bold" }}>
                        <QueryBuilderIcon fontSize="small" style={{ marginRight: "15px" }}></QueryBuilderIcon>
                        Evidencija
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker label="Datum unosa evidencije" value={date} inputFormat="dd.MM.yyyy" maxDate={new Date()} onChange={handleDatumChange} renderInput={(params) => <TextField style={{ width: "99%" }} {...params} size="small" error={!dateValid} helperText={dateValid ? "" : "Pogrešan format datuma!"} />} />
                    </LocalizationProvider>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
                        <StyledTextField
                            id="basic-button"
                            fullWidth
                            aria-controls={periodMenuOpened ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={periodMenuOpened ? "true" : undefined}
                            onClick={handlePeriodClick}
                            size="small"
                            variant="outlined"
                            aria-readonly
                            label="Opcije perioda prikaza evidencija"
                            value={period ? (period.PeriodID != 7 ? (period.Naziv || "") : (period.Naziv + ' (' + calendarPeriodOption.Naziv + ')')) : ""}
                            style={{ cursor: "pointer" }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment style={{ cursor: "pointer" }} position="end">
                                        {periodMenuOpened ? <KeyboardArrowUpIcon></KeyboardArrowUpIcon> : <KeyboardArrowDownIcon></KeyboardArrowDownIcon>}
                                    </InputAdornment>
                                ),
                            }}
                        ></StyledTextField>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={periodMenuOpened}
                            MenuListProps={{
                                "aria-labelledby": "basic-button",
                            }}
                            onClose={() => {
                                setAnchorEl(null);
                                setPeriod(periodHistory);
                                setCustomPeriod(customPeriodHistory);
                            }}
                        >
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriod(periodOptions[0]);
                                    setCustomPeriod(false);
                                }}
                            >
                                <Radio checked={period.PeriodID == 1}></Radio>
                                <ListItemText>{periodOptions[0].Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriod(periodOptions[1]);
                                    setCustomPeriod(false);
                                }}
                            >
                                <Radio checked={period.PeriodID == 2}></Radio>
                                <ListItemText>{periodOptions[1].Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriod(periodOptions[2]);
                                    setCustomPeriod(false);
                                }}
                            >
                                <Radio checked={period.PeriodID == 3}></Radio>
                                <ListItemText>{periodOptions[2].Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriod(periodOptions[3]);
                                    setCustomPeriod(false);
                                }}
                            >
                                <Radio checked={period.PeriodID == 4}></Radio>
                                <ListItemText>{periodOptions[3].Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriod(periodOptions[4]);
                                    setCustomPeriod(false);
                                }}
                            >
                                <Radio checked={period.PeriodID == 5}></Radio>
                                <ListItemText>{periodOptions[4].Naziv}</ListItemText>
                            </MenuItem>
                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriod(periodOptions[5]);
                                    setCustomPeriod(false);
                                }}
                            >
                                <Radio checked={period.PeriodID == 6}></Radio>
                                <ListItemText>{periodOptions[5].Naziv}</ListItemText>
                            </MenuItem>

                            <MenuItem style={{ cursor: "pointer" }} onClick={() => setNewCustomPeriod()} onKeyDown={(e) => e.stopPropagation()}>
                                <Radio checked={customPeriod}></Radio>
                                <TextField
                                    value={customPeriodVrijednost}
                                    size="small"
                                    type="number"
                                    onChange={handleCustomPeriodVrijednostChange}
                                    error={customPeriodVrijednostError != ""}
                                    sx={{
                                        "& legend": { display: "none" },
                                        "& fieldset": { top: 0 },
                                    }}
                                ></TextField>
                                <Select
                                    size="small"
                                    value={customPeriodJedinica}
                                    onChange={handleCustomPeriodJedinicaChange}
                                    sx={{
                                        "& legend": { display: "none" },
                                        "& fieldset": { top: 0 },
                                    }}
                                >
                                    <MenuItem value={1}>dan</MenuItem>
                                    <MenuItem value={2}>tjedan</MenuItem>
                                    <MenuItem value={3}>mjesec</MenuItem>
                                    <MenuItem value={4}>godina</MenuItem>
                                </Select>
                            </MenuItem>

                            <MenuItem
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setPeriod(periodOptions[6]);
                                    setCustomPeriod(false);
                                }}
                            >
                                <Radio checked={period.PeriodID == 7}></Radio>
                                <ListItemText>{periodOptions[6].Naziv}</ListItemText>
                            </MenuItem>


                            {period.PeriodID == 7 ? (
                                <div>

                                <FormControl fullWidth>
                                    <MenuItem><Autocomplete style={{width:"100%"}}
                                        PaperComponent={CustomPaper}
                                        disablePortal
                                        options={calendarPeriodOptions}
                                        getOptionLabel={(option) => option.Naziv}
                                        value={calendarPeriodOption}
                                        onChange={(e,v) => {if (v) setCalendarPeriodOption(v)}}
                                        renderInput={(params) => <TextField {...params} label="Vremensko razdoblje"  size="small"/>}
                                        // renderOption={(props, option) => {
                                        //     return (
                                        //     <span {...props} style={{ backgroundColor: 'beige' }}>
                                        //         {option.Naziv}
                                        //     </span>
                                        //     );
                                        // }}                                        
                                    /></MenuItem>
                                </FormControl> 
                                {calendarPeriodOption.Vrijednost == 2 ? (
                                    <MenuItem>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                            <DatePicker
                                                views={['year']}
                                                label="Godina"
                                                value={godina}
                                                inputFormat="yyyy"
                                                onChange={(v) => {setGodina(v); setDatumOd1(v);}}
                                                renderInput={(params) => <TextField style={{width:"99%"}} {...params} onKeyDown={e => e.stopPropagation()} size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </MenuItem>
                                ) : null}
                                {calendarPeriodOption.Vrijednost == 3 ? (
                                    <MenuItem>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                            <DatePicker
                                                views={['year', 'month']}
                                                label="Mjesec"
                                                value={mjesec}
                                                inputFormat="MM.yyyy"
                                                onChange={(v) => {setMjesec(v); setDatumOd1(v);}}
                                                renderInput={(params) => <TextField style={{width:"99%"}} {...params} onKeyDown={e => e.stopPropagation()} size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </MenuItem>
                                ) : null}
                                {calendarPeriodOption.Vrijednost == 4 ? (
                                    <MenuItem>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                            <DatePicker
                                                label="Datum"
                                                value={datumOd1}
                                                inputFormat="dd.MM.yyyy"
                                                onChange={(v) => setDatumOd1(v)}
                                                renderInput={(params) => <TextField style={{width:"99%"}} {...params} onKeyDown={e => e.stopPropagation()} size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </MenuItem>
                                ) : null}
                                {calendarPeriodOption.Vrijednost == 5 ? (
                                    <MenuItem>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                            <DatePicker
                                                label="Od"
                                                value={datumOd1}
                                                inputFormat="dd.MM.yyyy"
                                                onChange={handleDatumOdChange}
                                                renderInput={(params) => <TextField style={{width:"99%"}} {...params} onKeyDown={e => e.stopPropagation()} size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </MenuItem>
                                ) : null}
                                {calendarPeriodOption.Vrijednost == 5 ? (
                                    <MenuItem>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                            <DatePicker
                                                label="Do"
                                                value={datumDo}
                                                inputFormat="dd.MM.yyyy"
                                                onChange={handleDatumDoChange}
                                                renderInput={(params) => <TextField style={{width:"99%"}} {...params} onKeyDown={e => e.stopPropagation()} size="small" />}
                                            />
                                        </LocalizationProvider>
                                    </MenuItem>
                                ) : null}


                                </div>
                            ) : null}
                            
                            
                            <Divider />
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    color="error"
                                    onClick={() => {
                                        setAnchorEl(null);
                                        setPeriod(periodHistory);
                                        setCustomPeriod(customPeriodHistory);
                                    }}
                                    style={{ marginRight: "10px" }}
                                >
                                    Zatvori
                                </Button>
                                <Button variant="outlined" onClick={handlePretraziAktivnostiClick}>
                                    <SearchIcon style={{ marginRight: "5px" }} />
                                    Pretraži evidencije
                                </Button>
                            </div>
                        </Menu>
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                    <FormControl fullWidth>
                        <InputLabel>Tip evidencije</InputLabel>
                        <Select name="tipEvidencijeID" size="small" value={tipEvidencijeID} label={"Tip evidencije"} onChange={handleTipEvidencijeChange}>
                                    <MenuItem key={"et0"} value={0}>
                                        Sve
                                    </MenuItem>
                                    <MenuItem key={"et1"} value={1}>
                                        Aktivnosti
                                    </MenuItem>
                                    {prikazTroskovaZaSveZaposlenike && global.roles.includes("3") ? (
                                        <Divider></Divider>
                                    ) : null}
                                    <MenuItem key={"et2"} value={2}>
                                        {prikazTroskovaZaSveZaposlenike && global.roles.includes("3") ? 'Troškovi odabranog korisnika' : 'Troškovi'}
                                    </MenuItem>
                                    {prikazTroskovaZaSveZaposlenike && global.roles.includes("3") ? (
                                        <MenuItem key={"et3"} value={3}>
                                            Troškovi svih korisnika
                                        </MenuItem>                                        
                                    ) : null}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                 
                        <AutocompleteAsync 
                            api={klijentSearchApi} 
                            onChange={onKlijentChange} 
                            onInputChange={onKlijentInputChange} 
                            size="small" 
                            value={klijent} 
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 || option.KlijentID == 0 ? "black" : "red"}}>
                                    {option.Broj + " - " + option.Naziv}
                                  </li>
                                );
                            }}
                            filterOptions={(options, state) => options.filter(option => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            name="Klijent" 
                            renderInput={(params) => <TextField {...params} sx={{input:{color: klijent && klijent.TipStatusaKlijentaID != 1 && klijent.KlijentID != 0 ? "red" : "black"}}} label="Klijent" />} 
                        />
                  
                </Grid>
                

                {/* <Grid item xs={12} sm={12} md={12}>
                    <FormControl fullWidth sx={{marginTop:"0px !important"}}>
                        <TextField size="small" label="Period prikaza evidencija" value={datumOd && date ? ("od " + dateFormat(datumOd, "dd.mm.yyyy.") + " do " + dateFormat(date, "dd.mm.yyyy.")) : "/"}></TextField>
                    </FormControl>
                </Grid>  */}

                <Grid item xs={12} sm={12} md={12} style={{textAlign:"center"}}>
                    {setPeriodLabel()}
                </Grid> 


                {global.drugiZaposlenik && global.drugiZaposlenik.Prava.includes(2) ? (
                    <Grid item xs={12}>
                        <Box style={{ width: "100%" }}>
                            <AddEditDelForm data={evidencije} dataType="evidencije" disableAddButton={global.drugiZaposlenik && global.drugiZaposlenik.TipStatusaKorisnikaID != 1} current={currentEvidencija} setCurrent={setCurrentEvidencija} add={() => openForm("add", 1)} addTrosak={() => openForm("add", 2)} edit={() => openForm("edit")} del={() => setDeleteAlert(true)} disableDeleteButton={!currentEvidencija || currentEvidencija.EvidencijaDetalj.filter((ed) => ed.BrojPrijenosa != null).length > 0 || cantBeDeleted} disableEdit={!currentEvidencija || currentEvidencija.EvidencijaDetalj.filter((ed) => ed.BrojPrijenosa != null).length > 0} evidentiranoVrijeme={evidentiranoVrijeme} ukupnoTroskova={ukupnoTroskova} ukupnoTarifa={ukupnoTarifa}></AddEditDelForm>
                            {currentEvidencija && currentEvidencija.TipPredmetnostiID == 1 ? (
                                <Box style={{ width: "100%", marginTop: "10px" }}>
                                    <ListItem className="list-title" key={"listHeader"} style={{ backgroundColor: "lightblue" }}>
                                        <Grid container>
                                            <Grid item xs={1}>
                                                <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={"Tip"}></ListItemText>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={"Broj"}></ListItemText>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={"Predmet"}></ListItemText>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={"Klijent"}></ListItemText>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <ListItemText style={{ paddingLeft: "15px" }} primaryTypographyProps={{ noWrap: true }} primary={"Tip oporezivosti"}></ListItemText>
                                            </Grid>
                                        </Grid>
                                    </ListItem>
                                    <Paper variant="outlined" style={{ width: "100%", maxHeight: 100, overflow: "auto" }}>
                                        <List dense>
                                            {currentEvidencija.EvidencijaDetalj.map((detalj, index) => {
                                                return (
                                                    <ListItem className="list-item" style={{ paddingTop: 0, paddingBottom: 0 }} key={"listItem" + index}>
                                                        <Grid container>
                                                            <Grid item xs={1} display="flex" alignItems="center">
                                                                <Tooltip title={detalj.TipNaplatnostiID == 1 ? "Naplatno" : "Nenaplatno"} enterDelay={300} enterNextDelay={300}>
                                                                    <ListItemIcon style={{ paddingLeft: "15px" }}>
                                                                        <Brightness1Icon sx={{ color: "rgb(" + (detalj.TipNaplatnostiID == 1 ? tipNaplatnostiNaplatnoBoja : tipNaplatnostiNenaplatnoBoja) + ")" }}></Brightness1Icon>
                                                                    </ListItemIcon>
                                                                </Tooltip>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <ListItemText style={{ paddingLeft: "15px", color: (detalj.PredmetTipStanjaPredmetaID !== 1 ? "red" : (detalj.PredmetTipNaplateID == 1 ? "black" : (detalj.PredmetTipNaplateID == 2 ? "magenta" : "green")))}} primaryTypographyProps={{ noWrap: true }} primary={detalj.PredmetBroj}></ListItemText>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <ListItemText onClick={() => global.roles.includes("1") && currentEvidencija.TipPredmetnostiID == 1 ? gotoPredmeti(klijentiPredmeti.Predmeti.find(p => p.PredmetID == detalj.ReferencaID)) : null} style={{ paddingLeft: "15px", color: (detalj.PredmetTipStanjaPredmetaID !== 1 ? "red" : (detalj.PredmetTipNaplateID == 1 ? "black" : (detalj.PredmetTipNaplateID == 2 ? "magenta" : "green")))}} primaryTypographyProps={{ noWrap: true }} primary={detalj.ReferencaNaziv}></ListItemText>
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <ListItemText onClick={() => global.roles.includes("1") && currentEvidencija.TipPredmetnostiID == 1 ? gotoKlijenti(klijentiPredmeti.Klijenti.find(k => k.KlijentID == klijentiPredmeti.Predmeti.find(p => p.PredmetID == detalj.ReferencaID).KlijentID)) : null} style={{ paddingLeft: "15px", color: (detalj.PredmetTipStanjaPredmetaID !== 1 ? "red" : (detalj.PredmetTipNaplateID == 1 ? "black" : (detalj.PredmetTipNaplateID == 2 ? "magenta" : "green")))}} primaryTypographyProps={{ noWrap: true }} primary={detalj.KlijentNaziv}></ListItemText>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <ListItemText style={{ paddingLeft: "15px", color: (detalj.PredmetTipStanjaPredmetaID !== 1 ? "red" : (detalj.PredmetTipNaplateID == 1 ? "black" : (detalj.PredmetTipNaplateID == 2 ? "magenta" : "green")))}} primaryTypographyProps={{ noWrap: true }} primary={detalj.TipOporezivosti.TipPorezaNaziv + ' (' + detalj.TipOporezivosti.Stopa * 100 + ' %)'}></ListItemText>
                                                            </Grid>
                                                            {index != currentEvidencija.EvidencijaDetalj.length - 1 ? (
                                                                <Grid item xs={12}>
                                                                    <Divider></Divider>
                                                                </Grid>
                                                            ) : null}
                                                        </Grid>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </Paper>
                                </Box>
                            ) : null}
                        </Box>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Typography variant="button" gutterBottom component="div" style={{marginTop:"20px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            Odabrani korisnik {global.drugiZaposlenik ? ("(" + global.drugiZaposlenik.Ime + " " + global.drugiZaposlenik.Prezime + ")") : ""} nema pravo evidentiranja za sebe.
                        </Typography>
                    </Grid>
                )}
            </Grid>

            {formOpened ? (
                <Modal className="modal" open={formOpened}>
                    <Box sx={style} overflow="auto" style={isMobileDevice ? { padding: "10px", width: "100%" } : { padding: "20px" }}>
                        <EvidencijaForm minsButtons={minsButtons} evidencijaUPrenesenoRazdobljeMessage={evidencijaUPrenesenoRazdobljeMessage} brojRedovaPredlozaka={brojRedovaPredlozaka} loadEvidencije={loadEvidencije} omoguciPohrani={omoguciPohrani} date={akcija == "edit" ? new Date(currentEvidencija.PocetakRada) : date} onDateChange={handleDatumChange} predmeti={klijentiPredmeti.Predmeti} nepredmetneKategorije={nepredmetneKategorije} evidencija={formEvidencija} korisnikID={global.user.korisnikID} setCanceled={setCanceled} setFormOpened={setFormOpened} tipoviEvidencije={tipoviEvidencije} klijenti={klijentiPredmeti.Klijenti} kontakti={kontakti} predlosci={predlosci} aktivnostDefTrajanjeMin={aktivnostDefTrajanjeMin} tipoviOporezivosti={tipoviOporezivosti} tipoviNaplatnosti={tipoviNaplatnosti} firmaValute={firmaValute} defaultFirmaValuta={defaultFirmaValuta} defaultTrosakTipOporezivosti={defaultTrosakTipOporezivosti} tipEvidencijeID={formTipEvidencijeID} tipNaplatnostiNaplatnoBoja={tipNaplatnostiNaplatnoBoja} tipNaplatnostiNenaplatnoBoja={tipNaplatnostiNenaplatnoBoja} defaultTarifnaAktivnostOmoguciIznosNula={defaultTarifnaAktivnostOmoguciIznosNula} mogucePrebacitiAktivnostiBezCijene={mogucePrebacitiAktivnostiBezCijene} samoOtvoreniPredmeti={defaultSamoOtvoreniPredmeti} setSamoOtvoreniPredmeti={saveSamoOtvoreniPredmeti} samoAktivniKlijenti={defaultSamoAktivniKlijenti} setSamoAktivniKlijenti={saveSamoAktivniKlijenti}></EvidencijaForm>
                    </Box>
                </Modal>
            ) : null}

            {deleteAlert ? <AlertDialog
                title="Brisanje evidencije"
                message={"Jeste li sigurni da želite obrisati odabranu evidenciju?"}
                checkboxMessage={currentEvidencija.EvidencijaDetalj.filter(ed => ed.Datoteka != null).length != 0 ? "Obriši datoteke priložene uz evidenciju." : null}
                checkbox={currentEvidencija.EvidencijaDetalj.filter(ed => ed.Datoteka != null).length != 0 ? deleteFile : null}
                setCheckbox={currentEvidencija.EvidencijaDetalj.filter(ed => ed.Datoteka != null).length != 0 ? setDeleteFile : null}
                cancelLabel={"Odustani"} 
                confirmLabel={"Obriši"} 
                cancelAction={deleteCanceled} 
                confirmAction={deleteConfirmed}
            ></AlertDialog> : null}

            {logoutAlert ? <AlertDialog title="Odjava" message={"Upravo se događa odjava inicirana od strane administratora."} cancelHidden={true} confirmHidden={true} okHidden={false} okLabel={"OK"} okAction={startLogout}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default Evidencije;
