import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import ExitToApp from "@mui/icons-material/ExitToApp";
import SaveIcon from '@mui/icons-material/Save';
import LineIcon from "react-lineicons";
import { Button, FormControl, TextField, Typography, Grid } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import globalContext from "./globalContext";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";
import AutocompleteAsync from "../controls/autocompleteasync";

function KlijentovVoditeljPredmetaKontaktForm(props) {

    const formKontakt = props.formKontakt;
    const klijentID = props.klijentID;
    const firmaID = props.firmaID;
    const setFormOpened = props.setFormOpened;

    const global = useContext(globalContext);

    const [naziv, setNaziv] = useState(null);
    const [telefon, setTelefon] = useState(null);
    const [email, setEmail] = useState(null);
    const [adresaID, setAdresaID] = useState(null);
    const [adresa, setAdresa] = useState(null);
    const [postanskiBroj, setPostanskiBroj] = useState(null);
    const [mjesto, setMjesto] = useState(null);
    const [loading, setLoading] = useState(false);
    const [edited, setEdited] = useState(false);
    const [adresaChanged, setAdresaChanged] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [mjestoApi, setMjestoApi] = useState("");

    const useConstructor = Util.useConstructor();

    useConstructor(() => {
        if (formKontakt) {
            setNaziv(formKontakt.Naziv);
            setTelefon(formKontakt.Tel);
            setEmail(formKontakt.Email);
            setAdresaID(formKontakt.AdresaID);
            setAdresa(formKontakt.AdresaNaziv);
            setPostanskiBroj(formKontakt.PostanskiBroj);
            //setMjesto(formKontakt.MjestoNaziv);
            setMjesto(formKontakt.Mjesto);
        }
    });

    function handleNazivChange(e) {
        setEdited(true);
        setNaziv(e.target.value);
    }

    function handleTelefonChange(e) {
        setEdited(true);
        setTelefon(e.target.value);
    }

    function handleEmailChange(e) {
        setEdited(true);
        setEmail(e.target.value);
    }

    function handleAdresaChange(e) {
        setEdited(true);
        setAdresaChanged(true);
        setAdresa(e.target.value);
    }

    function handlePostanskiBrojChange(e) {
        setEdited(true);
        setAdresaChanged(true);
        setPostanskiBroj(e.target.value);
    }

    function handleMjestoChange(e, v) {
        setEdited(true);
        setAdresaChanged(true);
        setMjesto(v);
    }

    function save() {
        setLoading(true);
        let newKontakt = {
            Naziv : naziv,
            Tel : telefon,
            Email : email,
            AdresaID : adresaChanged ? null : adresaID,
            AdresaNaziv : adresa,
            Mjesto : mjesto,
            AdresaChanged : adresaChanged,
            KlijentID : klijentID,
            FirmaID : firmaID
        }
        if (formKontakt) {
            newKontakt = {...newKontakt, KlijentovVoditeljPredmetaKontaktID : formKontakt.KlijentovVoditeljPredmetaKontaktID}
        } else {
            newKontakt = {...newKontakt, KlijentovVoditeljPredmetaKontaktID : -1}
        }
        api.post("kli-vod-pred-kontakt-by-klijent-update", newKontakt, saveSuccess, saveFail);
    }

    function saveSuccess() {
        setLoading(false);
        setEdited(false);
        props.load();
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
        setEdited(false);
        setFormOpened(false);
    }

    function onMjestoInputChange(e, value) {
        setMjestoApi("/mjesta/search/" + value + "/" + global.user.firmaID);
    }

    return (
        <ComponentContainer>
            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div">
                        {formKontakt ? <div><ManageAccountsIcon fontSize="small" /> {formKontakt.Naziv}</div> : <div><PersonAddIcon fontSize="small" /> Nova kontakt osoba</div>}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField autoFocus label="Naziv" inputProps={{ maxLength: 200 }} value={naziv} error={!naziv} onChange={handleNazivChange} size="small"></TextField>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Telefon" inputProps={{ maxLength: 50 }} value={telefon} onChange={handleTelefonChange} size="small"></TextField>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Email" value={email} inputProps={{ maxLength: 200 }} onChange={handleEmailChange} size="small"></TextField>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Adresa" value={adresa} inputProps={{ maxLength: 500 }} onChange={handleAdresaChange} size="small"></TextField>
                    </FormControl>
                </Grid>
                
                {/* <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Poštanski broj" value={postanskiBroj} onChange={handlePostanskiBrojChange} size="small"></TextField>
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Mjesto" value={mjesto} onChange={handleMjestoChange} size="small"></TextField>
                    </FormControl>
                </Grid> */}

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <AutocompleteAsync
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.MjestoID}>
                                        {(!option.PostanskiBroj ? "" : (option.PostanskiBroj + " ")) + (!option.Naziv? "" : (option.Naziv + ", ")) + option.DrzavaNaziv}
                                    </li>
                                );
                            }}
                            api={mjestoApi}
                            onChange={handleMjestoChange}
                            onInputChange={onMjestoInputChange}
                            size="small"
                            value={mjesto}
                            getOptionLabel={(option) => (!option.PostanskiBroj ? "" : (option.PostanskiBroj + " ")) + (!option.Naziv? "" : (option.Naziv + ", ")) + option.DrzavaNaziv}
                            name="Mjesto"
                            error={!mjesto}
                            renderInput={(params) => <TextField {...params} id="mjestoNaziv" error={adresa && !mjesto} label="Mjesto" />}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" style={{marginRight:"10px"}} color="success" disabled={!edited || !global.roles.includes("18") || loading || !naziv || (adresa && !mjesto)}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <SaveIcon style={{marginRight:"10px"}} />} Spremi</Button>
                        <Button variant="outlined" color="error" onClick={() => setFormOpened(false)} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>

            </Grid>
            
            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );

}

export default KlijentovVoditeljPredmetaKontaktForm;