import { ClickAwayListener, Grid, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import BusinessIcon from '@mui/icons-material/Business';
import { Box } from "@mui/system";
import AddEditDelForm from "./addEditDelForm";
import SatnicaRadaForm from "./satnicaRadaForm";
import StoreIcon from '@mui/icons-material/Store';
import SatnicaRadaDetaljForm from "./satnicaRadaDetaljForm";
import { findGeometry } from "@antv/g2plot/lib/utils";
import AlertDialog from "./alertdialog";
import ErrorAlert from "./errorAlert";
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

const styleDetalj = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    minHeight: '35%',
    maxHeight: '80%'
};

const dani = [
    { Naziv : "Nedjelja", Vrijednost : 0},
    { Naziv : "Ponedjeljak", Vrijednost : 1},
    { Naziv : "Utorak", Vrijednost: 2},
    { Naziv : "Srijeda", Vrijednost : 3},
    { Naziv : "Četvrtak", Vrijednost : 4},
    { Naziv : "Petak", Vrijednost: 5},
    { Naziv : "Subota", Vrijednost : 6},
    { Naziv : "Blagdan", Vrijednost : 8}
];

function SatniceRada(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [satniceRada, setSatniceRada] = useState([]);
    const [currentSatnica, setCurrentSatnica] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [formOpened, setFormOpened] = useState(false);
    const [formSatnica, setFormSatnica] = useState(null);
    const [currentDetalj, setCurrentDetalj] = useState(null);
    const [detaljFormOpened, setDetaljFormOpened] = useState(false);
    const [formDetalj, setFormDetalj] = useState(null);
    const [deleteDetaljAlert, setDeleteDetaljAlert] = useState(false);
    const [satnicaRadaID, setSatnicaRadaID] = useState(-1);
    const [detaljID, setDetaljID] = useState(-1);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        loadSatniceRada();
    });

    function loadSatniceRada(returnPoint) {
        setLoading(true);
        api.get("satnicarada/getforfirma/" + global.user.firmaID, (data) => loadSatniceRadaSuccess(data, returnPoint), loadSatniceRadaFail);
    }

    function loadSatniceRadaSuccess(data, returnPoint) {
        setSatniceRada(data);
        if (data.length > 0) {
            if(!returnPoint){
                setCurrentSatnica(data.at(0));
                if (data.at(0).SatnicaRadaDetalji.length > 0) {
                    setCurrentDetalj(data.at(0).SatnicaRadaDetalji.at(0));
                }    
            }
            else{
                let cs = data.find(x=>x.SatnicaRadaID == returnPoint.SatnicaRadaID);
                setCurrentSatnica(cs);
                if(returnPoint.SatnicaRadaDetaljID > 0){
                    let csd = cs.SatnicaRadaDetalji.find(x=>x.SatnicaRadaDetaljID == returnPoint.SatnicaRadaDetaljID);
                    setCurrentDetalj(csd);
                }
                else{
                    //pozicioniramo se na prvog samo ako smo bili u brisanju ili dodavanju nove satnice rada
                    if (returnPoint.SatnicaRadaDetaljID == 0 && cs.SatnicaRadaDetalji.length > 0) {
                        setCurrentDetalj(cs.SatnicaRadaDetalji.at(0));
                    }    
                }
            }
        }
        setLoading(false);
    }

    function loadSatniceRadaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function add() {
        setFormSatnica(null);
        setFormOpened(true);
    }

    function edit() {
        setFormSatnica(currentSatnica);
        setFormOpened(true);
    }

    function addDetalj() {
        setFormDetalj(null);
        setDetaljFormOpened(true);
    }

    function editDetalj() {
        setFormDetalj(currentDetalj);
        setDetaljFormOpened(true);
    }

    useEffect(() => {
        if (currentSatnica) {
            setSatnicaRadaID(currentSatnica.SatnicaRadaID);
            if(currentDetalj){
                //vidjeti da li trenutno odabrani detalj pripada ovoj satnici - ako pripada, ne dirati, a inače postaviti na nulti detalj ove satnice
                let csd = currentSatnica.SatnicaRadaDetalji.find(x=>x.SatnicaRadaDetaljID == currentDetalj.SatnicaRadaDetaljID);
                if(!csd && currentSatnica.SatnicaRadaDetalji.length > 0){
                    setCurrentDetalj(currentSatnica.SatnicaRadaDetalji[0]);
                } 
            }
            else{
                if(currentSatnica.SatnicaRadaDetalji.length > 0){
                    setCurrentDetalj(currentSatnica.SatnicaRadaDetalji[0]);
                }
            }
        }
    }, [currentSatnica]);

    useEffect(() => {
        if (currentDetalj) {
            setDetaljID(currentDetalj.SatnicaRadaDetaljID);
        }
    }, [currentDetalj]);

    function deleteSatnicaRada() {
        setLoading(true);
        api.post("satnicaRada/delete/" + satnicaRadaID, null, deleteSatnicaRadaSuccess, deleteSatnicaRadaFail);
    }

    function deleteSatnicaRadaSuccess() {
        setLoading(false);
        setDeleteAlert(false);
        loadSatniceRada();
    }

    function deleteSatnicaRadaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setDeleteAlert(false);
        setLoading(false);
    }

    function deleteDetalj() {
        setLoading(true);
        api.post("satnicarada/deletesatnicaradadetalj/" + detaljID, null, deleteDetaljSuccess, deleteDetaljFail);
    }

    function deleteDetaljSuccess() {
        let returnPoint = {
            SatnicaRadaID: currentSatnica.SatnicaRadaID,
            SatnicaRadaDetaljID: 0
        }       
        setDeleteDetaljAlert(false);
        loadSatniceRada(returnPoint);
        setLoading(false);
    }

    function deleteDetaljFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setDeleteDetaljAlert(false);
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <QueryBuilderIcon fontSize="medium" style={{marginRight:"15px"}}></QueryBuilderIcon>
                        Satnice rada
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{width:"100%", marginTop:"20px"}}>
                        <AddEditDelForm dataType="satniceRada" data={satniceRada} current={currentSatnica} setCurrent={setCurrentSatnica} disableEdit={!currentSatnica || satniceRada.length == 0} disableDeleteButton={satniceRada.length == 1 || !currentSatnica || currentSatnica.OsnovnaSatnica} add={add} edit={edit} del={() => setDeleteAlert(true)}></AddEditDelForm>
                    
                        {currentSatnica ? (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" gutterBottom component="div" style={{marginBottom:"15px"}}>
                                        <ManageHistoryIcon fontSize="medium" style={{marginRight:"15px"}}></ManageHistoryIcon>
                                        Detalji
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <AddEditDelForm dataType="satnicaRadaDetalji" data={currentSatnica.SatnicaRadaDetalji} formDetalj={formDetalj} current={currentDetalj} disableEdit={!currentDetalj || !currentSatnica || currentSatnica.SatnicaRadaDetalji.length == 0} disableDeleteButton={!currentDetalj || !currentSatnica || currentSatnica.SatnicaRadaDetalji.length == 0} disableAddButton={currentSatnica.SatnicaRadaDetalji.length == dani.length} setCurrent={setCurrentDetalj} add={addDetalj} edit={editDetalj} del={() => setDeleteDetaljAlert(true)}></AddEditDelForm>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Box>
                </Grid>
        </Grid>  
            
        <Modal open={formOpened}>
            <Box sx={style} overflow={"auto"} >
                <SatnicaRadaForm loadSatniceRada={loadSatniceRada} firmaID={global.user.firmaID} formSatnica={formSatnica} currentDetalj={currentDetalj} setFormOpened={setFormOpened} satniceRada={satniceRada}></SatnicaRadaForm>
            </Box>
        </Modal>  
            
        <Modal open={detaljFormOpened}>
            <Box sx={styleDetalj} overflow={"auto"} >
                <SatnicaRadaDetaljForm dani={dani} loadSatniceRada={loadSatniceRada} firmaID={global.user.firmaID} satnica={currentSatnica} formDetalj={formDetalj} setFormOpened={setDetaljFormOpened}></SatnicaRadaDetaljForm>
            </Box>
        </Modal>

        {deleteAlert ? <AlertDialog title="Brisanje satnice rada" message={"Jeste li sigurni da želite obrisati odabranu satnicu rada i njene detalje?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => setDeleteAlert(false)} confirmAction={deleteSatnicaRada}></AlertDialog> : null}

        {deleteDetaljAlert ? <AlertDialog title="Brisanje detalja satnice rada" message={"Jeste li sigurni da želite obrisati odabrani detalj satnice?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => setDeleteDetaljAlert(false)} confirmAction={deleteDetalj}></AlertDialog> : null}

        <Modal className="modal" open={errorMessage != null}>
            <Box sx={style}>
                <ErrorAlert message={errorMessage} close={() => setErrorMessage(null)}></ErrorAlert>
            </Box>
        </Modal>
        </ComponentContainer>
    );
}

export default SatniceRada;