import { Autocomplete, Button, Divider, FormControl, Grid, IconButton, InputAdornment, ListItem, Menu, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from "@mui/system";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import api from "../util/api";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from '@mui/icons-material/History';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import globalContext from "./globalContext";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

// const dani = [
//     { Naziv : "Nedjelja", Vrijednost : 0},
//     { Naziv : "Ponedjeljak", Vrijednost : 1},
//     { Naziv : "Utorak", Vrijednost: 2},
//     { Naziv : "Srijeda", Vrijednost : 3},
//     { Naziv : "Četvrtak", Vrijednost : 4},
//     { Naziv : "Petak", Vrijednost: 5},
//     { Naziv : "Subota", Vrijednost : 6},
//     { Naziv : "Blagdan", Vrijednost : 8}
// ];


function SatnicaRadaDetaljForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const dani = props.dani;
    const formDetalj = props.formDetalj;
    const setFormOpened = props.setFormOpened;
    const satnica = props.satnica;

    const [loading, setLoading] = useState(false);
    const [dan, setDan] = useState(null);
    const [pocetak, setPocetak] = useState(null);
    const [trajanje, setTrajanje] = useState(480);
    const [daniOptions, setDaniOptions] = useState(dani);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {

        let zauzetiDani = satnica.SatnicaRadaDetalji.map(s => s.DanTjednaID);
        let dostupniDani = dani.filter(x=>!zauzetiDani.includes(x.Vrijednost));
        if(formDetalj){
            dostupniDani.push({Naziv: formDetalj.DanTjedna, Vrijednost: formDetalj.DanTjednaID});
        }
        setDaniOptions(dostupniDani.sort((prvi, drugi) => (prvi.Vrijednost - drugi.Vrijednost)));

        if (formDetalj) {
            setDan(dani.find(o => o.Vrijednost == formDetalj.DanTjednaID));
            setPocetak(new Date(formDetalj.Pocetak));
            setTrajanje(formDetalj.Duzina)
        }
        else{
            setPocetak(new Date('1900-1-1 09:00:00'));
            setTrajanje(8);
        }
    });

    function save() {
        let resource = {
            SatnicaRadaDetaljID : formDetalj ? formDetalj.SatnicaRadaDetaljID : -1,
            DanTjednaID : dan.Vrijednost,
            Pocetak : pocetak,
            Duzina : trajanje,
            SatnicaRadaID : satnica.SatnicaRadaID
        }
        setLoading(true);
        api.post("satnicarada/savedetalj", resource, (data) => saveSuccess(data), saveFail);
    }

    function saveSuccess(data) {
        let returnPoint = {
            SatnicaRadaID: satnica.SatnicaRadaID,
            SatnicaRadaDetaljID: data
        };
        setLoading(false);
        props.loadSatniceRada(returnPoint);
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container className="padding-grid">
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formDetalj ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <AddIcon fontSize="medium" style={{marginRight:"15px"}}></AddIcon>
                            )}
                            {formDetalj ? (
                                "Uređivanje detalja satnice rada"
                            ) : (
                                "Novi detalj satnice rada"
                            )}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            options={daniOptions}
                            value={dan}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {
                                if (v) {
                                    setDan(v);
                                }  
                            }}                               
                            disableClearable
                            renderInput={(params) => <TextField {...params} error={!dan} helperText={!dan ? 'Dan je obvezan podatak' : ''} label="Dan" size="small" />}
                            ListboxProps={
                                {
                                style:{
                                    maxHeight: '10rem'
                                    }
                                }
                             }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils}>
                            <TimePicker
                                label="Očekivani početak rada"
                                value={pocetak}
                                views={["hours", "minutes", "seconds"]}
                                inputFormat="HH:mm:ss"
                                onChange={(v) => {if (v) setPocetak(new Date(v))}}
                                renderInput={(params) => <TextField sx={{width:"99%"}} {...params} size="small" error={!pocetak || isNaN(new Date(pocetak))} helperText={!pocetak ? 'Vrijeme početka je obavezan podatak' : ''} required />}
                            />
                        </LocalizationProvider>        
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField type="number" inputProps={{ min: 1, max: 24 }} InputProps={{endAdornment:(<InputAdornment position="end">sati</InputAdornment>)}} label="Trajanje" value={trajanje} onChange={(e) => setTrajanje(e.target.value)} error={!trajanje} helperText={!trajanje ? 'Trajanje je obvezan podatak' : ''}  required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" color="success" style={{marginRight:"10px"}} disabled={!dan || !pocetak  || isNaN(new Date(pocetak)) || !global.roles.includes("20") || !trajanje}><SaveIcon style={{marginRight:"10px"}} /> Spremi</Button>
                        <Button variant="outlined" color="error" onClick={() => {setFormOpened(false);}} ><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default SatnicaRadaDetaljForm;