import { Box, Button, Checkbox, Divider, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ComponentContainer from "./componentContainer";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import BlockIcon from '@mui/icons-material/Block';
import LineIcon from "react-lineicons";
import ExitToApp from "@mui/icons-material/ExitToApp";
import Util from "../util/util";
import FakturiranjeForm from "./fakturiranjeForm";
import api from "../util/api";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';

function StornoForm(props) {

    const useConstructor = Util.useConstructor();

    const racun = props.racun;
    const setFormOpened = props.setFormOpened;
    const filterRacuni = props.filter;

    const [loading, setLoading] = useState(false);
    const [datum, setDatum] = useState(new Date());
    const [minDatum, setMinDatum] = useState(null);
    const [maxDatum, setMaxDatum] = useState(null);
    const [vratiTroskove, setVratiTroskove] = useState(true);
    const [vratiAktivnosti, setVratiAktivnosti] = useState(true);
    const [postojeTroskovi, setPostojeTroskovi] = useState(false);
    const [postojeAktivnosti, setPostojeAktivnosti] = useState(false);
    const [currentBroj, setCurrentBroj] = useState(null);
    const [broj, setBroj] = useState(null);
    const [blagajna, setBlagajna] = useState(null);
    const [poslovniProstor, setPoslovniProstor] = useState(null);
    const [datumError, setDatumError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        if (racun) {
            racun.RacunskeStavke.forEach(racunskaStavka => {
                if (racunskaStavka.TipRacunskeStavkeID == 1) {
                    racunskaStavka.StavkeRacunskeStavke.forEach(stavkaRacunskeStavke => {
                        if (stavkaRacunskeStavke.EvidencijaDetaljID) {
                            if (stavkaRacunskeStavke.TipEvidencijeID == 1) {
                                setPostojeAktivnosti(true);
                            } else if (stavkaRacunskeStavke.TipEvidencijeID == 2) {
                                setPostojeTroskovi(true);
                            }
                        }
                    });
                }
            });
        }
    });

    function save() {
        let resource = {
            RacunID : racun ? racun.RacunID : -1,
            Broj : broj,
            Datum : datum,
            Blagajna : blagajna,
            PoslovniProstor : poslovniProstor,
            VratiAktivnostiUNeprenesenoStanje : vratiAktivnosti,
            VratiTroskoveUNeprenesenoStanje : vratiTroskove
        }
        setLoading(true);
        api.post("racun/storno", resource, saveSuccess, saveFail);
    }

    function saveSuccess() {
        setLoading(false);
        filterRacuni();
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        if (currentBroj) {
            setMinDatum(currentBroj.DatumOd ? new Date(currentBroj.DatumOd) : null);
            setMaxDatum(currentBroj.DatumDo ? new Date(currentBroj.DatumDo) : null);
            setBroj(currentBroj.PrviSlobodniBroj);
            if (currentBroj.DatumOd && datum < new Date(currentBroj.DatumOd)) {
                setDatum(new Date(currentBroj.DatumOd));
            }
            if (currentBroj.DatumDo && datum > new Date(currentBroj.DatumDo)) {
                setDatum(new Date(currentBroj.DatumDo));
            }
        }
    }, [currentBroj]);

    useEffect(() => {
        if (datum) {
            if (isNaN(datum)) {
                setDatumError("Pogrešan format datuma!");
            } else {
                let datumCheck = new Date(datum.getFullYear(), datum.getMonth(), datum.getDate(), datum.getHours(), datum.getMinutes(), datum.getSeconds());
                if (minDatum && minDatum > datumCheck || maxDatum && maxDatum < datumCheck) {
                        setDatumError("Datum izvan dopuštenog raspona!");
                } else {
                    setDatumError("");
                }
            }
        } else {
            setDatumError("");
        }
    }, [datum, minDatum, maxDatum]);

    function handleDatumChange(v) {
        if (v) {
            if (minDatum) {
                if (v.getFullYear() == minDatum.getFullYear() && v.getFullYear() == minDatum.getFullYear() && v.getMonth() == minDatum.getMonth() && v.getDate() == minDatum.getDate() && v < minDatum) {
                    let newDatum = new Date(minDatum);
                    setDatum(newDatum);
                } else {
                    setDatum(v);
                }
            }
            if (maxDatum) {
                if (v.getFullYear() == maxDatum.getFullYear() && v.getFullYear() == maxDatum.getFullYear() && v.getMonth() == maxDatum.getMonth() && v.getDate() == maxDatum.getDate() && v > minDatum) {
                    let newDatum = new Date(maxDatum);
                    setDatum(newDatum);
                } else {
                    setDatum(v);
                }
            }
            if (minDatum == null && maxDatum == null) {
                setDatum(v);
            }
        }
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div">
                        <div style={{
                            display: "inline-flex",
                            verticalAlign: "text-bottom",
                            boxSizing: "inherit",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%"
                        }}>
                            <BlockIcon fontSize="medium" style={{marginRight:"15px"}}></BlockIcon>
                            Storno računa
                        </div>
                    </Typography>
                </Grid>
                {/* <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker
                            label="Datum storna"
                            value={datum}
                            inputFormat="dd.MM.yyyy"
                            onChange={handleDatumChange}
                            renderInput={(params) => <TextField style={{width:"100%"}} {...params} size="small" error={!datum || isNaN(new Date(datum)) || datumError != ""} helperText={datumError} required />}
                        />
                    </LocalizationProvider>
                </Grid> */}
                {postojeAktivnosti ? (
                    <Grid item xs={12} >
                        <FormControlLabel 
                            labelPlacement="end" 
                            label="Vrati aktivnosti iz kojih su nastale specifikacije u nepreneseno stanje."
                            style={{marginLeft:"0px"}}
                            control={<Checkbox checked={vratiAktivnosti} onChange={e => setVratiAktivnosti(e.target.checked)}></Checkbox>}>
                        </FormControlLabel>
                    </Grid>
                ) : null}
                {postojeTroskovi ? (
                    <Grid item xs={12} >
                        <FormControlLabel 
                            labelPlacement="end" 
                            label="Vrati troškove iz kojih su nastale specifikacije u nepreneseno stanje."
                            style={{marginLeft:"0px"}}
                            control={<Checkbox checked={vratiTroskove} onChange={e => setVratiTroskove(e.target.checked)}></Checkbox>}>
                        </FormControlLabel>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Divider style={{marginTop:"10px", marginBottom:"10px"}}></Divider>
                </Grid>
                <Grid item xs={12}>
                    <FakturiranjeForm 
                        vrstaRacunaID={7}
                        vezniRacunID={racun.RacunID}
                        storno={true} 
                        odobrenjeZaduzenjeStornoDatumRacuna={racun ? racun.Datum : null}
                        datumStorna={datum}
                        setDatumError={setDatumError}
                        setStornoDatum={setDatum}
                        setParentBroj={setBroj}
                        setParentCurrentBroj={setCurrentBroj}
                        setParentBlagajna={setBlagajna}
                        setParentPoslovniProstor={setPoslovniProstor}
                        // racun={racun}
                        // racuni={null}
                    ></FakturiranjeForm>
                </Grid>
                <Grid item xs={12}>
                    <Divider style={{marginTop:"10px", marginBottom:"10px"}}></Divider>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" style={{marginRight:"10px"}} color="success" disabled={loading || !datum || isNaN(new Date(datum)) || !broj || !blagajna || !poslovniProstor}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <BlockIcon style={{marginRight:"10px"}} />} Storniraj</Button>
                        <Button variant="outlined" color="error" onClick={() =>  setFormOpened(false)} ><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>

            
            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default StornoForm;