import { Alert, Button, FormControl, Grid, Grow, TextField } from "@mui/material";
import React, { useState } from "react";
import DoneIcon from '@mui/icons-material/Done';
import api from "../util/api";
import ComponentContainer from "./componentContainer";
import ExitToApp from "@mui/icons-material/ExitToApp";

export default function FakturiranjeRucniUnosBrojaForm(props) {
    
    const prviBroj = props.prviBroj ? parseInt(props.prviBroj) : 2;
    const setBrojRacuna = props.setBrojRacuna;
    const blagajnaID = props.blagajnaID;
    const close = props.close;
    const datum = props.datum;
    const godina = props.godina;

    const [loading, setLoading] = useState(false);
    const [broj, setBroj] = useState(prviBroj);
    const [errorMessage, setErrorMessage] = useState(null);
    
    function onBrojFocusOut(e) {
        if (isNaN(parseInt(e.target.value)) || parseInt(e.target.value) < prviBroj) {
            setBroj(prviBroj);
        }
    }

    function prihvati() {
        setLoading(true);
        let resource = {
            BlagajnaID : blagajnaID,
            Broj : broj,
            Godina : godina,
            Datum : datum
        }
        api.post("fakturiranje/provjerirucniunosbroja", resource, provjeriBrojSuccess, provjeriBrojFail);
    }

    function provjeriBrojSuccess(data) {
        setLoading(false);
        if (data) {
            setErrorMessage(data);
        } else {
            setErrorMessage(null);
            setBrojRacuna(broj);
            close();
        }
    }

    function provjeriBrojFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer laoding={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Alert severity="warning">
                        U tekstualno je polje moguće unijeti broj računa koji je veći od najvećeg iskorištenog broja na odabranoj blagajni u trenutnoj godini.  
                        Ovime u brojevima računa nastaje "rupa", odnosno prekida se slijedni niz brojeva računa na blagajni i nastaju neiskorišteni brojevi, koji se mogu iskoristiti prilikom budućih fakturiranja.
                    </Alert>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Broj" value={broj} onChange={(e) => setBroj(parseInt(e.target.value))} onBlur={onBrojFocusOut} type="number" inputProps={{min:prviBroj}} size="small" ></TextField>
                    </FormControl>
                </Grid>
                {errorMessage ? (
                    <Grow in={errorMessage}>
                        <Grid item xs={12}>
                            <Alert severity="error">
                                {errorMessage}
                            </Alert>
                        </Grid>
                    </Grow>
                ) : null}
                <Grid item xs={12} style={{display:"flex", justifyContent:"flex-end", marginTop:"10px"}}>
                    <Button variant="contained" color="success" startIcon={<DoneIcon></DoneIcon>} onClick={prihvati}>Prihvati</Button>
                    <Button style={{marginLeft:"5px"}} variant="outlined" color="error" startIcon={<ExitToApp></ExitToApp>} onClick={close}>Odustani</Button>
                </Grid>
            </Grid>
        </ComponentContainer>
    );
}