import { Autocomplete, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import { Box } from "@mui/system";
import Util from "../util/util";
import api from "../util/api";
import AlertDialog from './alertdialog';
import ErrorIcon from '@mui/icons-material/Error';
import globalContext from "./globalContext";

export default function SudForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formSud = props.formSud;
    const close = props.close;
    const getSudovi = props.getSudovi;
    const dodajSudSuccess = props.dodajSudSuccess;

    const [loading, setLoading] = useState(true);
    const [tipoviVrsteSuda, setTipoviVrsteSuda] = useState([]);
    const [tipVrsteSuda, setTipVrsteSuda] = useState(null);
    const [naziv, setNaziv] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        api.get("sud/getvrstesuda", getVrsteSudaSuccess, fail);
    });

    function getVrsteSudaSuccess(data) {
        setTipoviVrsteSuda(data);
        if (formSud) {
            setTipVrsteSuda(formSud.TipVrsteSuda);
            setNaziv(formSud.Naziv);
        } else {
            if (data.length > 0) {
                setTipVrsteSuda(data[0]);
            }
            if (props.sudNaziv) {
                setNaziv(props.sudNaziv);
            }
        }
        setLoading(false);
    }

    function fail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save() {
        setLoading(true);
        let resource = {
            SudID: formSud ? formSud.SudID : -1,
            Naziv: naziv,
            TipVrsteSudaID: tipVrsteSuda.TipVrsteSudaID,
            FirmaID: global.user.firmaID
        };
        api.post("sud/save", resource, saveSuccess, fail);
    }

    function saveSuccess(data) {
        if (getSudovi) {
            getSudovi(); 
        }
        if (dodajSudSuccess) {
            dodajSudSuccess(data);
        }
        close();
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formSud ? (
                                <div></div>
                            ) : (
                                <div></div>
                            )}
                            {formSud ? (
                                "Uređivanje suda"
                            ) : (
                                "Dodavanje suda"
                            )}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <TextField label="Naziv" value={naziv} inputProps={{ maxLength: 150 }} onChange={e => setNaziv(e.target.value)} error={!naziv} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete size="small" 
                            options={tipoviVrsteSuda} 
                            onChange={(e,v) => {if (v) setTipVrsteSuda(v)}}
                            getOptionLabel={option => option.Naziv}
                            value={tipVrsteSuda}
                            autoHighlight
                            disableClearable
                            renderInput={(params) => <TextField sx={{width:"100%"}} size="small" {...params} label="Vrsta" error={!tipVrsteSuda} />} 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" style={{marginRight:"10px"}} color="success" disabled={loading || !naziv || !tipVrsteSuda}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <SaveIcon style={{marginRight:"10px"}} />} Spremi</Button>
                        <Button variant="outlined" color="error" onClick={close} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
        </ComponentContainer>
    );
}