import React, { useContext, useEffect, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Box, ClickAwayListener, Grid, Modal, Typography } from "@mui/material";
import api from "../util/api";
import globalContext from "./globalContext";
import AddEditDelForm from "./addEditDelForm";
import GrupaForm from "./grupaForm";
import AlertDialog from "./alertdialog";
import ErrorAlert from "./errorAlert";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

function Grupa(props) {
    
    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);
    
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [formOpened, setFormOpened] = useState(false);
    const [form, setForm] = useState(null);
    const [canceled, setCanceled] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        load();
    });

    function load() {
        api.get("Grupa/GetAllByFirmaID?id=" + global.user.firmaID, loadSuccess, loadFail);
    }

    function loadSuccess(data) {
        setItems(data);
        setLoading(false);
    }

    function loadFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function openForm(type) {
        if (type == "add") {
            setForm(null);
            setFormOpened(true);
        } else {
            setForm(selectedItem);
            setFormOpened(true);
        }
    }

    useEffect(() => {if (!formOpened && !deleteAlert && !canceled) load()}, [formOpened, deleteAlert]);

    function remove() {
        setLoading(true);
        api.post("Grupa/delete", {GrupaID: selectedItem.GrupaID}, deleteSuccess, deleteFail);
    }

    function deleteSuccess(data) {
        setCanceled(false);
        setDeleteAlert(false);

        if(!data.Success){
            alert(data.ErrorMessage);
        }
    }

    function deleteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setDeleteAlert(false);
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <AssessmentIcon fontSize="medium" style={{marginRight:"15px"}}></AssessmentIcon>
                        Grupe klijenata
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <ClickAwayListener onClickAway={() => setSelectedItem(null)}>
                        <Box style={{width:"100%", marginTop:"20px"}}>
                            <AddEditDelForm dataType="grupa" data={items} current={selectedItem} setCurrent={setSelectedItem} add={() => openForm("add")} edit={() => openForm("edit")} del={() => setDeleteAlert(true)}></AddEditDelForm>
                        </Box>
                    </ClickAwayListener>
                </Grid>
            </Grid>

            <Modal open={formOpened}>
                <Box sx={style} overflow={"auto"} >
                    <GrupaForm form={form} setFormOpened={setFormOpened} setCanceled={setCanceled}></GrupaForm>
                </Box>
            </Modal>

            {deleteAlert ? <AlertDialog title="Brisanje grupe" message={"Jeste li sigurni da želite obrisati odabranu grupu?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => {setCanceled(true); setDeleteAlert(false);}} confirmAction={remove}></AlertDialog> : null}
        
            <Modal className="modal" open={errorMessage != null}>
                <Box sx={style}>
                    <ErrorAlert message={errorMessage} close={() => setErrorMessage(null)}></ErrorAlert>
                </Box>
            </Modal>
        </ComponentContainer>
    );
}

export default Grupa;