import React, { useState, useEffect, useContext, useRef } from "react";
import database from "../util/database";
import { Form, FormInstance, Input, Alert, Row, Divider, Checkbox, Select, Option } from "antd";
import GlobalContext from "../components/globalContext";
import { UserOutlined, LockOutlined, DownloadOutlined } from "@ant-design/icons";
import api from "../util/api";
import logo from "../images/Untitled-2.png";
import Snack from "../controls/snack";
import ComponentContainer from "./componentContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Link, FormHelperText, IconButton, OutlinedInput, InputLabel, InputAdornment, FormControl, TextField, Box, ClickAwayListener, Grid, Modal, Typography } from "@mui/material";
import ExitToApp from "@mui/icons-material/ExitToApp";
import SaveIcon from '@mui/icons-material/Save';
import { SignalCellularNullSharp } from "@mui/icons-material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { LoadingButton } from '@mui/lab';
import ErrorIcon from '@mui/icons-material/Error';

const pwdChangeBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '90%'
};


function Login(props) {
    const [errorMessage, setErrorMessage] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [mailSent, setMailSent] = useState(false);
    const [zaboravljenaLozinka, setZaboravljenaLozinka] = useState(false);
    const [loading, setLoading] = useState(false);
    const global = useContext(GlobalContext);
    const [korisnikID, setKorisnikID] = useState(0);
    const [registracija, setRegistracija] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackType, setSnackType] = useState("success");
    const [snackMessage, setSnackMessage] = useState("Uspješno spremljeno");
    const [pwdForcedChange, setPwdForcedChange] = useState(false);
    const [pwdResetMessage, setPwdResetMessage] = useState("");
    const [userState, setUserState] = useState(null);
    const [newPwd1, setNewPwd1] = useState("");
    const [newPwd2, setNewPwd2] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [newPwdFormValid, setNewPwdFormValid] = useState(false);
    const [usernameEdited, setUsernameEdited] = useState(false);
    const [passwordEdited, setPasswordEdited] = useState(false);
    const [newPwd1Edited, setNewPwd1Edited] = useState(false);
    const [newPwd2Edited, setNewPwd2Edited] = useState(false);

    // const unameRef = useRef(null);

    // function focusOnUname() {
    //     setTimeout(() => {
    //         if (unameRef.current) {
    //             unameRef.current.focus();
    //         }
    //     }, 100);
    // }
    
    // focusOnUname();

    function onFinish(values) {
        submit();
    }

    function onFinishFailed(errorInfo) {
        //console.log("Failed:", errorInfo);
    }

    function submit(e) {
        setLoading(true);
        setTimeout(() => {
            api.login(username, password, success, fail);
        }, 1000);
    }

    function submitPromjenaLozinke(e) {
        setLoading(true);
        api.get("korisnik/resetpassword?username=" + email, sendMailSuccess, sendMailFail);
    }

    function sendMailSuccess(result) {
        if (result.Success) {
            setSnackMessage(result.Message);
            setSnackType("success");
            setZaboravljenaLozinka(false);
        } else {
            setSnackMessage(result.ErrorMessage);
            setSnackType("error");
        }
        setLoading(false);
        setSnackOpen(true);
        setLoading(false);
    }

    function sendMailFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function fail(ex) {
        setErrorMessage(
            <div>
                {" "}
                Neispravno korisničko ime ili lozinka &nbsp;{" "}
                {/* <span
                onClick={() => {
                    this.setState({ zaboravljenaLozinka: true, errorMessage: "" });
                }}
                style={{ textDecoration: "underline", fontSize: "12px", color: "blue", cursor: "pointer" }}
            >
                zaboravljena lozinka
            </span>{" "} */}
            </div>
        );

        setLoading(false);
    }

    function success(user) {
        setUserState(user);
        if (user.error && user.error_description) {
            setErrorMessage(user.error_description);
            setLoading(false);
            return;
        }

        if (user) {
                      
            user.authdata = window.btoa(username + ":" + password);

            // // Build the expiration date string:
            // let expiration_date = new Date();
            // expiration_date.setFullYear(expiration_date.getFullYear() + 1);
            // // Build the set-cookie string:
            // let cookie_user = "user=" + JSON.stringify(user) + "; path=/; expires=" + expiration_date.toUTCString();
            // let cookie_roles = "roles=" + user.roles + "; path=/; expires=" + expiration_date.toUTCString();
            // // Create or update the cookie:
            // document.cookie = cookie_user;
            // document.cookie = cookie_roles;

            database.set("user", user);

            let roles = user.roles.split(",");
            database.set("roles", roles);
            
            if(user.pwdResetRequired == "True"){
                setPwdForcedChange(true);
                setPwdResetMessage(user.pwdResetMessage);
                setNewPwd1('');
                setNewPwd2('');
                setNewPwd1Edited(false);
                setNewPwd2Edited(false);
                setLoading(false);
                return;
            }

            api.get("korisnici/all/" + user.firmaID, (data) => loadInfoSuccess(data, user, username), loadInfoFail);

            // window.location = "/#";
            //window.location.reload();
            // if (self.props.success) self.props.success();
        }
    }

    function loadInfoSuccess(data, user, username) {       
        
        api.get("firma/getlogo?firmaID=" + user.firmaID, (res) => {
            global.setLogoFile(res);
        });

        // global.setLoading(true);

        let roles = user.roles.split(",");
        
        global.setLogoPosition(user.logoPosition);
        global.setUsername(username);
        global.setUser(user);
        global.setRoles(roles);

        database.set("zaposlenici", data);
        global.setZaposlenici(data);
        global.setLoading(false);
        if (props.success) props.success(user);
    }

    function loadInfoFail(errorMsg) {
        //if (props.success) props.success();
        if(errorMsg){
            setErrorMessage(errorMsg);
        }
        //alert(error);
        setLoading(false);

    }

    function usernameChanged(value) {
        setUsername(value.target.value);
        setUsernameEdited(true);
    }

    function passwordChanged(value) {
        setPassword(value.target.value);
        setPasswordEdited(true);
    }

    function emailChanged(value) {
        setEmail(value.target.value);
    }

    function onKeyUp(data) {
        if (data.keyCode === 13) {
            submit();
        }
    }

    function logout(){
        setPwdForcedChange(false);
        setPwdResetMessage('');
    }

    function spremiNovuLozinku(){
        let resource = {
            Username: userState.username,
            OldPassword: '',
            NewPassword: newPwd1,
            NewPasswordConfirm: newPwd2,
            KorisnikID: userState.korisnikID
        }
        api.post("password/forcedupdate", resource, pwdUpdateSuccess, pwdUpdateFail);
    }

    function pwdUpdateSuccess(){
        let newUserState = {...userState}
        newUserState["pwdResetRequired"] = "False";
        setUserState(newUserState);
        success(newUserState);
    }

    function pwdUpdateFail(data){
        setPwdForcedChange(false);
        setPwdResetMessage('');
        alert('Dogodila se greška pri spremanju nove lozinke');
    }

    function handleNewPwd1Change(e){
        setNewPwd1(e.target.value);
        setNewPwd1Edited(true);
    }

    function handleNewPwd2Change(e){
        setNewPwd2(e.target.value);
        setNewPwd2Edited(true);
    }

    function handleClickShowPassword(){
        setShowPassword((show) => !show);
    }

    function isPwdValid(pwd){
        const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return re.test(String(pwd).toLowerCase());        
    }

    useEffect(() => {
        let formValid = newPwd1 != null && newPwd1 !== '' && newPwd2 != null && newPwd2 !== '' && isPwdValid(newPwd1) && isPwdValid(newPwd2) && newPwd1 == newPwd2 && newPwd1 != password && newPwd2 != password;
        setNewPwdFormValid(formValid);
    },[newPwd1, newPwd2]);


    // useEffect(() => {
    //     alert(errorMessage);
    // },[errorMessage]);

    return (
        <>
            <Row  justify="center" align="middle" style={{ minHeight: "80vh" }}>
                {/* {mailSent ? (
                    <Form style={{ width: "250px" }} name="normal_login" className="login-form" initialValues={{}} onFinish={onFinish}>
                        {errorMessage != "" ? (
                            <Form.Item>
                                <Alert message={errorMessage}></Alert>
                            </Form.Item>
                        ) : null}
                    </Form>
                ) : null} */}

                {mailSent ? (
                    <Grid container spacing={1} sx={{ width: "250px"}}>
                        <Grid item xs={12} sx={{textAlign:'center'}}>
                            {errorMessage != "" ? (
                                <Box sx={{ border:'1px solid', borderColor:'#91d5ff', backgroundColor:'#e6f7ff', marginTop:'2em', minHeight:'5em', display:'flex', justifyContent:'center', flexDirection:'column' }}>
                                    {errorMessage}
                                </Box>
                            ) : null}
                        </Grid>
                    </Grid>
                ) : null}


                {zaboravljenaLozinka ? (
                    <Grid container spacing={1} sx={{ width: "250px"}}>
                        <Grid item xs={12}>
                            <div style={{ fontSize: "12px", textAlign: "center" }}>Molimo unesite korisničko ime na čiji mail ćemo poslati link za obnavljanje lozinke</div>
                            <br />
                        </Grid> 
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                                <TextField
                                    id="username-input"
                                    label="Korisničko ime"
                                    type={'text'}
                                    onChange={emailChanged}
                                    value={email}
                                    size="small"
                                    autoComplete="off"
                                    error={email == null || email == ''}
                                    helperText={email == null || email == '' ? 'Korisničko ime je obavezno' : ''}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">
                                            <FaceOutlinedIcon /> 
                                        </InputAdornment>,
                                    }}                                                    
                                />
                            </FormControl>                        
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" disabled={!email} onClick={submitPromjenaLozinke} loading={loading} style={{ width: "100%" }} className="search-button">
                                Pošalji
                            </Button>   
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="outlined" disabled={loading} onClick={()=>setZaboravljenaLozinka(false)} style={{ width: "100%" }} className="search-button">
                                Povratak na prijavu
                            </Button>   
                        </Grid>                        
                    </Grid>
                ) : null}

                {/* {zaboravljenaLozinka ? (
                    <Form style={{ width: "250px" }} name="normal_login" className="login-form" initialValues={{}} onFinish={onFinish}>
                        <div style={{ fontSize: "12px", textAlign: "center" }}>Molimo unesite korisničko ime na čiji mail ćemo poslati link za obnavljanje lozinke</div>
                        <br />
                        <Form.Item name="email" rules={[{ required: true, message: "Molimo unesite korisničko ime na čiji mail ćemo poslati link za obnavljanje lozinke" }]}>
                            <Input onChange={emailChanged} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Korisničko ime" />
                        </Form.Item>
                        <Form.Item>
                            <Button  disabled={!email} onClick={submitPromjenaLozinke} loading={loading} style={{ width: "100%" }} className="search-button">
                                Pošalji
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button onClick={()=>setZaboravljenaLozinka(false)} disabled={loading} style={{ width: "100%" }} className="search-button">
                                Povratak na prijavu
                            </Button>
                        </Form.Item>
                        {errorMessage != "" ? (
                            <Form.Item>
                                <Alert message={errorMessage}></Alert>
                            </Form.Item>
                        ) : null}
                    </Form>
                ) : null} */}

                {!zaboravljenaLozinka && !mailSent ? (
                    <Grid container spacing={1} sx={{ width: "250px"}}>
                        {/* <Grid item>
                            <input type="text" style={{display:'none'}}></input>
                            <input type="password" style={{display:'none'}}></input>
                        </Grid> */}
                        <Grid item xs={12} sx={{textAlign:'center'}}>
                            <table className="menu-logo" style={{ display: "inline-block", marginLeft: "-10px" }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src={logo} style={{ height: "64px", width: "64px" }}></img>
                                        </td>
                                        <td>
                                            <span style={{ marginLeft: "-8px", marginTop: "5px", display: "inline-block" }}>
                                                <p style={{ color: "#626262", fontFamily: "Righteous", fontSize: "24px" }}>CHATO</p>
                                                <p style={{ marginTop: "-35px", fontSize: "9px", color: "#757575" }}>{"Copyright 1998 - " + (new Date()).getFullYear() + " - IPT d.o.o."}</p>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <TextField
                                        //autoFocus
                                        onFocus={(e) => e.target.select()}
                                        // inputRef={unameRef}
                                        InputLabelProps={{ shrink: true }}
                                        id="username-input"
                                        label="Korisničko ime"
                                        type={'text'}
                                        onChange={usernameChanged}
                                        onKeyUp={onKeyUp}                                    
                                        value={username}
                                        size="small"
                                        autoComplete="username"
                                        error={usernameEdited && username == ''}
                                        helperText={usernameEdited && username == '' ? 'Korisničko ime je obavezno' : ''}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <FaceOutlinedIcon /> 
                                            </InputAdornment>,
                                        }}                                                    
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                <TextField
                                        InputLabelProps={{ shrink: true }}
                                        id="password-input"
                                        label="Lozinka"
                                        type={'password'}
                                        onChange={passwordChanged}
                                        onKeyUp={onKeyUp}
                                        value={password}
                                        size="small"
                                        autoComplete="new-password"
                                        error={passwordEdited && password == ''}
                                        helperText={passwordEdited && password == '' ? 'Lozinka je obavezna' : ''}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">
                                                <LockOutlinedIcon /> 
                                            </InputAdornment>,
                                        }}                                                    
                                    />
                                </FormControl>  
                            </Grid>

                            <Grid item xs={12}>
                                {/* <Button variant="contained" onClick={submit} loading={loading} style={{ width: "100%" }} className="search-button">
                                    Prijava
                                </Button> */}

                                <LoadingButton variant="contained" onClick={submit} loading={loading} style={{ width: "100%" }} className="search-button">
                                    <span>Prijava</span>
                                </LoadingButton>

                            </Grid>
                            <Grid item xs={12} sx={{textAlign:'center'}}>
                                <Link onClick={() => {setEmail(username); setZaboravljenaLozinka(true)}}>Zaboravljena lozinka</Link>
                                {errorMessage != "" ? (
                                    <Box sx={{ border:'1px solid', borderColor:'#91d5ff', backgroundColor:'#e6f7ff', marginTop:'2em', minHeight:'5em', display:'flex', justifyContent:'center', flexDirection:'column' }}>
                                        {errorMessage}
                                    </Box>
                                ) : null}                        
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        window.location = "#/registracija";
                                        global.setLoggedOutComponent("registracija");
                                    }}
                                    //loading={loading}
                                    style={{ width: "100%" }}
                                    className="search-button"
                                    variant="outlined"
                                >
                                    Registracija
                                </Button>                                
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    onClick={() => {
                                        window.location = "#/upute";
                                        global.setLoggedOutComponent("login");
                                    }}
                                    //loading={loading}
                                    style={{ width: "100%" }}
                                    className="search-button"
                                    variant="outlined"
                                >
                                    Upute
                                </Button>                                
                            </Grid>

                        </Grid>
                ) : null}

                {/* {!zaboravljenaLozinka && !mailSent ? (
                    <Form style={{ width: "250px" }} name="normal_login" className="login-form" initialValues={{}} onFinish={onFinish}>
                        <Form.Item style={{ textAlign: "center" }}>
                            <table className="menu-logo" style={{ display: "inline-block", marginLeft: "-10px" }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <img src={logo} style={{ height: "64px", width: "64px" }}></img>
                                        </td>
                                        <td>
                                            <span style={{ marginLeft: "-8px", marginTop: "5px", display: "inline-block" }}>
                                                <p style={{ color: "#626262", fontFamily: "Righteous", fontSize: "24px" }}>CHATO</p>
                                                <p style={{ marginTop: "-35px", fontSize: "9px", color: "#757575" }}>Copyright 2023 IPT d.o.o.</p>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Form.Item>

                        <Form.Item name="username" rules={[{ required: true, message: "Molimo unesite korisničko ime!" }]}>
                            <Input onChange={usernameChanged} onKeyUp={onKeyUp} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, message: "Molimo unesite lozinku!" }]}>
                            <Input onChange={passwordChanged} onKeyUp={onKeyUp} prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
                        </Form.Item>

                        <Form.Item style={{ textAlign: "center" }}>
                            <Button variant="contained" onClick={submit} loading={loading} style={{ width: "100%" }} className="search-button">
                                Prijava
                            </Button>
                            <span
                                onClick={() => {
                                    setZaboravljenaLozinka(true);
                                }}
                                style={{ textDecoration: "underline", margin: "10px", display: "block", fontSize: "12px", color: "#989898", cursor: "pointer" }}
                            >
                                zaboravljena lozinka
                            </span>
                        </Form.Item>
                        {errorMessage != "" ? (
                            <Form.Item>
                                <Alert message={errorMessage}></Alert>
                            </Form.Item>
                        ) : null}

                        <Form.Item style={{ textAlign: "center", marginTop: "40px" }}>
                            <Button
                                onClick={() => {
                                    window.location = "#/registracija";
                                    global.setLoggedOutComponent("registracija");
                                }}
                                loading={loading}
                                style={{ width: "100%" }}
                                className="search-button"
                                variant="outlined"
                            >
                                Registracija
                            </Button>
                        </Form.Item>

                        <Form.Item style={{ textAlign: "center" }}></Form.Item>
                    </Form>
                ) : null} */}

                <Snack open={snackOpen} type={snackType} onClose={() => setSnackOpen(false)} message={snackMessage} />
            </Row>
            
            {pwdForcedChange ? (
                <ComponentContainer>

                        <Modal className="modal" open={pwdForcedChange}>
                            <Box sx={{...pwdChangeBoxStyle}}>
                                <Box>
                                
                                    <Grid container spacing={1}>

                                        <Grid item xs={12} style={{textAlign:'center', fontSize:'x-large'}}>

                                            Obvezna obnova lozinke
                                              
                                        </Grid>

                                        <Grid item xs={12} style={{textAlign:'center', fontSize:'medium', paddingBottom:'1em'}}>

                                            {pwdResetMessage ? "Razlog obnove: " +  pwdResetMessage : ""}
                                              
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl variant="outlined" fullWidth>
                                                <TextField
                                                    id="outlined-password-input1"
                                                    label="Nova lozinka"
                                                    type={showPassword ? 'text' : 'password'}
                                                    onChange={handleNewPwd1Change}
                                                    value={newPwd1}
                                                    size="small"
                                                    autoComplete="off"
                                                    inputProps={{ maxLength: 50 }}
                                                    error={newPwd1Edited && (newPwd1 == ''  || !isPwdValid(newPwd1) || newPwd1 == password || newPwd1 != newPwd2)}
                                                    helperText={newPwd1Edited && (newPwd1 == null || newPwd1 == '' ? 'Lozinka ne može biti prazna' : (!isPwdValid(newPwd1) ? 'Lozinka mora sadržavati minimalno 7 znakova i 1 broj' : (newPwd1 != newPwd2 ? 'Upisane lozinke se ne podudaraju' : (newPwd2 == password ? 'Lozinka mora biti različita od prethodne' : ''))))}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="start">
                                                            <IconButton
                                                                onClick={handleClickShowPassword}
                                                                edge="end"
                                                                tabIndex={-1}
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>                                                        
                                                        </InputAdornment>,
                                                    }}                                                    
                                                />
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormControl variant="outlined" fullWidth>
                                            <TextField
                                                    id="outlined-password-input2"
                                                    label="Ponovljena lozinka"
                                                    type={showPassword ? 'text' : 'password'}
                                                    onChange={handleNewPwd2Change}
                                                    value={newPwd2}
                                                    size="small"
                                                    autoComplete="off"
                                                    error={newPwd2Edited && (newPwd2 == null || newPwd2 == ''  || !isPwdValid(newPwd2) || newPwd2 == password || newPwd1 != newPwd2)}
                                                    helperText={newPwd2Edited && (newPwd2 == null || newPwd2 == '' ? 'Lozinka ne može biti prazna' : (!isPwdValid(newPwd2) ? 'Lozinka mora sadržavati minimalno 7 znakova i 1 broj' : (newPwd1 != newPwd2 ? 'Upisane lozinke se ne podudaraju' : (newPwd2 == password ? 'Lozinka mora biti različita od prethodne' : ''))))}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="start">
                                                            <IconButton
                                                                onClick={handleClickShowPassword}
                                                                edge="end"
                                                                tabIndex={-1}
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>                                                        
                                                        </InputAdornment>,
                                                    }}                                                    
                                                />
                                            </FormControl>  
                                        </Grid>

                                    </Grid>

                                </Box>
                                <Box display="flex" sx={{display:{ xs:"none", sm:"none", md:"flex" }, justifyContent:"flex-end"}}>
                                    <Button variant="contained" float="right" style={{ marginTop:"15px"}} disabled={!newPwdFormValid} onClick={() => spremiNovuLozinku()}><SaveIcon style={{ marginRight:"10px"}}></SaveIcon>Spremi</Button>
                                    <Button variant="outlined" float="right" color="error" style={{ marginTop:"15px", marginLeft:"4px"}} onClick={() => logout()} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Odustani</Button>
                                </Box>
                            </Box>
                        </Modal>
                </ComponentContainer>
            ) : null}

        </>
    );
}

export default Login;
