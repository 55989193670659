import { Autocomplete, Box, Typography, Grid, Button, FormControl, TextField } from "@mui/material";
import React, { useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import ExitToApp from "@mui/icons-material/ExitToApp";
import SaveIcon from '@mui/icons-material/Save';
import LineIcon from "react-lineicons";
import ArhivaNeelektronickaForm from "./arhivaNeelektronickaForm";
import ArhivaFileForm from "./arhivaFileForm";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function ArhivaPredmetForm(props) {

    const useConstructor = Util.useConstructor();

    const predmetID = props.predmetID;
    const setFormOpened = props.setFormOpened;
    const klijentID = props.klijentID;
    const predmetNaziv = props.predmetNaziv;
    const brojRegistratora = props.brojRegistratora;
    const setArhivaSaved = props.setArhivaSaved;

    const [loading, setLoading] = useState(true);
    const [tipArhiveOptions, setTipArhiveOptions] = useState([]);
    const [tipArhive, setTipArhive] = useState(null);
    const [edited, setEdited] = useState(false);
    const [canceled, setCanceled] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        api.get("archive/gettiparhiveoptions/" + predmetID, loadTipArhiveOptionsSuccess, loadTipArhiveOptionsFail);
    });

    function handleTipArhiveChange(e,v) {
        if(v){
            setTipArhive(v);
        }
    }

    function loadTipArhiveOptionsSuccess(data) {
        setTipArhiveOptions(data);
        setLoading(false);
        if(data && data.length > 0){
            setTipArhive(data[0]);
        }
    }

    function loadTipArhiveOptionsFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save() {

    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12} sx={{textAlign:'center'}}>
                    <Typography variant="h6" gutterBottom component="div" >
                        Stvaranje arhive pri zatvaraju predmeta
                    </Typography>    
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            autoHighlight
                            id="tip-arhive-select"
                            disabled={tipArhiveOptions.length == 0}
                            options={tipArhiveOptions}
                            value = {tipArhive}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={handleTipArhiveChange}
                            renderInput={(params) => <TextField {...params} label="Tip arhive" size="small" />}
                        />
                    </FormControl>

                </Grid>
                <Grid item xs={12}>
                    {!tipArhive ? (
                        <Box style={{width:"100%", display:"flex", justifyContent:"flex-end", marginTop:"10px"}}>
                            <Button color="error" variant="outlined" onClick={() => {setCanceled(true); setFormOpened(false);}}>Odustani</Button>
                        </Box>                
                    ): null}
                </Grid>
                <Grid item xs={12}>
                    {tipArhive && tipArhive.Elektronicka ? (
                        <ArhivaFileForm arhivaPredmet={true} setArhivaSaved={setArhivaSaved} setCanceled={setCanceled} setFormOpened={setFormOpened} predmetID={predmetID} tipArhiveID={tipArhive ? tipArhive.TipArhiveID : null}></ArhivaFileForm>
                    ) : null}
                </Grid>
                <Grid item xs={12}>
                    {tipArhive && !tipArhive.Elektronicka ? (
                        <ArhivaNeelektronickaForm arhivaPredmet={true} setArhivaSaved={setArhivaSaved} setCanceled={setCanceled} setFormOpened={setFormOpened} formArhiva={null} predmetID={predmetID} klijentID={klijentID} tipArhiveID={tipArhive ? tipArhive.TipArhiveID : null} tipArhiveOznaka={tipArhive ? tipArhive.Oznaka : null} tipArhiveNaziv={tipArhive ? tipArhive.Naziv : null} predmetNaziv={predmetNaziv} brojRegistratora={brojRegistratora}></ArhivaNeelektronickaForm>
                    ) : null}
                </Grid>

            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );

}

export default ArhivaPredmetForm;