import $ from "jquery";
import api from "./api";
import languages from "./languages";
import { useState, useRef, useContext } from "react";

import database from "./database";
import GlobalContext from "../components/globalContext";

const dataGridHrvatski = {
    // Root
    noRowsLabel: 'Nema podataka',
    noResultsOverlayLabel: 'Nema rezultata.',
    errorOverlayDefaultLabel: 'Greška!',
  
    // Density selector toolbar button text
    toolbarDensity: 'Gustoća',
    toolbarDensityLabel: 'Gustoća',
    toolbarDensityCompact: 'Kompaktno',
    toolbarDensityStandard: 'Standardno',
    toolbarDensityComfortable: 'Ugodno',
  
    // Columns selector toolbar button text
    toolbarColumns: 'Stupci',
    toolbarColumnsLabel: 'Odaberi stupce',
  
    // Filters toolbar button text
    toolbarFilters: 'Filteri',
    toolbarFiltersLabel: 'Prikaži filtere',
    toolbarFiltersTooltipHide: 'Sakrij filtere',
    toolbarFiltersTooltipShow: 'Prikaži filtere',
    toolbarFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} aktivnih filtera` : `${count} aktivan filter`,
  
    // Quick filter toolbar field
    toolbarQuickFilterPlaceholder: 'Traži…',
    toolbarQuickFilterLabel: 'Traži',
    toolbarQuickFilterDeleteIconLabel: 'Očisti',
  
    // Export selector toolbar button text
    toolbarExport: 'Export',
    toolbarExportLabel: 'Export',
    toolbarExportCSV: 'Preuzmi CSV',
    toolbarExportPrint: 'Ispiši',
    toolbarExportExcel: 'Preuzmi Excel',
  
    // Columns panel text
    columnsPanelTextFieldLabel: 'Pronađi stupac',
    columnsPanelTextFieldPlaceholder: 'Naziv stupca',
    columnsPanelDragIconLabel: 'Presloži stupce',
    columnsPanelShowAllButton: 'Prikaži sve',
    columnsPanelHideAllButton: 'Sakrij sve',
  
    // Filter panel text
    filterPanelAddFilter: 'Dodaj filter',
    filterPanelDeleteIconLabel: 'Obriši',
    filterPanelLinkOperator: 'Logički operator',
    filterPanelOperators: 'Operator', // TODO v6: rename to filterPanelOperator
    filterPanelOperatorAnd: 'I',
    filterPanelOperatorOr: 'ILI',
    filterPanelColumns: 'Stupci',
    filterPanelInputLabel: 'Vrijednost',
    filterPanelInputPlaceholder: 'Vrijednost filtera',
  
    // Filter operators text
    filterOperatorContains: 'sadrži',
    filterOperatorEquals: 'je jednako',
    filterOperatorStartsWith: 'započinje s',
    filterOperatorEndsWith: 'završava s',
    filterOperatorIs: 'je',
    filterOperatorNot: 'nije',
    filterOperatorAfter: 'je nakon',
    filterOperatorOnOrAfter: 'je na ili nakon',
    filterOperatorBefore: 'je prije',
    filterOperatorOnOrBefore: 'je na ili prije',
    filterOperatorIsEmpty: 'je prazan',
    filterOperatorIsNotEmpty: 'nije prazan',
    filterOperatorIsAnyOf: 'je ijedan od',
  
    // Filter values text
    filterValueAny: 'ijedan',
    filterValueTrue: 'istina',
    filterValueFalse: 'neistina',
  
    // Column menu text
    columnMenuLabel: 'Izbornik',
    columnMenuShowColumns: 'Prikaži stupce',
    columnMenuFilter: 'Filter',
    columnMenuHideColumn: 'Sakrij',
    columnMenuUnsort: 'Poništi sortiranje',
    columnMenuSortAsc: 'Sortiraj uzlazno',
    columnMenuSortDesc: 'Sortiraj silazno',
  
    // Column header text
    columnHeaderFiltersTooltipActive: (count) =>
      count !== 1 ? `${count} aktivnih filtera` : `${count} aktivan filter`,
    columnHeaderFiltersLabel: 'Prikaži filtere',
    columnHeaderSortIconLabel: 'Sortiraj',
  
    // Rows selected footer text
    footerRowSelected: (count) =>
      count !== 1
        ? `Označeno redaka: ${count.toLocaleString()}`
        : `${count.toLocaleString()} redak označen`,
  
    // Total row amount footer text
    footerTotalRows: 'Ukupno redaka:',
  
    // Total visible row amount footer text
    footerTotalVisibleRows: (visibleCount, totalCount) =>
      `${visibleCount.toLocaleString()} od ${totalCount.toLocaleString()}`,
  
    // Checkbox selection text
    checkboxSelectionHeaderName: 'Checkbox odabir',
    checkboxSelectionSelectAllRows: 'Odaberi sve',
    checkboxSelectionUnselectAllRows: 'Odaberi nijedan',
    checkboxSelectionSelectRow: 'Odaberi redak',
    checkboxSelectionUnselectRow: 'Poništi odabir retka',
  
    // Boolean cell text
    booleanCellTrueLabel: 'da',
    booleanCellFalseLabel: 'ne',
  
    // Actions cell more text
    actionsCellMore: 'više',
  
    // Column pinning text
    pinToLeft: 'Prikači lijevo',
    pinToRight: 'Prikači desno',
    unpin: 'Otkvači',
  
    // Tree Data
    treeDataGroupingHeaderName: 'Grupa',
    treeDataExpand: 'prikaži djecu',
    treeDataCollapse: 'sakrij djecu',
  
    // Grouping columns
    groupingColumnHeaderName: 'Grupiraj',
    groupColumn: (name) => `Grupiraj po ${name}`,
    unGroupColumn: (name) => `Poništi grupiranje po ${name}`,
  
    // Master/detail
    detailPanelToggle: 'Detalji',
    expandDetailPanel: 'Proširi',
    collapseDetailPanel: 'Zatvori',
  
    // Used core components translation keys
    MuiTablePagination: {},
  
    // Row reordering text
    rowReorderingHeaderName: 'Reorganizacija redaka',
  
    // Aggregation
    aggregationMenuItemHeader: 'Agregacija',
    aggregationFunctionLabelSum: 'suma',
    aggregationFunctionLabelAvg: 'prosjek',
    aggregationFunctionLabelMin: 'min',
    aggregationFunctionLabelMax: 'max',
    aggregationFunctionLabelSize: 'veličina',
};


export const Util = {
    confirm,
    alert,
    getParametersFromUrl,
    getCurrentPosition,
    getPlatform,
    getVersion,
    getOSVersion,
    translate,
    getCookie,
    setCookie,
    setLanguage,
    isMobileDevice,
    unixToDateTime,
    getSecondsBetweenDates,
    getSecondsToTime,
    getDateFromDateTimeString,
    getTimeFromDateTimeString,
    useConstructor,
    getDuration,
    getRoles,
    logout,
    OIBCheck,
    isValidEmail,
    isValidPhoneNumber,
    isValidJMBG,
    isValidNewUsername,
    izracunajIznosRacuna,
    izracunajPlacenoRacuna,
    izracunajIznosRacunskeStavke,
    izracunajPorezRacuna,
    izracunajPorezRacunskeStavke,
    getRacunDetalji,
    formatter,
    dataGridHrvatski,
    generateUUID,
    toStringDecimal,
    countDecimals,
    toBase64,
    izracunajIznosRacunaIzStavki,
    izracunajPorezRacunaIzStavki,
    valutaKonzistentna
};

function toBase64 (buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

function countDecimals(value) {
    if (value.indexOf(".") == -1) {
        return 0;
    } else if (Math.floor(parseFloat(value)) !== parseFloat(value)) {
        return value.toString().split(".")[1].length;
    } else {
        return 2;
    }
}

function toStringDecimal(value) {
    if (value != null) {
        ;
        let res = value.toString();
        var decimals = countDecimals(res);
        if (decimals == 0) {
            res = value + ".00";
        } else if (decimals == 1) {
            res = value + "0";
        } else if (decimals != 2) {
            res = toStringDecimal((Math.round( res * 100 ) / 100).toFixed(2));
        }
        return (Math.round( res * 100 ) / 100).toFixed(2);
    } else {
        return null;
    }
}

function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

function logout(){
    window.location = "#/login";
    database.set("user", null);
    database.set("zaposlenici", null);
    database.set("drugiZaposlenik", null);
    window.location.reload();
}

function getRoles(){
    return [1, 2];
}

function getTimeFromDateTimeString(date) {
    return date.toLocaleTimeString();
}

function getDateFromDateTimeString(date) {
    let todayDate = new Date();
    let tomorowDate = new Date();
    tomorowDate.setDate(tomorowDate.getDate() + 1);

    let today = todayDate.getDate() + "." + (todayDate.getMonth() + 1) + "." + todayDate.getFullYear();
    let tomorow = tomorowDate.getDate() + "." + (tomorowDate.getMonth() + 1) + "." + tomorowDate.getFullYear();
    let newDate = date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear();

    if (today == newDate) return translate("today");

    if (tomorow == newDate) return translate("tomorrow");

    return newDate;
}

function getSecondsToTime(seconds, dana) {
    let measuredTime = new Date(null);
    measuredTime.setSeconds(seconds); // specify value of SECONDS
    var isoTime = measuredTime.toISOString();

    var datumDo = new Date(isoTime.substr(0, 10));
    var datumOd = new Date("1970-01-01");
    var days = getDaysBetweenDates(datumOd, datumDo);

    var rtrn = isoTime.substr(11, 8);

    if (days >= 0) {
        rtrn = days + " " + dana + ", " + rtrn;
    }

    return rtrn;
}

function getSecondsBetweenDates(date1, date2) {
    let dif = date1.getTime() - date2.getTime();
    let Seconds_from_T1_to_T2 = dif / 1000;
    return Math.abs(Seconds_from_T1_to_T2);
}

function getDaysBetweenDates(date1, date2) {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

function unixToDateTime(data) {
    let unix_timestamp = data;
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    var date = new Date(unix_timestamp * 1000);
    // Hours part from the timestamp
    var hours = date.getHours();
    // Minutes part from the timestamp
    var minutes = "0" + date.getMinutes();
    // Seconds part from the timestamp
    var seconds = "0" + date.getSeconds();

    // Will display time in 10:30:23 format
    var formattedTime = hours + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);

    return formattedTime;
}

function isMobileDevice() {
    if (window.hasOwnProperty("cordova")) {
        return true;
    }

    // Or

    if (typeof cordova == "object") {
        return true;
    }
    // Or

    if (!!window.cordova) {
        return true;
    }
    
    const isMobile = window.matchMedia("only screen and (max-width: 760px)");
    if (/Mobi|Tablet|iPad|iPhone/i.test(navigator.userAgent) || isMobile.matches) {
        return true;
    }

    return false;
}

function confirm(text, title, callback, buttons){
    if (window.navigator.notification) {
        
        if(!title){
            title = translate('naslov');
        }

        if(!buttons){
            buttons = [translate('odustani'), translate('ok')]; 
        }

        window.navigator.notification.confirm(text || " ", callback, title, buttons);
    } else {
        if(window.confirm(text)){
            callback(2);
        }
        else{
            callback(1);
        }
    }
}



function getParametersFromUrl(url) {
    let params = {};
    (url + "?")
        .split("?")[1]
        .split("&")
        .forEach(function (pair) {
            pair = (pair + "=").split("=").map(decodeURIComponent);
            if (pair[0].length) {
                params[pair[0]] = pair[1];
            }
        });
    return params;
}

var getCurrentPositionSucessOut;
var getCurrentPositionErrorOut;

function getCurrentPosition(success, error) {
    getCurrentPositionSucessOut = success;
    getCurrentPositionErrorOut = error;

    if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(getCurrentPositionSuccess, getCurrentPositionError, { enableHighAccuracy: false, timeout: 5000 });
    } else {
        error(null);
    }
}

function getCurrentPositionSuccess(data) {
    getCurrentPositionSucessOut({ latitude: data.coords.latitude, longitude: data.coords.longitude });
}

function getCurrentPositionError(data) {
    getCurrentPositionErrorOut(data);
}

function getPlatform() {
    if (window.device) {
        return window.device.platform.toLowerCase();
    }
    return "Browser";
}

function getOSVersion(){
    if(window.device){
        return window.device.version;
    }
    else{
        //browser bi trebao biti
        return "browser";
    }
    return "N/A";
}


function getVersion(){
    if(window.AppVersion){
        return window.AppVersion.version
    }
    return "N/A";
}

function translate(text) {
    let lan = localStorage.getItem("lang");
    if (!lan) {
        //setCookie("lang", "hr");
        localStorage.setItem("lang", "EN");
        localStorage.setItem("langID", "2");
        lan = "EN";
    }

    let a = api.host;
    let b = languages.get(lan,text);
    if (languages.get(lan) && languages.get(lan,text)) {
        return languages.get(lan,text);
    } else {
        return "Nema prijevoda, key: " + text + ", jezik: " + lan;
    }
}

function setLanguage(lang) {
    //setCookie("lang", lang);
    localStorage.setItem("lang", lang.shortName);
    localStorage.setItem("langID", lang.languageId);
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function setCookie(cookieName, value, expirationDate) {
    if (expirationDate) {
        document.cookie = cookieName + "=" + value + "; path=/; expires=" + expirationDate.toUTCString();
    } else {
        document.cookie = cookieName + "=" + value + "; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    }
}


function useConstructor() {

    const hasBeenCalled = useRef(false);

    return (callBack = () => {}) => {
        
        if (hasBeenCalled.current) return;
        callBack();
        hasBeenCalled.current = true;
    };
 }

// function useConstructor(callBack = () => {}) {
//    const [hasBeenCalled, setHasBeenCalled] = useState(false);
//    if (hasBeenCalled) return;
//    callBack();
//    setHasBeenCalled(true);
// }
// function useConstructor(callBack = () => {}) {
//     const [hasBeenCalled, setHasBeenCalled] = useState(false);
//     if (hasBeenCalled) return;
//     callBack();
//     setHasBeenCalled(true);
// }
 
function getDuration(start, end) {
    var rtrn = "";

    var delta = ConvertToJSDate(end) - ConvertToJSDate(start);
    var minutes = Math.round(delta / 1000 / 60);

    if (isNaN(minutes)) {
        rtrn = "-";
    } else {
        var days = Math.floor(minutes / 1440);
        var restMinutes = minutes % 1440;
        var hours = Math.floor(restMinutes / 60);
        var minutes = restMinutes % 60;

        // var hours = Math.floor(minutes / 60);
        // var minutes = minutes % 60;

        var dayLabel = "";

        if (days <= 0) {
            dayLabel = "";
        } else {
            dayLabel = days.toString() + "d ";
        }

        if (minutes > 0) {
            rtrn = hours + "h " + minutes + "min";
        } else {
            rtrn = hours + "h";
        }
    }

    return dayLabel + rtrn;
}

function ConvertToJSDate(dateTime) {
    var convertedDate = dateTime.replace(".", " ").replace(".", " ").replace(":", " ");
    var splittedDate = convertedDate.split(" ");
    return new Date(splittedDate[2], splittedDate[1], splittedDate[0], splittedDate[3], splittedDate[4]);
}

function OIBCheck(oib) {
    oib = oib.toString();

    if (oib.length != 11) return false;

    var isNumeric = parseInt(oib, 10);

    if (isNaN(isNumeric)) return false;

    var a = 10;
    for (var i = 0; i < 10; i++) {
        a = a + parseInt(oib.substr(i, 1), 10);
        a = a % 10;
        if (a == 0) a = 10;
        a *= 2;
        a = a % 11;
    }

    var crc = 11 - a;
    if (crc == 10) crc = 0;
    return crc == parseInt(oib.substr(10, 1));
}

function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function isValidJMBG(JMBG) {
    
    if (!JMBG || isNaN(parseInt(JMBG)))
        return false;

    var intZbroj;
    var i;
    var intOstatak;
    var intK;

    if (JMBG.length != 13)
        return false;

    intZbroj = 0;
    intOstatak = 0;
    intK = 0;
    i = 0;

    //provjera da li je JMBG ispravan
    for (i = 2; i < 8; i++)
    {
        intZbroj = intZbroj + i * parseInt(JMBG.substring((12 - (i + 5)), (12 - (i + 5)) + 1)) + i * parseInt(JMBG.substring((12 - i + 1), (12 - i + 1) + 1));
    }
    
    intK = parseInt(JMBG.substring(12, 13));
    intOstatak = intZbroj % 11;
    var strSubs1 = JMBG.substring(7, 8);
    var strSubs2 = JMBG.substring(7, 9);

    if ((intOstatak == 0 & intK == 0) | (11 - intOstatak) == intK)
    {
        //državljanin RH
        if (strSubs1 != "3" & strSubs2 != "03")
            return false;
        return true;
    }
    else
        return false;

}

function getOsnoviceRacunskeStavke(stavka) {
    let osnovice = {};
    if (stavka.TipRacunskeStavkeID == 1) {
        if (stavka.StavkaSeNaplacuje == null || stavka.StavkaSeNaplacuje == true) {
            let iznos;
            stavka.StavkeRacunskeStavke.forEach(stavkaRacunskeStavke => {
                iznos = 0;
                if (stavkaRacunskeStavke.TipNaplatnostiID == 1) {
                    if (stavkaRacunskeStavke.Vrijednost != null) {
                        iznos = stavkaRacunskeStavke.Kolicina * stavkaRacunskeStavke.Vrijednost;
                    } else {
                        iznos = stavkaRacunskeStavke.Kolicina * stavkaRacunskeStavke.Iznos;
                    }
                }
                if (osnovice[stavkaRacunskeStavke.TipOporezivosti.Stopa] != null) {
                    osnovice[stavkaRacunskeStavke.TipOporezivosti.Stopa] = osnovice[stavkaRacunskeStavke.TipOporezivosti.Stopa] + iznos;
                } else {
                    osnovice[stavkaRacunskeStavke.TipOporezivosti.Stopa] = iznos;
                }
            });
            for (var stopa in osnovice) {
                if (Object.prototype.hasOwnProperty.call(osnovice, stopa)) {
                    osnovice[stopa] = roundToTwoDecimals(osnovice[stopa]);
                }
            }
        }
    } else {
        if (stavka.TipNaplatnostiID == 1) {
            let iznos = 0;
            if (stavka.Vrijednost != null) {
                iznos = stavka.Kolicina * stavka.Vrijednost;
            } else {
                iznos = stavka.Kolicina * stavka.Cijena;
            }
            iznos = iznos * (1 + (stavka.Marza || 0) / 100);
            iznos = iznos * (1 - (stavka.Rabat || 0) / 100);
            if (osnovice[stavka.TipOporezivosti.Stopa] != null) {
                osnovice[stavka.TipOporezivosti.Stopa] = osnovice[stavka.TipOporezivosti.Stopa] + roundToTwoDecimals(iznos);
            } else {
                osnovice[stavka.TipOporezivosti.Stopa] = roundToTwoDecimals(iznos);
            }
        }
    }
    return osnovice;
}

function izracunajIznosRacunskeStavke(stavka) {
    var iznos = 0;
    let osnovice = getOsnoviceRacunskeStavke(stavka);
    for (var stopa in osnovice) {
        if (Object.prototype.hasOwnProperty.call(osnovice, stopa)) {
            iznos += osnovice[stopa];
        }
    }
    return iznos;
}

function izracunajIznosRacuna(racun) {
    var iznos = 0;
    racun.RacunskeStavke.forEach(stavka => {
        iznos += izracunajIznosRacunskeStavke(stavka);
    });
    return iznos;
}

function round(value, decimals) {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

function roundToTwoDecimals(num) {
    return Number((Math.round(num * 100 ) / 100).toFixed(2));
    //return Number(Math.round(Math.round(num * 1000) / 10) / 100);
}

function izracunajPorezRacunskeStavke(stavka) {
    var porez = 0;
    let osnovice = getOsnoviceRacunskeStavke(stavka);
    for (var stopa in osnovice) {
        if (Object.prototype.hasOwnProperty.call(osnovice, stopa)) {
            porez += roundToTwoDecimals(Number(stopa) * osnovice[stopa]);
        }
    }
    return porez;
}

function izracunajPorezRacuna(racun) {
    var porez = 0;
    let osnoviceSveukupno = {};
    racun.RacunskeStavke.forEach(stavka => {
        let osnoviceStavka = getOsnoviceRacunskeStavke(stavka);
        for (var stopa in osnoviceStavka) {
            if (Object.prototype.hasOwnProperty.call(osnoviceStavka, stopa)) {
                if (osnoviceSveukupno[stopa] != null) {
                    osnoviceSveukupno[stopa] = osnoviceSveukupno[stopa] + osnoviceStavka[stopa];
                } else {
                    osnoviceSveukupno[stopa] = osnoviceStavka[stopa];
                }
            }
        }
    });
    for (var stopa in osnoviceSveukupno) {
        if (Object.prototype.hasOwnProperty.call(osnoviceSveukupno, stopa)) {
            porez += roundToTwoDecimals(Number(stopa) * osnoviceSveukupno[stopa]);
        }
    }
    return porez;
}

function izracunajPlacenoRacuna(racun) {
    let placeno = 0;
    racun.RasporediPlacanja.forEach(rp => {
        placeno += rp.Iznos;
    });
    //return (Math.round( placeno * 100 ) / 100).toFixed(2);
    return roundToTwoDecimals(placeno);
}

function getRacunDetalji(racun) {
    let iznos = izracunajIznosRacuna(racun);
    let porez = izracunajPorezRacuna(racun);
    let placeno = racun.PrisilnoZatvoreno ? (iznos + porez) : izracunajPlacenoRacuna(racun);
    let ostalo = racun.PrisilnoZatvoreno ? 0 : roundToTwoDecimals((iznos + porez) - placeno);
//    let ostalo = (iznos + porez) - placeno;
    return {
        Iznos : iznos,
        Porez : porez,
        Ukupno : iznos + porez,
        Placeno : placeno,
        Ostalo : ostalo
    };
}

function izracunajIznosRacunaIzStavki(stavke) {
    var iznos = 0;
    stavke.forEach(stavka => {
        iznos += izracunajIznosRacunskeStavke(stavka);
    });
    return iznos;
}


function izracunajPorezRacunaIzStavki(stavke) {
    var porez = 0;
    let osnoviceSveukupno = {};
    stavke.forEach(stavka => {
        let osnoviceStavka = getOsnoviceRacunskeStavke(stavka);
        for (var stopa in osnoviceStavka) {
            if (Object.prototype.hasOwnProperty.call(osnoviceStavka, stopa)) {
                if (osnoviceSveukupno[stopa] != null) {
                    osnoviceSveukupno[stopa] = osnoviceSveukupno[stopa] + osnoviceStavka[stopa];
                } else {
                    osnoviceSveukupno[stopa] = osnoviceStavka[stopa];
                }
            }
        }
    });
    for (var stopa in osnoviceSveukupno) {
        if (Object.prototype.hasOwnProperty.call(osnoviceSveukupno, stopa)) {
            porez += roundToTwoDecimals(Number(stopa) * osnoviceSveukupno[stopa]);
        }
    }
    return porez;
}

function formatter() {
    return new Intl.NumberFormat('hr-HR', {
            minimumFractionDigits: 2,      
            maximumFractionDigits: 2,
        });
}

function isValidPhoneNumber(phoneNumber) {
    //var re = /^(3859[125789][\d]{6,7})$/;

    var re = /^(385[1-9][0-9]{7,8})$/;

    return re.test(phoneNumber);
}

function isValidNewUsername(username){
    var re = /^[A-Za-z][A-Za-z0-9_.]{7,29}$/;

    return re.test(username);    
}

function valutaKonzistentna(predmet, cjenici, firmaValuta){
    //proći kroz sve cjenike ovog predmeta i vidjeti da li postoji i jedan s valutom koja je različita od zadane
    var localFirmaValutaID = firmaValuta ? firmaValuta.FirmaValutaID : null; //punimo s firmaValuta.FirmaValutaID, ako je dostavljeno
    var BreakException = {};
    if(predmet){
        //edit je, možda postoji i cjenik
        try{
            // predmet.Cjenici.forEach(cjenik => {
            //     localFirmaValutaID = !localFirmaValutaID ? cjenik.FirmaValutaID : localFirmaValutaID;   //ako nije bilo popunjeno, popunit će se u prvoj iteraciji cjenika
            //     if(cjenik.FirmaValutaID != localFirmaValutaID){
            //         //ovaj cjenik nije u zadanoj valuti
            //         throw BreakException;
            //     }
            // })
            cjenici.forEach(cjenik => {
                localFirmaValutaID = !localFirmaValutaID ? cjenik.FirmaValutaID : localFirmaValutaID;   //ako nije bilo popunjeno, popunit će se u prvoj iteraciji cjenika
                if(cjenik.FirmaValutaID != localFirmaValutaID){
                    //ovaj cjenik nije u zadanoj valuti
                    throw BreakException;
                }
            })
            return true;
        } catch(e){
            if (e === BreakException){
                return false;
            }
        }
    }else{
        //nije edit - ne može postojati cjenik
        return true;
    }
}


export default Util;
