import { Button, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import EventIcon from '@mui/icons-material/Event';
import api from "../util/api";
import globalContext from "./globalContext";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";
import AddEditDelForm from "./addEditDelForm";
import { Box } from "@mui/system";
import BlagdanForm from "./blagdanForm";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    padding:'15px',
    maxHeight: '95%',
    maxWidth: '95%',
    minWidth: '50%'
};

export default function Blagdani(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [blagdani, setBlagdani] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [automatskoPopunjavanjeAlert, setAutomatskoPopunjavanjeAlert] = useState(false);
    const [current, setCurrent] = useState(null);
    const [godina, setGodina] = useState(new Date());
    const [blagdanForEdit, setBlagdanForEdit] = useState(null);
    const [formOpened, setFormOpened] = useState(false);

    useConstructor(() => {
        loadBlagdani(null);
    });

    function loadBlagdani(god) {
        api.get("blagdani/get?firmaID=" + global.user.firmaID + "&god=" + (god ? god.getFullYear() : godina.getFullYear()), loadBlagdaniSuccess, fail);
    }

    function loadBlagdaniSuccess(data) {
        setBlagdani(data);
        if (data.length > 0) {
            setCurrent(data[0]);
        } else {
            setCurrent(null);
        }
        setLoading(false);
    }

    function fail(data) {
        if (deleteAlert) {
            setDeleteAlert(false);
        }
        if (automatskoPopunjavanjeAlert) {
            setAutomatskoPopunjavanjeAlert(false);
        }
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function add() {    
        setBlagdanForEdit(null);
        setFormOpened(true);
    }

    function edit() {    
        setBlagdanForEdit(current);
        setFormOpened(true);
    }

    function del() {
        api.get("blagdani/delete/" + current.BlagdanID, delSuccess, fail);
    }

    function delSuccess() {
        setDeleteAlert(false);
        loadBlagdani(null);
    }

    function automatskoPopunjavanje() {
        setLoading(true);
        api.get("blagdani/popuni/" + godina.getFullYear(), automatskoPopunjavanjeSuccess, fail);
    }

    function automatskoPopunjavanjeSuccess(data) {
        setAutomatskoPopunjavanjeAlert(false);
        loadBlagdani(null);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Typography variant="h4" gutterBottom component="div">
                        <EventIcon fontSize="medium" style={{marginRight:"15px"}}></EventIcon>
                        Blagdani
                    </Typography>   
                </Grid>
                <Grid item xs={4} style={{display:"flex", alignItems:"center"}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker
                            views={['year']}
                            label="Godina"
                            value={godina}
                            inputFormat="yyyy"
                            onChange={(v) => {setGodina(v); if (!isNaN(new Date(v))) {loadBlagdani(v)}}}
                            renderInput={(params) => <TextField style={{width:"99%"}} {...params} size="small" />}
                        />
                    </LocalizationProvider>   
                </Grid>
                <Grid item xs={12}>
                    <AddEditDelForm dataType="blagdani" automatskoPopunjavanje={() => setAutomatskoPopunjavanjeAlert(true)} automatskoPopunjavanjeIcon={<EditCalendarIcon></EditCalendarIcon>} data={blagdani} current={current} setCurrent={setCurrent} disableEdit={!current} disableDeleteButton={!current} add={add} edit={edit} del={() => setDeleteAlert(true)}></AddEditDelForm>
                </Grid>
            </Grid>

            <Modal className="modal" open={formOpened}>
                <Box overflow="auto" sx={style}>
                    <BlagdanForm formBlagdan={blagdanForEdit} loadBlagdani={loadBlagdani} close={() => setFormOpened(false)} ></BlagdanForm>
                </Box>
            </Modal>

            {deleteAlert ? <AlertDialog title={"Brisanje blagdana"} message={"Jeste li sigurni da želite obrisati odabrani blagdan?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => setDeleteAlert(false)} confirmAction={del}></AlertDialog> : null}

            {automatskoPopunjavanjeAlert ? <AlertDialog title={"Automatsko popunjavanje blagdana"} message={"Automatskim popunjavanjem dodat će se svi blagdani Vaše firme s fiksnim datumom iz prethodne godine, ili osnovni blagdani, ukoliko u prethodnoj godini za Vašu firmu nije bio dodan niti jedan takav blagdan. Dodatno, bit će uneseni i osnovni blagdani s promjenjivim datumom (Uskrs, Uskrsni ponedjeljak i Tijelovo)."} cancelLabel={"Odustani"} confirmLabel={"Popuni"} cancelAction={() => setAutomatskoPopunjavanjeAlert(false)} confirmAction={automatskoPopunjavanje}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
        </ComponentContainer>
    );
}