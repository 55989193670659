import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';

export default function AlertDialog(props) {
    const [open, setOpen] = useState(true);
    const message = props.message;
    const title = props.title;
    const checkboxMessage = props.checkboxMessage;
    const checkbox = props.checkbox;
    const setCheckbox = props.setCheckbox;
    const cancelLabel = props.cancelLabel;
    const cancelAction = props.cancelAction;
    const cancelHidden = typeof props.cancelHidden === 'undefined' ? false : props.cancelHidden;
    const confirmLabel = props.confirmLabel;
    const confirmAction = props.confirmAction;
    const confirmDisabled = props.confirmDisabled;
    const confirmHidden = typeof props.confirmHidden === 'undefined' ? false : props.confirmHidden;// props.confirmHidden || false;
    const okLabel = props.okLabel;
    const okAction = props.okAction;
    const okHidden = typeof props.okHidden === 'undefined' ? true : props.okHidden;
    const icon = props.icon;
    const buttonsLayout = props.buttonsLayout || "default";

    const handleClose = (arg) => {
        if (arg == 1) {
            confirmAction();
            setOpen(false);
        } else if (arg == 2) {
            cancelAction();
            setOpen(false);
        } else if (arg == 3) {
            okAction();
            setOpen(false);
        }
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                {...(props.minWidth ? {
                    sx: {
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                              minWidth: props.minWidth
                            },
                        }
                    }
                } : {})}
            >
                <DialogContent>
                    {title ? (
                        <DialogContentText id="alert-dialog-description" sx={{fontSize:"x-large", fontWeight:"bold", textAlign:"center", marginBottom:"1em"}}>
                            {icon ? (
                                <div style={{
                                    display: 'inline-flex',
                                    verticalAlign: 'text-bottom',
                                    boxSizing: 'inherit',
                                    textAlign: 'center',
                                    alignItems: 'center'
                                }}>
                                    {icon}
                                    {title}
                                </div>
                            ) : title}
                        </DialogContentText>

                    ) : null}
                    <DialogContentText id="alert-dialog-description" sx={{textAlign:"justify", marginRight:"1em", marginLeft:"1em"}}>
                        {message}
                    </DialogContentText>
                    {checkboxMessage && setCheckbox ? (
                            <FormControlLabel
                                labelPlacement="end" 
                                label={<Typography variant="body" gutterBottom style={{width:"100%"}} component="div">{checkboxMessage}</Typography>}
                                style={{marginLeft:"0px", marginTop:"15px"}}
                                control={<Checkbox checked={checkbox} onChange={e => setCheckbox(e.target.checked)}></Checkbox>}>
                            </FormControlLabel>
                    ) : null}
                </DialogContent>
                {buttonsLayout == "default" ? (
                    <DialogActions sx={{marginBottom:"1em", marginRight:"1em"}}>
                        {!confirmHidden ? (
                            <Button variant="contained" onClick={() => handleClose(1)} disabled={confirmDisabled} autoFocus>{confirmLabel}</Button>
                        ) : null}
                        {!okHidden ? (
                            <Button variant="outlined" onClick={() => handleClose(3)}>{okLabel}</Button>
                        ) : null}
                        {!cancelHidden ? (
                            <Button variant="outlined" onClick={() => handleClose(2)}>{cancelLabel}</Button>
                        ) : null}
                    </DialogActions>
                ) : null}
                {buttonsLayout == "vertical" ? (
                    <DialogActions sx={{display:"block"}}>
                        {!confirmHidden ? (
                            <Button variant="contained" style={{width:"100%"}} onClick={() => handleClose(1)} disabled={confirmDisabled} autoFocus>{confirmLabel}</Button>
                        ) : null}
                        {!okHidden ? (
                            <Button variant="outlined" style={{width:"100%", marginLeft:0, marginTop:"5px"}} onClick={() => handleClose(3)}>{okLabel}</Button>
                        ) : null}
                        {!cancelHidden ? (
                            <Button variant="outlined" style={{width:"100%", marginLeft:0, marginTop:"5px"}} onClick={() => handleClose(2)}>{cancelLabel}</Button>
                        ) : null}
                    </DialogActions>
                ) : null}
            </Dialog>
    </div>
    );
}