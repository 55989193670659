import { Autocomplete, Box, Button, FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import dateFormat, { masks } from "dateformat";
import Util from "../util/util";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import globalContext from "./globalContext";
import api from "../util/api";
import ComponentContainer from "./componentContainer";
import ExitToApp from "@mui/icons-material/ExitToApp";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function PrebaciStavkuNaDrugiRacunForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const stavke = props.stavke;
    const setStavke = props.setStavke;
    const setFormOpened = props.setFormOpened;
    const racunskaStavkaID = props.racunskaStavkaID;
    const filterRacuni = props.filterRacuni;

    const [loading, setLoading] = useState(true);
    const [racuni, setRacuni] = useState([]);
    const [racun, setRacun] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        api.get("racun/getnefakturiraniforfirma/" + global.user.firmaID, loadSuccess, loadFail);
    });

    function loadSuccess(data) {
        setRacuni(data);
        setLoading(false);
    }

    function loadFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save() {
        let resource = {
            RacunskaStavkaID : racunskaStavkaID,
            RacunID : racun.RacunID
        };
        api.post("racunskastavka/prebacinaracun", resource, saveSuccess, saveFail);
    }

    function saveSuccess() {
        //let newStavke = stavke.filter(s => s.RacunskaStavkaID != racunskaStavkaID);
        //setStavke(newStavke);
        //setLoading(false);
        filterRacuni(null, null, null);
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid>
                    <Typography variant="h6" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        Odaberite račun na koji želite prebaciti odabranu računsku stavku:
                    </Typography>   
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            options={racuni}
                            autoHighlight
                            value={racun}
                            getOptionLabel={(option) => "(ID: " + option.RacunID + ") " + option.KlijentNaziv + " - " + dateFormat(option.Datum, "dd.mm.yyyy")}
                            onChange={(e,v) => {if (v) setRacun(v)}}
                            renderInput={(params) => <TextField {...params} label="Račun" size="small" style={{width:"99%"}} error={!racun} required />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} startIcon={<MoveUpIcon></MoveUpIcon>} variant="contained" style={{marginRight:"10px"}} color="success" disabled={loading || !racun}> Prebaci</Button>
                        <Button variant="outlined" color="error" onClick={() =>  setFormOpened(false)} ><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Odustani</Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default PrebaciStavkuNaDrugiRacunForm;