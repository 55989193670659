import { Badge, Button, Menu, Tooltip } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { UserOutlined, SettingOutlined, EditOutlined, LoginOutlined, LogoutOutlined, BarChartOutlined, CalendarOutlined, KeyOutlined } from "@ant-design/icons";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SearchIcon from "@mui/icons-material/Search";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import { Link } from "react-router-dom";
import database from "../util/database";
import GlobalContext from "../components/globalContext";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import TimelineIcon from "@mui/icons-material/Timeline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import upute from "../images/chato-upute.pdf";
import HelpIcon from '@mui/icons-material/Help';
import TuneIcon from '@mui/icons-material/Tune';
import IosShareIcon from '@mui/icons-material/IosShare';
import Util from "../util/util";
import { LinkedIn } from "@mui/icons-material";
import api from "../util/api";
import AlertDialog from "./alertdialog";
import { Grid, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { hr } from "date-fns/locale";
import ComponentContainer from "./componentContainer";
import ErrorIcon from '@mui/icons-material/Error';
import EditIcon from "@mui/icons-material/Edit";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const { SubMenu } = Menu;

const probniPeriodMessage = ""

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

function MainMenu(props) {
    function logout() {
        Util.logout();
        window.location = "/#/login";
    }

    const useConstructor = Util.useConstructor();
    const global = useContext(GlobalContext);
    const [pathname, setPathname] = useState("http://localhost:3000/#/calendar");
    const isLoggedIn = !!global.user;
    const isMobileDevice = Util.isMobileDevice();

    const roles = database.get("roles") || [];
    const zahtjevOdobrenTrigger = props.zahtjevOdobrenTrigger;

    const administrator = roles.indexOf("1") > -1;
    const upisnikAktivnosti = roles.indexOf("2") > -1;
    const upisnikAktivnostiZaDruge = roles.indexOf("3") > -1;
    const backOfficeManager = roles.indexOf("4") > -1;
    const frontOfficeManager = roles.indexOf("5") > -1;
    const reportingManager = roles.indexOf("6") > -1;
    const administratorArhvie = roles.indexOf("7") > -1;
    const administratorPrijenosaAktivnosti = roles.indexOf("8") > -1;
    const administratorIzradeRacuna = roles.indexOf("9") > -1;
    const tecajManager = roles.indexOf("10") > -1;
    const korisnikManager = roles.indexOf("12") > -1;
    const postavkeManager = roles.indexOf("13") > -1;
    const [probniPeriodAlert, setProbniPeriodAlert] = useState(false);
    const [exportFormOpened, setExportFormOpened] = useState(false);
    const [zahtjeviBadge, setZahtjeviBadge] = useState(false);
    const [evidencijeExportDatumOd, setEvidencijeExportDatumOd] = useState(new Date());
    const [evidencijeExportDatumDo, setEvidencijeExportDatumDo] = useState(new Date());
    const [racuniExportDatumOd, setRacuniExportDatumOd] = useState(new Date());
    const [racuniExportDatumDo, setRacuniExportDatumDo] = useState(new Date());
    const [exportLoading, setExportLoading] = useState(false);
    const [probniPeriodMessage, setProbniPeriodMessage] = useState("");
    
    useConstructor(() => {
    });

    function getProbniPeriodMessageSuccess(data) {
        let parser = new DOMParser();
        
        let htmlObject = document.createElement("p");
        htmlObject.innerHTML = parser.parseFromString(data, "text/html");
        setProbniPeriodMessage(data);
        
        setProbniPeriodAlert(true);
    }

    let interval = null;

    useEffect(() => {
        if (global.user && global.user.probniPeriod == "True") {
            api.get("postavke/getprobniperiodmessage", getProbniPeriodMessageSuccess, null);
        }
    }, [global.user]);

    useEffect(() => {
        if (global.roles && global.roles.includes("21") && interval == null) {
            interval = setInterval(() => {
                api.get("korisnici/provjerineobradene", (data) => setZahtjeviBadge(data), null);
            }, 60000);
        }
    }, [global.roles]);

    useEffect(() => {
        return () => {
            if (interval != null) {
                clearInterval(interval);
            }
        };
    }, []);

    useEffect(() => {
        if (global.roles && global.roles.includes("21")) {
            api.get("korisnici/provjerineobradene", (data) => setZahtjeviBadge(data), null);
        }
    }, [zahtjevOdobrenTrigger]);

    function onLinkClick(data) {
        if (data.key != "Upute" && data.key != "Odabir teme" && data.key != "Probni period" && data.key != "Export") {
            global.setSelectedTab(data.key);
        }
    }

    function onStartDragItem(e) {
        let links = e.target.href.split("#");
        console.log(e);
        e.dataTransfer.setData("menulink", e.target.innerHTML);
        e.stopPropagation();
  
    }

    function evidencijaMenuClick(e) {
        global.setSelectedTab("Evidencija");
        window.location = "#/evidencija";
        e.stopPropagation();
    }
    // function dragg(e){
    //     e.dataTransfer.setData();
    // }

    function exportPodataka() {
        setExportLoading(true);
        let resource = {
            FirmaID : global.user.firmaID,
            EvidencijeDatumOd : evidencijeExportDatumOd,
            EvidencijeDatumDo : evidencijeExportDatumDo,
            RacuniDatumOd : racuniExportDatumOd,
            RacuniDatumDo : racuniExportDatumDo
        };
        api.post("firma/export", resource, () => {
            setExportLoading(false);
            setExportFormOpened(false);
        }, null);
    }
  
    return isLoggedIn ? (
        <div style={{ height: "100vh" }}>
            <Menu theme={global.theme == "light" ? "light" : "dark"} mode={isMobileDevice ? "horizontal" : "inline"} collapsedWidth="50" onSelect={onLinkClick} selectedKeys={[global.selectedTab]}>
                {upisnikAktivnosti || upisnikAktivnostiZaDruge ? (
                    <SubMenu onDragStart={(e) => e.dataTransfer.setData("menulink", "Evidencija")} draggable={true} className={global.selectedTab == "Evidencija" ? "ant-menu-item-selected" : null} key="Evidencija" icon={<EditIcon onClick={evidencijaMenuClick} />} title={<div onClick={evidencijaMenuClick}>Evidencija</div>}>
                        <Menu.Item key="Pretraga evidencije" icon={<SearchIcon />}>
                            <Link to="/pretragaevidencije" onDragStart={onStartDragItem} draggable={true}>
                                Pretraga evidencije
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                ) : null}

                {global && global.roles && (global.roles.includes("23") || global.roles.includes("24")) ? (
                    <Menu.Item key="Zadaci" icon={<TaskAltIcon />}>
                        <Link to="/zadaci" onDragStart={onStartDragItem} draggable={true}>
                            Zadaci
                        </Link>
                    </Menu.Item>
                ) : null}

                {administrator ? (
                    <SubMenu key="administracija" className="noselect" icon={<SettingsSuggestIcon />} title="Administracija" onDragStart={(e) => e.dataTransfer.setData("menulink", "Postavke")} draggable={false} >
                        {/* <Menu.Item key="postavke" icon={<SettingOutlined />}>
                        <Link  onDragStart={onStartDragItem} draggable={true} to="/postavke">Postavke</Link>
                    </Menu.Item> */}
                        {postavkeManager ? (
                            <Menu.Item key="Postavke" icon={<SettingOutlined />}>
                                <Link onDragStart={onStartDragItem} draggable={true} to="/postavke">
                                    Postavke
                                </Link>
                            </Menu.Item>
                        ) : null}

                        {korisnikManager ? (
                            <Menu.Item key="Korisnici" icon={<GroupIcon />}>
                                <Link onDragStart={onStartDragItem} draggable={true} to="/korisnici">
                                    Korisnici
                                </Link>
                            </Menu.Item>
                        ) : null}

                        {/* <Menu.Item key="s2i0-1-izvještaji">
                            <Link to="/izvjestaji">
                                Izvještaji
                            </Link>
                        </Menu.Item> */}
                        <SubMenu key="sifrarnici" title="Šifrarnici" className="noselect">
                            {/* <Menu.Item key="s2i0-1-1">
                                <Link  to="/projekti">
                                    Projekti
                                </Link>
                            </Menu.Item> */}
                            <Menu.Item key="Klijenti">
                                <Link to="/klijenti" onDragStart={onStartDragItem} draggable={true}>
                                    Klijenti
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="Grupe klijenata">
                                <Link to="/grupe" onDragStart={onStartDragItem} draggable={true}>
                                    Grupe klijenata
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="Predmeti">
                                <Link to="/predmeti" onDragStart={onStartDragItem} draggable={true}>
                                    Predmeti
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="Odjeli">
                                <Link to="/odjeli" onDragStart={onStartDragItem} draggable={true}>
                                    Odjeli
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="Tipovi korisnika">
                                <Link to="/tipovikorisnika" onDragStart={onStartDragItem} draggable={true}>
                                    Tipovi korisnika
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="mjesta">
                                <Link to="/mjesta" onDragStart={onStartDragItem} draggable={true}>
                                    Mjesta
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="Nepredmetne kategorije">
                                <Tooltip title="Nepredmetne kategorije" mouseEnterDelay={0.3}>
                                    <Link to="/nepredmetnekategorije" onDragStart={onStartDragItem} draggable={true}>
                                        Nepredmetne kategorije
                                    </Link>
                                </Tooltip>
                            </Menu.Item>
                            <Menu.Item key="Konto">
                                <Link to="/konto" onDragStart={onStartDragItem} draggable={true}>
                                    Konto
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="Načini plaćanja">
                                <Link to="/nacinplacanja" onDragStart={onStartDragItem} draggable={true}>
                                    Načini plaćanja
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="Poslovni prostori">
                                <Link to="/poslovniprostori" onDragStart={onStartDragItem} draggable={true}>
                                    Poslovni prostori
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="Satnice rada">
                                <Link to="/satnicerada" onDragStart={onStartDragItem} draggable={true}>
                                    Satnice rada
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="Blagdani">
                                <Link to="/blagdani" onDragStart={onStartDragItem} draggable={true}>
                                    Blagdani
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="Komponente dokumenata">
                                <Link to="/reportrjecnik" onDragStart={onStartDragItem} draggable={true}>
                                    Komponente dokumenata
                                </Link>
                            </Menu.Item>
                            {/* <Menu.Item key="Sudovi">
                                <Link to="/sudovi" onDragStart={onStartDragItem} draggable={true}>
                                    Sudovi
                                </Link>
                            </Menu.Item> */}
                            {/* <Menu.Item key="korisnici">
                            <Link to="/korisnici">Korisnici</Link>
                        </Menu.Item>
                        <Menu.Item key="lokacije">
                            <Link to="/lokacije">Lokacije</Link>
                        </Menu.Item>
                        <Menu.Item key="struke">
                            <Link to="/struke">Struke</Link>
                        </Menu.Item>
                        <Menu.Item key="rangovi">
                            <Link to="/rangovi">Rangovi</Link>
                        </Menu.Item>
                        <Menu.Item key="grupe">
                            <Link to="/grupe">Grupe</Link>
                        </Menu.Item>
                        <Menu.Item key="blagdani">
                            <Link to="/blagdani">Blagdani</Link>
                        </Menu.Item> */}
                        </SubMenu>
                    </SubMenu>
                ) : null}

                {/* {upisnikAktivnostiZaDruge ? (
                <SubMenu key="evidencijaradnogvremena" icon={<CalendarOutlined />} title="Evidencija radnog vremena">
                    <Menu.Item key="izvjestaji">
                        <Link to="/pregledkorisnika">Izvještaji</Link>
                    </Menu.Item>
                    <Menu.Item key="prebacaj">
                        <Link to="/pregledkorisnika">Prebačaj</Link>
                    </Menu.Item>
                </SubMenu>
            ) : null} */}

                {administratorIzradeRacuna ? (
                    <SubMenu key="racuni" icon={<ReceiptLongIcon />} title="Računi" className="noselect"  onDragStart={(e) => e.dataTransfer.setData("menulink", "Upravljanje")} draggable={false} >
                        {/* <Menu.Item key="s2i0-1-024">
                        <Link to="/racuniizvjestaji">Izvještaji</Link>
                    </Menu.Item> */}
                        <Menu.Item key="Upravljanje">
                            <Link to="/racuni" onDragStart={onStartDragItem} draggable={true}>
                                Upravljanje
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="Financijska kartica">
                            <Link to="/financijskakartica" onDragStart={onStartDragItem} draggable={true}>
                                Financijska kartica
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                ) : null}

                {administratorArhvie ? (
                    <Menu.Item key="Arhiva" icon={<AllInboxIcon />}>
                        <Link to="/arhiva" onDragStart={onStartDragItem} draggable={true}>
                            Arhiva
                        </Link>
                    </Menu.Item>
                ) : null}

                {tecajManager ? (
                    <Menu.Item key="Tečaj" icon={<TimelineIcon></TimelineIcon>}>
                        <Link to="/tecaj" onDragStart={onStartDragItem} draggable={true}>
                            Tečaj
                        </Link>
                    </Menu.Item>
                ) : null}

                {reportingManager ? (
                    <Menu.Item key="Izvještaji" icon={<AssessmentIcon></AssessmentIcon>}>
                        <Link to="/izvjestaj" onDragStart={onStartDragItem} draggable={true}>
                            Izvještaji
                        </Link>
                    </Menu.Item>
                ) : null}

                {administratorPrijenosaAktivnosti ? (
                    <Menu.Item key="Prijenos" icon={<MoveUpIcon />}>
                        <Link to="/prijenos" onDragStart={onStartDragItem} draggable={true}>
                            Prijenos
                        </Link>
                    </Menu.Item>
                ) : null}

                {global.user && global.roles && global.roles.includes("21") ? (
                    <SubMenu key="Interno" icon={<TuneIcon />} title={<span>IPT interno&nbsp;&nbsp;{zahtjeviBadge && <Badge status="error"></Badge>}</span>}>
                        <Menu.Item key="RegistracijaInterno">                            
                            <Link to="/registracijainterno" onDragStart={onStartDragItem} draggable={true}>
                                Registracija
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="Zahtjevi">
                            {zahtjeviBadge && <Badge status="error"></Badge>}
                            <Link to="/zahtjevi" onDragStart={onStartDragItem} draggable={true}>
                                Zahtjevi
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="Aktivnost">
                            <Link to="/aktivnost" onDragStart={onStartDragItem} draggable={true}>
                                Aktivnost
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="Verifikacija">
                            <Link to="/verifikacija" onDragStart={onStartDragItem} draggable={true}>
                                Verifikacija
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="Fiskalizacija">
                            <Link to="/fiskalizacija" onDragStart={onStartDragItem} draggable={true}>
                                Fiskalizacija
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="DodatneNaplate">
                            <Link to="/dodatnenaplate" onDragStart={onStartDragItem} draggable={true}>
                                Dodatne naplate
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="Licence">
                            <Link to="/licence" onDragStart={onStartDragItem} draggable={true}>
                                Licence
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="Postavke sustava">
                            <Link to="/postavkesustava" onDragStart={onStartDragItem} draggable={true}>
                                Postavke sustava
                            </Link>
                        </Menu.Item>
                    </SubMenu>
                ) : null}

                <Menu.Item key="Upute" icon={<HelpIcon />} onClick={() => window.open(upute, "_blank")}>
                    Upute
                </Menu.Item>

                
                {global.user && global.roles && global.roles.includes("22") ? (
                    <Menu.Item key="Export" icon={<IosShareIcon />} onClick={() => setExportFormOpened(true)}>
                        Export podataka
                    </Menu.Item>
                ) : null}

                <Menu.Item key="Odjava" icon={<LogoutOutlined />} style={{ marginBottom: "10px" }} onClick={logout}>
                    Odjava
                </Menu.Item>

                {!global.navCollapsed && global.user.probniPeriod == "True" ? (
                    <Menu.Item key="Probni period" style={{ paddingLeft: "10px", textAlign: "center" }}>
                        <div>
                            <Button type="primary" danger onClick={() => setProbniPeriodAlert(true)}>
                                Ugovori i aktiviraj
                            </Button>
                        </div>
                    </Menu.Item>
                ) : null}

                {!global.navCollapsed ? (
                    <Menu.Item key="Odabir teme" style={{ paddingLeft: 0 }}>
                        <div className="themepickers">
                            <span className="themecirclelight" onClick={() => global.setTheme("light")}></span>{" "}
                            <span
                                className="themecircledark"
                                onClick={() => {
                                    global.setTheme("dark");
                                }}
                            ></span>
                        </div>
                    </Menu.Item>
                ) : null}
            </Menu>

            {probniPeriodAlert ? (
                <AlertDialog
                    title={"Probni period"}
                    message={
                        <div dangerouslySetInnerHTML={{__html: probniPeriodMessage || "<div></div>"}}></div>
                    }
                    cancelHidden={true}
                    confirmHidden={true}
                    okHidden={false}
                    okLabel="U redu"
                    okAction={() => setProbniPeriodAlert(false)}
                ></AlertDialog>
            ) : null}

            <Modal open={exportFormOpened}>
                <Box sx={style} overflow={"auto"} >
                    <ComponentContainer loading={exportLoading}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Export podataka
                                </Typography>
                            </Grid>
                            <Grid item xs={6} marginTop="15px">
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} fullWidth>
                                    <DatePicker
                                        label="Evidencije - od datuma"
                                        value={evidencijeExportDatumOd}
                                        inputFormat="dd.MM.yyyy."
                                        onChange={v => setEvidencijeExportDatumOd(v)}
                                        renderInput={(params) => <TextField style={{width:"100%"}} {...params} required error={!evidencijeExportDatumOd || isNaN(new Date(evidencijeExportDatumOd))} size="small"/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6} marginTop="15px">
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} fullWidth>
                                    <DatePicker
                                        label="Evidencije - do datuma"
                                        value={evidencijeExportDatumDo}
                                        inputFormat="dd.MM.yyyy."
                                        onChange={v => setEvidencijeExportDatumDo(v)}
                                        renderInput={(params) => <TextField style={{width:"100%"}} {...params} required error={!evidencijeExportDatumDo || isNaN(new Date(evidencijeExportDatumDo))} size="small"/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} fullWidth>
                                    <DatePicker
                                        label="Računi - od datuma"
                                        value={racuniExportDatumOd}
                                        inputFormat="dd.MM.yyyy."
                                        onChange={v => setRacuniExportDatumOd(v)}
                                        renderInput={(params) => <TextField style={{width:"100%"}} {...params} required error={!racuniExportDatumOd || isNaN(new Date(racuniExportDatumOd))} size="small"/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} fullWidth>
                                    <DatePicker
                                        label="Računi - do datuma"
                                        value={racuniExportDatumDo}
                                        inputFormat="dd.MM.yyyy."
                                        onChange={v => setRacuniExportDatumDo(v)}
                                        renderInput={(params) => <TextField style={{width:"100%"}} {...params} required error={!racuniExportDatumDo || isNaN(new Date(racuniExportDatumDo))} size="small"/>}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} style={{marginTop:"15px", textAlign:"right"}}>
                                <Button type="primary" onClick={exportPodataka} style={{marginRight:"5px"}}>Export</Button>
                                <Button onClick={() => setExportFormOpened(false)}>Odustani</Button>
                            </Grid>
                        </Grid>
                    </ComponentContainer>
                </Box>
            </Modal>
        </div>
    ) : null;
    // <Menu theme="dark" mode={isMobileDevice ? "horizontal" : "inline"} collapsedWidth="50">
    //     <Menu.Item key="Prijava" icon={<LoginOutlined />}>
    //         <Link to="/login" onDragStart={onStartDragItem} draggable={true}>
    //             Prijava
    //         </Link>
    //     </Menu.Item>
    // </Menu>
}
export default MainMenu;
