import { Autocomplete, Box, Button, Divider, FormControl, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ComponentContainer from "./componentContainer";
import EditIcon from '@mui/icons-material/Edit';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import LineIcon from "react-lineicons";
import UnosTroskaForm from "./unosTroskaForm";
import UnosAktivnostiForm from "./unosAktivnostiForm";
import Util from "../util/util";
import dateFormat, { masks } from "dateformat";

function EvidencijaForm(props) {

    const useConstructor = Util.useConstructor();

    //console.log(props.evidencija);

    const formEvidencija = props.evidencija;
    const tipoviEvidencije = props.tipoviEvidencije;
    const setCanceled = props.setCanceled;
    const setFormOpened = props.setFormOpened;
    const tipEvidencijeID = props.tipEvidencijeID;

    const [tipEvidencije, setTipEvidencije] = useState(null);
    const [formValid, setFormValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [edited, setEdited] = useState(false);
    const [titleVisible, setTitleVisible] = useState(true);

    const unsavedChanges = useRef(false);

    useConstructor(() => {
        if (formEvidencija) {
            tipoviEvidencije.forEach(tip => {
                if (tip.TipEvidencijeID == formEvidencija.TipEvidencijeID) {
                    setTipEvidencije(tip);
                }
            });
        }
    });

    useEffect(() => {
        window.onbeforeunload = confirmExit;
        function confirmExit(e)
        {
            if (unsavedChanges.current) {
                return "Možda postoje izmjene koje nisu spremljene. Jeste li sigurni da želite zatvoriti aplikaciju?";  // browseri vise ne podrzavaju custom poruke na zatvaranju prozora, tako da je svejedno sto ovdje pise           
            } else {
                return null;
            }
        }
    }, []);

    function save() {

    }

    return (
        <ComponentContainer>
            <Grid container spacing={1}>
                {titleVisible ? (
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formEvidencija ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <MoreTimeIcon fontSize="medium" style={{marginRight:"15px"}}></MoreTimeIcon>
                            )}
                            {formEvidencija ? (
                                "Uređivanje evidencije" + (tipEvidencijeID == 1 ? " aktivnosti" : " troška") //+ " za datum " + dateFormat(formEvidencija.PocetakRada, "dd.mm.yyyy")
                            ) : (
                                "Nova evidencija" + (tipEvidencijeID == 1 ? " aktivnosti" : " troška") //+ " za datum " + dateFormat(props.date, "dd.mm.yyyy")
                            )}
                        </div>
                    </Typography>
                </Grid>
                ) : null}
                {/* <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            options={tipoviEvidencije}
                            value={tipEvidencije}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {setTipEvidencije(v)}}
                            renderInput={(params) => <TextField {...params} style={{width:"99%"}} label="Tip evidencije" size="small" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} marginBottom="10px">
                    <Divider></Divider>
                </Grid> */}
                {tipEvidencijeID == 1 ? (
                    <UnosAktivnostiForm
                        minsButtons={props.minsButtons}
                        evidencija={formEvidencija}
                        date={props.date}
                        predmeti={props.predmeti}
                        nepredmetneKategorije={props.nepredmetneKategorije}
                        korisnikID={props.korisnikID}
                        klijenti={props.klijenti}
                        kontakti={props.kontakti}
                        predlosci={props.predlosci}
                        radnoVrijemeDefStart={props.radnoVrijemeDefStart}
                        aktivnostDefTrajanjeMin={props.aktivnostDefTrajanjeMin}
                        tipoviOporezivosti={props.tipoviOporezivosti}
                        tipoviNaplatnosti={props.tipoviNaplatnosti}
                        firmaValute={props.firmaValute}
                        defaultFirmaValuta={props.defaultFirmaValuta}
                        setFormOpened={setFormOpened}
                        setCanceled={setCanceled}
                        setTitleVisible={setTitleVisible}
                        tipNaplatnostiNaplatnoBoja={props.tipNaplatnostiNaplatnoBoja}
                        tipNaplatnostiNenaplatnoBoja={props.tipNaplatnostiNenaplatnoBoja}
                        mogucePrebacitiAktivnostiBezCijene={props.mogucePrebacitiAktivnostiBezCijene}
                        defaultTarifnaAktivnostOmoguciIznosNula={props.defaultTarifnaAktivnostOmoguciIznosNula}
                        samoOtvoreniPredmeti={props.samoOtvoreniPredmeti}
                        setSamoOtvoreniPredmeti={props.setSamoOtvoreniPredmeti}
                        samoAktivniKlijenti={props.samoAktivniKlijenti}
                        setSamoAktivniKlijenti={props.setSamoAktivniKlijenti}
                        omoguciPohrani={props.omoguciPohrani}
                        loadEvidencije={props.loadEvidencije}
                        povezaniZadaci={props.povezaniZadaci}
                        zadatakZaEvidencijuID={props.zadatakZaEvidencijuID}
                        zadatakPredmeti={props.zadatakPredmeti}
                        zadatakOpis={props.zadatakOpis}
                        zadatakPocetak={props.zadatakPocetak}
                        zadatakKraj={props.zadatakKraj}
                        tipZadatakVrstaID={props.tipZadatakVrstaID}
                        pretragaPredmeti={props.pretragaPredmeti}
                        brojRedovaPredlozaka={props.brojRedovaPredlozaka}
                        evidencijaUPrenesenoRazdobljeMessage={props.evidencijaUPrenesenoRazdobljeMessage}
                        unsavedChanges={unsavedChanges}
                    ></UnosAktivnostiForm>
                ) : null}
                {tipEvidencijeID == 2 ? (
                    <UnosTroskaForm
                        evidencija={formEvidencija}
                        predmeti={props.predmeti}
                        klijenti={props.klijenti}
                        tipoviOporezivosti={props.tipoviOporezivosti}
                        tipoviNaplatnosti={props.tipoviNaplatnosti}
                        firmaValute={props.firmaValute}
                        setFormOpened={setFormOpened}
                        setCanceled={setCanceled}
                        defaultFirmaValuta={props.defaultFirmaValuta}
                        defaultTrosakTipOporezivosti={props.defaultTrosakTipOporezivosti}
                        date={props.date}
                        tipNaplatnostiNaplatnoBoja={props.tipNaplatnostiNaplatnoBoja}
                        tipNaplatnostiNenaplatnoBoja={props.tipNaplatnostiNenaplatnoBoja}
                        loadEvidencije={props.loadEvidencije}
                        evidencijaUPrenesenoRazdobljeMessage={props.evidencijaUPrenesenoRazdobljeMessage}
                        unsavedChanges={unsavedChanges}
                    ></UnosTroskaForm>
                ) : null}
                {!tipEvidencijeID ? (
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                            <Button variant="outlined" style={{marginRight:"10px"}} color="error" onClick={() => {setCanceled(true); setFormOpened(false)}} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Odustani</Button>
                        </Box>
                    </Grid>
                ) : null}
            </Grid>
        </ComponentContainer>
    );
}

export default EvidencijaForm;