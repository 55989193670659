import { useState, useRef, useContext, useEffect } from "react";
import "./css/app.css";
import "./css/antd.css";
import "antd/dist/antd.css";
import Navigation from "./components/navigation";
import React from "react";
import GlobalContext from "./components/globalContext";
import Util from "./util/util";
import { createTheme } from "@mui/material/styles";
import api from "./util/api";
import database from "./util/database";
import ComponentContainer from "./components/componentContainer";
import { HashRouter } from "react-router-dom";

function App() {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [username, setUsername] = useState(null);
    const [selectedTab, setSelectedTab] = useState(null);
    const [zaposlenici, setZaposlenici] = useState([]);     // svi zaposlenici firme
    const [drugiZaposlenik, setDrugiZaposlenik] = useState(null);      // odabrani zaposlenik (u navigacijskoj traci)
    const [dostupniZaposlenici, setDostupniZaposlenici] = useState([]);     // zaposlenici koji su dostupni za odabir, mogu biti razliciti za upis evidencija i za upravljanje racunima
    const [dostupniZaposleniciLastChangedComponent, setDostupniZaposleniciLastChangedComponent] = useState("");     // sprjecava beskonacno ucitavanje
    const useConstructor = Util.useConstructor();
    const [logoFile, setLogoFile] = useState(null);
    const [logoPosition, setLogoPosition] = useState(1);
    const [theme, setTheme] = useState("dark");
    const [roles, setRoles] = useState(null);
    const [navCollapsed, setNavCollapsed] = useState(null);
    const [loggedOutComponent, setLoggedOutComponent] = useState(null);
                                
    useConstructor(() => {
        let t = database.get("theme");
        if(t){  
            setTheme(t);
        }else{
            setTheme("dark");
        }
    });

    const darkTheme = createTheme({

        palette: {type:'dark'}
        // palette: {
        //     type: 'light',
        //     primary: {
        //       main: '#0a1d31',
        //       dark: '#f91fb4',
        //       light: '#ec27d4',
        //     },
        //     secondary: {
        //       main: '#f50057',
        //     },
        //   },
    });

    const onSetUser = (newUser) => {
        if(user && (user.KorisnikID != newUser.KorisnikID))
        {
            setUser(newUser);
        }
        else{
            setUser(newUser);
        }
    };

    const onSetUsername = (username) => {

        if(username != username)
            setUsername(username);
    };

    const onSetSelectedTab = (key) => {
        setSelectedTab(key);
    };

    const onSetLogoFile = (file) => {
        setLogoFile(file);
    }

    const onSetLogoPosition = (position) => {
        setLogoPosition(position);
    }

    const onSetTheme = (t) => {
        setTheme(t);
        database.set("theme", t);
    }

    const onSetNavCollapsed = (t) => {
        setNavCollapsed(t);
    }

    return (
            <GlobalContext.Provider value={{ user: user, 
                                             setUser: onSetUser, 
                                             username: username, 
                                             setUsername: onSetUsername, 
                                             selectedTab: selectedTab, 
                                             setSelectedTab: setSelectedTab, 
                                             zaposlenici: zaposlenici, 
                                             setZaposlenici: setZaposlenici, 
                                             drugiZaposlenik: drugiZaposlenik, 
                                             setDrugiZaposlenik: setDrugiZaposlenik, 
                                             dostupniZaposlenici : dostupniZaposlenici,
                                             setDostupniZaposlenici : setDostupniZaposlenici,
                                             dostupniZaposleniciLastChangedComponent : dostupniZaposleniciLastChangedComponent,
                                             setDostupniZaposleniciLastChangedComponent : setDostupniZaposleniciLastChangedComponent,
                                             loading: loading,
                                             setLoading: setLoading,
                                             logoFile: logoFile,
                                             setLogoFile: onSetLogoFile,
                                             logoPosition: logoPosition,
                                             setLogoPosition: onSetLogoPosition,
                                             setTheme: onSetTheme,
                                             theme: theme,
                                             roles: roles,
                                             setRoles: setRoles,
                                             setNavCollapsed: onSetNavCollapsed,
                                             navCollapsed: navCollapsed,
                                             loggedOutComponent: loggedOutComponent,
                                             setLoggedOutComponent: setLoggedOutComponent
                                        }}>
                <HashRouter>
                    <Navigation></Navigation>
                </HashRouter>
            </GlobalContext.Provider>
    );
}

export default App;
