import { ClickAwayListener, Grid, Modal, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import BusinessIcon from '@mui/icons-material/Business';
import { Box } from "@mui/system";
import AddEditDelForm from "./addEditDelForm";
import PoslovniProstorForm from "./poslovniProstorForm";
import StoreIcon from '@mui/icons-material/Store';
import BlagajnaForm from "./blagajnaForm";
import { findGeometry } from "@antv/g2plot/lib/utils";
import AlertDialog from "./alertdialog";
import ErrorAlert from "./errorAlert";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

function PoslovniProstori(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [poslovniProstori, setPoslovniProstori] = useState([]);
    const [currentProstor, setCurrentProstor] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [formOpened, setFormOpened] = useState(false);
    const [formProstor, setFormProstor] = useState(null);
    const [currentBlagajna, setCurrentBlagajna] = useState(null);
    const [blagajnaFormOpened, setBlagajnaFormOpened] = useState(false);
    const [formBlagajna, setFormBlagajna] = useState(null);
    const [deleteBlagajnaAlert, setDeleteBlagajnaAlert] = useState(false);
    const [poslovniProstorID, setPoslovniProstorID] = useState(-1);
    const [blagajnaID, setBlagajnaID] = useState(-1);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        loadPoslovniProstori();
    });

    function loadPoslovniProstori() {
        setLoading(true);
        api.get("poslovniprostor/getforfirma/" + global.user.firmaID, loadPoslovniProstoriSuccess, loadPoslovniProstoriFail);
    }

    function loadPoslovniProstoriSuccess(data) {
        setPoslovniProstori(data);
        if (data.length > 0) {
            setCurrentProstor(data.at(0));
            if (data.at(0).Blagajne.length > 0) {
                setCurrentBlagajna(data.at(0).Blagajne.at(0));
            }
        }
        setLoading(false);
    }

    function loadPoslovniProstoriFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function add() {
        setFormProstor(null);
        setFormOpened(true);
    }

    function edit() {
        setFormProstor(currentProstor);
        setFormOpened(true);
    }

    function addBlagajna() {
        setFormBlagajna(null);
        setBlagajnaFormOpened(true);
    }

    function editBlagajna() {
        setFormBlagajna(currentBlagajna);
        setBlagajnaFormOpened(true);
    }

    useEffect(() => {
        if (currentProstor) {
            setPoslovniProstorID(currentProstor.PoslovniProstorID);
        }
    }, [currentProstor]);

    useEffect(() => {
        if (currentBlagajna) {
            setBlagajnaID(currentBlagajna.BlagajnaID);
        }
    }, [currentBlagajna]);

    function deletePoslovniProstor() {
        setLoading(true);
        api.post("poslovniprostor/delete/" + poslovniProstorID, null, deletePoslovniProstorSuccess, deletePoslovniProstorFail);
    }

    function deletePoslovniProstorSuccess() {
        setLoading(false);
        setDeleteAlert(false);
        loadPoslovniProstori();
    }

    function deletePoslovniProstorFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setDeleteAlert(false);
        setLoading(false);
    }

    function deleteBlagajna() {
        setLoading(true);
        api.post("poslovniprostor/deleteblagajna/" + blagajnaID, null, deleteBlagajnaSuccess, deleteBlagajnaFail);
    }

    function deleteBlagajnaSuccess() {
        setLoading(false);
        setDeleteBlagajnaAlert(false);
        loadPoslovniProstori();
    }

    function deleteBlagajnaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setDeleteBlagajnaAlert(false);
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <BusinessIcon fontSize="medium" style={{marginRight:"15px"}}></BusinessIcon>
                        Poslovni prostori
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{width:"100%", marginTop:"20px"}}>
                        <AddEditDelForm dataType="poslovniProstori" data={poslovniProstori} disableDeleteButton={currentProstor && currentProstor.KoristiSe} current={currentProstor} setCurrent={setCurrentProstor} add={add} edit={edit} del={() => setDeleteAlert(true)}></AddEditDelForm>
                    
                        {currentProstor ? (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" gutterBottom component="div" style={{marginBottom:"15px"}}>
                                        <StoreIcon fontSize="medium" style={{marginRight:"15px"}}></StoreIcon>
                                        Blagajne
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <AddEditDelForm dataType="blagajne" data={currentProstor.Blagajne} disableDeleteButton={currentBlagajna && (currentBlagajna.KoristiSe || currentBlagajna.Fiskalna)} formBlagajna={formBlagajna} current={currentBlagajna} setCurrent={setCurrentBlagajna} add={addBlagajna} edit={editBlagajna} del={() => setDeleteBlagajnaAlert(true)}></AddEditDelForm>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Box>
                </Grid>
        </Grid>  
            
        <Modal open={formOpened}>
            <Box sx={style} overflow={"auto"} >
                <PoslovniProstorForm loadPoslovniProstori={loadPoslovniProstori} firmaID={global.user.firmaID} formProstor={formProstor} setFormOpened={setFormOpened} ></PoslovniProstorForm>
            </Box>
        </Modal>  
            
        <Modal open={blagajnaFormOpened}>
            <Box sx={style} overflow={"auto"} >
                <BlagajnaForm loadPoslovniProstori={loadPoslovniProstori} poslovniProstori={[]} firmaID={global.user.firmaID} formBlagajna={formBlagajna} fiskalna={formBlagajna ? formBlagajna.Fiskalna : false} setFormOpened={setBlagajnaFormOpened} poslovniProstorID={poslovniProstorID}></BlagajnaForm>
            </Box>
        </Modal>

        {deleteAlert ? <AlertDialog title="Brisanje poslovnog prostora" message={"Jeste li sigurni da želite obrisati odabrani poslovni prostor i njegove blagajne?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => setDeleteAlert(false)} confirmAction={deletePoslovniProstor}></AlertDialog> : null}

        {deleteBlagajnaAlert ? <AlertDialog title="Brisanje blagajne" message={"Jeste li sigurni da želite obrisati odabranu blagajnu?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => setDeleteBlagajnaAlert(false)} confirmAction={deleteBlagajna}></AlertDialog> : null}

        <Modal className="modal" open={errorMessage != null}>
            <Box sx={style}>
                <ErrorAlert message={errorMessage} close={() => setErrorMessage(null)}></ErrorAlert>
            </Box>
        </Modal>
        </ComponentContainer>
    );
}

export default PoslovniProstori;