import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { InputAdornment, Divider, Tooltip, Paper, List, ListItem, ListItemText, Button, FormControl, Grid, Box, Input, InputLabel, Autocomplete, TextField, Typography, Modal } from "@mui/material";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import SearchIcon from '@mui/icons-material/Search';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Util from "../util/util";
import api from "../util/api";
import ComponentContainer from "./componentContainer";
import AddEditDelForm from "./addEditDelForm";
import AlertDialog from "./alertdialog";
import globalContext from "./globalContext";
import dateFormat, { masks } from "dateformat";
import { CurrencyRubleTwoTone } from "@mui/icons-material";
import PretragaEvidencijeListItem from "./pretragaEvidencijeListItem";
import { hr } from "date-fns/locale";
import ErrorIcon from '@mui/icons-material/Error';
import CreateIcon from '@mui/icons-material/Create';
import EvidencijaForm from "./evidencijaForm";

const tipoviEvidencije = [
    { Naziv : "Svi", TipEvidencijeID : 0},
    { Naziv : "Samo aktivnosti", TipEvidencijeID : 1},
    { Naziv : "Samo troškovi", TipEvidencijeID: 2}
];

const tipoviPredmetnosti = [
    { Naziv : "Svi", TipPredmetnostiID : 0},
    { Naziv : "Samo predmetne evidencije", TipPredmetnostiID : 1},
    { Naziv : "Samo nepredmetne evidencije", TipPredmetnostiID: 2}
];

const tipoviNaplatnosti = [
    { Naziv : "Svi", TipNaplatnostiID : 0},
    { Naziv : "Samo naplatne evidencije", TipNaplatnostiID : 1},
    { Naziv : "Samo nenaplatne evidencije", TipNaplatnostiID: 2}
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    padding:'15px',
    maxHeight: '95%',
    maxWidth: '95%'
};


export default function PretragaEvidencije(props) {
    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const klijentiPredmeti = props.klijentiPredmeti || {
        Klijenti : [],
        Predmeti: [],
        KlijentiLastUpdate : null,
        PredmetiLastUpdate : null
    };
    const loadKlijentiAndPredmeti = props.loadKlijentiAndPredmeti;

    const [value, setValue] = React.useState();
    const [korisnik, setKorisnik] = useState(null);
    const [korisnici, setKorisnici] = useState(null);
    const [klijent, setKlijent] = useState(null);
    const [predmet, setPredmet] = useState(null);
    const [klijenti, setKlijenti] = useState([]);
    const [predmeti, setPredmeti] = useState([]);
    const [datumOd, setDatumOd] = useState(null);
    const [datumDo, setDatumDo] = useState(null);
    const [tekstPretrage, setTekstPretrage] = useState(null);
    const [valid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tipEvidencije, setTipEvidencije] = useState(tipoviEvidencije[0]);
    const [tipPredmetnosti, setTipPredmetnosti] = useState(tipoviPredmetnosti[0]);
    const [rezultatPretrage, setRezultatPretrage] = useState([]);
    const [currentRow, setCurrentRow] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [infoAlert, setInfoAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [evidencijaInfo, setEvidencijaInfo] = useState(null);
    const [evidencijaOpened, setEvidencijaOpened] = useState(false);
    const [ukupnoSati, setUkupnoSati] = useState("");
    const [ukupnoTroskova, setUkupnoTroskova] = useState("");
    const [tipNaplatnosti, setTipNaplatnosti] = useState(tipoviNaplatnosti[0]);

    function handleChange(e) {
        setValue(e.target.value);
    }

    useConstructor(() => {
        setLoading(true);
        api.get("korisnik/pretragafilter", pretragaFilterSuccess, pretragaFilterFail);
    });

    function pretragaFilterSuccess(data){
        if(data){
            setLoading(false)
            let opcijeKorisnika = [{ KorisnikID: 0, Ime: "Svi", Prezime: "" }, ...data.Korisnici];
            setKorisnici(opcijeKorisnika);
            if(global && global.roles && global.roles.includes("15")){
                if(data.Korisnik == null){
                    setKorisnik(opcijeKorisnika[0]);
                }
                else{
                    setKorisnik(data.Korisnik);
                }
            }
            else{
                setKorisnik(data.Korisnici.find(k => k.KorisnikID == global.user.korisnikID));
            }
            let opcijeKlijenata = [{ KlijentID: 0, Naziv: "Svi", Broj: 0 }, ...klijentiPredmeti.Klijenti];
            setKlijenti(opcijeKlijenata);
            var urlParams = Util.getParametersFromUrl(document.location);
            if (urlParams.klijentID) {
                setKlijent(opcijeKlijenata.find(k => k.KlijentID == parseInt(urlParams.klijentID)))
            } else if (data.Klijent == null) {
                setKlijent(opcijeKlijenata[0]);
            } else{
                setKlijent(data.Klijent);
            }
            let opcijePredmeta = [{ PredmetID: 0, Naziv: "Svi", Broj: 0 }, ...klijentiPredmeti.Predmeti];
            setPredmeti(opcijePredmeta);
            if (urlParams.predmetID) {
                setPredmet(opcijePredmeta.find(p => p.PredmetID == parseInt(urlParams.predmetID)))
            } else if (data.Predmet == null) {
                setPredmet(opcijePredmeta[0]);
            } else {
                setPredmet(data.Predmet);
            }
            setDatumOd(new Date(data.DatumOd));
            setDatumDo(new Date(data.DatumDo));
            if(data.TipPredmetnosti == null){
                setTipPredmetnosti(tipoviPredmetnosti[0]);
            }
            else{
                setTipPredmetnosti(tipoviPredmetnosti.find(tp => tp.TipPredmetnostiID == data.TipPredmetnosti.TipPredmetnostiID))
            }
            if(data.TipEvidencije == null){
                setTipEvidencije(tipoviEvidencije[0]);
            }
            else{
                setTipEvidencije(tipoviEvidencije.find(tp => tp.TipEvidencijeID == data.TipEvidencije.TipEvidencijeID))
            }
            if(data.TipNaplatnosti == null){
                setTipNaplatnosti(tipoviNaplatnosti[0]);
            }
            else{
                setTipNaplatnosti(tipoviNaplatnosti.find(tn => tn.TipNaplatnostiID == data.TipNaplatnosti.TipNaplatnostiID))
            }
        }

    }

    function pretragaFilterFail(data){
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    // useEffect(() => {
    //     if (tekstPretrage == null || tekstPretrage == '') {
    //         let a = 1;
    //     }
    // }, [tekstPretrage]);

    function search(){
        setLoading(true);
        let resource ={
            KorisnikID: korisnik && korisnik.KorisnikID != 0 ? korisnik.KorisnikID : null,
            KlijentID: klijent && klijent.KlijentID != 0 ? klijent.KlijentID : null,
            PredmetID: predmet && predmet.PredmetID != 0 ? predmet.PredmetID : null,
            DatumOd: datumOd,
            DatumDo: datumDo,
            TekstPretrage: tekstPretrage,
            TipEvidencije: tipEvidencije && tipEvidencije.TipEvidencijeID != 0 ? tipEvidencije : null,
            TipPredmetnosti: tipPredmetnosti && tipPredmetnosti.TipPredmetnostiID != 0 ? tipPredmetnosti : null,
            TipNaplatnosti: tipNaplatnosti && tipNaplatnosti.TipNaplatnostiID != 0 ? tipNaplatnosti : null
        }
        api.post("evidencija/pretraga/", resource, pretragaSuccess, pretragaFail);
    }

    function pretragaSuccess(data){
        setLoading(false);
        setUkupnoSati(data.UkupnoSati);
        setUkupnoTroskova(data.UkupnoTroskova);
        setRezultatPretrage(data.Evidencije);
        if(data.Evidencije.length > 0){
            setCurrentRow(data.Evidencije[0]);
        }
        else{
            setCurrentRow(null);
            setInfoAlert(true);
        }
    }

    function pretragaFail(data){
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        setValid(datumOd && datumDo && !isNaN(new Date(datumOd)) && !isNaN(new Date(datumDo)) && datumDo >= datumOd && (tekstPretrage && tekstPretrage.length > 2 || (klijent && klijent.KlijentID != 0) || (predmet && predmet.PredmetID != 0)));
    }, [datumOd, datumDo, tekstPretrage, klijent, predmet]);

    useEffect(() => {
        if(currentRow){
            setCurrentData({
                Opis: currentRow.Opis,
                Korisnik: currentRow.Korisnik.Ime + ' ' + currentRow.Korisnik.Prezime, 
                Datum: dateFormat(currentRow.Datum, "dd.mm.yyyy"),
                Trajanje: currentRow.TipNaziv == "Aktivnost" || currentRow.TipPredmetnosti.TipPredmetnostiID == 2 ? currentRow.Trajanje : currentRow.Iznos,
                KlijentNaziv: currentRow.TipPredmetnosti.TipPredmetnostiID == 1 ? currentRow.Klijent.Broj + ' - ' + currentRow.Klijent.Naziv : '',
                PredmetNaziv: currentRow.TipPredmetnosti.TipPredmetnostiID == 1 ? currentRow.Predmet.Broj  + ' - ' + currentRow.Predmet.Naziv : '',
                StatusPrijenosa: currentRow.StatusPrijenosa,
                StatusPrijenosaBoja: currentRow.StatusPrijenosaBoja,
                KlijentBoja: currentRow.TipPredmetnosti.TipPredmetnostiID == 1 && currentRow.Klijent.TipStatusaKlijentaID == 2 ? 'red' : 'black',
                PredmetBoja: currentRow.TipPredmetnosti.TipPredmetnostiID == 1 ? (currentRow.Predmet.TipaStanjaPredmetaID > 1 ? 'red' : (currentRow.Predmet.TipNaplateID == 3 ? 'green' :(currentRow.Predmet.TipNaplateID == 2 ? 'magenta' : 'black'))): 'black',
                TipBoja: currentRow.Boja,
                TipNaziv: currentRow.TipNaziv,
                NaplatnostBoja: currentRow.NaplatnostBoja,
                NaplatnostNaziv: currentRow.TipNaplatnosti && currentRow.TipNaplatnosti.TipNaplatnostiID == 1 ? "Naplatno" : "Nenaplatno",
                Kontakt: currentRow.Kontakt ? currentRow.Kontakt.Naziv : 'N/A',
                TipPredmetnostiID: currentRow.TipPredmetnosti.TipPredmetnostiID
            });
        }
        else{
            setCurrentData(null);
        }
    }, [currentRow]);

    function handleTableClick(item){
        setCurrentRow(item);    
    }

    function evidencija() {
        setLoading(true);
        api.get("evidencija/pretragatoevidencijainfo?korisnikID=" + global.user.korisnikID + "&predmetID=" + (predmet && predmet.PredmetID > 0 ? predmet.PredmetID : "0"), evidencijaInfoSuccess, evidencijaInfoFail);
    }

    function evidencijaInfoSuccess(data) {
        setEvidencijaInfo(data);
        setEvidencijaOpened(true);
        setLoading(false);
    }

    function evidencijaInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function saveSamoOtvoreniPredmeti(samoOtvoreniPredmeti) {
        api.get("korisnik/spremidefsamootvorenipredmeti/" + samoOtvoreniPredmeti);
    }

    function saveSamoAktivniKlijenti(samoAktivniKlijenti) {
        api.get("korisnik/spremidefsamoaktivniklijenti/" + samoAktivniKlijenti);
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container spacing={1}>
                <Grid item md={12}>
                    <Typography variant="h4" gutterBottom component="div" style={{marginBottom:"15px"}}>
                        <ContentPasteSearchIcon fontSize="medium" style={{marginRight:"15px"}}></ContentPasteSearchIcon>
                        Pretraga evidencija
                    </Typography>
                </Grid>

                <Grid item md={4}>
                    <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Datum od"
                            value={datumOd}
                            inputFormat="dd.MM.yyyy"
                            onChange={(newValue) => {
                                setDatumOd(newValue);
                            }}
                            renderInput={(params) => <TextField size="small" {...params} error={!datumOd || isNaN(new Date(datumOd)) || datumDo < datumOd} sx={{width: '100%'}} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={4}>
                    <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Datum do"
                            value={datumDo}
                            inputFormat="dd.MM.yyyy"
                            onChange={(newValue) => {
                                setDatumDo(newValue);
                            }}                                       
                            renderInput={(params) => <TextField size="small" {...params} error={!datumDo || isNaN(new Date(datumDo)) || datumDo < datumOd} sx={{width: '100%'}} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>

                </Grid>
                <Grid item xs={8}>
                    <Grid container columnSpacing={1}>
                        <Grid item md={3} >
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal
                                    autoHighlight
                                    disableClearable
                                    id="zaposlenik-select"
                                    disabled={!korisnici || korisnici.length == 0 || !global || !global.roles || !global.roles.includes("15")}
                                    options={korisnici}
                                    value={korisnik}
                                    getOptionLabel={(option) => option.Ime + " " + option.Prezime}
                                    renderOption={(props, option) => {
                                        return (
                                        <li {...props} key={"korisnik" + option.KorisnikID} style={{color: option.KorisnikID == global.user.korisnikID ? "blue" : (option.TipStatusaKorisnikaID == 1 || option.KorisnikID == 0 ? "black" : "red")}}>
                                            {option.Ime + " " + option.Prezime}
                                        </li>
                                        );
                                    }}                                           
                                    onChange={(e,v) => setKorisnik(v)}
                                    renderInput={(params) => <TextField sx={{ input: { color: korisnik && korisnik.TipStatusaKorisnikaID != 1 && korisnik.KorisnikID != 0 ? "red" : "black"} }} {...params} label="Korisnik" size="small" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} >
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal
                                    autoHighlight
                                    disableClearable
                                    id="tipevidencije-select"
                                    options={tipoviEvidencije}
                                    value={tipEvidencije}
                                    getOptionLabel={(option) => option.Naziv}
                                    onChange={(e,v) => setTipEvidencije(v)}
                                    renderInput={(params) => <TextField {...params} label="Tip evidencije" size="small" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} >
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal
                                    autoHighlight
                                    disableClearable
                                    id="tippredmetnosti-select"
                                    options={tipoviPredmetnosti}
                                    value={tipPredmetnosti}
                                    getOptionLabel={(option) => option.Naziv}                                     
                                    onChange={(e,v) => setTipPredmetnosti(v)}
                                    renderInput={(params) => <TextField {...params} label="Tip predmetnosti" size="small" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item md={3} >
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal
                                    autoHighlight
                                    disableClearable
                                    id="tipnaplatnosti-select"
                                    options={tipoviNaplatnosti}
                                    value={tipNaplatnosti}
                                    getOptionLabel={(option) => option.Naziv}                                     
                                    onChange={(e,v) => {
                                        if (v && v.TipNaplatnostiID != 0) {
                                            setTipPredmetnosti(tipoviPredmetnosti.find(tp => tp.TipPredmetnostiID == 1));
                                        }
                                        setTipNaplatnosti(v);
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Tip naplatnosti" size="small" />}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} style={{textAlign:"center"}}>
                    <Button startIcon={<SearchIcon></SearchIcon>} variant="outlined" onClick={search} disabled={!valid}>Pretraži</Button>
                </Grid>
                <Grid item md={4}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            disableClearable
                            id="klijent-select"
                            disabled={!klijenti || klijenti.length == 0}
                            options={klijenti}
                            value={klijent}
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            renderOption={(props, option) => {
                                return (
                                <li {...props} key={"klijent" + option.KlijentID} style={{color: option.KlijentID == 0 || option.TipStatusaKlijentaID == 1 ? "black" : "red"}}>
                                    {option.Broj + " - " + option.Naziv}
                                </li>
                                );
                            }}                                        
                            onChange={(e,v) => {
                                setPredmet(predmeti.find(p => p.PredmetID == 0));
                                setKlijent(v)
                            }}
                            renderInput={(params) => <TextField {...params} sx={{input:{color: klijent && klijent.KlijentID != 0 && klijent.TipStatusaKlijentaID != 1 ? "red" : "black"}}} label="Klijent" size="small" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item md={4}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            disableClearable
                            id="predmet-select"
                            disabled={!klijent || klijent.KlijentID == 0 || !predmeti || predmeti.length == 0}
                            options={klijent && klijent.KlijentID > 0 ? predmeti.filter(p => p.KlijentID == klijent.KlijentID || p.PredmetID == 0) : predmeti.filter(p => p.PredmetID == 0)}
                            value={predmet}
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            renderOption={(props, option) => {
                                return (
                                <li {...props} key={"predmet" + option.PredmetID} style={{color: option.PredmetID == 0 || option.TipStanjaPredmetaID == 1 ? "black" : "red"}}>
                                    {option.Broj + " - " + option.Naziv}
                                </li>
                                );
                            }}                                        
                            onChange={(e,v) => setPredmet(v)}
                            renderInput={(params) => <TextField {...params} sx={{input:{color: predmet && predmet.PredmetID != 0 && predmet.TipStanjaPredmetaID != 1 ? "red" : "black"}}} label="Predmet" size="small" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item sx={4}>

                </Grid>
                <Grid item xs={8}>
                    <FormControl fullWidth>
                        <TextField 
                            autoFocus 
                            label="Tekst pretrage" 
                            value={tekstPretrage} 
                            onChange={(e) => setTekstPretrage(e.target.value)} 
                            error={((!klijent || klijent.KlijentID == 0) && (!predmet || predmet.PredmetID == 0)) && (!tekstPretrage || tekstPretrage.length <= 2)} 
                            size="small"
                            onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                    if(valid) search();
                                }
                            }}                                  
                    ></TextField>
                    </FormControl>
                </Grid>        
                <Grid item xs={4} style={{textAlign:"center"}}>
                    <Button startIcon={<CreateIcon></CreateIcon>} variant="outlined" onClick={evidencija}>Nova evidencija</Button>
                </Grid>     


                <Grid item md={12}>
                    <Box style={{ width: "100%" }}>                            
                        {rezultatPretrage && rezultatPretrage.length > 0 ? (
                            <Box style={{width:"100%"}}>
                                <Grid container spacing={1}>
                                    <Grid item md={12} style={{paddingTop:'1em', paddingBottom:'1em'}}>
                                        <Divider></Divider>
                                    </Grid>
                                    <Grid item md={12} style={{paddingTop:'1em', paddingBottom:'1em'}}>
                                        <Typography variant="h6" component="div">
                                            {"Rezultat pretrage (broj pronađenih evidencija: " + rezultatPretrage.length + (ukupnoSati ? (", evidentirano vrijeme: " + ukupnoSati) : "") + (ukupnoTroskova ? (", evidentirano troškova: " + ukupnoTroskova) : "") + ")"}
                                        </Typography>
                                    </Grid>
                                    <Grid item md={12}>

                                        <ListItem className="list-title" key={"listHeader"} >
                                            <Grid container>

                                                <Grid item md={1}>

                                                    <Grid container>

                                                        <Grid item md={8} sx={{display: { xs: "none", sm: "block"}}}>
                                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Datum"} />
                                                        </Grid>

                                                        <Grid item md={4} x={{display: { xs: "none", sm: "block"}}}>
                                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Tip"} />
                                                        </Grid>


                                                    </Grid>

                                                </Grid>

                                                <Grid item md={2} x={{display: { xs: "none", sm: "block"}}}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Korisnik"} />
                                                </Grid>

                                                <Grid item md={2} x={{display: { xs: "none", sm: "block"}}}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Klijent"} />
                                                </Grid>

                                                <Grid item md={2} x={{display: { xs: "none", sm: "block"}}}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Predmet"} />
                                                </Grid>

                                                <Grid item md={4} x={{display: { xs: "none", sm: "block"}}}>
                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Opis"} />
                                                </Grid>

                                                <Grid item md={1} x={{display: { xs: "none", sm: "block"}}}>
                                                    <ListItemText style={{paddingLeft:"15px", textAlign:"right"}} primaryTypographyProps={{ noWrap:true }} primary={"Naplatnost"} />
                                                </Grid>

                                            </Grid>

                                        </ListItem>
                                        <Paper variant="outlined" sx={{maxHeight: {md:130}}} style={{width:"100%", overflow: 'auto'}}>
                                            <List dense>
                                                {rezultatPretrage.map((item, index) => {
                                                    {/* return (
                                                    <ListItem className="list-item" id={item.UniqueKey} key={"listItem" + item.UniqueKey} style={{paddingTop:0, paddingBottom:0}} onClick={() => handleTableClick(item)}>
                                                        <Grid container>
                                                            
                                                            <Grid item md={1}>

                                                                <Grid container>

                                                                    <Grid item md={8} sx={{display: { xs: "none", sm: "block"}}}>
                                                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={currentRow == item ? <b> {dateFormat(item.Datum, "dd.mm.yyyy")} </b> : dateFormat(item.Datum, "dd.mm.yyyy")} />
                                                                    </Grid>
                                                                    <Grid item md={4} sx={{display: { xs: "none", sm: "block"}}}>
                                                                        <Tooltip title={item.TipNaziv} enterDelay={300} enterNextDelay={300}>
                                                                            <ListItemText style={{paddingLeft:"15px"}} >
                                                                                <Brightness1Icon sx={{ color: item.Boja }}></Brightness1Icon>
                                                                            </ListItemText>
                                                                        </Tooltip>
                                                                    </Grid>


                                                                </Grid>

                                                            </Grid>
                                                            
                                                            <Grid item md={2} x={{display: { xs: "none", sm: "block"}}}>
                                                                <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={currentRow == item ? <b> {item.Korisnik.Ime + " " + item.Korisnik.Prezime} </b> : item.Korisnik.Ime + " " + item.Korisnik.Prezime} />
                                                            </Grid>
                                                            <Grid item md={2} x={{display: { xs: "none", sm: "block"}}}>
                                                                <Tooltip title={item.TipPredmetnosti.TipPredmetnostiID == 1 ? item.Klijent.Broj + " - " + item.Klijent.Naziv : ""} enterDelay={300} enterNextDelay={300}>
                                                                    <ListItemText style={{paddingLeft:"15px", color: item.TipPredmetnosti.TipPredmetnostiID == 1 && item.Klijent.TipStatusaKlijentaID == 2 ? 'red' : 'black'}} primaryTypographyProps={{ noWrap:true }} primary={currentRow == item ? <b> {item.TipPredmetnosti.TipPredmetnostiID == 1 ? item.Klijent.Broj + " - " + item.Klijent.Naziv : ""} </b> : (item.TipPredmetnosti.TipPredmetnostiID == 1 ? item.Klijent.Broj + " - " + item.Klijent.Naziv : "")} />
                                                                </Tooltip>
                                                            </Grid>                                                        
                                                            <Grid item md={2} x={{display: { xs: "none", sm: "block"}}}>
                                                                <Tooltip title={item.TipPredmetnosti.TipPredmetnostiID == 1 ? item.Predmet.Broj + " - " + item.Predmet.Naziv : ""} enterDelay={300} enterNextDelay={300}>
                                                                    <ListItemText style={{paddingLeft:"15px", color: item.TipPredmetnosti.TipPredmetnostiID == 1 ? (item.Predmet.TipaStanjaPredmetaID > 1 ? 'red' : (item.Predmet.TipNaplateID == 3 ? 'green' :(item.Predmet.TipNaplateID == 2 ? 'magenta' : 'black'))): 'black'}} primaryTypographyProps={{ noWrap:true }} primary={currentRow == item ? <b> {item.TipPredmetnosti.TipPredmetnostiID == 1 ? item.Predmet.Broj + " - " + item.Predmet.Naziv : ""} </b> : item.TipPredmetnosti.TipPredmetnostiID == 1 ? item.Predmet.Broj + " - " + item.Predmet.Naziv : ""} />
                                                                </Tooltip>
                                                            </Grid>

                                                            <Grid item md={4} x={{display: { xs: "none", sm: "block"}}}>
                                                                <Tooltip title={item.Opis} enterDelay={300} enterNextDelay={300} arrow>
                                                                    <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={currentRow == item ? <b> {item.Opis} </b> : item.Opis} />
                                                                </Tooltip>
                                                            </Grid>

                                                            <Grid item md={1} x={{display: { xs: "none", sm: "block"}}}>
                                                                <Tooltip title={item.TipNaplatnosti && item.TipNaplatnosti.TipNaplatnostiID == 1 ? "Naplatno" : "Nenaplatno"} enterDelay={300} enterNextDelay={300}>
                                                                    <ListItemText style={{paddingLeft:"15px", textAlign:"right"}} primaryTypographyProps={{ noWrap:true }}>
                                                                        <Brightness1Icon sx={{ color: item.NaplatnostBoja }}></Brightness1Icon>
                                                                    </ListItemText>
                                                                </Tooltip>
                                                            </Grid>

                                                        </Grid>
                                                    </ListItem>
                                                    ); */}
                                                    return <PretragaEvidencijeListItem key={index} item={item} index={index} handleTableClick={handleTableClick} fontWeight={currentRow && currentRow == item ? 700 : 400}></PretragaEvidencijeListItem>
                                                })}
                                            </List>
                                        </Paper>

                                    </Grid>

                                    <Grid item md={12} style={{paddingTop:'1em', paddingBottom:'1em'}}>
                                        <Typography variant="h6" component="div">
                                            Detalji
                                        </Typography>
                                    </Grid>
                                    
                                    <Grid item md={6}>
                                        <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                            <TextField 
                                                label={"Opis"}
                                                multiline 
                                                rows={7} 
                                                style={{width:"100%"}}
                                                size="small"
                                                InputProps={{ 
                                                    readOnly: true}}
                                                value={(currentData && currentData.Opis) || ''}
                                            >
                                            </TextField>
                                        </Box>
                                    </Grid>

                                    <Grid item md={6}>
                                        <Grid container spacing={1}>

                                            <Grid item md={6}>
                                                <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <TextField 
                                                        label="Korisnik"
                                                        style={{width:"100%"}}
                                                        size="small"
                                                        InputProps={{ 
                                                            readOnly: true}}
                                                        value={(currentData && currentData.Korisnik) || ''}
                                                    >
                                                    </TextField>
                                                </Box>                        
                                            </Grid>

                                            <Grid item md={3}>
                                                <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <TextField
                                                        label={"Datum"}
                                                        style={{width:"100%"}}
                                                        size="small"
                                                        InputProps={{ 
                                                            readOnly: true}}
                                                        inputProps={{
                                                            style: { textAlign: 'center' }
                                                        }}
                                                        value={(currentData && currentData.Datum) || ''}
                                                    >
                                                    </TextField>
                                                </Box>                        
                                            </Grid>

                                            <Grid item md={3}>
                                                <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <TextField
                                                        label={currentData && (currentData.TipNaziv == "Aktivnost" || currentData.TipPredmetnostiID == 2) ? "Trajanje" : "Iznos"}
                                                        style={{width:"100%"}}
                                                        size="small"
                                                        InputProps={{ 
                                                            readOnly: true
                                                        }}
                                                        inputProps={{
                                                            style: { textAlign: 'center' }
                                                        }}
                                                        //value={(currentData && currentData.Trajanje) || ''}
                                                        value={(currentData && currentData.Trajanje) || ''}
                                                    >
                                                    </TextField>
                                                </Box>                        
                                            </Grid>

                                            <Grid item md={6}>
                                                <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <TextField
                                                        label={'Kontakt'}
                                                        style={{width:"100%"}}
                                                        size="small"
                                                        InputProps={{ 
                                                            readOnly: true
                                                        }}
                                                        value={(currentData && currentData.Kontakt) || ''}
                                                    >
                                                    </TextField>
                                                </Box>   
                                            </Grid>

                                            <Grid item md={6}>
                                                
                                                <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>

                                                    <Grid container spacing={1}>

                                                        <Grid item md={6}>
                                                            <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                                <TextField 
                                                                        style={{width:"100%"}}
                                                                        size="small"
                                                                        InputProps={{ 
                                                                            readOnly: true,
                                                                            startAdornment: <InputAdornment position="start"><Brightness1Icon sx={{ color : currentData && currentData.TipBoja}}></Brightness1Icon></InputAdornment>
                                                                        }}
                                                                        value={(currentData && currentData.TipNaziv) || ''}
                                                                    >
                                                                </TextField>

                                                            </Box>
                                                        </Grid>

                                                        <Grid item md={6}>
                                                            <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                                <TextField 
                                                                        style={{width:"100%"}}
                                                                        size="small"
                                                                        InputProps={{ 
                                                                            readOnly: true,
                                                                            startAdornment: <InputAdornment position="start"><Brightness1Icon sx={{ color : currentData && currentData.NaplatnostBoja}}></Brightness1Icon></InputAdornment>
                                                                        }}
                                                                        value={(currentData && currentData.NaplatnostNaziv) || ''}
                                                                    >
                                                                </TextField>
                                                            </Box>
                                                        </Grid>

                                                    </Grid>


                                                </Box>                        
                                            </Grid>


                                            <Grid item md={6}>
                                                <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <TextField 
                                                        label="Klijent"
                                                        style={{width:"100%"}}
                                                        size="small"
                                                        InputProps={{ 
                                                            readOnly: true}}
                                                        inputProps={{
                                                            style: { color: currentData && currentData.KlijentBoja }
                                                        }}                                                            
                                                        value={(currentData && currentData.KlijentNaziv) || ''}
                                                    >
                                                    </TextField>
                                                </Box>                        
                                            </Grid>
                                            <Grid item md={6}>
                                                <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <TextField
                                                        label="Predmet" 
                                                        style={{width:"100%"}}
                                                        size="small"
                                                        InputProps={{ 
                                                            readOnly: true}}
                                                            inputProps={{
                                                            style: { color: currentData && currentData.PredmetBoja }
                                                        }}                                                            
                                                        value={(currentData && currentData.PredmetNaziv) || ''}
                                                    >
                                                    </TextField>
                                                </Box>                        
                                            </Grid>                                             

                                            <Grid item md={12}>
                                                <Box style={{width:"100%", height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                    <TextField 
                                                        label="Status prijenosa"
                                                        style={{width:"100%"}}
                                                        size="small"
                                                        InputProps={{ 
                                                            readOnly: true,
                                                            startAdornment: <InputAdornment position="start"><Brightness1Icon sx={{ color : currentData && currentData.StatusPrijenosaBoja}}></Brightness1Icon></InputAdornment>
                                                        }}
                                                        value={(currentData && currentData.StatusPrijenosa) || ''}
                                                    >
                                                    </TextField>
                                                </Box>                        
                                            </Grid>

                                        </Grid>
                                    
                                    </Grid>

                                </Grid>
                            </Box>
                            ) : (
                            <div style={{textAlign:"center"}}>Za pretragu odaberite kriterije i pritisnite gumb Pretraži (ili Enter).</div>
                            )
                        }
                    </Box>
                </Grid>

            </Grid>
            {/* <>
                <div>{"U izradi..."}</div>
            </> */}

            {infoAlert ? <AlertDialog title="Nema rezultata" message={"Pretraga po zadanim kriterijima nije dala niti jedan rezultat."} cancelHidden={true} confirmHidden={true} okHidden={false} okLabel={"OK"} okAction={() => setInfoAlert(false)}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
            {evidencijaInfo != null ? (
                <Modal className="modal" open={evidencijaOpened}>
                    <Box sx={{...style, minWidth:{xs:"100%", sm:"100%", md:"70%"}}} overflow="auto">
                        <EvidencijaForm minsButtons={evidencijaInfo.MinsButtons} evidencijaUPrenesenoRazdobljeMessage={evidencijaInfo.EvidencijaUPrenesenoRazdobljeMessage} brojRedovaPredlozaka={evidencijaInfo.BrojRedovaPredlozaka} pretragaPredmeti={evidencijaInfo.DodaniPredmeti} date={new Date()} loadEvidencije={() =>{ search(); setEvidencijaInfo(null);}} omoguciPohrani={evidencijaInfo.OmoguciPohrani} onDateChange={() => {}} predmeti={klijentiPredmeti.Predmeti} nepredmetneKategorije={evidencijaInfo.NepredmetneKategorije} korisnikID={global.user.korisnikID} setCanceled={() => {}} setFormOpened={setEvidencijaOpened} tipoviEvidencije={evidencijaInfo.TipoviEvidencije} klijenti={klijentiPredmeti.Klijenti} kontakti={evidencijaInfo.Kontakti} predlosci={evidencijaInfo.Predlosci} aktivnostDefTrajanjeMin={evidencijaInfo.AktivnostDefTrajanjeMin} tipoviOporezivosti={evidencijaInfo.TipoviOporezivosti} tipoviNaplatnosti={evidencijaInfo.TipoviNaplatnosti} firmaValute={evidencijaInfo.FirmaValute} defaultFirmaValuta={evidencijaInfo.DefaultFirmaValuta} defaultTrosakTipOporezivosti={evidencijaInfo.DefaultTrosakTipOporezivosti} tipEvidencijeID={1} tipNaplatnostiNaplatnoBoja={evidencijaInfo.TipNaplatnostiNaplatnoBoja} tipNaplatnostiNenaplatnoBoja={evidencijaInfo.TipNaplatnostiNenaplatnoBoja} defaultTarifnaAktivnostOmoguciIznosNula={evidencijaInfo.DefaultTarifnaAktivnostOmoguciIznosNula} mogucePrebacitiAktivnostiBezCijene={evidencijaInfo.MogucePrebacitiAktivnostiBezCijene} samoOtvoreniPredmeti={evidencijaInfo.DefaultSamoOtvoreniPredmeti} setSamoOtvoreniPredmeti={saveSamoOtvoreniPredmeti} samoAktivniKlijenti={evidencijaInfo.DefaultSamoAktivniKlijenti} setSamoAktivniKlijenti={saveSamoAktivniKlijenti}></EvidencijaForm>
                    </Box>
                </Modal>
            ) : null}

        </ComponentContainer>
    );
}
