import { Autocomplete, Button, FormControl, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { hr } from "date-fns/locale";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

const tipoviDrzavnosti = [
    {TipDrzavnostiID : 1, Naziv : "Domaći"},
    {TipDrzavnostiID : 2, Naziv : "Strani"}
];

const tipoviPonude = [
    {TipVrstePonudeID : -1, Naziv: "Ništa"},
    {TipVrstePonudeID : 1, Naziv: "Ponuda"},
    {TipVrstePonudeID : 2, Naziv: "Predračun"}
];

export default function ReportRjecnikPogledajPrimjerForm(props) {
    
    const useConstructor = Util.useConstructor();
    const prijevod = props.prijevod;
    const close = props.close;
    const jezikID = props.jezikID;

    const [loading, setLoading] = useState(true);
    const [preview, setPreview] = useState("");
    const [pocetak, setPocetak] = useState(new Date());
    const [kraj, setKraj] = useState(new Date());
    const [datumPlacanja, setDatumPlacanja] = useState(new Date());
    const [pnb, setPnb] = useState("1-1-1");
    const [model, setModel] = useState("HR00");
    const [napomena, setNapomena] = useState("Napomena računa");
    const [tipDrzavnosti, setTipDrzavnosti] = useState(tipoviDrzavnosti[0]);
    const [specifikacijaExists, setSpecifikacijaExists] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [brojDana, setBrojDana] = useState(3);
    const [tipVrstePonude, setTipVrstePonude] = useState(tipoviPonude[0]);

    useConstructor(() => {
        getPreview();
    });

    function getPreview() {
        setLoading(true);
        let resource = {
            Napomena : napomena,
            DatumPlacanja : datumPlacanja,
            Pocetak : pocetak,
            Kraj : kraj,
            Model : model,
            PNB : pnb,
            Prijevod : prijevod,
            JezikID : jezikID,
            TipDrzavnostiID : tipDrzavnosti.TipDrzavnostiID,
            SpecifikacijaExists : specifikacijaExists,
            BrojDana : brojDana,
            TipVrstePonudeID : tipVrstePonude ? tipVrstePonude.TipVrstePonudeID : -1
        };
        api.post("reportsapi/getnapomenapreview", resource, getNapomenaPreviewSuccess, getNapomenaPreviewFail);
    }

    function getNapomenaPreviewSuccess(data) {
        setPreview(data);
        setLoading(false);
    }

    function getNapomenaPreviewFail(data) {
        setPreview("");
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
            <ComponentContainer loading={loading}>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <DatePicker onChange={v => {if (!isNaN(new Date(v))) setPocetak(new Date(v))}} label="Datum početka usluge" value={pocetak} inputFormat="dd.MM.yyyy" renderInput={(params) => <TextField {...params} style={{width:"100%"}} size="small" />} /> 
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker onChange={v => {if (!isNaN(new Date(v))) setKraj(new Date(v))}} label="Datum kraja usluge" value={kraj} inputFormat="dd.MM.yyyy" renderInput={(params) => <TextField {...params} style={{width:"100%"}} size="small" />} /> 
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <TextField label="Broj dana za plaćanje" type="number" error={brojDana == null || isNaN(parseInt(brojDana)) || parseInt(brojDana) < 0} value={brojDana} onChange={e => setBrojDana(e.target.value)} size="small"></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <DatePicker onChange={v => {if (!isNaN(new Date(v))) setDatumPlacanja(new Date(v))}} label="Datum plaćanja" value={datumPlacanja} inputFormat="dd.MM.yyyy" renderInput={(params) => <TextField {...params} style={{width:"100%"}} size="small" />} /> 
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <TextField label="Model plaćanja" value={model} onChange={e => setModel(e.target.value)} size="small"></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField label="Poziv na broj" value={pnb} onChange={e => setPnb(e.target.value)} size="small"></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField label="Napomena" value={napomena} multiline rows={3} onChange={e => setNapomena(e.target.value)} size="small"></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Autocomplete size="small" 
                                disablePortal
                                disableClearable
                                options={tipoviDrzavnosti} 
                                onChange={(e,v) => {if (v) setTipDrzavnosti(v)}}
                                getOptionLabel={option => option.Naziv}
                                value={tipDrzavnosti}
                                autoHighlight
                                ListboxProps={{ style: { maxHeight: '10rem' } }}
                                renderInput={(params) => <TextField sx={{width:"100%"}} size="small" {...params} label="Tip državnosti" error={!tipDrzavnosti} />} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Autocomplete size="small" 
                                disablePortal 
                                disableClearable
                                options={[{Naziv : "DA", Vrijednost : true}, { Naziv : "NE", Vrijednost : false}]} 
                                onChange={(e,v) => {if (v) setSpecifikacijaExists(v.Vrijednost)}}
                                getOptionLabel={option => option.Naziv}
                                value={specifikacijaExists ? {Naziv : "DA", Vrijednost : true} : { Naziv : "NE", Vrijednost : false}}
                                autoHighlight
                                ListboxProps={{ style: { maxHeight: '10rem' } }}
                                renderInput={(params) => <TextField sx={{width:"100%"}} size="small" {...params} label="Specifikacija postoji" />} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Autocomplete size="small" 
                                disablePortal 
                                disableClearable
                                options={tipoviPonude} 
                                onChange={(e,v) => {if (v) setTipVrstePonude(v)}}
                                getOptionLabel={option => option.Naziv}
                                value={tipVrstePonude}
                                autoHighlight
                                ListboxProps={{ style: { maxHeight: '10rem' } }}
                                renderInput={(params) => <TextField sx={{width:"100%"}} size="small" {...params} label="Tip ponude" />} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{display:"flex", justifyContent:"center"}}>
                            <Button variant="contained" color="success" style={{marginRight:"5px"}} startIcon={<PlayCircleFilledWhiteIcon></PlayCircleFilledWhiteIcon>} onClick={getPreview}>Generiraj</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField label="Pregled" value={preview} InputProps={{readOnly: true}} multiline rows={8} size="small"></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{display:"flex", justifyContent:"flex-end", marginTop:"10px"}}>
                            <Button variant="outlined" color="error" startIcon={<CloseIcon></CloseIcon>} onClick={close}>Zatvori</Button>
                        </Box>
                    </Grid>
                </Grid>

                {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
            </ComponentContainer>
        </LocalizationProvider>
    );

}