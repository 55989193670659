import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function RegistracijaConfirm(props) {

    const useConstructor = Util.useConstructor();

    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        setSuccess(Util.getParametersFromUrl(window.location)["success"]);
        setErrorMessage(Util.getParametersFromUrl(window.location)["error"]);
    });

    return (
        <ComponentContainer>
            {success == "true" ? (
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{display:"flex", justifyContent:"center"}}>
                        <Typography variant="h6" gutterBottom component="div">
                            Registracija je uspješno provedena. Ubrzo će Vam stići email poruka s pristupnim podacima.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{display:"flex", justifyContent:"center", marginTop:"15px"}}>
                            <Button variant="contained" onClick={() => window.location="#/login"}>Prijava</Button>
                        </Box>
                    </Grid>
                </Grid>
            ) : null}

            {errorMessage != null && errorMessage != "" ? (
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{display:"flex", justifyContent:"center"}}>
                        <Typography variant="h6" gutterBottom component="div">
                            {errorMessage}
                        </Typography>
                    </Grid>
                </Grid>
            ) : null}
            
        </ComponentContainer>
    );
}

export default React.memo(RegistracijaConfirm);