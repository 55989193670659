import React, { useEffect, useState, useCallback, useRef } from "react";
import Util from "../util/util";
import api from "../util/api";
import AddEditDelForm from "./addEditDelForm";
import ComponentContainer from "./componentContainer";
import AlertDialog from "./alertdialog";
import PredmetForm from "./predmetForm";
import { Box } from "@mui/system";
import { Alert, Autocomplete, Button, ClickAwayListener, Divider, FormControl, FormControlLabel, FormLabel, Grid, ListItem, ListItemText, Menu, MenuItem, Modal, Radio, RadioGroup, TextField, Tooltip, Typography } from "@mui/material";
import InventoryIcon from '@mui/icons-material/Inventory';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Cjenik from "./cjenik";
import ExitToApp from "@mui/icons-material/ExitToApp";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import database from "../util/database";
import ErrorAlert from "./errorAlert";
import SearchIcon from '@mui/icons-material/Search';

const tipovi = [
    { Naziv : "Svi", Vrijednost : 0},
    { Naziv : "Satnica", Vrijednost : 1},
    { Naziv : "Satnica s Cap/Max", Vrijednost : 4},
    { Naziv : "Tarifa", Vrijednost: 2},
    { Naziv : "Fiksna naplata", Vrijednost: 3}
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '90%',
    minHeight: '50%'
};

const alertStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '90%'
};

const cjenikStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '90%'
};

function Predmeti(props) {
    const useConstructor = Util.useConstructor();
    const isMobileDevice = Util.isMobileDevice();

    const [klijentID, setKlijentID] = useState(null);
    const [initialKlijentID, setInitialKlijentID] = useState(true);
    const firmaID = props.firmaID;
    const tipTvrtkeID = props.tipTvrtkeID;

    const klijentiPredmeti = props.klijentiPredmeti || {
        Klijenti: [],
        Predmeti: [],
        KlijentiLastUpdate: null,
        PredmetiLastUpdate: null
    };
    const loadKlijentiAndPredmeti = props.loadKlijentiAndPredmeti;
    const filterRef = props.filterRef;
    const dataGridFilterRef = props.dataGridFilterRef;
    const dataGridSortRef = props.dataGridSortRef;

//    const [predmeti, setPredmeti] = useState([]);
    const [filtriraniPredmeti, setFiltriraniPredmeti] = useState([]);
    const [loading, setLoading] = useState(true);
    const [current, setCurrent] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [formPredmet, setFormPredmet] = useState(null);
    const [formOpened, setFormOpened] = useState(false);
    const [prebaciNaFormOpened, setPrebaciNaFormOpened] = useState(false);
    const [enableScrolling, setEnableScrolling] = useState(false);
    const [klijent, setKlijent] = useState(null);
    const [klijentZaPrebacaj, setKlijentZaPrebacaj] = useState(null);

    const [cjenikChanged, setCjenikChanged] = useState(false);
    const [predmetCreatedAlert, setPredmetCreatedAlert] = useState(false);
    const [predmetCreated, setPredmetCreated] = useState(false);

    const [otvoreni, setOtvoreni] = useState(true);
    const [realizirani, setRealizirani] = useState(true);
    const [nerealizirani, setNerealizirani] = useState(true);
    const [aktivni, setAktivni] = useState(true);
    const [neaktivni, setNeaktivni] = useState(true);
    const [tip, setTip] = useState(tipovi[0]);
    const [initialFilter, setInitialFilter] = useState(true);
    const [alertMessage, setAlertMessage] = useState(null);

    const [cjenikOpened, setCjenikOpened] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [logoutAlert, setLogoutAlert] = useState(false);
    const [filterModel, setFilterModel] = useState({
        items: []
    });
    const [sortModel, setSortModel] = useState([]);
    const [prebaciSuccessAlert, setPrebaciSuccessAlert] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const filterOpened = Boolean(anchorEl);
    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterClose = () => {
        setAnchorEl(null);
    };
    
    const global = props.global;

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(null);
    const [limitPageSize, setLimitPageSize] = useState(null);

    useConstructor(() => {
        //global.setRoles(database.get("roles") || []);
        if (klijentiPredmeti.KlijentiLastUpdate != "" && klijentiPredmeti.KlijentiLastUpdate != null && klijentiPredmeti.PredmetiLastUpdate != "" && klijentiPredmeti.PredmetiLastUpdate != null) {
            let resource = {
                FirmaID: global.user.firmaID,
                KlijentiLastUpdate: klijentiPredmeti.KlijentiLastUpdate,
                PredmetiLastUpdate: klijentiPredmeti.PredmetiLastUpdate,
                KorisnikID: global.user.korisnikID
            }
            api.post("firma/checkeverything", resource, checkEverythingSuccess, checkEverythingFail);
        }
    });

    function checkEverythingSuccess(data) {
        if (data && (data.UserAccountValid == false || data.LogoutRequired)) {
            setLoading(false);
            setLogoutAlert(true);
            return;
        }

        if (data.CheckIfKlijentiOrPredmetiUnchangedResource == false) {
            loadKlijentiAndPredmeti(global.user.firmaID, loadKlijentiAndPredmetiSuccess, data.KlijentiChanged, data.PredmetiChanged);
        } else {
            loadKlijentiAndPredmetiSuccess();
        }
    }

    function checkEverythingFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function startLogout() {
        setLogoutAlert(false);
        Util.logout();
        window.location = "/#/login";
    }

    const klijentZaPrebacajRef = useRef(null);

    function focusOnKlijentZaPrebacajRef() {
        setTimeout(() => {
            if (klijentZaPrebacajRef.current) {
                klijentZaPrebacajRef.current.focus();
            }
        }, 100);
    }

    useEffect(() => loadKlijent(), [klijentID]);

    useEffect(() => {
        if (limitPageSize != null) {
            setPageSize(filterRef.current && filterRef.current.PageSize ? filterRef.current.PageSize : 100);
        }
    }, [limitPageSize]);

    useEffect(() => {       
        if (pageSize != null) {
            // setTimeout(() => {
            //     setPage(filterRef.current != null ? (filterRef.current.Page || 0) : 0);
            //     filterRef.current = null;
            // }, [200])
            setPage(filterRef.current != null ? (filterRef.current.Page || 0) : 0);
            filterRef.current = null;            
        }
    }, [pageSize]);

    function loadKlijentiAndPredmetiSuccess() {
        setLoading(false);
        if (filterRef.current != null) {
            setKlijentID(filterRef.current.KlijentID);
            setTip(tipovi.find(t => t.Vrijednost == filterRef.current.Tip));
            setOtvoreni(filterRef.current.Otvoreni);
            setRealizirani(filterRef.current.Realizirani);
            setNerealizirani(filterRef.current.Nerealizirani);
            setAktivni(filterRef.current.Aktivni);
            setNeaktivni(filterRef.current.Neaktivni);
            setCurrent(filterRef.current.Predmet);
            setLimitPageSize(true);
        } else {
            let params = Util.getParametersFromUrl(document.location);
            setKlijentID(params["klijentID"]);
            setLimitPageSize(false);
        }
        if (dataGridFilterRef.current != null) {
            setFilterModel(dataGridFilterRef.current);
            dataGridFilterRef.current = null;
        }
        if (dataGridSortRef.current != null) {
            setSortModel(dataGridSortRef.current);
            dataGridSortRef.current = null;
        }
    }

    function loadKlijent() {
        if (initialKlijentID) {
            setInitialKlijentID(false);
        } else {
            // setLoading(true);
            // if (klijentID) {
            //     api.get("/klijent/id/" + klijentID, loadKlijentSuccess, loadKlijentFail);
            // } else {
            //     //loadPredmeti();
            //     setLoading(false);
            // }
            setKlijent(klijentiPredmeti.Klijenti.find(k => k.KlijentID == klijentID));
        }
    }

    function loadKlijentSuccess(data) {
        setKlijent(data);
        //loadPredmeti();
        setLoading(false);
    }

    function loadKlijentFail(data) {
        alert("Pogreška prilikom učitavanja klijenta!")
        //loadPredmeti();
        setLoading(false);
    }

    function afterSave() {
        filterRef.current = {
            KlijentID : klijentID,
            Tip : tip.Vrijednost,
            Otvoreni : otvoreni,
            Realizirani : realizirani,
            Nerealizirani : nerealizirani,
            Aktivni : aktivni,
            Neaktivni : neaktivni,
            Predmet : current,
            Page : pageSize <= 100 ? page : 0,
            PageSize : pageSize <= 100 ? pageSize : 100
        };
        dataGridFilterRef.current = filterModel;
        dataGridSortRef.current = sortModel;
        loadKlijentiAndPredmeti(global.user.firmaID, null, false, true);
    }

    function loadPredmeti() {
        setLoading(true);
        // if (!(otvoreni && realizirani && nerealizirani && aktivni && neaktivni && tip && tip.Vrijednost == 0)) {
        //     filter();
        // } else {
        //     if (klijentID) {
        //         api.get("/predmeti/klijent/" + klijentID, loadPredmetiSuccess, loadPredmetiFail);
        //     } else {
        //         api.get("/predmeti/firma/" + firmaID, loadPredmetiSuccess, loadPredmetiFail);
        //     }
        // }
        setLoading(false);
    }

    // useEffect(() => {
    //     if (cjenikChanged) {
    //         //filter();
    //         setCjenikChanged(false);
    //         loadKlijentiAndPredmeti(global.user.firmaID);
    //     }
    // }, [cjenikChanged]);

    useEffect(() => {
        if (filtriraniPredmeti.length > 0) {
            setCurrent(filtriraniPredmeti[0]);
        } else {
            setCurrent(null);
        }
    }, [filtriraniPredmeti]);

    //function loadPredmetiSuccess(data) {
    //    if (data) {
    //        //setPredmeti(data);
    //        let params = Util.getParametersFromUrl(document.location);
    //        if(params["predmetID"] != null){
    //            setCurrent(data.find(p => p.PredmetID == params["predmetID"]));
    //        }
    //    }
    //    setLoading(false);
    //}

    //function loadPredmetiFail(data) {
    //    alert("fail")
    //    setLoading(false);
    //}

    function deleteCanceled() {
        setDeleteAlert(false);
    }

    function deleteConfirmed() {
        setDeleteAlert(false);
        deletePredmet();
    }

    function openForm(type) {
        if (type == "add") {
            setFormPredmet(null);
        } else {
            setFormPredmet(current);
        }
        setFormOpened(true);
    }

    function handleKlijentChange(e,v) {
        setKlijent(v);
        if (v) {
            setKlijentID(v.KlijentID);

        } else {
            setKlijentID(null);
        }
    }

    function handleKlijentZaPrebacajChange(e,v){
        setKlijentZaPrebacaj(v);
    }

    function handleSvaStanjaClick () {
        setOtvoreni(true);
        setRealizirani(true);
        setNerealizirani(true);
    }

    function handleOtvoreniClick () {
        setOtvoreni(true);
        setRealizirani(false);
        setNerealizirani(false);
    }

    function handleRealiziraniClick () {
        setOtvoreni(false);
        setRealizirani(true);
        setNerealizirani(false);
    }

    function handleNerealiziraniClick() {
        setOtvoreni(false);
        setRealizirani(false);
        setNerealizirani(true);
    }

    function handleAktivniNeaktivniClick() {
        setAktivni(true);
        setNeaktivni(true);
    }

    function handleAktivniClick() {
        setAktivni(true);
        setNeaktivni(false);
    }

    function handleNeaktivniClick() {
        setAktivni(false);
        setNeaktivni(true);
    }

    function handleTipChange(e, v){
        if(v){
            setTip(v);
        }
    }

    function filter() {
        //if (!initialFilter) {
        //    setLoading(true);
        //    let filterData = {
        //        FirmaID: firmaID,
        //        KlijentID: klijentID,
        //        Otvoreni: otvoreni,
        //        Realizirani: realizirani,
        //        Nerealizirani: nerealizirani,
        //        Aktivni: aktivni,
        //        Neaktivni: neaktivni,
        //        Tip: tip.Vrijednost
        //    }
        //    api.post("/predmeti/filter", filterData, loadPredmetiSuccess, loadPredmetiFail);
        //} else {
        //    setInitialFilter(false);
        //}

        let newFiltriraniPredmeti = JSON.parse(JSON.stringify(klijentiPredmeti.Predmeti));
        if (!otvoreni) {
            newFiltriraniPredmeti = newFiltriraniPredmeti.filter(p => p.TipStanjaPredmetaID != 1);
        }
        if (!realizirani) {
            newFiltriraniPredmeti = newFiltriraniPredmeti.filter(p => p.TipStanjaPredmetaID != 2);
        }
        if (!nerealizirani) {
            newFiltriraniPredmeti = newFiltriraniPredmeti.filter(p => p.TipStanjaPredmetaID != 3);
        }
        if (!aktivni) {
            newFiltriraniPredmeti = newFiltriraniPredmeti.filter(p => p.Aktivan != true);
        }
        if (!neaktivni) {
            newFiltriraniPredmeti = newFiltriraniPredmeti.filter(p => p.Aktivan != false);
        }
        if (tip.Vrijednost != 0) {
            switch (tip.Vrijednost) {
                case 1:
                    newFiltriraniPredmeti = newFiltriraniPredmeti.filter(p => p.TipNaplateID == 1);
                    break;
                case 2:
                    newFiltriraniPredmeti = newFiltriraniPredmeti.filter(p => p.TipNaplateID == 2);
                    break;
                case 3:
                    newFiltriraniPredmeti = newFiltriraniPredmeti.filter(p => p.TipNaplateID == 3);
                    break;
                case 4:
                    newFiltriraniPredmeti = newFiltriraniPredmeti.filter(p => p.Cap);
                    break;
            }
        }
        if (klijentID) {
            newFiltriraniPredmeti = newFiltriraniPredmeti.filter(p => p.KlijentID == klijentID);
        }

        setFiltriraniPredmeti(newFiltriraniPredmeti);
    }

    useEffect(() => filter(), [otvoreni, realizirani, nerealizirani, aktivni, neaktivni, tip, klijentiPredmeti, klijentID]);
    //useEffect(() => loadPredmeti(), [formOpened]);

    function deletePredmet() {
        api.post("subject/delete/" + current.PredmetID, null, deleteSuccess, deleteFail);
        //deleteSuccess();
    }

    function deleteSuccess() {
        setLoading(false);
        filterRef.current = {
            KlijentID : klijentID,
            Tip : tip.Vrijednost,
            Otvoreni : otvoreni,
            Realizirani : realizirani,
            Nerealizirani : nerealizirani,
            Aktivni : aktivni,
            Neaktivni : neaktivni,
            Page : 0,
            PageSize : pageSize
        };
        dataGridFilterRef.current = filterModel;
        dataGridSortRef.current = sortModel;
        loadKlijentiAndPredmeti(global.user.firmaID, null, false, true);
    }

    function deleteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    const handleKeyPressShorcuts = useCallback((event) => {
        if(!cjenikOpened){
            if (event.altKey) {
                let charCode = String.fromCharCode(event.which).toLowerCase();
                if (charCode == 'd') {
                    event.preventDefault();
                    if (!loading && global.roles.includes("19")) {
                        openForm("add");
                    }
                }
            }
        }
    }, [loading, cjenikOpened]);

    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressShorcuts);

        return () => {
            document.removeEventListener('keydown', handleKeyPressShorcuts);
        };
    }, [handleKeyPressShorcuts]);
    
    function gotoKlijenti(){
        //alert('jeee');
        if(klijent){
            window.location = "#/klijenti?klijentID=" + klijent.KlijentID; 
        }
        else{
            window.location = "#/klijenti"; 
        }
        global.setSelectedTab("Klijenti");
    }

    function prebaci(){
        setLoading(true);
        let data ={
            predmetID : current.PredmetID,
            noviKlijentID : klijentZaPrebacaj.KlijentID
        }
        api.post("/predmeti/prebacaj", data, prebaciSuccess, prebaciFail);
    }

    function prebaciSuccess(data){
        setPrebaciNaFormOpened(false);
        setPrebaciSuccessAlert(true);
        setLoading(false);
    }

    function prebaciFail(data){
        setPrebaciNaFormOpened(false);
        setKlijentZaPrebacaj(null);
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function renderPrebaciTitle(current){
        let naslov = "Prebaci predmet";
        if(current){
            naslov = naslov + '"<b>' + current.Naziv + '</b>" s klijenta "<b>' + current.KlijentNaziv + '</b>" na klijenta:'; 
        }
        return <>
            {naslov}
        </>
    }

    //useEffect(() => global.setRoles(database.get("roles") || []), []);

    return (
        <ComponentContainer loading={loading || global.loading}>

            <Grid container>
                <Grid item sm={12} md={6}>
                    <Typography variant="h4" gutterBottom component="div">
                        <InventoryIcon fontSize="medium" style={{marginRight:"15px"}}></InventoryIcon>
                        Predmeti
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            autoHighlight
                            id="klijent-select"
                            disabled={!klijentiPredmeti || !klijentiPredmeti.Klijenti}
                            options={klijentiPredmeti.Klijenti}
                            //value = {klijentID != null && klijent != null ? klijent : null}
                            value = {klijent}
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            onChange={handleKlijentChange}
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 ? "black" : "red"}}>
                                    {option.Broj + " - " + option.Naziv}
                                  </li>
                                );
                            }}
                            filterOptions={(options, state) => options.filter(option => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            renderInput={(params) => <TextField {...params} sx={{input:{color: klijent && klijent.TipStatusaKlijentaID != 1 ? "red" : "black"}}} label="Klijent" size="small" />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <div style={{display:"flex", justifyContent:"flex-end", width:"100%", maxHeight:"40px"}}>
                        <Button
                            id="basic-button"
                            aria-controls={filterOpened ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={filterOpened ? 'true' : undefined}
                            onClick={handleFilterClick}
                            size="medium"
                            style={{marginBottom:"5px"}}
                            variant="outlined"
                            endIcon={filterOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        >
                            Filter
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={filterOpened}
                            onClose={handleFilterClose}
                            PaperProps={{  
                                style: {  
                                width: 230,  
                                },
                            }}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            <FormControl style={{width:'100%', paddingBottom:'8px', paddingLeft:'8px', paddingRight:'8px'}}>
                                <FormLabel id="filter-stanje" style={{paddingBottom: '8px'}}>&nbsp;Tip</FormLabel>
                                <Autocomplete style={{width:"100%"}}
                                    disablePortal
                                    autoHighlight
                                    disableClearable
                                    id="tip-select"
                                    options={tipovi}
                                    value = {tip}
                                    getOptionLabel={(option) => option.Naziv}
                                    onChange={handleTipChange}
                                    renderOption={(props, option) => {
                                        return (
                                        <li {...props} key={"tip" + option.Vrijednost} style={{color: option.Vrijednost == 0 || option.Vrijednost == 1 || option.Vrijednost == 4 ? "black" : (option.Vrijednost == 2 ? "magenta" : "green")}}>
                                            {option.Naziv}
                                        </li>
                                        );
                                    }}
                                    renderInput={(params) => 
                                        <TextField {...params}
                                            sx={{input: {color:tip.Vrijednost == 0 || tip.Vrijednost == 1 || tip.Vrijednost == 4 ? "black" :(tip.Vrijednost == 2 ? "magenta" : "green")}}}
                                            label="" 
                                            size="small" 
                                        />}
                                />
                            </FormControl>
                            <Divider></Divider>
                            <FormControl fullWidth>
                                <FormLabel id="filter-stanje" style={{paddingLeft: '8px', paddingTop:'8px'}}>&nbsp;Stanje</FormLabel>
                                <RadioGroup aria-labelledby="filter-stanje">
                                        <MenuItem style={{cursor:"pointer"}} onClick={handleSvaStanjaClick}><Radio checked={otvoreni && realizirani && nerealizirani}></Radio><ListItemText>Svi</ListItemText></MenuItem>
                                        <MenuItem style={{cursor:"pointer"}} onClick={handleOtvoreniClick}><Radio checked={otvoreni && !realizirani && !nerealizirani}></Radio><ListItemText>Otvoreni</ListItemText></MenuItem>
                                        <MenuItem style={{cursor:"pointer"}} onClick={handleRealiziraniClick}><Radio checked={!otvoreni && realizirani && !nerealizirani}></Radio><ListItemText>Realizirani&nbsp;&nbsp;&nbsp;</ListItemText></MenuItem>
                                        <MenuItem style={{cursor:"pointer"}} onClick={handleNerealiziraniClick}><Radio checked={!otvoreni && !realizirani && nerealizirani}></Radio><ListItemText>Nerealizirani</ListItemText></MenuItem>
                                </RadioGroup>
                            </FormControl>
                            <Divider></Divider>
                            <FormControl fullWidth>
                                <FormLabel id="filter-naplata" style={{paddingLeft: '8px', paddingTop:'8px'}}>&nbsp;Naplata</FormLabel>
                                <RadioGroup aria-labelledby="filter-naplata">
                                    <MenuItem style={{cursor:"pointer"}} onClick={handleAktivniNeaktivniClick}><Radio checked={aktivni && neaktivni}></Radio><ListItemText>Svi</ListItemText></MenuItem>
                                    <MenuItem style={{cursor:"pointer"}} onClick={handleAktivniClick}><Radio checked={aktivni && !neaktivni}></Radio><ListItemText>Aktivni</ListItemText></MenuItem>
                                    <MenuItem style={{cursor:"pointer"}} onClick={handleNeaktivniClick}><Radio checked={!aktivni && neaktivni}></Radio><ListItemText>Neaktivni</ListItemText></MenuItem>
                                </RadioGroup>
                            </FormControl>
                            <div style={{textAlign:"center"}}><Button color="error" onClick={handleFilterClose}>Zatvori filter</Button></div>
                        </Menu>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{width:"100%"}}>
                        <div>
                            <AddEditDelForm page={page} limitPageSize={limitPageSize} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} sortModel={sortModel} setSortModel={setSortModel} sortModelRef={dataGridSortRef} filterModel={filterModel} setFilterModel={setFilterModel} filterModelRef={dataGridFilterRef} dataType="predmeti" data={filtriraniPredmeti} current={current} setCurrent={setCurrent} add={() => openForm("add")} edit={() => openForm("edit")} del={() => setDeleteAlert(true)}></AddEditDelForm>
                        </div>

                        {/* {current ? (
                                    <Box style={{width:"100%"}} display="flex" justifyContent={isMobileDevice ? "center" : "flex-end"} marginTop="10px">
                                        <Button variant="contained" disabled={!current || current.TipNaplateID != 1} onClick={() => setCjenikOpened(true)}><MenuBookIcon style={{marginRight:"10px"}}></MenuBookIcon>Cjenik</Button>
                                    </Box>
                            ) : null}

                        {klijentID ? (
                            <Box style={{width:"100%"}} display="flex" justifyContent={isMobileDevice ? "center" : "flex-end"} marginTop="10px">
                                <Button variant="contained" onClick={() => gotoKlijenti()}><MenuBookIcon style={{marginRight:"10px"}}></MenuBookIcon>Klijenti</Button>
                            </Box>
                        ) : null} */}

                        <Box display="flex" sx={{display:{ xs:"none", sm:"none", md:"flex" }, justifyContent:"flex-end"}} marginTop="10px">
                        
                            <Button variant="contained" style={{marginRight:"5px"}} disabled={!current || !klijentiPredmeti || !klijentiPredmeti.Predmeti || klijentiPredmeti.Predmeti.length == 0} startIcon={<SearchIcon></SearchIcon>} onClick={() => window.location.href="/#/pretragaevidencije?klijentID=" + current.KlijentID + "&predmetID=" + current.PredmetID}>Evidencije</Button>

                            <Button variant="contained" style={{marginRight:"5px"}} disabled={!current || current.TipNaplateID != 1 || !klijentiPredmeti || !klijentiPredmeti.Predmeti || klijentiPredmeti.Predmeti.length == 0} onClick={() => setCjenikOpened(true)}><MenuBookIcon style={{marginRight:"5px"}}></MenuBookIcon>Cjenik</Button>
                            
                            <Button variant="contained" style={{marginRight:"5px"}} onClick={() => gotoKlijenti()}><PersonSearchIcon style={{marginRight:"5px"}}></PersonSearchIcon>Klijenti</Button>
                            
                            {global && global.roles && global.roles.includes("19") ? (
                                <Button variant="contained" disabled={!current || !klijentiPredmeti || !klijentiPredmeti.Predmeti || klijentiPredmeti.Predmeti.length == 0} onClick={() => {setPrebaciNaFormOpened(true); focusOnKlijentZaPrebacajRef();}}><AltRouteIcon style={{marginRight:"10px"}}></AltRouteIcon>Prebaci</Button>
                            ) : null}

                        </Box>

                    </Box>
                </Grid>
            </Grid>

            <Modal className="modal" open={prebaciNaFormOpened}>
                <Box sx={{...style, minWidth:"75%", minHeight:"10%"}} style={isMobileDevice ? {padding:"10px", width:"90%"} : {}}>
                    <Box>
                        <Typography variant="h6" gutterBottom component="div">
                            <InventoryIcon fontSize="medium" style={{marginRight:"15px"}}></InventoryIcon>
                            Prebaci predmet <Box component="span" style={{fontWeight:'bold', fontStyle:'italic'}}>{current ? current.Naziv : ''}</Box> s klijenta <Box component="span" style={{fontWeight:'bold', fontStyle:'italic'}}>{current ? current.KlijentNaziv : ''}</Box>  na klijenta:
                        </Typography>
                        <FormControl fullWidth>
                            <Autocomplete style={{width:"100%"}}
                                disablePortal
                                autoHighlight
                                id="klijent-select"
                                disabled={!klijentiPredmeti || !klijentiPredmeti.Klijenti}
                                options={current ? klijentiPredmeti.Klijenti.filter(k => k.KlijentID != current.KlijentID) : klijentiPredmeti.Klijenti}
                                //value = {klijentID != null && klijent != null ? klijent : null}
                                value={klijentZaPrebacaj}
                                getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                                onChange={handleKlijentZaPrebacajChange}
                                renderOption={(props, option) => {
                                    return (
                                    <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 ? "black" : "red"}}>
                                        {option.Broj + " - " + option.Naziv}
                                    </li>
                                    );
                                }}
                                filterOptions={(options, state) => options.filter(option => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                                renderInput={(params) => <TextField {...params} inputRef={klijentZaPrebacajRef} sx={{input:{color: klijentZaPrebacaj && klijentZaPrebacaj.TipStatusaKlijentaID != 1 ? "red" : "black"}}} error={!klijentZaPrebacaj} label="Klijent" size="small" />}
                            />
                        </FormControl>
                    </Box>
                    <Box display="flex" sx={{display:{ xs:"none", sm:"none", md:"flex" }, justifyContent:"flex-end"}}>
                        <Button variant="contained" float="right" style={{ marginTop:"15px"}} disabled={!klijentZaPrebacaj || loading} onClick={() => prebaci()}><AltRouteIcon style={{ marginRight:"10px"}}></AltRouteIcon>Prebaci</Button>
                        <Button variant="outlined" float="right" color="error" style={{ marginTop:"15px", marginLeft:"4px"}} onClick={() => setPrebaciNaFormOpened(false)} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Odustani</Button>
                    </Box>
                </Box>
            </Modal>

            <Modal className="modal" open={formOpened}>
                <Box sx={{...style, minWidth:"80%"}} overflow={enableScrolling ? "auto" : "hidden"} style={isMobileDevice ? {padding:"10px", width:"90%"} : {}}>
                    <PredmetForm formPredmet={formPredmet} klijenti={klijentiPredmeti.Klijenti} setPredmetCreated={setPredmetCreated} setPredmetCreatedAlert={setPredmetCreatedAlert} setCurrent={setCurrent} setCjenikOpened={setCjenikOpened} filter={afterSave} loadPredmeti={afterSave} klijent={klijent} firmaID={firmaID} tipTvrtkeID={tipTvrtkeID} setFormOpened={setFormOpened} setEnableScrolling={setEnableScrolling}></PredmetForm>
                </Box>
            </Modal>

            <Modal className="modal" open={cjenikOpened}>
                <Box sx={cjenikStyle} overflow="auto" style={isMobileDevice ? {padding:"10px", width:"90%"} : {}}>
                    <Cjenik cjenikChanged={cjenikChanged} setCjenikChanged={setCjenikChanged} setPredmetCreatedAlert={setPredmetCreatedAlert} predmetCreatedAlert={predmetCreatedAlert} predmet={current ? current : null} predmetID={current ? current.PredmetID : null} firmaID={firmaID} predmeti={klijentiPredmeti ? klijentiPredmeti.Predmeti : []} setCjenikOpened={setCjenikOpened}></Cjenik>
                    <div style={{display:"flex", justifyContent:"flex-end", width:"100%"}}>
                        <Button variant="outlined" float="right" color="error" style={{ marginTop:"15px"}} onClick={() => { if (cjenikChanged || predmetCreated) {afterSave(); setPredmetCreated(false); setCjenikChanged(false);} setCjenikOpened(false);}} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </div>
                </Box>
            </Modal>

            {deleteAlert ? <AlertDialog title="Brisanje predmeta" message={"Jeste li sigurni da želite obrisati odabrani predmet?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={deleteCanceled} confirmAction={deleteConfirmed}></AlertDialog> : null}

            {alertMessage ? (
                <Modal className="modal" open={alertMessage}>
                    <Box sx={alertStyle}>
                        <Alert severity="error">{alertMessage}</Alert>
                        <Box style={{width:"100%"}} display="flex" justifyContent="flex-end" marginTop="10px">
                                <Button variant="contained" onClick={() => setAlertMessage(null)}>OK</Button>
                        </Box>
                    </Box>
                </Modal>
            ) : null}

            <Modal className="modal" open={errorMessage != null}>
                <Box sx={alertStyle}>
                    <ErrorAlert message={errorMessage} close={() => setErrorMessage(null)}></ErrorAlert>
                </Box>
            </Modal>

            {logoutAlert ? <AlertDialog title="Odjava" message={"Upravo se događa odjava inicirana od strane administratora."} cancelHidden={true} confirmHidden={true} okHidden={false} okLabel={"OK"} okAction={startLogout}></AlertDialog> : null}

            {prebaciSuccessAlert ? <AlertDialog title="Predmet prebačen na drugog klijenta" message={"Prebacili ste predmet na drugog klijenta. Preporučamo da provjerite njegove postavke, posebice tip naplatnosti i tip oporezivosti."} cancelHidden={true} confirmHidden={true} okHidden={false} okLabel={"OK"} okAction={() => {
                setPrebaciSuccessAlert(false);
                filterRef.current = {
                    KlijentID : klijentZaPrebacaj.KlijentID,
                    Tip : tip.Vrijednost,
                    Otvoreni : otvoreni,
                    Realizirani : realizirani,
                    Nerealizirani : nerealizirani,
                    Aktivni : aktivni,
                    Neaktivni : neaktivni,
                    Predmet : current,
                    Page : pageSize <= 100 ? page : 0,
                    PageSize : pageSize <= 100 ? pageSize : 100
                };
                dataGridFilterRef.current = filterModel;
                dataGridSortRef.current = sortModel;    
                loadKlijentiAndPredmeti(global.user.firmaID, () => setKlijent(JSON.parse(JSON.stringify(klijentZaPrebacaj))), false, true);
            }}></AlertDialog> : null}

        </ComponentContainer>
    );
}

export default Predmeti;