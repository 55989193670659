import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import api from "../util/api";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";
import globalContext from "./globalContext";

export default function BlagdanForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formBlagdan = props.formBlagdan;
    const close = props.close;
    const loadBlagdani = props.loadBlagdani;

    const [naziv, setNaziv] = useState("");
    const [datum, setDatum] = useState(new Date());
    const [fiksni, setFiksni] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useConstructor(() => {
        if (formBlagdan) {
            setNaziv(formBlagdan.Naziv);
            setDatum(formBlagdan.Datum);
            setFiksni(formBlagdan.Fiksni);
        }
    });

    function save() {
        setLoading(true);
        let resource = {
            BlagdanID: formBlagdan ? formBlagdan.BlagdanID : -1,
            Naziv: naziv,
            Datum: datum,
            Fiksni: fiksni,
            FirmaID: global.user.firmaID
        };
        api.post("blagdani/save", resource, saveSuccess, fail);
    }

    function saveSuccess() {
        loadBlagdani(null);
        close();
    }

    function fail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            <EditCalendarIcon fontSize="medium" style={{marginRight:"15px"}}></EditCalendarIcon>
                            {formBlagdan ? (
                                formBlagdan.Naziv
                            ) : (
                                "Novi blagdan"
                            )}
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <TextField label="Naziv" value={naziv} inputProps={{ maxLength: 150 }} onChange={e => setNaziv(e.target.value)} error={!naziv} size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12} style={{display:"flex", alignItems:"center"}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                        <DatePicker
                            views={['year', 'month', 'day']}
                            label="Datum"
                            value={datum}
                            inputFormat="dd.MM.yyyy"
                            onChange={(v) => {setDatum(v);}}
                            renderInput={(params) => <TextField style={{width:"100%"}} {...params} size="small" />}
                        />
                    </LocalizationProvider>   
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        labelPlacement="end" 
                        label={<span style={{ display: 'inline-flex', verticalAlign: 'text-bottom', boxSizing: 'inherit', textAlign: 'center', alignItems: 'bottom'}}>Fiksni datum&nbsp;<Tooltip title="Blagdan se svake godine obilježava na isti datum." enterDelay={200} enterNextDelay={200}><span style={{paddingTop:"3px"}}><HelpOutlineIcon fontSize="small"></HelpOutlineIcon></span></Tooltip></span>}
                        style={{marginLeft:"0px"}}
                        control={<Checkbox size="small" style={{marginTop:"-10px"}} checked={fiksni} onChange={e => setFiksni(e.target.checked)}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} disabled={!naziv || !datum || isNaN(datum)} variant="contained" style={{marginRight:"10px"}} color="success" startIcon={<SaveIcon />}>Spremi</Button>
                        <Button variant="outlined" color="error" onClick={close} startIcon={<ExitToApp />}>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
        </ComponentContainer>
    );
}