import { Autocomplete, Button, Divider, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, ListItem, Menu, MenuItem, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from "@mui/system";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import api from "../util/api";
import SearchIcon from "@mui/icons-material/Search";
import HistoryIcon from '@mui/icons-material/History';
import globalContext from "./globalContext";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

function SatnicaRadaForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formSatnica = props.formSatnica;
    const currentDetalj = props.currentDetalj;
    const setFormOpened = props.setFormOpened;

    const [loading, setLoading] = useState(true);
    const [naziv, setNaziv] = useState("");
    const [osnovnaSatnica, setOsnovnaSatnica] = useState(false);
    const [predlozakSatnica, setPredlozakSatnica] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        if (formSatnica) {
            setNaziv(formSatnica.Naziv);
            setOsnovnaSatnica(formSatnica.OsnovnaSatnica);
        }
        else{
            if(props.satniceRada && props.satniceRada.length > 0){
                let defaultSatnica = props.satniceRada.find(sr=>sr.OsnovnaSatnica == true);
                if(defaultSatnica){
                    setPredlozakSatnica(defaultSatnica);
                    setNaziv(defaultSatnica.Naziv + ' - kopija');
                }
                else{
                    setPredlozakSatnica(props.satniceRada[0]);
                    setNaziv(props.satniceRada[0] + ' - kopija');
                }
            }
        }
        setLoading(false);
    });

    function save() {
        let resource = {
            SatnicaRadaID : formSatnica ? formSatnica.SatnicaRadaID : (predlozakSatnica ? predlozakSatnica.SatnicaRadaID * -1 : -1),
            Naziv : naziv,
            OsnovnaSatnica : formSatnica ? osnovnaSatnica : false, 
            // FiskalnaOznaka : fiskalnaOznaka,
            // Adresa : {
            //     AdresaNaziv : adresa,
            //     MjestoResource : mjesto
            // },
            FirmaID : props.firmaID
        };
        setLoading(true);
        api.post("satnicarada/save", resource, saveSuccess, saveFail);
    }

    function saveSuccess(data) {
        let returnPoint = {
            SatnicaRadaID: formSatnica ? formSatnica.SatnicaRadaID : data,
            SatnicaRadaDetaljID: formSatnica && currentDetalj ? currentDetalj.SatnicaRadaDetaljID : 0
        }
        setLoading(false);
        props.loadSatniceRada(returnPoint);
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    // function handleHistoryClick(event) {
    //     setAnchorEl(event.currentTarget);
    // };

    // function handleHistoryClose(event) {
    //     setAnchorEl(null);
    // };

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formSatnica ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <AddIcon fontSize="medium" style={{marginRight:"15px"}}></AddIcon>
                            )}
                            {formSatnica ? (
                                "Uređivanje satnice rada"
                            ) : (
                                "Nova satnica rada"
                            )}
                        </div>
                    </Typography>
                </Grid>
                {formSatnica ? (
                    <>
                        <Grid item xs={9}>
                            <FormControl fullWidth>
                                <TextField value={naziv} inputProps={{ maxLength: 50 }} label="Naziv" onChange={e => setNaziv(e.target.value)} error={!naziv} size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} display="flex" justifyContent="flex-end">
                            <FormControlLabel
                                tabIndex={-1}
                                labelPlacement="start"
                                label={<span style={{ fontSize: "0.8em" }}>Osnovna satnica</span>}
                                control={<Checkbox tabIndex={-1} disabled={formSatnica && formSatnica.OsnovnaSatnica} checked={osnovnaSatnica} onChange={(e) => setOsnovnaSatnica(e.target.checked)}></Checkbox>}
                            ></FormControlLabel>
                        </Grid>  
                    </>
                )
                :
                (
                    <>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    disablePortal
                                    autoHighlight
                                    options={props.satniceRada}
                                    value={predlozakSatnica}
                                    getOptionLabel={(option) => option.Naziv}
                                    onChange={(e,v) => {
                                        if (v) {
                                            setPredlozakSatnica(v);
                                            setNaziv(v.Naziv + ' - kopija');
                                        }  
                                    }}                               
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} error={!predlozakSatnica} label="Predložak satnice" size="small" />}
                                    ListboxProps={
                                        {
                                        style:{
                                            maxHeight: '10rem'
                                            }
                                        }
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField value={naziv} inputProps={{ maxLength: 50 }} label="Naziv" onChange={e => setNaziv(e.target.value)} error={!naziv} size="small"></TextField>
                            </FormControl>
                        </Grid>                        
                    </>
                )}              
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={save} variant="contained" color="success" style={{marginRight:"10px"}} disabled={(!formSatnica && (!predlozakSatnica || !naziv)) || !global.roles.includes("20") || (formSatnica && !naziv)}><SaveIcon style={{marginRight:"10px"}} /> Spremi</Button>
                        <Button variant="outlined" color="error" onClick={() => {setFormOpened(false);}} ><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                    </Box>
                </Grid>
            </Grid>

            {/* <Menu
                anchorEl={anchorEl}
                open={historyOpened}
                onClose={handleHistoryClose}
                PaperProps={{ style: { maxWidth: "50vw" } }} 
            >
                <ListItem>
                    <FormControl fullWidth>
                        <TextField 
                            sx={{
                                '& legend': { display: 'none' },
                                '& fieldset': { top: 0 },
                            }} 
                            value={historyFilter} 
                            onChange={e => setHistoryFilter(e.target.value)} 
                            size="small"
                            InputProps={{endAdornment:(<InputAdornment position="end"><SearchIcon></SearchIcon></InputAdornment>)}}
                            onKeyDown={e => e.stopPropagation()}
                        ></TextField>
                    </FormControl>
                </ListItem>
                <Divider></Divider>
                   {adreseHistory.filter(a => a.AdresaNaziv.toLowerCase().includes(historyFilter.toLowerCase())).map((adresa, index) => {
                        return <Tooltip title={adresa.AdresaNaziv + ", " + adresa.MjestoResource.PostanskiBroj + " " + adresa.MjestoResource.Naziv} enterDelay={200} enterNextDelay={200}>
                                <MenuItem onClick={() => {setAdresa(adresa.AdresaNaziv); setMjesto(adresa.MjestoResource);}}>
                                    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                        {adresa.AdresaNaziv + ", " + adresa.MjestoResource.PostanskiBroj + " " + adresa.MjestoResource.Naziv}
                                    </div>
                                </MenuItem>
                            </Tooltip>
                    })}
            </Menu> */}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default SatnicaRadaForm;