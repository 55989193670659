import { Box, Button, Checkbox, Radio, ClickAwayListener, Container, FormControl, Grid, IconButton, InputLabel, List, ListItem, ListItemText, Menu, MenuItem, Modal, Paper, Popper, Select, TextField, Tooltip, Typography, Stack, Divider } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import api from "../util/api";
import database from "../util/database";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import Util from "../util/util";
import KlijentForm from "./klijentForm";
import SearchIcon from "@mui/icons-material/Search";
import AddEditDelForm from "./addEditDelForm";
import AlertDialog from "./alertdialog";
import { CheckBox, Label } from "@mui/icons-material";
import InventoryIcon from '@mui/icons-material/Inventory';
import Predmeti from "./predmeti";
import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import ExitToApp from "@mui/icons-material/ExitToApp";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Kontakti from "./kontakti";
import PaymentsIcon from '@mui/icons-material/Payments';
import FinancijskaKartica from "./financijskaKartica";
import ErrorAlert from "./errorAlert";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    padding:'15px',
    maxHeight: '95%',
    maxWidth: '95%'
};


function Klijenti(props) {

    const useConstructor = Util.useConstructor();
    const isMobileDevice = Util.isMobileDevice();
    
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [deleteAlert, setDeleteAlert] = useState(false);

    const global = props.global;
    const openFinCard = props.openFinCard;
    const filterRef = props.filterRef;
    const dataGridFilterRef = props.dataGridFilterRef;
    const dataGridSortRef = props.dataGridSortRef;

    const klijentiPredmeti = props.klijentiPredmeti || {
        Klijenti: [],
        Predmeti: [],
        KlijentiLastUpdate: null,
        PredmetiLastUpdate: null
    };
    const loadKlijentiAndPredmeti = props.loadKlijentiAndPredmeti;

    //const [klijenti, setKlijenti] = useState([]);
    const [filtriraniKlijenti, setFiltriraniKlijenti] = useState([]);
    const [current, setCurrent] = useState(null);
    const [urlParams, setUrlParams] = useState(Util.getParametersFromUrl((document.location)) ? Util.getParametersFromUrl((document.location)) : null)
    const [formOpened, setFormOpened] = useState(false);
    const [formKlijent, setFormKlijent] = useState(null);
    const [domaci, setDomaci] = useState(true);
    const [strani, setStrani] = useState(true);
    const [pravni, setPravni] = useState(true);
    const [fizicki, setFizicki] = useState(true);
    const [aktivni, setAktivni] = useState(true);
    const [neaktivni, setNeaktivni] = useState(true);
    const [enableScrolling, setEnableScrolling] = useState(false);
    const [financijskaKarticaKlijentID, setFinancijskaKarticaKlijentID] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [logoutAlert, setLogoutAlert] = useState(false);
    const [filterModel, setFilterModel] = useState({
        items: []
    });
    const [sortModel, setSortModel] = useState([]);
    
    const [anchorEl, setAnchorEl] = useState(null);
    const filterOpened = Boolean(anchorEl);
    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(null);
    const [limitPageSize, setLimitPageSize] = useState(null);

    useConstructor(() => {
        //global.setRoles(database.get("roles") || []);
        //load();

        if (klijentiPredmeti.KlijentiLastUpdate != "" && klijentiPredmeti.KlijentiLastUpdate != null && klijentiPredmeti.PredmetiLastUpdate != "" && klijentiPredmeti.PredmetiLastUpdate != null) {
            let resource = {
                FirmaID: global.user.firmaID,
                KlijentiLastUpdate: klijentiPredmeti.KlijentiLastUpdate,
                PredmetiLastUpdate: klijentiPredmeti.PredmetiLastUpdate,
                KorisnikID: global.user.korisnikID
            }
            api.post("firma/checkeverything", resource, checkEverythingSuccess, checkEverythingFail);
        }
    });

    function checkEverythingSuccess(data) {
        if (data && (data.UserAccountValid == false || data.LogoutRequired)) {
            setLoading(false);
            setLogoutAlert(true);
            return;
        }

        if (data.CheckIfKlijentiOrPredmetiUnchangedResource == false) {
            loadKlijentiAndPredmeti(global.user.firmaID, loadSuccess, data.KlijentiChanged, data.PredmetiChanged);
        } else {
            loadSuccess();
        }
    }

    function checkEverythingFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function startLogout() {
        setLogoutAlert(false);
        Util.logout();
        window.location = "/#/login";
    }

    function load() {
        api.get("klijenti/all/" + global.user.firmaID, loadSuccess, loadFail);
    };

    function loadSuccess() {
        //setKlijenti(data);
        // if (current) {
        //     setCurrent(klijentiPredmeti.Klijenti.find(k => k.KlijentID == current.KlijentID));
        // } else if(urlParams && urlParams.klijentID){
        //     //naći tog klijnet ai upisati njegov naziv u search
        //     let klnt = klijentiPredmeti.Klijenti.find(k => k.KlijentID == urlParams.klijentID);
        //     if(klnt){
        //         setSearchText(klnt.Naziv);
        //     }
        //     else{
        //         setSearchText(null);
        //         if(klijentiPredmeti.Klijenti && klijentiPredmeti.Klijenti.length > 0){
        //             setCurrent(klijentiPredmeti.Klijenti[0]);
        //         }
        //     }
        // } else if (klijentiPredmeti.Klijenti && klijentiPredmeti.Klijenti.length > 0) {
        //     setCurrent(klijentiPredmeti.Klijenti[0]);
        // }
        if (filterRef.current == null) {
            setLimitPageSize(false);
        }
        if (filterRef.current != null) {
            setSearchText(filterRef.current.SearchText);
            setDomaci(filterRef.current.Domaci);
            setStrani(filterRef.current.Strani);
            setPravni(filterRef.current.Pravni);
            setFizicki(filterRef.current.Fizicki);
            setAktivni(filterRef.current.Aktivni);
            setNeaktivni(filterRef.current.Neaktivni);
            if (filterRef.current.KlijentID) {
                let kl = klijentiPredmeti.Klijenti.find(k => k.KlijentID == filterRef.current.KlijentID);
                if (kl) {
                    setCurrent(kl);
                }
            }
            setLimitPageSize(true);
        } else if(urlParams && urlParams.klijentID){
            //naći tog klijnet ai upisati njegov naziv u search
            let klnt = klijentiPredmeti.Klijenti.find(k => k.KlijentID == urlParams.klijentID);
            if(klnt){
                setSearchText(klnt.Naziv);
            }
            else{
                setSearchText(null);
                if(klijentiPredmeti.Klijenti && klijentiPredmeti.Klijenti.length > 0){
                    setCurrent(klijentiPredmeti.Klijenti[0]);
                }
            }
        }
        if (dataGridFilterRef.current != null) {
            setFilterModel(dataGridFilterRef.current);
            dataGridFilterRef.current = null;
        }
        if (dataGridSortRef.current != null) {
            setSortModel(dataGridSortRef.current);
            dataGridSortRef.current = null;
        }
        focusOnSearch();
        setLoading(false);
        if(openFinCard && klijentiPredmeti.Klijenti && klijentiPredmeti.Klijenti.length > 0){
            setFinancijskaKarticaKlijentID(klijentiPredmeti.Klijenti[0].KlijentID);
            //setFinancijskaKarticaKlijentID(0);
        }
    };

    function loadFail(data) {
        if(window && window.location.includes('klijent')){
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        }
    };

    useEffect(() => {
        if (limitPageSize != null) {
            setPageSize(filterRef.current && filterRef.current.PageSize ? filterRef.current.PageSize : 100);
        }
    }, [limitPageSize]);

    useEffect(() => {       
        if (pageSize != null) {
            // setTimeout(() => {
            //     setPage(filterRef.current != null ? (filterRef.current.Page || 0) : 0);
            //     filterRef.current = null;
            // }, [200])
            setPage(filterRef.current != null ? (filterRef.current.Page || 0) : 0);
            filterRef.current = null;            
        }
    }, [pageSize]);

    function handleClick(item) {
        setCurrent(item);
    };

    function openForm(type) {
        if (type == "add") {
            setFormKlijent(null);
        } else {
            setFormKlijent(current);
        }
        setFormOpened(true);
    }

    function deleteKlijent() {
        setDeleteAlert(true);
    }

    function deleteConfirmed() {
        setLoading(true)
        api.post("klijenti/delete/" + current.KlijentID, null, deleteSuccess, deleteFail);
        setDeleteAlert(false);
    }

    function deleteCanceled() {
        setDeleteAlert(false);
    }

    function deleteSuccess() {
        setLoading(false);
        reloadKlijentiAndPredmeti();
    }

    function deleteFail(data) {
        setErrorMessage(data.Message);
        setLoading(false);
    }

    function reloadKlijentiAndPredmeti() {
        filterRef.current = {
            SearchText : searchText,
            Domaci : domaci,
            Strani : strani,
            Pravni : pravni,
            Fizicki : fizicki,
            Aktivni : aktivni,
            Neaktivni : neaktivni,
            KlijentID : current ? current.KlijentID : null,
            Page : pageSize <= 100 ? page : 0,
            PageSize : pageSize <= 100 ? pageSize : 100
        };
        dataGridFilterRef.current = filterModel;
        dataGridSortRef.current = sortModel;
        loadKlijentiAndPredmeti(global.user.firmaID, null, true, false);
    }

    useEffect(() => {
        if(klijentiPredmeti && klijentiPredmeti.Klijenti){
            var selClients = searchText ? klijentiPredmeti.Klijenti.filter(k => k.Naziv.toLowerCase().includes(searchText.toLowerCase())) : klijentiPredmeti.Klijenti;
            if(selClients){
                setCurrent(selClients[0]);
            }
        }
    }, [searchText])

    function handleSearchTextChange(e) {
        setSearchText(e.target.value);
    }

    function filter() {
        // setLoading(true);
        // let filterData = {
        //     FirmaID: global.user.firmaID,
        //     Domaci: domaci,
        //     Strani: strani,
        //     Pravni: pravni,
        //     Fizicki: fizicki,
        //     Aktivni: aktivni,
        //     Neaktivni: neaktivni
        // }
        // api.post("klijenti/filter", filterData, loadSuccess, loadFail);

        let newFiltriraniKlijenti = JSON.parse(JSON.stringify(klijentiPredmeti.Klijenti));

        if (!domaci) {
            newFiltriraniKlijenti = newFiltriraniKlijenti.filter(k => k.TipDrzavnostiID != 1);
        }
        if (!strani) {
            newFiltriraniKlijenti = newFiltriraniKlijenti.filter(k => k.TipDrzavnostiID != 2);
        }
        if (!pravni) {
            newFiltriraniKlijenti = newFiltriraniKlijenti.filter(k => k.TipOsobeID != 1);
        }
        if (!fizicki) {
            newFiltriraniKlijenti = newFiltriraniKlijenti.filter(k => k.TipOsobeID != 2);
        }
        if (!aktivni) {
            newFiltriraniKlijenti = newFiltriraniKlijenti.filter(k => k.TipStatusaKlijentaID != 1);
        }
        if (!neaktivni) {
            newFiltriraniKlijenti = newFiltriraniKlijenti.filter(k => k.TipStatusaKlijentaID != 2);
        }

        setFiltriraniKlijenti(newFiltriraniKlijenti);
    }

    //useEffect(() => load(), [formOpened]);

    useEffect(() => filter(), [domaci, strani, pravni, fizicki, aktivni, neaktivni, klijentiPredmeti]);

    const handleDomaciStraniClick = () => {
        setDomaci(true);
        setStrani(true);
    }

    const handleDomaciClick = () => {
        setDomaci(true);
        setStrani(false);
    }

    const handleStraniClick = () => {
        setDomaci(false);
        setStrani(true);
    }

    const handlePravniFizickiClick = () => {
        setPravni(true);
        setFizicki(true);
    }

    const handlePravniClick = () => {
        setPravni(true);
        setFizicki(false);
    }

    const handleFizickiClick = () => {
        setPravni(false);
        setFizicki(true);
    }

    const handleAktivniNeaktivniClick = () => {
        setAktivni(true);
        setNeaktivni(true);
    }

    const handleAktivniClick = () => {
        setAktivni(true);
        setNeaktivni(false);
    }

    const handleNeaktivniClick = () => {
        setAktivni(false);
        setNeaktivni(true);
    }

    const handlePredmetiClick = () => {
        window.location = "#/predmeti?klijentID=" + current.KlijentID; 
        global.setSelectedTab("Predmeti");
    }

    const searchRef = useRef(null);

    function focusOnSearch() {
        setTimeout(() => {
            if (searchRef.current) {
                //satiRef.current.blur();
                searchRef.current.focus();
            }
        }, 100);
    }

    const handleKeyPressShorcuts = useCallback((event) => {
        if(financijskaKarticaKlijentID == null){
            if (event.altKey) {
                let charCode = String.fromCharCode(event.which).toLowerCase();
                if (charCode == 'd' && global.roles.includes("18")) {
                    event.preventDefault();
                    if (!loading) {
                        openForm("add");
                    }
                }
            }    
        }
    }, [loading, financijskaKarticaKlijentID]);

    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressShorcuts);

        return () => {
            document.removeEventListener('keydown', handleKeyPressShorcuts);
        };
    }, [handleKeyPressShorcuts]);
    
    return (
        <ComponentContainer loading={loading || global.loading} >
            <div style={{display:"flex", justifyContent:"flex-start", alignItems:"flex-end", width:"100%"}}>
                <Typography variant="h4" gutterBottom component="div" style={{width:"30%"}}>
                    <GroupIcon fontSize="medium" style={{marginRight:"15px"}}></GroupIcon>
                    Klijenti
                </Typography>
                <div style={{display:"flex", justifyContent:"flex-end", width:"70%"}}>
                    <Button
                        id="basic-button"
                        aria-controls={filterOpened ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={filterOpened ? 'true' : undefined}
                        onClick={handleFilterClick}
                        size="medium"
                        style={{height:"75%"}}
                        variant="outlined"
                        endIcon={filterOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    >
                        Filter
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={filterOpened}
                        onClose={handleFilterClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem style={{cursor:"pointer"}} onClick={handleDomaciStraniClick}><Radio checked={domaci && strani}></Radio><ListItemText>Domaći i strani</ListItemText></MenuItem>
                        <MenuItem style={{cursor:"pointer"}} onClick={handleDomaciClick}><Radio checked={domaci && !strani}></Radio><ListItemText>Domaći</ListItemText></MenuItem>
                        <MenuItem style={{cursor:"pointer"}} onClick={handleStraniClick}><Radio checked={!domaci && strani}></Radio><ListItemText>Strani</ListItemText></MenuItem>
                        
                        <Divider></Divider>
                        
                        <MenuItem style={{cursor:"pointer"}} onClick={handlePravniFizickiClick}><Radio checked={pravni && fizicki}></Radio><ListItemText>Pravni i fizički</ListItemText></MenuItem>
                        <MenuItem style={{cursor:"pointer"}} onClick={handlePravniClick}><Radio checked={pravni && !fizicki}></Radio><ListItemText>Pravni</ListItemText></MenuItem>
                        <MenuItem style={{cursor:"pointer"}} onClick={handleFizickiClick}><Radio checked={!pravni && fizicki}></Radio><ListItemText>Fizički</ListItemText></MenuItem>

                        <Divider></Divider>
                        
                        <MenuItem style={{cursor:"pointer"}} onClick={handleAktivniNeaktivniClick}><Radio checked={aktivni && neaktivni}></Radio><ListItemText>Svi</ListItemText></MenuItem>
                        <MenuItem style={{cursor:"pointer"}} onClick={handleAktivniClick}><Radio checked={aktivni && !neaktivni}></Radio><ListItemText>Aktivni</ListItemText></MenuItem>
                        <MenuItem style={{cursor:"pointer"}} onClick={handleNeaktivniClick}><Radio checked={!aktivni && neaktivni}></Radio><ListItemText>Neaktivni</ListItemText></MenuItem>


                        <div style={{textAlign:"center"}}><Button color="error" onClick={handleFilterClose}>Zatvori filter</Button></div>
                    </Menu>
                </div>
            </div>

            <Box style={{width:"100%"}}>
                <Grid container>
                    <Grid item xs={12}>
                        <AddEditDelForm page={page} limitPageSize={limitPageSize} setPage={setPage} pageSize={pageSize} setPageSize={setPageSize} sortModel={sortModel} setSortModel={setSortModel} sortModelRef={dataGridSortRef} filterModel={filterModel} setFilterModel={setFilterModel} filterModelRef={dataGridFilterRef} searchTextValue={searchText} searchRef={searchRef} dataType="klijenti" data={filtriraniKlijenti != null ? (searchText ? filtriraniKlijenti.filter(k => k.Naziv.toLowerCase().includes(searchText.toLowerCase())) : filtriraniKlijenti) : []} add={() => openForm("add")} edit={() => openForm("edit")} del={deleteKlijent} current={current} setCurrent={setCurrent} handleSearchTextChange={handleSearchTextChange} deleteSearchText={() => setSearchText('')}></AddEditDelForm>
                    </Grid>
                    {current ? (
                        <Grid item xs={6} marginTop="5px">
                            <Kontakti klijent={current} kontakti={current ? current.Kontakti : []} load={reloadKlijentiAndPredmeti}></Kontakti>
                        </Grid>
                    ) : null}
                    {current ? (
                        <Grid item xs={6} marginTop="5px">
                            <Box display="flex" justifyContent="flex-end">
                                <Button variant="contained" style={{marginRight:"5px"}} onClick={() => setFinancijskaKarticaKlijentID(current.KlijentID)}><PaymentsIcon style={{marginRight:"10px"}}></PaymentsIcon>Fin. kartica</Button>
                                <Button variant="contained" onClick={handlePredmetiClick}><InventoryIcon style={{marginRight:"10px"}}></InventoryIcon>Predmeti</Button>
                            </Box>
                        </Grid>
                    ) : null}
                    {/* <Grid item xs={12}>
                        <Button onClick={() => api.post("klijenti/delete/234432423", null, () => alert("ok"), (data) => setErrorMessage(data))}>Napravi error</Button>
                    </Grid> */}
                </Grid>
            </Box>
          
            <Modal className="modal" open={formOpened}>
                <Box sx={{...style, minWidth:"80%"}} overflow={enableScrolling ? "auto" : "hidden"} style={isMobileDevice ? {padding:"10px", width:"90%"} : {}}>
                    <KlijentForm klijent={formKlijent} klijenti={klijentiPredmeti.Klijenti} firmaID={global.user.firmaID} filter={reloadKlijentiAndPredmeti} setFormOpened={setFormOpened} setEnableScrolling={setEnableScrolling} />
                </Box>
            </Modal>

            <Modal className="modal" open={financijskaKarticaKlijentID != null}>
                <Box sx={{...style, minWidth:"90%"}} overflow={enableScrolling ? "auto" : "hidden"} style={isMobileDevice ? {padding:"10px", width:"90%"} : {}}>
                    <FinancijskaKartica klijenti={klijentiPredmeti.Klijenti} klijentID={financijskaKarticaKlijentID} close={() => setFinancijskaKarticaKlijentID(null)}></FinancijskaKartica>
                </Box>
            </Modal>

            {deleteAlert ? <AlertDialog title="Brisanje klijenta" message={"Jeste li sigurni da želite obrisati odabranog klijenta?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={deleteCanceled} confirmAction={deleteConfirmed}></AlertDialog> : null}
            
            <Modal className="modal" open={errorMessage != null}>
                <Box sx={style}>
                    <ErrorAlert message={errorMessage} close={() => setErrorMessage(null)}></ErrorAlert>
                </Box>
            </Modal>

            {logoutAlert ? <AlertDialog title="Odjava" message={"Upravo se događa odjava inicirana od strane administratora."} cancelHidden={true} confirmHidden={true} okHidden={false} okLabel={"OK"} okAction={startLogout}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default Klijenti;