import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import AddCardIcon from '@mui/icons-material/AddCard';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

export default function DodatneNaplate(props) {

    const useConstructor = Util.useConstructor();

    const [loading, setLoading] = useState(true);
    const [firme, setFirme] = useState([]);
    const [firma, setFirma] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [barkodOmogucen, setBarkodOmogucen] = useState(false);
    const [barkodCijena, setBarkodCijena] = useState(0);
    const [valute, setValute] = useState([]);
    const [valuta, setValuta] = useState(null);
    const [cjenici, setCjenici] = useState([]);
    const [cjenik, setCjenik] = useState(null);
    const [barkodBrojRacuna, setBarkodBrojRacuna] = useState(0);

    useConstructor(() => {
        api.get("firma/getall", loadFirmeSuccess, loadFirmeFail);
    });

    function loadFirmeSuccess(data) {
        setFirme(data);
        setLoading(false);
    }
 
    function loadFirmeFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleFirmaChange(v) {
        if (v) {
            setFirma(v);
            setLoading(true);
            api.get("postavke/getdodatnenaplate/" + v.FirmaID, loadParametriSuccess, loadParametriFail);
        }
    }

    function loadParametriSuccess(data) {
        setValute(data.Valute);
        setCjenici(data.Cjenici);
        setValuta(data.Valute.find(v => v.ValutaID == data.BarkodValutaID));
        setCjenik(data.Cjenici.find(c => c.ElektronickaArhivaCjenikID == data.ElektronickaArhivaCjenikID));
        setBarkodCijena(data.BarkodCijena || 0);
        setBarkodOmogucen(data.BarkodOmogucen);
        setBarkodBrojRacuna(data.BarkodNaplataBrojRacuna || 0);
        setLoading(false);
    }
 
    function loadParametriFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save() {
        setLoading(true);
        let resource = {
            FirmaID: firma.FirmaID,
            BarkodOmogucen: barkodOmogucen,
            BarkodNaplataBrojRacuna: barkodBrojRacuna,
            BarkodCijena: barkodOmogucen ? barkodCijena : null,
            BarkodValutaID: barkodOmogucen ? (valuta ? valuta.ValutaID : null) : null,
            ElektronickaArhivaCjenikID: cjenik ? cjenik.ElektronickaArhivaCjenikID : null
        };
        api.post("postavke/savedodatnenaplate", resource, saveSuccess, saveFail);
    }

    function saveSuccess(data) {
        handleFirmaChange(firma);
        setLoading(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleBarkodOmogucenChange(e) {
        setBarkodOmogucen(e.target.checked);
        if (e.target.checked) {
            api.get("postavke/barkoddefault", (data) => {
                setBarkodCijena(data.Cijena);
                setValuta(valute.find(v => v.ValutaID == data.ValutaID));
                setBarkodBrojRacuna(data.BrojRacuna);
                setLoading(false);
            }, (data) => {
                setErrorMessage(data ? (data.Message || data.toString()) : "");
                setLoading(false);
            })
        } else {
            setBarkodCijena(0);
            setBarkodBrojRacuna(0);
            setValuta(null);
        }
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{marginTop:"20px", marginBottom:"10px", display:"flex", alignItems:"center"}}>
                        <AddCardIcon fontSize="medium" style={{marginRight:"15px"}}></AddCardIcon>
                        Dodatne naplate
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <Autocomplete
                            disablePortal
                            disableClearable
                            autoHighlight
                            options={firme}
                            getOptionLabel={(option) => option.Naziv}
                            value={firma}
                            onChange={(e,v) => {
                                handleFirmaChange(v);
                            }}                           
                            renderInput={(params) => <TextField {...params} label="Firma" size="small"/>}
                        />
                    </FormControl>
                </Grid>
                {firma ? (
                    <>
                        <Grid item xs={4} marginTop="20px">
                            <FormControlLabel control={<Checkbox checked={barkodOmogucen} onChange={e => handleBarkodOmogucenChange(e)} />} label="Omogući ispis barkoda za plaćanje na računima" />
                        </Grid>
                        <Grid item xs={2} marginTop="20px">
                            <FormControl fullWidth >
                                <TextField type="number" label="Jedinična naknada" value={barkodCijena} onChange={e => setBarkodCijena(e.target.value)} disabled={!barkodOmogucen} error={barkodOmogucen && (barkodCijena == null || isNaN(parseFloat(barkodCijena)) || parseFloat(barkodCijena) < 0)} size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} marginTop="20px">
                            <Autocomplete
                                autoHighlight
                                disablePortal
                                disableClearable
                                options={valute}
                                disabled={!barkodOmogucen}
                                getOptionLabel={(option) => option.Kratica}
                                value={valuta || null}
                                onChange={(e,v) => setValuta(v)}                           
                                renderInput={(params) => <TextField {...params} error={barkodOmogucen && !valuta} label="Valuta" size="small"/>}
                            />
                        </Grid>
                        <Grid item xs={2} marginTop="20px">
                            <FormControl fullWidth >
                                <TextField type="number" label="Broj računa" value={barkodBrojRacuna} onChange={e => setBarkodBrojRacuna(e.target.value)} disabled={!barkodOmogucen} error={barkodOmogucen && (barkodBrojRacuna == null || isNaN(parseInt(barkodBrojRacuna)) || parseInt(barkodBrojRacuna) <= 0)} size="small"></TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth >
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    autoHighlight
                                    options={cjenici}
                                    getOptionLabel={(option) => option.Naziv + " - po datoteci: " + option.MiBPerFile + " MiB, ukupno: " + option.MiBTotal + " MiB, cijena: " + option.Cijena + " " + option.Valuta}
                                    value={cjenik}
                                    onChange={(e,v) => {
                                        setCjenik(v);
                                    }}                           
                                    renderInput={(params) => <TextField {...params} label="Naplata elektroničke arhive" size="small"/>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"right"}}>
                            <Button style={{marginRight:"5px"}} color="success" onClick={save} disabled={!cjenik || (barkodOmogucen && (barkodCijena == null || isNaN(parseFloat(barkodCijena)) || parseFloat(barkodCijena) < 0 || barkodBrojRacuna == null || isNaN(parseInt(barkodBrojRacuna)) || parseInt(barkodBrojRacuna) <= 0 || !valuta))} startIcon={<SaveIcon></SaveIcon>} variant="contained">Spremi</Button>
                            <Button color="error" onClick={() => handleFirmaChange(firma)} startIcon={<CancelIcon></CancelIcon>} variant="outlined">Poništi</Button>
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </ComponentContainer>
    );
}