import { Tooltip, Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormLabel, InputAdornment, Modal, Select, TextField, Typography, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback, useRef } from "react";
import Util from "../util/util";
import CurrencyTextField from "../controls/currencyTextField";
import ComponentContainer from "./componentContainer";
import api from "../util/api";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import LineIcon from "react-lineicons";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { hr } from "date-fns/locale";
import ExitToApp from "@mui/icons-material/ExitToApp";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import ArhivaPredmetForm from "./arhivaPredmetForm";
import globalContext from "./globalContext";
import { Grid } from "@material-ui/core";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import SudForm from "./sudForm";

const proBonoOptions = [{naziv:"NE", value:0}, {naziv:"DA", value:1}];
const aktivnostOptions = [{naziv:"Neaktivan", value:0}, {naziv:"Aktivan", value:1}];
const materijalniOblikOptions = [{naziv:"NE", value:0}, {naziv:"DA", value:1}];
const capOptions = [{naziv:"NE", value:0}, {naziv:"DA", value:1}];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%',
    minHeight: '50%',
    minWidth: '50%'
};

const styleManji = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%',
    minHeight: '0%',
    minWidth: '50%'
};

function PredmetForm(props) {
    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const setEnableScrolling = props.setEnableScrolling;
    const setFormOpened = props.setFormOpened;
    const firmaID = props.firmaID;
    const tipTvrtkeID = props.tipTvrtkeID;
    const formPredmet = props.formPredmet;
    const loadPredmeti = props.loadPredmeti;
    const filter = props.filter;
    const klijenti = props.klijenti;

    const setCjenikOpened = props.setCjenikOpened;
    const setCurrent = props.setCurrent;

    const [loading, setLoading] = useState(true);
    const [loadingVoditelj, setLoadingVoditelj] = useState(true);
    const [loadingKlijent, setLoadingKlijent] = useState(true);
    const [edited, setEdited] = useState(false);
    const [naziv, setNaziv] = useState(null);
    const [brojPredmeta, setBrojPredmeta] = useState(null);
    const [brojUnique, setBrojUnique] = useState(true);
    const [voditeljId, setVoditeljId] = useState(null);
    const [datumOtvaranja, setDatumOtvaranja] = useState(new Date());
    const [tipStanjaPredmetaId, setTipStanjaPredmetaId] = useState(null);
    const [jezikId, setJezikId] = useState(null);
    const [klijentId, setKlijentId] = useState(null);
    const [tipNaplateId, setTipNaplateId] = useState(null);
    const [opaske, setOpaske] = useState(null);
    const [proBono, setProBono] = useState({naziv:"NE", value:0});
    const [klijentovVoditeljPredmetaKontaktID, setKlijentovVoditeljPredmetaKontaktID] = useState(null);
    const [satnicaZaokruzivanje, setSatnicaZaokruzivanje] = useState(45);
    const [tipNaplatnostiId, setTipNaplatnostiId] = useState(null);
    const [tipOporezivostiId, setTipOporezivostiId] = useState(null);
    const [materijalniOblik, setMaterijalniOblik] = useState({naziv:"DA", value:1});
    const [brojRegistratora, setBrojRegistratora] = useState(0);
    const [aktivnost, setAktivnost] = useState({naziv:"Aktivan", value:1});
    const [sudskiBroj, setSudskiBroj] = useState(null);
    const [protustranka, setProtustranka] = useState(null);
    const [nadlezniSud, setNadlezniSud] = useState(null);
    const [sudac, setSudac] = useState(null);
    const [vrstaPredmetaId, setVrstaPredmetaId] = useState(null);
    const [dodatnaOznaka, setDodatnaOznaka] = useState(null);
    const [arhiveLabel, setArhiveLabel] = useState("");
    const [posudbeLabel, setPosudbeLabel] = useState("");
    const [prikaziProBono, setPrikaziProBono] = useState(false);
    const [voditelji, setVoditelji] = useState([]);
    const [voditelj, setVoditelj] = useState(null);
    const [klijent, setKlijent] = useState(null);
    const [kontakti, setKontakti] = useState([]);
    const [kontakt, setKontakt] = useState(null);
    const [jezici, setJezici] = useState([]);
    const [jezik, setJezik] = useState(null);
    const [firmaJezikId, setFirmaJezikId] = useState(null);
    const [firmaJezici, setFirmaJezici] = useState([]);
    const [tipStanjaPredmetaOptions, setTipStanjaPredmetaOptions] = useState([]);
    const [tipNaplateOptions, setTipNaplateOptions] = useState([]);
    const [tipNaplatnostiOptions, setTipNaplatnostiOptions] = useState([]);
    const [tipOporezivostiOptions, setTipOporezivostiOptions] = useState([]);
    const [tipVrstePredmetaOptions, setTipVrstePredmetaOptions] = useState([]);
    const [tipStanjaPredmeta, setTipStanjaPredmeta] = useState(null);
    const [tipNaplate, setTipNaplate] = useState(null);
    const [tipNaplatnosti, setTipNaplatnosti] = useState(null);
    const [tipOporezivosti, setTipOporezivosti] = useState(null);
    const [vrstaPredmeta, setVrstaPredmeta] = useState(null);
    const [satnicaZaokruzivanjeError, setSatnicaZaokruzivanjeError] = useState(null);
    const [arhivaAlert, setArhivaAlert] = useState(false);
    const [arhivaFormOpened, setArhivaFormOpened] = useState(false);
    const [arhivaSaved, setArhivaSaved] = useState(false);
    const [iznos, setIznos] = useState("0.00");
    const [tipPeriodiciteta, setTipPeriodiciteta] = useState(null);
    const [tipoviPeriodiciteta, setTipoviPeriodiciteta] = useState([]);
    const [firmaValute, setFirmaValute] = useState([])
    const [firmaValuta, setFirmaValuta] = useState(null);
    const [formValid, setFormValid] = useState(false);
    const [cap, setCap] = useState({naziv:"NE", value:0});
    const [provjeraPromjeneValute, setProvjeraPromjeneValute] = useState(false);
    const [zabraniPromjenuValute, setZabraniPromjenuValute] = useState(false);
    const [posudbaAlert, setPosudbaAlert] = useState(false);
    const [posudjenoKom, setPosudjenoKom] = useState(0);
    const [inicijalniTipStanjaPredmetaID, setInicijalniTipStanjaPredmetaID] = useState();
    const [cjenici, setCjenici] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [defaultBrojRegistratora, setDefaultBrojRegistratora] = useState(1);
    const [sudovi, setSudovi] = useState([]);
    const [dodajSud, setDodajSud] = useState(false);
    const [sudoviIDs, setSudoviIDs] = useState([]);

    const sudInputRef = useRef();

    useConstructor(() => {
        setEnableScrolling(false);
        if (formPredmet != null) {
            setPosudjenoKom(formPredmet.PosudjenoKom);
            setNaziv(formPredmet.Naziv);
            setBrojPredmeta(formPredmet.Broj);
            setBrojRegistratora(formPredmet.BrojRegistratora)
            setVoditeljId(formPredmet.Voditelj_KorisnikID);
            setDatumOtvaranja(formPredmet.DatumOtvaranja);
            setTipStanjaPredmetaId(formPredmet.TipStanjaPredmetaID);
            setInicijalniTipStanjaPredmetaID(formPredmet.TipStanjaPredmetaID);
            setFirmaJezikId(formPredmet.FirmaJezikID);
            setKlijentId(formPredmet.KlijentID);
            setTipNaplateId(formPredmet.TipNaplateID);
            setOpaske(formPredmet.Opaske);
            setProBono(proBonoOptions.at(formPredmet.ProBono ? 1 : 0));
            setKlijentovVoditeljPredmetaKontaktID(formPredmet.KlijentovVoditeljPredmetaKontaktID);
            setSatnicaZaokruzivanje(formPredmet.SatnicaZaokruzivanje);
            setTipNaplatnostiId(formPredmet.TipNaplatnostiID);
            setTipOporezivostiId(formPredmet.TipOporezivostiID);
            setMaterijalniOblik(materijalniOblikOptions.at(formPredmet.U_MaterijalnomObliku ? 1 : 0));
            setCap(capOptions.at(formPredmet.Cap ? 1 : 0));
            setAktivnost(aktivnostOptions.at(formPredmet.Aktivan ? 1 : 0));
            setSudskiBroj(formPredmet.SudskiBroj);
            setProtustranka(formPredmet.Protustranka);
            setNadlezniSud(formPredmet.NadlezniSud);
            setSudac(formPredmet.Sudac);
            setVrstaPredmetaId(formPredmet.VrstaPredmetaID);
            setDodatnaOznaka(formPredmet.DodatnaOznaka);
            setTipPeriodiciteta(formPredmet.TipPeriodiciteta);
            setIznos(formPredmet.Iznos ? Util.toStringDecimal(formPredmet.Iznos) : "0.00");
            setFirmaValuta(formPredmet.FirmaValuta);
            setSudoviIDs(formPredmet.SudoviIDs);
        } else {
            setLoadingVoditelj(false);
            setLoadingKlijent(false);
        }
        //loadTipStanjaPredmetaOptions();
        loadFormInfo();
    });

    function loadFormInfo() {
        setLoading(true);
        let parameters = { FirmaID : firmaID, KlijentID : formPredmet ? formPredmet.KlijentID : null, PredmetID : formPredmet ? formPredmet.PredmetID : null}
        api.post("predmeti/getforminfo/", parameters, loadFormInfoSuccess, loadFormInfoFail);
    }

    function loadFormInfoSuccess(data) {
        setVoditelji(data.Voditelji);
        setJezici(data.Jezici);
        setFirmaJezici(data.FirmaJezici);
        setTipStanjaPredmetaOptions(data.TipStanjaPredmetaOptions);
        setTipNaplatnostiOptions(data.TipNaplatnostiOptions);
        setTipNaplateOptions(data.TipNaplateOptions);
        setTipOporezivostiOptions(data.TipOporezivostiOptions);
        setTipVrstePredmetaOptions(data.TipVrstePredmetaOptions);
        setKontakti(data.Kontakti || []);
        setArhiveLabel(data.Arhive);
        setPosudbeLabel(data.Posudbe);
        setCjenici(data.Cjenici);
        //setPrikaziProBono(data.OdvjetnickaFirma);
        setPrikaziProBono(false); //na ovaj način je zaremano prikazivanje probono-a
        setTipoviPeriodiciteta(data.TipoviPeriodiciteta);
        setFirmaValute(data.FirmaValute);
        setDefaultBrojRegistratora(data.DefaultBrojRegistratora);
        setSudovi(data.Sudovi);
        if (!formPredmet) {
            setTipPeriodiciteta(data.TipoviPeriodiciteta.find(tp => tp.BrojMjeseci == 0));
            if (props.klijent) {
                setKlijentId(props.klijent.KlijentID);
                setTipOporezivostiId(props.klijent.TipOporezivostiID);
                setVoditeljId(props.klijent.Voditelj_KorisnikID);
                setJezikId(props.klijent.FirmaJezik.JezikID);
                setKlijentovVoditeljPredmetaKontaktID(null);
            } else {
                //dodavanje predmeta, ali nije odabran klijent
                setTipOporezivostiId(data.DefaultTipOporezivosti.TipOporezivostiID);
                setJezikId(1);
            }
            setTipStanjaPredmetaId(data.TipStanjaPredmetaOptions.at(0).TipStanjaPredmetaID);
            setTipNaplatnostiId(data.TipNaplatnostiOptions.at(0).TipNaplatnostiID);
            setVrstaPredmetaId(data.DefaultTipVrstePredmetaID || data.TipVrstePredmetaOptions.at(0).TipVrstePredmetaID);
            setTipNaplateId(data.TipNaplateOptions.at(0).TipNaplateID);
            setBrojPredmeta(data.NextBroj);
            setBrojRegistratora(data.DefaultBrojRegistratora);
            setSatnicaZaokruzivanje(data.DefaultSatnicaZaokruzivanje);
            setTipPeriodiciteta(data.TipoviPeriodiciteta.find(tp => tp.BrojMjeseci == 0))
            setFirmaValuta(data.DefaultFirmaValuta);
        } else if (formPredmet.Cap) {
            setProvjeraPromjeneValute(true);
            api.get("predmeti/provjeripromjenuvalute/" + formPredmet.PredmetID, (data) => {
                if (data) {
                    setFirmaValuta(data);
                    setZabraniPromjenuValute(true);
                }
                setProvjeraPromjeneValute(false);
            }, null)
        }
    }

    function loadFormInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => loadKontakti(), [klijentId])

    useEffect(() => {savePredmetAfterArhiva(arhivaSaved)}, [arhivaSaved])

    function savePredmetAfterArhiva(arhivaSaved){
        //ovo je signal da treba sejvati predmet i zatvoriti formu (ako je arhivaSaved = true)
        if(arhivaSaved){
            setArhivaSaved(false);    
            //alert('jeeee');
            save();
        }
    }

    function loadKontakti() {
        if (klijentId && !formPredmet) {
            setLoading(true);
            api.get("kli-vod-pred-kontakt-by-klijent/" + klijentId, loadKontaktiSuccess, loadKontaktiFail);
        }
    }

    function loadKontaktiSuccess(data) {
        setKontakti(data);
        setLoading(false);
    }

    function loadKontaktiFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleNazivChange(e) {
        setEdited(true);
        setNaziv(e.target.value);
    }

    function handleBrojPredmetaChange(e) {
        setEdited(true);
        setBrojPredmeta(e.target.value);
    }

    function handleBrojRegistratoraChange(e) {
        setEdited(true);
        setBrojRegistratora(e.target.value);
    }

    useEffect(() => findVoditelj(), [voditelji, voditeljId]);

    function findVoditelj() {
        if (voditeljId == null || voditelji == null) {
            setVoditelj(null);
            setLoadingVoditelj(false);
        } else {
            voditelji.forEach(v => {
                if (v.KorisnikID == voditeljId) {
                    setVoditelj(v);
                    setLoadingVoditelj(false);
                }
            });
        }
    }

    function loadJezikId() {
        if (firmaJezikId) {
            api.get("firmajezik/id/" + firmaJezikId, loadJezikIdSuccess, loadJezikIdFail);
        } else {
            loadJezikIdSuccess();
        }

    }

    function loadJezikIdSuccess(data) {
        if (data) {
            setJezikId(data.JezikID);
        }
    }

    function loadJezikIdFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
        setEnableScrolling(true);
    }

    useEffect(() => loadJezikId(), [firmaJezikId]);
    useEffect(() => findJezik(), [jezici, jezikId]);

    function findJezik() {
        if (jezikId == null || jezici == null) {
            setJezik(null);
        } else {
            jezici.forEach(j => {
                if (j.JezikID == jezikId) {
                    setJezik(j);
                }
            });
        }
    }

    function handleJezikChange(e,v) {
        if (v) {
            setEdited(true);
            setJezikId(v.JezikID);
        }
    }  

    useEffect(() => changeFirmaJezik(), [jezikId, firmaJezici]);

    function changeFirmaJezik() {
        if (jezikId && firmaJezici) {
            firmaJezici.forEach(fj => {
                if (fj.JezikID == jezikId) {
                    setFirmaJezikId(fj.FirmaJezikID);
                }
            })
        }
    }

    const handleVoditeljChange = (e,v) => {
        if (v) {
            setEdited(true);
            setVoditeljId(v.KorisnikID);
        }
    };

    const handleDatumOtvaranjaChange = v => {
        if (v) {
            setEdited(true);
            setDatumOtvaranja(v);
        }
    }

    const handleTipStanjaPredmetaChange = (e,v) => {
        if(v){
            setTipStanjaPredmetaId(v.TipStanjaPredmetaID);
            //ako se radi o promjeni predmeta, te se predmet želi zatvoriti (bilo realiziran ili nerealiziran), a bio je otvoren i forma je validna  
            if (v.TipStanjaPredmetaID != 1 && formPredmet && formPredmet.TipStanjaPredmetaID == 1 && formValid) {
                checkIfReaktiviranaArhivaExists();
            } else {
                setEdited(true);
            }        
        }
    }

    function checkIfReaktiviranaArhivaExists() {
        api.get("predmet/checkifarhiaforreaktiviranpredmetexists/" + formPredmet.PredmetID, checkIfReaktiviranaArhivaExistsSuccess, checkIfReaktiviranaArhivaExistsFail);
    }

    function checkIfReaktiviranaArhivaExistsSuccess(data) {
        if (!data) {
            setArhivaAlert(true);
        }
        setLoading(false);
    }

    function checkIfReaktiviranaArhivaExistsFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => findKlijent(), [klijenti, klijentId]);

    function findKlijent() {
        if (formPredmet) {
            api.get("klijent/id/" + formPredmet.KlijentID, findKlijentSuccess, findKlijentFail);
        } else if (klijentId == null || klijenti == null) {
            setKlijent(null);
            setLoadingKlijent(false);
        } else {
            klijenti.forEach(k => {
                if (k.KlijentID == klijentId) {
                    setKlijent(k);
                    setLoadingKlijent(false);
                }
            });
        }
    }

    function findKlijentSuccess(data) {
        setKlijent(data);
        setLoadingKlijent(false);
    }

    function findKlijentFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoadingKlijent(false);
    }

    const handleKlijentChange = (e,v) => {
        if (v) {
            setEdited(true);
            setKlijentId(v.KlijentID);
            setTipOporezivostiId(v.TipOporezivostiID);
            setVoditeljId(v.Voditelj_KorisnikID);
            setJezikId(v.FirmaJezik.JezikID);
            setKlijentovVoditeljPredmetaKontaktID(null);
        }
    };

    const handleTipNaplateChange = (e,v) => {
        if (v) {
            setEdited(true);
            setTipNaplateId(v.TipNaplateID);
        }
    }

    const handleOpaskeChange = e => {
        setEdited(true);
        setOpaske(e.target.value);
    }

    const handleProBonoChange = (e,v) => {
        if (v) {
            setEdited(true);
            setProBono(v);
        }
    }

    useEffect(() => findKontakt(), [kontakti, klijentovVoditeljPredmetaKontaktID]);

    function findKontakt() {
        if (klijentovVoditeljPredmetaKontaktID == null || kontakti == null) {
            setKontakt(null);
        } else {
            kontakti.forEach(kontakt => {
                if (kontakt.KlijentovVoditeljPredmetaKontaktID == klijentovVoditeljPredmetaKontaktID) {
                    setKontakt(kontakt);
                }
            });
        }
    }

    const handleKontaktChange = (e,v) => {
        if (v) {
            setKlijentovVoditeljPredmetaKontaktID(v.KlijentovVoditeljPredmetaKontaktID);
        }
    }

    const handleSatnicaZaokruzivanjeChange = e => {
        setEdited(true);
        setSatnicaZaokruzivanje(e.target.value);
    }

    const validateSatnicaZaokruzivanje = () => {
        setEdited(true);
        if (!satnicaZaokruzivanje || satnicaZaokruzivanje < 1 || satnicaZaokruzivanje > 60) {
            setSatnicaZaokruzivanjeError(true);
        } else {
            setSatnicaZaokruzivanjeError(false);
        }
    }

    useEffect(() => validateSatnicaZaokruzivanje(), [satnicaZaokruzivanje]);

    const handleTipNaplatnostiChange = (e,v) => {
        if (v) {
            setEdited(true);
            setTipNaplatnostiId(v.TipNaplatnostiID);
        }
    }

    const handleTipOporezivostiChange = (e,v) => {
        if (v) {
            setEdited(true);
            setTipOporezivostiId(v.TipOporezivostiID);
        }
    }

    const handleMaterijalniOblikChange = (e,v) => {
        if(v){
            setEdited(true);
            setMaterijalniOblik(v);    
        }
    }

    const handleCapChange = (e,v) => {
        if(v){
            setEdited(true);
            setCap(v);    
        }
    }

    const handleAktivnostChange = (e,v) => {
        if(v){
            setEdited(true);
            setAktivnost(v);    
        }
    }

    const handleSudskiBrojChange = e => {
        setSudskiBroj(e.target.value);
    }

    const handleProtustrankaChange = e => {
        setProtustranka(e.target.value);
    }

    const handleNadlezniSudChange = e => {
        setNadlezniSud(e.target.value);
    }

    const handleSudacChange = e => {
        setSudac(e.target.value);
    }

    const handleTipVrstePredmetaChange = (e,v) => {
        if (v) {
            setVrstaPredmetaId(v.TipVrstePredmetaID);
        }
    }

    const handleDodatnaOznakaChange = e => {
        setEdited(true);
        setDodatnaOznaka(e.target.value);
    }
    
    function saveCheck(){
        if(formPredmet && posudjenoKom > 0 && tipStanjaPredmetaId == 1 && inicijalniTipStanjaPredmetaID != 1){
            setPosudbaAlert(true);
        }
        else{
            save();
        }
    }

    function save() {
        setLoading(true);
        let newPredmet = {
            Naziv : naziv,
            Broj : brojPredmeta,
            DatumOtvaranja : datumOtvaranja,
            Voditelj_KorisnikID : voditeljId,
            TipStanjaPredmetaID : tipStanjaPredmetaId,
            FirmaJezikID : firmaJezikId,
            KlijentID : klijentId,
            TipNaplateId : tipNaplateId,
            Opaske : (opaske == null ? "" : opaske),
            ProBono : proBono.value,
            KlijentovVoditeljPredmetaKontaktID : klijentovVoditeljPredmetaKontaktID,
            SatnicaZaokruzivanje : satnicaZaokruzivanje,
            TipNaplatnostiID : tipNaplatnostiId,
            TipOporezivostiID : tipOporezivostiId,
            U_MaterijalnomObliku : materijalniOblik.value,
            Aktivan : aktivnost.value,
            SudskiBroj : sudskiBroj,
            Protustranka : protustranka,
            NadlezniSud : nadlezniSud,
            Sudac : sudac,
            BrojRegistratora : brojRegistratora,
            VrstaPredmetaID : vrstaPredmetaId,
            DodatnaOznaka : (dodatnaOznaka == null ? "" : dodatnaOznaka),
            FirmaValutaID : tipNaplateId == 3 || (tipNaplateId == 1 && cap.value == 1) ? firmaValuta.FirmaValutaID : null,
            TipPeriodicitetaID : tipNaplateId == 3 ? tipPeriodiciteta.TipPeriodicitetaID : null,
            Iznos : tipNaplateId == 3 || (tipNaplateId == 1 && cap.value == 1) ? iznos : null,
            Cap : cap.value,
            SudoviIDs : sudoviIDs
        }
        if (formPredmet) {
            newPredmet = {...newPredmet, PredmetID : formPredmet.PredmetID};
            api.post("subject/update", newPredmet, saveSuccess, saveFail);
        } else {
            //newPredmet = {...newPredmet, PredmetID : 0};
            api.post("subject/new", newPredmet, saveSuccess, saveFail);
        }
    }

    function saveSuccess(data) {
        setLoading(false);      
        setEnableScrolling(true);
        setEdited(false);
        //loadPredmeti();
        setFormOpened(false);
        if(!formPredmet && tipNaplateId == 1){
            //ovo je bilo dodavanje novog predmeta, pa idemo odmah korisniku dati mogućnost da upiše cjenik, ako se radi o satnicama
            setCurrent(data);
            props.setPredmetCreatedAlert(true);
            props.setPredmetCreated(true);
            setCjenikOpened(true);
        } else {
            loadPredmeti();
        }
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
        setEnableScrolling(true);
    }

    function checkScrollingEnabled() {
        if (!loading && !loadingVoditelj && !loadingKlijent) {
            setEnableScrolling(true);
        }
    }

    useEffect(() => checkScrollingEnabled(), [loading, loadingVoditelj, loadingKlijent]);
    useEffect(() => checkLoading(), [voditelji, klijenti, jezici, firmaJezici, tipNaplateOptions, tipStanjaPredmetaOptions, tipNaplatnostiOptions, tipOporezivostiOptions, tipVrstePredmetaOptions, kontakti])

    function checkLoading() {
        ;
        if (voditelji && (klijenti || formPredmet) && jezici && firmaJezici && tipNaplateOptions && tipStanjaPredmetaOptions && tipNaplatnostiOptions && tipOporezivostiOptions && tipVrstePredmetaOptions && (kontakti || !klijentId)) {
            setLoading(false);
        }
    } 

    useEffect(() => findTipStanja(), [tipStanjaPredmetaOptions, tipStanjaPredmetaId]);
    
    function findTipStanja() {
        if (tipStanjaPredmetaOptions && tipStanjaPredmetaId) {
            tipStanjaPredmetaOptions.forEach(stanje => {
                if (stanje.TipStanjaPredmetaID == tipStanjaPredmetaId) {
                    setTipStanjaPredmeta(stanje);
                }
            })
        }
    }
    
    useEffect(() => findTipNaplate(), [tipNaplateOptions, tipNaplateId]);

    function findTipNaplate() {
        if (tipNaplateOptions && tipNaplateId) {
            tipNaplateOptions.forEach(naplata => {
                if (naplata.TipNaplateID == tipNaplateId) {
                    setTipNaplate(naplata);
                }
            })
        }
    }

    useEffect(() => findTipNaplatnosti(), [tipNaplatnostiOptions, tipNaplatnostiId]);

    function findTipNaplatnosti() {
        if (tipNaplatnostiOptions && tipNaplatnostiId) {
            tipNaplatnostiOptions.forEach(naplatnost => {
                if (naplatnost.TipNaplatnostiID == tipNaplatnostiId) {
                    setTipNaplatnosti(naplatnost);
                }
            })
        }
    }

    useEffect(() => findTipOporezivosti(), [tipOporezivostiOptions, tipOporezivostiId]);

    function findTipOporezivosti() {
        if (tipOporezivostiOptions && tipOporezivostiId) {
            tipOporezivostiOptions.forEach(oporezivost => {
                if (oporezivost.TipOporezivostiID == tipOporezivostiId) {
                    setTipOporezivosti(oporezivost);
                }
            })
        }
    }

    useEffect(() => findTipVrstePredmeta(), [tipVrstePredmetaOptions, vrstaPredmetaId]);

    function findTipVrstePredmeta() {
        if (tipVrstePredmetaOptions && vrstaPredmetaId) {
            tipVrstePredmetaOptions.forEach(vrsta => {
                if (vrsta.TipVrstePredmetaID == vrstaPredmetaId) {
                    setVrstaPredmeta(vrsta);
                }
            })
        }
    }

    function checkIfBrojUnique() {
        let resource = {
            FirmaID : global.user.firmaID,
            CurrentID : formPredmet ? formPredmet.PredmetID : -1,
            Broj : brojPredmeta
        };
        api.post("predmeti/checkifbrojunique", resource, data => setBrojUnique(data), (data) => {
            setBrojUnique(false);
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        });
    } 

    function handleIznosFocusOut() {
        // if (!isNaN(parseFloat(iznos)) && parseFloat(iznos) > 0) {
        //     setIznos(parseFloat(iznos));
        // } else {
        //     setIznos(0);
        // }

        if (iznos == "" || iznos.charAt(0) == '-') {
            setIznos("0.00");
        } else if (!isNaN(parseFloat(iznos))) {
            let value = iznos;
            let i = 0;
            while (iznos.length > i + 1 && iznos.charAt(i) == '0' && iznos.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            ;
            value = Util.toStringDecimal(value);
            setIznos(value);
        }

    }

    const handleKeyPressShorcuts = useCallback((event) => {
        if (event.altKey) {
            let charCode = String.fromCharCode(event.which).toLowerCase();
            if (charCode == 'p') {
                event.preventDefault();
                if (!loading && formValid && global.roles.includes("19")) {
                    save();
                }
            }
            if (charCode == 'o') {
                event.preventDefault();
                if (!loading) {
                    setFormOpened(false);
                }
            }
        }
    }, [
        loading, 
        formValid, 
        naziv, 
        brojPredmeta, 
        datumOtvaranja, 
        voditeljId, 
        tipStanjaPredmetaId, 
        firmaJezikId, 
        tipNaplateId, 
        opaske, 
        proBono, 
        klijentovVoditeljPredmetaKontaktID, 
        satnicaZaokruzivanje, 
        tipNaplatnostiId, 
        tipOporezivostiId, 
        materijalniOblik, 
        aktivnost, 
        sudskiBroj, 
        protustranka, 
        nadlezniSud, 
        sudac, 
        brojRegistratora, 
        vrstaPredmetaId, 
        dodatnaOznaka, 
        cap, 
        tipPeriodiciteta, 
        iznos, 
        firmaValuta,
        klijentId
    ]);

    const handleEsc = useCallback((event) => {
        if(event.keyCode == 27){
            event.preventDefault();
            if (!loading) {
                setFormOpened(false);
            }            
        }
    }, [loading]);
    
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyPressShorcuts);
        document.addEventListener('keyup', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleKeyPressShorcuts);
            document.removeEventListener('keyup', handleEsc);
        };
    }, [handleKeyPressShorcuts]);

    useEffect(() => checkIfFormValid(), [naziv, brojPredmeta, voditelj, datumOtvaranja, klijent, brojRegistratora, satnicaZaokruzivanjeError, tipNaplateId, cap, iznos, firmaValuta]);

    function checkIfFormValid() {
        //ovo sam eksplicitno morao dovesti do booleana, jer u protivon varijabla valid nije nužno boolena s vrijednošću 'true', što se provjerava na setu state-a setFormValid
        let valid = !satnicaZaokruzivanjeError && tipStanjaPredmetaId != null && naziv != '' && naziv != null &&  brojPredmeta != '' && voditelj != null && datumOtvaranja != null && klijent != null && brojRegistratora != null && brojRegistratora !== '' && !isNaN(brojRegistratora) && parseInt(brojRegistratora) >= 0 && ((((tipNaplateId == 1 && cap.value == 1) || tipNaplateId == 3) && iznos != null && !isNaN(iznos) && iznos > 0 && firmaValuta != null && Util.valutaKonzistentna(formPredmet, cjenici, firmaValuta)) || tipNaplateId == 2 || (tipNaplateId == 1 && cap.value == 0));
        setFormValid(valid == true);
    }

    function poruka(){

        //alert('Jeeee');
    }

    useEffect(() => {
        if (!formPredmet) {
            setBrojRegistratora(materijalniOblik && materijalniOblik.value == 1 ? defaultBrojRegistratora : 0);
        }
    }, [materijalniOblik]);

    function dodajSudClick() {
        setDodajSud(true);
    }

    function dodajSudSuccess(data) {
        if (data) {
            let newSudovi = JSON.parse(JSON.stringify(sudovi));
            newSudovi.push(data);
            setSudovi(newSudovi);
            
            let newSudoviIDs = JSON.parse(JSON.stringify(sudoviIDs));
            newSudoviIDs.push(data.SudID);
            setSudoviIDs(newSudoviIDs);
        }
    }

    function onSudInputChange(e, v, r) {
        if (r == "input") {
            sudInputRef.current = v;
        }
    }

    return (
        <ComponentContainer loading={loading || loadingKlijent || loadingVoditelj || provjeraPromjeneValute}>
            <Typography variant="h5" gutterBottom component="div">
                {formPredmet ? <div><EditIcon fontSize="small" /> {formPredmet.Naziv}</div> : <div><AddCircleOutlineIcon fontSize="small" /> Novi predmet</div>}
            </Typography>

            <Grid container className="padding-grid">
                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <TextField label="Naziv" value={naziv || ""} inputProps={{ maxLength: 200 }} onChange={handleNazivChange} error={!naziv} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField label="Broj predmeta" value={brojPredmeta || ""} onChange={handleBrojPredmetaChange} onBlur={checkIfBrojUnique} error={!brojPredmeta || !brojUnique} helperText={brojPredmeta && !brojUnique ? "Broj predmeta mora biti jedinstven." : ""} required size="small"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth >
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            id="voditelj-select"
                            options={voditelji ? voditelji.filter(v => v.TipStatusaKorisnikaID == 1) : []}
                            autoHighlight
                            getOptionLabel={(option) => option.Ime + " " + option.Prezime}
                            value={voditelj || null}
                            onChange={handleVoditeljChange}
                            renderInput={(params) => <TextField {...params} label="Voditelj" error={!voditeljId} required size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4} style={{marginTop:"2px"}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} fullWidth>
                        <DatePicker
                            label="Datum otvaranja"
                            value={datumOtvaranja || null}
                            inputFormat="dd.MM.yyyy"
                            onChange={handleDatumOtvaranjaChange}
                            renderInput={(params) => <TextField style={{width:"100%"}} {...params} required error={!datumOtvaranja} size="small"/>}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth >
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            id="tip-stanja-select"
                            autoHighlight
                            options={tipStanjaPredmetaOptions}
                            getOptionLabel={(option) => option.Naziv}
                            value={tipStanjaPredmeta || null}
                            onChange={handleTipStanjaPredmetaChange}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={"tipstanjapredmeta" + option.TipStanjaPredmetaID} style={{color: option.TipStanjaPredmetaID != 1 ? "red" : "black"}}>
                                        {option.Naziv}
                                    </li>
                                );
                            }}                               
                            renderInput={(params) => <TextField {...params} label="Tip stanja predmeta" sx={{input:{color: tipStanjaPredmeta && tipStanjaPredmeta.TipStanjaPredmetaID != 1 ? "red" : "black"}}} error={!tipStanjaPredmetaId} required size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth >
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            id="jezik-select"
                            options={jezici}
                            autoHighlight
                            getOptionLabel={(option) => option.Naziv}
                            value={jezik || null}
                            onChange={handleJezikChange}
                            renderInput={(params) => <TextField {...params} label="Jezik" error={!jezikId} required size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={tipNaplateId != 1 ? 6 : 4}>
                    <FormControl fullWidth >
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            id="klijent-select"
                            options={formPredmet ? [] : klijenti}
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            value={klijent || null}
                            autoHighlight
                            disabled={formPredmet != null}
                            onChange={handleKlijentChange}
                            renderOption={(props, option) => {
                                return (
                                  <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 ? "black" : "red"}}>
                                    {option.Broj + " - " + option.Naziv}
                                  </li>
                                );
                            }}
                            filterOptions={(options, state) => options.filter(option => (option.Broj + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            renderInput={(params) => <TextField {...params} sx={{input:{color: klijent && klijent.TipStatusaKlijentaID != 1 ? "red" : "black"}}} label="Klijent" error={!klijentId} required size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={prikaziProBono ? 3 : 6}>
                    <FormControl fullWidth >
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            id="tip-naplate-select"
                            options={tipNaplateOptions}
                            getOptionLabel={(option) => option.Naziv}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={"tipnaplate" + option.TipNaplateID} style={{color: option.TipNaplateID == 1 ? "black" : (option.TipNaplateID == 2 ? "magenta" : "green")}}>
                                        {option.Naziv}
                                    </li>
                                );
                            }}                                                  
                            renderInput={(params) => (
                                <TextField 
                                    {...params} 
                                    label="Tip naplate" 
                                    sx={{input:{color: tipNaplate && tipNaplate.TipNaplateID == 1 ? "black" : (tipNaplate && tipNaplate.TipNaplateID == 2 ? "magenta" : "green")}}} 
                                    error={!tipNaplateId} 
                                    required size="small"/>)}
                            value={tipNaplate || null}
                            autoHighlight
                            onChange={handleTipNaplateChange}
                        />
                    </FormControl>
                </Grid>
                {prikaziProBono ? (
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <Autocomplete style={{width:"100%"}}
                                disablePortal
                                disableClearable
                                options={proBonoOptions}
                                getOptionLabel={(option) => option.naziv}
                                value={proBono || null}
                                autoHighlight
                                onChange={handleProBonoChange}
                                renderInput={(params) => <TextField {...params} label="Pro bono" error={proBono == null} required size="small"/>}>
                            </Autocomplete>
                        </FormControl>
                    </Grid>
                ) : null}
                {tipNaplateId == 1 ? (
                    <Grid item xs={2}>
                    <FormControl fullWidth>
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            autoHighlight
                            options={capOptions}
                            getOptionLabel={(option) => option.naziv}
                            value={cap || null}
                            onChange={handleCapChange}
                            renderInput={(params) => <TextField {...params} label="Cap/Max:" error={cap == null} required size="small"/>}>
                        </Autocomplete>
                    </FormControl>
                    </Grid>
                ) : null}
                {tipNaplateId == 3 || (tipNaplateId == 1 && cap.value == 1) ? (
                    <Grid item xs={tipNaplateId == 3 ? 4 : 6}>
                        <FormControl fullWidth>
                            <TextField type="number" inputProps={{ min: 0.00 }} label="Iznos" value={iznos || ""} onChange={e => setIznos(e.target.value)} onBlur={handleIznosFocusOut} error={iznos && (isNaN(parseFloat(iznos)) || parseFloat(iznos) <= 0)} size="small"></TextField>
                        </FormControl>
                    </Grid>
                ) : null}
                {tipNaplateId == 3 || (tipNaplateId == 1 && cap.value == 1) ? (
                    <Grid item xs={tipNaplateId == 3 ? 4 : 6}>
                        <FormControl fullWidth>  
                            <Autocomplete style={{width:"100%"}}
                                disablePortal
                                disableClearable
                                options={firmaValute}
                                getOptionLabel={(option) => option.FirmaValutaNaziv}
                                value={firmaValuta || null}
                                autoHighlight
                                disabled={zabraniPromjenuValute}
                                onChange={(e,v) => setFirmaValuta(v)}
                                renderInput={(params) => <TextField {...params} label="Valuta" error={((tipNaplateId == 1 && cap) || tipNaplateId == 3) && (!firmaValuta || !Util.valutaKonzistentna(formPredmet, cjenici, firmaValuta))} size="small"/>}>
                            </Autocomplete>
                        </FormControl>
                    </Grid>
                ) : null}
                {tipNaplateId == 3 ? (
                    <Grid item xs={4}>
                        <FormControl fullWidth>  
                            <Autocomplete style={{width:"100%"}}
                                disablePortal
                                options={tipoviPeriodiciteta}
                                getOptionLabel={(option) => option.Naziv}
                                value={tipPeriodiciteta || null}
                                autoHighlight
                                disableClearable
                                onChange={(e,v) => setTipPeriodiciteta(v)}
                                renderInput={(params) => <TextField {...params} label="Tip periodiciteta" size="small" error={!tipPeriodiciteta}/>}>
                            </Autocomplete>
                        </FormControl>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Opaske" value={opaske || ""} inputProps={{ maxLength: 500 }} multiline rows={4} onChange={handleOpaskeChange} size="small"></TextField>
                    </FormControl>
                </Grid>
                {tipNaplateId == 1 ? (
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField type="number" inputProps={{ min: 1, max: 60 }} InputProps={{endAdornment:(<InputAdornment position="end">min</InputAdornment>)}} label="Satnica zaokruživanje" value={satnicaZaokruzivanje || ""} onChange={handleSatnicaZaokruzivanjeChange} error={satnicaZaokruzivanjeError} required size="small"></TextField>
                        </FormControl>
                    </Grid>
                ) : null}
                <Grid item xs={tipNaplateId == 1 ? 4 : 6}>
                    <FormControl fullWidth >
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            autoHighlight
                            id="tip-naplatnosti-select"
                            options={tipNaplatnostiOptions}
                            getOptionLabel={(option) => option.Naziv}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} >
                                        <Brightness1Icon sx={{ color: "rgb(" + option.TipNaplatnostiBoja + ")"}}></Brightness1Icon>
                                        <span>&nbsp;&nbsp;{option.Naziv}</span>
                                    </li>
                                );
                            }}
                            value={tipNaplatnosti || null}
                            onChange={handleTipNaplatnostiChange}
                            renderInput={(params) => <TextField {...params} label="Tip naplatnosti" InputProps={{
                                        ...params.InputProps,
                                        startAdornment: tipNaplatnosti ? <InputAdornment position="end"><Brightness1Icon sx={{ color : "rgb(" + tipNaplatnosti.TipNaplatnostiBoja + ")"}}></Brightness1Icon></InputAdornment> : null
                                    }} error={!tipNaplatnostiId} required size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={tipNaplateId == 1 ? 4 : 6}>
                    <FormControl fullWidth >
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            autoHighlight
                            id="tip-oporezivosti-select"
                            options={tipOporezivostiOptions}
                            getOptionLabel={(option) => option.TipPorezaNaziv + " (" + option.Stopa * 100 + " %)"}
                            value={tipOporezivosti || null}
                            onChange={handleTipOporezivostiChange}
                            renderInput={(params) => <TextField {...params} label="Tip oporezivosti" error={!tipOporezivostiId} required size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth >
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            id="kontakt-select"
                            options={kontakti}
                            autoHighlight
                            getOptionLabel={(option) => option.Naziv}
                            value={kontakt || null}
                            onChange={handleKontaktChange}
                            renderInput={(params) => <TextField {...params} label="Kontakt" size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <Autocomplete style={{width:"100%"}}
                            disablePortal
                            disableClearable
                            autoHighlight
                            options={materijalniOblikOptions}
                            getOptionLabel={(option) => option.naziv}
                            value={materijalniOblik || null}
                            onChange={handleMaterijalniOblikChange}
                            renderInput={(params) => <TextField {...params} label="U materijalnom obliku:" error={materijalniOblik == null} required size="small"/>}>
                        </Autocomplete>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField type="number" InputProps={{ inputProps: { min: 0 } }} label="Količina" value={brojRegistratora != null ? brojRegistratora : ""} onChange={handleBrojRegistratoraChange} error={brojRegistratora == null || isNaN(brojRegistratora) || brojRegistratora < 0 } helperText={""} required size="small"></TextField>
                    </FormControl>
                </Grid>                
                <Grid item xs={4}>
                    <Tooltip title="Može služiti kao filter u izvještajima evidencija i rekapitulacija." arrow>
                        <FormControl fullWidth>
                            <Autocomplete style={{width:"100%"}}
                                disablePortal
                                disableClearable
                                autoHighlight
                                options={aktivnostOptions}
                                getOptionLabel={(option) => option.naziv}
                                value={aktivnost || null}
                                onChange={handleAktivnostChange}
                                renderInput={(params) => <TextField {...params} label="Aktivnost" error={aktivnost == null} required size="small"/>}>
                            </Autocomplete>
                        </FormControl>
                    </Tooltip>
                </Grid>
                {tipTvrtkeID == 2 ? (
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField label="Sudski broj" value={sudskiBroj || ""} inputProps={{ maxLength: 150 }} onChange={handleSudskiBrojChange} size="small"></TextField>
                        </FormControl>
                    </Grid>
                ) : null}
                {tipTvrtkeID == 2 ? (
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField label="Protustranka" value={protustranka || ""} inputProps={{ maxLength: 200 }} onChange={handleProtustrankaChange} size="small"></TextField>
                        </FormControl>
                    </Grid>
                ) : null}
                {tipTvrtkeID == 2 ? (
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField label="Nadležni sud" value={nadlezniSud || ""} inputProps={{ maxLength: 200 }} onChange={handleNadlezniSudChange} size="small"></TextField>
                        </FormControl>
                    </Grid>
                ) : null}
                {/* {tipTvrtkeID == 2 ? (
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Autocomplete style={{width:"100%"}}
                                autoHighlight
                                options={sudovi}
                                getOptionLabel={(option) => option.Naziv}
                                value={sud}
                                onChange={(e,v) => setSud(v)}
                                renderInput={(params) => <TextField {...params} label="Nadležni sud" size="small"/>}
                            />
                        </FormControl>
                    </Grid>
                ) : null} */}
                {/* {tipTvrtkeID == 2 ? (
                    <Grid item xs={11}>
                        <FormControl fullWidth>
                            <Autocomplete style={{width:"100%"}}
                                autoHighlight
                                options={sudovi}
                                multiple
                                getOptionLabel={(option) => option.Naziv}
                                value={sudovi && sudoviIDs ? sudovi.filter(s => sudoviIDs.includes(s.SudID)) : []}
                                onChange={(e,v) => setSudoviIDs(v.map(s => s.SudID))}
                                onInputChange={onSudInputChange}
                                renderInput={(params) => <TextField {...params} label="Nadležni sud(ovi)" size="small"/>}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                {tipTvrtkeID == 2 ? (
                    <Grid item xs={1}>
                        <Tooltip title="Dodaj sud" enterDelay={200} enterNextDelay={300}>
                            <IconButton style={{width:"100%"}} onClick={dodajSudClick}><DomainAddIcon></DomainAddIcon></IconButton>
                        </Tooltip>
                    </Grid>
                ) : null} */}
                {tipTvrtkeID == 2 ? (
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField label="Sudac" value={sudac || ""} inputProps={{ maxLength: 200 }} onChange={handleSudacChange} size="small"></TextField>
                        </FormControl>
                    </Grid>
                ) : null}
                {tipTvrtkeID == 2 ? (
                    <Grid item xs={6}>
                        <FormControl fullWidth >
                            <Autocomplete style={{width:"100%"}}
                                disablePortal
                                autoHighlight
                                id="tip-vrste-predmeta-select"
                                options={tipVrstePredmetaOptions}
                                getOptionLabel={(option) => option.Naziv}
                                value={vrstaPredmeta || null}
                                onChange={handleTipVrstePredmetaChange}
                                renderInput={(params) => <TextField {...params} label="Vrsta predmeta" size="small"/>}
                            />
                        </FormControl>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField label="Dodatna oznaka" value={dodatnaOznaka || ""} inputProps={{ maxLength: 150 }} onChange={handleDodatnaOznakaChange} size="small"></TextField>
                    </FormControl>
                </Grid>
                {arhiveLabel.length > 0 ? (
                    <Grid item xs={12}>
                        <Typography>{arhiveLabel}</Typography>
                    </Grid>
                ) : null}
                {posudbeLabel.length > 0 ? (
                    <Grid item xs={12}>
                        <Typography style={{marginTop:'-5px'}}>{posudbeLabel}</Typography>
                    </Grid>
                ) : null}                
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                        <Button onClick={saveCheck} variant="contained" style={{marginRight:"10px"}} color="success" disabled={!edited || !global.roles.includes("19") || loading || !formValid}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <SaveIcon style={{marginRight:"10px"}} />} Sp&#818;remi</Button>
                        <Button variant="outlined" color="error" onClick={() => {setFormOpened(false);}} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>O&#818;dustani</Button>
                    </Box>
                </Grid>
            </Grid>

            {posudbaAlert ? <AlertDialog title={"Potrebna potvrda"} message={"Izmjena tipa stanja predmeta na 'Otvoren' podrazumijeva brisanje postojećih arhiva, a postoje posudbe jednog ili više dijelova arhiva po ovom predmetu. Ukoliko nastavite, posudbe će biti automatski administrativno vraćene. Da li želite nastaviti?"} cancelLabel={"Odustani"} confirmLabel={"Nastavi"} cancelAction={() => setPosudbaAlert(false)} confirmAction={save}></AlertDialog> : null}

            <Modal className="modal" open={arhivaAlert}>
                <Box sx={styleManji} overflow="auto">
                    <Typography variant="subtitle1" gutterBottom component="div">
                        Želite li promijeniti stanje predmeta u "Realiziran" i stvoriti arhivu za taj predmet?
                    </Typography>
                    <Box style={{width:"100%", display:"flex", justifyContent:"flex-end", marginTop:"20px"}}>
                        <Button variant="outlined" style={{marginRight:"10px"}} onClick={() => {
                                setEdited(true);
                                //setTipStanjaPredmetaId(2); //zašto ovo?
                                setArhivaAlert(false);
                            }}>Promijeni stanje bez arhive</Button>
                        <Button variant="outlined" onClick={() => {
                                setEdited(true);
                                //setTipStanjaPredmetaId(2); //zašto ovo?
                                setArhivaAlert(false);
                                setArhivaFormOpened(true);
                            }}>Promijeni stanje i stvori arhivu</Button>
                    </Box>
                    <Box style={{width:"100%", display:"flex", justifyContent:"flex-end", marginTop:"10px"}}>
                        <Button color="error" variant="outlined" onClick={() => setArhivaAlert(false)}>Odustani</Button>
                    </Box>
                </Box>
            </Modal>

            <Modal className="modal" open={arhivaFormOpened}>
                <Box sx={styleManji} overflow="auto">
                    <ArhivaPredmetForm setArhivaSaved={setArhivaSaved} setFormOpened={setArhivaFormOpened} predmetID={formPredmet ? formPredmet.PredmetID : -1} klijentID={formPredmet ? formPredmet.KlijentID : -1} predmetNaziv={naziv} brojRegistratora={brojRegistratora == 0 ? 1: brojRegistratora}></ArhivaPredmetForm>
                </Box>
            </Modal>

            <Modal open={dodajSud}>
                <Box sx={styleManji} overflow={"auto"} >
                    <SudForm dodajSudSuccess={dodajSudSuccess} sudNaziv={sudInputRef.current || ""} close={() => setDodajSud(false)}></SudForm>
                </Box>
            </Modal>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default PredmetForm;