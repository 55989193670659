import util from "../util/util";
import api from "../util/api";
import languages from "../util/languages";

export const database = {
    init,
    get,
    add,
    remove,
    set,
    where,
    first,
};

function init(onSuccess, onError, forceUpdate) {
    sessionStorage.setItem("init", "true");

    if (!forceUpdate) {
        forceUpdate = false;
    }

    let user = get("user");

    let lastDateUpdate = get("lastDateUpdate");
    if (!lastDateUpdate) {
        lastDateUpdate = new Date(0);
    }

    let dictionaryLastDateUpdate = get("dictionaryLastDateUpdate");
    if (!dictionaryLastDateUpdate) {
        dictionaryLastDateUpdate = new Date(0);
    }

    let position = get("position");
    if (!position) {
        position = { latitude: 0, longitude: 0 };
    }

    var initNewData = { ForceUpdate: forceUpdate, LastDictionaryDateUpdate: dictionaryLastDateUpdate, LastDateUpdate: lastDateUpdate, GUID: user.userDeviceGUID, Latitude: position.latitude, Longitude: position.longitude, RefreshSourceTypeID: 3, IsAccountSupported: true, NotificationRegID: "" };

    api.post(
        "TokenMobileApi/InitializeNew",
        initNewData,
        (dat) => {
            //ako je napunjeno:
            if (dat.Languages) {
                set("cities", dat.Cities);
                set("cardTypes", dat.CardTypes);
                set("zones", dat.Zones);
                set("zoneTariffs", dat.ZoneTariffs);
                set("languages", dat.Languages);
            }
            if (dat.Dictionary) {
                set("dictionary", JSON.parse(dat.Dictionary));
                languages.refreshLanguages(); //potrebno je importirati language.js, koji importira dtabase.js => cross reference...zato sam cijeli init prebacio u util.js
            }
            if (onSuccess) {
                onSuccess(dat);
            }
        },
        (err) => {
            if (onError) onError(err);
        }
    );
}

function get(field) {
    let items = window.localStorage.getItem(field);
    if (items) {
        try {
            items = JSON.parse(items);
        } catch {}
    } else {
        items = null;
        error("Table " + field + " does not exist!");
    }

    if (Array.isArray(items)) {
        items.forEach((item, index) => {
            if (isNaN(item)) {
                items[index]._id = index;
            }
        });
    }
    return items;
}

function set(field, items) {
    window.localStorage.setItem(field, JSON.stringify(items));
}

function add(field, value) {
    let items = window.localStorage.getItem(field);
    if (items) items = JSON.parse(items);
    if (!items) items = [];
    items.push(value);
    window.localStorage.setItem(field, JSON.stringify(items));
}

function error(message) {
    //console.error(message);
}

function where(field, property, value) {
    let items = get(field);

    if (items && items.length > 0) {
        if (!items[0][property]) {
            error("Property " + property + " does not exist in table " + field);
            return null;
        }

        let result = items.filter((x) => x[property] == value);
        return result;
    }

    return null;
}

function first(field, property, value) {
    let result = where(field, property, value);
    if (result && result.length > 0) {
        return where(field, property, value)[0];
    }
    return null;
}

function remove(field, value) {
    let items = window.localStorage.getItem(field);
    if (items) items = JSON.parse(items);
    let isMatch = true;
    let matchIndex = -1;

    for (var x = 0; x < items.length; x++) {
        let item = items[x];
        let prps = Object.getOwnPropertyNames(item);

        for (var i = 0; i < prps.length; i++) {
            let pItem = prps[i];
            isMatch = true;
            if (item[pItem] !== value[pItem]) {
                isMatch = false;
            }

            if (isMatch) {
                matchIndex = x;
                items.splice(matchIndex, 1);
                window.localStorage.setItem(field, JSON.stringify(items));
                break;
            }
        }

        if (isMatch) {
            break;
        }
    }
}

export default database;
