import { Autocomplete, Typography, Box, Button, FormControl, FormControlLabel, Grid, TextField, Checkbox } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';

function ArhivaNeelektronickaForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formArhiva = props.formArhiva;
    const tipArhiveID = props.tipArhiveID;
    const tipArhiveOznaka = props.tipArhiveOznaka;

    const tipArhiveNaziv = props.tipArhiveNaziv;
    const klijentID = props.klijentID;
    const predmetID = props.predmetID;
    const predmetNaziv = props.predmetNaziv;
    const predmetTipStanjaPredmetaID = props.predmetTipStanjaPredmetaID;
    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;
    const setArhivaSaved = props.setArhivaSaved;
    const arhivaPredmet = props.arhivaPredmet;

    const [broj, setBroj] = useState("");
    const [kolicina, setKolicina] = useState(props.brojRegistratora);
    const [loading, setLoading] = useState(true);
    const [objBroj, setObjBroj] = useState(null);
    const [objBrojevi, setObjBrojevi] = useState([]);
    const [alertOpened, setAlertOpened] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [promijeniStanje, setPromijeniStanje] = useState(false);

    useConstructor(() => {
        if (formArhiva) {
            setBroj(formArhiva.Broj);
            setKolicina(formArhiva.ArhivaStavka.length);
            setLoading(false);
        }
    });

    useEffect(() => {
        if(!formArhiva){
            let resource = {
                FirmaID : global.user.firmaID,
                TipArhiveID : tipArhiveID
            }
            //api.post("archive/getarhivanumber", resource, loadNumberSuccess, loadNumberFail)
            api.post("archive/getarhivanumberwithchildnumberlist", resource, loadNumberWithChildNumberListSuccess, loadNumberWithChildNumberListFail)
        }
    }, [tipArhiveID, formArhiva]);

    function loadNumberSuccess(data) {
        setBroj(data);
        setLoading(false);
    }

    function loadNumberFail() {

    }


    function loadNumberWithChildNumberListSuccess(data) {
        setObjBrojevi(data);
        if(data.length > 0){
            setObjBroj(data[data.length-1]);
        }
        setLoading(false);
    }

    function loadNumberWithChildNumberListFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleObjBrojChange(e,v) {
        if(v){
            setObjBroj(v);
        }
    }

    function handleKolicinaChange(e) {
        if (parseInt(e.target.value) > 0) {
            setKolicina(parseInt(e.target.value));
        } else if (e.target.value == "") {
            setKolicina("");
        }
    }

    function checkSave(){
        if(!formArhiva){
            if(objBroj.BrojStavki != null && objBroj.BrojStavki != kolicina){
                setAlertOpened(true);
            }
            else{
                save();
            }    
        }
        else{
            save();
        }
    }

    function save() {
        setLoading(true);
        let resource = {};
        if(formArhiva){
            resource = {
                TipArhiveID : tipArhiveID,
                FirmaID : global.user.firmaID,
                PredmetID : predmetID,
                Broj : broj,
                Kolicina : kolicina,
                Elektronicka : false
            }    
        }
        else{
            resource = {
                TipArhiveID : tipArhiveID,
                FirmaID : global.user.firmaID,
                PredmetID : predmetID,
                Broj : objBroj.Broj,
                Kolicina : objBroj.BrojStavki ? objBroj.BrojStavki : kolicina,
                Elektronicka : false,
                PromijeniStanje : promijeniStanje
            }    
        }
        api.post("archive/neweditarhiva", resource, saveSuccess, saveFail);
    }

    function saveSuccess() {
        setLoading(false);
        setCanceled(false);
        setFormOpened(false);
        if(setArhivaSaved){
            setArhivaSaved(true);
        }
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                {!arhivaPredmet ?
                    <Grid item xs={12} sx={{textAlign:'center'}}>
                        <Typography variant="h6" gutterBottom component="div" >
                            Stvaranje arhive fizičkih dokumenata
                        </Typography>    
                    </Grid>                
                : null}
                <Grid item xs={12}>
                    <FormControl style={{ width: "100%" }}>
                        <TextField size="small" label="Predmet" value={predmetNaziv} aria-readonly />
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12}>
                    <FormControl style={{ width: "100%" }}>
                       <TextField size="small" value={tipArhiveNaziv} label="Tip arhive" aria-readonly />
                    </FormControl>
                </Grid> */}
                
                {formArhiva ? (
                    <Grid item xs={12}>
                        <FormControl style={{ width: "100%" }}>
                        <TextField size="small" value={broj} label="Broj" aria-readonly />
                        </FormControl>
                    </Grid>
                ) : (

                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete style={{width:"100%"}}
                                disablePortal
                                autoHighlight
                                id="objBroj"
                                //disabled={tipArhiveOptions.length == 0}
                                options={objBrojevi}
                                value = {objBroj}
                                getOptionLabel={(option) => (tipArhiveOznaka ? tipArhiveOznaka : "") + option.Broj.toString().padStart(6,'0') + ' (' + (option.BrojStavki ? option.BrojStavki : kolicina) + ')'}
                                onChange={handleObjBrojChange}
                                renderInput={(params) => <TextField {...params} label="Broj" size="small" />}
                            />
                        </FormControl>
                    </Grid>

                )}
                <Grid item xs={12}>
                    <FormControl style={{ width: "100%" }}>
                        <TextField size="small" label="Količina" type="number" inputProps={{ min: 1 }} value={kolicina} onChange={handleKolicinaChange} error={kolicina == null || kolicina == ""} />
                    </FormControl>
                </Grid>
                {predmetTipStanjaPredmetaID == 1 ? (
                    <Grid item xs={12}>
                        <FormControlLabel
                            labelPlacement="end" 
                            label={<span>Promijeni stanje predmeta u "Zatvoren i realiziran"</span>}
                            style={{marginLeft:"0px"}}
                            control={<Checkbox size="small" checked={promijeniStanje} onChange={e => setPromijeniStanje(e.target.checked)}></Checkbox>}>
                        </FormControlLabel>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end" marginTop="10px">
                        <Button startIcon={<SaveIcon></SaveIcon>} color="success" variant="contained" disabled={kolicina == null || kolicina == ""} onClick={checkSave}>
                            Spremi
                        </Button>
                        <Button startIcon={<CloseIcon></CloseIcon>} color="error" variant="outlined" style={{marginLeft:"10px"}} onClick={() => {setCanceled(true); setFormOpened(false);}}>
                            Odustani
                        </Button>
                    </Box>
                </Grid>

                {alertOpened ? <AlertDialog message={"Odabrana količina za arhiviranje je: " + kolicina + ", a prema evidenciji mjesta ima za: " + objBroj.BrojStavki + ". Da li ste sigurni da možete organizirati prostor za arhiviranje na prikladan način?"} title={"Upozorenje na količinu"} cancelLabel={"Odustani"} confirmLabel={"Spremi"} cancelAction={() => setAlertOpened(false)} confirmAction={save}></AlertDialog> : null}

                {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
            </Grid>
        </ComponentContainer>
    );

}

export default ArhivaNeelektronickaForm;