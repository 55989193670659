import { Alert, Autocomplete, Box, Button, Divider, FormControl, Grid, IconButton, List, ListItem, ListItemText, Modal, Paper, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import LockIcon from '@mui/icons-material/Lock';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import LineIcon from "react-lineicons";
import ExitToApp from "@mui/icons-material/ExitToApp";
import dateFormat, { masks } from "dateformat";
import { P } from "@antv/g2plot";
import Racuni from "./racuni";
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import RestoreIcon from '@mui/icons-material/Restore';
import EditIcon from "@mui/icons-material/Edit";
import FakturiranjeRucniUnosBrojaForm from "./fakturiranjeRucniUnosBrojaForm";
import ErrorIcon from '@mui/icons-material/Error';
import AlertDialog from "./alertdialog";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6
};

function FakturiranjeForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    //const racun = props.racun;
    const racuni = props.racuni;
    const setFormOpened = props.setFormOpened;
    const odobrenjeZaduzenje = props.odobrenjeZaduzenje;
    const storno = props.storno;
    const setStornoDatum = props.setStornoDatum;
    const setBorderStyle = props.setBorderStyle;

    const [loading, setLoading] = useState(true);
    const [poslovniProstori, setPoslovniProstori] = useState([]);
    const [poslovniProstor, setPoslovniProstor] = useState(null);
    const [blagajne, setBlagajne] = useState([]);
    const [blagajna, setBlagajna] = useState(null);
    const [brojevi, setBrojevi] = useState([]);
    const [currentBroj, setCurrentBroj] = useState(null);
    const [broj, setBroj] = useState(null);
    const [datum, setDatum] = useState(null);
    const [datumVrijeme, setDatumVrijeme] = useState(null);
    const [datumPlacanja, setDatumPlacanja] = useState(null);
    const [readyToSave, setReadyToSave] = useState(false);
    const [minDatum, setMinDatum] = useState(null);
    const [maxDatum, setMaxDatum] = useState(null);
    const [datumError, setDatumError] = useState("");
    const [fiksniIznosMessages, setFiksniIznosMessages] = useState([]);
    const [fiksniIznosAlert, setFiksniIznosAlert] = useState(false);
    const [nastavljenoSFakturiranjem, setNastavljenoSFakturiranjem] = useState(false);
    const [placanjeBrojDana, setPlacanjeBrojDana] = useState(0);
    const [vrijemeZadnjegRacuna, setVrijemeZadnjegRacuna] = useState(null);
    const [rucniUnosBroja, setRucniUnosBroja] = useState(false);
    const [prviBrojZaRucniUnos, setPrviBrojZaRucniUnos] = useState(2);
    const [godina, setGodina] = useState(new Date().getFullYear());
    const [errorMessage, setErrorMessage] = useState("");
    
    const bottomRef = useRef();

    useConstructor(() => {
        let resource = {
            FirmaID : global.user.firmaID,
            VrstaRacunaID : props.vrstaRacunaID,
            VezniRacunID : props.vezniRacunID
        }
        api.post("fakturiranje/getposlovniprostoriblagajne", resource, loadPoslovniProstoriBlagajneSuccess, loadPoslovniProstoriBlagajneFail);
    });

    function loadPoslovniProstoriBlagajneSuccess(data) {
        ;
        setPoslovniProstori(data.PoslovniProstori);
        setBlagajne(data.Blagajne);
        if (data.ZadnjaKoristenaBlagajna) {
            data.PoslovniProstori.forEach(pp => {
                if (pp.PoslovniProstorID == data.ZadnjaKoristenaBlagajna.PoslovniProstorID) {
                    setPoslovniProstor(pp);
                }
            })
            setBlagajna(data.ZadnjaKoristenaBlagajna);
        }
        if (racuni && racuni.at(0)) {
            setDatum(new Date(racuni.at(0).Datum));
            setDatumVrijeme(new Date(racuni.at(0).Datum));
            setPlacanjeBrojDana(racuni[0].DatumPlacanjaBrojDana);
        }
        if (data.ZadnjeVrijemeRacuna) {
            setVrijemeZadnjegRacuna(new Date(data.ZadnjeVrijemeRacuna));
        }
        setLoading(false);
    }

    function loadPoslovniProstoriBlagajneFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        // ovdje se treba refreshati dostupne brojeve, jer se promijenila godina u kojoj se fakturira
        if (blagajna && datum && datum.getFullYear() != godina) {
            setGodina(datum.getFullYear());
            setLoading(true);
            let resource = {
                BlagajnaID : blagajna.BlagajnaID,
                OdobrenjeZaduzenjeStornoDatumRacuna : odobrenjeZaduzenje || storno ? props.odobrenjeZaduzenjeStornoDatumRacuna : null,
                Storno: storno,
                Godina : datum.getFullYear()
            }
            api.post("fakturiranje/getbrojevi", resource, loadBrojeviSuccess, loadBrojeviFail);
        }
    }, [datum, blagajna]);

    useEffect(() => {
        setBlagajna(null);
    }, [poslovniProstor]);

    useEffect(() => {
        if (blagajna) {
            if (setBorderStyle) {
                if (blagajna.Fiskalna) {
                    setBorderStyle("10px solid #FF7F50");
                } else {
                    setBorderStyle("2px solid #000");
                }
            }
            setLoading(true);
            let resource = {
                BlagajnaID : blagajna.BlagajnaID,
                OdobrenjeZaduzenjeStornoDatumRacuna : odobrenjeZaduzenje || storno ? props.odobrenjeZaduzenjeStornoDatumRacuna : null,
                Storno : storno,
                Godina : godina
            }
            api.post("fakturiranje/getbrojevi", resource, loadBrojeviSuccess, loadBrojeviFail);
        }
    }, [blagajna]);

    function loadBrojeviSuccess(data) {
        setBrojevi(data);
        setCurrentBroj(data.at(data.length - 1));
        setPrviBrojZaRucniUnos(data.at(data.length - 1).PrviSlobodniBroj + 1);
        setLoading(false);
    }

    function loadBrojeviFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        if (currentBroj) {
            setMinDatum(currentBroj.DatumOd ? new Date(currentBroj.DatumOd) : null);
            setMaxDatum(currentBroj.DatumDo ? new Date(currentBroj.DatumDo) : null);
            setBroj(currentBroj.PrviSlobodniBroj);
            if (currentBroj.DatumOd && datum < new Date(currentBroj.DatumOd)) {
                setDatum(new Date(currentBroj.DatumOd));
                setDatumVrijeme(new Date(currentBroj.DatumOd));
            }
            if (currentBroj.DatumDo && datum > new Date(currentBroj.DatumDo)) {
                setDatum(new Date(currentBroj.DatumDo));
                setDatumVrijeme(new Date(currentBroj.DatumDo));
            }
        }
    }, [currentBroj]);

    useEffect(() => {
        if (datum) {
            //setNastavljenoSFakturiranjem(false);
            if (isNaN(new Date(datum))) {
                setDatumError("Pogrešan format datuma!");
            } else {
                let datumCheck = new Date(datum.getFullYear(), datum.getMonth(), datum.getDate(), datum.getHours(), datum.getMinutes(), datum.getSeconds());
                if (minDatum && minDatum > datumCheck || maxDatum && maxDatum < datumCheck) {
                        setDatumError("Datum izvan dopuštenog raspona!");
                } else {
                    setDatumError("");
                    // if (currentBroj && racuni && racuni.filter(r => r.FiksnaNaplata).length > 0) {
                    //     provjeriJeLiVecFakturiranFiksniIznos();
                    // }
                    if(!odobrenjeZaduzenje && !storno) {
                        provjeriJeLiVecFakturiranFiksniIznos();
                    }
                }
            }
        } else {
            setDatumError("");
            // if (currentBroj && racuni && racuni.filter(r => r.FiksnaNaplata).length > 0) {
            //     provjeriJeLiVecFakturiranFiksniIznos();
            // }
            if(!odobrenjeZaduzenje && !storno) {
                provjeriJeLiVecFakturiranFiksniIznos();
            }
        }
    }, [datum, minDatum, maxDatum]);

    function provjeriJeLiVecFakturiranFiksniIznos() {
        setLoading(true);
        let resource = {
            //RacuniIDs : racuni ? racuni.map(r => r.RacunID) : [props.racun.RacunID],
            RacuniIDs : racuni.map(r => r.RacunID),
            Datum : datum
        }
        api.post("racun/provjerifiksniiznos", resource, provjeriJeLiVecFakturiranFiksniIznosSuccess, provjeriJeLiVecFakturiranFiksniIznosFail);
    }

    function provjeriJeLiVecFakturiranFiksniIznosSuccess(data) {
        if (data.length > 0) {
            setFiksniIznosMessages(data);
            if (!nastavljenoSFakturiranjem || data.filter(m => !m.Cap).length > 0) {
                setFiksniIznosAlert(true);
            }
        }
        setLoading(false);
    }

    function provjeriJeLiVecFakturiranFiksniIznosFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save() {
        if (!storno) {
            let resource = {
                RacuniIDs : racuni.map(r => r.RacunID),
                Broj : broj,
                Blagajna : blagajna,
                PoslovniProstor : poslovniProstor,
                Datum : datum,
                DatumPlacanja : datumPlacanja,
                DatumPlacanjaBrojDana : parseInt(placanjeBrojDana)
            }
            setLoading(true);
            api.post("fakturiranje/save", resource, afterSave, saveFail);
        } else if (storno) {
        }
    }

    useEffect(() => {
        if (readyToSave) {
            props.save();
            props.filterRacuni();
            setFormOpened(false);
        }
    }, [readyToSave]);

    function afterSave() {
        if (blagajna.Fiskalna) {
            let resource = {
                RacunskaStavkaID : -1,
                RacunID : racuni[0].RacunID,
                TipNaplatnostiID : 1,
                FirmaJezik : null,
                PutanjaDoReporta : null,
                Extension : ".pdf",
                MediaTypeHeaderValue : "application/pdf",
                ReportType : "PDF",
                NaRuke : null,
                PrikaziStvarnoEvidentiranoVrijeme : false,
                PrikaziBarkod : false
            };
            api.getReport("defaultispisracuna/" + racuni[0].RacunID, resource, ".pdf", "_blank", saveSuccess, saveSuccess);
        } else {
            saveSuccess();
        }
    }

    function saveSuccess() {
        setLoading(false);
        if (setBorderStyle) {
            setBorderStyle("2px solid #000");
        }
        props.filterRacuni();
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handle235959Click() {
        let newDatum = new Date(datum);
        newDatum.setHours(23, 59, 59);
        setDatum(newDatum);
    }

    function handleDanasClick() {
        let newDatum = new Date();
        newDatum.setHours(datum.getHours(), datum.getMinutes(), datum.getSeconds());
        setDatum(newDatum);
    }

    function handleNowClick() {
        let now = new Date();
        let newDatum = new Date(datum);
        newDatum.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
        setDatum(newDatum);
    }

    
    function addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    useEffect(() => {
        if (!isNaN(parseInt(placanjeBrojDana))) {
            setDatumPlacanja(addDays(datum, parseInt(placanjeBrojDana)));
        }
    }, [datum, placanjeBrojDana]);

    function handleDatumPlacanjaChange(v) {
        setDatumPlacanja(v);
        if (datum && v) {
            let razlika = v.getTime() - datum.getTime();
            let dani = Math.ceil(razlika / (1000 * 3600 * 24));
            setPlacanjeBrojDana(dani);
        }
    }

    useEffect(() => {
        if (storno) {
            props.setParentCurrentBroj(currentBroj);
            props.setParentBlagajna(blagajna);
            props.setParentPoslovniProstor(poslovniProstor);
        }
    }, [broj, blagajna, poslovniProstor]);

    function handleZadnjeVrijemeClick() {
        let newDatum = new Date(vrijemeZadnjegRacuna);
        setDatum(newDatum);
    }

    useEffect(() => {
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
    }, [brojevi]);

    useEffect(() => {
        if (storno) {
            setStornoDatum(datum);
        }
    }, [datum]);

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div">
                        <div style={{
                            display: "inline-flex",
                            verticalAlign: "text-bottom",
                            boxSizing: "inherit",
                            textAlign: "center",
                            justifyContent: storno ? "flex-start" : "center",
                            alignItems: "center",
                            width: "100%"
                        }}>
                            <LockIcon fontSize="medium" style={{marginRight:"15px"}}></LockIcon>
                            Fakturiranje dokumenata
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            options={poslovniProstori}
                            value={poslovniProstor}
                            getOptionLabel={(option) => option.Naziv}
                            onChange={(e,v) => {if (v) setPoslovniProstor(v)}}
                            renderInput={(params) => <TextField {...params} error={!poslovniProstor} label="Poslovni prostor" size="small" style={{width:"99%"}} />}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <Autocomplete
                            disablePortal
                            autoHighlight
                            options={poslovniProstor ? blagajne.filter(b => b.PoslovniProstorID == poslovniProstor.PoslovniProstorID) : []}
                            disabled={!poslovniProstor}
                            value={blagajna}
                            getOptionLabel={(option) => option.Naziv + (option.Fiskalna ? " (FISKALNA BLAGAJNA)" : "")}
                            onChange={(e,v) => {if (v) setBlagajna(v)}}
                            style={{backgroundColor: (blagajna && blagajna.Fiskalna) ? "#FF7F50" : "white", borderRadius: "5px" }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={"blagajna" + option.BlagajnaID} style={{backgroundColor: option.Fiskalna ? "#FF7F50" : "white"}}>
                                        {option.Naziv + (option.Fiskalna ? " (FISKALNA BLAGAJNA)" : "")}
                                    </li>
                                );
                            }}
                            renderInput={(params) => <TextField {...params} error={!blagajna} label={<span style={{ display:"flex", justifyContent:"center", backgroundColor:"white", borderRadius:"10px", width:"6em"}}>Blagajna</span>} size="small" style={{width:"100%"}} />}
                        />
                    </FormControl>
                </Grid>
                {!storno && racuni.length > 1 && (!blagajna || !blagajna.Fiskalna) ? (
                    <Grid item xs={12}>
                        <Alert severity="warning">Za fakturiranje je odabrano više od jednog ({racuni.length}) računa. Za sve fakturirane račune vrijedit će datum računa i datum plaćanja odabrani u ovoj formi, dok se broj računa bira samo za prvi račun u nizu, a svaki će sljedeći račun dobiti broj prethodnog uvećan za 1.</Alert>
                    </Grid>                    
                ) : null}
                {blagajna && blagajna.FiskalnaBlagajnaWarning ? (
                    <Grid item xs={12}>
                        <Alert severity="warning">{blagajna.FiskalnaBlagajnaWarning}</Alert>
                    </Grid>                    
                ) : null}
                {!storno && racuni.length > 1 && blagajna && blagajna.Fiskalna ? (
                    <Grid item xs={12}>
                        <Alert severity="error">Nije moguće odjednom fakturirati više od jednog računa na fiskalnoj blagajni.</Alert>
                    </Grid>
                ) : null}
                {blagajna && blagajna.FiskalnaBlagajnaError ? (
                    <Grid item xs={12}>
                        <Alert severity="error">{blagajna.FiskalnaBlagajnaError}</Alert>
                    </Grid>
                ) : null}
                {blagajna && !blagajna.Fiskalna && brojevi.length > 0 ? (
                    <Grid item xs={12} marginTop="10px" marginBottom="10px">
                        <ListItem className="list-title" style={{backgroundColor:"lightblue"}}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Broj" />
                                </Grid>
                                <Grid item xs={5}>
                                    <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Početno vrijeme" />
                                </Grid>
                                <Grid item xs={5}>
                                    <ListItemText primaryTypographyProps={{ noWrap:true }} primary="Krajnje vrijeme" />
                                </Grid>
                            </Grid>
                        </ListItem>
                        <Paper variant="outlined" style={{width:"100%", maxHeight: 250, overflow: 'auto'}}>
                            <List dense>
                                {brojevi.map((broj, index) => {
                                    return <Tooltip enterDelay={200} enterNextDelay={200} arrow title={!storno && broj.Kapacitet && broj.Kapacitet < racuni.length ? "Nemoguće je odabrati ovaj broj kao broj prvoga računa, jer je duljina niza slobodnih brojeva računa premalena da bi se mogli fakturirati svi računi." : ""}>
                                        <ListItem className="list-item" 
                                            key={"listItem" + index}
                                            onClick={() => {if (storno || !broj.Kapacitet || broj.Kapacitet >= racuni.length) setCurrentBroj(broj)}}
                                            style={{paddingTop:0, paddingBottom:0}}
                                            disabled={broj.Kapacitet && !storno && broj.Kapacitet < racuni.length}
                                        >
                                            <Grid container spacing={1}>
                                                <Grid item xs={2} onDoubleClick={() => {if (broj.DatumOd) {setDatum(new Date(broj.DatumOd)); setDatumVrijeme(new Date(broj.DatumOd))}}}>
                                                    <ListItemText primaryTypographyProps={{ noWrap:true }} primary={broj == currentBroj ? <b>{broj.PrviSlobodniBroj}</b> : broj.PrviSlobodniBroj} />
                                                </Grid>
                                                <Grid item xs={5} onDoubleClick={() => {if (broj.DatumOd) {setDatum(new Date(broj.DatumOd)); setDatumVrijeme(new Date(broj.DatumOd))}}}>
                                                    <ListItemText primaryTypographyProps={{ noWrap:true }} primary={broj == currentBroj ? <b>{broj.DatumOd ? dateFormat(broj.DatumOd, "dd.mm.yyyy HH:MM:ss") : "---"}</b> : broj.DatumOd ? dateFormat(broj.DatumOd, "dd.mm.yyyy HH:MM:ss") : "---"} />
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <ListItemText primaryTypographyProps={{ noWrap:true }} primary={broj == currentBroj ? <b>{broj.DatumDo ? dateFormat(broj.DatumDo, "dd.mm.yyyy HH:MM:ss") : "---"}</b> : broj.DatumDo ? dateFormat(broj.DatumDo, "dd.mm.yyyy HH:MM:ss") : "---"} />
                                                </Grid>
                                            </Grid>
                                            {index == brojevi.length - 1 ? (
                                                <div ref={bottomRef}></div>
                                            ) : null}
                                        </ListItem>
                                    </Tooltip>;
                                })}
                            </List>
                        </Paper>
                    </Grid>
                ) : null}
                {blagajna && brojevi.length > 0 ? (
                    <Grid item xs={3} >
                        <FormControl fullWidth>
                            <TextField label="Broj" value={broj || ""} inputProps={{ readOnly : true }} size="small" ></TextField>
                        </FormControl>
                    </Grid>
                ) : null}
                {blagajna && !blagajna.Fiskalna && brojevi.length > 0 && !storno ? (
                    <Grid item xs={3} >
                        <Tooltip arrow title="Unos broja računa većeg od najvećeg broja fakturiranih računa na odabranoj blagajni" enterDelay={200} enterNextDelay={200}>
                            <span>
                                <Button style={{width:"100%", height:"100%"}} startIcon={<EditIcon></EditIcon>} onClick={() => {setCurrentBroj(brojevi.at(brojevi.length - 1)); setRucniUnosBroja(true);}} variant="outlined">Ručni unos broja</Button>
                            </span>
                        </Tooltip>
                    </Grid>
                ) : null}
                {blagajna && brojevi.length > 0 ? (
                    <Grid item xs={blagajna.Fiskalna ? 9 : 6} >
                    </Grid>
                ) : null}
                {blagajna && !blagajna.Fiskalna && brojevi.length > 0 ? (
                    <>
                        <Grid item xs={8} sm={8} md={5} marginTop="2px" marginBottom="2px">
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils}>
                                    <DatePicker
                                        label="Datum izdavanja"
                                        value={datum}
                                        inputFormat="dd.MM.yyyy"
                                        onChange={(v) => {if (v) {
                                            setDatum(v)
                                        }}}
                                        renderInput={(params) => <TextField {...params} size="small" error={!datum || datumError} helperText={datumError} required />}
                                    />
                                </LocalizationProvider>        
                            </FormControl>
                        </Grid>
                        <Grid item xs={8} sm={8} md={1} style={{display:"flex", alignItems:"center"}}>                        
                            <Button style={{width:"100%", height:"90%"}} onClick={handleDanasClick} variant="outlined">Danas</Button>                            
                        </Grid>
                    </>
                ) : null}
                {blagajna && !blagajna.Fiskalna && brojevi.length > 0 ? (
                    <Grid item  xs={6} sm={6} md={3} marginTop="2px" marginBottom="2px">
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils}>
                                <TimePicker
                                    label="Datum izdavanja - vrijeme"
                                    value={datum}
                                    views={["hours", "minutes", "seconds"]}
                                    inputFormat="HH:mm:ss"
                                    onChange={(v) => {if (v) setDatum(v)}}
                                    renderInput={(params) => <TextField sx={{width:"99%"}} {...params} size="small" error={!datum || datumError} helperText={datumError} required />}
                                />
                            </LocalizationProvider>        
                        </FormControl>
                    </Grid>
                ) : null}
                {/* {blagajna && brojevi.length > 0 && !odobrenjeZaduzenje && !storno ? (
                    <Grid item  xs={6} sm={6} md={3} marginTop="2px" marginBottom="2px">
                        <FormControl fullWidth>
                                <KeyboardTimePicker
                                    label="Datum izdavanja - vrijeme"
                                    value={datum} mask="__:__:__"
                                    views={["hours", "minutes", "seconds"]}
                                    inputFormat="HH:mm:ss"
                                    onChange={(v) => {if (v) setDatum(v)}}
                                    renderInput={(params) => <TextField sx={{width:"99%"}} {...params} size="small" error={!datum || datumError} helperText={datumError} required />}
                                />
                        </FormControl>
                    </Grid>
                ) : null} */}
                {blagajna && !blagajna.Fiskalna && brojevi.length > 0 ? (
                    <Grid item  xs={1} sm={1} md={1} marginTop="2px" marginBottom="2px" display="flex" alignItems="center" justifyContent="flex-end">
                        <Tooltip title="Trenutno vrijeme">
                            <span style={{width:"100%"}}>
                                <IconButton style={{width:"100%"}} onClick={handleNowClick}><HistoryToggleOffIcon></HistoryToggleOffIcon></IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                ) : null}
                {blagajna && !blagajna.Fiskalna && brojevi.length > 0 ? (
                    <Grid item  xs={1} sm={1} md={1} marginTop="2px" marginBottom="2px" display="flex" alignItems="center" justifyContent="flex-end">
                        <Tooltip title="Datum i vrijeme zadnjeg fakturiranog računa">
                            <span style={{width:"100%"}}>
                                <IconButton style={{width:"100%"}} disabled={vrijemeZadnjegRacuna == null} onClick={handleZadnjeVrijemeClick}><RestoreIcon></RestoreIcon></IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                ) : null}
                {blagajna && !blagajna.Fiskalna && brojevi.length > 0 ? (
                    <Grid item  xs={5} sm={5} md={1} marginTop="2px" marginBottom="2px" display="flex" alignItems="center" justifyContent="flex-end">
                        <Button variant="outlined" style={{width:"95%", height:"80%"}} onClick={handle235959Click}>23:59:59</Button>
                    </Grid>
                ) : null}
                {blagajna && brojevi.length > 0 && !storno ? (
                    <Grid item  xs={12} sm={12} md={6} marginTop="2px" marginBottom="2px">
                        <FormControl fullWidth>
                            <TextField label="Broj dana za plaćanje" type="number" inputProps={{min:0}} value={placanjeBrojDana} onChange={(e) => {setPlacanjeBrojDana(e.target.value);}} error={placanjeBrojDana === null || placanjeBrojDana === "" || isNaN(parseInt(placanjeBrojDana)) || parseInt(placanjeBrojDana) < 0} required size="small"></TextField>
                        </FormControl>
                    </Grid>
                ) : null}
                {blagajna && brojevi.length > 0 && !storno ? (
                    <Grid item  xs={12} sm={12} md={6} marginTop="2px" marginBottom="2px">
                        <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils}>
                                <DatePicker
                                    label="Datum plaćanja"
                                    value={datumPlacanja}
                                    inputFormat="dd.MM.yyyy"
                                    onChange={(v) => {handleDatumPlacanjaChange(v)}}
                                    renderInput={(params) => <TextField sx={{width:"99%"}} {...params} size="small" error={!datumPlacanja || isNaN(new Date(datumPlacanja))} required />}
                                />
                            </LocalizationProvider>        
                        </FormControl>
                    </Grid>
                ) : null}
                {blagajna && brojevi.length > 0 ? (
                    <Grid item xs={6} marginTop="2px" marginBottom="2px">
                    </Grid>
                ) : null}
                {!storno ? (
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                            <Button onClick={save} variant="contained" style={{marginRight:"10px"}} color="success" disabled={loading || !broj || (!odobrenjeZaduzenje && !storno && !datum) || (!odobrenjeZaduzenje && !storno && !datumPlacanja) || !blagajna || datumError || placanjeBrojDana === null || (!storno && racuni.length > 1 && blagajna && blagajna.Fiskalna) || blagajna.FiskalnaBlagajnaError || placanjeBrojDana === "" || isNaN(parseInt(placanjeBrojDana)) || parseInt(placanjeBrojDana) < 0}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <LockIcon style={{marginRight:"10px"}} />} Fakturiraj</Button>
                            <Button variant="outlined" color="error" onClick={() =>  setFormOpened(false)} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>Zatvori</Button>
                        </Box>
                    </Grid>  
                ) : null}
            </Grid>

            <Modal open={fiksniIznosAlert}>
                <Box sx={{...style}} overflow={"auto"} >
                    <Grid container spacing={1}>
                        {fiksniIznosMessages.filter(r => !r.Cap).length > 0 ? (
                            // <Grid item xs={12} style={{display:"flex", justifyContent:"center"}}>
                            //     <Typography variant="h5" gutterBottom component="div" marginBottom="10px" style={{textAlign:"center"}}> 
                            //             Provjera predmeta s fiksnim iznosom naplate
                            //     </Typography>
                            // </Grid>
                            <Grid item xs={12}>
                                <Alert severity="warning">Prekoračenje kod predmeta s fiksnim iznosom naplate</Alert>
                            </Grid>
                        ) : null}
                        {fiksniIznosMessages.filter(r => !r.Cap).map((m, i) => {
                            return (
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" gutterBottom component="div" marginBottom="10px"> 
                                                    Račun ID: {m.RacunID}, Klijent: {m.Klijent}, Iznos: {m.Iznos}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider></Divider>
                                        </Grid>
                                        {m.Racuni.map((r,i) => {
                                            return (
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={8}>
                                                            <div>{r.Message}</div>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <div style={{textAlign:"right"}}>{r.Iznos}</div>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                        <Grid item xs={12}>
                                            <Divider></Divider>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{textAlign:"right"}}>Zasad fakturirano:</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div style={{textAlign:"right"}}>{m.UkupnoFakturirano}</div>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{textAlign:"right"}}>Planirani fiksni iznos:</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div style={{textAlign:"right"}}>{m.IznosPerioda}</div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider></Divider>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{textAlign:"right"}}>Prekoračenje:</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div style={{textAlign:"right", fontWeight:"bold", color:"red"}}>{m.Prekoracenje}</div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                        {fiksniIznosMessages.filter(r => r.Cap).length > 0 ? (
                            <>
                            <Grid item xs={12}>
                                <Divider></Divider>
                            </Grid>
                            {/* <Grid item xs={12} style={{display:"flex", justifyContent:"center"}}>
                                <Typography variant="h5" gutterBottom component="div" marginBottom="10px" style={{textAlign:"center"}}> 
                                        Prekoračenje
                                </Typography>
                            </Grid> */}
                            <Grid item xs={12}>
                                <Alert severity="warning">Prekoračenje kod predmeta s ograničenim (cap) iznosom naplate</Alert>
                            </Grid>
                            </>
                        ) : null}
                        {fiksniIznosMessages.filter(r => r.Cap).map((m, i) => {
                            return (
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="button" gutterBottom component="div"> 
                                                {m.Predmet}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider></Divider>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{textAlign:"right"}}>Zasad fakturirano:</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div style={{textAlign:"right"}}>{m.UkupnoFakturirano}</div>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{textAlign:"right"}}>Cap:</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div style={{textAlign:"right"}}>{m.IznosPerioda}</div>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{textAlign:"right"}}>Ostalo:</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div style={{textAlign:"right"}}>{m.OstaloDoCapa}</div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider></Divider>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{textAlign:"right"}}>Ukupni iznos na odabranim računima za fakturiranje:</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div style={{textAlign:"right"}}>{m.Iznos}</div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider></Divider>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <div style={{textAlign:"right"}}>Prekoračenje:</div>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <div style={{textAlign:"right", fontWeight:"bold", color:"red"}}>{m.Prekoracenje}</div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                        <Grid item xs={12} style={{display:"flex", justifyContent:"flex-end", marginTop:"15px"}}>
                            <Button color="success" variant="contained" onClick={() => {setFiksniIznosAlert(false); setNastavljenoSFakturiranjem(true);}}>Nastavi s fakturiranjem</Button>
                            <Button color="error" style={{marginLeft:"5px"}} variant="outlined" onClick={() => setFormOpened(false)}>Odustani od fakturiranja</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>

            <Modal open={rucniUnosBroja}>
                <Box sx={style}>
                    <FakturiranjeRucniUnosBrojaForm prviBroj={prviBrojZaRucniUnos} datum={datum} godina={godina} blagajnaID={blagajna ? blagajna.BlagajnaID : 0} setBrojRacuna={setBroj} close={() => setRucniUnosBroja(false)}></FakturiranjeRucniUnosBrojaForm>
                </Box>
            </Modal>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );
}

export default FakturiranjeForm;