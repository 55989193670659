import { Autocomplete, Button, Divider, FormControl, Grid, Modal, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddEditDelForm from "./addEditDelForm";
import { Box } from "@mui/system";
import BlagajnaForm from "./blagajnaForm";
import globalContext from "./globalContext";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

export default function Fiskalizacija(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [firme, setFirme] = useState([]);
    const [firma, setFirma] = useState(null);
    const [apiFiscalUsername, setApiFiscalUsername] = useState("");
    const [apiFiscalPassword, setApiFiscalPassword] = useState("");
    const [fiscalCertValidityDate, setFiscalCertValidityDate] = useState(new Date());
    const [blagajne, setBlagajne] = useState([]);
    const [poslovniProstori, setPoslovniProstori] = useState([]);
    const [currentBlagajna, setCurrentBlagajna] = useState(null);
    const [formBlagajna, setFormBlagajna] = useState(null);
    const [blagajnaFormOpened, setBlagajnaFormOpened] = useState(false);
    const [deleteBlagajnaAlert, setDeleteBlagajnaAlert] = useState(false);

    useConstructor(() => {
        api.get("firma/getall", loadFirmeSuccess, loadFirmeFail);
    });

    function loadFirmeSuccess(data) {
        setFirme(data);
        setLoading(false);
    }
 
    function loadFirmeFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleFirmaChange(v) {
        if (v) {
            setFirma(v);
            setLoading(true);
            api.get("postavke/getfiskalniparametri/" + v.FirmaID, loadParametriSuccess, loadParametriFail);
        }
    }

    function loadParametriSuccess(data) {
        setApiFiscalUsername(data.ApiFiscalUsername);
        setApiFiscalPassword(data.ApiFiscalPassword);
        setFiscalCertValidityDate(data.FiscalCertValidityDate);
        setBlagajne(data.Blagajne);
        setPoslovniProstori(data.PoslovniProstori);
        setCurrentBlagajna(data.Blagajne.length > 0 ? data.Blagajne[0] : null);
        setLoading(false);
    }
 
    function loadParametriFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function deleteBlagajna() {
        setLoading(true);
        api.post("poslovniprostor/deleteblagajna/" + currentBlagajna.BlagajnaID, null, deleteBlagajnaSuccess, deleteBlagajnaFail);
    }

    function deleteBlagajnaSuccess() {
        setLoading(false);
        setDeleteBlagajnaAlert(false);
        handleFirmaChange(firma);
    }

    function deleteBlagajnaFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setDeleteBlagajnaAlert(false);
        setLoading(false);
    }

    function save() {
        setLoading(true);
        let resource = {
            FirmaID: firma.FirmaID,
            ApiFiscalUsername: apiFiscalUsername,
            ApiFiscalPassword: apiFiscalPassword,
            FiscalCertValidityDate: fiscalCertValidityDate
        };
        api.post("postavke/savefiskalniparametri", resource, saveSuccess, saveFail);
    }

    function saveSuccess(data) {
        handleFirmaChange(firma);
        setLoading(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{marginTop:"20px", marginBottom:"10px", display:"flex", alignItems:"center"}}>
                        <ReceiptLongIcon fontSize="medium" style={{marginRight:"15px"}}></ReceiptLongIcon>
                        Fiskalizacija
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth >
                        <Autocomplete
                            disablePortal
                            disableClearable
                            options={firme}
                            getOptionLabel={(option) => option.Naziv}
                            value={firma}
                            onChange={(e,v) => {
                                handleFirmaChange(v);
                            }}                           
                            renderInput={(params) => <TextField {...params} label="Firma" size="small"/>}
                        />
                    </FormControl>
                </Grid>
                {firma ? (
                    <>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <TextField label="eServices korisničko ime" value={apiFiscalUsername || ""} onChange={e => setApiFiscalUsername(e.target.value)} size="small"/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <TextField label="eServices lozinka" value={apiFiscalPassword || ""} onChange={e => setApiFiscalPassword(e.target.value)} size="small"/>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <LocalizationProvider dateAdapter={AdapterDateFns} locale={hr} utils={DateFnsUtils} fullWidth>
                                <DatePicker
                                    label="Certifikat vrijedi do"
                                    value={fiscalCertValidityDate || null}
                                    inputFormat="dd.MM.yyyy."
                                    onChange={(v) => {
                                        if (!isNaN(new Date(v))) {
                                            setFiscalCertValidityDate(new Date(v));
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} sx={{width:"100%"}} size="small" />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"right"}}>
                            <Button style={{marginRight:"5px"}} color="success" onClick={save} startIcon={<SaveIcon></SaveIcon>} variant="contained">Spremi</Button>
                            <Button color="error" onClick={() => handleFirmaChange(firma)} startIcon={<CancelIcon></CancelIcon>} variant="outlined">Poništi</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider></Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="button" gutterBottom component="div" style={{marginBottom:"10px"}}>
                                Fiskalne blagajne
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <AddEditDelForm 
                                dataType="blagajne" 
                                data={blagajne} 
                                current={currentBlagajna} 
                                setCurrent={setCurrentBlagajna} 
                                add={() => {
                                    setFormBlagajna(null);
                                    setBlagajnaFormOpened(true);
                                }} 
                                edit={() => {
                                    setFormBlagajna(currentBlagajna);
                                    setBlagajnaFormOpened(true);
                                }} 
                                del={() => setDeleteBlagajnaAlert(true)}>
                            </AddEditDelForm>
                        </Grid>
                    </>
                ) : null}
            </Grid>
                
            <Modal open={blagajnaFormOpened}>
                <Box sx={style} overflow={"auto"} >
                    <BlagajnaForm loadPoslovniProstori={() => handleFirmaChange(firma)} poslovniProstori={poslovniProstori} fiskalna={true} firmaID={global.user.firmaID} formBlagajna={formBlagajna} setFormOpened={setBlagajnaFormOpened} poslovniProstorID={formBlagajna ? formBlagajna.PoslovniProstorID : null}></BlagajnaForm>
                </Box>
            </Modal>

            {deleteBlagajnaAlert ? <AlertDialog title="Brisanje blagajne" message={"Jeste li sigurni da želite obrisati odabranu blagajnu?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => setDeleteBlagajnaAlert(false)} confirmAction={deleteBlagajna}></AlertDialog> : null}

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
        </ComponentContainer>
    );

}