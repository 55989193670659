import { Tooltip, Alert, Autocomplete, Box, Button, Divider, FormControl, Grid, imageListClasses, imageListItemClasses, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import LineIcon from "react-lineicons";
import api from "../util/api";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutocompleteAsync from "../controls/autocompleteasync";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorAlert from "./errorAlert";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import GlobalContext from "../components/globalContext";
import upute from "../images/chato-upute.pdf";
import slogan from "../images/ManageTimeSaveMoney.png";

const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9.]+$/;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

function Upute(props) {

    const useConstructor = Util.useConstructor();

    const form = props.form;
    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;
    const global = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    useConstructor(() => {
        setLoading(false);
    });

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1} style={{maxWidth:"60vw"}}>
                <Grid item xs={12} style={{textAlign:"left", marginBottom:"15px"}}>
                    <Button variant="outlined" onClick={() => {!global.loggedOutComponent || global.loggedOutComponent == "login" ? window.location = "#/login" : window.location = "#/registracija"}} startIcon={<ArrowBackIcon></ArrowBackIcon>}>{!global.loggedOutComponent || global.loggedOutComponent == "login" ? "Prijava" : "Registracija"}</Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            <AutoAwesomeIcon fontSize="medium" style={{marginRight:"15px"}}></AutoAwesomeIcon>
                            Upute
                        </div>
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify',
                            color: 'red'
                        }}>
                            Hvala Vam na interesu!
                        </div>
                    </Typography>                    
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            Korisničke upute možete pogledati/preuzeti <a href={upute} target="_blank">&nbsp;ovdje</a>.
                        </div>
                    </Typography>                    
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"right"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            Inače, mi smo:
                        </div>
                    </Typography>                    
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"left"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            IPT d.o.o.
                        </div>
                    </Typography>   
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h5"  component="div" style={{textAlign:"right"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            Nalazimo se na adresi:
                        </div>
                    </Typography>                    
                </Grid>
                <Grid item xs={3}>
                    <Tooltip title={<div style={{fontSize:'larger'}}>Dođite, posjetite nas, možemo organizirati prezentaciju Chate za Vas bez naknade!</div>} arrow>
                        <Typography variant="h5"  component="div" style={{textAlign:"left"}}>
                            <div style={{
                                display: 'inline-flex',
                                verticalAlign: 'text-bottom',
                                boxSizing: 'inherit',
                                textAlign: 'justify',
                                alignItems: 'justify'
                            }}>
                                Miramarska 24, Zagreb
                            </div>
                        </Typography>   
                    </Tooltip>
                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5"  component="div" style={{textAlign:"right"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            Možete nas dobiti direktno na telefon:
                        </div>
                    </Typography>                    
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5"  component="div" style={{textAlign:"left"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            01/6005-605
                        </div>
                    </Typography>   
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h5"  component="div" style={{textAlign:"right"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            ili putem e-maila:
                        </div>
                    </Typography>                    
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h5"  component="div" style={{textAlign:"left"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            chato@ipt.hr
                        </div>
                    </Typography>   
                </Grid>

                <Grid item xs={6}>
                    <Typography  component="div" style={{textAlign:"right"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            Naš OIB je:
                        </div>
                    </Typography>                    
                </Grid>
                <Grid item xs={6}>
                    <Typography component="div" style={{textAlign:"left"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            74377537525
                        </div>
                    </Typography>   
                </Grid>

                <Grid item xs={6} style={{paddingTop:'0px'}}>
                    <Typography component="div" style={{textAlign:"right"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            Registrirani smo:
                        </div>
                    </Typography>                    
                </Grid>
                <Grid item xs={6} style={{paddingTop:'0px'}}>
                    <Typography component="div" style={{textAlign:"left"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            pri Trgovačkom sudu u Zagrebu
                        </div>
                    </Typography>   
                </Grid>

                <Grid item xs={6} style={{paddingTop:'0px'}}>
                    <Typography component="div" style={{textAlign:"right"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            s temeljnim kapitalom od:
                        </div>
                    </Typography>                    
                </Grid>
                <Grid item xs={6} style={{paddingTop:'0px'}}>
                    <Typography component="div" style={{textAlign:"left"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            20.000 starih kuna ili 2.654,46 eura uz fiksni tečaj konverzije 7.5345
                        </div>
                    </Typography>   
                </Grid>

                <Grid item xs={6} style={{paddingTop:'0px'}}>
                    <Typography component="div" style={{textAlign:"right"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            a direktor je:
                        </div>
                    </Typography>                    
                </Grid>
                <Grid item xs={6} style={{paddingTop:'0px'}}>
                    <Typography component="div" style={{textAlign:"left"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                            Hrvoje Rajić
                        </div>
                    </Typography>   
                </Grid>

                <Grid item xs={6} style={{paddingTop:'0px'}}>
                    <Typography component="div" style={{textAlign:"right"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify',
                            fontSize:'smaller'
                        }}>
                            Ako nam želite uplatiti neke novce to možete učiniti na IBAN:
                        </div>
                    </Typography>                    
                </Grid>
                <Grid item xs={6} style={{paddingTop:'0px'}}>
                    <Typography component="div" style={{textAlign:"left"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify',
                            fontSize:'smaller'
                        }}>
                            HR1924840081100203980 (Raiffeisenbank Austria d.d.)
                        </div>
                    </Typography>   
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginTop:'15px'}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                           Mi ozbiljno mislimo:
                        </div>
                    </Typography>                    
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'justify',
                            alignItems: 'justify'
                        }}>
                           <img src={slogan}></img>
                        </div>
                    </Typography>                    
                </Grid>

            </Grid>

        </ComponentContainer>
    );
}

export default Upute;