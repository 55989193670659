import { ClickAwayListener, Grid, Modal, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import api from "../util/api";
import Util from "../util/util";
import ComponentContainer from "./componentContainer";
import globalContext from "./globalContext";
import ClassIcon from '@mui/icons-material/Class';
import { Box } from "@mui/system";
import AddEditDelForm from "./addEditDelForm";
import NepredmetnaKategorijaForm from "./nepredmetnaKategorijaForm";
import AlertDialog from "./alertdialog";
import ErrorAlert from "./errorAlert";
import ErrorIcon from '@mui/icons-material/Error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #000',
    p: 6,
    maxHeight: '80%'
};

function NepredmetneKategorije(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const [loading, setLoading] = useState(true);
    const [nepredmetneKategorije, setNepredmetneKategorije] = useState([]);
    const [currentKategorija, setCurrentKategorija] = useState(null);
    const [deleteAlert, setDeleteAlert] = useState(false);
    const [formOpened, setFormOpened] = useState(false);
    const [formKategorija, setFormKategorija] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    useConstructor(() => {
        loadKategorije();
    });

    function loadKategorije() {
        setLoading(true);
        api.get("nepredmetnaKategorija/" + global.user.firmaID, loadKategorijeSuccess, loadKategorijeFail);
    }

    function loadKategorijeSuccess(data) {
        setNepredmetneKategorije(data);
        setLoading(false);
    }

    function loadKategorijeFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function add() {
        setFormKategorija(null);
        setFormOpened(true);
    }

    function edit() {
        setFormKategorija(currentKategorija);
        setFormOpened(true);
    }

    function deleteKategorija() {
        api.post("nepredmetnaKategorija/delete/" + currentKategorija.NepredmetnaKategorijaID, null, deleteSuccess, deleteFail);
    }

    function deleteSuccess() {
        setLoading(false);
        setDeleteAlert(false);
        loadKategorije();
    }
    
    function deleteFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setDeleteAlert(false);
        setLoading(false);
    }

    return (
        <ComponentContainer loading={loading || global.loading}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Typography variant="h4" gutterBottom component="div" style={{marginBottom:"15px"}}>
                            <ClassIcon fontSize="medium" style={{marginRight:"15px"}}></ClassIcon>
                            Nepredmetne kategorije
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ClickAwayListener onClickAway={() => setCurrentKategorija(null)}>
                            <Box style={{width:"100%", marginTop:"20px"}}>
                                <AddEditDelForm dataType="nepredmetneKategorije" data={nepredmetneKategorije} current={currentKategorija} setCurrent={setCurrentKategorija} add={add} edit={edit} del={() => setDeleteAlert(true)}></AddEditDelForm>
                            </Box>
                        </ClickAwayListener>
                    </Grid>
                </Grid>

                <Modal open={formOpened}>
                    <Box sx={style} overflow={"auto"} >
                        <NepredmetnaKategorijaForm loadKategorije={loadKategorije} firmaID={global.user.firmaID} formKategorija={formKategorija} setFormOpened={setFormOpened} ></NepredmetnaKategorijaForm>
                    </Box>
                </Modal>

                {deleteAlert ? <AlertDialog title="Brisanje nepredmetne kategorije" message={"Jeste li sigurni da želite obrisati odabranu nepredmetnu kategoriju?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => setDeleteAlert(false)} confirmAction={deleteKategorija}></AlertDialog> : null}
            </Grid>

            <Modal className="modal" open={errorMessage != null}>
                <Box sx={style}>
                    <ErrorAlert message={errorMessage} close={() => setErrorMessage(null)}></ErrorAlert>
                </Box>
            </Modal>
        </ComponentContainer>
    );
}

export default NepredmetneKategorije;