import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, Grid, InputAdornment, TextField, Tooltip, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ComponentContainer from "./componentContainer";
import EditIcon from '@mui/icons-material/Edit';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { hr } from "date-fns/locale";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Util from "../util/util";
import api from "../util/api";
import globalContext from "./globalContext";
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';
import SaveIcon from "@mui/icons-material/Save";
import ExitToApp from "@mui/icons-material/ExitToApp";
import { TwentyZeroMpRounded } from "@mui/icons-material";

export default function ZadatakForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const formZadatak = props.formZadatak;
    const klijenti = props.klijenti;
    const predmeti = props.predmeti;
    const close = props.close;
    const filter = props.filter;
    const uloga = props.uloga;
    const reviewOnly = props.reviewOnly;

    const [loading, setLoading] = useState(true);
    const [opis, setOpis] = useState("");
    const [klijent, setKlijent] = useState(null);
    const [predmet, setPredmet] = useState(null);
    const [pocetak, setPocetak] = useState(null);
    const [kraj, setKraj] = useState(null);
    const [pocetakExists, setPocetakExists] = useState(false);
    const [krajExists, setKrajExists] = useState(false);
    const [vrsta, setVrsta] = useState(null);
    const [prioritet, setPrioritet] = useState(null);
    const [postotak, setPostotak] = useState(0);
    const [status, setStatus] = useState(null);
    const [remind, setRemind] = useState(true);
    const [remindMinsBefore, setRemindMinsBefore] = useState(15);
    const [vrste, setVrste] = useState([]);
    const [statusi, setStatusi] = useState([]);
    const [prioriteti, setPrioriteti] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [korisniciIDs, setKorisniciIDs] = useState([]);
    const [korisnici, setKorisnici] = useState([]);
    const [formValid, setFormValid] = useState(false);

    useConstructor(() => {
        api.get("zadatak/getforminfo/" + global.user.korisnikID, loadInfoSuccess, fail);
    });

    function loadInfoSuccess(data) {
        setVrste(data.Vrste);
        setPrioriteti(data.Prioriteti);
        setStatusi(data.Statusi.filter(s => s.TipZadatakStatusID != 4));
        setKorisnici(data.Korisnici);
        if (formZadatak) {
            setOpis(formZadatak.Opis);
            if (formZadatak.KlijentID) {
                setKlijent(klijenti.find(k => k.KlijentID == formZadatak.KlijentID));
            }
            if (formZadatak.PredmetID) {
                setPredmet(predmeti.find(p => p.PredmetID == formZadatak.PredmetID));
            }
            setStatus(data.Statusi.find(s => s.TipZadatakStatusID == formZadatak.TipZadatakStatusID));
            setVrsta(data.Vrste.find(s => s.TipZadatakVrstaID == formZadatak.TipZadatakVrstaID));
            setPrioritet(data.Prioriteti.find(s => s.TipZadatakPrioritetID == formZadatak.TipZadatakPrioritetID));
            if (formZadatak.Pocetak) {
                setPocetakExists(true);
                setPocetak(new Date(formZadatak.Pocetak));
            } else {
                setPocetakExists(false);
            }
            if (formZadatak.Kraj) {
                setKrajExists(true);
                setKraj(new Date(formZadatak.Kraj));
            } else {
                setKrajExists(false);
            }
            if (formZadatak.RemindMinutesBefore != null) {
                setRemind(true);
                setRemindMinsBefore(formZadatak.RemindMinutesBefore);
            } else {
                setRemind(false);
            }
            setPostotak(formZadatak.PostotakZavrsenosti);
            setKorisniciIDs(formZadatak.KorisniciIDs);
        } else {
            if (uloga == 0) {
                let newKorisniciIDs = [];
                newKorisniciIDs.push(parseInt(global.drugiZaposlenik ? global.drugiZaposlenik.KorisnikID : global.user.korisnikID));
                setKorisniciIDs(newKorisniciIDs);
            }
            setStatus(data.Statusi.find(s => s.TipZadatakStatusID == 1));
        }
        setLoading(false);
    }

    function fail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleStatusChange(v) {
        setStatus(v);
        if (v.TipZadatakStatusID == 1) {
            setPostotak(0);
        } else if (v.TipZadatakStatusID == 3) {
            setPostotak(100);
        }
    }

    useEffect(() => {
        let valid = opis
                    && vrsta 
                    && status 
                    && prioritet 
                    && !(vrsta.TipZadatakVrstaID == 3 && (postotak == null || isNaN(parseInt(postotak)) || !Number.isInteger(Number(postotak)) || Number(postotak) < 0 || Number(postotak) > 100))
                    && !(remind && (remindMinsBefore == null || isNaN(parseInt(remindMinsBefore)) || !Number.isInteger(Number(remindMinsBefore)) || Number(remindMinsBefore) < 0))
                    && korisniciIDs && korisniciIDs.length > 0
                    && !(pocetakExists && (pocetak == null || isNaN(new Date(pocetak))))
                    && !(krajExists && (kraj == null || isNaN(new Date(kraj))))
                    && !(pocetakExists && krajExists && (new Date(pocetak) > new Date(kraj)))
        setFormValid(valid);
    }, [opis, vrsta, status, prioritet, postotak, remind, remindMinsBefore, korisniciIDs, pocetakExists, pocetak, krajExists, kraj]);

    function save() {
        setLoading(true);
        let resource = {
            ZadatakID: formZadatak ? formZadatak.ZadatakID : -1,
            ZadavateljKorisnikID: formZadatak ? formZadatak.ZadavateljKorisnikID : (global.drugiZaposlenik ? global.drugiZaposlenik.KorisnikID : global.user.korisnikID),
            DatumVrijemeZadavanja: formZadatak ? formZadatak.DatumVrijemeZadavanja : new Date(),
            KlijentID: klijent ? klijent.KlijentID : null,
            PredmetID: predmet ? predmet.PredmetID : null,
            Opis: opis,
            TipZadatakStatusID: status.TipZadatakStatusID,
            Pocetak: pocetakExists ? pocetak : null,
            Kraj: krajExists ? kraj : null,
            TipZadatakPrioritetID: prioritet.TipZadatakPrioritetID,
            RemindMinutesBefore: remind ? remindMinsBefore : null,
            TipZadatakVrstaID: vrsta.TipZadatakVrstaID,
            PostotakZavrsenosti: vrsta.TipZadatakVrstaID == 3 ? postotak : null,
            KorisniciIDs: korisniciIDs,
            ReminderSent: formZadatak ? formZadatak.ReminderSent : 0,
            FinalReminderSent: formZadatak ? formZadatak.FinalReminderSent : 0
        }
        api.post("zadatak/save", resource, saveSuccess, fail);
    }

    function saveSuccess() {
        filter();
        close();
    }

    return (
        <ComponentContainer loading={loading}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                        <div style={{
                            display: 'inline-flex',
                            verticalAlign: 'text-bottom',
                            boxSizing: 'inherit',
                            textAlign: 'center',
                            alignItems: 'center'
                        }}>
                            {formZadatak ? (
                                <EditIcon fontSize="medium" style={{marginRight:"15px"}}></EditIcon>
                            ) : (
                                <AddTaskIcon fontSize="medium" style={{marginRight:"15px"}}></AddTaskIcon>
                            )}
                            {formZadatak ? (
                                "Uređivanje zadatka"
                            ) : (
                                "Novi zadatak"
                            )}
                        </div>
                    </Typography>
                </Grid>
                {formZadatak ? (
                    <Grid item xs={12} style={{fontSize:"13pt"}} marginBottom="5px">
                        &nbsp;&nbsp;&nbsp;Zadatak zadao/la: {formZadatak.ZadavateljIme}
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField value={opis} disabled={formZadatak && (global.drugiZaposlenik ? global.drugiZaposlenik.KorisnikID : global.user.korisnikID) != formZadatak.ZadavateljKorisnikID || reviewOnly} onChange={e => setOpis(e.target.value)} error={!opis} multiline rows={4} size="small" label="Opis"></TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete 
                            disablePortal
                            autoHighlight
                            options={klijenti}
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            value={klijent}
                            disabled={formZadatak}
                            onChange={(e,v) => {
                                setPredmet(null);
                                setKlijent(v);
                            }}
                            renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={"klijent" + option.KlijentID} style={{color: option.TipStatusaKlijentaID == 1 || option.KlijentID == 0 ? "black" : "red"}}>
                                        {option.Broj + " - " + option.Naziv}
                                      </li>
                                    );
                                }}                            
                            filterOptions={(options, state) => options.filter(option => (option.Broj + " - " + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            renderInput={(params) => <TextField {...params} sx={{input:{color: klijent && klijent.TipStatusaKlijentaID != 1 && klijent.KlijentID != 0 ? "red" : "black"}}} label="Klijent"  size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete 
                            disablePortal
                            autoHighlight
                            options={klijent ? predmeti.filter(p => p.KlijentID == klijent.KlijentID) : []}
                            getOptionLabel={(option) => option.Broj + " - " + option.Naziv}
                            value={predmet}
                            disabled={formZadatak || !klijent}
                            onChange={(e,v) => setPredmet(v)}
                            renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={"predmet" + option.PredmetID} style={{color: option.TipStanjaPredmetaID == 1 || option.PredmetID == 0 ? "black" : "red"}}>
                                        {option.Broj + " - " + option.Naziv}
                                      </li>
                                    );
                                }}                            
                            filterOptions={(options, state) => options.filter(option => (option.Broj + " - " + option.Naziv).toLowerCase().includes(state.inputValue.toLowerCase()))}
                            renderInput={(params) => <TextField {...params} sx={{input:{color: predmet && predmet.TipStanjaPredmetaID != 1 && predmet.PredmetID != 0 ? "red" : "black"}}} label="Predmet"  size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={2} style={{textAlign:"center"}}>
                    <FormControlLabel 
                        labelPlacement="end" 
                        label="Početak"
                        disabled={formZadatak && (global.drugiZaposlenik ? global.drugiZaposlenik.KorisnikID : global.user.korisnikID) != formZadatak.ZadavateljKorisnikID || reviewOnly}
                        control={<Checkbox checked={pocetakExists} onChange={e => {
                            if (!e.target.checked) {
                                setPocetak(null);
                            }
                            setPocetakExists(e.target.checked)
                        }}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
                        <DateTimePicker onChange={v => setPocetak(v)} label="Početak" disabled={!pocetakExists || (formZadatak && (global.drugiZaposlenik ? global.drugiZaposlenik.KorisnikID : global.user.korisnikID) != formZadatak.ZadavateljKorisnikID) || reviewOnly} value={pocetak} inputFormat="dd.MM.yyyy HH:mm" renderInput={(params) => <TextField {...params} style={{width:"100%"}} size="small" error={pocetakExists && (!pocetak || isNaN(new Date(pocetak))) || (pocetak && kraj && !isNaN(new Date(pocetak)) && !isNaN(new Date(kraj)) && new Date(pocetak) > new Date(kraj))} helperText={pocetak && kraj && !isNaN(new Date(pocetak)) && !isNaN(new Date(kraj)) && new Date(pocetak) > new Date(kraj) ? "Početak ne može biti prije kraja." : ""} />} /> 
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6} sm={6} md={2} style={{textAlign:"center"}}>
                    <FormControlLabel 
                        labelPlacement="end" 
                        label="Kraj"
                        disabled={formZadatak && (global.drugiZaposlenik ? global.drugiZaposlenik.KorisnikID : global.user.korisnikID) != formZadatak.ZadavateljKorisnikID || reviewOnly}
                        control={<Checkbox checked={krajExists} onChange={e => {
                            if (!e.target.checked) {
                                setKraj(null);
                            }
                            setKrajExists(e.target.checked)
                        }}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
                        <DateTimePicker onChange={v => setKraj(v)} label="Kraj" disabled={!krajExists || (formZadatak && (global.drugiZaposlenik ? global.drugiZaposlenik.KorisnikID : global.user.korisnikID) != formZadatak.ZadavateljKorisnikID) || reviewOnly} value={kraj} inputFormat="dd.MM.yyyy HH:mm" renderInput={(params) => <TextField {...params} style={{width:"100%"}} size="small" error={krajExists && (!kraj || isNaN(new Date(kraj))) || (pocetak && kraj && !isNaN(new Date(pocetak)) && !isNaN(new Date(kraj)) && new Date(pocetak) > new Date(kraj))} helperText={pocetak && kraj && !isNaN(new Date(pocetak)) && !isNaN(new Date(kraj)) && new Date(pocetak) > new Date(kraj) ? "Kraj ne može biti prije početka." : ""} />} /> 
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete 
                            disablePortal
                            autoHighlight
                            disableClearable
                            options={vrste}
                            disabled={formZadatak}
                            getOptionLabel={(option) => option.Naziv}
                            value={vrsta}
                            onChange={(e,v) => setVrsta(v)}
                            renderOption={(props, option) => {
                                    return (
                                        <li {...props} id={"vrsta" + option.TipZadatakVrstaID}>
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <div style={{display:"flex", alignItems:"center", height:"100%"}}>
                                                        {option.Naziv}
                                                    </div>
                                                </Grid>
                                                <Tooltip title={option.Opis} enterDelay={200} enterNextDelay={200}>
                                                    <Grid item xs={2} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                        <HelpOutlineIcon></HelpOutlineIcon>
                                                    </Grid>
                                                </Tooltip>
                                            </Grid>
                                        </li>
                                    );
                                }}                            
                            renderInput={(params) => <TextField {...params} label="Vrsta" error={!vrsta} size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={vrsta && vrsta.TipZadatakVrstaID == 3 ? 6 : 12} sm={vrsta && vrsta.TipZadatakVrstaID == 3 ? 6 : 12} md={vrsta && vrsta.TipZadatakVrstaID == 3 ? 3 : 6}>
                    <FormControl fullWidth>
                        <Autocomplete 
                            disablePortal
                            autoHighlight
                            disableClearable
                            disabled={!formZadatak || reviewOnly}
                            options={vrsta && vrsta.TipZadatakVrstaID == 1 ? statusi.filter(s => s.TipZadatakStatusID != 2) : statusi}
                            getOptionLabel={(option) => option.Naziv}
                            value={status}
                            onChange={(e,v) => handleStatusChange(v)}                     
                            renderInput={(params) => <TextField {...params} label="Status" error={!status} size="small"/>}
                        />
                    </FormControl>
                </Grid>
                {vrsta && vrsta.TipZadatakVrstaID == 3 ? (
                    <Grid item xs={6} sm={6} md={3}>
                        <FormControl fullWidth>
                            <TextField value={postotak} type="number" disabled={(status && status.TipZadatakStatusID == 1) || reviewOnly} size="small" label="Postotak završenosti" InputProps={{endAdornment:(<InputAdornment position="end">%</InputAdornment>), inputProps: { min: 0, max: 100 }}} onChange={e => setPostotak(e.target.value)} error={postotak == null || isNaN(Number(postotak)) || !Number.isInteger(Number(postotak)) || Number(postotak) < 0 || Number(postotak) > 100}></TextField>
                        </FormControl>
                    </Grid>
                ) : null}
                <Grid item xs={12} sm={12} md={6}>
                    <FormControl fullWidth>
                        <Autocomplete 
                            disablePortal
                            autoHighlight
                            disableClearable
                            disabled={formZadatak && (global.drugiZaposlenik ? global.drugiZaposlenik.KorisnikID : global.user.korisnikID) != formZadatak.ZadavateljKorisnikID || reviewOnly}
                            options={prioriteti}
                            getOptionLabel={(option) => option.Naziv}
                            value={prioritet}
                            onChange={(e,v) => setPrioritet(v)}                     
                            renderInput={(params) => <TextField {...params} label="Prioritet" error={!prioritet} size="small"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} md={2} style={{textAlign:"center"}}>
                    <FormControlLabel 
                        labelPlacement="end" 
                        label="Podsjetnik"
                        disabled={formZadatak && (global.drugiZaposlenik ? global.drugiZaposlenik.KorisnikID : global.user.korisnikID) != formZadatak.ZadavateljKorisnikID || reviewOnly}
                        control={<Checkbox checked={remind} onChange={e => {
                            setRemind(e.target.checked)
                        }}></Checkbox>}>
                    </FormControlLabel>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    {remind ? (
                        <FormControl fullWidth>
                            <TextField value={remindMinsBefore} disabled={formZadatak && (global.drugiZaposlenik ? global.drugiZaposlenik.KorisnikID : global.user.korisnikID) != formZadatak.ZadavateljKorisnikID || reviewOnly} type="number" size="small" InputProps={{endAdornment:(<InputAdornment position="end">min ranije</InputAdornment>)}} onChange={e => setRemindMinsBefore(e.target.value)} error={remindMinsBefore == null || isNaN(Number(remindMinsBefore)) || !Number.isInteger(Number(remindMinsBefore)) || Number(remindMinsBefore) < 0}></TextField>
                        </FormControl>
                    ) : null}
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Autocomplete  
                            multiple
                            value={korisnici && korisniciIDs ? korisnici.filter(k => korisniciIDs.includes(k.KorisnikID)) : []}
                            onChange={(e,v) => {
                                setKorisniciIDs(v.map(k => k.KorisnikID));
                            }}
                            disabled={formZadatak && (global.drugiZaposlenik ? global.drugiZaposlenik.KorisnikID : global.user.korisnikID) != formZadatak.ZadavateljKorisnikID || reviewOnly}
                            options={global.drugiZaposlenik && global.drugiZaposlenik.Prava.includes(24) ? korisnici.filter(k => k.Prava.includes(23)) : korisnici.filter(k => k.KorisnikID == (global.drugiZaposlenik ? global.drugiZaposlenik.KorisnikID : global.user.korisnikID))} 
                            getOptionLabel={(option) => option.Ime + " " + option.Prezime} 
                            autoHighlight
                            renderInput={(params) => <TextField {...params} label="Izvršitelji" size="small" error={korisniciIDs.length == 0} />} 
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} style={{textAlign:"right", marginTop:"15px"}}>
                    <Button onClick={save} startIcon={<SaveIcon></SaveIcon>} variant="contained" style={{ marginRight: "10px" }} disabled={!formValid || reviewOnly} color="success">
                        Spremi
                    </Button>
                    <Button variant="outlined" startIcon={<ExitToApp></ExitToApp>} color="error" onClick={close} >
                        Odustani
                    </Button>
                </Grid>
            </Grid>

            {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
        </ComponentContainer>
    );
}