import { useState, useRef, useContext, useEffect, useCallback, Fragment } from "react";
import { Line } from "@ant-design/charts";
import { AccessAlarm, Label, SettingsRemoteRounded, ThreeDRotation } from "@mui/icons-material";
import api from "../util/api";
import Util from "../util/util";
import GlobalContext from "../components/globalContext";
import ComponentContainer from "../components/componentContainer";
import TextField from "@mui/material/TextField";
import { ButtonGroup, Button, Divider, FormControl, FormGroup, InputAdornment, ListItem, ListItemText, Paper, Stack, Tooltip, List, Modal, Typography, ListItemButton, makeStyles, IconButton, FormLabel, ListItemIcon } from "@mui/material";
import Box from "@mui/material/Box";
import database from "../util/database";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Grid from "@mui/material/Grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { hr } from "date-fns/locale";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Fade from "@mui/material/Fade";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Autocomplete from "@mui/material/Autocomplete";
import UnosAktivnostiPredmetiForm from "./unosAktivnostiPredmetiForm";
import SaveIcon from "@mui/icons-material/Save";
import ExitToApp from "@mui/icons-material/ExitToApp";
import LineIcon from "react-lineicons";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import EditIcon from "@mui/icons-material/Edit";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AlertDialog from "./alertdialog";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import TollIcon from '@mui/icons-material/Toll';
import UnosTroskaForm from "./unosTroskaForm";
import BrziUnosTroskaForm from "./brziUnosTroskaForm";
import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import ErrorIcon from '@mui/icons-material/Error';
import ZadatakEvidencija from "./zadatakEvidencija";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "white",
    border: "2px solid #000",
    p: 6,
    maxHeight: "80%",
    minWidth: "60%",
};

function UnosAktivnostiForm(props) {
    const useConstructor = Util.useConstructor();
    const isMobileDevice = Util.isMobileDevice();

    const predmeti = props.predmeti;
    const nepredmetneKategorije = props.nepredmetneKategorije;
    //const predlosciResources = props.predlosci;
    const valute = props.firmaValute;
    const aktivnostDefTrajanjeMin = props.aktivnostDefTrajanjeMin;
    const setTitleVisible = props.setTitleVisible;
    const minsButtons = props.minsButtons;
    const omoguciPohrani = props.omoguciPohrani;
    const brojRedovaPredlozaka = props.brojRedovaPredlozaka || 0;
    const evidencijaUPrenesenoRazdobljeMessage = props.evidencijaUPrenesenoRazdobljeMessage;
    const unsavedChanges = props.unsavedChanges;

    const [date, setDate] = useState(new Date(props.date));
    var formEvidencija = props.evidencija;
    const [formEvidencijaDetalj, setFormEvidencijaDetalj] = useState(null);
    const korisnikID = props.korisnikID;
    const setFormOpened = props.setFormOpened;
    const setCanceled = props.setCanceled;
    const global = useContext(GlobalContext);
    const [loading, setLoading] = useState(true);
    const [someList, setSomeList] = useState([1, 2, 3]);
    const [opis, setOpis] = useState("");
    const [nepredmetnaKategorijaID, setNepredmetnaKategorijaID] = useState(0);
    const [nepredmetnaKategorija, setNepredmetnaKategorija] = useState(null);
    const [datum, setDatum] = useState(null);
    const [datumOd, setDatumOd] = useState(null);
    const [datumDo, setDatumDo] = useState(null);
    const [trajanje, setTrajanje] = useState(0);
    const [klijenti, setKlijenti] = useState([]);
    const [selectedKlijent, setSelectedKlijent] = useState(null);
    const [selectedPredmet, setSelectedPredmet] = useState(null);
    const [klijentovVoditelj, setKlijentovVoditelj] = useState(null);
    const [tipNaplatnostiOptions, setTipNaplatnostiOptions] = useState([]);
    const [tipOporezivostiOptions, setTipOporezivostiOptions] = useState([]);
    const [tipNaplatnosti, setTipNaplatnosti] = useState(null);
    const [tipOporezivosti, setTipOporezivosti] = useState(null);
    const [selectedKontakt, setSelectedKontakt] = useState(null);
    const [kontakti, setKontakti] = useState([]);
    const [tarifa, setTarifa] = useState(null);
    const [iznos, setIznos] = useState(null);
    const [valuta, setValuta] = useState(null);
    const [predlosci, setPredlosci] = useState([]);
    const [predlozakEvidencija, setPredlozakEvidencija] = useState(null);
    const [predlozakDetalji, setPredlozakDetalji] = useState([]);
    const [predlozakEvidencijaDetalj, setPredlozakEvidencijaDetalj] = useState(null);
    const [newPredlozakPozicija, setNewPredlozakPozicija] = useState(null);
    const [createPredlozak, setCreatePredlozak] = useState(false);
    const [nazivPredloska, setNazivPredloska] = useState("");
    const [dodavanjePredmeta, setDodavanjePredmeta] = useState(false);
    const [dodaniPredmeti, setDodaniPredmeti] = useState([]);
    const [currentPredmet, setCurrentPredmet] = useState(null);
    const [datumErrorMessage, setDatumErrorMessage] = useState("");
    const [edited, setEdited] = useState(true);
    const [formValid, setFormValid] = useState(false);
    const [obrisaniDetaljiIDs, setObrisaniDetaljiIDs] = useState([]);
    const [pocetakRadaDisabled, setPocetakRadaDisabled] = useState(false);
    const [krajRadaDisabled, setKrajRadaDisabled] = useState(false);
    const [datoteka, setDatoteka] = useState(null);
    const [datotekaNaziv, setDatotekaNaziv] = useState("");
    const [dokumentNaziv, setDokumentNaziv] = useState("");
    const [file, setFile] = useState([]);
    const [contentType, setContentType] = useState("");
    const [fileSize, setFileSize] = useState(0);
    const [deletePredlozakAlert, setDeletePredlozakAlert] = useState(false);
    const [sati, setSati] = useState(0);
    const [minute, setMinute] = useState(0);
    const [pohranjeno, setPohranjeno] = useState(false);
    const [trosakOpened, setTrosakOpened] = useState(false);
    const [trosakDetalji, setTrosakDetalji] = useState([]);
    const [elektronickaArhivaDostupnoMiB, setElektronickaArhivaDostupnoMiB] = useState(0);
    const [elektronickaArhivaDostupnoMiBOriginal, setElektronickaArhivaDostupnoMiBOriginal] = useState(0);
    const [elektronickaArhivaMiBPerFile, setElektronickaArhivaMiBPerFile] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [zadaciOpened, setZadaciOpened] = useState(false);
    const [povezaniZadaci, setPovezaniZadaci] = useState([]);
    const [zadatakKlijentID, setZadatakKlijentID] = useState(null);
    const [dodavanjePredmetaMessage, setDodavanjePredmetaMessage] = useState("");
    const [evidencijaUPrenesenoRazdobljeAlert, setEvidencijaUPrenesenoRazdobljeAlert] = useState(false);

    useConstructor(() => {
        loadInfo();
    });

    const opisRef = useRef(null);

    function focusOnOpis() {
        setTimeout(() => {
            if (opisRef.current) {
                opisRef.current.focus();
            }
        }, 100);
    }

    const satiRef = useRef(null);

    function focusOnSati() {
        setTimeout(() => {
            if (satiRef.current) {
                //satiRef.current.blur();
                satiRef.current.focus();
            }
        }, 100);
    }

    const minuteRef = useRef(null);

    function loadInfo(newDate) {
        if (newDate) {
            formEvidencija = null;
        }
        setLoading(true);
        let resource = {
            KorisnikID: global.drugiZaposlenik.KorisnikID,
            Date: date || new Date(newDate),
        };

        api.post("evidencija/getinfoforunosaktivnostiform", resource, loadInfoSuccess, loadInfoFail);
    }

    // useEffect(() => {
    //     if (nepredmetneKategorije.length === 0 && props.nepredmetneKategorije) {
    //         let npk = [...props.nepredmetneKategorije];
    //         npk.unshift({ NepredmetnaKategorijaID: 0, Naziv: "Predmet" });
    //         setNepredmetneKategorije(npk);
    //         setKlijenti(props.klijenti);
    //         setPredmeti(props.predmeti);
    //     }
    // });

    useEffect(() => checkIfFormValid(), [datumOd, datumDo, opis, nepredmetnaKategorija, dodaniPredmeti]);

    useEffect(() => {
        let dostupno = elektronickaArhivaDostupnoMiBOriginal;

        if (dodaniPredmeti) {
            dodaniPredmeti.forEach(p => {
                if (p.Datoteka) {
                    dostupno = dostupno - (p.Datoteka.FileSize / (1024 * 1024));
                }
            });
        }

        setElektronickaArhivaDostupnoMiB(parseFloat(Util.toStringDecimal(dostupno)));
    }, [dodaniPredmeti]);

    useEffect(() => () => {     
        if(!datum){
            if(new Date(datum) != new Date(props.date)){
                setDatum(new Date(props.date));
            }
        }
    });

    function checkIfFormValid() {
        //ovo sam eksplicitno morao dovesti do booleana, jer u protivon varijabla valid nije nužno boolena s vrijednošću 'true', što se provjerava na setu state-a setFormValid
        let valid = datumOd != null && opis != '' && datumDo != null && datumOd < datumDo && (nepredmetnaKategorija !=null || dodaniPredmeti.length > 0);
        setFormValid(valid == true);
    }

    const handleKeyPressShorcuts = useCallback(
        (event) => {
            //event.preventDefault();
            if(!dodavanjePredmeta && !trosakOpened){
                if (event.altKey) {
                    let charCode = String.fromCharCode(event.which).toLowerCase();
                    if (charCode == "p") {
                        event.preventDefault();
                        if (!loading && !pohranjeno && datumOd && opis && datumDo && (nepredmetnaKategorija || dodaniPredmeti.length > 0)) {
                            save(null);
                        }
                    }
                    if (charCode == "h") {
                        event.preventDefault();
                        if (!loading && datumOd && opis && datumDo && (nepredmetnaKategorija || dodaniPredmeti.length > 0)) {
                            pohrani();
                        }
                    }
                    if (charCode == "d") {
                        event.preventDefault();
                        if (!loading && nepredmetnaKategorijaID === 0) {
                            setCurrentPredmet(null);
                            setDodavanjePredmeta(true);                    
                        }
                    }
                    if (charCode == "o") {
                        event.preventDefault();
                        if (!loading) {
                            setCanceled(true);
                            unsavedChanges.current = false;
                            setFormOpened(false);
                        }
                    }   
                    if (charCode == "t") {
                        event.preventDefault();
                        if (!loading && dodaniPredmeti.length > 0) {
                            trosak();
                        }
                    }              
                }                    
            }

        }, [
            datumOd,
            datumDo, 
            opis, 
            nepredmetnaKategorija, 
            dodaniPredmeti, 
            loading, 
            dodavanjePredmeta, 
            trosakOpened, 
            pohranjeno,
            formEvidencija,
            obrisaniDetaljiIDs,
            datoteka,
            povezaniZadaci,
            sati,
            minute
        ]
    );

    const handleEsc = useCallback(
        (event) => {

            if(!dodavanjePredmeta){
                if(event.keyCode == 27){
                    event.preventDefault();
                    if (!loading) {
                        setCanceled(true);
                        unsavedChanges.current = false;
                        setFormOpened(false);                 
                    }
                }                        
            }

        },
        [loading, dodavanjePredmeta]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPressShorcuts);
        document.addEventListener("keyup", handleEsc);

        return () => {
            document.removeEventListener("keydown", handleKeyPressShorcuts);
            document.removeEventListener("keyup", handleEsc);
        };
    }, [handleKeyPressShorcuts]);

    function loadInfoSuccess(data) {
        loadPredlosci(data.PredlozakEvidencijaList);

        //console.log("--start--");
        //console.log(data);

        if (formEvidencija) {
            setPocetakRadaDisabled(true);
            // ako se radi o striktnoj satnici onda treba provjeriti da li je ovo zadnja aktivnost u danu
            // jer ako nije onda i kraj rada mora biti disejblan
            if(global.user.tipPrikazaSatnice == 1 && data.TimeOfLastActivityOfTheDay && new Date(data.TimeOfLastActivityOfTheDay) > new Date(formEvidencija.KrajRada)){
                setKrajRadaDisabled(true);
            }
            setDatumOd(new Date(formEvidencija.PocetakRada));
            setDatumDo(new Date(formEvidencija.KrajRada));
            setOpis(formEvidencija.Opis);
            if (formEvidencija.EvidencijaDetalj) {
                setFormEvidencijaDetalj(formEvidencija.EvidencijaDetalj);
            }
            if (formEvidencija.Datoteka) {
                setDatotekaNaziv(formEvidencija.Datoteka.DokumentNaziv);
                setDokumentNaziv(formEvidencija.Datoteka.DokumentNaziv);
                setFile(formEvidencija.Datoteka.File);
                setContentType(formEvidencija.Datoteka.ContentType);
                setFileSize(formEvidencija.Datoteka.FileSize);
            }
            setPovezaniZadaci(formEvidencija.PovezaniZadaci);
            focusOnSati();
        } else {
            let start = date;

            if (global.user.tipPrikazaSatnice == 1 && data.TimeOfLastActivityOfTheDay) {
                start = new Date(data.TimeOfLastActivityOfTheDay);
                setPocetakRadaDisabled(true);
            } else if (global.user.tipPrikazaSatnice == 1 && data.RadnoVrijemeDefStart) {
                start.setHours(new Date(data.RadnoVrijemeDefStart).getHours());
                start.setMinutes(new Date(data.RadnoVrijemeDefStart).getMinutes());
                start.setSeconds(new Date(data.RadnoVrijemeDefStart).getSeconds());
                start.setMilliseconds(0);
            } else if (global.user.tipPrikazaSatnice == 1) {
                start.setHours(9);
                start.setMinutes(0);
                start.setSeconds(0);
                start.setMilliseconds(0);
            } else {
                start.setHours(0);
                start.setMinutes(0);
                start.setSeconds(0);
                start.setMilliseconds(0);
            }
            let end = new Date();
            if (props.zadatakPocetak
                && props.zadatakKraj 
                && props.tipZadatakVrstaID == 1
                && new Date(start).getDate() == new Date(new Date(start).getTime() + (new Date(props.zadatakKraj).getTime() - new Date(props.zadatakPocetak).getTime())).getDate()) {
                
                end = new Date(new Date(start).getTime() + (new Date(props.zadatakKraj).getTime() - new Date(props.zadatakPocetak).getTime()));
               
            } else if (aktivnostDefTrajanjeMin) {
                end = new Date(start.getTime() + aktivnostDefTrajanjeMin * 60000);
            } else {
                end = new Date(start.getTime() + 15 * 60000);
            }
            
            setDatumOd(start);
            setDatumDo(end);
            focusOnSati();
        }

        setElektronickaArhivaDostupnoMiB(data.ElektronickaArhivaDostupnoMiB);
        setElektronickaArhivaDostupnoMiBOriginal(data.ElektronickaArhivaDostupnoMiB);
        setElektronickaArhivaMiBPerFile(data.ElektroncikaArhivaMiBPerFile);

        if (props.povezaniZadaci) {
            setPovezaniZadaci(props.povezaniZadaci);
        }
        if (props.zadatakPredmeti) {
            setFormEvidencijaDetalj(props.zadatakPredmeti);
        }
        if (props.zadatakOpis) {
            setOpis(props.zadatakOpis);
        }

        if (props.pretragaPredmeti && props.pretragaPredmeti.length > 0) {
            setFormEvidencijaDetalj(props.pretragaPredmeti);
        }

        setLoading(false);
    }

    function loadInfoFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function loadPredlosci(list) {
        let predlosciList = new Array(brojRedovaPredlozaka * 8).fill(null);
        if (list) {
            list.forEach((p) => {
                predlosciList.splice(p.Pozicija - 1, 1, p);
            });
        }
        setPredlosci(predlosciList);
    }

    useEffect(() => loadFormEvidencijaDetaljData(), [formEvidencijaDetalj, nepredmetneKategorije]);

    function loadFormEvidencijaDetaljData() {
        if (formEvidencijaDetalj && nepredmetneKategorije) {
            if ((formEvidencija && formEvidencija.TipPredmetnostiID == 1) || (props.zadatakPredmeti && props.zadatakPredmeti.length > 0) || (props.pretragaPredmeti && props.pretragaPredmeti.length > 0)) {
                formEvidencijaDetalj.forEach((detalj) => {
                    let newPredmet = {
                        Naziv: detalj.Naziv,
                        DetaljID: detalj.EvidencijaDetaljID,
                        KlijentID: detalj.KlijentID,
                        ReferencaID: detalj.ReferencaID,
                        KontaktID: detalj.KlijentovVoditeljPredmetaKontaktID,
                        TipNaplatnostiID: detalj.TipNaplatnostiID,
                        TipOporezivostiID: detalj.TipOporezivostiID,
                        FirmaValutaID: detalj.FirmaValutaID,
                        PredmetTipStanjaPredmetaID: detalj.PredmetTipStanjaPredmetaID,
                        PredmetTipNaplateID: detalj.PredmetTipNaplateID,
                        Tarifa: detalj.Tarifa,
                        Iznos: detalj.Iznos,
                        Datoteka: detalj.Datoteka,
                        Edited: false,
                    };
                    setDodaniPredmeti((dodaniPredmeti) => [...dodaniPredmeti, newPredmet]);
                    if (!formEvidencija && detalj.PredmetTipNaplateID == 2 && !props.pretragaPredmeti) {
                        setCurrentPredmet(newPredmet);
                        setDodavanjePredmetaMessage("Odabrali ste kreiranje evidencije iz zadatka za koji je definiran predmet koji ima tip naplate \"Tarifa\", pa je stoga potrebno upisati detalje o naplati. Klikom na gumb \"Odustani\", poništava se kreiranje evidencije.");
                        setDodavanjePredmeta(true);
                    } else if (!formEvidencija && detalj.PredmetTipNaplateID == 2 && props.pretragaPredmeti) {
                        setCurrentPredmet(newPredmet);
                        setDodavanjePredmetaMessage("Odabrali ste kreiranje evidencije za predmet koji ima tip naplate \"Tarifa\", pa je stoga potrebno upisati detalje o naplati. Klikom na gumb \"Odustani\", poništava se kreiranje evidencije.");
                        setDodavanjePredmeta(true);
                    } else if (!formEvidencija && !detalj.ReferencaID) {
                        setDodavanjePredmeta(true);
                        setDodavanjePredmetaMessage("Odabrali ste kreiranje evidencije iz zadatka za koji je definiran klijent, ali ne i predmet, pa je stoga potrebno odabrati predmet. Klikom na gumb \"Odustani\", poništava se kreiranje evidencije.");
                        setZadatakKlijentID(detalj.KlijentID);
                    }
                });
            } else if (formEvidencija && formEvidencija.TipPredmetnostiID == 2) {
                findNepredmetnaKategorija();
            }
        }
    }

    function handleDatumChange(e) {
        //loadInfo(e);

        if (!isNaN(new Date(e))) {
            unsavedChanges.current = true;

            setDatum(new Date(e));
            const dt = new Date(e);
            const dtOd = new Date(datumOd);
            const dtDo = new Date(datumDo);
    
            const y = dt.getFullYear();
            const M = dt.getMonth();
            const d = dt.getDate();
    
            const hdtOd = dtOd.getHours();
            const mdtOd = dtOd.getMinutes();
    
            const hdtDo = dtDo.getHours();
            const mdtDo = dtDo.getMinutes();
    
            const s = 0;
    
            const newdtOd = new Date(y, M, d, hdtOd, mdtOd ,s);
            const newdtDo = new Date(y, M, d, hdtDo, mdtDo ,s);
            
            setDatumOd(newdtOd);
            setDatumDo(newdtDo);
            // if (props.onDateChange) props.onDateChange(e);
        }
    }

    function onDatumOdChange(e) {
        if (!isNaN(e)) {
            unsavedChanges.current = true;
            if (datumDo) {
                setDatumOd(e);
                if (e < datumDo) {
                    setDatumErrorMessage("");
                } else {
                    setDatumErrorMessage("Početak rada ne može biti nakon kraja rada!");
                }
            } else {
                setDatumOd(e);
                setDatumErrorMessage("");
            }
        }
    }

    function onDatumDoChange(e) {
        if (!isNaN(e)) {
            unsavedChanges.current = true;
            if (datumOd) {
                setDatumDo(e);
                if (e > datumOd) {
                    setDatumErrorMessage("");
                } else {
                    setDatumErrorMessage("Vrijeme kraja rada mora biti kasnije od vremena početka rada!");
                }
            } else {
                setDatumDo(e);
                setDatumErrorMessage("");
            }
        }
    }

    useEffect(() => getTrajanje(), [datumOd, datumDo]);

    function getTrajanje() {
        if (datumOd && datumDo) {
            if (isNaN(datumOd) || isNaN(datumDo)) {
                setTrajanje(0);
            }
            var diff = datumDo.getTime() - datumOd.getTime();
            setTrajanje(diff >= 0 ? Math.ceil(diff / 60000) : 0);
            setSati(Math.floor(parseInt(diff >= 0 ? Math.ceil(diff / 60000) : 0) / 60));
            setMinute(parseInt(diff >= 0 ? Math.ceil(diff / 60000) : 0) % 60);
        }
    }

    function onNepredmetnakategorijatChange(e, v) {
        unsavedChanges.current = true;
        if (v) {
            setNepredmetnaKategorijaID(v.NepredmetnaKategorijaID);
            setNepredmetnaKategorija(v);
            if (datoteka || datotekaNaziv != "") {
                setDatoteka(null);
                setDatotekaNaziv("");
            }
        } else {
            setNepredmetnaKategorijaID(0);
            setNepredmetnaKategorija(null);
        }
    }

    function onSave() {
        setDodavanjePredmeta(false);

        let data = {};
        if (props.onSave) {
            props.onSave(data);
        }
    }

    function stvoriPredlozak() {
        if (newPredlozakPozicija != null) {
            setNazivPredloska("");
            setCreatePredlozak(true);
        }
    }

    function urediPredlozak() {
        if (newPredlozakPozicija != null) {
            setNazivPredloska(predlosci[newPredlozakPozicija].Naziv);
            setCreatePredlozak(true);
        }
    }

    function spremiPredlozak() {
        setLoading(true);
        let predlozakDetaljList = [];
        if (dodaniPredmeti) {
            dodaniPredmeti.forEach((p) => {
                let newDetalj = {
                    PredlozakEvidencijaDetaljID: -1,
                    PredlozakEvidencijaID: -1,
                    ReferencaID: p.ReferencaID,
                    Tarifa: p.Tarifa,
                    Iznos: p.Iznos,
                    FirmaValutaID: p.FirmaValutaID,
                    TipNaplatnostiID: p.TipNaplatnostiID,
                    TipOporezivostiID: p.TipOporezivostiID,
                    KlijentovVoditeljPredmetaKontaktID: p.KontaktID,
                    PredmetTipNaplateID: p.TipPredmetnostiID == 2 ? 0 : p.PredmetTipNaplateID,
                    PredmetTipStanjaPredmetaID: p.TipPredmetnostiID == 2 ? 0 : p.PredmetTipStanjaPredmetaID
                };
                predlozakDetaljList.push(newDetalj);
            });
        }
        let newPredlozak = {
            Naziv: nazivPredloska,
            PredlozakEvidencijaID: -1,
            KorisnikID: global.drugiZaposlenik.KorisnikID,
            Pozicija: newPredlozakPozicija + 1,
            TipEvidencijeID: 1,
            TipPredmetnostiID: nepredmetnaKategorija ? 2 : 1,
            PocetakRada: datumOd,
            KrajRada: datumDo,
            Opis: opis,
            TrajanjeMin: trajanje,
            PredlozakEvidencijaDetalj: predlozakDetaljList,
        };
        api.post("evidencija/insertpredlozak", newPredlozak, spremiPredlozakSuccess, spremiPredlozakFail);
    }

    function spremiPredlozakSuccess() {
        setLoading(false);
        setCreatePredlozak(false);
        reloadPredlosciEvidencije();
    }

    function spremiPredlozakFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function deletePredlozak() {
        let resource = {
            KorisnikID: global.drugiZaposlenik.KorisnikID,
            Pozicija: newPredlozakPozicija + 1,
        };
        setLoading(true);
        api.post("evidencija/deletepredlozakevidencija", resource, deletePredlozakSuccess, deletePredlozakFail);
    }

    function deletePredlozakSuccess() {
        setDeletePredlozakAlert(false);
        reloadPredlosciEvidencije();
    }

    function deletePredlozakFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function onNazivPredloskaChange(e) {
        setNazivPredloska(e.target.value);
    }

    useEffect(() => {
        if (predlozakEvidencija) loadPredlozakData();
    }, [predlozakEvidencija]);

    function loadPredlozakEvidencijaDetalj() {
        api.get("evidencija/getpredlozakevidencijadetalj/" + predlozakEvidencija.PredlozakEvidencijaID, loadPredlozakEvidencijaDetaljSuccess, loadPredlozakEvidencijaDetaljFail);
    }

    function loadPredlozakEvidencijaDetaljSuccess(data) {
        setPredlozakEvidencijaDetalj(data);
    }

    function loadPredlozakEvidencijaDetaljFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    // useEffect(() => {
    //     alert(datumOd);
    // }, [datumOd])

    function loadPredlozakData() {
        setLoading(true);
        if (predlozakEvidencija) {
            if(global.user.tipPrikazaSatnice == 1){
                if(!krajRadaDisabled){
                    let dateStart = new Date(datumOd);
                    let difference = new Date(predlozakEvidencija.KrajRada) - new Date(predlozakEvidencija.PocetakRada);
                    let x = dateStart.getTime();
                    let addedDate = x + difference;
                    let adjustedDate = new Date(addedDate);
                    setDatumDo(adjustedDate);
                }
            }
            else{
                // let dateStart = new Date(date);
                let dateStart = new Date(datumOd);
                let hours = new Date(predlozakEvidencija.PocetakRada).getHours();
                let minutes = new Date(predlozakEvidencija.PocetakRada).getMinutes();
                dateStart.setHours(hours, minutes, 0, 0);
                setDatumOd(dateStart);
                // let dateEnd = new Date(date);
                let dateEnd = new Date(datumOd);
                hours = new Date(predlozakEvidencija.KrajRada).getHours();
                minutes = new Date(predlozakEvidencija.KrajRada).getMinutes();
                dateEnd.setHours(hours, minutes, 0, 0);
                setDatumDo(dateEnd);
            }
            setOpis(predlozakEvidencija.Opis);
            setDodaniPredmeti([]);
            if (predlozakEvidencija.TipPredmetnostiID == 1 && predlozakEvidencija.PredlozakEvidencijaDetalj) {
                setNepredmetnaKategorijaID(0);
                setNepredmetnaKategorija(null);
                predlozakEvidencija.PredlozakEvidencijaDetalj.forEach((detalj) => {
                    let newPredmet = {
                        Naziv: detalj.Naziv,
                        DetaljID: detalj.PredlozakEvidencijaDetaljID,
                        KlijentID: detalj.KlijentID,
                        ReferencaID: detalj.ReferencaID,
                        KontaktID: detalj.KlijentovVoditeljPredmetaKontaktID,
                        TipNaplatnostiID: detalj.TipNaplatnostiID,
                        TipOporezivostiID: detalj.TipOporezivostiID,
                        FirmaValutaID: detalj.FirmaValutaID,
                        Tarifa: detalj.Tarifa,
                        Iznos: detalj.Iznos,
                        Edited: true,
                        PredmetTipNaplateID: detalj.PredmetTipNaplateID,
                        PredmetTipStanjaPredmetaID: detalj.PredmetTipStanjaPredmetaID
                    };
                    setDodaniPredmeti((dodaniPredmeti) => [...dodaniPredmeti, newPredmet]);
                });
                setLoading(false);
            } else if (predlozakEvidencija.TipPredmetnostiID == 2) {
                findNepredmetnaKategorija();
            } else {
                setLoading(false);
            }
        }
    }

    function findValuta(valutaID) {
        if (valute) {
            valute.forEach((v) => {
                if (v.ValutaID == valutaID) {
                    setValuta(v);
                }
            });
        }
    }

    function findPredmetSuccess(predmet) {
        if (predmet) {
            setSelectedPredmet(predmet);
            findKlijent(predmet.KlijentID);
        }
        setLoading(false);
    }

    function findPredmetFail() {}

    function findNepredmetnaKategorija() {
        if (nepredmetneKategorije) {
            if (formEvidencijaDetalj) {
                nepredmetneKategorije.forEach((kategorija) => {
                    if (formEvidencijaDetalj.at(0) && kategorija.NepredmetnaKategorijaID == formEvidencijaDetalj.at(0).ReferencaID) {
                        setNepredmetnaKategorijaID(formEvidencijaDetalj.ReferencaID);
                        setNepredmetnaKategorija(kategorija);
                    }
                });
            } else if (predlozakEvidencijaDetalj) {
                nepredmetneKategorije.forEach((kategorija) => {
                    if (predlozakEvidencijaDetalj.at(0) && kategorija.NepredmetnaKategorijaID == predlozakEvidencijaDetalj.at(0).ReferencaID) {
                        setNepredmetnaKategorijaID(predlozakEvidencijaDetalj.ReferencaID);
                        setNepredmetnaKategorija(kategorija);
                    }
                });
            }
        }
        setLoading(false);
    }

    function findKlijent(klijentID) {
        if (klijenti) {
            klijenti.forEach((k) => {
                if (k.KlijentID == klijentID) {
                    setSelectedKlijent(k);
                }
            });
        }
    }

    const keyOptions = ["1", "2", "3", "4", "5", "6", "7", "8"];
    const keyShiftOptions = ["!", '"', "#", "$", "%", "&", "/", "("];

    const handleKeyPress = useCallback(
        (event) => {
            if (event.ctrlKey) {
                if (event.shiftKey) {
                    if (keyShiftOptions.includes(event.key)) {
                        //alert("ctrl shift + " + event.key);
                    }
                }
            } else if (event.altKey) {
                if (keyOptions.includes(event.key)) {
                    if (predlosci.at(parseInt(event.key - 1))) {
                        setPredlozakEvidencija(predlosci.at(parseInt(event.key - 1)));
                    }
                }
            }
        },
        [predlosci]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    function findPredlozak(key) {
        if (predlosci) {
            predlosci.forEach((predlozak) => {
                if (predlozak.Pozicija == parseInt(key)) {
                    setPredlozakEvidencija(predlozak);
                }
            });
        }
    }

    function handleDateChange(e) {
        if (props.onDateChange) props.onDateChange(e);
    }

    //useEffect(() => {if (currentPredmet) setDodavanjePredmeta(true)}, [currentPredmet]);
    useEffect(() => {
        if (!dodavanjePredmeta) setCurrentPredmet(null);
    }, [dodavanjePredmeta]);

    function save(action) {
        if (!loading && formValid) {
            setLoading(true);
            setCanceled(false);
            
            // zbog keyboard shortcuta - ako su sati ili minute i dalje fokusirani, ne dogada se onBlur, odnosno update statea datumDo, i promjene sati ili minuta se ne spremaju
            let newDatumDo = null;
            if (document.activeElement == minuteRef.current || document.activeElement == satiRef.current) {
                newDatumDo = new Date(datumOd.getTime() + (sati || 0) * 60 * 60 * 1000 + (minute || 0) * 60000);
            }
            
            let updatedDetalji = [];
            if (dodaniPredmeti.length > 0) {
                dodaniPredmeti.forEach((detalj) => {
                    if (detalj.Datoteka) {
                        detalj.Datoteka.Opis = opis;
                    }
                    let newDetalj = {
                        EvidencijaDetaljID: detalj.DetaljID,
                        EvidencijaID: formEvidencija ? formEvidencija.EvidencijaID : -1,
                        ReferencaID: detalj.ReferencaID,
                        Tarifa: detalj.Tarifa,
                        Iznos: detalj.Iznos,
                        FirmaValutaID: detalj.FirmaValutaID,
                        TipNaplatnostiID: detalj.TipNaplatnostiID,
                        TipOporezivostiID: detalj.TipOporezivostiID,
                        KlijentovVoditeljPredmetaKontaktID: detalj.KontaktID,
                        Datoteka: detalj.Datoteka
                    };
                    updatedDetalji.push(newDetalj);
                });
            } else if (nepredmetnaKategorija) {
                let newDetalj = {
                    EvidencijaDetaljID: formEvidencijaDetalj && !props.zadatakPredmeti ? formEvidencijaDetalj.at(0).EvidencijaDetaljID : -1,
                    EvidencijaID: formEvidencija ? formEvidencija.EvidencijaID : -1,
                    ReferencaID: nepredmetnaKategorija.NepredmetnaKategorijaID,
                    TipNaplatnostiID: 2,
                    TipOporezivostiID: 2,
                };
                updatedDetalji.push(newDetalj);
            }
            let updatedEvidencija = {
                EvidencijaID: formEvidencija ? formEvidencija.EvidencijaID : -1,
                KorisnikID: global.drugiZaposlenik.KorisnikID,
                TipEvidencijeID: 1,
                TipPredmetnostiID: nepredmetnaKategorija ? 2 : 1,
                PocetakRada: datumOd,
                KrajRada: newDatumDo == null || isNaN(new Date(newDatumDo)) ? datumDo : newDatumDo,
                Opis: opis,
                EvidencijaDetalj: updatedDetalji,
                ObrisaniDetaljiIDs: obrisaniDetaljiIDs,
                Datoteka: datoteka ? 
                    {
                          ArhivaFileID: formEvidencija && formEvidencija.Datoteka ? formEvidencija.Datoteka.ArhivaFileID : -1,
                          ArhivaID: formEvidencija && formEvidencija.Datoteka ? formEvidencija.Datoteka.ArhivaID : -1,
                          TipDokumentaID: 3,
                          TipDokumentaNaziv: "Ostalo",
                          DokumentNaziv: dokumentNaziv,
                          Opis: opis,
                          File: file,
                          Ekstenzija: dokumentNaziv.substring(datoteka.name.lastIndexOf("."), datoteka.name.length),
                          FileSize: fileSize,
                          ContentType: contentType,
                      }
                    : null,
                PovezaniZadaci: povezaniZadaci,
                EvidencijaUPrenesenoRazdobljeAction: action
            };
            api.post("evidencija/insert", updatedEvidencija, saveSuccess, saveFail);
        }
    }

    function saveSuccess(data) {
        props.loadEvidencije(data);
        setLoading(false);    
        unsavedChanges.current = false;    
        setFormOpened(false);
    }

    function saveFail(data) {
        if (data == -2) {
            setEvidencijaUPrenesenoRazdobljeAlert(true);
            setLoading(false);
        } else {
            setErrorMessage(data ? (data.Message || data.toString()) : "");
            setLoading(false);
        }
    }

    function handleMinClick(mins) {
        let newDate = new Date(datumOd.getTime() + mins * 60000);
        if (newDate.getDay() == datumDo.getDay()) {
            unsavedChanges.current = true;
            setDatumDo(newDate);
        }
    }

    function handlePlusClick(mins) {
        let newDate = new Date(datumDo.getTime() + mins * 60000);
        let h = datumDo.getHours();
        let m = datumDo.getMinutes();
        let s = datumDo.getSeconds();
        if (newDate.getDay() == datumOd.getDay() || (newDate.getHours() == 0 && newDate.getMinutes() == 0 && newDate.getSeconds() == 0)) {
            unsavedChanges.current = true;
            setDatumDo(newDate);
        }
    }

    function handleMinusClick(mins) {
        let newDate = new Date(datumDo.getTime() - mins * 60000);
        if (newDate >= datumOd && (newDate.getDay() == datumOd.getDay() || (datumDo.getHours() == 0 && datumDo.getMinutes() == 0 && datumDo.getSeconds() == 0))) {
            unsavedChanges.current = true;
            setDatumDo(newDate);
        }
    }

    function reloadPredlosciEvidencije() {
        api.get("evidencija/loadpredloscievidencije/" + global.drugiZaposlenik.KorisnikID, reloadPredlosciEvidencijeSuccess, reloadPredlosciEvidencijeFail);
    }

    function reloadPredlosciEvidencijeSuccess(data) {
        loadPredlosci(data);
        setLoading(false);
    }

    function reloadPredlosciEvidencijeFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function handleDatotekaChange(e) {
        let files = Array.from(e.target.files);
        setDatoteka(files.at(0));
        setDatotekaNaziv(files.at(0).name);
    }

    useEffect(() => {
        if (datoteka) {
            setDokumentNaziv(datoteka.name);

            const reader = new FileReader();
            const fileByteArray = [];

            reader.readAsArrayBuffer(datoteka);

            reader.onloadend = (evt) => {
                if (evt.target.readyState === FileReader.DONE) {
                    const arrayBuffer = evt.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for (const a of array) {
                        fileByteArray.push(a);
                    }

                    setFile(fileByteArray);
                }
            };

            setFileSize(datoteka.size);
            setContentType(datoteka.type);
        }
    }, [datoteka]);

    function handleSatiFocusOut() {
        let newDate;
        if (sati == "" || sati == null || isNaN(parseInt(sati)) || parseInt(sati) < 0 || parseInt(sati) > 24) {
            newDate = new Date(datumOd.getTime() + minute * 60000);
            setSati(0);
        } else {
            if (parseInt(sati) == 24) {
                setMinute(0);
                newDate = new Date(datumOd.getTime() + sati * 60 * 60 * 1000);
            } else {
                newDate = new Date(datumOd.getTime() + sati * 60 * 60 * 1000 + minute * 60000);
            }
        }
        setDatumDo(newDate);
    }

    function handleMinuteFocusOut() {
        let newDate;
        if (minute == "" || minute == null || isNaN(parseInt(minute)) || parseInt(minute) < 0 || parseInt(minute) > 60 || sati == 24) {
            newDate = new Date(datumOd.getTime() + sati * 60 * 60 * 1000);
            setMinute(0);
        } else {
            newDate = new Date(datumOd.getTime() + sati * 60 * 60 * 1000 + minute * 60000);
        }
        setDatumDo(newDate);
    }

    function pohrani() {
        setPohranjeno(true);
        if (!loading && formValid) {
            setLoading(true);
            setCanceled(false);
            let updatedDetalji = [];
            if (dodaniPredmeti.length > 0) {
                dodaniPredmeti.forEach((detalj) => {
                    let newDetalj = {
                        EvidencijaDetaljID: detalj.DetaljID,
                        EvidencijaID: formEvidencija ? formEvidencija.EvidencijaID : -1,
                        ReferencaID: detalj.ReferencaID,
                        Tarifa: detalj.Tarifa,
                        Iznos: detalj.Iznos,
                        FirmaValutaID: detalj.FirmaValutaID,
                        TipNaplatnostiID: detalj.TipNaplatnostiID,
                        TipOporezivostiID: detalj.TipOporezivostiID,
                        KlijentovVoditeljPredmetaKontaktID: detalj.KontaktID,
                        Datoteka: detalj.Datoteka
                    };
                    if (newDetalj.Datoteka) {
                        newDetalj.Datoteka.Opis = opis;
                    }
                    updatedDetalji.push(newDetalj);
                });
            } else if (nepredmetnaKategorija) {
                let newDetalj = {
                    EvidencijaDetaljID: formEvidencijaDetalj ? formEvidencijaDetalj.at(0).EvidencijaDetaljID : -1,
                    EvidencijaID: formEvidencija ? formEvidencija.EvidencijaID : -1,
                    ReferencaID: nepredmetnaKategorija.NepredmetnaKategorijaID,
                    TipNaplatnostiID: 2,
                    TipOporezivostiID: 2,
                };
                updatedDetalji.push(newDetalj);
            }
            let updatedEvidencija = {
                EvidencijaID: formEvidencija ? formEvidencija.EvidencijaID : -1,
                KorisnikID: global.drugiZaposlenik.KorisnikID,
                TipEvidencijeID: 1,
                TipPredmetnostiID: nepredmetnaKategorija ? 2 : 1,
                PocetakRada: datumOd,
                KrajRada: datumDo,
                Opis: opis,
                EvidencijaDetalj: updatedDetalji,
                ObrisaniDetaljiIDs: obrisaniDetaljiIDs,
                Datoteka: datoteka ? 
                    {
                          ArhivaFileID: formEvidencija && formEvidencija.Datoteka ? formEvidencija.Datoteka.ArhivaFileID : -1,
                          ArhivaID: formEvidencija && formEvidencija.Datoteka ? formEvidencija.Datoteka.ArhivaID : -1,
                          TipDokumentaID: 3,
                          TipDokumentaNaziv: "Ostalo",
                          DokumentNaziv: dokumentNaziv,
                          Opis: opis,
                          File: file,
                          Ekstenzija: dokumentNaziv.substring(datoteka.name.lastIndexOf("."), datoteka.name.length),
                          FileSize: fileSize,
                          ContentType: contentType,
                      }
                    : null,
            };
            api.post("evidencija/insert", updatedEvidencija, pohraniSuccess, pohraniFail);
        }
    }

    function pohraniSuccess(data) {
        setOpis("");
        props.loadEvidencije(data);
        if (global.user.tipPrikazaSatnice == 1) {
            let newDatumOd = new Date(datumDo);
            let newDatumDo = new Date(newDatumOd.getTime() + Math.abs(datumDo.getTime() - datumOd.getTime()));
            setDatumOd(new Date(newDatumOd));
            setDatumDo(new Date(newDatumDo))
        }
        let updatedDodaniPredmeti = [];
        if (dodaniPredmeti.length > 0) {
            dodaniPredmeti.forEach((detalj) => {
                let newPredmet = {...detalj, Datoteka: null};
                updatedDodaniPredmeti.push(newPredmet);
            });
        }
        setDodaniPredmeti(updatedDodaniPredmeti);
        setLoading(false);
    }

    function pohraniFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function trosak() {
        let newTrosakDetalji = [];
        dodaniPredmeti.forEach(dp => {
            newTrosakDetalji.push({
                PredmetID : dp.ReferencaID,
                PredmetNaziv : null,
                KlijentNaziv : null,
                FirmaValutaID : null,
                TipOporezivostiID : dp.TipOporezivostiID,
                PoreznaStopa : null,
                DefaultPoreznaStopa : null,
                DefaultTipNaplatnostiID : null,
                DefaultTipOporezivostiID : null,
                TipNaplatnostiID : dp.TipNaplatnostiID
            });
        });
        setTrosakDetalji(newTrosakDetalji);
        setTrosakOpened(true);
    }

    function checkZadaciAndKlijenti(predmetiIDs) {
        if (povezaniZadaci.length > 0) {
            setLoading(true);
            api.get("evidencija/getdodaniklijentiids?predmetiIDs=" + encodeURIComponent(predmetiIDs), (data) => {
                setPovezaniZadaci(povezaniZadaci.filter(z => z.KlijentID == null || data.includes(z.KlijentID)));
                setLoading(false);
            } , (data) => {
                setErrorMessage(data ? (data.Message || data.toString()) : "");
                setLoading(false);
            });
        }
    }

    return (
        <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
            <ComponentContainer loading={loading}>
                {dodavanjePredmeta ? (
                    <Box sx={{ width: "95%", paddingTop: "10px", paddingBottom: "10px", paddingLeft: 0, paddingRight: 0 }}>
                        <UnosAktivnostiPredmetiForm unsavedChanges={unsavedChanges} opis={opis} zadatakKlijentID={zadatakKlijentID} zadatakMessage={dodavanjePredmetaMessage} closeEvidencija={() => { setFormOpened(false); } } elektronickaArhivaDostupnoMiB={elektronickaArhivaDostupnoMiB} elektronickaArhivaMiBPerFile={elektronickaArhivaMiBPerFile} korisnikID={korisnikID} setDodavanjePredmeta={setDodavanjePredmeta} setDodaniPredmeti={setDodaniPredmeti} dodaniPredmeti={dodaniPredmeti} formEvidencijaDetalj={currentPredmet} predmeti={predmeti} klijenti={props.klijenti} kontakti={props.kontakti} firmaValute={props.firmaValute} defaultFirmaValuta={props.defaultFirmaValuta} tipoviOporezivosti={props.tipoviOporezivosti} tipoviNaplatnosti={props.tipoviNaplatnosti} setTitleVisible={setTitleVisible} tipNaplatnostiNaplatnoBoja={props.tipNaplatnostiNaplatnoBoja} tipNaplatnostiNenaplatnoBoja={props.tipNaplatnostiNenaplatnoBoja} mogucePrebacitiAktivnostiBezCijene={props.mogucePrebacitiAktivnostiBezCijene} setSamoOtvoreniPredmeti={props.setSamoOtvoreniPredmeti} samoOtvoreniPredmeti={props.samoOtvoreniPredmeti} setSamoAktivniKlijenti={props.setSamoAktivniKlijenti} samoAktivniKlijenti={props.samoAktivniKlijenti} focusOnOpis={focusOnOpis}></UnosAktivnostiPredmetiForm>
                    </Box>
                ) : (
                    <Box sx={{ width: "100%", padding: "10px" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={24} sm={24} md={24} style={{ textAlign: "center" }}>
                                <DatePicker onChange={handleDatumChange} label="Datum unosa evidencije" value={datum} inputFormat="dd.MM.yyyy" maxDate={new Date()} renderInput={(params) => <TextField style={{ width: "230px" }} {...params} size="small" />} />
                            </Grid>

                            {global.user.tipPrikazaSatnice == 1 ? (
                                <Grid item xs={12} sm={6} md={4}>
                                    <TimePicker ampm={false} label="Početak rada" value={datumOd} onChange={onDatumOdChange} disabled={pocetakRadaDisabled || formEvidencija} renderInput={(params) => <TextField step={300} size="small" {...params} error={datumErrorMessage != ""} sx={{ width: "100%" }} />} />
                                </Grid>
                            ) : null}
                            {global.user.tipPrikazaSatnice == 1 ? (
                                <Grid item xs={10} sm={5} md={4}>
                                    <TimePicker ampm={false} label="Kraj rada" value={datumDo} onChange={onDatumDoChange} disabled={krajRadaDisabled} renderInput={(params) => <TextField size="small" {...params} error={datumErrorMessage != ""} helperText={datumErrorMessage != "" ? datumErrorMessage : ""} sx={{ width: "100%" }} />} />
                                </Grid>
                            ) : null}
                            {global.user.tipPrikazaSatnice == 1 ? (
                                <Grid item xs={2} sm={1} md={1}>
                                    <Tooltip title="Sada">
                                        <span>
                                            <IconButton onClick={() => {unsavedChanges.current = true; setDatumDo(new Date());}}>
                                                <WatchLaterIcon></WatchLaterIcon>
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            ) : null}
                            {global.user.tipPrikazaSatnice == 1 ? (
                                <Grid item xs={12} sm={12} md={global.user.tipPrikazaSatnice == 2 ? 12 : 3} display="flex" alignItems="center">
                                    <Typography variant="button" gutterBottom component="div" style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", width: "100%", height: "100%" }}>
                                        {"Trajanje: " + (Math.floor(parseInt(trajanje) / 60) > 0 ? Math.floor(parseInt(trajanje) / 60) + " h " : "") + (parseInt(trajanje) % 60) + " min"}
                                    </Typography>
                                </Grid>
                            ) : null}
                            {global.user.tipPrikazaSatnice == 2 ? <Grid item xs={1} sm={1} md={4}></Grid> : null}
                            {global.user.tipPrikazaSatnice == 2 ? (
                                <Grid item xs={5} sm={5} md={2}>
                                    <FormControl fullWidth>
                                        <TextField
                                            inputRef={satiRef}
                                            size="small"
                                            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
                                            value={sati}
                                            type="number"
                                            onChange={(e) => {
                                                if (!isNaN(parseInt(e.target.value))) {
                                                    unsavedChanges.current = true;
                                                    setSati(parseInt(e.target.value));
                                                } else if (e.target.value == "") {
                                                    unsavedChanges.current = true;
                                                    setSati("");
                                                }
                                            }}
                                            onBlur={handleSatiFocusOut}
                                            onFocus={(e) => e.target.select()}
                                            InputProps={{ endAdornment: <InputAdornment position="end">h</InputAdornment>, inputProps: { min: 0, max: 24 } }}
                                        ></TextField>
                                    </FormControl>
                                </Grid>
                            ) : null}

                            {global.user.tipPrikazaSatnice == 2 ? (
                                <Grid item xs={5} sm={5} md={2}>
                                    <FormControl fullWidth>
                                        <TextField
                                            inputRef={minuteRef}
                                            size="small"
                                            sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
                                            value={minute}
                                            type="number"
                                            onChange={(e) => {
                                                if (!isNaN(parseInt(e.target.value))) {
                                                    unsavedChanges.current = true;
                                                    setMinute(parseInt(e.target.value));
                                                } else if (e.target.value == "") {
                                                    unsavedChanges.current = true;
                                                    setMinute("");
                                                }
                                            }}
                                            onBlur={handleMinuteFocusOut}
                                            onFocus={(e) => e.target.select()}
                                            InputProps={{ endAdornment: <InputAdornment position="end">min</InputAdornment>, inputProps: { min: 0, max: 59 } }}
                                        ></TextField>
                                    </FormControl>
                                </Grid>
                            ) : null}

                            {global.user.tipPrikazaSatnice == 2 ? <Grid item xs={1} sm={1} md={4}></Grid> : null}

                            <Grid container spacing={0} >

                                <Grid item xs={(12-minsButtons.length*6)/2} sm={(12-minsButtons.length*4)/2} md={(12-minsButtons.length*4)/2} lg={(12-minsButtons.length*2)/2}>

                                </Grid>
                                
                                {minsButtons.map((item, index) => {
                                            return (
                                                <Grid item xs={6} sm={4} md={4} lg={2}>
                                                    <Box textAlign="center">
                                                        <Tooltip title={"Postavi na " + item + " min"} enterDelay={200} enterNextDelay={200} arrow placement="top">
                                                            <span>
                                                                <Button fullWidth onClick={() => handleMinClick(item)} tabIndex={-1} style={{ padding: 0 }}>
                                                                    {item + " min"}
                                                                </Button>
                                                            </span>
                                                        </Tooltip>
                                                        <ButtonGroup orientation="horizontal">
                                                            <Tooltip title={"Oduzmi "+ item + " min"} enterDelay={200} enterNextDelay={200} arrow placement="bottom">
                                                                <span>
                                                                    <Button onClick={() => handleMinusClick(item)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                                        <RemoveIcon fontSize="small"></RemoveIcon>
                                                                    </Button>
                                                                </span>
                                                            </Tooltip>
                                                            <Tooltip title={"Dodaj " + item + " min"} enterDelay={200} enterNextDelay={200} arrow placement="bottom">
                                                                <span>
                                                                    <Button onClick={() => handlePlusClick(item)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                                        <AddIcon fontSize="small"></AddIcon>
                                                                    </Button>
                                                                </span>
                                                            </Tooltip>
                                                        </ButtonGroup>
                                                    </Box>
                                                </Grid>
                                            );
                                        })}



                                {/* {minsButtons.includes(1) ? (
                                    <Grid item xs={6} sm={4} md={4} lg={2}>
                                        <Box textAlign="center">
                                            <Tooltip title="Postavi na 1 min" enterDelay={200} enterNextDelay={200}>
                                                <span>
                                                    <Button fullWidth onClick={() => handleMinClick(1)} tabIndex={-1} style={{ padding: 0 }}>
                                                        1 min
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                            <ButtonGroup orientation="horizontal">
                                                <Tooltip title="Oduzmi 1 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handleMinusClick(1)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <RemoveIcon fontSize="small"></RemoveIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Dodaj 1 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handlePlusClick(1)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <AddIcon fontSize="small"></AddIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </Box>
                                    </Grid>
                                ) : null}

                                {minsButtons.includes(5) ? (
                                    <Grid item xs={6} sm={4} md={4} lg={2}>
                                        <Box textAlign="center">
                                            <Tooltip title="Postavi na 5 min" enterDelay={200} enterNextDelay={200}>
                                                <span>
                                                    <Button fullWidth onClick={() => handleMinClick(5)} tabIndex={-1} style={{ padding: 0 }}>
                                                        5 min
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                            <ButtonGroup orientation="horizontal">
                                                <Tooltip title="Oduzmi 5 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handleMinusClick(5)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <RemoveIcon fontSize="small"></RemoveIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Dodaj 5 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handlePlusClick(5)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <AddIcon fontSize="small"></AddIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </Box>
                                    </Grid>
                                ) : null }

                                {minsButtons.includes(10) ? (
                                    <Grid item xs={6} sm={4} md={4} lg={2}>
                                        <Box textAlign="center">
                                            <Tooltip title="Postavi na 10 min" enterDelay={200} enterNextDelay={200}>
                                                <span>
                                                    <Button fullWidth onClick={() => handleMinClick(10)} tabIndex={-1} style={{ padding: 0 }}>
                                                        10 min
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                            <ButtonGroup orientation="horizontal">
                                                <Tooltip title="Oduzmi 10 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handleMinusClick(10)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <RemoveIcon fontSize="small"></RemoveIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Dodaj 10 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handlePlusClick(10)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <AddIcon fontSize="small"></AddIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </Box>
                                    </Grid>
                                ) : null }

                                {minsButtons.includes(15) ? (
                                    <Grid item xs={6} sm={4} md={4} lg={2}>
                                        <Box textAlign="center">
                                            <Tooltip title="Postavi na 15 min" enterDelay={200} enterNextDelay={200}>
                                                <span>
                                                    <Button fullWidth onClick={() => handleMinClick(15)} tabIndex={-1} style={{ padding: 0 }}>
                                                        15 min
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                            <ButtonGroup orientation="horizontal">
                                                <Tooltip title="Oduzmi 15 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handleMinusClick(15)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <RemoveIcon fontSize="small"></RemoveIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Dodaj 15 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handlePlusClick(15)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <AddIcon fontSize="small"></AddIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </Box>
                                    </Grid>
                                ) : null }

                                {minsButtons.includes(30) ? (
                                    <Grid item xs={6} sm={4} md={4} lg={2} enterDelay={200} enterNextDelay={200}>
                                        <Box textAlign="center">
                                            <Tooltip title="Postavi na 30 min" enterDelay={200} enterNextDelay={200}>
                                                <span>
                                                    <Button fullWidth onClick={() => handleMinClick(30)} tabIndex={-1} style={{ padding: 0 }}>
                                                        30 min
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                            <ButtonGroup orientation="horizontal">
                                                <Tooltip title="Oduzmi 30 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handleMinusClick(30)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <RemoveIcon fontSize="small"></RemoveIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Dodaj 30 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handlePlusClick(30)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <AddIcon fontSize="small"></AddIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </Box>
                                    </Grid>
                                ) : null }

                                {minsButtons.includes(45) ? (
                                    <Grid item xs={6} sm={4} md={4} lg={2}>
                                        <Box textAlign="center">
                                            <Tooltip title="Postavi na 45 min" enterDelay={200} enterNextDelay={200}>
                                                <span>
                                                    <Button fullWidth onClick={() => handleMinClick(45)} tabIndex={-1} style={{ padding: 0 }}>
                                                        45 min
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                            <ButtonGroup orientation="horizontal">
                                                <Tooltip title="Oduzmi 45 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handleMinusClick(45)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <RemoveIcon fontSize="small"></RemoveIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Dodaj 45 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handlePlusClick(45)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <AddIcon fontSize="small"></AddIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </Box>
                                    </Grid>
                                ) : null }

                                {minsButtons.includes(60) ? (
                                    <Grid item xs={6} sm={4} md={4} lg={2}>
                                        <Box textAlign="center">
                                            <Tooltip title="Postavi na 60 min" enterDelay={200} enterNextDelay={200}>
                                                <span>
                                                    <Button fullWidth onClick={() => handleMinClick(60)} tabIndex={-1} style={{ padding: 0 }}>
                                                        60 min
                                                    </Button>
                                                </span>
                                            </Tooltip>
                                            <ButtonGroup orientation="horizontal">
                                                <Tooltip title="Oduzmi 60 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handleMinusClick(60)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <RemoveIcon fontSize="small"></RemoveIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title="Dodaj 60 min" enterDelay={200} enterNextDelay={200}>
                                                    <span>
                                                        <Button onClick={() => handlePlusClick(60)} tabIndex={-1} sx={{ padding: { xs: "5px", sm: "5px", md: 0 } }}>
                                                            <AddIcon fontSize="small"></AddIcon>
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </Box>
                                    </Grid>
                                ) : null } */}

                                <Grid item xs={(12-minsButtons.length*6)/2} sm={(12-minsButtons.length*4)/2} md={(12-minsButtons.length*4)/2} lg={(12-minsButtons.length*2)/2}>

                                </Grid>

                            </Grid>


                            {nepredmetnaKategorijaID === 0 && (
                                <>
                                    <Grid item xs={12}>
                                        <FormControl style={{ width: "100%" }}>
                                            <Button
                                                onClick={() => {
                                                    setCurrentPredmet(null);
                                                    setDodavanjePredmeta(true);
                                                }}
                                                size="large"
                                                disabled={pohranjeno}
                                                style={{ paddingTop: "15px", paddingBottom: "15px" }}
                                                variant="contained"
                                                startIcon={<AddIcon></AddIcon>}
                                            >
                                                D&#818;odaj Predmet
                                            </Button>
                                        </FormControl>
                                    </Grid>
                                </>
                            )}
                            {dodaniPredmeti.length == 0 ? (
                                <Grid item xs={12}>
                                    <FormControl style={{ width: "100%" }}>
                                        <Autocomplete
                                            size="small"
                                            disablePortal
                                            options={nepredmetneKategorije}
                                            onChange={onNepredmetnakategorijatChange}
                                            getOptionLabel={(option) => option.Naziv}
                                            autoHighlight
                                            disabled={pohranjeno}
                                            value={nepredmetnaKategorija}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.NepredmetnaKategorijaID}>
                                                        <Brightness1Icon sx={{ color: "rgb(" + option.Boja + ")" }}></Brightness1Icon>
                                                        <span>&nbsp;&nbsp;{option.Naziv}</span>
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    size="small"
                                                    {...params}
                                                    label="Ostalo"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: nepredmetnaKategorija ? (
                                                            <InputAdornment position="end">
                                                                <Brightness1Icon sx={{ color: "rgb(" + nepredmetnaKategorija.Boja + ")" }}></Brightness1Icon>
                                                            </InputAdornment>
                                                        ) : null,
                                                    }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            ) : null}
                            {nepredmetnaKategorijaID === 0 && dodaniPredmeti.length > 0 && (
                                <Grid item xs={12} marginTop="5px" paddingLeft="12px !important">
                                    {/* <Paper variant="elevation" elevation={4} style={{width:"100%", maxHeight: 350, overflow: 'auto'}}> */}
                                    <FormLabel style={{ marginLeft: "0.71em", marginTop: "-0.71em", paddingLeft: "0.44em", paddingRight: "0.44em", zIndex: 2, width: "8.5em", backgroundColor: "white", position: "absolute", fontSize: "0.75em" }}>Dodani predmeti</FormLabel>
                                    <List dense sx={{ borderRadius: 1, border: 1, borderColor: "grey.400", "&:hover": { borderColor: "black" } }}>
                                        {dodaniPredmeti.map((item, index) => {
                                            return (
                                                <ListItem className="list-item" key={index}>
                                                    <Grid container spacing={1}>
                                                        <Tooltip title={item.TipNaplatnostiID == 1 ? "Naplatno" : "Nenaplatno"} enterDelay={300} enterNextDelay={300}>
                                                            <Grid item xs={1} display="flex" alignItems="center">
                                                                <ListItemIcon>
                                                                    <Brightness1Icon fontSize="small" sx={{ color: "rgb(" + (item.TipNaplatnostiID == 1 ? props.tipNaplatnostiNaplatnoBoja : props.tipNaplatnostiNenaplatnoBoja) + ")" }}></Brightness1Icon>
                                                                </ListItemIcon>
                                                            </Grid>
                                                        </Tooltip>
                                                        <Grid item xs={7}>
                                                            <Tooltip title={item.Naziv} enterDelay={200} enterNextDelay={200}>
                                                                <ListItemText
                                                                    style={{color: (item.PredmetTipStanjaPredmetaID !== 1 ? "red" : (item.PredmetTipNaplateID == 1 ? "black" : (item.PredmetTipNaplateID == 2 ? "magenta" : "green")))}}
                                                                    primaryTypographyProps={{ noWrap: true }}
                                                                    primary={item.Naziv}
                                                                    onClick={() => {
                                                                        if (!pohranjeno) {
                                                                            setCurrentPredmet(item);
                                                                            setDodavanjePredmeta(true);
                                                                        }
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                        </Grid>
                                                        <Grid item xs={1} display="flex" alignItems="center" justifyContent="center">
                                                            {item.Datoteka ? (
                                                                <ListItemIcon onClick={() => {
                                                                    if (!pohranjeno) {
                                                                        setCurrentPredmet(item);
                                                                        setDodavanjePredmeta(true);
                                                                    }
                                                                }}>
                                                                    <AttachFileIcon fontSize="small"></AttachFileIcon>
                                                                </ListItemIcon>
                                                            ) : null}    
                                                        </Grid>
                                                        <Grid item xs={3}>
                                                            <ListItemButton
                                                                style={{ display: "flex", justifyContent: "center", padding: "0", height: "100%", color: "red" }}
                                                                disabled={pohranjeno}
                                                                onClick={() => {
                                                                    let newDodaniPredmetiList = dodaniPredmeti.filter((p) => {
                                                                        return p.ReferencaID != item.ReferencaID;
                                                                    });
                                                                    unsavedChanges.current = true;
                                                                    setCurrentPredmet(null);
                                                                    setDodaniPredmeti(newDodaniPredmetiList);
                                                                    setPovezaniZadaci(povezaniZadaci.filter(z => z.PredmetID != item.ReferencaID))
                                                                    checkZadaciAndKlijenti(newDodaniPredmetiList.map(p => p.ReferencaID));
                                                                    setObrisaniDetaljiIDs((ogObrisaniDetalji) => [...ogObrisaniDetalji, item.ReferencaID]);
                                                                }}
                                                            >
                                                                <RemoveIcon></RemoveIcon>
                                                                Ukloni
                                                            </ListItemButton>
                                                        </Grid>
                                                    </Grid>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                    {/* </Paper> */}
                                </Grid>
                            )}
                            {/* {dodaniPredmeti.length == 1 && datoteka == null && datotekaNaziv == "" ? (
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center">
                                        <Button variant="outlined" component="label" startIcon={<UploadFileIcon />} style={{ marginTop: "5px", marginBottom: "5px" }}>
                                            Prenesi datoteku
                                            <input type="file" hidden onChange={handleDatotekaChange}></input>
                                        </Button>
                                    </Box>
                                </Grid>
                            ) : null}
                            {datoteka || datotekaNaziv != "" ? (
                                <Grid item xs={6}>
                                    <Typography variant="button" gutterBottom component="div">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginTop: "6px",
                                            }}
                                        >
                                            <AttachFileIcon fontSize="small"></AttachFileIcon>
                                            {datotekaNaziv}
                                        </div>
                                    </Typography>
                                </Grid>
                            ) : null}
                            {datoteka || datotekaNaziv != "" ? (
                                <Grid item xs={6} display="flex" justifyContent="center">
                                    <Button
                                        color="error"
                                        onClick={() => {
                                            setDatoteka(null);
                                            setDatotekaNaziv("");
                                        }}
                                    >
                                        Obriši datoteku
                                    </Button>
                                </Grid>
                            ) : null} */}
                            <Grid item xs={12}>
                                <FormControl style={{ width: "100%" }}>
                                    <TextField size="small" label="Opis" inputProps={{ spellCheck: "false" }} multiline rows={5} value={opis} onChange={(e) => { setOpis(e.target.value); unsavedChanges.current = true; }} error={!opis} inputRef={opisRef} />
                                </FormControl>
                            </Grid>
                            {brojRedovaPredlozaka > 0 ? (
                                <>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <FormControl style={{ width: "100%" }}>
                                            <Autocomplete size="small" disablePortal options={Array(brojRedovaPredlozaka * 8).fill(null).map((v,i) => i)} onChange={(e, v) => setNewPredlozakPozicija(v)} getOptionLabel={(option) => (predlosci.at(option) ? option + 1 + ") " + predlosci.at(option).Naziv : option + 1 + ") Nema spremljenog predloška.")} value={newPredlozakPozicija} noOptionsText="Nema spremljenih predložaka." renderInput={(params) => <TextField size="small" {...params} label="Predložak za uređivanje" inputProps={{ ...params.inputProps, tabIndex: -1 }}></TextField>} />
                                        </FormControl>
                                    </Grid>
                                    {newPredlozakPozicija == null || !predlosci.at(newPredlozakPozicija) ? (
                                        <Grid item xs={12} sm={12} md={6} display="flex" alignItems="flex-end">
                                            <Tooltip title={'U izborniku s lijeve strane odaberite poziciju na koju želite spremiti predložak i kliknite "Novi predložak"'}>
                                                <div style={{ width:"100%", display:"flex", justifyContent:"center" }}>
                                                    <Button style={{ width: "100%", marginBottom: "2%" }} disabled={newPredlozakPozicija == null} onClick={stvoriPredlozak} tabIndex={-1}>
                                                        <AddIcon fontSize="small"></AddIcon>
                                                        Novi predložak
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                    ) : null}
                                    {newPredlozakPozicija != null && predlosci.at(newPredlozakPozicija) ? (
                                        <Grid item xs={12} sm={12} md={6} display="flex" alignItems="space-between">
                                            <Button startIcon={<DriveFileRenameOutlineIcon></DriveFileRenameOutlineIcon>} style={{ width: "100%", marginBottom: "2%" }} onClick={urediPredlozak} tabIndex={-1}>
                                                Uredi predložak
                                            </Button>
                                            <Button startIcon={<HighlightOffIcon></HighlightOffIcon>} color="error" style={{ width: "100%", marginBottom: "2%" }} onClick={() => setDeletePredlozakAlert(true)} tabIndex={-1}>
                                                Obriši predložak
                                            </Button>
                                        </Grid>
                                    ) : null}
                                </>
                            ) : null}
                            <Grid item sm={12}>
                                {Array(brojRedovaPredlozaka).fill(null).map((v, red) => (
                                    <div>
                                        <ButtonGroup style={{ width: "100%", justifyContent: "center" }}>
                                            {Array(8).fill(null).map((v2, i) => (
                                                <Tooltip title={predlosci.at(red * 8 + i) ? predlosci.at(red * 8 + i).Naziv : ""}>
                                                    <Button variant="contained" style={{width:"3em", borderBottom: red != brojRedovaPredlozaka - 1 ? "1px solid darkgray" : "0px"}} tabIndex={-1} disabled={!predlosci.at(red * 8 + i)} onClick={() => setPredlozakEvidencija(predlosci.at(red * 8 + i))}>
                                                        {red * 8 + i + 1}
                                                    </Button>
                                                </Tooltip>
                                            ))}
                                        </ButtonGroup>                                        
                                    </div>
                                ))}
                            </Grid>
                            <Grid item xs={12} sm={12} md={omoguciPohrani ? 2 : 5} marginTop="10px" display="flex" justifyContent={{xs:"center", sm:"center", md:"flex-end"}}>
                                {global.drugiZaposlenik && (global.drugiZaposlenik.Prava.includes(23) || global.drugiZaposlenik.Prava.includes(24)) ? (
                                    <Button variant="outlined" onClick={() => setZadaciOpened(true)} startIcon={<TaskAltIcon></TaskAltIcon>}>Zadaci</Button>
                                ) : null}
                            </Grid>
                            {omoguciPohrani ? (
                                <Grid item xs={12} sm={12} md={5}>
                                    <Box display="flex" justifyContent={{xs:"space-evenly", sm:"space-evenly", md:"flex-end"}} width="100%" marginTop="10px">
                                        <Button variant="outlined" disabled={dodaniPredmeti.length == 0} onClick={trosak} style={{ marginRight: "10px" }} startIcon={<TollIcon></TollIcon>}>T&#818;rošak</Button>
                                        <Button onClick={pohrani} variant="contained" color="success" disabled={loading || !formValid}>
                                            {loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{ marginRight: "10px" }} /> : <SaveAsIcon style={{ marginRight: "10px" }} />} Poh&#818;rani
                                        </Button>
                                    </Box>
                                </Grid>
                            ) : null}
                            <Grid item xs={12} sm={12} md={omoguciPohrani ? 5 : 7}>
                                <Box display="flex" justifyContent={{xs:"space-evenly", sm:"space-evenly", md:"flex-end"}} width="100%" marginTop="10px">
                                    <Button onClick={() => save(null)} variant="contained" style={{ marginRight: "10px" }} color="success" disabled={loading || !formValid || pohranjeno}>
                                        {loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{ marginRight: "10px" }} /> : <SaveIcon style={{ marginRight: "10px" }} />} Sp&#818;remi
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => {
                                            setCanceled(true);
                                            unsavedChanges.current = false;
                                            setFormOpened(false);
                                        }}
                                        disabled={loading}
                                    >
                                        <ExitToApp style={{ marginRight: "10px" }}></ExitToApp>{pohranjeno ? "Zatvori" : <span>O&#818;dustani</span>}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {createPredlozak ? (
                    <Modal className="modal" open={createPredlozak}>
                        <Box sx={style} overflow="auto">
                            <Typography variant="h6" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px", width:"100%"}}>
                                Dodavanje predloška
                            </Typography>
                            <FormControl fullWidth>
                                <TextField size="small" label="Naziv predloška:" inputProps={{ maxLength: 150 }} value={nazivPredloska} onChange={onNazivPredloskaChange} error={nazivPredloska == ""}></TextField>
                            </FormControl>
                            <Box display="flex" justifyContent="flex-end" marginTop="10px">
                                <Button disabled={loading || nazivPredloska == ""} variant="contained" color="success" startIcon={<SaveIcon></SaveIcon>} onClick={spremiPredlozak} style={{ marginRight:"5px" }}>
                                    {"Spremi predložak na poziciju " + (newPredlozakPozicija + 1) + "."}
                                </Button>
                                <Button disabled={loading} variant="outlined" color="error" startIcon={<CloseIcon></CloseIcon>} onClick={() => setCreatePredlozak(false)}>
                                    Odustani
                                </Button>
                            </Box>
                        </Box>
                    </Modal>
                ) : null}

                {deletePredlozakAlert ? <AlertDialog title="Brisanje predloška" message={"Želite li obrisati odabrani predložak?"} cancelLabel={"Odustani"} confirmLabel={"Obriši"} cancelAction={() => setDeletePredlozakAlert(false)} confirmAction={() => deletePredlozak()}></AlertDialog> : null}
            
                {trosakOpened ? (
                    <Modal className="modal" open={trosakOpened}>
                        <Box sx={style} overflow="auto">
                            <BrziUnosTroskaForm
                                tipoviOporezivosti={props.tipoviOporezivosti}
                                tipoviNaplatnosti={props.tipoviNaplatnosti}
                                firmaValute={props.firmaValute}
                                setFormOpened={setTrosakOpened}
                                defaultFirmaValuta={props.defaultFirmaValuta}
                                defaultTrosakTipOporezivosti={props.tipoviOporezivosti.find(to => to.TipOporezivostiID == dodaniPredmeti[0].TipOporezivostiID)}
                                date={datum}
                                tipNaplatnostiNaplatnoBoja={props.tipNaplatnostiNaplatnoBoja}
                                tipNaplatnostiNenaplatnoBoja={props.tipNaplatnostiNenaplatnoBoja}
                                loadEvidencije={props.loadEvidencije}
                                trosakDetalji={trosakDetalji}
                            ></BrziUnosTroskaForm>
                        </Box>
                    </Modal>
                ) : null}

                {zadaciOpened ? (
                    <Modal className="modal" open={zadaciOpened}>
                        <Box sx={{...style, minWidth:{xs:"100%", sm:"100%", md:"70%"}, padding:{xs:"10px", sm:"10px", md:"20px"}}} overflow="auto">
                            <ZadatakEvidencija close={() => setZadaciOpened(false)} zadatakZaEvidencijuID={props.zadatakZaEvidencijuID} predmetiIDs={dodaniPredmeti.map(p => p.ReferencaID)} klijentiIDs={dodaniPredmeti.map(p => p.KlijentID)} povezaniZadaci={povezaniZadaci} setPovezaniZadaci={setPovezaniZadaci}></ZadatakEvidencija>
                        </Box>
                    </Modal>
                ) : null}

                {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}

                {evidencijaUPrenesenoRazdobljeAlert ? <AlertDialog title="Upozorenje" buttonsLayout="vertical" icon={<WarningAmberIcon style={{marginRight:"15px", color:"red"}}></WarningAmberIcon>} message={<div dangerouslySetInnerHTML={{__html: evidencijaUPrenesenoRazdobljeMessage}}></div>} cancelHidden={false} confirmHidden={false} okHidden={false} cancelLabel={"Odustani"} cancelAction={() => setEvidencijaUPrenesenoRazdobljeAlert(false)} confirmLabel="Spremi evidenciju" confirmAction={() => save(1)} okLabel="Spremi evidenciju i ne prikazuj ovu poruku ubuduće" okAction={() => save(2)}></AlertDialog> : null}
            
            </ComponentContainer>
        </LocalizationProvider>
    );
}
export default UnosAktivnostiForm;
