import { Grow, Alert, Autocomplete, Box, Button, Divider, FormControl, Grid, IconButton, InputAdornment, ListItem, Menu, MenuItem, TextField, Tooltip, Typography, ListItemText, ListItemIcon, List, Paper } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback } from "react";
import ComponentContainer from "./componentContainer";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateFnsUtils from "@date-io/date-fns";
import { hr } from "date-fns/locale";
import SaveIcon from '@mui/icons-material/Save';
import ExitToApp from "@mui/icons-material/ExitToApp";
import LineIcon from "react-lineicons";
import Util from "../util/util";
import api from "../util/api";
import globalContext from "./globalContext";
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from "@mui/icons-material/Search";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import AlertDialog from "./alertdialog";
import ErrorIcon from '@mui/icons-material/Error';

const izvorPodatakaOptions = [
    { Vrijednost: 0, Naziv: "Prilagođeno" },
    { Vrijednost: 1, Naziv: "Preuzmi iz predmeta" },
    { Vrijednost: 2, Naziv: "Preuzmi iz evidencije aktivnosti" }
];

function BrziUnosTroskaForm(props) {

    const useConstructor = Util.useConstructor();
    const global = useContext(globalContext);

    const firmaValute = props.firmaValute;
    const tipoviNaplatnosti = props.tipoviNaplatnosti;
    const tipoviOporezivosti = props.tipoviOporezivosti;
    const setFormOpened = props.setFormOpened;
    const tipNaplatnostiNaplatnoBoja = props.tipNaplatnostiNaplatnoBoja;
    const tipNaplatnostiNenaplatnoBoja = props.tipNaplatnostiNenaplatnoBoja;

    const [loading, setLoading] = useState(true);
    const [opis, setOpis] = useState("");
    const [iznos, setIznos] = useState("0.00");
    const [firmaValuta, setFirmaValuta] = useState(null);
    const [tipNaplatnosti, setTipNaplatnosti] = useState(null);
    const [tipOporezivosti, setTipOporezivosti] = useState(null);
    const [datum, setDatum] = useState(new Date(props.date));
    const [formValid, setFormValid] = useState(false);
    const [povijestTroskova, setPovijestTroskova] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const historyOpened = Boolean(anchorEl);
    const [povijestFilter, setPovijestFilter] = useState("");
    const [trosakDetalji, setTrosakDetalji] = useState(props.trosakDetalji);
    const [trosakDetaljiOriginal, setTrosakDetaljiOriginal] = useState(props.trosakDetalji);
    const [izvorPodatakaOption, setIzvorPodatakaOption] = useState(izvorPodatakaOptions[0])
    const [errorMessage, setErrorMessage] = useState("");

    useConstructor(() => {
        setTipNaplatnosti(tipoviNaplatnosti.find(tn => tn.TipNaplatnostiID == 1));
        setFirmaValuta(props.defaultFirmaValuta);
        setTipOporezivosti(props.defaultTrosakTipOporezivosti);
        let resource = {
            TrosakDetalji : trosakDetalji,
            KorisnikID : global.drugiZaposlenik ? global.drugiZaposlenik.korisnikID : global.user.korisnikID
        };
        api.post("evidencija/getbrziunostroskainfo", resource, loadSuccess, loadFail);
    });

    function loadSuccess(data) {
        setPovijestTroskova(data.PovijestTroskova);
        let newTrosakDetalji = [];
        data.TrosakDetalji.forEach(td => {
            newTrosakDetalji.push({...td, 
                TipNaplatnostiID : 1, 
                TipOporezivostiID : props.defaultTrosakTipOporezivosti.TipOporezivostiID,
                PoreznaStopa : props.defaultTrosakTipOporezivosti.Stopa * 100}
            );
        });
        setTrosakDetalji(newTrosakDetalji);
        setTrosakDetaljiOriginal(data.TrosakDetalji);
        setLoading(false);
    }

    function loadFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    function save() {
        setLoading(true);
        let resource = {
            KorisnikID : global.drugiZaposlenik.KorisnikID,
            Opis : opis,
            TrosakDetalji : trosakDetalji,
            Iznos : iznos,
            FirmaValutaID : firmaValuta.FirmaValutaID,
            Datum : datum
        };
        api.post("evidencija/brziunostroska", resource, saveSuccess, saveFail);
    }

    function saveSuccess(data) {
        props.loadEvidencije(data);
        setLoading(false);
        setFormOpened(false);
    }

    function saveFail(data) {
        setErrorMessage(data ? (data.Message || data.toString()) : "");
        setLoading(false);
    }

    useEffect(() => {
        let checkValid = opis && (parseFloat(iznos) >= 0) && firmaValuta && tipNaplatnosti && tipOporezivosti && datum && !isNaN(new Date(datum));
        setFormValid(checkValid); 
    }, [iznos, firmaValuta, tipNaplatnosti, tipOporezivosti, datum, opis]);

    function countDecimals(value) {
        if (value.indexOf(".") == -1) {
            return 0;
        } else if (Math.floor(parseFloat(value)) !== parseFloat(value)) {
            return value.toString().split(".")[1].length;
        }
    }


    function handleIznosFocusOut() {
        // if (!isNaN(parseFloat(iznos)) && parseFloat(iznos) > 0) {
        //     setIznos(parseFloat(iznos));
        // } else {
        //     setIznos(0);
        // }

        if (iznos == "" || iznos.charAt(0) == '-') {
            setIznos("0.00");
        } else if (!isNaN(parseFloat(iznos))) {
            let value = iznos;
            let i = 0;
            while (iznos.length > i + 1 && iznos.charAt(i) == '0' && iznos.charAt(i + 1) != '.') {
                value = value.substring(1);
                i++;
            }
            ;
            value = Util.toStringDecimal(value);
            setIznos(value);
        }

    }

    function handleHistoryClick(event) {
        setAnchorEl(event.currentTarget);
    };

    function handleHistoryClose(event) {
        setAnchorEl(null);
    };

    const handleKeyPressShorcuts = useCallback(
        (event) => {

            if (event.altKey) {
                let charCode = String.fromCharCode(event.which).toLowerCase();
                if (charCode == "p") {
                    event.preventDefault();
                    if (!loading && opis && (parseFloat(iznos) >= 0) && firmaValuta && tipNaplatnosti && tipOporezivosti && datum && !isNaN(new Date(datum))) {
                        save();
                    }
                }
                if (charCode == "o") {
                    event.preventDefault();
                    if (!loading) {
                        setFormOpened(false);                 
                    }
                }                
                 
            }

        },
        [loading, iznos, firmaValuta, tipNaplatnosti, tipOporezivosti, datum, opis, trosakDetalji]
    );

    const handleEsc = useCallback(
        (event) => {

            if(event.keyCode == 27){
                event.preventDefault();
                if (!loading) {
                    setFormOpened(false);                 
                }
            }                        

        },
        [loading]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPressShorcuts);
        document.addEventListener("keyup", handleEsc);

        return () => {
            document.removeEventListener("keydown", handleKeyPressShorcuts);
            document.removeEventListener("keyup", handleEsc);
        };
    }, [handleKeyPressShorcuts]);

    function handleIzvorPodatakaChange(e,v) {
        if (v) {
            setIzvorPodatakaOption(v);
            if (v.Vrijednost == 0) {
                let newTrosakDetalji = [];
                trosakDetalji.forEach(td => {
                    newTrosakDetalji.push({...td, 
                        TipNaplatnostiID : tipNaplatnosti.TipNaplatnostiID, 
                        TipOporezivostiID : tipOporezivosti.TipOporezivostiID,
                        PoreznaStopa : tipOporezivosti.Stopa * 100}
                    );
                });
                setTrosakDetalji(newTrosakDetalji);
            } else if (v.Vrijednost == 1) {
                let newTrosakDetalji = [];
                trosakDetaljiOriginal.forEach(td => {
                    newTrosakDetalji.push({...td, 
                        TipNaplatnostiID : td.DefaultTipNaplatnostiID, 
                        TipOporezivostiID : td.DefaultTipOporezivostiID,
                        PoreznaStopa : td.DefaultPoreznaStopa}
                    );
                });
                setTrosakDetalji(newTrosakDetalji);
            } else {
                let newTrosakDetalji = [];
                trosakDetaljiOriginal.forEach(td => {
                    newTrosakDetalji.push({...td, 
                        TipNaplatnostiID : td.TipNaplatnostiID, 
                        TipOporezivostiID : td.TipOporezivostiID,
                        PoreznaStopa : td.PoreznaStopa}
                    );
                });
                setTrosakDetalji(newTrosakDetalji);
            }
        }
    }

    function handleTipNaplatnostiChange(e,v) {
        if (v) {
            let newTrosakDetalji = [];
            trosakDetalji.forEach(td => {
                newTrosakDetalji.push({...td, 
                    TipNaplatnostiID : v.TipNaplatnostiID, 
                    TipOporezivostiID : td.TipOporezivostiID,
                    PoreznaStopa : td.PoreznaStopa}
                );
            });
            setTrosakDetalji(newTrosakDetalji);
            setTipNaplatnosti(v);
        }
    }

    function handleTipOporezivostiChange(e,v) {
        if (v) {
            let newTrosakDetalji = [];
            trosakDetalji.forEach(td => {
                newTrosakDetalji.push({...td, 
                    TipNaplatnostiID : td.TipNaplatnostiID, 
                    TipOporezivostiID : v.TipOporezivostiID,
                    PoreznaStopa : v.Stopa * 100}
                );
            });
            setTrosakDetalji(newTrosakDetalji);
            setTipOporezivosti(v);
        }
    }
     
    return (
        <LocalizationProvider locale={hr} dateAdapter={AdapterDateFns}>
            <ComponentContainer loading={loading}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center", marginBottom:"15px"}}>
                            Brzi unos troška
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box style={{width:"100%"}}>
                            <ListItem className="list-title" key={"listHeader"} >
                                <Grid container>
                                    <Grid item xs={4}>
                                        <ListItemText primaryTypographyProps={{ noWrap:true }} primary={"Klijent"} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Predmet"} />        
                                    </Grid>
                                    <Grid item xs={1}>
                                        <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={"Naplatno"} />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <ListItemText style={{paddingLeft:"15px", textAlign:"right"}} primaryTypographyProps={{ noWrap:true }} primary={"Porez"} />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <Paper variant="outlined" style={{width:"100%", maxHeight: 400, overflow: 'auto'}}>
                                <List dense>
                                    {trosakDetalji.map((detalj, index) => {
                                        return <ListItem className="list-item" key={index} style={{paddingTop:0, paddingBottom:0}}>
                                                    <Grid container>
                                                        <Grid item xs={4}>
                                                            <ListItemText primaryTypographyProps={{ noWrap:true }} primary={detalj.KlijentNaziv} />
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <ListItemText style={{paddingLeft:"15px"}} primaryTypographyProps={{ noWrap:true }} primary={detalj.PredmetNaziv} />        
                                                        </Grid>
                                                        <Tooltip title={detalj.TipNaplatnostiID == 1 ? "Naplatno" : "Nenaplatno"} enterDelay={300} enterNextDelay={300}>
                                                            <Grid item xs={1}>
                                                                <ListItemIcon style={{ height:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                                                    <Brightness1Icon sx={{ color: "rgb(" + (detalj.TipNaplatnostiID == 1 ? props.tipNaplatnostiNaplatnoBoja : props.tipNaplatnostiNenaplatnoBoja) + ")"}}></Brightness1Icon>
                                                                </ListItemIcon>
                                                            </Grid>
                                                        </Tooltip>
                                                        <Grid item xs={2}>
                                                            <ListItemText style={{paddingLeft:"15px", textAlign:"right"}} primaryTypographyProps={{ noWrap:true }} primary={detalj.PoreznaStopa + " %"} />
                                                        </Grid>
                                                    </Grid>
                                            </ListItem>;
                                    })}
                                </List>
                            </Paper>
                        </Box>
                    </Grid>
                    <Grid item xs={12} style={{display:"flex", justifyContent:"center", marginTop:"15px"}}>
                        <DatePicker onChange={setDatum} label="Datum unosa troška" value={datum} inputFormat="dd.MM.yyyy" maxDate={new Date()} renderInput={(params) => <TextField style={{ width: "230px" }} {...params} size="small" />} /> 
                    </Grid>
                    <Grid item xs={11}>
                        <FormControl fullWidth>
                            <TextField autoFocus value={opis || ""} label="Opis" onChange={e => setOpis(e.target.value)} multiline rows={2} error={!opis}></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                        <IconButton onClick={handleHistoryClick}><HistoryIcon></HistoryIcon></IconButton>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth>
                            <TextField type="number" label="Iznos" size="small" value={iznos} onChange={e => setIznos(e.target.value)} onBlur={handleIznosFocusOut} error={parseFloat(iznos) < 0} required></TextField>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth>
                            <Autocomplete size="small" 
                                disablePortal 
                                options={firmaValute} 
                                onChange={(e,v) => {if (v) setFirmaValuta(v)}}
                                getOptionLabel={option => option.FirmaValutaNaziv}
                                value={firmaValuta}
                                autoHighlight
                                ListboxProps={{ style: { maxHeight: '10rem' } }}
                                renderInput={(params) => <TextField sx={{width:"100%"}} size="small" {...params} label="Valuta" error={!firmaValuta} required />} 
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Autocomplete size="small" 
                                disablePortal
                                options={izvorPodatakaOptions} 
                                onChange={handleIzvorPodatakaChange}
                                getOptionLabel={(option) => option.Naziv}
                                value={izvorPodatakaOption}
                                autoHighlight
                                ListboxProps={{ style: { maxHeight: '10rem' } }}
                                renderInput={(params) => <TextField sx={{width:"100%"}} size="small" {...params} label="Oporezivost i naplatnost postavi na:" error={!tipOporezivosti} required />} 
                            />
                        </FormControl>
                    </Grid>
                    {izvorPodatakaOption.Vrijednost == 0 ? (
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth style={{ display:"flex", justifyContent:"center" }}>
                                <Autocomplete size="small" 
                                    disablePortal 
                                    options={tipoviNaplatnosti} 
                                    onChange={handleTipNaplatnostiChange}
                                    getOptionLabel={option => option.Naziv}
                                    value={tipNaplatnosti}
                                    autoHighlight
                                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.TipNaplatnostiID}>
                                                <Brightness1Icon sx={{ color: "rgb(" + (option.TipNaplatnostiID == 1 ? tipNaplatnostiNaplatnoBoja : tipNaplatnostiNenaplatnoBoja) + ")"}}></Brightness1Icon>
                                                <span>&nbsp;&nbsp;{option.Naziv}</span>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField size="small" sx={{width:"100%"}} {...params} label="Tip naplatnosti" error={!tipNaplatnosti} required InputProps={{
                                            ...params.InputProps,
                                            startAdornment: tipNaplatnosti ? <InputAdornment position="end"><Brightness1Icon sx={{ color : "rgb(" + (tipNaplatnosti.TipNaplatnostiID == 1 ? tipNaplatnostiNaplatnoBoja : tipNaplatnostiNenaplatnoBoja) + ")"}}></Brightness1Icon></InputAdornment> : null
                                        }}/>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                    ) : null}
                    {izvorPodatakaOption.Vrijednost == 0 ? (
                        <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                                <Autocomplete size="small" 
                                    disablePortal
                                    options={tipoviOporezivosti} 
                                    onChange={handleTipOporezivostiChange}
                                    getOptionLabel={(option) => option.TipPorezaNaziv + " (" + option.Stopa * 100 + " %)"}
                                    value={tipOporezivosti}
                                    autoHighlight
                                    ListboxProps={{ style: { maxHeight: '10rem' } }}
                                    renderInput={(params) => <TextField sx={{width:"100%"}} size="small" {...params} label="Tip oporezivosti" error={!tipOporezivosti} required />} 
                                />
                            </FormControl>
                        </Grid>
                    ) : null}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end" width="100%" marginTop="10px" >
                            <Button onClick={save} variant="contained" style={{marginRight:"10px"}} color="success" disabled={loading || !formValid}>{loading ? <LineIcon size="sm" name="spiner-solid lni-is-spinning" style={{marginRight:"10px"}} /> : <SaveIcon style={{marginRight:"10px"}} />} Sp&#818;remi</Button>
                            <Button variant="outlined" color="error" onClick={() => {setFormOpened(false);}} disabled={loading}><ExitToApp style={{ marginRight:"10px"}}></ExitToApp>O&#818;dustani</Button>
                        </Box>
                    </Grid>
                </Grid>

                <Menu
                    anchorEl={anchorEl}
                    open={historyOpened}
                    onClose={handleHistoryClose}
                    PaperProps={{ style: { maxWidth: "50vw" } }} 
                >
                    <ListItem>
                        <FormControl fullWidth>
                            <TextField 
                                sx={{
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }} 
                                value={povijestFilter} 
                                onChange={e => setPovijestFilter(e.target.value)} 
                                size="small"
                                InputProps={{endAdornment:(<InputAdornment position="end"><SearchIcon></SearchIcon></InputAdornment>)}}
                                disabled={povijestTroskova.length == 0}
                                onKeyDown={e => e.stopPropagation()}
                            ></TextField>
                        </FormControl>
                    </ListItem>
                    <Divider></Divider>
                    {povijestTroskova.length > 0 ? (
                        povijestTroskova.filter(opis => opis.toLowerCase().includes(povijestFilter.toLowerCase())).map((opis, index) => {
                            return <Tooltip title={opis} enterDelay={200} enterNextDelay={200}>
                                    <MenuItem onClick={() => {setOpis(opis)}}>
                                        <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                                            {opis}
                                        </div>
                                    </MenuItem>
                                </Tooltip>
                        })
                    ) : (
                        <div style={{textAlign:"center"}}>Ne postoji niti jedan evidentirani trošak.</div>
                    )}
                </Menu>

                {errorMessage != null && errorMessage != "" ? <AlertDialog title="Greška" icon={<ErrorIcon style={{marginRight:"15px", color:"red"}}></ErrorIcon>} message={errorMessage} cancelHidden={false} confirmHidden={true} okHidden={true} cancelLabel={"Zatvori"} cancelAction={() => setErrorMessage("")}></AlertDialog> : null}
            
            </ComponentContainer>
        </LocalizationProvider>
    );
}

export default BrziUnosTroskaForm;