import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import ComponentContainer from "./componentContainer";

function IzvjestajPrijenosa(props) {

    const data = props.data || {};
    const close = props.close;

    return (
        <ComponentContainer loading={false}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom component="div" style={{textAlign:"center"}}>
                        Izvještaj prijenosa
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="button" gutterBottom component="div" style={{textAlign:"center", color: data.BrojGresaka == 0 ? "black" : "red"}}>
                        Broj grešaka: {data.BrojGresaka}
                    </Typography>
                </Grid>
                { data.BrojGresaka > 0 ? (
                    data.ErrorMessages.map((errorMessage, index) => {
                        return(
                            <Grid item xs={12}>
                                <Typography variant="button" gutterBottom component="div" style={{textAlign:"center", color: "red"}}>
                                    {errorMessage}
                                </Typography>
                            </Grid>
                        )
                    })
                ) : null}

                <Grid item xs={12}>
                    <Typography variant="button" gutterBottom component="div" style={{textAlign:"center"}}>
                        Broj stvorenih računa: {data.KreiranoRacuna}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="button" gutterBottom component="div" style={{textAlign:"center"}}>
                        Broj stvorenih specifikacija: {data.KreiranoSpecifikacija}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="button" gutterBottom component="div" style={{textAlign:"center"}}>
                        Broj prebačenih satnica: {data.PrebacenoSatnica}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="button" gutterBottom component="div" style={{textAlign:"center"}}>
                        Broj prebačenih tarifa: {data.PrebacenoTarifa}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="button" gutterBottom component="div" style={{textAlign:"center"}}>
                        Broj prebačenih troškova: {data.PrebacenoTroskova}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="button" gutterBottom component="div" style={{textAlign:"center"}}>
                        Broj prebačenih evidencija po predmetu fiksne naplate: {data.PrebacenoFiksnih}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button style={{float:"right"}} variant="contained" onClick={close}>U redu</Button>
                </Grid>
            </Grid>
        </ComponentContainer>
    );
}

export default IzvjestajPrijenosa;